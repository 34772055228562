import React, { useCallback, useEffect, useState } from 'react';
import {
    TextField,
    Checkbox,
    Box,
    IconButton,
    FormControl,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { MdCheckBox, MdCheckBoxOutlineBlank, MdClear } from 'react-icons/md';

import SearchWithFilters from 'components/SearchWithFilters';
import { ContentTypes, LinkTypes } from '_helpers/enumerations';
import { debounce } from 'GeminiViewerComponent/_helpers/lodashUtils';
import { MultiSelectDoneButton } from 'GeminiViewerComponent/components/MultiSelectDoneButton';

const SelectExistingContentFilters = ({
    contentPayload,
    setContentPayload,
    searchStringState,
    setSearchStringState,
    inlineVideo,
}) => {
    const [contentTypeFilter, setContentTypeFilter] = useState([]);
    const [contentTypeOptions, setContentTypeOptions] = useState([]);

    useEffect(() => {
        if (inlineVideo === 'inlineVideo') {
            setContentTypeOptions(LinkTypes.Video.contentTypes);
        } else if (inlineVideo === 'videoPoster') {
            setContentTypeOptions(LinkTypes.Image.contentTypes);
        } else {
            setContentTypeOptions(Object.values(ContentTypes));
        }
    }, [inlineVideo]);

    const getContentTypeIds = (contentTypeIds) => {
        if (contentTypeIds && contentTypeIds?.length > 0) {
            return [...contentTypeIds];
        } else {
            if (inlineVideo === 'inlineVideo') {
                return LinkTypes.Video.contentTypes.map((link) => link.id);
            } else if (inlineVideo === 'videoPoster') {
                return LinkTypes.Image.contentTypes.map((link) => link.id);
            } else {
                return [];
            }
        }
    };

    const debouncedSearch = useCallback(
        debounce((searchString, contentTypeIds) => {
            setContentPayload({
                ...contentPayload,
                reset: true,
                startIndex: 0,
                stopIndex: 19,
                contentTypeIds: getContentTypeIds(contentTypeIds),
                searchString: searchString,
            });
        }, 1000),
        [contentPayload]
    );

    const handleSearch = (searchString) => {
        setSearchStringState(searchString);
        const array = contentTypeFilter.map((item) => item.id);
        debouncedSearch(searchString, array);
    };

    const updateSearchString = (evt) => {
        let searchString = evt.target.value;
        handleSearch(searchString);
    };

    const clearFilter = useCallback(() => {
        if (contentTypeFilter && contentTypeFilter?.length > 0) {
            setContentPayload({
                reset: true,
                startIndex: 0,
                stopIndex: 19,
                contentTypeIds: getContentTypeIds(),
                searchString: searchStringState,
            });
            setContentTypeFilter([]);
        }
    }, [inlineVideo, contentTypeFilter, searchStringState]);

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {Object.keys(ContentTypes)?.length > 0 && (
                    <FormControl
                        variant="outlined"
                        fullWidth
                        style={{
                            position: 'relative',
                            width: '50%',
                        }}
                    >
                        <Autocomplete
                            sx={{
                                minWidth: '140px',
                            }}
                            limitTags={1}
                            multiple
                            variant="outline"
                            id="checkboxes-content-types"
                            options={contentTypeOptions}
                            onChange={(event, newInputValue) => {
                                setContentTypeFilter(newInputValue);
                                const array = newInputValue.map(
                                    (item) => item.id
                                );
                                debouncedSearch(searchStringState, array);
                            }}
                            PaperComponent={MultiSelectDoneButton}
                            disableCloseOnSelect
                            value={contentTypeFilter}
                            disableClearable
                            getOptionLabel={(option) => option.label}
                            renderOption={(props, option, { selected }) => (
                                <li
                                    {...props}
                                    style={{
                                        padding: '0',
                                    }}
                                >
                                    <Checkbox
                                        icon={
                                            <MdCheckBoxOutlineBlank className="react-icon" />
                                        }
                                        checkedIcon={
                                            <MdCheckBox className="react-icon" />
                                        }
                                        style={{
                                            marginRight: 8,
                                        }}
                                        checked={selected}
                                    />
                                    {option.label}
                                </li>
                            )}
                            renderInput={(params) => (
                                <>
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Content Type"
                                        placeholder="Content Type"
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            right: '25px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            fontSize: '20px',
                                        }}
                                        className={'clearSelect'}
                                    >
                                        <IconButton onClick={clearFilter}>
                                            <MdClear />
                                        </IconButton>
                                    </Box>
                                </>
                            )}
                        />
                    </FormControl>
                )}

                <SearchWithFilters
                    onChange={(evt) => updateSearchString(evt)}
                    filter={false}
                ></SearchWithFilters>
            </div>
        </div>
    );
};

export { SelectExistingContentFilters };
