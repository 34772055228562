import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { Role } from '_helpers';
import { accountsSlice } from 'app/store';
const { selectActiveUser } = accountsSlice;
import { useConfigValues } from 'hooks/useConfigValues';
import { StatusSubTabPanel } from './StatusSubTabPanel/Index';
import { tabStyles } from 'GeminiViewerComponent/components/styles';
import { ManagementSubTabPanel } from './ManagementSubTabPanel/Index';
import { selectConfigValuesByFields } from '_features/configValues/configValuesSlice';
import {
    getJobsTabIndex,
    setJobsTabIndex,
} from '_features/globals/visibilitySlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';

function JobTab(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            style={{ height: '100%' }}
            {...other}
        >
            {value === index && (
                <Box p={3} style={{ overflow: 'auto hidden' }}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

JobTab.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

function JobsTabPanel() {
    const dispatch = useDispatch();
    const classes = tabStyles();

    const queryParams = new URLSearchParams(window.location.search);
    const tabIndex = +queryParams.get('tabIndex');

    const activeUser = useSelector(selectActiveUser);
    const displayTabIndex = useSelector(getJobsTabIndex);
    const { configValues, configFields } = useConfigValues({
        clientId: activeUser?.client_id,
    });
    const { DeploymentPackageJobsEnabled } = selectConfigValuesByFields(
        configValues,
        configFields,
        ['DeploymentPackageJobsEnabled']
    );

    const handleChange = (event, newValue) => {
        dispatch(setJobsTabIndex(newValue));
    };

    useEffect(() => {
        if (tabIndex) {
            dispatch(activateLoading());
            if (DeploymentPackageJobsEnabled === 'true') {
                dispatch(setJobsTabIndex(tabIndex));
                dispatch(deactivateLoading());
            }
        }
    }, [DeploymentPackageJobsEnabled]);

    let tabBarIndex = -1;
    let tabPageIndex = -1;
    return (
        <div className={classes.tabRoot}>
            <AppBar position="static" color="default">
                <Tabs
                    value={displayTabIndex}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    className={classes.tabs}
                >
                    {!Role?.DisableAdminRoles?.includes(activeUser?.role) && (
                        <Tab label="Status" {...a11yProps(++tabBarIndex)} />
                    )}
                    {Role?.IPSAdmin === activeUser?.role &&
                        DeploymentPackageJobsEnabled === 'true' && (
                            <Tab
                                label="Management"
                                {...a11yProps(++tabBarIndex)}
                            />
                        )}
                </Tabs>
            </AppBar>
            {Role?.IPSAdmin === activeUser?.role && (
                <>
                    <JobTab value={displayTabIndex} index={++tabPageIndex}>
                        <StatusSubTabPanel
                            DeploymentPackageJobsEnabled={
                                DeploymentPackageJobsEnabled
                            }
                        />
                    </JobTab>
                    {DeploymentPackageJobsEnabled === 'true' && (
                        <JobTab value={displayTabIndex} index={++tabPageIndex}>
                            <ManagementSubTabPanel />
                        </JobTab>
                    )}
                </>
            )}
        </div>
    );
}

export { JobsTabPanel };
