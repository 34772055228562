import { makeStyles } from '@mui/styles';

const recommendationsStyles = makeStyles(() => ({
    recHeader: {
        display: 'flex',
        height: '50px',
        backgroundColor: '#002A50',
        color: 'white',
        alignItems: 'center',
        fontSize: '22px',
        padding: '0px 10px',
        justifyContent: 'space-between',
    },
    slider: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '300px',
        padding: '0px 25px',
    },
}));

export { recommendationsStyles };
