import React from 'react';
import { Alert, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { MdRefresh } from 'react-icons/md';
import IconButton from '@mui/material/IconButton';
import { accountsSlice, accountsThunk } from 'app/store';
import { useStyles } from './styles';
import ClientDropdown from '../ClientsDropdown';
import { Role } from '_helpers/role';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { config } from 'gemini-config';
import { clearCacheStore } from 'GeminiViewerComponent/_helpers';
const { selectActiveUser, setAppLoading, setSelectedClient } = accountsSlice;

const { clientSwitch } = accountsThunk;

function PageHeader({ header, icon, onRefreshIconClick = null }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const activeUser = useSelector(selectActiveUser);

    const handleClientChange = async (selectedClientId) => {
        dispatch(activateLoading());
        dispatch(setAppLoading(true));
        dispatch(setSelectedClient(selectedClientId));
        const clientSelected = await dispatch(clientSwitch(selectedClientId));
        clearCacheStore();
        if (clientSelected.payload) {
            localStorage.setItem(
                'user',
                JSON.stringify(clientSelected.payload)
            );
        }
        dispatch(deactivateLoading());
        dispatch(setAppLoading(false));
        window.location.reload();
    };
    return (
        <div className={classes.headerContainer}>
            <div style={{ position: 'relative' }}>
                <Typography className={classes.headerTitle}>
                    {header}
                </Typography>
                {header === 'Admin Manager' || header === 'Job Status' ? (
                    <IconButton
                        onClick={() => {
                            if (onRefreshIconClick) {
                                onRefreshIconClick();
                            }
                        }}
                        aria-label="close"
                        sx={{
                            position: 'absolute',
                            top: '-1px',
                            right: '-41px',
                            color: '#707070',
                        }}
                        size="large"
                    >
                        <MdRefresh
                            className="react-icon"
                            style={{ fontSize: '24px', color: '#000' }}
                        />
                    </IconButton>
                ) : (
                    ''
                )}
            </div>
            {!activeUser.s3_prefix && (
                <div>
                    <Alert
                        severity="error"
                        sx={{ margin: '0px 10px 0px 10px' }}
                    >
                        Storage settings are not configured correctly. Please
                        check your config settings or contact your
                        administrator.
                    </Alert>
                </div>
            )}
            {activeUser?.role === Role.IPSAdmin &&
                config?.modeType === 'author' && (
                    <ClientDropdown
                        clients={activeUser?.available_clients}
                        onSelect={handleClientChange}
                        clientId={activeUser?.client_id}
                    />
                )}

            <div className={classes.headerIcon}>{icon}</div>
        </div>
    );
}
export { PageHeader };
