import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    selectorContainer: {
        height: '100%',
        '& .MuiFormControl-root': {
            width: '100%',
        },
    },
    selectorField: {
        // marginRight: '30px',
        // width: '249px',
    },
    optionsMainDiv: {
        position: 'absolute',
        top: 'auto',
        left: 0,
        border: '1px solid #cccccc',
        borderRadius: '5px',
        marginTop: '7px',
        zIndex: 1,
        backgroundColor: '#ffffff',
    },
    optionsSubDiv: {
        padding: '7px',
        display: 'flex',
        justifyContent: 'center',
        gap: '10px',
    },
}));

export default useStyles;
