import React, { useEffect, useRef, useState } from 'react';
import {
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    FormControl,
    Autocomplete,
    TextField,
    ClickAwayListener,
} from '@mui/material';
import useStyles from './style';

const MinMaxSelection = ({
    disabled = false,
    readOnly = true,
    variant = 'outlined',
    displayUnit = null,
    minOptions = [],
    maxOptions = [],
    minValue = '',
    maxValue = '',
    setMinValue = null,
    setMaxValue = null,
    inputLabel = 'Filter',
    selectorId,
    onSelectorChange,
    error = false,
    helperText = '',
    hasFullWidth = false,
    style = {},
}) => {
    const classes = useStyles();
    const isValuesChange = useRef(false);
    const valueUnits = displayUnit ? displayUnit : '';
    const showUnit = (val) => {
        return val && val !== 'No Min' && val !== 'No Max' ? valueUnits : '';
    };
    const [showOptions, setShowOptions] = useState(false);

    const toggleOptions = () => {
        setShowOptions(!showOptions);
    };

    useEffect(() => {
        if (
            isValuesChange.current === true &&
            showOptions === false &&
            onSelectorChange
        ) {
            isValuesChange.current = false;
            onSelectorChange();
        }
    }, [showOptions]);

    const possibleOptions = () => {
        const options = [
            {
                key: `~`,
                val: `~`,
                displayLabel: `None`,
            },
        ];
        if (Array.isArray(minOptions) && minOptions.length > 0) {
            minOptions.map((minOpt) => {
                options.push({
                    key: `${minOpt}~`,
                    val: `${minOpt}~`,
                    displayLabel: `${minOpt}${showUnit(minOpt)} ~ None`,
                });
                if (Array.isArray(maxOptions) && maxOptions.length > 0) {
                    maxOptions.map((maxOpt, index) => {
                        options.push({
                            key: `${minOpt}~${maxOpt}`,
                            val: `${minOpt}~${maxOpt}`,
                            displayLabel: `${minOpt}${showUnit(
                                minOpt
                            )} ~ ${maxOpt}${showUnit(maxOpt)}`,
                        });
                    });
                }
            });
        }
        if (Array.isArray(maxOptions) && maxOptions.length > 0) {
            maxOptions.map((maxOpt) => {
                options.push({
                    key: `~${maxOpt}`,
                    val: `~${maxOpt}`,
                    displayLabel: `None ~ ${maxOpt}${showUnit(maxOpt)}`,
                });
            });
        }
        return options;
    };

    return (
        <ClickAwayListener
            onClickAway={() => {
                setShowOptions(false);
            }}
        >
            <div className={classes.selectorContainer}>
                <div style={{ ...style, position: 'relative' }}>
                    <FormControl>
                        <InputLabel error={error} htmlFor={selectorId}>
                            {inputLabel}
                        </InputLabel>
                        <Select
                            name={selectorId}
                            id={selectorId}
                            label={inputLabel}
                            variant={variant}
                            // className={!hasFullWidth && classes.selectorField}
                            value={`${minValue}~${maxValue}`}
                            disabled={disabled}
                            error={error}
                            readOnly={readOnly}
                            placeholder={inputLabel}
                            onClick={toggleOptions}
                        >
                            {possibleOptions().map((opt) => (
                                <MenuItem key={opt.key} value={opt.val}>
                                    {opt.displayLabel}
                                </MenuItem>
                            ))}
                        </Select>
                        {error && (
                            <FormHelperText error={error}>
                                {helperText}
                            </FormHelperText>
                        )}
                    </FormControl>
                    {showOptions && (
                        <div className={classes.optionsMainDiv}>
                            <div className={classes.optionsSubDiv}>
                                <Autocomplete
                                    sx={{
                                        minWidth: '150px',
                                        maxWidth: 'max-content',
                                    }}
                                    variant="outline"
                                    id="checkboxes-min-values"
                                    options={minOptions}
                                    onChange={(event, newInputValue) => {
                                        isValuesChange.current = true;
                                        if (newInputValue) {
                                            setMinValue(newInputValue);
                                        } else {
                                            setMinValue('');
                                        }
                                    }}
                                    disableCloseOnSelect={false}
                                    value={minValue}
                                    getOptionLabel={(option) =>
                                        `${option}${showUnit(option)}`
                                    }
                                    renderOption={(
                                        props,
                                        option,
                                        { selected }
                                    ) => (
                                        <li
                                            {...props}
                                            key={`min-${option}`}
                                            style={{
                                                padding: '6px',
                                            }}
                                        >
                                            {option} {showUnit(option)}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Min Value"
                                            placeholder="Min Value"
                                        />
                                    )}
                                />
                                <Autocomplete
                                    sx={{
                                        minWidth: '150px',
                                        maxWidth: 'max-content',
                                    }}
                                    variant="outline"
                                    id="checkboxes-max-values"
                                    options={maxOptions}
                                    onChange={(event, newInputValue) => {
                                        isValuesChange.current = true;
                                        if (newInputValue) {
                                            setMaxValue(newInputValue);
                                        } else {
                                            setMaxValue('');
                                        }
                                    }}
                                    disableCloseOnSelect={false}
                                    value={maxValue}
                                    getOptionLabel={(option) =>
                                        `${option}${showUnit(option)}`
                                    }
                                    renderOption={(
                                        props,
                                        option,
                                        { selected }
                                    ) => (
                                        <li
                                            {...props}
                                            key={`max-${option}`}
                                            style={{
                                                padding: '6px',
                                            }}
                                        >
                                            {option} {showUnit(option)}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Max Value"
                                            placeholder="Max Value"
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </ClickAwayListener>
    );
};

export default MinMaxSelection;
