/**
 * This slice is to manage the zones display table
 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    getLoadedZoneById,
    updateZoneInActiveAsset,
} from 'GeminiViewerComponent/_features/asset/assetSlice';
import { zoneService } from '_features/_services';
import {
    LoadingStatus,
    SavingStatus,
} from 'GeminiViewerComponent/_helpers/AsyncStatus';

const sliceName = 'zonesTable';

const initialState = {
    status: LoadingStatus.Idle,

    error: null,
    selectedZoneId: null,

    initialZones: [],
    allZones: [],

    zonePageInfo: {},
    loadedZonesMap: {},
    zoneStatus: LoadingStatus.Idle,

    viewContentPanel: false,
    zoneCategory: [],
    categoryStatus: LoadingStatus.Idle,
};

export const addNewZone = createAsyncThunk(
    `${sliceName}/addNewZone`,
    async (newZone, { getState }) =>
        await zoneService.create(
            newZone,
            getState().accounts.activeUser.s3_prefix
        )
);

export const deleteZoneById = createAsyncThunk(
    `${sliceName}/a/deleteZoneById`,
    async (zoneId) => {
        const response = await zoneService.delete(zoneId);
        response.id = zoneId;
        return response;
    }
);

export const deleteZoneArray = createAsyncThunk(
    `${sliceName}/a/deleteZoneArray`,
    async ({ assetId, zoneIds }) => {
        const response = await zoneService.deleteArray(zoneIds);
        response.asset_id = assetId;
        response.ids = zoneIds;
        return response;
    }
);

export const duplicateZone = createAsyncThunk(
    `${sliceName}/a/duplicateZone`,
    async (existingZoneId, { getState }) =>
        await zoneService.duplicate(
            existingZoneId,
            getState().accounts.activeUser.s3_prefix
        )
);

export const editZone = createAsyncThunk(
    `${sliceName}/a/editZone`,
    async (props, { getState }) =>
        await zoneService.update(
            props,
            getState().accounts.activeUser.s3_prefix
        )
);

export const pinZone = createAsyncThunk(
    `${sliceName}/pinZone`,
    async (zoneId, { getState, dispatch }) => {
        // Reverse current pinned state
        const zone = getLoadedZoneById(getState(), zoneId);
        const params = { object_id: zoneId, is_pinned: !zone.is_pinned };
        var response = await zoneService.zonePinning(
            params,
            getState().accounts.activeUser.s3_prefix
        );
        if (response) {
            response.pinId = zoneId;
            await dispatch(
                updateZoneInActiveAsset({
                    zone_id: zoneId,
                    is_pinned: !response.payload.is_pinned,
                })
            );
        }
        return response;
    }
);

export const fetchZonesRange = createAsyncThunk(
    `${sliceName}/fetchZonesRange`,
    async (params, { getState }) => {
        const response = await zoneService.getAll(
            params.searchString,
            params.assetId,
            params.startIndex,
            params.stopIndex,
            params.sort,
            params.includeItems,
            getState().accounts.activeUser.s3_prefix
        );
        return response;
    }
);

export const fetchMoreZones = createAsyncThunk(
    `${sliceName}/fetchMoreZones`,
    async (params, { getState }) => {
        const response = await zoneService.getRange(
            params.assetId,
            params.startIndex,
            params.stopIndex,
            getState().accounts.activeUser.s3_prefix,
            true,
            params.includeItems
        );
        return response;
    }
);

export const copyZoneToAsset = createAsyncThunk(
    `${sliceName}/copyZoneToAsset`,
    async (params) => {
        const response = await zoneService.copyZoneToAsset(
            params.zone_ids,
            params.asset_ids
        );
        return response;
    }
);

// ---------------------------- ZONE CATEGORY --------------------------------
export const fetchZoneCategory = createAsyncThunk(
    `${sliceName}/fetchZoneCategory`,
    async ({ asset_id }) => {
        const response = await zoneService.getZoneCategoryByAssetId(asset_id);
        return response;
    }
);

export const createZoneCategory = createAsyncThunk(
    `${sliceName}/createZoneCategory`,
    async (params) => await zoneService.createZoneCategory(params)
);
// ---------------------------- END ZONE CATEGORY ----------------------------

const zonesTableSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        clearSelectedZone: (state) => {
            state.selectedZoneId = null;
        },
        resetTableZones: () => initialState,
        setTableZoneStatus: (state, action) => {
            state.zoneStatus = action.payload;
        },
        setSelectedZone: (state, action) => {
            state.selectedZoneId = action.payload;
        },
        updateTableZone: () => initialState,
        deletedAssetZone: (state, action) => {
            const zoneAsset = state.assets.find(
                (x) => x.asset_id === action.payload.assetId
            );
            if (zoneAsset) {
                zoneAsset.zone_count -= 1;
            }
            state.selectedZoneId = null;
            state.zoneStatus = LoadingStatus.Idle;
            state.loadedZonesMap = {};
        },
        addedAssetZone: () => initialState,
        editedAssetZone: () => initialState,
    },
    extraReducers: {
        [fetchZonesRange.pending]: (state) => {
            state.zoneStatus = LoadingStatus.Loading;
        },
        [fetchZonesRange.fulfilled]: (state, action) => {
            state.zoneStatus = LoadingStatus.Loaded;
            state.zonePageInfo = action.payload.pageInfo;
            state.initialZones = action.payload.zones;
            state.allZones = action.payload.zones;

            const startIndex = action.meta.arg.startIndex;
            const stopIndex = startIndex + action.payload.zones.length - 1;
            const requestedStopIndex = action.meta.arg.stopIndex;

            state.loadedZonesMap = {};

            for (var i = startIndex; i <= stopIndex; i++) {
                state.loadedZonesMap[i] = LoadingStatus.Loaded;
            }

            // In case we didn't load as many as requested
            for (var j = stopIndex + 1; j <= requestedStopIndex; j++) {
                delete state.loadedZonesMap[j];
            }
        },
        [fetchZonesRange.rejected]: (state, action) => {
            state.error = action.error.message;
        },
        [fetchMoreZones.pending]: (state, action) => {
            const startIndex = action.meta.arg.startIndex;
            const stopIndex = action.meta.arg.stopIndex;

            for (var i = startIndex; i <= stopIndex; i++) {
                state.loadedZonesMap[i] = LoadingStatus.Loading;
            }
        },
        [fetchMoreZones.fulfilled]: (state, action) => {
            const startIndex = action.meta.arg.startIndex;
            const stopIndex = startIndex + action.payload.zones.length - 1;
            const requestedStopIndex = action.meta.arg.stopIndex;

            for (var i = startIndex; i <= stopIndex; i++) {
                state.loadedZonesMap[i] = LoadingStatus.Loaded;
            }

            // In case we didn't load as many as requested
            for (var j = stopIndex + 1; j <= requestedStopIndex; j++) {
                delete state.loadedZonesMap[j];
            }

            state.allZones = state.allZones.concat(action.payload.zones);
        },
        [fetchMoreZones.rejected]: (state, action) => {
            const startIndex = action.meta.arg.startIndex;
            const stopIndex = action.meta.arg.stopIndex;

            for (var i = startIndex; i <= stopIndex; i++) {
                delete state.loadedZonesMap[i];
            }

            state.status = LoadingStatus.Failed;
            state.error = action.error.message;
        },
        [addNewZone.fulfilled]: () => initialState,
        [deleteZoneById.fulfilled]: () => initialState,
        [deleteZoneArray.fulfilled]: () => initialState,
        [deleteZoneArray.rejected]: (state, action) => {
            state.status = SavingStatus.Failed;
            state.error = action.error.message;
        },
        [duplicateZone.fulfilled]: () => initialState,
        [editZone.fulfilled]: () => initialState,
        [pinZone.fulfilled]: () => initialState,
        [pinZone.rejected]: (action) => {
            return action.payload;
        },

        // category
        [fetchZoneCategory.pending]: (state) => {
            state.categoryStatus = LoadingStatus.Loading;
        },
        [fetchZoneCategory.fulfilled]: (state, action) => {
            state.zoneCategory = action.payload;
            state.categoryStatus = LoadingStatus.Loaded;
        },
        [fetchZoneCategory.rejected]: (state) => {
            state.categoryStatus = LoadingStatus.Failed;
        },
        [createZoneCategory.fulfilled]: (state) => {
            state.categoryStatus = LoadingStatus.Idle;
        },
        [copyZoneToAsset.fulfilled]: (state, action) => {
            resetTableZones();
        },
        [copyZoneToAsset.rejected]: (state, action) => {
            state.status = SavingStatus.Failed;
            state.error = action.error.message;
        },
    },
});

export const selectSelectedZoneId = (state) => state.zonesTable.selectedZoneId;

export const selectSelectedZone = (state) => {
    return state.zonesTable.allZones.find(
        (zone) => zone.zone_id === state.zonesTable.selectedZoneId
    );
};

export const selectTableZoneById = (state, zoneId) => {
    return state.zonesTable.allZones
        ? state.zonesTable.allZones.find((zone) => zone.zone_id === zoneId)
        : undefined;
};

export const selectSelectedZoneTag = (state) => {
    const zone = selectSelectedZone(state);
    return zone ? zone.content_tag : undefined;
};

export const selectedSelectedZone = (state) => state.assets.displayZone;

export const selectInitialTableZones = (state) => {
    return state.zonesTable.initialZones;
};

export const selectLoadedZonesMap = (state) => state.zonesTable.loadedZonesMap;

export const selectAllTableZones = (state) => state.zonesTable.allZones ?? [];

export const selectZonePageInfo = (state) => state.zonesTable.zonePageInfo;

export const selectZoneCategory = (state) => state.zonesTable.zoneCategory;
export const selectCategoryStatus = (state) => state.zonesTable.categoryStatus;
export const selectTableZoneStatus = (state) => state.zonesTable.zoneStatus;

export const {
    clearSelectedZone,
    setSelectedZone,
    updateTableZone,
    resetTableZones,
    updateZoneItem,
    deleteZoneItemById,
    setTableZoneStatus,
} = zonesTableSlice.actions;

export default zonesTableSlice.reducer;
