import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { navItemStyles } from './styles';
import { FiArrowRight } from 'react-icons/fi';

export default function NavItem({ to, icon, text }) {
    const classes = navItemStyles();
    const isCurrentLoc = useLocation().pathname === to;

    return (
        <Link to={to} className={clsx(classes.navLink)}>
            <ListItem button key={text}>
                <ListItemIcon
                    className={clsx(classes.navLinkIcon, {
                        [classes.activeLink]: isCurrentLoc,
                        [classes.activeLinkCustomIcons]:
                            isCurrentLoc && to === '/procedures',
                    })}
                >
                    {icon}
                </ListItemIcon>
                <ListItemText
                    primary={text}
                    className={clsx({ [classes.activeLink]: isCurrentLoc })}
                />
                {isCurrentLoc ? (
                    <FiArrowRight className={classes.activeLink} />
                ) : null}
            </ListItem>
        </Link>
    );
}
