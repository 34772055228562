import React, { useEffect, useState } from 'react';
import {
    MenuItem,
    Box,
    FormControl,
    IconButton,
    InputLabel,
    Select,
    TextField,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { MdAdd, MdDelete, MdEdit } from 'react-icons/md';

import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { actionListDialogStyle } from '../styles';
import { map } from 'GeminiViewerComponent/_helpers/lodashUtils';

const ReasonsDropdown = ({
    actionsData,
    setFieldValue,
    values,
    setReasonId,
    setOpenReasonListDialog,
    getReasonList,
    idx,
    actionsList,
    setActionsList,
    setActionId,
    setAddReason,
}) => {
    const theme = useSelector(selectActiveTheme);
    const actionDialogClasses = actionListDialogStyle(theme);
    const [reasonsData, setReasonsData] = useState([]);
    const handleChange = (e) => {
        let list = [...actionsList];
        list[idx] = {
            ...actionsData,
            inspection_reason_list_id: e.target.value,
        };
        setActionsList([...list]);
        setReasonId(e.target.value);
    };

    useEffect(() => {
        setReasonsData(getReasonList);
    }, [getReasonList]);

    const handleRemoveAction = () => {
        let actionData = [...actionsList];
        actionData.splice(idx, 1);
        setActionsList([...actionData]);
    };

    return (
        <Box
            key={actionsData?.inspection_action_id}
            display="flex"
            alignItems="center"
            marginBottom="15px"
        >
            <TextField
                sx={{ marginRight: '15px' }}
                fullWidth
                id="action-name"
                value={actionsList[idx]?.display_name}
                onChange={(e) => {
                    let list = [...actionsList];
                    list[idx] = {
                        ...actionsData,
                        display_name: e.target.value,
                    };
                    setActionsList([...list]);
                }}
                label={'Name'}
                type="text"
                variant="outlined"
            />
            <FormControl fullWidth>
                <Box display="flex" width="100%">
                    <InputLabel id="reason-list-label">Reasons List</InputLabel>
                    <Select
                        labelId="reason-list-label"
                        id="action-list-type"
                        label="Actions List"
                        value={
                            map(actionsList, 'inspection_reason_list_id')[idx]
                        }
                        onChange={handleChange}
                        style={{
                            width: '60%',
                            border: '0',
                            borderRadius: '4px 0 0 4px',
                        }}
                    >
                        {reasonsData?.length > 0 &&
                            reasonsData?.map((inspectionReason) => (
                                <MenuItem
                                    key={
                                        inspectionReason.inspection_reason_list_id
                                    }
                                    value={
                                        inspectionReason.inspection_reason_list_id
                                    }
                                >
                                    {inspectionReason.display_name}
                                </MenuItem>
                            ))}
                    </Select>
                    <IconButton
                        sx={{ borderRadius: 0 }}
                        color="black"
                        aria-label="Edit Actions List"
                        className={actionDialogClasses.actionListButton}
                        onClick={() => {
                            setReasonId(
                                map(actionsList, 'inspection_reason_list_id')[
                                    idx
                                ]
                            );
                            setOpenReasonListDialog(true);
                            setActionId(idx);
                            setAddReason(false);
                        }}
                    >
                        <MdEdit className="react-icon" />
                    </IconButton>
                    <IconButton
                        sx={{ borderRadius: '0 4px 4px 0' }}
                        color="black"
                        aria-label="Delete Actions List"
                        className={actionDialogClasses.actionListButton}
                        onClick={() => {
                            setReasonId(null);
                            setOpenReasonListDialog(true);
                            setActionId(idx);
                            setAddReason(true);
                        }}
                    >
                        <MdAdd className="react-icon" />
                    </IconButton>
                </Box>
            </FormControl>
            <IconButton
                color="black"
                aria-label="Delete Actions List"
                style={{ height: 'auto' }}
                onClick={() => {
                    handleRemoveAction(idx);
                }}
            >
                <MdDelete className="react-icon" />
            </IconButton>
        </Box>
    );
};

export default ReasonsDropdown;
