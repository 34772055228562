import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import Dropzone from 'react-dropzone';
import { MdDelete } from 'react-icons/md';

import { MarzipanoViewer } from 'GeminiViewerComponent/components/MarzipanoViewer';
import useStyles from '../styles';

const TitleHeader = ({
    title,
    backColor = '#7d7d7d',
    textColor = '#ffffff',
}) => {
    return (
        <Grid
            item
            style={{
                backgroundColor: `${backColor}`,
                borderRadius: '5px',
                color: `${textColor}`,
                width: '100%',
                padding: '5px',
                textAlign: 'center',
            }}
        >
            {title}
        </Grid>
    );
};

function PanoEditor({
    sequenceImages,
    setSequenceImages,
    handleFileChange,
    handleSaveZones,
}) {
    const [activePreviewZone, setActivePreviewZone] = useState(null);
    const classes = useStyles();

    useEffect(() => {
        if (Array.isArray(sequenceImages) && sequenceImages.length > 0) {
            let isZoneExist = false;
            if (activePreviewZone && activePreviewZone?.id) {
                let activeZone = sequenceImages.find(
                    (zone) => zone?.id === activePreviewZone?.id
                );
                if (activeZone) {
                    isZoneExist = true;
                    setActivePreviewZone(activeZone);
                }
            }
            if (!isZoneExist) {
                setActivePreviewZone(sequenceImages[0]);
            }
        } else {
            setActivePreviewZone(null);
        }
    }, [sequenceImages]);

    const handleActiveZone = (id) => {
        let activeZone = sequenceImages.find((zone) => zone?.id === id);
        if (activeZone) {
            setActivePreviewZone(activeZone);
        } else {
            setActivePreviewZone(null);
        }
    };

    const handleZoneNameUpdate = (e, zoneImg) => {
        const updatedObjects = sequenceImages.map((zone) =>
            zone?.id === zoneImg?.id
                ? {
                      ...zone,
                      display_name: e.target.value,
                  }
                : zone
        );
        setSequenceImages(updatedObjects);
    };

    const handleDeleteSequenceImage = (id) => {
        const filteredZones = sequenceImages.filter((zone) => zone?.id !== id);
        setSequenceImages(filteredZones);
    };

    return (
        <Grid container columnSpacing={2}>
            <Grid
                style={{ height: 'calc(100vh - 235px)' }}
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
            >
                <div className={classes.panoEditor}>
                    <div className={classes.zoneListingDiv}>
                        <TitleHeader
                            backColor="white"
                            textColor="#000"
                            title="Panos/Zones"
                        />
                        {Array.isArray(sequenceImages) &&
                        sequenceImages.length > 0
                            ? sequenceImages.map((zoneImg) => (
                                  <Grid
                                      key={zoneImg.id}
                                      container
                                      className={classes.unitZoneDiv}
                                      style={{
                                          border: `${
                                              activePreviewZone?.id ===
                                              zoneImg.id
                                                  ? '2px solid #000000'
                                                  : '2px solid #cbcccc'
                                          }`,
                                          backgroundColor:
                                              zoneImg.zone_create_status ===
                                              'Pending'
                                                  ? '#297FB9'
                                                  : zoneImg.zone_create_status ===
                                                    'Completed'
                                                  ? '#7DC576'
                                                  : '#D32F2F',
                                      }}
                                      onClick={(e) => {
                                          handleActiveZone(zoneImg?.id);
                                      }}
                                  >
                                      <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={12}
                                          lg={12}
                                      >
                                          <Grid container>
                                              <Grid
                                                  item
                                                  xs={12}
                                                  sm={12}
                                                  md={10}
                                                  lg={11}
                                              >
                                                  <input
                                                      className={
                                                          classes.zoneNameInput
                                                      }
                                                      value={
                                                          zoneImg?.display_name
                                                      }
                                                      onClick={(e) => {
                                                          e.stopPropagation();
                                                      }}
                                                      onChange={(e) =>
                                                          handleZoneNameUpdate(
                                                              e,
                                                              zoneImg
                                                          )
                                                      }
                                                      disabled={
                                                          zoneImg.zone_create_status ===
                                                          'Completed'
                                                      }
                                                  />
                                              </Grid>
                                              <Grid
                                                  item
                                                  xs={12}
                                                  sm={12}
                                                  md={2}
                                                  lg={1}
                                                  justifyContent="end"
                                                  display="flex"
                                              >
                                                  <div
                                                      onClick={(e) => {
                                                          e.stopPropagation();
                                                          handleDeleteSequenceImage(
                                                              zoneImg.id
                                                          );
                                                      }}
                                                  >
                                                      {zoneImg.zone_create_status !==
                                                          'Completed' && (
                                                          <MdDelete
                                                              className="react-icon"
                                                              style={{
                                                                  fill: 'white',
                                                              }}
                                                          />
                                                      )}
                                                  </div>
                                              </Grid>
                                          </Grid>
                                          <Grid
                                              container
                                              style={{
                                                  marginTop: '5px',
                                              }}
                                          >
                                              <Grid
                                                  item
                                                  xs={12}
                                                  sm={12}
                                                  md={12}
                                                  lg={12}
                                                  justifyContent="start"
                                                  display="flex"
                                              >
                                                  <div>
                                                      {
                                                          zoneImg.zone_create_status
                                                      }
                                                  </div>
                                              </Grid>
                                          </Grid>
                                      </Grid>
                                  </Grid>
                              ))
                            : null}
                    </div>

                    <Grid container className={classes.morePanoDropzone}>
                        <Grid item className={classes.panoTextDiv}>
                            <Dropzone
                                maxFiles={100}
                                maxSize={250000000}
                                multiple={true}
                                accept={[
                                    'image/jpeg',
                                    'image/jpg',
                                    'image/png',
                                ]}
                                onDropAccepted={(files) =>
                                    handleFileChange(files)
                                }
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <div className={classes.dropzoneText}>
                                            Drop images or click here to add
                                            more Panos
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9}>
                <div className={classes.panoDisplayDiv}>
                    <Grid
                        container
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <TitleHeader
                            title={`${
                                activePreviewZone?.display_name ?? 'Title...'
                            }`}
                        />
                        <Grid item className={classes.viewerDisplayDiv}>
                            {activePreviewZone && (
                                <MarzipanoViewer
                                    previewZone={activePreviewZone}
                                />
                            )}
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            <Grid
                display="flex"
                justifyContent="end"
                item
                mt={1}
                xs={12}
                sm={12}
                md={12}
                lg={12}
            >
                <Button
                    variant="contained"
                    color="primary"
                    disabled={Boolean(sequenceImages?.length < 1)}
                    onClick={handleSaveZones}
                >
                    {sequenceImages.filter(
                        (zone) => zone.zone_create_status === 'Failed'
                    )?.length > 0
                        ? 'Save & Retry'
                        : 'Save'}
                </Button>
            </Grid>
        </Grid>
    );
}

export { PanoEditor };
