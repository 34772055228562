import { Card, CardMedia, Typography } from '@mui/material';
import { ContentTypes } from '_helpers';

export default function ExistingContentCard({
    content,
    handleSelect,
    width,
    isSelected,
}) {
    const image = [
        ContentTypes.Jpeg.id,
        ContentTypes.Jpg.id,
        ContentTypes.Png.id,
        ContentTypes.Svg.id,
    ].includes(content.content_type_id)
        ? content?.url
        : ContentTypes[
              Object.keys(ContentTypes).find(
                  (at) => ContentTypes[at].id === content.content_type_id
              )
          ].img;

    return (
        <Card
            style={{
                width: width,
                height: '175px',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '0',
                border: `${
                    isSelected(content.content_id)
                        ? '2px solid #2d357c'
                        : ' none'
                }`,
                boxShadow: ' 0 0 17px 0 rgba(45,61,80,0.11)',
                overflow: 'auto',
            }}
            onClick={(e) => handleSelect(e, content)}
        >
            <CardMedia
                component={typeof image === 'string' ? 'div' : image}
                image={typeof image === 'string' ? image : null}
                style={{
                    minWidth: '40%',
                    height: '100%',
                    backgroundPosition: 'right',
                    cursor: 'pointer',
                    minHeight: '75px',
                }}
            />
            <Typography
                style={{
                    textAlign: 'center',
                    userSelect: 'none',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                }}
            >
                {content.display_name}
            </Typography>
        </Card>
    );
}
