import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdVrpano } from 'react-icons/md';

import { setVisualEditZone } from '_features/common/editZoneSlice';
import { loadZone } from 'shared/loadZoneSlice';
import { setHotspotIcons } from 'GeminiViewerComponent/_features/asset/assetSlice';
import { selectAllHotspots } from 'shared/hotspotsSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { IconButton, Tooltip } from '@mui/material';

export function VisualEdit({ rowData }) {
    const dispatch = useDispatch();
    const hotspots = useSelector(selectAllHotspots);

    useEffect(() => {
        if (hotspots) {
            dispatch(setHotspotIcons(hotspots));
        }
    }, [hotspots]);

    async function handleClick(rowData) {
        dispatch(activateLoading());
        await dispatch(loadZone({ zoneId: rowData.zone_id }));
        dispatch(setVisualEditZone(true));
        dispatch(deactivateLoading());
    }

    var hasImage =
        rowData.equirect_image_preview_url !== undefined ||
        rowData.flat_image_preview_url !== undefined;

    if (!hasImage) {
        return null;
    }

    return (
        <Tooltip title="View Zone">
            <IconButton>
                <MdVrpano
                    className="react-icon"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleClick(rowData);
                    }}
                />
            </IconButton>
        </Tooltip>
    );
}
