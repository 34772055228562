import { makeStyles } from '@mui/styles';

const dtSingleChoiceStyles = makeStyles(() => ({
    dtSingleChoice: {
        padding: '10px',
        backgroundColor: '#f7f7f7',
    },
}));

export { dtSingleChoiceStyles };
