// eslint-disable-next-line
import React, { useEffect, useState, createElement } from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery,
} from '@mui/material';

import { getZoneMediaItems } from 'shared/loadZoneSlice';
import { sortBy } from 'GeminiViewerComponent/_helpers/lodashUtils';
import { LinkTypeAccordion } from '../../components/LinkTypeAccordion';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { selectContentPanelItem } from 'GeminiViewerComponent/_features/contentPanel/contentPanelItemSlice';
import { useTheme } from '@emotion/react';

const useStyles = makeStyles((theme) => ({
    noLinks: {
        textAlign: 'center',
        margin: '25px',
        fontSize: theme.typography.pxToRem(20),
        color: (props) => props.colors.secondarySectionForeground,
    },
}));

const SidePanelBody = ({ linkTypes, audienceIds }) => {
    const theme = useSelector(selectActiveTheme);
    const classes = useStyles(theme);
    const [links, setLinks] = useState({});
    const [open, setOpen] = useState(false);
    const panelItem = useSelector(selectContentPanelItem);
    const zoneMediaItem = useSelector(getZoneMediaItems);
    const muiTheme = useTheme();
    const fullScreen = useMediaQuery(muiTheme.breakpoints.down('md'));

    useEffect(() => {
        var linkObj = {};
        var allLinks =
            Object.keys(panelItem ?? {}).length > 0
                ? panelItem
                : { links: zoneMediaItem };
        const links = Object.values(allLinks.links ?? {}).sort(
            sortBy('display_name', 'DESC')
        );
        links.forEach((link) => {
            let hasAllAudiences = true;
            if (
                audienceIds !== null &&
                audienceIds !== undefined &&
                link.audience_ids !== null &&
                link.audience_ids !== undefined
            ) {
                hasAllAudiences =
                    link.audience_ids.length === 0 ||
                    link.audience_ids.some((audienceId) => {
                        return audienceIds.includes(audienceId);
                    });
            }
            if (hasAllAudiences) {
                var linkTypeId = link.link_type
                    ? link.link_type.link_type_id
                    : link.link_type_id;

                if (
                    !Object.prototype.hasOwnProperty.call(linkObj, linkTypeId)
                ) {
                    if (link.is_active) {
                        linkObj[linkTypeId] = [link];
                    }
                } else {
                    if (link.is_active) {
                        linkObj[linkTypeId] = [...linkObj[linkTypeId], link];
                    }
                }
            }
        });

        setLinks({ ...linkObj });
    }, [panelItem, zoneMediaItem, audienceIds]);

    if (Object.keys(links).length === 0) {
        return (
            <div className={classes.noLinks}>
                This item has no links at the moment.
            </div>
        );
    }

    const closeModal = () => {
        setOpen(false);
    };

    return (
        <div>
            {Object.keys(links).map((linkTypeId) => {
                return (
                    <LinkTypeAccordion
                        linkTypes={linkTypes}
                        linkTypeId={linkTypeId}
                        links={links[linkTypeId]}
                        key={linkTypeId}
                        isZoneMedia={!Object.keys(panelItem ?? {}).length > 0}
                        setOpen={setOpen}
                    />
                );
            })}
            <Dialog fullScreen={fullScreen} open={open}>
                <DialogTitle>Warning</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The link you selected is configured to display in a
                        Companion App window, but there is no Companion App
                        window open.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeModal}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export { SidePanelBody };
