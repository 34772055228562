import { useCallback, useEffect, useState } from 'react';
import { MdExpandMore } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
} from '@mui/material';

import { TableGrid } from '../../TableGrid';
import { fetchMoreAssets } from '_features/assets/assetsTableSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';

const CategoriesAssetTable = ({
    allCategories,
    searchString,
    sortDirection,
    sortBy,
    handleSort,
    loadingStatus,
    assetType,
    assetPayload,
    readOnly,
    category,
    expanded,
    handleOpenCategory,
    selectedAssetCategory,
    setNoCategoriesAsset = () => {},
}) => {
    //#region Constants
    const dispatch = useDispatch();
    //#endregion Constants

    //#region Hooks
    //#endregion Hooks

    //#region State
    const [filteredAssets, setFilteredAssets] = useState([]);
    const [filteredAssetsPageInfo, setFilteredAssetsPageInfo] = useState({});
    //#endregion State

    //#region Selectors
    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Effects
    useEffect(() => {
        if (category && category?.asset_count > 0) {
            fetchCategoryAsset(category?.category_id);
        } else {
            setFilteredAssets([]);
        }
    }, [category, allCategories, assetPayload, searchString]);

    //#endregion Effects

    //#region Methods
    const fetchCategoryAsset = useCallback(
        async (categoryId) => {
            await dispatch(activateLoading());
            const categoriesAssets = await dispatch(
                fetchMoreAssets({
                    ...assetPayload,
                    startIndex: 0,
                    stopIndex: 14,
                    searchString: searchString,
                    assetCategoryId: categoryId,
                })
            );
            setFilteredAssetsPageInfo(categoriesAssets.payload?.pageInfo);
            setFilteredAssets(categoriesAssets.payload?.assets);
            if (categoriesAssets.payload?.assets?.length > 0) {
                setNoCategoriesAsset(false);
            }
            await dispatch(deactivateLoading());
        },
        [assetPayload, searchString]
    );
    //#endregion Methods

    //#region Render time calcs
    //#endregion Render time calcs

    //#region Render
    return (
        filteredAssets &&
        filteredAssets?.length > 0 &&
        (selectedAssetCategory?.length === 1 ? (
            <TableGrid
                assets={filteredAssets}
                pageInfo={filteredAssetsPageInfo}
                searchString={searchString}
                sortDirection={sortDirection}
                sortBy={sortBy}
                handleSort={handleSort}
                loadingStatus={loadingStatus}
                assetType={assetType}
                assetPayload={assetPayload}
                readOnly={readOnly}
                accordianTable={true}
                categoryId={category?.category_id}
                setFilteredAssets={setFilteredAssets}
            />
        ) : (
            <Accordion
                id={`category-${category?.category_id}`}
                expanded={expanded.some((cat) => cat === category?.category_id)}
                onChange={handleOpenCategory(category?.category_id)}
            >
                <AccordionSummary
                    sx={{
                        backgroundColor: '#efefef',
                    }}
                    expandIcon={<MdExpandMore />}
                >
                    {category?.display_name}
                </AccordionSummary>
                <AccordionDetails sx={{ padding: '8px !important' }}>
                    <Box
                        id={`assetCards-${category.category_id}`}
                        sx={{
                            overflow: 'auto !important',
                        }}
                    >
                        <TableGrid
                            assets={filteredAssets}
                            pageInfo={filteredAssetsPageInfo}
                            searchString={searchString}
                            sortDirection={sortDirection}
                            sortBy={sortBy}
                            handleSort={handleSort}
                            loadingStatus={loadingStatus}
                            assetType={assetType}
                            assetPayload={assetPayload}
                            readOnly={readOnly}
                            accordianTable={true}
                            categoryId={category?.category_id}
                        />
                    </Box>
                </AccordionDetails>
            </Accordion>
        ))
    );
    //#endregion Render
};

export { CategoriesAssetTable };
