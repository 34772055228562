// eslint-disable-next-line
import React, { createElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    setCurrentNode,
    addReversal,
    getCurrentNode,
} from '../../../../_features/decisionTree/decisionTreeSlice';
import { setListening } from '../../../../_features/voice/voiceSlice';
import { saveStatement } from 'GeminiViewerComponent/_features/xAPI/xapiSlice';
import { LrsVerbs } from 'GeminiViewerComponent/_features/xAPI/xApiData';
import { dtOptionStyles } from './styles';

const DecisionOption = ({ option }) => {
    const dispatch = useDispatch();
    const classes = dtOptionStyles();
    const currentNode = useSelector((state) => getCurrentNode(state));

    const handleOption = () => {
        dispatch(setCurrentNode({ node_id: option.button_link_node }));
        dispatch(addReversal({ node_id: currentNode.node_id }));
        dispatch(
            saveStatement({
                LrsVerb: LrsVerbs.Answered,
                response: {
                    action: 'handleOption',
                    node_id: option.button_link_node,
                    statementType: 'REPORT_RESPONSE',
                },
            })
        );
        dispatch(setListening({ listen: false }));
    };

    return (
        <div className={classes.dtOption} onClick={handleOption}>
            <span className={classes.dtOptionText}>{option.button_text}</span>
        </div>
    );
};

export { DecisionOption };
