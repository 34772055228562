import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setCurrentNode,
    addReversal,
    getCurrentNode,
} from '../../../../_features/decisionTree/decisionTreeSlice';
import { recommendationStyles } from './styles';

const Recommendation = ({ rec }) => {
    const dispatch = useDispatch();
    const classes = recommendationStyles();
    const currentNode = useSelector((state) => getCurrentNode(state));

    const handleOption = () => {
        dispatch(setCurrentNode({ node_id: rec.node_id }));
        dispatch(addReversal({ node_id: currentNode.node_id }));
    };

    return (
        <div className={classes.recommendation} onClick={handleOption}>
            <div className={classes.recInfo}>
                <div className={classes.title}>{rec.title}</div>
                <div className={classes.certainty}>{rec.certainty}%</div>
            </div>
        </div>
    );
};

export { Recommendation };
