// eslint-disable-next-line
import React, { createElement } from 'react';
import { useDispatch } from 'react-redux';
import { IoMdRefreshCircle } from 'react-icons/io';
import { saveStatement } from 'GeminiViewerComponent/_features/xAPI/xapiSlice';
import { LrsVerbs } from 'GeminiViewerComponent/_features/xAPI/xApiData';

import { resetTree } from '../../../../../../_features/decisionTree/decisionTreeSlice';
import { dtNodeActionStyles } from '../../styles';

const DtResetAction = () => {
    const dispatch = useDispatch();
    const classes = dtNodeActionStyles();

    const handleReset = () => {
        dispatch(resetTree());
        dispatch(
            saveStatement({
                LrsVerb: LrsVerbs.Updated,
                response: { action: 'handleReset' },
            })
        );
    };

    return (
        <div className={classes.dtAction} onClick={handleReset}>
            <IoMdRefreshCircle />
            <div>Reset</div>
        </div>
    );
};

export { DtResetAction };
