import { config } from 'gemini-config';
import { getAccountService } from 'GeminiViewerComponent/_features/services/account.service';
import { getNetworkService, prefixUrl } from 'GeminiViewerComponent/_helpers';

const baseUrl = `${config.apiUrl}/playerexportjob`;
const accountService = getAccountService({ config: config });
const networkService = getNetworkService({
    config: config,
    checkAuth: accountService.checkAuth,
});

const mapAsset = (asset, s3Prefix) => {
    asset.file_url = prefixUrl(asset.file_url, s3Prefix);
    return asset;
};

const mapAssets = (assets, s3Prefix) => {
    return assets.map((asset) => {
        return mapAsset(asset, s3Prefix);
    });
};

const getAll = async (
    searchString = '',
    sort = '-status_id',
    s3Prefix = ''
) => {
    const filters = [searchString ? `asset_name@=*${searchString}` : ''];

    const params = {
        filters: filters.join(','),
        sorts: sort,
    };

    const query = new URLSearchParams(params);

    const response = await networkService.get(`${baseUrl}/?${query}`);
    const assets = mapAssets(response.data, s3Prefix);
    const pageInfo = response.headers['x-pagination'];
    return { pageInfo: pageInfo, assets: assets };
};

const create = async (params) => {
    const response = await networkService.post(baseUrl, params);
    return response.data;
};

export const assetExportService = {
    create,
    getAll,
};
