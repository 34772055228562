import { getSelectedSearchItemId } from 'GeminiViewerComponent/_features/asset/assetSlice';
import { getProcedureContentVersion } from 'GeminiViewerComponent/_features/globals/visibilitySlice';
import { useSelector } from 'react-redux';

export const useHighlightedItem = (activeZone) => {
    const searchItem = useSelector(getSelectedSearchItemId);
    const procedureContentVersion = useSelector(getProcedureContentVersion);

    let setActiveZonePosition = { ...activeZone };

    if (searchItem) {
        const highlitedItem = activeZone?.items.find(
            (item) => item.item_id === searchItem
        );
        setActiveZonePosition.init_view_yaw = highlitedItem?.yaw;
        setActiveZonePosition.init_view_pitch = highlitedItem?.pitch;
    } else {
        if (
            procedureContentVersion?.doAction &&
            procedureContentVersion.doAction?.item_id
        ) {
            const highlitedItem = activeZone?.items.find(
                (item) =>
                    item.item_id === procedureContentVersion.doAction.item_id
            );
            setActiveZonePosition.init_view_yaw = highlitedItem?.yaw;
            setActiveZonePosition.init_view_pitch = highlitedItem?.pitch;
        }
    }
    if (
        !setActiveZonePosition?.init_view_yaw &&
        !setActiveZonePosition.init_view_pitch &&
        !setActiveZonePosition.init_view_zoom
    ) {
        setActiveZonePosition.init_view_yaw = 0;
        setActiveZonePosition.init_view_pitch = 0;
        setActiveZonePosition.init_view_zoom = 1;
    }
    return setActiveZonePosition;
};
