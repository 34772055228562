import { useState, useEffect, useRef } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { FormActions } from '_helpers/form-action';
import { snackbarHandler } from 'GeminiViewerComponent/_helpers/snackbar-handler';
import { makeFormStyles } from '../styles';
import {
    setProgress,
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import SetDirtyForm from 'forms/SetDirtyForm';
import { LoadingLogo } from 'GeminiViewerComponent/components/LoadingLogo/LoadingLogo';

import { FormTextField } from 'components/TextField';
import {
    createCategory,
    fetchCategoryById,
    updateCategory,
} from '_features/category/categorySlice';

const CategoryForm = ({ formAction, categoryId }) => {
    //#region Constants
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    //#endregion Constants

    //#region Hooks
    //#endregion Hooks

    //#region State
    const [loaded, setLoaded] = useState(
        formAction.id === FormActions.Create.id ? true : false
    );
    const [initValues, setInitValues] = useState({
        display_name: '',
    });
    //#endregion State

    //#region Selectors
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);
    //#endregion Selectors

    //#region Refs
    const categoryNameRef = useRef(null);
    //#endregion Refs

    //#region Effects
    useEffect(() => {
        if (formAction.id === FormActions.Edit.id) {
            const fetchData = async () => {
                let objectData = await dispatch(fetchCategoryById(categoryId));
                let fetchedObject = objectData.payload;
                setInitValues({
                    ...fetchedObject,
                });
                setLoaded(true);
            };
            fetchData();
        } else {
            setInitValues({ display_name: '' });
        }
    }, [categoryId]); // eslint-disable-line react-hooks/exhaustive-deps
    //#endregion Effects

    //#region Methods
    const handleSubmit = async (values, setSubmitting, resetForm) => {
        dispatch(activateLoading({ showProgress: true }));
        let resultAction = null;

        switch (formAction.id) {
            case FormActions.Edit.id:
                resultAction = await dispatch(
                    updateCategory({
                        ...values,
                    })
                );
                if (!resultAction.error) {
                    setInitValues({
                        ...initValues,
                        display_name: resultAction?.payload.display_name,
                    });
                }
                break;
            case FormActions.Create.id:
                dispatch(
                    setProgress({
                        progress: 0,
                        label: 'Step 1 of 2: Uploading',
                        noAnimation: true,
                    })
                );
                resultAction = await dispatch(createCategory({ ...values }));
                if (!resultAction.error) {
                    resetForm();
                    if (categoryNameRef.current) {
                        categoryNameRef.current.focus();
                    }
                }
                break;
            default:
                break;
        }
        if (resultAction?.error) {
            enqueueSnackbar(resultAction?.error?.message, {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: 'error',
            });
        } else {
            const { message, variant } = snackbarHandler(
                resultAction.meta.requestStatus,
                formAction.label
            );
            enqueueSnackbar(message, {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: variant,
            });
        }
        dispatch(deactivateLoading());
        setSubmitting(false);
    };
    //#endregion Methods

    //#region Render time calcs
    //#endregion Render time calcs

    //#region Render
    return !loaded ? (
        <LoadingLogo styleClassName={classes.loadingSvg} />
    ) : (
        <Formik
            enableReinitialize={true}
            initialValues={{ ...initValues }}
            validationSchema={Yup.object({
                display_name: Yup.string()
                    .max(100, 'Must be 100 characters or less')
                    .required('Required'),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                handleSubmit(values, setSubmitting, resetForm);
            }}
        >
            {({ isSubmitting }) => (
                <Form className={classes.form}>
                    <div className={classes.formHeader}>Category Details</div>
                    <FormTextField
                        inputRef={categoryNameRef}
                        autoFocus
                        label="Category Name"
                        name="display_name"
                        placeholder="Category Name"
                    />
                    <SetDirtyForm />
                    <button
                        className={classes.submit}
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {formAction.buttonLabel}
                    </button>
                </Form>
            )}
        </Formik>
    );
    //#endregion Render
};

export { CategoryForm };
