import { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { FormActions } from '_helpers/form-action';
import { snackbarHandler } from 'GeminiViewerComponent/_helpers/snackbar-handler';

import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import SetDirtyForm from 'forms/SetDirtyForm';
import { closePanel } from '_features/common/formSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { LoadingLogo } from 'GeminiViewerComponent/components/LoadingLogo/LoadingLogo';
import { FormTextField } from 'components/TextField';
import { makeFormStyles } from 'forms/styles';
import {
    Box,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@mui/material';
import { SelectionAutocomplete } from 'components';
import {
    fetchAllAssets,
    resetAssetsTableState,
    selectAssetPage,
    selectAssetsLoadingStatus,
} from '_features/assets/assetsTableSlice';
import {
    addManagementDeployment,
    editManagementDeployment,
    fetchDeployementById,
} from '_features/jobs/jobsSlice';
import { useConfigValues } from 'hooks/useConfigValues';
import { accountsSlice } from 'app/store';
import { selectConfigValuesByFields } from '_features/configValues/configValuesSlice';

const DeploymentPackageForm = ({ formAction, deploymentId }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [loaded, setLoaded] = useState(
        formAction.id === FormActions.Create.id ? true : false
    );
    const INIT_VALUES = {
        name: '',
        viewer_authentication_id: 1,
        include_gais: false,
        include_gaps: false,
        include_landing_json: false,
        asset_ids: [],
    };
    const theme = useSelector(selectActiveTheme);
    const activeUser = useSelector(accountsSlice.selectActiveUser);
    const classes = makeFormStyles(theme);
    const [initValues, setInitValues] = useState({ ...INIT_VALUES });
    const { configValues, configFields } = useConfigValues({
        clientId: activeUser?.client_id,
    });

    const {
        GaisApplicationAvailable: gaisApplicationAvailable,
        GapsApplicationAvailable: gapsApplicationAvailable,
        GaisAuthenticationEnabled: gaisAuthenticationEnable
    } = selectConfigValuesByFields(configValues, configFields, [
        'GaisApplicationAvailable',
        'GapsApplicationAvailable',
        'GaisAuthenticationEnabled'
    ]);

    useEffect(() => {
        if (formAction.id === FormActions.Edit.id) {
            const fetchData = async () => {
                var objectData = await dispatch(
                    fetchDeployementById(deploymentId)
                );
                var fetchedObject = objectData.payload;
                var assetId = fetchedObject?.assets.map(
                    (item) => item.asset_id
                );
                setInitValues({
                    name: fetchedObject?.name,
                    viewer_authentication_id:
                        fetchedObject?.viewer_authentication_id,
                    include_gais: fetchedObject?.include_gais,
                    include_gaps: fetchedObject?.include_gaps,
                    include_landing_json: fetchedObject?.include_landing_json,
                    asset_ids: assetId,
                });
                setLoaded(true);
            };
            fetchData();
        } else {
            setInitValues({ ...INIT_VALUES });
        }
    }, [deploymentId]);

    useEffect(() => {
        return () => {
            dispatch(resetAssetsTableState);
        };
    }, []);

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        dispatch(activateLoading({ showProgress: true }));
        var resultAction = null;

        switch (formAction.id) {
            case FormActions.Edit.id:
                resultAction = await dispatch(
                    editManagementDeployment({
                        deploymentId,
                        packageData: { ...values },
                    })
                );
                break;
            case FormActions.Create.id:
                resultAction = await dispatch(
                    addManagementDeployment({ ...values })
                );
                resetForm();
                break;
            default:
                break;
        }
        dispatch(deactivateLoading());
        await dispatch(closePanel({ formKey: 'managementDeployForm' }));
        const { message, variant } = snackbarHandler(
            resultAction.meta.requestStatus,
            formAction.label
        );
        enqueueSnackbar(message, {
            action: (key) => <SnackbarDismiss key={key} />,
            variant: variant,
        });
        setSubmitting(false);
    };

    return !loaded ? (
        <LoadingLogo styleClassName={classes.loadingSvg} />
    ) : (
        <Formik
            enableReinitialize={true}
            initialValues={{ ...initValues }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                handleSubmit(values, setSubmitting, resetForm);
            }}
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Form className={classes.form}>
                    <div className={classes.formHeader}>
                        Deployment Package Details
                    </div>
                    <FormTextField
                        label="Name"
                        name="name"
                        placeholder="Name"
                    />
                    <RadioGroup
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '150px',
                        }}
                        name="viewer_authentication_id"
                        value={values.viewer_authentication_id}
                        onChange={(e) => {
                            setFieldValue(
                                'viewer_authentication_id',
                                parseInt(e.target.value)
                            );
                        }}
                    >
                        <FormControlLabel
                            value={1}
                            control={<Radio color="primary" />}
                            label="Anonymous"
                        />
                        {gaisAuthenticationEnable === 'true' && (
                            <FormControlLabel
                                value={2}
                                control={<Radio color="primary" />}
                                label="GAIS"
                            />
                        )}
                    </RadioGroup>
                    {gaisApplicationAvailable === 'true' && (
                        <FormTextField
                            component={CheckboxWithLabel}
                            Label={{ label: 'Include GAIS' }}
                            type="checkbox"
                            checked={!!values?.include_gais}
                            placeholder="Include GAIS"
                            indeterminate={
                                values?.include_gais === undefined
                                    ? true
                                    : false
                            }
                            onChange={(e) => {
                                setFieldValue('include_gais', e.target.checked);
                            }}
                        />
                    )}
                    {gapsApplicationAvailable === 'true' && (
                        <FormTextField
                            component={CheckboxWithLabel}
                            Label={{ label: 'Include GAPS' }}
                            type="checkbox"
                            checked={!!values?.include_gaps}
                            placeholder="Include GAPS"
                            indeterminate={
                                values?.include_gaps === undefined
                                    ? true
                                    : false
                            }
                            onChange={(e) => {
                                setFieldValue('include_gaps', e.target.checked);
                            }}
                        />
                    )}
                    <FormTextField
                        component={CheckboxWithLabel}
                        Label={{ label: 'Include Landing Screen' }}
                        type="checkbox"
                        checked={!!values?.include_landing_json}
                        placeholder="Include Landing Screen"
                        indeterminate={
                            values?.include_landing_json === undefined
                                ? true
                                : false
                        }
                        onChange={(e) => {
                            setFieldValue(
                                'include_landing_json',
                                e.target.checked
                            );
                        }}
                    />
                    <Box display="flex" flexDirection="column" gap="10px">
                        <FormControl>
                            <Field name={'asset_ids'}>
                                {({
                                    field: { value },
                                    form: { setFieldValue },
                                }) => (
                                    <SelectionAutocomplete
                                        title="Asset"
                                        placeholder="Asset"
                                        keyProperty="asset_id"
                                        nameProperty="display_name"
                                        entityIds={value}
                                        setEntityIds={setFieldValue}
                                        entitySelector={selectAssetPage}
                                        entityStatusSelector={
                                            selectAssetsLoadingStatus
                                        }
                                        fetchEntityPage={fetchAllAssets}
                                        formField={'asset_ids'}
                                        multiSelection
                                        fetchParams={{
                                            lastPublishedDate: 'last_published_date!=null',
                                        }}
                                    />
                                )}
                            </Field>
                        </FormControl>
                    </Box>
                    <SetDirtyForm />
                    <button
                        className={classes.submit}
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {formAction.buttonLabel}
                    </button>
                </Form>
            )}
        </Formik>
    );
};

export { DeploymentPackageForm };
