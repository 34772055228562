import { useStyles } from './styles';
import { useDispatch } from 'react-redux';
import { MdArrowForwardIos } from 'react-icons/md';

import { loadItem } from '_features/common/editItemSlice';
import { loadEditZone } from '_features/common/editZoneSlice';
import { setSnippet } from '_features/snippets/snippetsSlice';
import { setFieldPreview } from '_features/procedures/proceduresSlice';
import { openPanel, setEditObjectId } from '_features/common/formSlice';
import getActionIcon from 'GeminiViewerComponent/_helpers/getActionIcon';
import { showActionModal } from 'GeminiViewerComponent/_features/common/actionModalSlice';

export default function PopupAction({
    action,
    object,
    level,
    showLabel,
    onActionHandled,
    activeUser = null,
}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleActionModal = () => {
        dispatch(
            showActionModal({
                isOpen: true,
                object: object,
                action: action,
                level: level,
            })
        );
    };

    const handleAction = async (e) => {
        switch (action.label) {
            case 'createContentVersion':
                // dispatch(showContentVersionCreatePanel({content: object}))
                dispatch(
                    setEditObjectId({
                        formKey: `contentVersionForm`,
                        object_id: object[`content_id`],
                    })
                );
                dispatch(
                    openPanel({
                        formKey: `contentVersionForm`,
                        formAction: 'Create',
                    })
                );
                break;
            case 'delete':
                if (level === 'formField') {
                    dispatch(
                        setFieldPreview({
                            previewAction: action.label,
                            field: object,
                        })
                    );
                } else handleActionModal();
                break;
            case 'deactivate':
                handleActionModal();
                break;
            case 'edit':
                // if (level === 'user') {
                //     dispatch(showEditPanel({viewPanel: true, object_id: null, user_id: object.user_id, content_id: null, level: level, }));
                // }
                // else if (level === 'asset') {
                //     dispatch(showEditPanel({viewPanel: true, object_id: object[level+'_id'], user_id: null, content_id: null, level: level}));
                // }
                // else {
                if (level === 'zone') {
                    dispatch(loadEditZone(object[`${level}_id`]));
                    dispatch(
                        openPanel({ formKey: 'zoneForm', formAction: 'Edit' })
                    );
                } else if (level === 'item') {
                    dispatch(
                        loadItem({
                            zoneId: object.zone_id,
                            itemId: object[`${level}_id`],
                        })
                    );
                    dispatch(
                        openPanel({ formKey: 'itemForm', formAction: 'Edit' })
                    );
                } else if (level === 'asset' && object?.asset_type_id === 3) {
                    dispatch(
                        setEditObjectId({
                            formKey: `procedureForm`,
                            object_id: object[`${level}_id`],
                        })
                    );
                    dispatch(
                        openPanel({
                            formKey: 'procedureForm',
                            formAction: 'Edit',
                            assetTypeID: object?.asset_type_id,
                        })
                    );
                } else if (level === 'itemTag') {
                    dispatch(
                        setEditObjectId({
                            formKey: `itemTagForm`,
                            object_id: object['tag_id'],
                        })
                    );
                    dispatch(
                        openPanel({
                            formKey: 'itemTagForm',
                            formAction: 'Edit',
                        })
                    );
                } else if (level === 'tool_category') {
                    dispatch(
                        setEditObjectId({
                            formKey: `toolCategoryForm`,
                            object_id: object['tool_category_id'],
                        })
                    );
                    dispatch(
                        openPanel({
                            formKey: 'toolCategoryForm',
                            formAction: 'Edit',
                        })
                    );
                } else if (level === 'tool_location') {
                    dispatch(
                        setEditObjectId({
                            formKey: `locationForm`,
                            object_id: object['tool_location_id'],
                        })
                    );
                    dispatch(
                        openPanel({
                            formKey: 'locationForm',
                            formAction: 'Edit',
                        })
                    );
                } else if (level === 'tool') {
                    dispatch(
                        setEditObjectId({
                            formKey: `toolForm`,
                            object_id: object['tool_id'],
                        })
                    );
                    dispatch(
                        openPanel({ formKey: 'toolForm', formAction: 'Edit' })
                    );
                } else if (level === 'hotspot_icon') {
                    dispatch(
                        setEditObjectId({
                            formKey: `hotspotForm`,
                            object_id: object[`${level}_id`],
                        })
                    );
                    dispatch(
                        openPanel({
                            formKey: 'hotspotForm',
                            formAction: 'Edit',
                        })
                    );
                } else if (level === 'part_group') {
                    dispatch(
                        setEditObjectId({
                            formKey: `partGroupForm`,
                            object_id: object[`${level}_id`],
                        })
                    );
                    dispatch(
                        openPanel({
                            formKey: 'partGroupForm',
                            formAction: 'Edit',
                        })
                    );
                } else if (level === 'part_category') {
                    dispatch(
                        setEditObjectId({
                            formKey: `partCategoryForm`,
                            object_id: object[`${level}_id`],
                        })
                    );
                    dispatch(
                        openPanel({
                            formKey: 'partCategoryForm',
                            formAction: 'Edit',
                        })
                    );
                } else if (level === 'part') {
                    dispatch(
                        setEditObjectId({
                            formKey: `partForm`,
                            object_id: object[`${level}_id`],
                        })
                    );
                    dispatch(
                        openPanel({
                            formKey: 'partForm',
                            formAction: 'Edit',
                        })
                    );
                } else if (level === 'formField') {
                    dispatch(
                        setFieldPreview({
                            previewAction: action.label,
                            field: object,
                        })
                    );
                } else if (level === 'companion_app') {
                    dispatch(
                        setEditObjectId({
                            formKey: `companionForm`,
                            object_id: object[`${level}_id`],
                        })
                    );
                    dispatch(
                        openPanel({
                            formKey: 'companionForm',
                            formAction: 'Edit',
                        })
                    );
                } else if (level === 'news_flash') {
                    dispatch(
                        setEditObjectId({
                            formKey: `newsFlashForm`,
                            object_id: object[`${level}_id`],
                        })
                    );
                    dispatch(
                        openPanel({
                            formKey: 'newsFlashForm',
                            formAction: 'Edit',
                        })
                    );
                } else if (level === 'category') {
                    dispatch(
                        setEditObjectId({
                            formKey: `categoryForm`,
                            object_id: object[`${level}_id`],
                        })
                    );
                    dispatch(
                        openPanel({
                            formKey: 'categoryForm',
                            formAction: 'Edit',
                        })
                    );
                } else if (level === 'snippet') {
                    dispatch(setSnippet(object?.snippet));
                    object?.setSelected(true);
                } else if (level === 'managementDeploy') {
                    await dispatch(
                        setEditObjectId({
                            formKey: `managementDeployForm`,
                            object_id: object?.deployment_package_id,
                        })
                    );
                    dispatch(
                        openPanel({
                            formKey: 'managementDeployForm',
                            formAction: 'Edit',
                        })
                    );
                } else {
                    dispatch(
                        setEditObjectId({
                            formKey: `${
                                level === 'user' ? 'account' : level
                            }Form`,
                            object_id: object[`${level}_id`],
                        })
                    );
                    dispatch(
                        openPanel({
                            formKey: `${
                                level === 'user' ? 'account' : level
                            }Form`,
                            formAction: 'Edit',
                        })
                    );
                }
                //}
                break;
            case 'duplicate':
                if (level === 'formField') {
                    dispatch(
                        setFieldPreview({
                            previewAction: action.label,
                            field: object,
                        })
                    );
                } else handleActionModal();
                break;
            case 'publish':
                handleActionModal();
                break;
            case 'export':
                handleActionModal();
                break;
            case 'exportAsset':
                dispatch(
                    setEditObjectId({
                        formKey: `assetExportForm`,
                        object_id: object[`asset_id`],
                    })
                );
                dispatch(
                    openPanel({
                        formKey: `assetExportForm`,
                        formAction: 'Create',
                    })
                );
                break;
            case 'publishArray':
                handleActionModal();
                break;
            case 'deleteArray':
                handleActionModal();
                break;
            case 'reactivate':
                handleActionModal();
                break;
            case 'playback': {
                if (object?.openPopover && level === 'asset') {
                    object.openPopover(e);
                }
                break;
            }
            case 'category': {
                if (object?.openPopover) {
                    object.openPopover(e);
                }
                break;
            }
            case 'unposition':
                object.Unposition();
                break;
            case 'resetposition':
                object.ResetPosition();
                break;
            default:
                break;
        }
        if (onActionHandled) {
            onActionHandled();
        }
    };

    return (
        <div className={classes.popupActionContainer} onClick={handleAction}>
            <div className={classes.popupActionIcon}>
                {getActionIcon(action.iconId)}
            </div>
            {showLabel && (
                <div className={classes.popupActionLabel}>
                    {action.displayLabel}
                </div>
            )}
            {object?.openPopover && level === 'asset' && (
                <MdArrowForwardIos style={{ marginLeft: 'auto' }} />
            )}
        </div>
    );
}
