export const loadDynamicScript = (scripts, callback) => {
    if (scripts.length === 0) callback();
    var numScripts = 0;
    if (scripts.length === 0) {
        callback();
    }
    scripts.forEach((url, index) => {
        const existingScript = document.getElementById(`script${index}`);

        if (!existingScript) {
            const script = document.createElement('script');
            script.src = url;
            script.id = `script${index}`;
            if (index === 0) {
                document.body.insertBefore(script, document.body.firstChild);
            } else {
                insertAfter(
                    script,
                    document.getElementById(`script${index - 1}`)
                );
            }

            script.onload = () => {
                numScripts += 1;
                if (callback && numScripts === scripts.length) callback();
            };
        }

        if (existingScript && callback) callback();
    });
};

function insertAfter(newNode, referenceNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}

const loadScript = (url, onLoaded) => {
    const script = document.createElement('script');
    document.body.appendChild(script);
    if (onLoaded !== undefined) {
        script.onload = onLoaded;
    }
    script.async = false;
    script.src = url;
};

const loadLink = (url, onLoaded, elementId) => {
    const link = document.createElement('link');
    document.head.appendChild(link);
    if (onLoaded !== undefined) {
        link.onload = onLoaded;
    }
    link.href = url;
    link.rel = 'stylesheet';
    if (elementId) {
        link.id = elementId;
    }
};

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const timeToSeconds = (timeStr = '0') => {
    const [hours, minutes, seconds] = timeStr.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
};

const convertSecondstoTime = (given_seconds) => {
    const hours = Math.floor(given_seconds / 3600);
    const minutes = Math.floor((given_seconds - hours * 3600) / 60);
    const seconds = given_seconds - hours * 3600 - minutes * 60;
    const timeString =
        hours.toString().padStart(2, '0') +
        ':' +
        minutes.toString().padStart(2, '0') +
        ':' +
        seconds.toFixed(3).toString().padStart(6, '0');
    return timeString;
};

export { loadScript, loadLink, getBase64, timeToSeconds, convertSecondstoTime };
