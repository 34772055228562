import React from 'react';
import { Box, Checkbox } from '@mui/material';

const ImageAttachmentField = ({ value, setFieldValue }) => {
    return (
        <Box>
            <Checkbox
                checked={Boolean(value)}
                onClick={(e) =>
                    setFieldValue('image_attachments_allow', e.target.checked)
                }
            />
            Allow Photo attachments
        </Box>
    );
};

export default ImageAttachmentField;
