import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import clsx from 'clsx';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import {
    MdCheck,
    MdCheckBox,
    MdCheckBoxOutlineBlank,
    MdClear,
    MdRefresh,
} from 'react-icons/md';

import { deleteKey } from 'GeminiViewerComponent/_helpers/cacheStore';
import {
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Button,
    DialogTitle,
    Paper,
} from '@mui/material';
import { autoCompleteStyles } from 'GeminiViewerComponent/components/styles';
import Loader from 'GeminiViewerComponent/components/Loader';
import { makeFormStyles } from '../../../forms/styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { MultiSelectDoneButton } from 'GeminiViewerComponent/components/MultiSelectDoneButton';
import { selectTags } from '_features/tags/tagsSlice';
import {
    useUniqueTagsName,
    useUniqueZoneCategory,
} from 'hooks/useUniqueTagsName';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { useSnackbar } from 'notistack';

const icon = <MdCheckBoxOutlineBlank className="react-icon" fontSize="small" />;
const checkedIcon = <MdCheckBox className="react-icon" fontSize="small" />;
const filter = createFilterOptions();

const SelectionAutocomplete = ({
    title,
    keyProperty,
    nameProperty,
    entityIds,
    setEntityIds,
    entitySelector,
    entityStatusSelector,
    fetchEntityPage,
    fetchParams,
    formField,
    placeholder,
    multiSelection = true,
    errorMessage,
    touched,
    enableCreatable = false,
    creatableDialogTitle = '',
    creatableDialogFieldLabel = '',
    creatableDispatch,
    creatableParams = null,
    enableCache = false,
    refreshCacheType = '',
    extraOptions = [],
    readOnly = false,
    handleItemTagEdit = () => {},
    showCloseItemEdit = false,
    cancelItemTagEdit = () => {},
    size = '',
    showInitialValuesAlways = false,
    onCustomChange = function () {},
    fieldValidationLength = null,
    fieldValidation = false,
    freeSolo = true,
}) => {
    const DIALOG_INITIAL_STATE = {
        isDialogOpen: false,
        [nameProperty]: '',
    };

    const theme = useSelector(selectActiveTheme);
    const { enqueueSnackbar } = useSnackbar();
    const formClasses = makeFormStyles(theme);
    const classes = autoCompleteStyles(theme);
    const dispatch = useDispatch();
    let entities = useSelector(entitySelector);
    entities = [...extraOptions, ...entities];
    const entityStatus = useSelector((state) => entityStatusSelector(state));
    const entitiesByIdMap = Object.create(null);
    const [isLoading, setIsLoading] = useState(false);
    const [manageDialog, setManageDialog] = useState(DIALOG_INITIAL_STATE);
    const [errors, setErrors] = useState(null);
    const tags = useSelector(selectTags);

    const validateField = (value) => {
        if (!value) {
            setErrors('Required');
        } else {
            setErrors(null);
            if (
                creatableDialogTitle === 'category' ||
                creatableDialogTitle === 'zone category' ||
                creatableDialogTitle === 'tool category'
            ) {
                if (
                    fieldValidationLength &&
                    value.length > fieldValidationLength
                ) {
                    setErrors(
                        `Must be ${fieldValidationLength} characters or less`
                    );
                } else if (useUniqueZoneCategory(entities, value)) {
                    setErrors(`Must be unique ${creatableDialogTitle} name`);
                }
            }
            if (creatableDialogTitle === 'tag') {
                if (
                    fieldValidationLength &&
                    value.length > fieldValidationLength
                ) {
                    setErrors(
                        `Must be ${fieldValidationLength} characters or less`
                    );
                } else if (useUniqueTagsName(tags, value)) {
                    setErrors('Must be unique tag name');
                }
            }
        }
    };
    useEffect(() => {
        validateField(manageDialog[nameProperty]);
    }, [manageDialog]);

    entities.forEach((row) => {
        if (!entitiesByIdMap[row[keyProperty]]) {
            entitiesByIdMap[row[keyProperty]] = row;
        }
    });

    const getDefaultValue = () => {
        if (multiSelection) {
            const selectedValue = [];
            entityIds?.forEach((item) => {
                if (entitiesByIdMap[item]) {
                    selectedValue.push(entitiesByIdMap[item]);
                }
            });
            return selectedValue || [];
        } else {
            return entitiesByIdMap[entityIds] || '';
        }
    };

    const handleChange = (newValue) => {
        if (!multiSelection) {
            if (enableCreatable) {
                if (typeof newValue === 'string') {
                    // timeout to avoid instant validation of the dialog's form.
                    setTimeout(() => {
                        setManageDialog({
                            [nameProperty]: newValue,
                            keyProperty: keyProperty,
                            isDialogOpen: true,
                        });
                    });
                } else if (newValue && newValue.inputValue) {
                    setManageDialog({
                        [nameProperty]: newValue.inputValue,
                        keyProperty: keyProperty,
                        isDialogOpen: true,
                    });
                }
            }
            setEntityIds(formField, newValue ? newValue[keyProperty] : '');
        } else {
            if (enableCreatable) {
                const newValueObj = newValue[newValue.length - 1];
                if (typeof newValueObj === 'string') {
                    // timeout to avoid instant validation of the dialog's form.
                    setTimeout(() => {
                        setManageDialog({
                            [nameProperty]: newValueObj,
                            keyProperty: keyProperty,
                            isDialogOpen: true,
                        });
                    });
                } else if (newValueObj && newValueObj.inputValue) {
                    setManageDialog({
                        [nameProperty]: newValueObj.inputValue,
                        keyProperty: keyProperty,
                        isDialogOpen: true,
                    });
                }
            }
            setEntityIds(
                formField,
                newValue
                    ?.filter((item) => item[keyProperty] != undefined)
                    .map((item) => (item ? item[keyProperty] : null))
            );
        }
    };

    const onRefreshIconClick = () => {
        deleteKey(refreshCacheType);
        dispatch(fetchEntityPage({ ...fetchParams }));
    };

    const filterOption = (options, params) => {
        const filtered = filter(options, params);
        if (enableCreatable && params?.inputValue !== '') {
            const isExisting = options.some(
                (option) => params?.inputValue === option[nameProperty]
            );
            if (params?.inputValue !== '' && !isExisting) {
                filtered.push({
                    inputValue: params.inputValue,
                    [nameProperty]: `Add "${params.inputValue}"`,
                });
            }
        }
        return filtered;
    };

    const handleSubmit = async () => {
        const result = await dispatch(
            creatableDispatch(
                creatableParams
                    ? { ...manageDialog, ...creatableParams }
                    : manageDialog
            )
        );
        if (!result.error) {
            const newId = result.payload[keyProperty];
            const updatedIds = multiSelection
                ? [...entityIds.filter((id) => id !== undefined), newId]
                : newId;
            setEntityIds(formField, updatedIds);
        } else {
            enqueueSnackbar(result?.error?.message, {
                action: (key) => <SnackbarDismiss snackItem={key} />,
                variant: 'error',
            });
            return;
        }
        setManageDialog(DIALOG_INITIAL_STATE);
    };

    useEffect(() => {
        if (entityStatus === LoadingStatus.Idle) {
            setIsLoading(true);
            dispatch(fetchEntityPage({ ...fetchParams }));
        }
        if (entityStatus === LoadingStatus.Loaded) {
            setIsLoading(false);
        }
    }, [entityStatus, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    return isLoading ? (
        <Loader
            styles={{
                width: '100%',
                marginTop: '8px',
                marginBottom: '4px',
                height: '56px',
            }}
            enableBorder
        />
    ) : (
        <>
            <div className={`${classes.root}`}>
                <Autocomplete
                    size={size}
                    multiple={multiSelection}
                    readOnly={readOnly}
                    limitTags={2}
                    id="checkboxes-tags-demo"
                    name={formField}
                    onChange={(event, newValue) => {
                        if (showInitialValuesAlways === false) {
                            handleChange(newValue);
                            onCustomChange(newValue);
                        }
                    }}
                    disableClearable={showCloseItemEdit}
                    options={entities}
                    disableCloseOnSelect={multiSelection}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return option[nameProperty];
                    }}
                    value={getDefaultValue()}
                    renderOption={(props, option, { selected }) => (
                        <li {...props} key={option[keyProperty]}>
                            {multiSelection && (
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                            )}
                            {option[nameProperty] !== ''
                                ? option[nameProperty]
                                : '-'}
                        </li>
                    )}
                    renderInput={(params) => (
                        <>
                            <TextField
                                {...params}
                                error={fieldValidation}
                                label={title}
                                className={clsx([
                                    errorMessage && touched
                                        ? classes.errorField
                                        : '',
                                    enableCache && showCloseItemEdit === false
                                        ? classes.clearIndicator
                                        : '',
                                ])}
                                placeholder={
                                    entityIds?.length > 0 ? '' : placeholder
                                }
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                    ...params.inputProps,
                                    onKeyDown: (e) => {
                                        if (e.key === 'Enter') {
                                            handleItemTagEdit();
                                        }
                                        if (e.key === 'Escape') {
                                            cancelItemTagEdit();
                                        }
                                    },
                                }}
                            />
                            {enableCache && (
                                <IconButton
                                    onClick={onRefreshIconClick}
                                    aria-label="close"
                                    sx={{
                                        position: 'absolute',
                                        top: '7px',
                                        right: 0,
                                        color: '#707070',
                                    }}
                                    size="large"
                                >
                                    <MdRefresh
                                        className="react-icon"
                                        style={{ fontSize: '18px' }}
                                    />
                                </IconButton>
                            )}
                            {showCloseItemEdit && (
                                <>
                                    <IconButton
                                        onClick={(e) => handleItemTagEdit()}
                                        aria-label="close"
                                        sx={{
                                            right: '20px',
                                            position: 'absolute',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                        }}
                                        size="large"
                                    >
                                        <MdCheck
                                            className="react-icon"
                                            style={{ fontSize: '18px' }}
                                        />
                                    </IconButton>
                                    <IconButton
                                        onClick={(e) => cancelItemTagEdit()}
                                        aria-label="close"
                                        sx={{
                                            position: 'absolute',
                                            right: '0px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                        }}
                                        size="large"
                                    >
                                        <MdClear
                                            className="react-icon"
                                            style={{ fontSize: '18px' }}
                                        />
                                    </IconButton>
                                </>
                            )}
                        </>
                    )}
                    filterOptions={(options, params) => {
                        return filterOption(options, params);
                    }}
                    freeSolo={freeSolo}
                    PaperComponent={
                        multiSelection ? MultiSelectDoneButton : Paper
                    }
                />
            </div>

            <Dialog
                open={manageDialog?.isDialogOpen}
                onClose={() =>
                    setManageDialog({ ...manageDialog, isDialogOpen: false })
                }
            >
                <DialogTitle>Add a new {creatableDialogTitle}</DialogTitle>
                <DialogContent sx={{ width: '400px' }}>
                    <DialogContentText marginBottom={'5px'}>
                        Add a new item to available {creatableDialogTitle} list.
                    </DialogContentText>
                    <TextField
                        fullWidth
                        autoFocus
                        margin="dense"
                        id="name"
                        value={manageDialog[nameProperty]}
                        onChange={(event) => {
                            validateField(event.target.value);
                            setManageDialog({
                                ...manageDialog,
                                [nameProperty]: event.target.value,
                                keyProperty: keyProperty,
                            });
                        }}
                        label={creatableDialogFieldLabel}
                        type="text"
                        variant="outlined"
                        error={!!errors}
                        helperText={errors}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        className={formClasses.cancel}
                        onClick={() =>
                            setManageDialog({
                                ...manageDialog,
                                isDialogOpen: false,
                            })
                        }
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            if (!errors) {
                                handleSubmit();
                            }
                        }}
                        className={formClasses.submit}
                        type="button"
                    >
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export { SelectionAutocomplete };
