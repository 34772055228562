import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './styles';
import { AutoSizer } from 'react-virtualized';
import { MdAdd } from 'react-icons/md';
import { IconButton, Tooltip } from '@mui/material';

import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';
import { Action } from '_helpers';
import {
    activeCell,
    labelHeader,
    labelCell,
    actionCell,
    checkCell,
    checkHeaderCell,
    VirtualTable,
} from 'components';
import { tableStyles } from 'components/_Tables/TableCells/styles';
import { EnhancedTableToolbar } from 'GeminiViewerComponent/components/EnhancedTableToolbar';
import {
    closePanel,
    openPanel,
    setEditObjectId,
} from '_features/common/formSlice';
import SearchWithFilters from 'components/SearchWithFilters';
import { makeButtonStyles } from '../../../../GeminiViewerComponent/components/styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { debounce } from 'GeminiViewerComponent/_helpers/lodashUtils';

function GroupTable({
    groups,
    pageInfo,
    groupPayload,
    setGroupPayload,
    sortDirection,
    sortBy,
    handleSort,
}) {
    const dispatch = useDispatch();
    const theme = useSelector(selectActiveTheme);
    const buttonStyles = makeButtonStyles(theme);
    const classes = useStyles();
    const tableClasses = tableStyles();
    const [selected, setSelected] = useState([]);
    const [selectedAll, setSelectedAll] = useState(false);

    const loadedRowsMap = useSelector((state) => state.groups.loadedGroupsMap);

    const headerHeight = 40;
    const rowHeight = 60;

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const prevSelectedAll = usePrevious(selectedAll);
    if (prevSelectedAll && selectedAll) {
        if (selected.length !== groups.length) {
            setSelected(groups.map((row) => row.group_id));
            setSelectedAll(true);
        }
    }

    const handleClick = (event, group_id) => {
        event.stopPropagation();
        const selectedIndex = selected.indexOf(group_id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, group_id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
        setSelectedAll(selected.length === groups.length);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelectedIds = groups.map((group) => group.group_id);
            setSelected(newSelectedIds);
            setSelectedAll(true);
            return;
        }
        setSelected([]);
        setSelectedAll(false);
    };

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const handleRowClick = async ({ rowData }) => {
        await dispatch(
            closePanel({
                formKey: 'groupForm',
            })
        );
        if (rowData?.group_id) {
            await dispatch(
                setEditObjectId({
                    formKey: `groupForm`,
                    object_id: rowData?.group_id,
                })
            );
            dispatch(
                openPanel({
                    formKey: `groupForm`,
                    formAction: 'Edit',
                })
            );
        }
    };

    const columns = [
        {
            classes: tableClasses,
            minWidth: 50,
            maxWidth: 50,
            width: 50,
            label: 'Select',
            dataKey: 'group_id',
            padding: 'checkbox',
            cellLevel: 'group',
            numSelected: selected.length,
            rowCount: groups.length,
            handleClick: handleClick,
            handleSelectAllClick: handleSelectAllClick,
            isSelected: isSelected,
            component: checkCell,
            headerComponent: checkHeaderCell,
        },
        {
            classes: tableClasses,
            minWidth: 50,
            maxWidth: 50,
            width: 50,
            label: '',
            dataKey: 'group_id',
            padding: 'none',
            actionCellLevel: 'group',
            targetPopup: 'groupManagement',
            formKey: 'groupForm',
            dispatch: dispatch,
            component: actionCell,
            headerComponent: labelHeader,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 120,
            minWidth: 120,
            label: 'Group',
            dataKey: 'display_name',
            padding: 'normal',
            component: labelCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 120,
            minWidth: 120,
            label: 'N° Users',
            dataKey: 'user_count',
            padding: 'normal',
            component: labelCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 120,
            minWidth: 120,
            label: 'N° Assets',
            dataKey: 'asset_count',
            padding: 'normal',
            component: labelCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 120,
            minWidth: 120,
            label: 'Active',
            dataKey: 'is_active',
            padding: 'normal',
            component: activeCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
    ];

    // eslint-disable-next-line no-unused-vars
    function loadMoreRows({ startIndex, stopIndex }) {}

    const debouncedSearch = useCallback(
        debounce((searchString) => {
            setGroupPayload({ ...groupPayload, searchString: searchString });
        }, 1000),
        [groupPayload]
    );

    const handleCreateNew = () => {
        dispatch(
            openPanel({
                formKey: 'groupForm',
                formAction: 'Create',
                clearEditId: true,
            })
        );
    };

    return (
        <div className={classes.root}>
            {selected.length > 0 && (
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    toolTipTitle={'Delete'}
                    totalCount={pageInfo.TotalCount}
                >
                    <PopupAction
                        action={Action.DeleteArray}
                        object={{
                            groupIds: selected,
                            setGroupIds: setSelected,
                        }}
                        level={'group'}
                        showLabel={false}
                    />
                </EnhancedTableToolbar>
            )}
            <div style={{ display: 'flex', gap: '5px' }}>
                <Tooltip title="Add New Account">
                    <IconButton
                        onClick={handleCreateNew}
                        size="large"
                        className={buttonStyles.contentAddButton}
                    >
                        <MdAdd className="react-icon" />
                    </IconButton>
                </Tooltip>
                <div style={{ flexGrow: 1 }}></div>
                <SearchWithFilters
                    filter={false}
                    onChange={(evt) => debouncedSearch(evt.target.value)}
                />
            </div>
            <div style={{ height: 'calc(100vh - 295px)', minWidth: '500px' }}>
                <AutoSizer>
                    {({ width, height }) => (
                        <VirtualTable
                            initialRows={groups}
                            allRows={groups}
                            totalRowCount={pageInfo.TotalCount}
                            loadedRowsMap={loadedRowsMap}
                            loadMoreRows={loadMoreRows}
                            columnDefinitions={columns}
                            cellClassName={tableClasses.flexContainer}
                            className={tableClasses.table}
                            rowClassName={tableClasses.flexContainer}
                            headerHeight={headerHeight}
                            width={width}
                            height={height}
                            rowHeight={rowHeight}
                            onRowClick={handleRowClick}
                        />
                    )}
                </AutoSizer>
            </div>
        </div>
    );
}

export { GroupTable };
