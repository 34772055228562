import { makeStyles } from '@mui/styles';

const dtContentStyles = makeStyles(() => ({
    dtContentContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    dtContent: {
        padding: '35px',
        fontSize: '18px',
        color: '#002A50',
    },
}));

export { dtContentStyles };
