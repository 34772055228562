import { useEffect, useRef } from 'react';
import { Field } from 'formik';
import { useSelector } from 'react-redux';
import {
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';

import { makeFormStyles } from '../../styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { FieldSource } from './ConfigSourceHeader';
import { VideoCaptionField } from './VideoCaptionField';

const VideoConfigs = ({
    editMode,
    defaultConfigValues,
    values,
    setIsVideoCompressionEnable,
    setFieldValue,
    configFields,
    validationTypes,
    readOnlyMode,
    getConfigOptionsArray,
}) => {
    const noOfFilesAllowed = useRef(0);
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);

    useEffect(() => {
        noOfFilesAllowed.current =
            getConfigOptionsArray(
                configFields,
                validationTypes,
                defaultConfigValues,
                'transcribe_language'
            ).length - 1;
        if (
            values?.transcribe_videos === true &&
            values?.transcribe_language &&
            values?.transcribe_language?.toLowerCase() !== 'auto detect'
        ) {
            noOfFilesAllowed.current = noOfFilesAllowed.current - 1;
        }
    }, [values, configFields, validationTypes, defaultConfigValues]);

    return !editMode || values?.raw_file ? (
        <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <FieldSource
                    source={defaultConfigValues.compress_videos.field_source}
                />
                <Checkbox
                    checked={values?.compress_videos}
                    onChange={(e) => {
                        setFieldValue('compress_videos', e.target.checked);
                        setIsVideoCompressionEnable(e.target.checked);
                    }}
                />
                {defaultConfigValues.compress_videos.display_name}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <FieldSource
                    source={defaultConfigValues.enable_hls.field_source}
                />
                <Checkbox
                    checked={values?.enable_hls}
                    onChange={(e) =>
                        setFieldValue('enable_hls', e.target.checked)
                    }
                />
                {defaultConfigValues.enable_hls.display_name}
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                }}
            >
                <FieldSource
                    source={defaultConfigValues.max_video_height.field_source}
                />
                <Field
                    className={classes.input}
                    name="max_video_height"
                    disabled={readOnlyMode}
                >
                    {({ field: { value }, form: { setFieldValue } }) => (
                        <FormControl sx={{ margin: '8px 0' }} fullWidth>
                            <InputLabel id="video-max-height-label">
                                {
                                    defaultConfigValues.max_video_height
                                        .display_name
                                }
                            </InputLabel>
                            <Select
                                labelId="video-max-height-label"
                                id="video-max-height-id"
                                label={
                                    defaultConfigValues.max_video_height
                                        .display_name
                                }
                                value={values?.max_video_height || ''}
                                onChange={(e) =>
                                    setFieldValue(
                                        'max_video_height',
                                        e.target.value
                                    )
                                }
                            >
                                {getConfigOptionsArray(
                                    configFields,
                                    validationTypes,
                                    defaultConfigValues,
                                    'max_video_height'
                                ).map((opt) => (
                                    <MenuItem
                                        key={`max_video_height${opt}`}
                                        value={opt}
                                    >
                                        {opt}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </Field>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <FieldSource
                    source={defaultConfigValues.transcribe_videos.field_source}
                />
                <Checkbox
                    checked={values?.transcribe_videos}
                    onChange={(e) =>
                        setFieldValue('transcribe_videos', e.target.checked)
                    }
                />
                Auto Transcribe Videos into captions
            </div>
            {values?.transcribe_videos === true && (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                    }}
                >
                    <FieldSource
                        source={
                            defaultConfigValues.transcribe_language.field_source
                        }
                    />
                    <Field
                        className={classes.input}
                        name="transcribe_language"
                        disabled={readOnlyMode}
                    >
                        {({ field: { value }, form: { setFieldValue } }) => (
                            <FormControl sx={{ margin: '8px 0' }} fullWidth>
                                <InputLabel id="transcribe-language-label">
                                    {
                                        defaultConfigValues.transcribe_language
                                            .display_name
                                    }
                                </InputLabel>
                                <Select
                                    labelId="transcribe-language-label"
                                    id="transcribe-language-id"
                                    label={
                                        defaultConfigValues.transcribe_language
                                            .display_name
                                    }
                                    value={values?.transcribe_language || ''}
                                    onChange={(e) => {
                                        setFieldValue(
                                            'transcribe_language',
                                            e.target.value
                                        );
                                        setFieldValue(
                                            'caption_languages',
                                            (Array.isArray(
                                                values?.caption_languages
                                            )
                                                ? values.caption_languages
                                                : []
                                            ).map((lang) =>
                                                lang === e.target.value
                                                    ? ''
                                                    : lang
                                            )
                                        );
                                    }}
                                >
                                    {getConfigOptionsArray(
                                        configFields,
                                        validationTypes,
                                        defaultConfigValues,
                                        'transcribe_language'
                                    ).map((opt) => (
                                        <MenuItem
                                            key={`transcribe_language${opt}`}
                                            value={opt}
                                        >
                                            {opt}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </Field>
                </div>
            )}
            <hr />
            <VideoCaptionField
                readOnlyMode={readOnlyMode}
                getConfigOptionsArray={getConfigOptionsArray}
                configFields={configFields}
                validationTypes={validationTypes}
                defaultConfigValues={defaultConfigValues}
            />
        </>
    ) : (
        <>
            <legend
                style={{
                    marginLeft: '10px',
                    padding: '3px',
                    fontSize: '14px',
                    color: '#6d7178',
                }}
            >
                Video Settings
            </legend>
            <VideoCaptionField
                readOnlyMode={readOnlyMode}
                getConfigOptionsArray={getConfigOptionsArray}
                configFields={configFields}
                validationTypes={validationTypes}
                defaultConfigValues={defaultConfigValues}
            />
        </>
    );
};

export { VideoConfigs };
