const useUniqueTagsName = (tags, name, id) => {
    let isNotUnique = null;
    if (id) {
        isNotUnique = tags.find(
            (tag) =>
                tag.name.toLowerCase() === name.toLowerCase() &&
                tag.tag_id !== id
        );
    } else {
        isNotUnique = tags.find(
            (tag) => tag.name.toLowerCase() === name.toLowerCase()
        );
    }
    return isNotUnique ? true : false;
};

const useUniqueZoneCategory = (catagory, name, id) => {
    let isNotUnique = null;
    if (id) {
        isNotUnique = catagory.find(
            (tag) =>
                tag.display_name.toLowerCase() === name.toLowerCase() &&
                tag.zone_category_id !== id
        );
    } else {
        isNotUnique = catagory.find(
            (tag) => tag.display_name.toLowerCase() === name.toLowerCase()
        );
    }
    return isNotUnique ? true : false;
};

export { useUniqueZoneCategory, useUniqueTagsName };
