import React, {
    useState,
    useEffect,
    useRef,
    forwardRef,
    useImperativeHandle,
    useCallback,
    Fragment,
    useMemo,
} from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import { ReactComponent as DragIcon } from './assets/icons/drag.svg';
import Animated from 'animated/lib/targets/react-dom';
import Hammer from 'hammerjs';
import { useSelector } from 'react-redux';

import useInterval from './hooks/useInterval';
import useTridiKeyPressHandler from './hooks/useTridiKeyPressHandler';
import ControlBar from './components/ControlBar';
import Pins from './components/Pins';
import StatusBar from './components/StatusBar';
import { Move, Spin, useHandleDragging } from './hooks/useHandleDragging';
import { ProcedureSplitViewTypes } from 'GeminiViewerComponent/_helpers';
import { getProcedureLoaded } from 'GeminiViewerComponent/_features/globals/visibilitySlice';
import { debounce } from 'GeminiViewerComponent/_helpers/lodashUtils';

const AnimatedDiv = Animated.div;
const LandscapeImage = 0;
const PortraitImage = 1;

class TridiUtils {
    static isValidProps = ({ images, format, location }) => {
        let isValid = true;
        if (!images && !format) {
            console.error(
                "'format' property is missing or invalid. Image format must be provided for 'numbered' property."
            );
            isValid = false;
        }
        if (images === 'numbered' && !location) {
            console.error(
                "'location' property is missing or invalid. Image location must be provided for 'numbered' property."
            );
            isValid = false;
        }
        return isValid;
    };

    static normalizedImages = (images, format, location, count, clearCache) => {
        if (images === 'numbered') {
            return Array.apply(null, { length: count }).map((_a, index) => {
                return `${location}/${
                    index + 1
                }.${format.toLowerCase()}?${clearCache}`;
            });
        }
        return images;
    };

    static uid = () => {
        return Date.now().toString(36) + Math.random().toString(36).substr(2);
    };
}

// eslint-disable-next-line react/display-name
const Tridi = forwardRef(
    (
        {
            className,
            style,
            images,
            clearCache,
            pins,
            keyFrames,
            startKeyFrame,
            pinWidth,
            pinHeight,
            setPins,
            format,
            location,
            count,
            draggable,
            hintOnStartup,
            hintText,
            autoplay,
            autoplaySpeed,
            stopAutoplayOnClick,
            stopAutoplayOnMouseEnter,
            resumeAutoplayOnMouseLeave,
            touch,
            mousewheel,
            resetOnToggleMove,
            inverse,
            setInverse,
            dragInterval,
            touchDragInterval,
            mouseleaveDetect,
            showControlBar,
            showStatusBar,
            renderPin,
            renderHint,
            zoom,
            onHintHide,
            onAutoplayStart,
            onAutoplayStop,
            onNextMove,
            onPrevMove,
            onNextFrame,
            onPrevFrame,
            onDragStart,
            onDragEnd,
            onFrameChange,
            onKeyFrameChange,
            onRecordStart,
            onRecordStop,
            onPinClick,
            onZoom,
            maxZoom,
            minZoom,
            hideRecord,
            onLoadChange,
            onContextClick, // IPS ADD
            useArrowKeys, // IPS ADD
            onClick,
            isMultipleHotspotEnabled,
            activeZone,
            limitImageHeight,
            onClickRefreshItem,
            splitViewMode,
        },
        ref
    ) => {
        const INIT_ANIMATED_VALUES = {
            x: new Animated.Value(0),
            y: new Animated.Value(0),
            zoom: new Animated.Value(zoom),
            originZoom: 1,
            originOffset: null,
            isZooming: false,
        };
        const [moveBuffer, setMoveBuffer] = useState([]);
        const [hintVisible, setHintVisible] = useState(hintOnStartup);
        const [currentImageIndex, setCurrentImageIndex] =
            useState(startKeyFrame);

        const [didDrag, setDidDrag] = useState(false);
        const [isDragging, setIsDragging] = useState(false);
        const [isAutoPlayRunning, setIsAutoPlayRunning] = useState(false);
        const [isRecording, setIsRecording] = useState(false);
        const [recordingSessionId, setRecordingSessionId] = useState(null);
        const [isPlaying, setIsPlaying] = useState(false);
        const [isMoveing, setIsMoveing] = useState(false);
        const [playReverse, setPlayReverse] = useState(inverse);
        const [currentKeyFrameIdx, setCurrentKeyFrameIdx] = useState(
            keyFrames.indexOf(startKeyFrame)
        );
        const isProcedureLoaded = useSelector((state) =>
            getProcedureLoaded(state)
        );

        const isPlayingRef = useRef(isAutoPlayRunning);
        const playReverseRef = useRef(playReverse);
        const { modeClickHandler, viewMode } = useHandleDragging(ref);

        const isPortrait = useRef(LandscapeImage);
        const isScalingCompleted = useRef(false);
        const setWidthOnZoomOut = useRef(false);
        const [heightOnResize, setHeightOnResize] = useState(false);

        useEffect(() => {
            let flat_image_width = activeZone?.flat_image_width;
            let flat_image_height = activeZone?.flat_image_height;
            if (
                typeof flat_image_width === 'number' &&
                typeof flat_image_height === 'number'
            ) {
                if (flat_image_width < flat_image_height) {
                    isPortrait.current = PortraitImage;
                } else {
                    isPortrait.current = LandscapeImage;
                }
            } else {
                isPortrait.current = LandscapeImage;
            }
        }, [activeZone]);

        useEffect(() => {
            setCurrentImageIndex(startKeyFrame);
            setCurrentKeyFrameIdx(keyFrames.indexOf(startKeyFrame));
        }, [startKeyFrame]);

        const AnimatedValues = useRef(INIT_ANIMATED_VALUES);

        useEffect(() => {
            if (viewMode === Spin) {
                AnimatedValues.current = INIT_ANIMATED_VALUES;
            }
        }, [viewMode]);

        const [viewerSize, setViewerSize] = useState(null);

        const _count = Array.isArray(images) ? images.length : Number(count);
        const _images = TridiUtils.normalizedImages(
            images,
            format,
            location,
            _count,
            clearCache
        );
        window.AnimatedValues = AnimatedValues.current;
        const _viewerImageRef = useRef(null);
        const _viewerZoomRef = useRef(null);
        const _draggable = !isRecording && draggable;
        const [loadedImagesCount, setLoadedImagesCount] = useState(0);

        const animatedDivStyle = useMemo(() => {
            return isScalingCompleted.current
                ? isPortrait.current === PortraitImage
                    ? { height: '100%' }
                    : setWidthOnZoomOut.current
                    ? {}
                    : { width: '100%' }
                : { height: '100%', width: '100%' };
        }, [
            isScalingCompleted.current,
            isPortrait.current,
            setWidthOnZoomOut.current,
        ]);

        const splitViewStyle = useMemo(() => {
            if (
                [
                    ProcedureSplitViewTypes.Above.id,
                    ProcedureSplitViewTypes.Below.id,
                ].includes(splitViewMode)
            ) {
                if (isProcedureLoaded) {
                    return { height: '100%', width: 'auto' };
                } else {
                    return { height: 'auto', width: 'auto' };
                }
            } else {
                return { height: 'auto', width: 'auto' };
            }
        }, [splitViewMode]);

        const viewerImageStyle = useMemo(() => {
            return isScalingCompleted.current
                ? {
                      ...(heightOnResize
                          ? {}
                          : isPortrait.current === PortraitImage
                          ? { height: '100%' }
                          : setWidthOnZoomOut.current
                          ? {}
                          : { width: '100%' }),
                  }
                : {};
        }, [
            isScalingCompleted.current,
            heightOnResize,
            isPortrait.current,
            setWidthOnZoomOut.current,
        ]);

        useEffect(() => {
            if (
                !isScalingCompleted.current &&
                ((isPortrait.current === LandscapeImage &&
                    activeZone?.flat_image_width &&
                    _viewerImageRef?.current?.clientWidth) ||
                    (isPortrait.current === PortraitImage &&
                        activeZone?.flat_image_height &&
                        _viewerImageRef?.current?.clientHeight))
            ) {
                setZoom(AnimatedValues.current.zoom._value + 0.02, false);
                isScalingCompleted.current = true;
            }
        }, [
            isScalingCompleted.current,
            document.body.clientWidth,
            document.body.clientHeight,
            _viewerImageRef?.current?.clientWidth,
            _viewerImageRef?.current?.clientHeight,
            activeZone?.flat_image_width,
            activeZone?.flat_image_height,
        ]);

        useEffect(() => {
            if (isScalingCompleted.current) {
                if (
                    isPortrait.current === LandscapeImage &&
                    document.body.clientWidth >
                        _viewerImageRef.current.clientWidth
                ) {
                    isScalingCompleted.current = false;
                } else {
                    if (
                        viewMode === Move &&
                        isPortrait.current === LandscapeImage
                    ) {
                        modeClickHandler();
                    }
                }
            }
        }, [isScalingCompleted.current]);

        useEffect(() => {
            const debouncedHandleResize = debounce(() => {
                setHeightOnResize(
                    isPortrait.current === PortraitImage &&
                        document?.body?.clientWidth ===
                            _viewerImageRef?.current?.clientWidth
                        ? true
                        : false
                );
            }, 100);

            window.addEventListener('resize', debouncedHandleResize);

            return (_) => {
                window.removeEventListener('resize', debouncedHandleResize);
            };
        });

        const smallestDistanceBetweenFrames = (
            currentFrame,
            targetFrame,
            count
        ) => {
            var distanceForward = 0;
            var distanceReverse = 0;
            if (currentFrame >= targetFrame) {
                distanceForward = currentFrame - targetFrame;
                distanceReverse = count - targetFrame + currentFrame;
            } else {
                distanceForward = count - targetFrame + currentFrame;
                distanceReverse = targetFrame - currentFrame;
            }

            if (distanceForward <= distanceReverse) {
                return distanceForward;
            } else {
                return -distanceReverse;
            }
        };

        const checkKeyFrame = (
            newIndex,
            isDragging,
            findClosest,
            reverse = false,
            dragEnd = false
        ) => {
            // If auto play is running then stop on next key frame
            if (
                !isDragging &&
                isPlayingRef.current &&
                newIndex === keyFrames[currentKeyFrameIdx]
            ) {
                toggleAutoplay(false);
                onKeyFrameChange(newIndex);
            } else {
                // If not dragging or end of dragging and auto play is off and stopped on a key frame then send event
                if (
                    dragEnd &&
                    !isPlayingRef.current &&
                    keyFrames.includes(newIndex) &&
                    currentImageIndex
                ) {
                    onKeyFrameChange(newIndex);
                }
                // If not dragging and auto play is off and stopped on a key frame then send event
                else if (
                    !isDragging &&
                    !isPlayingRef.current &&
                    newIndex === keyFrames[currentKeyFrameIdx]
                ) {
                    onKeyFrameChange(newIndex);
                }
                // If not dragging and auto play is off and didn't stop on key frame then move to closest key frame
                else if (
                    !isDragging &&
                    !isPlayingRef.current &&
                    keyFrames.length > 0 &&
                    newIndex !== keyFrames[currentKeyFrameIdx]
                ) {
                    if (findClosest == true) {
                        var closestIndex = 0;
                        var closestDistance = 0;

                        var closestFrame = keyFrames.reduce(function (
                            prev,
                            curr,
                            currentIndex
                        ) {
                            var currDistance = smallestDistanceBetweenFrames(
                                curr,
                                newIndex,
                                count
                            );
                            var prevDistance = smallestDistanceBetweenFrames(
                                prev,
                                newIndex,
                                count
                            );
                            if (
                                Math.abs(currDistance) < Math.abs(prevDistance)
                            ) {
                                closestIndex = currentIndex;
                                closestDistance = currDistance;
                                return curr;
                            } else {
                                closestDistance = prevDistance;
                                return prev;
                            }
                        });

                        if (closestFrame !== newIndex) {
                            setCurrentKeyFrameIdx(closestIndex);

                            reverse = closestDistance < 0;

                            playReverseRef.current = reverse;
                            setPlayReverse(reverse);
                            toggleAutoplay(true);
                        } else {
                            onKeyFrameChange(newIndex);
                        }
                    } else {
                        playReverseRef.current = reverse;
                        setPlayReverse(reverse);
                        toggleAutoplay(true);
                    }
                }
            }
        };

        const hideHint = () => {
            setHintVisible(false);
            onHintHide();
        };

        const nextFrame = useCallback(() => {
            const newIndex =
                currentImageIndex >= _count - 1 ? 0 : currentImageIndex + 1;
            if (isPlayingRef.current || isDragging) {
                setCurrentImageIndex(newIndex);
            }
            onNextFrame();
            onFrameChange(newIndex);
            checkKeyFrame(newIndex, isDragging, false, false);
        }, [
            _count,
            currentImageIndex,
            onFrameChange,
            onKeyFrameChange,
            onNextFrame,
            currentKeyFrameIdx,
            isDragging,
        ]);

        const prevFrame = useCallback(() => {
            const newIndex =
                currentImageIndex <= 0 ? _count - 1 : currentImageIndex - 1;
            if (isPlayingRef.current || isDragging) {
                setCurrentImageIndex(newIndex);
            }
            onPrevFrame();
            onFrameChange(newIndex);
            checkKeyFrame(newIndex, isDragging, false, true);
        }, [
            _count,
            currentImageIndex,
            onFrameChange,
            onKeyFrameChange,
            onPrevFrame,
            currentKeyFrameIdx,
            isDragging,
        ]);

        const nextMove = useCallback(() => {
            onNextMove();
            if (isPlayingRef.current) {
                return playReverseRef.current ? prevFrame() : nextFrame();
            } else {
                if (!isDragging) {
                    var nextKeyFrameIdx = currentKeyFrameIdx + 1;
                    if (nextKeyFrameIdx > keyFrames.length - 1) {
                        nextKeyFrameIdx = 0;
                    }
                    setCurrentKeyFrameIdx(nextKeyFrameIdx);
                    nextFrame();
                } else {
                    inverse ? prevFrame() : nextFrame();
                }
            }
        }, [
            isPlayingRef,
            playReverse,
            inverse,
            nextFrame,
            onNextMove,
            prevFrame,
            currentKeyFrameIdx,
            isDragging,
        ]);

        const prevMove = useCallback(() => {
            onPrevMove();
            if (isPlayingRef.current) {
                return playReverseRef.current ? nextFrame() : prevFrame();
            } else {
                if (!isDragging) {
                    var nextKeyFrameIdx = currentKeyFrameIdx - 1;
                    if (nextKeyFrameIdx < 0) {
                        nextKeyFrameIdx = keyFrames.length - 1;
                    }
                    setCurrentKeyFrameIdx(nextKeyFrameIdx);
                    prevFrame();
                } else {
                    inverse ? nextFrame() : prevFrame();
                }
            }
        }, [
            isPlayingRef,
            playReverse,
            inverse,
            nextFrame,
            onPrevMove,
            prevFrame,
            currentKeyFrameIdx,
            isDragging,
        ]);

        const rotateViewerImage = useCallback(
            (e) => {
                if (count <= 8) {
                    dragInterval = 15;
                    touchDragInterval = 20;
                }
                const interval = e.touches ? touchDragInterval : dragInterval;
                const eventX = e.touches
                    ? Math.round(e.touches[0].clientX)
                    : e.clientX;
                const coord = eventX - _viewerImageRef.current.offsetLeft;
                let newMoveBufffer = moveBuffer;
                if (moveBuffer.length < 2) {
                    newMoveBufffer = moveBuffer.concat(coord);
                } else {
                    newMoveBufffer = [moveBuffer[1], coord];
                }
                setMoveBuffer(newMoveBufffer);
                const threshold = !(coord % interval);
                const oldMove = newMoveBufffer[0];
                const newMove = newMoveBufffer[1];
                if (threshold && newMove < oldMove) {
                    nextMove();
                } else if (threshold && newMove > oldMove) {
                    prevMove();
                }
            },
            [dragInterval, moveBuffer, nextMove, prevMove, touchDragInterval]
        );

        const resetMoveBuffer = () => setMoveBuffer([]);

        const startDragging = useCallback(() => {
            setDidDrag(false);
            setIsDragging(true);
            onDragStart();
        }, [onDragStart]);

        const stopDragging = useCallback(() => {
            setIsDragging(false);
            if (didDrag) {
                checkKeyFrame(currentImageIndex, false, true, false, true);
                onDragEnd();
            }
            setDidDrag(false);
        }, [onDragEnd, isDragging, currentKeyFrameIdx, currentImageIndex]);

        const getViewerImageRef = useCallback((state) => {
            return _viewerImageRef;
        }, []);

        const getAnimatedValues = useCallback((state) => {
            return AnimatedValues;
        }, []);

        const viewCoordToImageCoord = useCallback((coord) => {
            const viewerOffsetLeft =
                _viewerImageRef.current.getBoundingClientRect().left;
            const viewerOffsetTop =
                _viewerImageRef.current.getBoundingClientRect().top;

            const offsetCoordX = coord.x - viewerOffsetLeft;
            const offsetCoordY = coord.y - viewerOffsetTop;

            const viewerWidth = _viewerImageRef.current.clientWidth;
            const viewerHeight = _viewerImageRef.current.clientHeight;
            const clientX =
                (offsetCoordX -
                    AnimatedValues.current.x._value *
                        AnimatedValues.current.zoom._value -
                    (viewerWidth -
                        viewerWidth * AnimatedValues.current.zoom._value) /
                        2) /
                AnimatedValues.current.zoom._value;
            const clientY =
                (offsetCoordY -
                    AnimatedValues.current.y._value *
                        AnimatedValues.current.zoom._value -
                    (viewerHeight -
                        viewerHeight * AnimatedValues.current.zoom._value) /
                        2) /
                AnimatedValues.current.zoom._value;

            const x = (clientX / viewerWidth).toFixed(6);
            const y = (clientY / viewerHeight).toFixed(6);
            return { x, y };
        }, []);

        const toggleAutoplay = useCallback(
            (state) => {
                setIsAutoPlayRunning(state);
                isPlayingRef.current = state;
                return state ? onAutoplayStart() : onAutoplayStop();
            },
            [onAutoplayStart, onAutoplayStop]
        );

        const toggleRecording = useCallback(
            (state, existingSessionId) => {
                setIsRecording(state);
                const sessionId =
                    recordingSessionId || existingSessionId || TridiUtils.uid();
                if (state) {
                    if (!recordingSessionId) {
                        setRecordingSessionId(sessionId);
                    }
                    onRecordStart(sessionId);
                } else {
                    setRecordingSessionId(null);
                    onRecordStop(sessionId);
                }
            },
            [onRecordStart, onRecordStop, recordingSessionId]
        );

        const resetPosition = useCallback(() => {
            AnimatedValues.current.x.setValue(0);
            AnimatedValues.current.y.setValue(0);
            AnimatedValues.current.zoom.setValue(zoom);
            if (pins) setPins(pins.map((pin) => ({ ...pin, scale: zoom })));
        }, [pins]);

        const toggleMoving = useCallback(
            (isMoving) => {
                if (isMoving) {
                    toggleRecording(false);
                    setIsMoveing(true);
                } else {
                    if (resetOnToggleMove) {
                        resetPosition();
                    }
                    setIsMoveing(false);
                }
            },
            [resetPosition, toggleRecording]
        );

        const setZoom = (zoom, allowChangeViewMode = true) => {
            if (zoom < 1) {
                setWidthOnZoomOut.current = true;
            }
            let newZoom = Math.max(minZoom, zoom);
            newZoom = Math.min(maxZoom, newZoom);
            if (
                allowChangeViewMode &&
                ((viewMode === Spin && newZoom > 1) ||
                    (viewMode === Move && newZoom <= 1))
            ) {
                modeClickHandler();
            }
            AnimatedValues.current.zoom.setValue(newZoom);
            onZoom(newZoom);

            if (pins) {
                const newPins = pins.map((pin) => {
                    return { ...pin, scale: newZoom };
                });
                setPins(newPins);
            }
        };

        // handlers
        const imageViewerMouseDownHandler = (e) => {
            if (e.buttons > 1) {
                return;
            }
            if (_draggable) {
                startDragging();
                rotateViewerImage(e);
            }
            if (isMoveing) {
                const clientX = e.clientX;
                const clientY = e.clientY;
                AnimatedValues.current.originOffset = {
                    x: clientX - AnimatedValues.current.x._value,
                    y: clientY - AnimatedValues.current.y._value,
                };
            }
            if (isAutoPlayRunning && stopAutoplayOnClick) {
                toggleAutoplay(false);
            }
        };

        const imageViewerMouseUpHandler = (e) => {
            if (_draggable) {
                if (e.preventDefault) e.preventDefault();
                stopDragging();
                resetMoveBuffer();
            }
            AnimatedValues.current.originOffset = null;
        };

        const imageViewerMouseMoveHandler = (e) => {
            if (
                isDragging &&
                isMoveing &&
                AnimatedValues.current.originOffset
            ) {
                const clientX = e.clientX;
                const clientY = e.clientY;

                const newHeight1 =
                    _viewerImageRef.current.getBoundingClientRect().height / 2;
                const newWidth1 =
                    _viewerImageRef.current.getBoundingClientRect().width / 2;
                const newHeight2 =
                    newHeight1 / AnimatedValues.current.zoom._value;
                const newWidth2 =
                    newWidth1 / AnimatedValues.current.zoom._value;

                if (
                    _viewerImageRef.current.getBoundingClientRect().left <=
                        _viewerZoomRef.current.refs.node.getBoundingClientRect()
                            .left &&
                    clientX - AnimatedValues.current.originOffset.x >=
                        AnimatedValues.current.x._value
                ) {
                    return;
                }

                if (
                    _viewerImageRef.current.getBoundingClientRect().right >=
                        _viewerZoomRef.current.refs.node.getBoundingClientRect()
                            .right &&
                    clientX - AnimatedValues.current.originOffset.x <=
                        AnimatedValues.current.x._value
                ) {
                    return;
                }

                if (
                    _viewerImageRef.current.getBoundingClientRect().top <=
                        _viewerZoomRef.current.refs.node.getBoundingClientRect()
                            .top &&
                    clientY - AnimatedValues.current.originOffset.y >=
                        AnimatedValues.current.y._value
                ) {
                    return;
                }

                if (
                    _viewerImageRef.current.getBoundingClientRect().bottom >=
                        _viewerZoomRef.current.refs.node.getBoundingClientRect()
                            .bottom &&
                    clientY - AnimatedValues.current.originOffset.y <=
                        AnimatedValues.current.y._value
                ) {
                    return;
                }

                if (
                    newWidth1 - newWidth2 <=
                        Math.abs(
                            clientX - AnimatedValues.current.originOffset.x
                        ) ||
                    newHeight1 - newHeight2 <=
                        Math.abs(
                            clientY - AnimatedValues.current.originOffset.y
                        )
                ) {
                    return;
                }

                AnimatedValues.current.x.setValue(
                    clientX - AnimatedValues.current.originOffset.x
                );
                AnimatedValues.current.y.setValue(
                    clientY - AnimatedValues.current.originOffset.y
                );
                return;
            }
            if (_draggable && isDragging) {
                setDidDrag(true);
                rotateViewerImage(e);
            }
        };

        const imageViewerMouseLeaveHandler = () => {
            setIsDragging(false);
            if (_draggable) resetMoveBuffer();
            if (!isAutoPlayRunning && resumeAutoplayOnMouseLeave) {
                toggleAutoplay(true);
            }
            if (mouseleaveDetect) {
                stopDragging();
                resetMoveBuffer();
            }
        };

        const imageViewerMouseEnterHandler = () => {
            if (isAutoPlayRunning && stopAutoplayOnMouseEnter) {
                toggleAutoplay(false);
            }
        };

        const imageViewerWheelHandler = useCallback(
            (e) => {
                if (mousewheel) {
                    if (e.preventDefault) e.preventDefault();
                    zoomBy(e.deltaY / 200);
                }
            },
            [mousewheel, nextMove, prevMove]
        );

        const zoomBy = (amount) => {
            setZoom(AnimatedValues.current.zoom._value - amount);
        };

        const imageViewerTouchStartHandler = useCallback(
            (e) => {
                if (isMoveing) {
                    const clientX = e.touches[0].clientX;
                    const clientY = e.touches[0].clientY;
                    AnimatedValues.current.originOffset = {
                        x: clientX - AnimatedValues.current.x._value,
                        y: clientY - AnimatedValues.current.y._value,
                    };
                    return;
                }
                if (touch) {
                    // if (e.preventDefault) e.preventDefault();
                    startDragging();
                    rotateViewerImage(e);
                }

                if (isAutoPlayRunning && stopAutoplayOnClick) {
                    toggleAutoplay(false);
                }
            },
            [
                isAutoPlayRunning,
                rotateViewerImage,
                startDragging,
                stopAutoplayOnClick,
                toggleAutoplay,
                touch,
                isMoveing,
            ]
        );

        const imageViewerTouchMoveHandler = useCallback(
            (e) => {
                if (AnimatedValues.current.isZooming) {
                    return;
                }
                if (isMoveing && AnimatedValues.current.originOffset) {
                    const clientX = e.touches[0].clientX;
                    const clientY = e.touches[0].clientY;
                    AnimatedValues.current.x.setValue(
                        clientX - AnimatedValues.current.originOffset.x
                    );
                    AnimatedValues.current.y.setValue(
                        clientY - AnimatedValues.current.originOffset.y
                    );
                    return;
                }
                if (touch) {
                    // if (e.preventDefault) e.preventDefault();
                    setDidDrag(true);
                    rotateViewerImage(e);
                }
            },
            [rotateViewerImage, touch, isMoveing]
        );

        const imageViewerTouchEndHandler = useCallback(
            (e) => {
                AnimatedValues.current.originOffset = null;
                if (touch) {
                    stopDragging();
                    resetMoveBuffer();
                }

                if (!isAutoPlayRunning && resumeAutoplayOnMouseLeave) {
                    toggleAutoplay(true);
                }
            },
            [
                isAutoPlayRunning,
                resumeAutoplayOnMouseLeave,
                stopDragging,
                toggleAutoplay,
                touch,
            ]
        );

        // IPS ADD
        const imageViewerContextHandler = (e) => {
            e.preventDefault();
            onContextClick(
                viewCoordToImageCoord({ x: e?.clientX, y: e?.clientY })
            );
        };

        const imageViewerClickHandler = (e) => {
            const coord = viewCoordToImageCoord({
                x: e.clientX,
                y: e.clientY,
            });
            if (isRecording) {
                // const viewerWidth = _viewerImageRef.current.clientWidth;
                // const viewerHeight = _viewerImageRef.current.clientHeight;

                // const viewerOffsetLeft =
                //     _viewerImageRef.current.getBoundingClientRect().left;
                // const viewerOffsetTop =
                //     _viewerImageRef.current.getBoundingClientRect().top;

                // const offsetCoordX = e.clientX - viewerOffsetLeft;
                // const offsetCoordY = e.clientY - viewerOffsetTop;

                // const clientX =
                //     (offsetCoordX -
                //         AnimatedValues.current.x._value -
                //         (viewerWidth -
                //             viewerWidth * AnimatedValues.current.zoom._value) /
                //             2) /
                //     AnimatedValues.current.zoom._value;
                // const clientY =
                //     (offsetCoordY -
                //         AnimatedValues.current.y._value -
                //         (viewerHeight -
                //             viewerHeight * AnimatedValues.current.zoom._value) /
                //             2) /
                //     AnimatedValues.current.zoom._value;

                // const x = ((clientX - viewerOffsetLeft) / viewerWidth).toFixed(
                //     6
                // );
                // const y = ((clientY - viewerOffsetTop) / viewerHeight).toFixed(
                //     6
                // );

                // const x = (clientX / viewerWidth).toFixed(6);
                // const y = (clientY / viewerHeight).toFixed(6);

                const pin = {
                    id: TridiUtils.uid(),
                    frameId: currentImageIndex,
                    x: coord.x,
                    y: coord.y,
                    recordingSessionId,
                    scale: AnimatedValues.current.zoom._value,
                };
                const newPins = pins.concat(pin);
                setPins(newPins);
            }
            e.preventDefault();
            if (onClick && isMultipleHotspotEnabled) {
                // let loc = this.viewer
                //     .view()
                //     .screenToCoordinates({ x: e.offsetX, y: e.offsetY });
                if (onClick) {
                    // const activeScene = this._activeScene();
                    onClick({
                        location: coord,
                        target: activeZone.zone_id,
                        zone_id: activeZone.zone_id,
                        isMultipleHotspotEnabled: isMultipleHotspotEnabled,
                        itemHotspots: activeZone.items,
                    });
                }
            }
        };

        const pinDoubleClickHandler = (pin) => {
            if (isRecording) {
                const newPins = pins.filter((item) => item.id !== pin.id);
                setPins(newPins);
            }
        };

        const pinClickHandler = (pin) => {
            if (!isRecording) {
                onPinClick(pin);
            }
        };

        // effects
        useEffect(() => {
            const viewerRef = _viewerImageRef.current;
            viewerRef.addEventListener(
                'touchstart',
                imageViewerTouchStartHandler,
                {
                    passive: false,
                }
            );
            viewerRef.addEventListener(
                'touchmove',
                imageViewerTouchMoveHandler,
                {
                    passive: false,
                }
            );
            viewerRef.addEventListener('touchend', imageViewerTouchEndHandler, {
                passive: false,
            });
            viewerRef.addEventListener('wheel', imageViewerWheelHandler, {
                passive: false,
            });

            return () => {
                viewerRef.removeEventListener(
                    'touchstart',
                    imageViewerTouchStartHandler
                );
                viewerRef.removeEventListener(
                    'touchmove',
                    imageViewerTouchMoveHandler
                );
                viewerRef.removeEventListener(
                    'touchend',
                    imageViewerTouchEndHandler
                );
                viewerRef.removeEventListener('wheel', imageViewerWheelHandler);
            };
        }, [
            imageViewerTouchEndHandler,
            imageViewerTouchMoveHandler,
            imageViewerTouchStartHandler,
            imageViewerWheelHandler,
        ]);

        useEffect(() => {
            if (autoplay) {
                toggleAutoplay(autoplay);
            }
        }, [autoplay, toggleAutoplay]);

        useInterval(
            () => {
                nextMove();
            },
            isAutoPlayRunning ? autoplaySpeed : null
        );

        useEffect(() => {
            if (
                zoom !== undefined &&
                zoom !== AnimatedValues.current.zoom._value
            ) {
                let newZoom = Math.max(minZoom, zoom);
                newZoom = Math.min(maxZoom, newZoom);
                AnimatedValues.current.zoom.setValue(newZoom);
                onZoom(newZoom);
            }
        }, [zoom, onZoom]);

        useImperativeHandle(ref, () => ({
            setZoom: setZoom,
            resetPosition: () => resetPosition(),
            setCurrentKeyFrameIdx: setCurrentKeyFrameIdx,
            toggleRecording: (state, recordingSessionId) =>
                toggleRecording(state, recordingSessionId),
            toggleAutoplay: (state) => toggleAutoplay(state),
            toggleMoving: (isMoving) => toggleMoving(isMoving),
            getViewerImageRef: (state) => getViewerImageRef(state),
            getAnimatedValues: (state) => getAnimatedValues(state),
            viewCoordToImageCoord: (state) => viewCoordToImageCoord(state),
            next: () => nextMove(),
            prev: () => prevMove(),
        }));

        const loadImage = () => {
            setLoadedImagesCount(loadedImagesCount + 1);
            onImageLoaded();
            if (!viewerSize) {
                setViewerSize({
                    width: _viewerImageRef?.current?.clientWidth,
                    height: _viewerImageRef?.current?.clientHeight,
                });
            }
            // onLoadChange(loadedImagesCount === _count, Math.round((loadedImagesCount / _count)* 100));
        };

        const onImageLoaded = useCallback(() => {
            onLoadChange(
                loadedImagesCount + 1 === _count,
                Math.round(((loadedImagesCount + 1) / _count) * 100)
            );
        }, [_count, loadedImagesCount, onLoadChange]);

        // useTridiKeyPressHandler({ nextMove, prevMove });
        // IPS ADD
        useTridiKeyPressHandler(useArrowKeys ? { nextMove, prevMove } : {});

        const imageClassName = limitImageHeight
            ? 'tridi-editor-image'
            : 'tridi-viewer-image';

        // render component helpers
        const renderImages = () =>
            _images.map((src, index) => (
                <img
                    key={index}
                    src={src}
                    onLoad={loadImage}
                    className={`${styles[imageClassName]} ${
                        currentImageIndex === index
                            ? styles['tridi-viewer-image-shown']
                            : styles['tridi-viewer-image-hidden']
                    }`}
                    alt=""
                />
            ));

        const renderHintOverlay = () => (
            <div
                className={`${styles['tridi-hint-overlay']}`}
                onClick={hideHint}
                onTouchStart={hideHint}
            >
                {!renderHint && (
                    <Fragment>
                        <DragIcon />
                        {hintText && (
                            <span className={`${styles['tridi-hint-text']}`}>
                                {hintText}
                            </span>
                        )}
                    </Fragment>
                )}
                {renderHint && renderHint()}
            </div>
        );

        const generateViewerClassName = () => {
            let classNameStr = styles['tridi-viewer'];
            if (_draggable)
                classNameStr += ' ' + styles['tridi-draggable-true'];
            if (isRecording)
                classNameStr += ' ' + styles['tridi-recording-true'];
            if (touch) classNameStr += ' ' + styles['tridi-touch-true'];
            if (mousewheel)
                classNameStr += ' ' + styles['tridi-mousewheel-true'];
            if (hintOnStartup)
                classNameStr += ' ' + styles['tridi-hintOnStartup-true'];
            if (className) classNameStr += ' ' + className;
            return classNameStr;
        };

        const initHammer = useCallback(() => {
            const element = document.getElementById('viewerImage');
            const mc = new Hammer.Manager(element);
            const pinch = new Hammer.Pinch();
            mc.add([pinch]);
            mc.on('pinchstart', (ev) => {
                AnimatedValues.current.originOffset = {
                    x: ev.center.x - AnimatedValues.current.x._value,
                    y: ev.center.y - AnimatedValues.current.y._value,
                };
                AnimatedValues.current.originZoom =
                    AnimatedValues.current.zoom._value;
                AnimatedValues.current.isZooming = true;
            });
            mc.on('pinchend', (ev) => {
                AnimatedValues.current.isZooming = false;
                AnimatedValues.current.originOffset = null;
            });
            mc.on('pinchcancel', (ev) => {
                AnimatedValues.current.isZooming = false;
                AnimatedValues.current.originOffset = null;
            });

            mc.on('pinch', function (ev) {
                let scale = AnimatedValues.current.originZoom - 1 + ev.scale;
                scale = Math.max(minZoom, scale);
                scale = Math.min(maxZoom, scale);

                /* const offset = {
					x: (ev.center.x - AnimatedValues.current.originOffset.x) / scale,
					y: (ev.center.y - AnimatedValues.current.originOffset.y) / scale
				};
				if (offset.x !== AnimatedValues.current.x._value) {
					AnimatedValues.current.x.setValue(offset.x);
				}
				if (offset.y !== AnimatedValues.current.y._value) {
					AnimatedValues.current.y.setValue(offset.y);
				} */

                if (scale !== AnimatedValues.current.zoom._value) {
                    if (scale > 1) {
                        toggleMoving(true);
                    }
                    if (scale <= 1) {
                        toggleMoving(false);
                    }
                    AnimatedValues.current.zoom.setValue(scale);
                    onZoom(scale);
                }
            });
        }, [minZoom, maxZoom]);

        useEffect(() => {
            initHammer();
        }, []);

        if (!TridiUtils.isValidProps({ images, format, location })) {
            console.log('Invalid Settings');
            return null;
        }

        return (
            <div
                className={generateViewerClassName()}
                tabIndex="0"
                onKeyDown={(e) => {
                    if (e.code === 'KeyR') {
                        onClickRefreshItem();
                    }
                }}
            >
                {hintVisible && renderHintOverlay()}
                <div
                    id="viewerImage"
                    style={{ ...viewerImageStyle, ...splitViewStyle }}
                    ref={_viewerImageRef}
                    onMouseDown={imageViewerMouseDownHandler}
                    onMouseUp={imageViewerMouseUpHandler}
                    onMouseMove={imageViewerMouseMoveHandler}
                    onMouseLeave={imageViewerMouseLeaveHandler}
                    onMouseEnter={imageViewerMouseEnterHandler}
                    onClick={imageViewerClickHandler}
                    onContextMenu={imageViewerContextHandler} // IPS ADD
                    data-id={currentImageIndex}
                >
                    <AnimatedDiv
                        ref={_viewerZoomRef}
                        style={{
                            overflow: 'visible',
                            ...animatedDivStyle,
                            ...splitViewStyle,
                            transform: [
                                { scale: AnimatedValues.current.zoom },
                                { translateY: AnimatedValues.current.y },
                                { translateX: AnimatedValues.current.x },
                            ],
                        }}
                    >
                        {_images?.length > 0 && renderImages()}
                        {viewerSize ? (
                            <Pins
                                pins={pins}
                                viewerWidth={viewerSize.width}
                                viewerHeight={viewerSize.height}
                                currentFrameId={currentImageIndex}
                                pinWidth={pinWidth}
                                pinHeight={pinHeight}
                                onPinDoubleClick={pinDoubleClickHandler}
                                onPinClick={pinClickHandler}
                                renderPin={renderPin}
                                zoom={AnimatedValues.current.zoom}
                            />
                        ) : null}
                    </AnimatedDiv>
                </div>

                {showStatusBar && (
                    <StatusBar
                        isRecording={isRecording}
                        currentImageIndex={currentImageIndex}
                        playReverse={inverse}
                    />
                )}
                {showControlBar && (
                    <ControlBar
                        hideRecord={hideRecord}
                        isPlaying={isPlaying}
                        isRecording={isRecording}
                        isMoveing={isMoveing}
                        setIsPlaying={setIsPlaying}
                        setIsRecording={setIsRecording}
                        setIsMoveing={setIsMoveing}
                        onStartMoveing={() => toggleMoving(true)}
                        onStopMoveing={() => toggleMoving(false)}
                        onPlay={() => toggleAutoplay(true)}
                        onPause={() => toggleAutoplay(false)}
                        onNext={() => nextMove()}
                        onPrev={() => prevMove()}
                        onRecordStart={() => {
                            toggleRecording(true);
                            setIsMoveing(false);
                        }}
                        onRecordStop={() => toggleRecording(false)}
                        onZoomout={() => {
                            setZoom(AnimatedValues.current.zoom._value - 0.1);
                        }}
                        onZoomin={() => {
                            setZoom(AnimatedValues.current.zoom._value + 0.1);
                        }}
                    />
                )}
            </div>
        );
    }
);

Tridi.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    images: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    clearCache: PropTypes.number,
    pins: PropTypes.array,
    keyFrames: PropTypes.array,
    startKeyFrame: PropTypes.number,
    pinWidth: PropTypes.number,
    pinHeight: PropTypes.number,
    format: PropTypes.string,
    location: PropTypes.string,
    count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    draggable: PropTypes.bool,
    hintOnStartup: PropTypes.bool,
    hintText: PropTypes.string,
    autoplay: PropTypes.bool,
    autoplaySpeed: PropTypes.number,
    stopAutoplayOnClick: PropTypes.bool,
    stopAutoplayOnMouseEnter: PropTypes.bool,
    resumeAutoplayOnMouseLeave: PropTypes.bool,
    touch: PropTypes.bool,
    mousewheel: PropTypes.bool,
    resetOnToggleMove: PropTypes.bool,
    inverse: PropTypes.bool,
    dragInterval: PropTypes.number,
    touchDragInterval: PropTypes.number,
    mouseleaveDetect: PropTypes.bool,
    showControlBar: PropTypes.bool,
    showStatusBar: PropTypes.bool,
    hideRecord: PropTypes.bool,

    renderPin: PropTypes.func,
    setPins: PropTypes.func,
    setInverse: PropTypes.func,
    renderHint: PropTypes.func,
    zoom: PropTypes.number,
    maxZoom: PropTypes.number,
    minZoom: PropTypes.number,
    useArrowKeys: PropTypes.bool,

    onHintHide: PropTypes.func,
    onAutoplayStart: PropTypes.func,
    onAutoplayStop: PropTypes.func,
    onNextMove: PropTypes.func,
    onPrevMove: PropTypes.func,
    onNextFrame: PropTypes.func,
    onPrevFrame: PropTypes.func,
    onDragStart: PropTypes.func,
    onDragEnd: PropTypes.func,
    onFrameChange: PropTypes.func,
    onKeyFrameChange: PropTypes.func,
    onRecordStart: PropTypes.func,
    onRecordStop: PropTypes.func,
    onPinClick: PropTypes.func,
    onLoadChange: PropTypes.func,
    onContextClick: PropTypes.func, // IPS ADD
};

Tridi.defaultProps = {
    className: undefined,
    style: undefined,
    images: 'numbered',
    clearCache: 0,
    keyFrames: [],
    startKeyFrame: 0,
    pin: undefined,
    pinWidth: 10,
    pinHeight: 10,
    format: undefined,
    location: './images',
    count: undefined,
    draggable: true,
    hintOnStartup: false,
    hintText: null,
    autoplay: false,
    autoplaySpeed: 50,
    stopAutoplayOnClick: false,
    stopAutoplayOnMouseEnter: false,
    resumeAutoplayOnMouseLeave: false,
    touch: true,
    mousewheel: false,
    resetOnToggleMove: false,
    inverse: false,
    dragInterval: 1,
    touchDragInterval: 2,
    mouseleaveDetect: false,
    showControlBar: false,
    showStatusBar: false,
    hideRecord: false,

    renderPin: undefined,
    setPins: () => {},
    setInverse: () => {},
    renderHint: undefined,
    zoom: 1,
    maxZoom: 3,
    minZoom: 1,
    onHintHide: () => {},
    onAutoplayStart: () => {},
    onAutoplayStop: () => {},
    onNextMove: () => {},
    onPrevMove: () => {},
    onNextFrame: () => {},
    onPrevFrame: () => {},
    onDragStart: () => {},
    onDragEnd: () => {},
    onFrameChange: () => {},
    onKeyFrameChange: () => {},
    onRecordStart: () => {},
    onRecordStop: () => {},
    onPinClick: () => {},
    onZoom: () => {},
    onLoadChange: () => {},
    onContextClick: () => {}, // IPS ADD
    useArrowKeys: false, // IPS ADD
};

export default Tridi;
