import { makeStyles } from '@mui/styles';

const linkDisplayStyles = makeStyles(() => ({
    modalContainer: {
        position: 'absolute',
        backgroundColor: 'white',
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        '&:focus': {
            outline: 'none',
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        borderRadius: '5px',
        boxShadow: '0 0 5px #444444',
        padding: '45px 20px 20px',
        width: '85%',
        height: '90%',
        '& .react-transform-wrapper': {
            width: '100%',
            height: '100%',
            maxHeight: '80vh',
        },
        '& .react-transform-component': {
            width: '100%',
            height: '80vh',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    embedContainer: {
        position: 'relative',
        width: '100%',
        height: '100vw',
        maxHeight: 'calc(100vh - 180px)',
        overflow: 'auto',
        '& iframe': {
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
        },
    },
    imageContainer: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        maxHeight: 'calc(100vh - 180px)',
        overflow: 'auto',
        height: '100vw',
        '& div': {
            position: 'absolute',
            paddingBottom: '10px',
        },
    },
    imageZoomButtons: {
        position: 'absolute',
        cursor: 'pointer',
        top: '19px',
        left: '19px',
        background: '#ffffff',
        // padding: '0.5rem',
        boxShadow: 'none',
        zIndex: '3',
        '& div': {
            position: 'absolute',
            padding: '5px',
            '& svg': {
                color: 'white',
                width: '25px',
                height: '25px',
            },
        },
    },
    zoomButton: {
        borderRadius: '6px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: (props) => props.colors.button.primaryBackground,
        marginTop: '3rem',
        marginLeft: '1rem',
    },
    videoContainer: {
        position: 'relative',
        width: '100%',
        height: '100vw',
        maxHeight: 'calc(100vh - 180px)',
        overflow: 'auto',
        '& video': {
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: 'calc(100vh - 180px)',
            paddingBottom: '10px',
        },
    },
    close: {
        marginTop: 'auto',
        width: '100%',
        background: (props) => props.colors.button.primaryBackground,
        color: (props) => props.colors.button.primaryForeground,
        '&:hover': {
            background: (props) => props.colors.button.primaryBackground,
            color: (props) => props.colors.button.primaryForeground,
            filter: 'brightness(90%)',
        },
        alignSelf: 'bottom',
    },
    chaptersWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '190px',
        overflow: 'auto',
        height: 'calc(100vh - 180px)',
    },
    chaptersLabel: {
        width: '150px',
        backgroundColor: '#000000',
        borderRadius: '4px',
        padding: '5px',
        color: '#FFFFFF',
        fontWeight: '600',
        textAlign: 'center',
        marginBottom: '5px',
    },
    chaptersCollapseButton: {
        zIndex: 9,
        position: 'absolute !important',
        transform: 'translateX(-50%)',
        color: '#fff !important',
        borderRadius: '4px !important',
    },
    chaptersButton: {
        backgroundColor: (props) =>
            `${props.colors.chapter_button.background_color} !important`,
        color: (props) =>
            `${props.colors.chapter_button.text_color} !important`,
        '&:hover': {
            filter: 'brightness(90%)',
        },
        width: '150px',
        maxWidth: '150px',
        border: '1px solid #ffffff',
    },
    activeChapterButton: {
        backgroundColor: (props) =>
            `${props.colors.chapter_button.active_color} !important`,
        color: (props) =>
            `${props.colors.chapter_button.text_color} !important`,
        '&:hover': {
            filter: 'brightness(90%)',
        },
    },
    overlayText: {
        zIndex: 2,
        top: '20px',
        left: '70px',
        position: 'absolute',
        width: '100%',
        height: 'auto',
        userSelect: 'none',
    },
    linkTitleWrapper: {
        backgroundColor: '#0009',
        color: '#fff',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: '0',
        zIndex: '9',
    },
    linkTitleText: {
        padding: '5px',
        fontWeight: '600',
        fontSize: '18px',
        maxWidth: '50%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    linkIndex: {
        padding: '5px 10px',
        fontWeight: '600',
        position: 'absolute',
        fontSize: '18px',
        right: '0',
    },
    navigationButtonWrapper: {
        width: '100%',
        position: 'absolute',
        zIndex: '9',
    },
    navigationButton: {
        pointerEvents: 'all',
        backgroundColor: '#0009',
        borderRadius: '5px',
        color: '#fff',
        width: '30px',
        height: '30px',
        position: 'absolute',
        '&:hover': {
            backgroundColor: '#c4c4c4',
            color: '#000',
        },
        '&:disabled': {
            display: 'none',
        },
    },
}));

export { linkDisplayStyles };
