import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Formik, Form, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Chip } from '@mui/material';
import Dropzone from 'react-dropzone';
import { MdClose } from 'react-icons/md';

import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { FormActions } from '_helpers/form-action';
import { LinkTypes } from '_helpers';
import { snackbarHandler } from 'GeminiViewerComponent/_helpers/snackbar-handler';
import { makeFormStyles } from '../styles';
import {
    setProgress,
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    createTag,
    getTagUsage,
    fetchTagUsageById,
    fetchTagById,
    updateTag,
    selectTags,
} from '_features/tags/tagsSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { UsageDisplay } from 'components/UsageDisplay';
import { panelStyles } from 'GeminiViewerComponent/components/styles';
import SetDirtyForm from 'forms/SetDirtyForm';
import { LoadingLogo } from 'GeminiViewerComponent/components/LoadingLogo/LoadingLogo';
import { useUniqueTagsName } from 'hooks/useUniqueTagsName';
import { FormTextField } from 'components/TextField';

const ItemTagForm = ({ formAction, itemTagId }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const tags = useSelector(selectTags);
    // eslint-disable-next-line no-unused-vars
    const [loaded, setLoaded] = useState(
        formAction.id === FormActions.Create.id ? true : false
    );
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);
    const panelClasses = panelStyles(theme);
    // eslint-disable-next-line no-unused-vars
    const INIT_VALUES = {
        name: '',
        image_url: '',
        remove_existing_image: false,
        tagIconAvailable: false,
    };
    const [initValues, setInitValues] = useState({ ...INIT_VALUES });
    const tagNameRef = useRef(null);
    const tagUsage = useSelector(getTagUsage);

    useEffect(() => {
        if (formAction.id === FormActions.Edit.id) {
            const fetchData = async () => {
                await dispatch(fetchTagUsageById(itemTagId));
                var objectData = await dispatch(fetchTagById(itemTagId));
                var fetchedObject = objectData.payload;
                setInitValues({
                    name: fetchedObject.name,
                    image_url: fetchedObject.image_url,
                    remove_existing_image: false,
                    tagIconAvailable: fetchedObject?.image_url ? true : false,
                });
                setLoaded(true);
            };
            fetchData();
        } else {
            setInitValues({ ...INIT_VALUES });
        }
    }, [itemTagId]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        dispatch(activateLoading({ showProgress: true }));
        var resultAction = null;

        switch (formAction.id) {
            case FormActions.Edit.id:
                resultAction = await dispatch(
                    updateTag({ tag_id: itemTagId, ...values, dispatch })
                );
                if (!resultAction.error) {
                    setInitValues({
                        ...initValues,
                        name: resultAction?.payload.name,
                        image_url: resultAction?.payload?.image_url,
                        remove_existing_image: false,
                        tagIconAvailable: resultAction?.payload?.image_url
                            ? true
                            : false,
                    });
                }
                break;
            case FormActions.Create.id:
                dispatch(
                    setProgress({
                        progress: 0,
                        label: 'Step 1 of 2: Uploading',
                        noAnimation: true,
                    })
                );
                resultAction = await dispatch(
                    createTag({ ...values, dispatch })
                );
                if (!resultAction.error) {
                    resetForm();
                    if (tagNameRef.current) {
                        tagNameRef.current.focus();
                    }
                }
                break;
            default:
                break;
        }

        dispatch(deactivateLoading());
        const { message, variant } = snackbarHandler(
            resultAction.meta.requestStatus,
            formAction.label
        );
        enqueueSnackbar(message, {
            action: (key) => <SnackbarDismiss key={key} />,
            variant: variant,
        });
        setSubmitting(false);
    };

    const onDrop = useCallback(({ file, setFieldValue, tagIconAvailable }) => {
        setFieldValue('image', file);
        if (tagIconAvailable) {
            setFieldValue('remove_existing_image', true);
        }
        const reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = (e) => {
            setFieldValue('image_url', e.target.result);
        };
        reader.readAsDataURL(file);
    }, []);

    const validateFields = useCallback((values) => {
        let errors = {};
        if (formAction.id === FormActions.Create.id) {
            if (useUniqueTagsName(tags, values?.name)) {
                errors = { ...errors, name: 'Must be unique tag name.' };
            }
        } else {
            if (useUniqueTagsName(tags, values?.name, itemTagId)) {
                errors = { ...errors, name: 'Must be unique tag name.' };
            }
        }
        return errors;
    }, []);

    return !loaded ? (
        <LoadingLogo styleClassName={classes.loadingSvg} />
    ) : (
        <Formik
            enableReinitialize={true}
            initialValues={{ ...initValues }}
            validate={validateFields}
            validationSchema={Yup.object({
                name: Yup.string()
                    .max(150, 'Must be 150 characters or less')
                    .required('Required'),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                handleSubmit(values, setSubmitting, resetForm);
            }}
        >
            {({ isSubmitting, values }) => (
                <Form className={classes.form}>
                    <div className={classes.formHeader}>Tag Details</div>
                    <FormTextField
                        inputRef={tagNameRef}
                        autoFocus
                        label="Tag Name"
                        name="name"
                        placeholder="Tag Name"
                    />
                    <Field className={classes.input} name="image" type="file">
                        {({ field: { value }, form: { setFieldValue } }) => (
                            <div className={panelClasses.dropzoneContainer}>
                                <Dropzone
                                    accept={LinkTypes.Image.contentTypes.map(
                                        (at) => at.extension
                                    )}
                                    maxFiles={1}
                                    maxSize={LinkTypes.Image.fileSizeLimit}
                                    multiple={false}
                                    onDropAccepted={(files) => {
                                        onDrop({
                                            file: files[0],
                                            setFieldValue: setFieldValue,
                                            tagIconAvailable:
                                                values.tagIconAvailable,
                                        });
                                    }}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div
                                            {...getRootProps()}
                                            className={
                                                panelClasses.dropzoneArea
                                            }
                                            style={{
                                                backgroundImage: `url(${values?.image_url})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'contain',
                                                backgroundPosition:
                                                    'center center',
                                            }}
                                        >
                                            <input {...getInputProps()} />
                                            {!values.image_url && (
                                                <div
                                                    className={
                                                        panelClasses.dropzoneText
                                                    }
                                                >
                                                    Drop Tag Icon Here
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Dropzone>
                                <div className={panelClasses.previewContainer}>
                                    {(value && Object.keys(value).length > 0) ||
                                    values?.image_url ? (
                                        <Chip
                                            className={
                                                panelClasses.previewUploadFileName
                                            }
                                            color="secondary"
                                            size="small"
                                            deleteIcon={
                                                <MdClose className="react-icon" />
                                            }
                                            onDelete={() => {
                                                setFieldValue('image', null);
                                                setFieldValue('image_url', '');
                                                if (values.tagIconAvailable) {
                                                    setFieldValue(
                                                        'remove_existing_image',
                                                        true
                                                    );
                                                }
                                            }}
                                            label={value?.name}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        )}
                    </Field>
                    <SetDirtyForm />
                    {formAction.id === FormActions.Edit.id
                        ? Object.keys(tagUsage ?? {})?.length > 0 && (
                              <UsageDisplay
                                  data={tagUsage}
                                  listHeader="Item Locations"
                                  id="tag-usage-id"
                              />
                          )
                        : null}
                    <button
                        className={classes.submit}
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {formAction.buttonLabel}
                    </button>
                </Form>
            )}
        </Formik>
    );
};

export { ItemTagForm };
