// eslint-disable-next-line
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    getSelectedHotspotFromUser,
    selectAllHotspotItems,
    getInitialZoneId,
    getActiveAssetActiveZone,
    selectActiveAsset,
    selectCompactZones,
    getSelectedSearchItemId,
    getDefaultHighlightIconId,
    getAllAudiences,
} from '../../_features/asset/assetSlice';
import { accountsSlice } from 'app/store';
import {
    hotSpotHasTag,
    isHotSpotVisible,
    isZoneVisible,
    zoneHasTag,
} from '../../_helpers/hotspot_helpers';
import { orbitStyles } from './styles';
import Tridi from '../react-tridi';
import { OrbitManager } from '../OrbitManager';
import { OrbitNavigation } from './OrbitNavigation';
import {
    HotspotIconTypes,
    ProcedureSplitViewTypes,
} from 'GeminiViewerComponent/_helpers';
import {
    getProcedureContentVersion,
    getProcedureLoaded,
    getShowOnlyByFilter,
    getTagFilters,
} from 'GeminiViewerComponent/_features/globals/visibilitySlice';
import { getConfig } from 'GeminiViewerComponent/_features/config/configSlice';
import { loadZone, selectZoneLoadingStatus } from 'shared/loadZoneSlice';
import { selectContentPanelItem } from 'GeminiViewerComponent/_features/contentPanel/contentPanelItemSlice';
import clsx from 'clsx';
const { selectActiveUser } = accountsSlice;

const OrbitViewer = ({
    activeItemId,
    draggableFlag = true,
    setDraggableFlag,
    hotspotIcons,
    onShiftRightClick,
    onRightClick,
    onUpdatedHotspot,
    onDeletedHotspot,
    onUpdatedLinkHotspot,
    onDeletedLinkHotspot,
    onEditClicked,
    onItemNavClicked,
    onNavClicked,
    onSelectHotspot,
    PanoItemHotspot,
    PanoLinkHotspot,
    onClick,
    isMultipleHotspotEnabled,
    itemHotspots,
    navigationHotspots,
    onClickRefreshItem,
    enableItemDragging,
    enableNavDragging,
    tridiRef,
    splitViewMode,
}) => {
    const classes = orbitStyles();
    const dispatch = useDispatch();

    const config = useSelector(getConfig);
    const selectedSearchItemId = useSelector(getSelectedSearchItemId);
    const defaultHighlightHotspot = useSelector(getDefaultHighlightIconId);
    const showOnlyByFilter = useSelector(getShowOnlyByFilter);
    const activeAsset = useSelector(selectActiveAsset);
    const allZones = useSelector(selectCompactZones);
    const panelItem = useSelector(selectContentPanelItem);
    const allHotspotItems = useSelector(selectAllHotspotItems);
    const selectedHotspotFromUser = useSelector(getSelectedHotspotFromUser);
    const tagFilters = useSelector(getTagFilters);

    const [inverse, setInverse] = useState(true);
    const initialZoneId = useSelector(getInitialZoneId);
    const activeZone = useSelector(getActiveAssetActiveZone);
    const zoneLoadingStatus = useSelector(selectZoneLoadingStatus);
    const isProcedureLoaded = useSelector((state) => getProcedureLoaded(state));
    const procedureContentVersion = useSelector(getProcedureContentVersion);
    const allAudiences = useSelector(getAllAudiences);

    let zoneIdsToFilterNavLinks = [];
    const activeUser = useSelector(selectActiveUser);

    allZones.forEach((zone) => {
        if (
            zoneHasTag(
                tagFilters.zone_tags.map((tag) => tag.tag_id),
                zone
            ) &&
            (config.user.role.toUpperCase() === 'ADMIN' ||
                isZoneVisible(config.user.audienceIds, zone))
        ) {
            zoneIdsToFilterNavLinks.push(zone.zone_id);
        }
    });
    const filteredItems =
        activeZone?.items?.filter((item) => {
            return (
                hotSpotHasTag(
                    tagFilters.item_tags.map((tag) => tag.tag_id),
                    item,
                    allHotspotItems,
                    showOnlyByFilter
                ) &&
                (config.user.role.toUpperCase() === 'ADMIN' ||
                    isHotSpotVisible(
                        // config.user.audienceIds,
                        allAudiences.map(
                            (item) => +Object.keys(item || {})?.[0]
                        ) || [],
                        item,
                        allHotspotItems
                    ))
            );
        }) ?? [];

    const hotSpotItems =
        filteredItems.map((item) => {
            return {
                id: item.item_id,
                frameId: activeZone.orbit_frame,
                x: item.flat_x,
                y: item.flat_y,
                recordingSessionId: 0,
            };
        }) ?? [];

    const filteredNavLinks =
        activeZone?.nav_links?.filter((item) =>
            zoneIdsToFilterNavLinks.includes(item.target_zone_id)
        ) ?? [];

    const navLinks =
        filteredNavLinks?.map((item) => {
            return {
                id: item.nav_link_id,
                frameId: activeZone.orbit_frame,
                x: item.flat_x,
                y: item.flat_y,
                recordingSessionId: 1,
            };
        }) ?? [];

    //   const [hotSpots, setHotSpots] = React.useState([]);
    const [hotSpots, setHotSpots] = React.useState([
        ...hotSpotItems,
        ...navLinks,
    ]);

    const resetDragElement = useRef(null);

    useEffect(() => {
        if (resetDragElement.current) {
            resetDragElement.current.style.transform = 'translate(0px,0px)';
            resetDragElement.current = null;
        }
    }, [hotSpots]); // eslint-disable-line react-hooks/exhaustive-deps

    // eslint-disable-next-line no-unused-vars
    const handleSceneChanged = () => {};

    // Always init viewmanager on first render
    useEffect(() => {
        window.viewerManager = new OrbitManager({
            config: config,
            initialZoneId: initialZoneId,
            hotspotItems: allHotspotItems,
            zoneItems: allZones,
            viewerRef: tridiRef,
            onSceneChanged: handleSceneChanged,
            onShiftRightClick: onShiftRightClick,
            onRightClick: onRightClick,
        });

        window.viewerManager.init();
    }, [
        config,
        allHotspotItems,
        initialZoneId,
        allZones,
        onShiftRightClick,
        onRightClick,
    ]);

    // Update view manager if anything changes

    useEffect(() => {
        window.viewerManager.update({
            config: config,
            initialZoneId: initialZoneId,
            hotspotItems: allHotspotItems,
            zoneItems: allZones,
            viewerRef: tridiRef,
            onSceneChanged: handleSceneChanged,
            onShiftRightClick: onShiftRightClick,
            onRightClick: onRightClick,
        });
        if (isMultipleHotspotEnabled) {
            document.getElementById('viewerImage').style.cursor = 'crosshair';
        } else {
            document.getElementById('viewerImage').style.cursor = 'default';
        }
    }, [
        config,
        initialZoneId,
        allHotspotItems,
        allZones,
        tridiRef,
        isMultipleHotspotEnabled,
        onShiftRightClick,
        onRightClick,
    ]);

    useEffect(() => {
        if (activeZone) {
            const filteredItems =
                activeZone?.items?.filter((item) => {
                    return (
                        hotSpotHasTag(
                            tagFilters.item_tags.map((tag) => tag.tag_id),
                            item,
                            allHotspotItems,
                            showOnlyByFilter
                        ) &&
                        (config.user.role.toUpperCase() === 'ADMIN' ||
                            isHotSpotVisible(
                                allAudiences.map(
                                    (item) => +Object.keys(item || {})?.[0]
                                ) || [],
                                item,
                                allHotspotItems
                            ))
                    );
                }) ?? [];

            const hotSpotItems =
                filteredItems.map((item) => {
                    return {
                        id: item.item_id,
                        frameId: activeZone.orbit_frame,
                        x: item.flat_x,
                        y: item.flat_y,
                        recordingSessionId: 0,
                    };
                }) ?? [];

            const filteredNavLinks =
                activeZone?.nav_links?.filter((item) =>
                    zoneIdsToFilterNavLinks.includes(item.target_zone_id)
                ) ?? [];
            const navLinks =
                filteredNavLinks?.map((item) => {
                    return {
                        id: item.nav_link_id,
                        frameId: activeZone.orbit_frame,
                        x: item.flat_x,
                        y: item.flat_y,
                        recordingSessionId: 1,
                    };
                }) ?? [];

            const newHotSpots = [...hotSpotItems, ...navLinks];
            setHotSpots(newHotSpots);
        }

        if (activeItemId) {
            // Show current node id
            // window.showHotspot(activeItemId);
        }
    }, [activeZone, tagFilters, showOnlyByFilter]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (window.showHotspot !== undefined) {
            // window.showHotspot(activeItemId);
        }
    }, [activeItemId]);

    useEffect(() => {
        if (
            activeItemId !== undefined &&
            panelItem !== undefined &&
            onSelectHotspot.canExecute &&
            panelItem !== null &&
            selectedHotspotFromUser === true
        ) {
            onSelectHotspot.execute(panelItem.ZoneId, panelItem.ItemId);
        }

        // eslint-disable-next-line
    }, [panelItem, selectedHotspotFromUser]);

    // eslint-disable-next-line no-unused-vars
    const recordStopHandler = () => {};

    // eslint-disable-next-line no-unused-vars
    const pinClickHandler = () => {};

    const contextClickHandler = (coord) => {
        if (onRightClick) {
            onRightClick({
                zone_id: activeZone.zone_id,
                location: coord,
            });
        }
    };

    function onLocalUpdatedHotspot(hotspot, dragElement) {
        resetDragElement.current = dragElement;
        onUpdatedHotspot(hotspot);
    }

    function onLocalUpdatedLinkHotspot(hotspot, dragElement) {
        resetDragElement.current = dragElement;
        onUpdatedLinkHotspot(hotspot);
    }

    function dragEndHandler() {}

    // eslint-disable-next-line no-unused-vars
    function frameChangeHandler(frameId) {}

    function keyFrameChangeHandler(frameId) {
        if (allZones) {
            const zoneInfo = allZones.find((zone) => {
                return zone.orbit_frame === frameId;
            });
            if (zoneLoadingStatus === 'Loaded') {
                if (tridiRef?.current) {
                    allZones.map((zone, index) => {
                        if (zone?.orbit_frame === frameId) {
                            tridiRef.current.setCurrentKeyFrameIdx(index);
                        }
                    });
                }
                dispatch(loadZone({ zoneId: zoneInfo.zone_id }));
            }
        }
    }

    let zone_frames = [
        ...new Set(
            allZones
                .filter((zone) => zone.orbit_frame !== undefined)
                .map((zone) => zone.orbit_frame)
        ),
    ];

    function onLocalNavClicked(zoneId) {
        // Navigate to zone in viewer
        const zone = allZones.find(
            (_zone) =>
                _zone.zone_id === zoneId && _zone.orbit_frame !== undefined
        );
        if (zone) {
            const currFrameIdx = zone_frames.indexOf(activeZone.orbit_frame);
            const frameIdx = zone_frames.indexOf(zone.orbit_frame);
            if (activeZone.orbit_frame >= zone.orbit_frame) {
                if (
                    currFrameIdx === zone_frames.length - 1 &&
                    frameIdx <= currFrameIdx
                ) {
                    tridiRef.current.next(frameIdx);
                } else {
                    tridiRef.current.prev(frameIdx);
                }
            } else {
                const closest = [0, zone_frames.length - 1].reduce(
                    (prev, curr) =>
                        Math.abs(curr - frameIdx) < Math.abs(prev - frameIdx)
                            ? curr
                            : prev
                );
                if (currFrameIdx === 0 && closest === zone_frames.length - 1) {
                    tridiRef.current.prev(frameIdx);
                } else {
                    tridiRef.current.next(frameIdx);
                }
            }

            tridiRef.current.setCurrentKeyFrameIdx(frameIdx);
            tridiRef.current.toggleAutoplay(true);
        } else {
            onNavClicked(zoneId);
        }
    }

    const hotspotItemIcon = (item) => {
        if (
            (selectedSearchItemId && selectedSearchItemId === item.item_id) ||
            procedureContentVersion?.doAction?.item_id === item.item_id
        ) {
            return (
                hotspotIcons?.length > 0 &&
                hotspotIcons.find(
                    (data) => data?.hotspot_icon_id === defaultHighlightHotspot
                )
            );
        }
        const iconId =
            item.hotspot_icon_id || item.hotspot_icon_id === 0
                ? item.hotspot_icon_id
                : item.hotspot_icon
                ? item.hotspot_icon.hotspot_icon_id
                : activeAsset.item_hotspot_icon_id;
        if (!iconId || iconId === 0) {
            return (
                hotspotIcons?.length > 0 &&
                hotspotIcons.find(
                    (data) =>
                        data?.is_default &&
                        data?.hotspot_type_id === HotspotIconTypes.Item.id
                )
            );
        }
        return (
            hotspotIcons?.length > 0 &&
            hotspotIcons.find((data) => data?.hotspot_icon_id === iconId)
        );
    };

    const hotspotNavIcon = (link) => {
        const iconId =
            link.hotspot_icon_id || link.hotspot_icon_id === 0
                ? link.hotspot_icon_id
                : link.hotspot_icon
                ? link.hotspot_icon.hotspot_icon_id
                : activeAsset.nav_hotspot_icon_id;
        if (!iconId || iconId === 0) {
            return (
                hotspotIcons?.length > 0 &&
                hotspotIcons.find(
                    (data) =>
                        data?.is_default &&
                        data?.hotspot_type_id === HotspotIconTypes.Navigation.id
                )
            );
        }
        return (
            hotspotIcons?.length > 0 &&
            hotspotIcons.find((data) => data?.hotspot_icon_id === iconId)
        );
    };

    return (
        <div className={classes.orbitViewWrapper}>
            <Tridi
                ref={tridiRef}
                zoom={1}
                minZoom={1}
                maxZoom={5}
                className={clsx([
                    classes.orbitView,
                    [
                        ProcedureSplitViewTypes.Above.id,
                        ProcedureSplitViewTypes.Below.id,
                    ].includes(splitViewMode) && classes.splitOrbitView,
                    !isProcedureLoaded && classes.splitOrbitViewAuto,
                ])}
                draggable={true}
                touch={draggableFlag}
                images="numbered"
                clearCache={activeAsset?.clearCache}
                location={activeAsset.orbit_images_url}
                count={activeAsset.orbit_images_count}
                keyFrames={zone_frames}
                startKeyFrame={activeZone.orbit_frame}
                format="jpg"
                dragInterval={3}
                pinWidth={0}
                pinHeight={0}
                mousewheel={true}
                resetOnToggleMove={false}
                pins={hotSpots}
                setPins={setHotSpots}
                inverse={inverse}
                setInverse={setInverse}
                showControlBar={false}
                showStatusBar={false}
                onRecordStop={recordStopHandler}
                onPinClick={pinClickHandler}
                onContextClick={contextClickHandler}
                onDragEnd={dragEndHandler}
                onFrameChange={frameChangeHandler}
                onKeyFrameChange={keyFrameChangeHandler}
                autoplay={false}
                onClickRefreshItem={onClickRefreshItem}
                renderPin={(pin) => {
                    if (pin.recordingSessionId === 0) {
                        const item = activeZone?.items?.find(
                            (_item) => _item.item_id === pin.id
                        );

                        return item ? (
                            <div
                                key={`info-${item.item_id}`}
                                className="hotspot info-hotspot-orbit"
                                id={`info-${item.item_id}`}
                            >
                                <PanoItemHotspot
                                    config={config}
                                    zoneId={activeZone.zone_id}
                                    hotspot={item}
                                    icon={hotspotItemIcon(item)}
                                    onUpdatedHotspot={onLocalUpdatedHotspot}
                                    onDeletedHotspot={onDeletedHotspot}
                                    onEditClicked={onEditClicked}
                                    onNavClicked={onItemNavClicked}
                                    onClicked={onSelectHotspot}
                                    hotspotIcons={itemHotspots}
                                    relativeMoves={false}
                                    setDraggableFlag={setDraggableFlag}
                                    readOnly={activeUser?.role === 'User'}
                                    enableDragging={enableItemDragging}
                                />
                            </div>
                        ) : (
                            <span
                                style={{
                                    display: 'inline-block',
                                    borderRadius: '5px',
                                    width: '10px',
                                    height: '10px',
                                    backgroundColor: 'red',
                                }}
                            ></span>
                        );
                    } else {
                        const link = activeZone?.nav_links?.find(
                            (_link) => _link.nav_link_id === pin.id
                        );

                        return link ? (
                            <div
                                key={`link-${link.nav_link_id}`}
                                className="hotspot link-hotspot-orbit"
                                id={`link-${link.nav_link_id}`}
                            >
                                <PanoLinkHotspot
                                    config={config}
                                    allZones={allZones}
                                    hotspot={link}
                                    icon={hotspotNavIcon(link)}
                                    onUpdatedLinkHotspot={
                                        onLocalUpdatedLinkHotspot
                                    }
                                    onDeletedLinkHotspot={onDeletedLinkHotspot}
                                    onNavClicked={onLocalNavClicked}
                                    hotspotIcons={navigationHotspots}
                                    asset={activeAsset}
                                    relativeMoves={false}
                                    setDraggableFlag={setDraggableFlag}
                                    readOnly={activeUser?.role === 'User'}
                                    enableDragging={enableNavDragging}
                                />
                            </div>
                        ) : (
                            <span
                                style={{
                                    display: 'inline-block',
                                    borderRadius: '5px',
                                    width: '10px',
                                    height: '10px',
                                    backgroundColor: 'red',
                                }}
                            ></span>
                        );
                    }
                }}
                onClick={onClick}
                isMultipleHotspotEnabled={isMultipleHotspotEnabled}
                activeZone={activeZone}
                splitViewMode={splitViewMode}
            />

            <OrbitNavigation
                className={classes.orbitNavigation}
                tridiRef={tridiRef}
            />
        </div>
    );
};

export { OrbitViewer };
