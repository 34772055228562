import { Collapse, List, ListItemButton, ListItemText } from '@mui/material';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { useSelector } from 'react-redux';

import ItemList from './ItemList';

const ZoneList = ({
    index,
    zoneItem,
    item,
    itemSubMenu,
    setItemSubMenu,
    itemProperty,
    linkProperty,
    handleClick,
    handleZoneClick,
    linkKey,
    zoneSubMenu,
    zoneProperty,
    setzoneSubMenu,
    itemKey,
}) => {
    const theme = useSelector(selectActiveTheme);
    return (
        <List component="div" disablePadding key={index}>
            <ListItemButton
                sx={{ pl: 4 }}
                onClick={(e) => {
                    e.stopPropagation();
                    handleZoneClick(zoneItem, item?.asset_id);
                }}
            >
                <span style={{ color: theme.colors.text, fontWeight: 'bold' }}>
                    Zone:&nbsp;
                </span>
                <ListItemText primary={zoneItem[zoneProperty]} />
                {zoneSubMenu[index] ? (
                    <MdExpandLess
                        className="react-icon"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleClick(zoneSubMenu, setzoneSubMenu, index);
                        }}
                        style={{ fontSize: '28px' }}
                    />
                ) : (
                    <MdExpandMore
                        className="react-icon"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleClick(zoneSubMenu, setzoneSubMenu, index);
                        }}
                        style={{ fontSize: '28px' }}
                    />
                )}
            </ListItemButton>
            {zoneItem[itemKey] !== undefined && (
                <Collapse in={zoneSubMenu[index]} timeout="auto" unmountOnExit>
                    {zoneItem[itemKey]?.map((hotspotItem, idx) => (
                        <ItemList
                            key={`ItemList-${idx}`}
                            index={idx}
                            linkProperty={linkProperty}
                            linkKey={linkKey}
                            handleClick={handleClick}
                            setItemSubMenu={setItemSubMenu}
                            handleZoneClick={handleZoneClick}
                            zoneItem={zoneItem}
                            item={item}
                            hotspotItem={hotspotItem}
                            itemSubMenu={itemSubMenu}
                            itemProperty={itemProperty}
                        />
                    ))}
                </Collapse>
            )}
        </List>
    );
};

export default ZoneList;
