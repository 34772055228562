import React, { useEffect } from 'react';
import { ItemLinkTable } from 'components';
import { Tabs, Tab } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import {
    selectContentById,
    getContentPageInfo,
    getContentStatus,
    fetchContentByTypeId,
} from '_features/content/contentSlice';
import { tabStyles } from 'GeminiViewerComponent/components/styles';
import { ContentManagerTable } from 'scenes/ContentManager/ContentManagerTable';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { selectActiveAsset } from 'GeminiViewerComponent/_features/asset/assetSlice';
import {
    getActiveLinkContentSubTab,
    setActiveLinkContentSubTab,
} from 'GeminiViewerComponent/_features/contentPanel/contentPanelItemSlice';

function LinkContentSubTab({ linkTypeId, contentTypes }) {
    const contentPageInfo = useSelector((state) => getContentPageInfo(state));
    const contentTypeIds = contentTypes.map((x) => x.id);
    const content = useSelector((state) => selectContentById(state));
    const contentStatus = useSelector((state) => getContentStatus(state));
    const displayAsset = useSelector(selectActiveAsset);
    const activeLinkContentSubTab = useSelector(getActiveLinkContentSubTab);
    const classes = tabStyles();
    const dispatch = useDispatch();

    const handleChange = (event, newValue) => {
        dispatch(setActiveLinkContentSubTab(newValue));
    };

    useEffect(() => {
        if (contentStatus === LoadingStatus.Idle) {
            dispatch(
                fetchContentByTypeId({
                    reset: true,
                    startIndex: 0,
                    stopIndex: 19,
                    contentTypeIds: contentTypeIds,
                    assetId: displayAsset.asset_id,
                })
            );
        }
    }, [dispatch, contentTypeIds, contentStatus]);

    return (
        <>
            <Tabs
                value={activeLinkContentSubTab}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                className={classes.tabs}
            >
                <Tab label={'Links'} {...tabProps(0)} />
                <Tab label={'Content'} {...tabProps(1)} />
            </Tabs>

            <div className={classes.tabBottom}>
                {activeLinkContentSubTab === 0 ? (
                    <ItemLinkTable linkTypeId={linkTypeId} />
                ) : (
                    <ContentManagerTable
                        content={content}
                        pageInfo={contentPageInfo}
                        contentPayload={{
                            reset: true,
                            startIndex: 20,
                            stopIndex: 29,
                            contentTypeIds: contentTypeIds,
                        }}
                    />
                )}
            </div>
        </>
    );
}

function tabProps(index) {
    return {
        id: `scrollable-sub-tab-auto-tab-${index}`,
        'aria-controls': `scrollable-sub-tab-auto-tabpanel-${index}`,
    };
}

export { LinkContentSubTab };
