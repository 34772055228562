import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    searchFilterContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    searchContainer: {
        display: 'flex',
        backgroundColor: 'white',
        borderRadius: '5px',
        width: '100%',
    },
    searchIcon: {
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#BDC3C7',
    },
    inputRoot: {
        flex: 'auto',
        color: 'black',
        backgroundColor: 'transparent',
        height: '100%',
    },
    inputInput: {
        backgroundColor: 'transparent',
        transition: theme.transitions.create('width'),
        width: '100%',
        padding: '7px 0 7px 0',
    },
    filterContainer: {
        height: 'auto',
        width: 'auto',
        cursor: 'pointer',
        zIndex: '2',
        position: 'absolute',
        top: '100%',
    },
    filterContent: {
        marginLeft: '5px',
        display: 'flex',
        alignItems: 'center',
        color: 'black',
    },
    filterBadge: {
        paddingRight: '9px',
        marginRight: '10px',
        '& span': {
            backgroundColor: '#1ABB9C',
            color: '#ffffff',
        },
    },
    filterText: {
        fontSize: '12px',
        marginTop: '0',
        color: '#BDC3C7',
    },
    filterClearButton: {
        border: '1px solid #394a61',
        color: '#394a61',
        borderRadius: '3px',
    },
    filterButtonPadding: {
        padding: '8px',
    },
    filterButtons: {
        display: 'flex',
        gap: '5px',
        cursor: 'text',
        justifyContent: 'flex-end',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    searchResultsContainer: {
        height: 'auto',
        width: '100%',
        cursor: 'pointer',
        zIndex: '2',
        position: 'absolute',
        top: '41px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        gap: '5px',
        maxWidth: '280px'
    },
    searchResultsHeader: {
        width: '100%',
        backgroundColor: 'gray',
        color: '#eee',
        padding: '5px',
    },
    searchResultsItemsContainer: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        gap: '5px',
        padding: '5px',
    },
    searchResultsItem: {
        width: '100%',
        padding: '5px',
        backgroundColor: '#eee',
        textAlign: 'left',
    },
    searchResultsItemZone: {
        fontWeight: 'bold',
    },
}));

export { useStyles };
