import { makeStyles } from '@mui/styles';

const dtNodeActionStyles = makeStyles(() => ({
    dtAction: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        color: 'white',
        backgroundColor: '#002A50',
        margin: '10px 5px',
        fontSize: '16px',
        '&:hover': {
            filter: 'brightness(85%)',
            cursor: 'pointer',
        },
        '& svg': {
            height: '20px',
            width: '20px',
            marginRight: '5px',
        },
    },
}));

export { dtNodeActionStyles };
