import React, { useEffect, useState, useRef } from 'react';
import { Box } from '@mui/material';
import { MdSpeakerNotes } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';

import useStyles from './styles';
import { PageHeader } from 'components';
import { accountsSlice } from 'app/store';
const { selectActiveUser } = accountsSlice;
import { DataNotFound } from 'components/DataNotFound';
import { NewsFlashTable } from './components/NewsFlashTable';
import NewsFlashActionBar from './components/NewsFlashActionBar';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    fetchAllNewsFlash,
    getAllNewsFlash,
    setNewsFlashStatus,
    getNewsFlashPageInfo,
    getNewsFlashStatus,
    fetchNewsFlashDisplayOptions,
    getNewsFlashDisplayOptionStatus,
} from 'GeminiViewerComponent/_features/newsFlash/newsFlashSlice';

function NewsFlash() {
    //#region Constants
    const dispatch = useDispatch();
    const INITIAL_PAYLOAD = {
        reset: true,
        startIndex: 0,
        stopIndex: 19,
        sort: 'display_name',
        startDate: null,
        endDate: null,
        displayOptionId: [],
    };
    //#endregion Constants

    //#region Hooks
    //#endregion Hooks

    //#region State
    const [searchString, setSearchString] = useState('');
    const [sortBy, setSortBy] = useState('display_name');
    const [sortDirection, setSortDirection] = useState('ASC');
    const [newsFlashPayload, setNewsFlashPayload] = useState(INITIAL_PAYLOAD);
    //#endregion State

    //#region Selectors
    const newsFlashes = useSelector(getAllNewsFlash);
    const pageInfo = useSelector(getNewsFlashPageInfo);
    const newsFlashStatus = useSelector(getNewsFlashStatus);
    const activeUser = useSelector(selectActiveUser);
    const newsFlashDisplayOptionStatus = useSelector(
        getNewsFlashDisplayOptionStatus
    );
    const theme = useSelector(selectActiveTheme);
    const readOnlyMode = activeUser?.role === 'User' ? true : false;
    const classes = useStyles(theme);
    //#endregion Selectors

    //#region Refs
    const isComponentLoaded = useRef(true);
    //#endregion Refs

    //#region Effects
    useEffect(() => {
        dispatch(setNewsFlashStatus(LoadingStatus.Idle));
    }, []);

    useEffect(() => {
        if (newsFlashStatus === LoadingStatus.Idle) {
            getNewsFlash({ ...newsFlashPayload });
        }
    }, [newsFlashStatus]);

    useEffect(() => {
        if (isComponentLoaded.current) {
            isComponentLoaded.current = false;
        } else {
            getNewsFlash(newsFlashPayload);
        }
    }, [newsFlashPayload]);
    //#endregion Effects

    //#region Methods
    const getNewsFlash = async (payload) => {
        await dispatch(activateLoading());
        await dispatch(fetchAllNewsFlash(payload));
        if (newsFlashDisplayOptionStatus === LoadingStatus.Idle) {
            await dispatch(fetchNewsFlashDisplayOptions());
        }
        await dispatch(deactivateLoading());
    };

    const setSorting = (_sortBy, _sortDirection) => {
        setSortBy(_sortBy);
        setSortDirection(_sortDirection);
        if (_sortBy !== '') {
            setNewsFlashPayload({
                ...newsFlashPayload,
                reset: true,
                startIndex: 0,
                stopIndex: 19,
                sort: `${_sortDirection === 'DESC' ? '-' : ''}${_sortBy}`,
            });
        }
    };

    //#endregion Methods

    return (
        <Box
            style={{ height: '100vh', width: 'calc(100% - 165px)' }}
            className={classes.content}
        >
            <PageHeader header={'News Flash'} icon={<MdSpeakerNotes />} />
            <NewsFlashActionBar
                initialPayload={INITIAL_PAYLOAD}
                newsFlashPayload={newsFlashPayload}
                setNewsFlashPayload={setNewsFlashPayload}
                searchString={searchString}
                setSearchString={setSearchString}
                setSorting={setSorting}
                sortDirection={sortDirection}
                sortBy={sortBy}
            />
            {newsFlashes.length === 0 &&
            newsFlashStatus === LoadingStatus.Loaded ? (
                <DataNotFound
                    title="News Flashes"
                    inputLength={searchString.length}
                />
            ) : (
                <NewsFlashTable
                    newsFlashes={newsFlashes}
                    pageInfo={pageInfo}
                    searchString={searchString}
                    sortDirection={sortDirection}
                    sortBy={sortBy}
                    handleSort={(_sortBy, _sortDirection) =>
                        setSorting(_sortBy, _sortDirection)
                    }
                    newsFlashType={1}
                    newsFlashPayload={newsFlashPayload}
                    readOnly={readOnlyMode}
                />
            )}
        </Box>
    );
}

export { NewsFlash };
