import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { MdList } from 'react-icons/md';
import ReactHlsPlayer from 'react-hls-player';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, IconButton, Typography } from '@mui/material';

import { VideoCaptions } from '../VideoCaptions';
import { linkDisplayStyles } from '../../styles';
import { Each } from 'GeminiViewerComponent/_helpers/Each';
import { contentCaptionURL } from 'GeminiViewerComponent/_helpers';
import { timeToSeconds } from 'GeminiViewerComponent/_helpers/helper';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    fetchChapterById,
    resetChapterStatus,
    selectChapter,
    setChapter,
} from 'GeminiViewerComponent/_features/globals/chapterSlice';
import {
    getChapterData,
    getChapterId,
    getLinkAutoPlay,
    getLinkDisplayContent,
    getLinkDisplayData,
    getLinkDisplayVis,
    setChapterData,
} from 'GeminiViewerComponent/_features/globals/visibilitySlice';

export const DisplayVideoContent = ({
    handleLoadedMetadata,
    isChapterVideo,
    setIsChapterVideo,
    videoRef,
}) => {
    const dispatch = useDispatch(null);
    const theme = useSelector(selectActiveTheme);
    const classes = linkDisplayStyles(theme);

    const linkDisplay = useSelector(getLinkDisplayVis);
    const linkContent = useSelector(getLinkDisplayContent);
    const linkData = useSelector(getLinkDisplayData);
    const linkAutoPlay = useSelector(getLinkAutoPlay);
    const chapter_id = useSelector(getChapterId);
    const chapter_data = useSelector(getChapterData);
    const chapterData = useSelector(selectChapter);
    const [displayChapter, setDisplayChapter] = useState(true);

    let extension = linkContent ? linkContent.split('.').pop() : '';
    if (extension.indexOf('?') !== -1) {
        extension = extension.split('?')?.[0];
    }

    let contentURL = contentCaptionURL(linkContent);

    useEffect(() => {
        if (chapter_data) {
            setIsChapterVideo({ chapterPlayed: false, chapterCapture: true });
        }
        if (
            linkDisplay &&
            chapter_id &&
            chapterData &&
            isChapterVideo.chapterCapture &&
            typeof videoRef.current?.currentTime === 'number'
        ) {
            const timeInSeconds = timeToSeconds(chapterData?.start_time || '0');
            if (isFinite(timeInSeconds)) {
                videoRef.current.currentTime = timeInSeconds;
                videoRef.current.play();
                if (!linkAutoPlay) {
                    videoRef.current.pause();
                }
            }
        }
        if (
            linkDisplay &&
            chapter_data &&
            typeof videoRef.current?.currentTime === 'number'
        ) {
            const timeInSeconds = timeToSeconds(
                chapter_data?.start_time || '0'
            );
            if (isFinite(timeInSeconds)) {
                videoRef.current.currentTime = timeInSeconds;
                videoRef.current.play();
                if (!linkAutoPlay) {
                    videoRef.current.pause();
                }
            }
        }
        if (videoRef.current && linkAutoPlay) {
            videoRef.current.play();
        }
    }, [
        chapterData,
        linkDisplay,
        chapter_data,
        linkAutoPlay,
        isChapterVideo.chapterCapture,
    ]);

    useEffect(() => {
        if (chapter_id) {
            fetchChapter(chapter_id);
            setIsChapterVideo({ chapterPlayed: false, chapterCapture: true });
        } else {
            setIsChapterVideo({ chapterPlayed: false, chapterCapture: false });
        }
        return () => {
            dispatch(resetChapterStatus());
        };
    }, [chapter_id]);

    useEffect(() => {
        setDisplayChapter(!linkData?.collapse_chapters);
    }, [linkData?.collapse_chapters]);

    const fetchChapter = async (chapter_id) => {
        await dispatch(activateLoading());
        await dispatch(fetchChapterById(chapter_id));
        await dispatch(deactivateLoading());
    };

    const handleTimeUpdate = useCallback(
        (e) => {
            if (chapter_id && Object.keys(chapterData || {}).length > 0) {
                if (
                    e.target.currentTime >=
                        timeToSeconds(chapterData.end_time) &&
                    !isChapterVideo.chapterPlayed
                ) {
                    videoRef.current?.pause();
                    setIsChapterVideo((prev) => ({
                        ...prev,
                        chapterPlayed: true,
                    }));
                }
            }
            if (chapter_data) {
                if (
                    e.target.currentTime >=
                        timeToSeconds(chapter_data.end_time) &&
                    !isChapterVideo.chapterPlayed
                ) {
                    videoRef.current?.pause();
                    setIsChapterVideo((prev) => ({
                        ...prev,
                        chapterPlayed: true,
                    }));
                }
            }
        },
        [chapterData, videoRef, chapter_id, chapter_data, isChapterVideo]
    );

    const handleSeekCapture = useCallback(
        (e) => {
            if (chapter_id) {
                if (
                    e.target.currentTime < timeToSeconds(chapterData.end_time)
                ) {
                    setIsChapterVideo((prev) => ({
                        ...prev,
                        chapterPlayed: false,
                    }));
                }
            }
            if (chapter_data) {
                if (
                    e.target.currentTime < timeToSeconds(chapter_data.end_time)
                ) {
                    setIsChapterVideo((prev) => ({
                        ...prev,
                        chapterPlayed: false,
                    }));
                }
            }
        },
        [chapterData, chapter_id, chapter_data]
    );

    return (
        <div className={classes.videoContainer}>
            {extension.toLowerCase() == 'm3u8' ? (
                <>
                    {linkData?.show_chapters &&
                    linkData?.chapters &&
                    linkData?.chapters?.length > 0 ? (
                        <Box display="flex" gap="5px">
                            {displayChapter && (
                                <Box
                                    className={classes.chaptersWrapper}
                                    height={videoRef?.current?.clientHeight}
                                >
                                    <Typography
                                        className={classes.chaptersLabel}
                                    >
                                        Chapters
                                    </Typography>
                                    <Each
                                        of={[...linkData.chapters].sort(
                                            (a, b) =>
                                                a.chapter_id - b.chapter_id
                                        )}
                                        render={(data, idx) => (
                                            <Button
                                                className={clsx(
                                                    classes.chaptersButton,
                                                    {
                                                        [classes.activeChapterButton]:
                                                            data?.chapter_id ===
                                                                chapter_data?.chapter_id ||
                                                            data?.chapter_id ===
                                                                chapterData?.chapter_id,
                                                    }
                                                )}
                                                onClick={() => {
                                                    dispatch(setChapter(data));
                                                    dispatch(
                                                        setChapterData(data)
                                                    );
                                                }}
                                            >
                                                {data?.title}
                                            </Button>
                                        )}
                                    />
                                </Box>
                            )}
                            <ReactHlsPlayer
                                style={{
                                    position: `${
                                        displayChapter ? 'relative' : 'absolute'
                                    }`,
                                    width: `${
                                        displayChapter
                                            ? 'calc(100% - 200px)'
                                            : '100%'
                                    }`,
                                }}
                                src={linkContent}
                                autoPlay={linkAutoPlay}
                                controls={true}
                                playerRef={videoRef}
                                onLoadedMetadata={handleLoadedMetadata}
                                disablePictureInPicture={true}
                                controlsList="nodownload noplaybackrate"
                                crossOrigin="anonymous"
                                onTimeUpdate={handleTimeUpdate}
                                onSeekingCapture={handleSeekCapture}
                            >
                                <VideoCaptions
                                    caption_languages={
                                        linkData?.caption_languages
                                    }
                                    contentURL={contentURL}
                                />
                            </ReactHlsPlayer>
                            <IconButton
                                className={classes.chaptersCollapseButton}
                                sx={{
                                    left: `${
                                        displayChapter ? '200px' : '25px'
                                    }`,
                                    backgroundColor: `${
                                        displayChapter ? '#000' : '#555555'
                                    }`,
                                    '&:hover': {
                                        backgroundColor: `${
                                            displayChapter ? '#000' : '#555555'
                                        }`,
                                    },
                                    display: 'flex',
                                }}
                                onClick={() => {
                                    setDisplayChapter(!displayChapter);
                                }}
                            >
                                <MdList className="react-icon" />
                            </IconButton>
                        </Box>
                    ) : (
                        <ReactHlsPlayer
                            src={linkContent}
                            autoPlay={linkAutoPlay}
                            controls={true}
                            playerRef={videoRef}
                            onLoadedMetadata={handleLoadedMetadata}
                            disablePictureInPicture={true}
                            controlsList="nodownload noplaybackrate"
                            crossOrigin="anonymous"
                            onTimeUpdate={handleTimeUpdate}
                            onSeekingCapture={handleSeekCapture}
                        >
                            <VideoCaptions
                                caption_languages={linkData?.caption_languages}
                                contentURL={contentURL}
                            />
                        </ReactHlsPlayer>
                    )}
                </>
            ) : extension.toLowerCase() !== 'mp4' ? (
                <div
                    style={{
                        textAlign: 'center',
                        color: 'red',
                    }}
                >
                    This video will be available once conversion is completed
                </div>
            ) : (
                <>
                    {linkData?.show_chapters &&
                    linkData?.chapters &&
                    linkData?.chapters?.length > 0 ? (
                        <Box display="flex" gap="5px">
                            {displayChapter && (
                                <Box
                                    className={classes.chaptersWrapper}
                                    height={videoRef?.current?.clientHeight}
                                >
                                    <Typography
                                        className={classes.chaptersLabel}
                                    >
                                        Chapters
                                    </Typography>
                                    <Each
                                        of={[...linkData.chapters].sort(
                                            (a, b) =>
                                                a.chapter_id - b.chapter_id
                                        )}
                                        render={(data, idx) => (
                                            <Button
                                                className={clsx(
                                                    classes.chaptersButton,
                                                    {
                                                        [classes.activeChapterButton]:
                                                            data?.chapter_id ===
                                                                chapter_data?.chapter_id ||
                                                            data?.chapter_id ===
                                                                chapterData?.chapter_id,
                                                    }
                                                )}
                                                onClick={() => {
                                                    dispatch(setChapter(data));
                                                    dispatch(
                                                        setChapterData(data)
                                                    );
                                                }}
                                            >
                                                {data?.title}
                                            </Button>
                                        )}
                                    />
                                </Box>
                            )}
                            <video
                                src={linkContent}
                                autoPlay={linkAutoPlay}
                                style={{
                                    position: `${
                                        displayChapter ? 'relative' : 'absolute'
                                    }`,
                                    width: `${
                                        displayChapter
                                            ? 'calc(100% - 200px)'
                                            : '100%'
                                    }`,
                                }}
                                playsInline
                                controls
                                ref={videoRef}
                                onLoadedMetadata={handleLoadedMetadata}
                                disablePictureInPicture={true}
                                controlsList="nodownload noplaybackrate"
                                crossOrigin="anonymous"
                                onTimeUpdate={handleTimeUpdate}
                                onSeekingCapture={handleSeekCapture}
                            >
                                <VideoCaptions
                                    caption_languages={
                                        linkData?.caption_languages
                                    }
                                    contentURL={contentURL}
                                />
                            </video>
                            <IconButton
                                className={classes.chaptersCollapseButton}
                                sx={{
                                    left: `${
                                        displayChapter ? '200px' : '25px'
                                    }`,
                                    backgroundColor: `${
                                        displayChapter ? '#000' : '#555555'
                                    }`,
                                    '&:hover': {
                                        backgroundColor: `${
                                            displayChapter ? '#000' : '#555555'
                                        }`,
                                    },
                                    display: 'flex',
                                }}
                                onClick={() => {
                                    setDisplayChapter(!displayChapter);
                                }}
                            >
                                <MdList className="react-icon" />
                            </IconButton>
                        </Box>
                    ) : (
                        <video
                            src={linkContent}
                            autoPlay={linkAutoPlay}
                            playsInline
                            controls
                            ref={videoRef}
                            onLoadedMetadata={handleLoadedMetadata}
                            disablePictureInPicture={true}
                            controlsList="nodownload noplaybackrate"
                            crossOrigin="anonymous"
                            onTimeUpdate={handleTimeUpdate}
                            onSeekingCapture={handleSeekCapture}
                        >
                            <VideoCaptions
                                caption_languages={linkData?.caption_languages}
                                contentURL={contentURL}
                            />
                        </video>
                    )}
                </>
            )}
        </div>
    );
};
