import React, { useEffect, useState } from 'react';
import { InputLabel, FormControl, MenuItem, Select, Box } from '@mui/material';
import { Field } from 'formik';
import FieldValidationError from './FieldValidationError';
import { ImageAttachments } from '../../ImageAttachments';

const SelectField = ({
    data,
    fieldValue,
    setFieldValue,
    validationError,
    handleBlur,
    inGroup = false,
    isReadOnly = false,
    isSubmitAllow = true,
    handleClick = () => {},
}) => {
    const [selectedValue, setSelectedValue] = useState('');
    let variable = data?.variable;
    if (data?.duplicateCount) {
        let lastIndex = data?.variable.lastIndexOf(`-${data?.duplicateCount}`);
        variable = data?.variable.slice(0, lastIndex);
    }

    useEffect(() => {
        if (data?.duplicateCount) {
            setSelectedValue(fieldValue[variable]?.[data.duplicateCount]);
        } else {
            setSelectedValue(fieldValue[variable]?.[0] || fieldValue[variable]);
        }
    }, [fieldValue]);

    const selectFieldData = (
        <FormControl style={{ width: '100%' }} error={!!validationError}>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Field
                    name={
                        data?.duplicateCount
                            ? `${variable}.${data?.duplicateCount}`
                            : `${variable}.${0}`
                    }
                >
                    {() => (
                        <>
                            {data?.hide_label !== true && (
                                <InputLabel id={`label-${data?.variable}`}>
                                    {data?.label}
                                </InputLabel>
                            )}

                            <Select
                                fullWidth
                                labelId={`label-${data?.variable}`}
                                id={data?.variable}
                                label={
                                    data?.hide_label === true ? '' : data?.label
                                }
                                value={selectedValue}
                                disabled={!isSubmitAllow}
                                sx={{
                                    '& .Mui-disabled': {
                                        WebkitTextFillColor:
                                            '#000000 !important',
                                    },
                                }}
                                onChange={(e) => {
                                    // Store default row data at 0 index
                                    if (isSubmitAllow) {
                                        if (data?.duplicateCount) {
                                            setFieldValue(
                                                `${variable}.${[
                                                    data?.duplicateCount,
                                                ]}`,
                                                e.target.value
                                            );
                                        } else {
                                            setFieldValue(
                                                `${variable}.${[0]}`,
                                                e.target.value
                                            );
                                        }
                                    }
                                }}
                                onClick={(e) => {
                                    if (!isSubmitAllow) {
                                        e.stopPropagation();
                                        handleClick(e, data);
                                    }
                                }}
                                onBlur={() =>
                                    isSubmitAllow && handleBlur(fieldValue)
                                }
                            >
                                {data?.options?.length > 0 &&
                                    data?.options.map((opt, idx) => (
                                        <MenuItem
                                            key={`form-select-option-${opt?.value}-${idx}`}
                                            value={opt?.value}
                                        >
                                            {opt?.label}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </>
                    )}
                </Field>
                {data?.image_attachments_allow === true && (
                    <ImageAttachments
                        data={data}
                        var_name={data?.variable}
                        level="field"
                        isReadOnly={!isSubmitAllow}
                        handleClick={handleClick}
                    />
                )}
            </Box>
            {validationError && (
                <FieldValidationError validationError={validationError} />
            )}
        </FormControl>
    );
    return inGroup ? (
        <div
            style={{
                display: 'inline-flex',
                width: data?.field_width ? data.field_width + '%' : '100%',
                padding: '13px 5px 5px',
            }}
        >
            {selectFieldData}
        </div>
    ) : (
        <div style={{ width: '100%' }}>
            <div
                style={{
                    display: 'inline-flex',
                    width: data?.field_width ? data.field_width + '%' : '100%',
                    padding: '5px',
                }}
            >
                {selectFieldData}
            </div>
        </div>
    );
};

export default SelectField;
