import { Collapse, List, ListItemButton, ListItemText } from '@mui/material';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { useSelector } from 'react-redux';

import LinkList from './LinkList';

const ItemList = ({
    index,
    zoneItem,
    item,
    hotspotItem,
    itemSubMenu,
    setItemSubMenu,
    itemProperty,
    linkProperty,
    handleClick,
    handleZoneClick,
    linkKey,
}) => {
    const theme = useSelector(selectActiveTheme);
    return (
        <List component="div" disablePadding key={index}>
            <ListItemButton
                sx={{ pl: 5 }}
                onClick={(e) => {
                    e.stopPropagation();
                    handleZoneClick(zoneItem, item?.asset_id, hotspotItem);
                }}
            >
                <span style={{ color: theme.colors.text, fontWeight: 'bold' }}>
                    Item:&nbsp;
                </span>
                <ListItemText primary={hotspotItem[itemProperty]} />
                {itemSubMenu[index] ? (
                    <MdExpandLess
                        className="react-icon"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleClick(itemSubMenu, setItemSubMenu, index);
                        }}
                        style={{ fontSize: '28px' }}
                    />
                ) : (
                    <MdExpandMore
                        className="react-icon"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleClick(itemSubMenu, setItemSubMenu, index);
                        }}
                        style={{ fontSize: '28px' }}
                    />
                )}
            </ListItemButton>
            {hotspotItem[linkKey] !== undefined && (
                <Collapse in={itemSubMenu[index]} timeout="auto" unmountOnExit>
                    {hotspotItem[linkKey]?.map((linkItem, idx) => (
                        <LinkList
                            key={`LinkList-${idx}`}
                            zoneItem={zoneItem}
                            hotspotItem={hotspotItem}
                            item={item}
                            linkProperty={linkProperty}
                            handleZoneClick={handleZoneClick}
                            linkItem={linkItem}
                        />
                    ))}
                </Collapse>
            )}
        </List>
    );
};

export default ItemList;
