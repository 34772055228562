import { makeStyles } from '@mui/styles';

const dtQuestionStyles = makeStyles(() => ({
    dtQuestion: {
        display: 'flex',
        height: '50px',
        backgroundColor: '#002A50',
        color: 'white',
        alignItems: 'center',
        fontSize: '22px',
    },
    dtQuestionIcon: {
        margin: '0px 10px',
    },
}));

export { dtQuestionStyles };
