// eslint-disable-next-line
import React, { createElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setMessage } from '../../../../_features/voice/voiceSlice';
import { RemoveHtmlTags } from '../SpeechSynth/components/SpeechDispatcher';
import { DtHeader } from '../DtHeader';
import { DtContent } from '../DtContent';
import { DtQuestion } from '../DtQuestion';
import { SingleChoice } from '../SingleChoice';
import { SingleChoicePicture } from '../SingleChoicePicture';
import { Recommendations } from '../Recommendations';

const DtNode = ({ node }) => {
    const dispatch = useDispatch();
    window.speechSynthesis.cancel();

    useEffect(() => {
        if (Object.keys(node).length !== 0) {
            const content = RemoveHtmlTags(node.content);
            if (content !== '') dispatch(setMessage({ message: content }));
        }
    }, [dispatch, node]);

    return (
        <div>
            <DtHeader nodeName={node.page_title} />
            <DtContent nodeContent={node.content} />
            {node.question && <DtQuestion nodeQuestion={node.question} />}
            {node.type === 'SingleChoice' && (
                <SingleChoice nodeOptions={node.option_buttons} />
            )}
            {node.type === 'PictureChoice' && (
                <SingleChoicePicture nodeOptions={node.option_buttons} />
            )}
            {node.recommendations && (
                <Recommendations recs={node.recommendations} />
            )}
        </div>
    );
};

export { DtNode };
