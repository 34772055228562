import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    TextField,
    Tooltip,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { MdAdd, MdDelete } from 'react-icons/md';

import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { dialogStyles } from 'GeminiViewerComponent/components/Procedure/styles';
import { makeFormStyles } from 'forms/styles';
import { actionListDialogStyle } from '../styles';
import { setInspectionReason } from 'GeminiViewerComponent/_features/inspection/inspectionSlice';
import { InspectionDataConfirmDialog } from '../components/InspectionDataConfirmDialog';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';

const ReasonListDialog = ({
    openDialog,
    setOpenDialog,
    inspectionReasonsList,
    createReason,
    handleSubmitReasons,
    addReason,
}) => {
    const theme = useSelector(selectActiveTheme);
    const classes = dialogStyles(theme);
    const formClasses = makeFormStyles(theme);
    const actionDialogClasses = actionListDialogStyle(theme);
    const dispatch = useDispatch();
    const { setFieldError, errors } = useFormikContext();
    const [reasonsList, setReasonsList] = useState([]);
    const [reasonData, setReasonsData] = useState([]);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

    useEffect(() => {
        dispatch(setInspectionReason(inspectionReasonsList));
        if (inspectionReasonsList?.inspection_reasons?.length > 0) {
            if (addReason) {
                setReasonsList([
                    ...inspectionReasonsList.inspection_reasons,
                    {},
                ]);
            } else {
                setReasonsList(inspectionReasonsList.inspection_reasons);
            }
        }
        if (createReason !== true) {
            setReasonsData(inspectionReasonsList);
        }
    }, [inspectionReasonsList]);

    const updateReasonList = (value, index) => {
        if (createReason === true) {
            reasonsList[index] = value;
        } else {
            reasonsList[index] = {
                ...reasonsList[index],
                display_name: value,
            };
        }
        setReasonsList([...reasonsList]);
    };

    const handleRemoveReason = (idx) => {
        let reasonList = [...reasonsList];
        reasonList.splice(idx, 1);
        setReasonsList([...reasonList]);
    };

    const validateReasonName = () => {
        if (reasonData.display_name && reasonData.display_name !== '') {
            setFieldError('reason_name', '');
            return true;
        } else {
            setFieldError('reason_name', 'Required');
            return false;
        }
    };

    const validateReasonList = () => {
        let error = false;
        if (reasonsList.length <= 0) {
            setFieldError('reasons', 'Required');
            return false;
        }
        reasonsList.map((reason) => {
            if (Object.keys(reason ?? {}).length <= 0) {
                setFieldError('reasons', 'Required');
                error = false;
            } else {
                setFieldError('reasons', '');
                error = true;
            }
        });
        return error;
    };

    const handleSubmit = async () => {
        if (validateReasonName() && validateReasonList()) {
            dispatch(activateLoading());
            if (createReason === true) {
                handleSubmitReasons({
                    ...reasonData,
                    reasons: reasonsList,
                });
            } else {
                handleSubmitReasons({
                    ...reasonData,
                    inspection_reasons: reasonsList,
                });
            }
            dispatch(deactivateLoading());
        }
    };

    return (
        <>
            <Dialog
                fullWidth
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                maxWidth={'sm'}
            >
                <DialogTitle>
                    {createReason
                        ? 'Create Reason List'
                        : `Edit Reason List (${inspectionReasonsList?.display_name})`}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="reason-name"
                        name="reason_name"
                        value={reasonData?.display_name}
                        onChange={(e) => {
                            setReasonsData({
                                ...reasonData,
                                display_name: e.target.value,
                            });
                        }}
                        label={'Name'}
                        type="text"
                        variant="outlined"
                    />
                    {errors.reason_name && (
                        <Box className={formClasses.inputErr}>
                            {errors.reason_name}
                        </Box>
                    )}
                    <FormControl fullWidth>
                        <Box
                            component="fieldset"
                            border="1px solid #cccccc"
                            padding="15px"
                            borderRadius="5px"
                            minWidth="0"
                        >
                            <Box
                                component="legend"
                                padding="3px"
                                fontSize="14px"
                                color="#6d7178"
                                textAlign="left"
                            >
                                Reasons
                            </Box>
                            {reasonsList?.map((reasonData, idx) => (
                                <Box
                                    key={reasonData.inspection_reason_id}
                                    display="flex"
                                    alignItems="center"
                                    marginBottom="15px"
                                >
                                    <TextField
                                        fullWidth
                                        id="reasons"
                                        name="reasons"
                                        value={reasonData?.display_name}
                                        onFocus={() =>
                                            setReasonsList([...reasonsList])
                                        }
                                        onChange={(e) => {
                                            updateReasonList(
                                                e.target.value,
                                                idx
                                            );
                                        }}
                                        label={'Name'}
                                        type="text"
                                        variant="outlined"
                                    />
                                    <IconButton
                                        color="black"
                                        aria-label="Delete Actions List"
                                        style={{ height: 'auto' }}
                                        onClick={() => {
                                            handleRemoveReason(idx);
                                        }}
                                    >
                                        <MdDelete className="react-icon" />
                                    </IconButton>
                                </Box>
                            ))}
                            {errors.reasons && (
                                <Box className={formClasses.inputErr}>
                                    {errors?.reasons}
                                </Box>
                            )}
                            <Tooltip title="Add Actions">
                                <IconButton
                                    className={
                                        actionDialogClasses.addButtonIcon
                                    }
                                    onClick={() => {
                                        setReasonsList([...reasonsList, {}]);
                                    }}
                                    size="small"
                                >
                                    <MdAdd className="react-icon" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        className={classes.cancel}
                        onClick={() => setOpenDialog(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            if (validateReasonName() && validateReasonList()) {
                                setOpenConfirmationDialog(true);
                            }
                        }}
                        className={classes.submit}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            {openConfirmationDialog && (
                <InspectionDataConfirmDialog
                    openDialog={openConfirmationDialog}
                    setOpenDialog={setOpenConfirmationDialog}
                    onSubmit={handleSubmit}
                />
            )}
        </>
    );
};

export { ReasonListDialog };
