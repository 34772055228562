// eslint-disable-next-line
import React, { createElement } from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { scenePreviewsStyles } from './styles';
import { loadZone, selectZoneLoadingStatus } from 'shared/loadZoneSlice';
import { getConfig } from 'GeminiViewerComponent/_features/config/configSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { getActiveAssetActiveZone } from 'GeminiViewerComponent/_features/asset/assetSlice';
import { useSetSplitModePosition } from 'GeminiViewerComponent/hooks/useSetSplitModePosition';

const ScenePreviews = ({
    open,
    onCategoryClick,
    zonesByCategory,
    searchMenu,
    controlMenu,
}) => {
    const dispatch = useDispatch();

    const config = useSelector(getConfig);
    const activeZone = useSelector(getActiveAssetActiveZone);
    const zoneLoadingStatus = useSelector(selectZoneLoadingStatus);
    const theme = useSelector(selectActiveTheme);
    const classes = scenePreviewsStyles(theme);
    const activeZoneId = activeZone == null ? '' : activeZone.zone_id;
    const menu = useSetSplitModePosition({ searchMenu, controlMenu });
    const zonesByCategoryKeys = zonesByCategory
        ? Object.keys(zonesByCategory)
        : [];
    const noCategories =
        zonesByCategoryKeys.length === 1 && zonesByCategoryKeys[0] === 'NONE';
    var categories = [];
    const allZones = [];
    var sortedKeys = zonesByCategoryKeys.sort((key1, key2) => {
        if (key1 === 'NONE') {
            return 1;
        }
        if (key2 === 'NONE') {
            return -1;
        }
        return key1.localeCompare(key2);
    });

    async function handleClick(zone) {
        if (zoneLoadingStatus === 'Loaded') {
            dispatch(loadZone({ zoneId: zone.zone_id }));
        }
    }

    for (const idx in sortedKeys) {
        const categoryName = sortedKeys[idx];
        let category = zonesByCategory[categoryName];

        const zoneComponents = [];
        for (const idx in category.zones) {
            const zone = category.zones[idx];
            var previewUrl = zone.equirect_image_preview_url
                ? zone.equirect_image_preview_url
                : zone.flat_image_preview_url
                ? zone.flat_image_preview_url
                : zone.pano_id && config.paths.BASE_STORE_URL
                ? config.paths.BASE_STORE_URL +
                  `tiles/${zone.pano_id}/1/f/0/0.jpg`
                : '';

            const zoneComponent = (
                <div
                    id={`zone_${zone.zone_id}`}
                    key={zone.zone_id}
                    className={clsx(classes.scenePreviewContainer, {
                        [classes.sceneActive]: zone.zone_id === activeZoneId,
                    })}
                    onClick={() => {
                        handleClick(zone);
                    }}
                >
                    <Box style={{ width: '100%' }}>
                        {previewUrl ? (
                            <img
                                className={clsx(classes.sceneImage, {
                                    [classes.sceneActive]:
                                        zone.zone_id === activeZoneId,
                                })}
                                src={previewUrl}
                                alt={`${previewUrl}`.toLowerCase()}
                            />
                        ) : (
                            <div
                                className={clsx(classes.sceneNoImage, {
                                    [classes.sceneActive]:
                                        zone.zone_id === activeZoneId,
                                })}
                            >
                                No Image
                            </div>
                        )}
                        <div
                            className={clsx(classes.scenePreviewTitle, {
                                [classes.scenePreviewTitleActive]:
                                    zone.zone_id === activeZoneId,
                            })}
                        >
                            {zone.display_name}
                        </div>
                    </Box>
                </div>
            );

            if (noCategories) {
                allZones.push(zoneComponent);
            } else {
                zoneComponents.push(zoneComponent);
            }
        }

        if (!noCategories) {
            categories.push(
                <div className={classes.categoryContainer} key={idx}>
                    <div
                        className={classes.categoryTitle}
                        onClick={() => {
                            onCategoryClick(categoryName);
                        }}
                    >
                        {categoryName}
                    </div>
                    <div
                        className={clsx(classes.categoryZones, {
                            categoryOpen: category.open,
                        })}
                    >
                        {zoneComponents}
                    </div>
                </div>
            );
        }
    }

    return (
        <div
            className={classes.scenePreviewsContainer}
            style={{ top: `${menu * 40}px` }}
        >
            <div
                className={clsx(classes.scenePreviews, {
                    [classes.sceneOpen]: open,
                })}
            >
                {noCategories ? allZones : categories}
            </div>
        </div>
    );
};

export { ScenePreviews };
