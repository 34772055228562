import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './styles';
import Toolbar from '@mui/material/Toolbar';
import { Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';
import { Action } from '_helpers';
import moment from 'moment';
import {
    fetchMoreAssets,
    selectAllAssets,
} from '_features/assets/assetsTableSlice';
import { AutoSizer } from 'react-virtualized';

import {
    labelHeader,
    labelCell,
    actionCell,
    checkCell,
    checkHeaderCell,
    pinCell,
    assetTypeIconCell,
    assetDisclaimerIconCell,
    VirtualTable,
} from 'components';
import { tableStyles } from 'components/_Tables/TableCells/styles';
import useAnalyticsEventTracker from 'shared/hooks/useAnalyticsEventTracker';
import { useToolbarStyles } from 'GeminiViewerComponent/components/styles';

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, selectedItems, totalItems } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography
                    className={classes.title}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} of {totalItems} selected
                </Typography>
            ) : (
                <Typography
                    className={classes.title}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                ></Typography>
            )}

            {numSelected > 0 && (
                <Tooltip title="Publish">
                    <PopupAction
                        action={Action.PublishArray}
                        object={selectedItems}
                        level={'asset'}
                        showLabel={false}
                    />
                </Tooltip>
            )}
        </Toolbar>
    );
};

function TableGrid({
    assets,
    pageInfo,
    searchString,
    sortDirection,
    sortBy,
    handleSort,
    loadingStatus,
    assetType,
    assetPayload,
    readOnly,
    accordianTable = false,
    categoryId = null,
    setFilteredAssets = () => {},
}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();
    const tableClasses = tableStyles();
    const [selected, setSelected] = useState([]);
    const [selectedAll, setSelectedAll] = useState(false);
    const [cardWrapperHeight, setCardWrapperHeight] = useState(null);
    const allRows = useSelector(selectAllAssets);

    const loadedRowsMap = useSelector((state) => state.assets.loadedRowsMap);

    const headerHeight = 40;
    const rowHeight = 60;

    const isSelected = (id) => selected.indexOf(id) !== -1;
    const gaEventTracker = useAnalyticsEventTracker('Assets');

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    let prevSelectedAll = usePrevious(selectedAll);

    useEffect(() => {
        setSelected([]);
        setSelectedAll(false);
    }, [assetType]);

    if (prevSelectedAll && selectedAll) {
        if (selected.length !== allRows?.length) {
            setSelected(allRows.map((row) => row.asset_id));
            setSelectedAll(true);
        }
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = allRows.map((row) => row.asset_id);
            setSelected(newSelecteds);
            setSelectedAll(true);
            return;
        }
        setSelected([]);
        setSelectedAll(false);
    };

    const handleClick = (event, id) => {
        event.stopPropagation();
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else {
            newSelected = selected.filter((selectedId) => selectedId !== id);
        }
        setSelected(newSelected);
        setSelectedAll(newSelected.length === allRows?.length);
    };

    const handleRowClick = ({ rowData }) => {
        gaEventTracker('Asset view by id', 'Grid View', rowData.asset_id);
        navigate(
            `/${assetType === 'procedures' ? 'procedure' : 'asset'}/${
                rowData.asset_id
            }`
        );
    };

    const columns = [
        {
            classes: tableClasses,
            minWidth: 50,
            maxWidth: 50,
            width: 50,
            label: 'Select',
            dataKey: 'asset_id',
            padding: 'checkbox',
            cellLevel: 'asset',
            numSelected: selected.length,
            rowCount: allRows.length,
            handleClick: handleClick,
            handleSelectAllClick: handleSelectAllClick,
            isSelected: isSelected,
            component: checkCell,
            headerComponent: checkHeaderCell,
        },
        {
            classes: tableClasses,
            minWidth: 50,
            maxWidth: 50,
            width: 50,
            label: '',
            dataKey: 'asset_id',
            padding: 'none',
            actionCellLevel: 'asset',
            targetPopup: 'asset',
            component: actionCell,
            headerComponent: labelHeader,
        },
        {
            classes: tableClasses,
            minWidth: 50,
            maxWidth: 50,
            width: 50,
            label: 'Pin',
            dataKey: 'is_pinned',
            padding: 'checkbox',
            cellLevel: 'asset',
            component: pinCell,
            headerComponent: labelHeader,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 80,
            minWidth: 100,
            label: 'Asset Type',
            dataKey: 'asset_type_id',
            padding: 'normal',
            component: assetTypeIconCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 200,
            minWidth: 120,
            label: 'Name',
            dataKey: 'display_name',
            padding: 'normal',
            component: labelCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            assetLabel: true,
        },
        {
            classes: tableClasses,
            minWidth: 50,
            maxWidth: 50,
            width: 50,
            label: '',
            dataKey: 'asset_id',
            padding: 'normal',
            dispatch: dispatch,
            component: assetDisclaimerIconCell,
            headerComponent: labelHeader,
        },
        {
            classes: tableClasses,
            minWidth: 50,
            maxWidth: 100,
            width: 100,
            label: 'N° Zones',
            dataKey: 'zone_count',
            padding: 'normal',
            component: labelCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            minWidth: 100,
            maxWidth: 200,
            width: 200,
            label: 'Owner',
            dataKey: 'owner_last_name',
            padding: 'normal',
            component: labelCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 180,
            minWidth: 100,
            maxWidth: 180,
            label: 'Published',
            dataKey: 'last_release_published_date',
            padding: 'normal',
            component: labelCell,
            headerComponent: labelHeader,
            transform: (data) =>
                data ? moment(data).local().format('MM/DD/YYYY hh:mm A') : '',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 180,
            minWidth: 100,
            maxWidth: 180,
            label: 'Draft Published',
            dataKey: 'last_draft_published_date',
            padding: 'normal',
            component: labelCell,
            headerComponent: labelHeader,
            transform: (data) =>
                data ? moment(data).local().format('MM/DD/YYYY hh:mm A') : '',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },

        /* {
            classes: tableClasses,
            minWidth: 80,
            maxWidth: 80,
            width: 80,
            label: '',
            dataKey: 'asset_id',
            padding: 'none',
            component: seeAssetCell,
            headerComponent: labelHeader,
        }, */
    ];

    const loadMoreRows = async ({ startIndex, stopIndex }) => {
        if (categoryId || categoryId === 0) {
            const moreCategoriesAssets = await dispatch(
                fetchMoreAssets({
                    startIndex: startIndex,
                    stopIndex: stopIndex,
                    searchString: searchString,
                    assetCategoryId: categoryId,
                    ...assetPayload,
                })
            );
            if (
                moreCategoriesAssets.payload?.assets &&
                moreCategoriesAssets.payload?.assets?.length > 0
            ) {
                if (startIndex === 0) {
                    setFilteredAssets([...moreCategoriesAssets.payload.assets]);
                } else {
                    setFilteredAssets((prev) => [
                        ...prev,
                        ...moreCategoriesAssets.payload.assets,
                    ]);
                }
            } else {
                setFilteredAssets([]);
            }
        } else {
            dispatch(
                fetchMoreAssets({
                    startIndex: startIndex,
                    stopIndex: stopIndex,
                    searchString: searchString,
                    ...assetPayload,
                })
            );
        }
    };

    return (
        <div className={classes.root}>
            <div
                style={{
                    height: `${
                        cardWrapperHeight
                            ? cardWrapperHeight + 'px'
                            : `calc(100vh - ${
                                  selected.length > 0 ? '287px' : '237px'
                              })`
                    }`,
                    minWidth: '750px',
                    overflow: 'auto hidden',
                }}
            >
                {selected.length > 0 && !readOnly && (
                    <EnhancedTableToolbar
                        numSelected={selected.length}
                        selectedItems={selected}
                        totalItems={pageInfo.TotalCount}
                    />
                )}
                <AutoSizer>
                    {({ width, height }) => (
                        <VirtualTable
                            initialRows={assets}
                            allRows={allRows}
                            totalRowCount={pageInfo.TotalCount}
                            loadedRowsMap={loadedRowsMap}
                            loadMoreRows={loadMoreRows}
                            columnDefinitions={columns}
                            cellClassName={tableClasses.flexContainer}
                            className={tableClasses.table}
                            rowClassName={clsx(
                                tableClasses.flexContainer,
                                tableClasses.tableRowHover
                            )}
                            dispatch={dispatch}
                            headerHeight={headerHeight}
                            width={width}
                            height={height}
                            rowHeight={rowHeight}
                            onRowClick={handleRowClick}
                            loadingStatus={loadingStatus}
                            accordianTable={accordianTable}
                            selected={selected}
                            setCardWrapperHeight={setCardWrapperHeight}
                        />
                    )}
                </AutoSizer>
            </div>
        </div>
    );
}

export { TableGrid };
