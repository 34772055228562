import { IconButton, TextField } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { MdDelete } from 'react-icons/md';

const SelectedContentCard = ({
    content,
    index,
    selectedImages,
    setSelectedImages,
}) => {
    const [imgName, setImageName] = useState('');

    useEffect(() => {
        setImageName(content?.name || '');
    }, [content]);

    const handleRemove = useCallback(() => {
        let currentImages = selectedImages.filter((img, idx) => idx !== index);
        setSelectedImages(currentImages);
    }, [index, selectedImages]);

    return (
        <div style={{ width: '250px', margin: '5px' }}>
            <div
                style={{
                    position: 'relative',
                    width: '250px',
                    height: '200px',
                    boxSizing: 'content-box',
                    marginBottom: '5px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid lightgray',
                    borderRadius: '5px',
                    overflow: 'hidden',
                }}
            >
                <img
                    src={content?.url}
                    alt={content?.name}
                    width={'250px'}
                    height={'200px'}
                    style={{
                        objectFit: 'contain',
                        maxWidth: '100%',
                        maxHeight: '100%',
                    }}
                />
                <IconButton
                    onClick={handleRemove}
                    aria-label="close"
                    sx={{ position: 'absolute', right: 0, top: 0, padding: 0 }}
                    size="large"
                >
                    <div
                        style={{
                            fontSize: '12px',
                            margin: 0,
                            color: '#000000',
                            backgroundColor: '#ffffff',
                        }}
                    >
                        <MdDelete className="react-icon" />
                    </div>
                </IconButton>
            </div>
            <div>
                <TextField
                    fullWidth
                    value={imgName}
                    onChange={(e) => setImageName(e.target.value)}
                    onBlur={(e) => {
                        let currentImages = selectedImages;
                        currentImages = currentImages.map((img, idx) => {
                            if (index === idx) {
                                return {
                                    ...content,
                                    name: e.target.value,
                                    isSaved: false,
                                };
                            }
                            return img;
                        });
                        setSelectedImages(currentImages);
                    }}
                    type="text"
                    variant="outlined"
                    inputProps={{
                        style: {
                            padding: '3px',
                            border: '1.5px solid #000000',
                            borderRadius: '5px',
                        },
                    }}
                />
            </div>
        </div>
    );
};
export { SelectedContentCard };
