import { useMemo, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Typography, IconButton, Tooltip } from '@mui/material';
import { MdOutlineExpandMore, MdClose } from 'react-icons/md';

import { theme } from 'theme';
import { accordionStyle } from './style';
import { map } from 'GeminiViewerComponent/_helpers/lodashUtils';

const FilterAccordion = ({
    children,
    expandedId,
    filteredTitles,
    styles,
    defaultExpanded = false,
    onClearFilter,
}) => {
    //#region Constants
    const classes = accordionStyle(theme);
    //#endregion Constants

    //#region Hooks
    //#endregion Hooks

    //#region State
    const [expanded, setExpanded] = useState(defaultExpanded);
    //#endregion State

    //#region Selectors
    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Effects
    const filters = useMemo(
        () => map(filteredTitles, 'text').filter(Boolean).length > 0,
        [filteredTitles]
    );
    //#endregion Effects

    //#region Methods
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const handleClearFilter = () => {
        onClearFilter();
    };
    //#endregion Methods

    //#region Render time calcs
    //#endregion Render time calcs

    //#region Render

    return (
        <>
            <Accordion
                expanded={expanded === expandedId || expanded}
                onChange={handleChange(expandedId)}
                sx={styles}
                className={classes.mainAccordion}
            >
                <AccordionSummary
                    expandIcon={<MdOutlineExpandMore />}
                    aria-controls="generate-report"
                    id="generate-report"
                    className={classes.accordionSummary}
                >
                    <Tooltip
                        title={
                            !expanded &&
                            !!filters &&
                            map(filteredTitles, 'text')
                                .filter(Boolean)
                                .join(' | ')
                        }
                    >
                        <Typography
                            sx={{
                                flexShrink: 0,
                                maxWidth: '148px',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {!expanded && !!filters
                                ? map(filteredTitles, 'text')
                                      .filter(Boolean)
                                      .join(' | ')
                                : 'Filters'}
                        </Typography>
                    </Tooltip>
                    <Tooltip title="Clear All Filter">
                        <IconButton
                            aria-label="close"
                            onClick={handleClearFilter}
                            sx={{
                                padding: '0',
                                marginLeft: '0.5rem',
                                fontSize: '0.5rem',
                                position: 'absolute',
                                right: '0',
                                background: '#ECF0F10',
                                borderRadius: '0',
                            }}
                            size="small"
                        >
                            <MdClose className="react-icon" />
                        </IconButton>
                    </Tooltip>
                </AccordionSummary>
                <AccordionDetails>{children}</AccordionDetails>
            </Accordion>
        </>
    );
    //#endregion Render
};
export { FilterAccordion };
