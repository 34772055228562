import React, { useEffect } from 'react';
import { FormControl } from '@mui/material';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { accountsSlice } from 'app/store';
const { selectActiveUser } = accountsSlice;
import { useZoneNav } from 'shared/hooks/useZoneNav';
import { useStyles } from 'GeminiViewerComponent/components/Procedure/components/ProcedureQuestions/styles';
import { customTinyMce } from 'GeminiViewerComponent/components/Procedure/styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import interceptClicks from 'GeminiViewerComponent/_helpers/interceptClicks';
import { saveStatement } from 'GeminiViewerComponent/_features/xAPI/xapiSlice';
import { LrsVerbs } from 'GeminiViewerComponent/_features/xAPI/xApiData';
import {
    findVideoChapter,
    setLinkDisplayVis,
} from 'GeminiViewerComponent/_features/globals/visibilitySlice';
import { gotoNode } from 'GeminiViewerComponent/components/Procedure/_features/procedureSlice';
import { config } from 'gemini-config';
import { getDependencyContents } from 'GeminiViewerComponent/_features/asset/assetSlice';

const LabelField = ({
    data,
    inGroup = false,
    isSubmitAllow = true,
    handleClick = () => {},
    contentService,
}) => {
    const theme = useSelector(selectActiveTheme);
    const classes = useStyles(theme);
    const tinyMceCustomClass = customTinyMce();
    const dispatch = useDispatch();
    const { gotoZone, gotoZoneAndItem } = useZoneNav();
    const activeUser = useSelector(selectActiveUser);
    const dependencyContents = useSelector(getDependencyContents);
    const labelContent = data?.content ?? data?.labelcontent;

    useEffect(() => {
        const containers = document.querySelectorAll(
            '.gemini-form-field-content-'
        );
        if (containers && !isSubmitAllow) {
            containers.forEach((container) => {
                const buttons = container.querySelectorAll('button');
                buttons.forEach((button) => {
                    button.style.pointerEvents = 'none';
                });

                const inputs = container.querySelectorAll('input');
                inputs.forEach((input) => {
                    input.style.pointerEvents = 'none';
                });

                const links = container.querySelectorAll('a');
                links.forEach((input) => {
                    input.style.pointerEvents = 'none';
                });
            });
        }
    }, []);

    useEffect(() => {
        return interceptClicks(
            document.querySelector(`.gemini-form-field-content-${data?.id}`),
            {
                //
                // Leave all these as defauts:
                //
                // modifierKeys: true
                // download: true
                // target: true
                // hash: true
                // mailTo: true

                // Intercept all clicks, even ones that are not same origin
                sameOrigin: false,
            },
            async function (e, el) {
                // e.stopPropagation();
                e.preventDefault();
                let params = new URLSearchParams(el.search);
                console.log(`Clicked on link with params ${params}`);
                let contentId = params.get('content_id');
                let autoPlay = params.get('auto_play');
                let linkTypeId = Number(params.get('link_type_id') ?? 2);
                var stmtResponse = {
                    contentId: contentId,
                    autoPlay: autoPlay,
                    linkTypeId: linkTypeId,
                };
                dispatch(
                    saveStatement({
                        LrsVerb: LrsVerbs.Interacted,
                        response: `${JSON.stringify(stmtResponse)}`,
                        contentId,
                    })
                );
                if (contentId) {
                    let content = await contentService?.fetchContentById(
                        contentId,
                        activeUser.s3_prefix
                    );
                    dispatch(
                        setLinkDisplayVis({
                            open: true,
                            linkTypeId: content?.default_link_type_id,
                            linkContent:
                                content?.last_content_version
                                    ?.encoding_status &&
                                content?.last_content_version?.encoding_status?.toLowerCase() ===
                                    'complete'
                                    ? content?.last_content_version?.encoded_url
                                    : content?.last_content_version?.url,
                            linkData:
                                typeof content?.last_content_version ===
                                'object'
                                    ? content?.last_content_version
                                    : {},
                            autoPlay: autoPlay,
                            overlayText:
                                content?.last_content_version?.overlay_text,
                        })
                    );
                } else {
                    let nodeId = params.get('node_id');
                    let zoneId = params.get('zone_id');
                    let itemId = params.get('item_id');
                    if (nodeId) {
                        if (zoneId) {
                            dispatch(
                                saveStatement({
                                    LrsVerb: LrsVerbs.Progressed,
                                    response: { nodeId, zoneId },
                                    zoneId,
                                })
                            );
                            gotoZone(zoneId);
                        }
                        dispatch(gotoNode(Number(nodeId)));
                    } else if (zoneId) {
                        if (itemId) {
                            dispatch(
                                saveStatement({
                                    LrsVerb: LrsVerbs.Progressed,
                                    response: { zoneId, itemId },
                                    zoneId,
                                    itemId,
                                })
                            );
                            gotoZoneAndItem(zoneId, itemId);
                        } else {
                            dispatch(
                                saveStatement({
                                    LrsVerb: LrsVerbs.Progressed,
                                    response: { zoneId },
                                    zoneId,
                                })
                            );
                            gotoZone(zoneId);
                        }
                    } else {
                        dispatch(
                            saveStatement({
                                LrsVerb: LrsVerbs.Progressed,
                                response: {
                                    linkTypeId,
                                    linkContent: el.href,
                                    autoPlay,
                                },
                            })
                        );
                        if (config.modeType === 'author') {
                            dispatch(
                                setLinkDisplayVis({
                                    open: true,
                                    chapter_id:
                                        +el.getAttribute('data-chapter-id') ||
                                        null,
                                    linkContent: el.href,
                                    linkTypeId: linkTypeId,
                                    linkData: null,
                                    autoPlay: autoPlay,
                                    overlayText: null,
                                })
                            );
                        } else {
                            const chapter_data = findVideoChapter(
                                {
                                    content_id: +el.getAttribute(
                                        'data-target-contentid'
                                    ),
                                    content_version_id: +el.getAttribute(
                                        'data-target-selectedversion'
                                    ),
                                    chapter_id:
                                        +el.getAttribute('data-chapter-id') ||
                                        null,
                                },
                                dependencyContents || []
                            );
                            dispatch(
                                setLinkDisplayVis({
                                    open: true,
                                    chapter_data: chapter_data,
                                    chapter_id:
                                        +el.getAttribute('data-chapter-id') ||
                                        null,
                                    linkContent: el.href,
                                    linkTypeId: linkTypeId,
                                    linkData: null,
                                    autoPlay: autoPlay,
                                    overlayText: null,
                                })
                            );
                        }
                    }
                }
                // Change the page state here
            }
        );
    }, [contentService, gotoZone, gotoZoneAndItem, dispatch]);

    const labelFieldData = (
        <FormControl
            style={{
                padding: '5px',
                width: data?.field_width ? data.field_width + '%' : '100%',
            }}
            onClick={(e) => {
                if (!isSubmitAllow) {
                    e.stopPropagation();
                    handleClick(e, data);
                }
            }}
        >
            <div
                className={clsx(
                    classes.nodeContent,
                    `gemini-form-field-content-${
                        isSubmitAllow ? data?.id : ''
                    } ${tinyMceCustomClass.tinymceCustom}`
                )}
                dangerouslySetInnerHTML={{ __html: labelContent }}
            />
        </FormControl>
    );

    return inGroup ? (
        labelFieldData
    ) : (
        <div style={{ width: '100%' }}>{labelFieldData}</div>
    );
};

export default LabelField;
