import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useStyles } from './styles';
import { AutoSizer } from 'react-virtualized';
import { Box } from '@mui/material';

import { labelHeader, labelCell, VirtualTable } from 'components';
import { tableStyles } from 'components/_Tables/TableCells/styles';
import { EnhancedTableToolbar } from 'GeminiViewerComponent/components/EnhancedTableToolbar';
import SearchWithFilters from 'components/SearchWithFilters';
import { debounce } from 'GeminiViewerComponent/_helpers/lodashUtils';

function MediaConversionTable({
    contents,
    pageInfo,
    contentPayload,
    setContentPayload,
    sortDirection,
    sortBy,
    handleSort,
}) {
    const classes = useStyles();
    const tableClasses = tableStyles();
    const [selected, setSelected] = useState([]);
    const [selectedAll, setSelectedAll] = React.useState(false);

    const loadedRowsMap = useSelector((state) => state.content.loadedRowsMap);

    const headerHeight = 40;
    const rowHeight = 60;

    const prevSelectedAll = usePrevious(selectedAll);
    if (prevSelectedAll && selectedAll) {
        if (selected.length !== contents.length) {
            setSelected(contents.map((row) => row.tag_id));
            setSelectedAll(true);
        }
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const columns = [
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 120,
            minWidth: 120,
            label: 'Media Name',
            dataKey: 'display_name',
            padding: 'normal',
            component: labelCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 120,
            minWidth: 120,
            label: 'Encoding Status',
            dataKey: 'encoding_status',
            padding: 'normal',
            component: labelCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 50,
            minWidth: 50,
            label: 'Encoding Percent',
            dataKey: 'encoding_percent',
            padding: 'normal',
            component: labelCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 120,
            minWidth: 120,
            label: 'Transcribe Status',
            dataKey: 'transcribing_status',
            padding: 'normal',
            component: labelCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 50,
            minWidth: 50,
            label: 'Transcribe Percent',
            dataKey: 'transcribing_percent',
            padding: 'normal',
            component: labelCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
    ];

    // eslint-disable-next-line no-unused-vars
    function loadMoreRows({ startIndex, stopIndex }) {}

    const debouncedSearch = useCallback(
        debounce((searchString) => {
            setContentPayload({
                ...contentPayload,
                reset: true,
                searchString: searchString,
            });
        }, 1000),
        [contentPayload]
    );

    return (
        <div className={classes.root}>
            {selected.length > 0 && (
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    toolTipTitle={'Delete'}
                    totalCount={pageInfo.TotalCount}
                ></EnhancedTableToolbar>
            )}
            <div style={{ height: 'calc(100vh - 295px)', minWidth: '500px' }}>
                <Box display="flex" alignItems="center" justifyContent="end">
                    <SearchWithFilters
                        filter={false}
                        onChange={(evt) => debouncedSearch(evt.target.value)}
                    />
                </Box>
                <AutoSizer>
                    {({ width, height }) => (
                        <VirtualTable
                            initialRows={contents}
                            allRows={contents}
                            totalRowCount={pageInfo.TotalCount}
                            loadedRowsMap={loadedRowsMap}
                            loadMoreRows={loadMoreRows}
                            columnDefinitions={columns}
                            cellClassName={tableClasses.flexContainer}
                            className={tableClasses.table}
                            rowClassName={tableClasses.flexContainer}
                            headerHeight={headerHeight}
                            width={width}
                            height={height}
                            rowHeight={rowHeight}
                        />
                    )}
                </AutoSizer>
            </div>
        </div>
    );
}

export { MediaConversionTable };
