import React from 'react';
import { ErrorMessage, useFormikContext } from 'formik';
import { Box, FormControl, TextField } from '@mui/material';
import { ImageAttachments } from '../../ImageAttachments';

const MultilineField = ({
    data,
    fieldValue,
    handleBlur,
    inGroup = false,
    isReadOnly = false,
    isSubmitAllow = true,
    handleClick = () => {},
}) => {
    const { setFieldValue, errors } = useFormikContext();
    let variable = data?.variable;
    if (data?.duplicateCount) {
        let lastIndex = data?.variable.lastIndexOf(`-${data?.duplicateCount}`);
        variable = data?.variable.slice(0, lastIndex);
    }

    const value = data?.duplicateCount
        ? fieldValue[variable]?.[data.duplicateCount]
        : fieldValue[variable]?.[0];

    const multilineData = (
        <FormControl
            style={{
                padding: '5px',
                width: data?.field_width ? data.field_width + '%' : '100%',
            }}
        >
            <Box display="flex" justifyContent="center" alignItems="center">
                {!isReadOnly && (
                    <TextField
                        id={data?.variable}
                        value={value}
                        multiline
                        fullWidth
                        onChange={(e) => {
                            // Store default row data at 0 index
                            if (isSubmitAllow) {
                                if (data?.duplicateCount) {
                                    setFieldValue(
                                        `${variable}.${[data?.duplicateCount]}`,
                                        e.target.value
                                    );
                                } else {
                                    setFieldValue(
                                        `${variable}.${[0]}`,
                                        e.target.value
                                    );
                                }
                            }
                        }}
                        onClick={(e) => {
                            if (!isSubmitAllow) {
                                e.stopPropagation();
                                handleClick(e, data);
                            }
                        }}
                        onBlur={() => isSubmitAllow && handleBlur(fieldValue)}
                        placeholder={
                            data?.hide_label === true ? '' : data?.label
                        }
                        label={data?.hide_label === true ? '' : data?.label}
                        rows={data?.lines ? data.lines : 3}
                        disabled={isReadOnly}
                        sx={{
                            '& .MuiInputBase-input.Mui-disabled': {
                                WebkitTextFillColor: '#000000',
                            },
                        }}
                        error={!!errors?.[data?.variable]}
                        helperText={errors?.[data?.variable]}
                        style={{ textAlign: 'left' }}
                    />
                )}
                {data?.image_attachments_allow === true && (
                    <ImageAttachments
                        data={data}
                        var_name={variable}
                        level="field"
                        isReadOnly={!isSubmitAllow}
                        handleClick={handleClick}
                    />
                )}
            </Box>
            {!isReadOnly && (
                <ErrorMessage
                    style={{
                        color: '#E84C3C',
                        margin: '4px 14px',
                        fontSize: '0.75rem',
                        textAlign: 'left',
                        fontFamily: 'Open Sans',
                        fontWeight: 600,
                        lineHeight: 1.66,
                    }}
                    name={variable}
                    component="div"
                />
            )}
            {isReadOnly && (
                <div>
                    <label className={'multiline-readonly-label'}>
                        {data?.hide_label === true ? '' : data?.label}
                    </label>
                    <p className={'multiline-readonly'}>{value || ''}</p>
                </div>
            )}
        </FormControl>
    );

    return inGroup ? (
        multilineData
    ) : (
        <div style={{ width: '100%' }}>{multilineData}</div>
    );
};

export default MultilineField;
