import { useCallback, useEffect, useState } from 'react';
import { accountsSlice, accountsThunk } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { ViewMode } from 'components';
import useStyles from '../../styles';
import AssetTypeButton from 'components/AssetTypeButton';
import { Checkbox, TextField, Box, Grid } from '@mui/material';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import Autocomplete from '@mui/material/Autocomplete';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { AssetsFilter } from '../AssetsFilter';
import { selectAssetTypes } from '_features/assets/assetManagerSlice';
import { formatBySeparator } from '_helpers';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { MultiSelectDoneButton } from 'GeminiViewerComponent/components/MultiSelectDoneButton';
import SearchWithFilters from 'components/SearchWithFilters';
import { FilterAccordion } from 'components/_Accordions/FilterAccordion';
import { debounce, map } from 'GeminiViewerComponent/_helpers/lodashUtils';

const { selectUserSetting, selectActiveUser } = accountsSlice;
const { updateUserSettings } = accountsThunk;

const AssetsActionBar = ({
    assetType,
    assetPayload,
    setAssetPayload,
    setInputValue,
    setSorting,
    sortDirection,
    setSortDirection,
    sortBy,
    setSortBy,
}) => {
    const theme = useSelector(selectActiveTheme);
    const classes = useStyles(theme);
    const dispatch = useDispatch();
    const activeUser = useSelector(selectActiveUser);
    const readOnlyMode = activeUser?.role === 'User' ? true : false;
    const assetTypeId = assetType === 'procedures' ? 3 : '1|2';
    const assetViewMode = useSelector((state) =>
        selectUserSetting(
            state,
            `${
                assetType === 'assets'
                    ? 'asset_view_mode'
                    : 'procedure_view_mode'
            }`
        )
    );
    const categoryView = useSelector(
        (state) =>
            selectUserSetting(
                state,
                `${
                    assetType === 'assets'
                        ? 'asset_category_view'
                        : 'procedure_category_view'
                }`
            ) ?? false
    );

    const assetTypesSelector = useSelector(selectAssetTypes);
    const [assetTypes, setAssetTypes] = useState([]);
    const [filteredAssetTypes, setFilteredAssetTypes] = useState([]);
    const [searchString, setSearchString] = useState('');
    const [publishedDate, setPublishedDate] = useState(null);
    const [filterTitle, setFilterTitle] = useState([]);

    useEffect(() => {
        const data = assetTypesSelector
            .filter((types) => types.asset_type_id !== 3)
            .map((types) => {
                if (types.asset_type_id === 1) {
                    return { ...types, display_name: '360 Orbit' };
                } else if (types.asset_type_id === 2) {
                    return { ...types, display_name: '360 Pano' };
                } else {
                    return types;
                }
            });
        setAssetTypes(data);
    }, [assetTypesSelector]);

    useEffect(() => {
        setFilteredAssetTypes([]);
        setPublishedDate(null);
        setSearchString('');
        setFilterTitle([]);
    }, [assetType]);

    const handleViewModeToggle = async (viewMode) => {
        dispatch(activateLoading());
        await dispatch(
            updateUserSettings({
                setting_key: `${
                    assetType === 'assets'
                        ? 'asset_view_mode'
                        : 'procedure_view_mode'
                }`,
                setting_value: viewMode,
            })
        );
        dispatch(deactivateLoading());
    };

    const handleCategoryViewToggle = async (categoryView) => {
        dispatch(activateLoading());
        await dispatch(
            updateUserSettings({
                setting_key: `${
                    assetType === 'assets'
                        ? 'asset_category_view'
                        : 'procedure_category_view'
                }`,
                setting_value: categoryView,
            })
        );
        dispatch(deactivateLoading());
    };

    const clearFilters = () => {
        setFilteredAssetTypes([]);
        setPublishedDate(null);
        delete assetPayload['lastPublishedDate'];
        setAssetPayload({
            ...assetPayload,
            searchByAssetType: assetTypeId,
        }); //Add another IDs if generated other than this
        setFilterTitle([]);
    };

    const debouncedSearch = useCallback(
        debounce((searchString) => {
            setInputValue(searchString);
            setAssetPayload({ ...assetPayload, searchString: searchString });
        }, 1000),
        [assetPayload]
    );

    const handleSearch = (searchString) => {
        debouncedSearch(searchString);
    };

    const updateInputValue = (value) => {
        let searchString = value;
        setSearchString(value);
        handleSearch(searchString);
    };

    const formateDate = (date) => {
        return date ? moment(date).format('YYYY-MM-DD') : null;
    };
    const addFilters = ({ assetTypesSelected, publishedDateSelected }) => {
        let filteredObj = {
            searchByAssetType: formatBySeparator(
                assetTypesSelected,
                'asset_type_id',
                '|'
            ),
            lastPublishedDate:
                publishedDateSelected &&
                moment(publishedDateSelected)
                    .utc()
                    .startOf('day')
                    .format('YYYY-MM-DD[T]HH:mm:ss'),
        };
        let count = 0;
        Object.keys(filteredObj).forEach((item) => {
            filteredObj[item]?.length > 0 && count++;
        });
        if (!filteredObj.searchByAssetType) {
            filteredObj = {
                ...filteredObj,
                searchByAssetType: assetTypeId,
            };
        }
        setAssetPayload({
            ...assetPayload,
            ...filteredObj,
        });
    };

    return (
        <Box
            className={classes.actionBar}
            sx={{ flexWrap: 'wrap', alignItems: 'flex-start' }}
        >
            <Box sx={{ width: '100%', mb: '1rem' }}>
                <ViewMode
                    viewMode={assetViewMode}
                    setViewMode={(viewMode) => handleViewModeToggle(viewMode)}
                    categoryView={categoryView}
                    setCategoryView={(categoryView) =>
                        handleCategoryViewToggle(categoryView)
                    }
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: {
                        xs: '0 0 100%',
                        md: assetType !== 'procedures' ? '0 0 70%' : '0 0 50%',
                    },
                }}
            >
                <div className={classes.assetFilter}>
                    {readOnlyMode !== true && (
                        <AssetTypeButton assetType={assetType} />
                    )}
                    <FilterAccordion
                        filteredTitles={filterTitle}
                        expandedId="assets-filter"
                        onClearFilter={clearFilters}
                        style={{ margin: '0', width: '100%' }}
                    >
                        <Grid container spacing={'1rem'}>
                            {assetType !== 'procedures' &&
                                assetTypes.length > 0 && (
                                    <Grid
                                        item={true}
                                        xs={12}
                                        md={6}
                                        lg={4}
                                        className={classes.customSelect}
                                    >
                                        <Autocomplete
                                            limitTags={2}
                                            multiple
                                            variant="outline"
                                            id="checkboxes-tags-demo"
                                            options={assetTypes}
                                            onChange={(
                                                event,
                                                newInputValue
                                            ) => {
                                                setFilteredAssetTypes(
                                                    newInputValue
                                                );
                                                addFilters({
                                                    assetTypesSelected:
                                                        newInputValue,
                                                    publishedDateSelected:
                                                        publishedDate,
                                                });
                                                setFilterTitle({
                                                    assetTypesSelected: {
                                                        text:
                                                            newInputValue?.length >
                                                            0
                                                                ? map(
                                                                      newInputValue,
                                                                      'display_name'
                                                                  )
                                                                : newInputValue?.display_name,
                                                    },
                                                    publishedDateSelected: {
                                                        text: formateDate(
                                                            publishedDate
                                                        ),
                                                    },
                                                });
                                            }}
                                            disableCloseOnSelect
                                            value={filteredAssetTypes}
                                            getOptionLabel={(option) =>
                                                option.display_name
                                            }
                                            renderOption={(
                                                props,
                                                option,
                                                { selected }
                                            ) => (
                                                <li {...props}>
                                                    <Checkbox
                                                        icon={
                                                            <MdCheckBoxOutlineBlank
                                                                className="react-icon"
                                                                kBoxOutlineBlankIcon
                                                            />
                                                        }
                                                        checkedIcon={
                                                            <MdCheckBox className="react-icon" />
                                                        }
                                                        style={{
                                                            marginRight: 8,
                                                        }}
                                                        checked={selected}
                                                    />
                                                    {option.display_name}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Asset Type"
                                                    placeholder="Asset Type"
                                                />
                                            )}
                                            PaperComponent={
                                                MultiSelectDoneButton
                                            }
                                        />
                                    </Grid>
                                )}
                            <Grid
                                item={true}
                                xs={12}
                                md={6}
                                lg={assetType !== 'procedures' ? 4 : 6}
                                className={classes.customSelect}
                            >
                                <LocalizationProvider
                                    dateAdapter={AdapterMoment}
                                >
                                    <DatePicker
                                        label="Last Published Date"
                                        value={publishedDate}
                                        onChange={(newValue) => {
                                            if (
                                                moment(newValue)
                                                    .utc()
                                                    .startOf('day')
                                                    .format(
                                                        'YYYY-MM-DD[T]HH:mm:ss'
                                                    ) !== 'Invalid date'
                                            ) {
                                                setPublishedDate(newValue);
                                                addFilters({
                                                    assetTypesSelected:
                                                        filteredAssetTypes,
                                                    publishedDateSelected:
                                                        newValue,
                                                });
                                            }
                                            setFilterTitle({
                                                assetTypesSelected: {
                                                    text:
                                                        filteredAssetTypes?.length >
                                                        0
                                                            ? map(
                                                                  filteredAssetTypes,
                                                                  'display_name'
                                                              )
                                                            : filteredAssetTypes?.display_name,
                                                },
                                                publishedDateSelected: {
                                                    text: formateDate(newValue),
                                                },
                                            });
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                variant="outlined"
                                                {...params}
                                                sx={{
                                                    minWidth: '200px',
                                                    width: '100%',
                                                }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </FilterAccordion>
                </div>
            </Box>
            <Box
                sx={{
                    position: 'relative',
                    flex: { xs: '0 0 100%', md: '0 0 25%' },
                    mt: { xs: '0.5rem', md: '0' },
                    ml: { xs: '3.93rem', md: '0' },
                    maxWidth: '280px',
                }}
            >
                <SearchWithFilters
                    onChange={(evt) => updateInputValue(evt.target.value)}
                    filter={assetViewMode === 'card' ? true : false}
                    searchString={searchString}
                    setSorting={setSorting}
                    sortDirection={sortDirection}
                    sortBy={sortBy}
                    addButtonText={'APPLY'}
                >
                    <AssetsFilter
                        assetType={assetType}
                        setSorting={setSorting}
                        sortDirection={sortDirection}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                        setSortDirection={setSortDirection}
                    />
                </SearchWithFilters>
            </Box>
        </Box>
    );
};
export default AssetsActionBar;
