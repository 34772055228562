import {
    MdContentCopy,
    MdDelete,
    MdEdit,
    MdFilterNone,
    MdFolderZip,
    MdOutlineCategory,
    MdPresentToAll,
    MdPublish,
    MdRepeat,
    MdRestore,
    MdUploadFile,
    MdVisibility,
    MdWrongLocation,
} from 'react-icons/md';
import { FiPlusCircle } from 'react-icons/fi';
import { FaImage } from 'react-icons/fa';
import { BiReset } from 'react-icons/bi';

import { ReactComponent as ProcessIcon } from 'GeminiViewerComponent/assets/process.svg';
import { ReactComponent as PlaceIcon } from 'GeminiViewerComponent/assets/place.svg';
import { ReactComponent as ProductIcon } from 'GeminiViewerComponent/assets/product.svg';

export const ActionIds = {
    Delete: 0,
    Edit: 1,
    Publish: 2,
    PublishArray: 3,
    DeleteArray: 4,
    DeleteCurrentZone: 5,
    CreateContentVersion: 6,
    Duplicate: 7,
    CopyToZone: 8,
    Undelete: 9,
    Product: 10,
    Place: 11,
    Procedure: 12,
    Repeat: 13,
    StandardPhoto: 14,
    Import: 15,
    Export: 16,
    ExportAsset: 17,
    Player: 18,
    Unposition: 19,
    ResetPosition: 20,
    Category: 21,
};

export default function getActionIcon(iconId, styles = null) {
    switch (iconId) {
        case ActionIds.Delete:
            return <MdDelete className="react-icon" />;
        case ActionIds.Edit:
            return <MdEdit className="react-icon" />;
        case ActionIds.Publish:
            return <MdPublish className="react-icon" />;
        case ActionIds.PublishArray:
            return <MdPublish className="react-icon" />;
        case ActionIds.DeleteArray:
            return <MdDelete className="react-icon" />;
        case ActionIds.DeleteCurrentZone:
            return <MdDelete className="react-icon" />;
        case ActionIds.CreateContentVersion:
            return <FiPlusCircle />;
        case ActionIds.Duplicate:
            return <MdFilterNone className="react-icon" />;
        case ActionIds.CopyToZone:
            return <MdContentCopy className="react-icon" />;
        case ActionIds.Undelete:
            return <MdRestore className="react-icon" />;
        case ActionIds.Product:
            return <ProductIcon style={styles} />;
        case ActionIds.Place:
            return <PlaceIcon style={styles} />;
        case ActionIds.Procedure:
            return <ProcessIcon style={styles} />;
        case ActionIds.Repeat:
            return <MdRepeat className="react-icon" style={styles} />;
        case ActionIds.StandardPhoto:
            return (
                <FaImage
                    className="react-icon"
                    style={{ ...styles, width: '0.8em' }}
                />
            );
        case ActionIds.Import:
            return <MdUploadFile className="react-icon" style={styles} />;
        case ActionIds.Export:
            return <MdPresentToAll className="react-icon" style={styles} />;
        case ActionIds.ExportAsset:
            return <MdFolderZip className="react-icon" style={styles} />;
        case ActionIds.Player:
            return <MdVisibility className="react-icon" style={styles} />;
        case ActionIds.Unposition:
            return <MdWrongLocation className="react-icon" style={styles} />;
        case ActionIds.ResetPosition:
            return <BiReset className="react-icon" style={styles} />;
        case ActionIds.Category:
            return <MdOutlineCategory className="react-icon" style={styles} />;
    }
}
