import React, { useState, useEffect, useCallback } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Chip } from '@mui/material';
import { MdClose } from 'react-icons/md';

import { HotspotTypes, LinkTypes } from '_helpers/enumerations';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { FormActions } from '_helpers/form-action';
import { snackbarHandler } from 'GeminiViewerComponent/_helpers/snackbar-handler';
import { panelStyles } from 'GeminiViewerComponent/components/styles';
import { makeFormStyles } from '../styles';

import {
    setProgress,
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    getHotspotById,
    addNewHotspot,
    editHotspot,
    fetchHotspotUsageById,
    getHotspotUsage,
} from 'shared/hotspotsSlice';
import Dropzone from 'react-dropzone';
import { closePanel } from '_features/common/formSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import SetDirtyForm from 'forms/SetDirtyForm';
import { LoadingLogo } from 'GeminiViewerComponent/components/LoadingLogo/LoadingLogo';
import { UsageDisplay } from 'components';
import { FormSelectField, FormTextField } from 'components/TextField';

const HotspotForm = ({ formAction, hotspotId }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [loaded, setLoaded] = useState(
        formAction.id === FormActions.Create.id ? true : false
    );
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);
    const panelClasses = panelStyles(theme);
    const INIT_VALUES = {
        display_name: '',
        hotspot_type_id: '',
        image: null,
    };
    const [initValues, setInitValues] = useState({ ...INIT_VALUES });
    const hotspotUsage = useSelector(getHotspotUsage);

    useEffect(() => {
        if (formAction.id === FormActions.Edit.id) {
            const fetchData = async () => {
                await dispatch(fetchHotspotUsageById(hotspotId));
                let objectData = await dispatch(getHotspotById(hotspotId));
                let fetchedObject = objectData.payload;

                setInitValues({
                    ...fetchedObject,
                    image: null,
                    image_url: fetchedObject?.image_url
                        ? fetchedObject?.image_url
                        : '',
                    hotspot_type_id:
                        fetchedObject?.is_highlight === true &&
                        fetchedObject?.hotspot_type_id === 1
                            ? 3
                            : fetchedObject?.hotspot_type_id,
                });
                setLoaded(true);
            };
            fetchData();
        } else {
            setInitValues({ ...INIT_VALUES });
        }
    }, [hotspotId]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = async (
        values,
        setSubmitting,
        resetForm,
        setErrors
    ) => {
        dispatch(activateLoading({ showProgress: true }));
        let resultAction = null;
        try {
            switch (formAction.id) {
                case FormActions.Edit.id:
                    dispatch(
                        setProgress({
                            progress: 0,
                            label: 'Step 1 of 2: Uploading',
                            noAnimation: true,
                        })
                    );

                    resultAction = await dispatch(editHotspot({ ...values }));
                    if (!resultAction.payload?.error) {
                        dispatch(closePanel({ formKey: 'hotspotForm' }));
                    }

                    break;
                case FormActions.Create.id:
                    dispatch(
                        setProgress({
                            progress: 0,
                            label: 'Step 1 of 2: Uploading',
                            noAnimation: true,
                        })
                    );
                    resultAction = await dispatch(
                        addNewHotspot({ ...values, dispatch })
                    );
                    if (!resultAction.error) {
                        resetForm();
                    }
                    break;
                default:
                    break;
            }

            if (!resultAction.payload?.error) {
                dispatch(closePanel({ formKey: 'hotspotForm' }));
                resetForm();
                const { message, variant } = snackbarHandler(
                    resultAction.meta.requestStatus,
                    formAction.label
                );
                enqueueSnackbar(message, {
                    action: (key) => <SnackbarDismiss key={key} />,
                    variant: variant,
                });
            } else {
                if (resultAction?.payload?.error?.errors) {
                    setErrors({ ...resultAction?.payload?.error?.errors });
                } else {
                    throw new Error(resultAction?.payload?.error);
                }
            }
        } catch (err) {
            enqueueSnackbar(
                err?.message !== undefined ? err.message : 'Create Failed',
                {
                    action: (key) => <SnackbarDismiss key={key} />,
                    variant: 'error',
                }
            );
        }
        dispatch(deactivateLoading());
        setSubmitting(false);
    };

    const onDrop = useCallback(({ file, setFieldValue }) => {
        if (file) {
            let reader = new FileReader();
            reader.onload = (e) => {
                setFieldValue('image_url', e.target.result);
            };
            reader.readAsDataURL(file);
        } else {
            setFieldValue('image', '');
        }
    }, []);

    const createValidationSchema = Yup.object({
        display_name: Yup.string()
            .max(150, 'Must be 150 characters or less')
            .required('Required'),
        hotspot_type_id: Yup.string().required('Required'),
        image: Yup.mixed().nullable().required('Required'),
    });

    const editValidationSchema = Yup.object({
        display_name: Yup.string()
            .max(150, 'Must be 150 characters or less')
            .required('Required'),
        hotspot_type_id: Yup.string().required('Required'),
        image: Yup.mixed().nullable(),
        image_url: Yup.string().required('Required'),
    });

    return !loaded ? (
        <LoadingLogo styleClassName={classes.loadingSvg} />
    ) : (
        <Formik
            enableReinitialize={true}
            initialValues={{ ...initValues }}
            validationSchema={
                formAction.id === FormActions.Create.id
                    ? createValidationSchema
                    : editValidationSchema
            }
            onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
                handleSubmit(values, setSubmitting, resetForm, setErrors);
            }}
        >
            {({ values, isSubmitting, errors, touched }) => (
                <Form className={classes.form}>
                    <div className={classes.formHeader}>Hotspot Details</div>
                    <FormTextField
                        autoFocus
                        label="Hotspot Name"
                        name="display_name"
                        placeholder="Hotspot Name"
                    />
                    <FormSelectField
                        name="hotspot_type_id"
                        label="Hotspot Type"
                        placeholder="Hotspot Type"
                        entities={HotspotTypes}
                        valueProperty="value"
                        nameProperty="name"
                        keyProperty="value"
                    />
                    <Field className={classes.input} name="image" type="file">
                        {({ field: { value }, form: { setFieldValue } }) => (
                            <div className={panelClasses.dropzoneContainer}>
                                <Dropzone
                                    accept={LinkTypes.Image.contentTypes.map(
                                        (at) => at.extension
                                    )}
                                    maxFiles={1}
                                    maxSize={LinkTypes.Image.fileSizeLimit}
                                    multiple={false}
                                    onDropAccepted={(files) => {
                                        setFieldValue('image', files[0]);
                                        onDrop({
                                            file: files[0],
                                            setFieldValue,
                                        });
                                    }}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div
                                            {...getRootProps()}
                                            className={
                                                panelClasses.dropzoneArea
                                            }
                                            style={{
                                                backgroundImage: `url(${values.image_url})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'contain',
                                                backgroundPosition:
                                                    'center center',
                                                borderColor: `${
                                                    errors?.image &&
                                                    touched?.image
                                                        ? 'red'
                                                        : 'black'
                                                }`,
                                            }}
                                        >
                                            <input {...getInputProps()} />
                                            {!values.image_url && (
                                                <div
                                                    className={
                                                        panelClasses.dropzoneText
                                                    }
                                                >
                                                    Upload Hotspot
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Dropzone>
                                <ErrorMessage
                                    component="div"
                                    className={classes.inputErr}
                                    name="image_url"
                                />
                                <div className={panelClasses.previewContainer}>
                                    {value && Object.keys(value).length > 0 && (
                                        <Chip
                                            className={
                                                panelClasses.previewUploadFileName
                                            }
                                            color="secondary"
                                            size="small"
                                            deleteIcon={
                                                <MdClose className="react-icon" />
                                            }
                                            onDelete={() => {
                                                setFieldValue('image', null);
                                                setFieldValue('image_url', '');
                                            }}
                                            label={value?.name}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </Field>
                    <div className={classes.formErrorDiv}>
                        <ErrorMessage name="image" component="div" />
                    </div>
                    <SetDirtyForm />
                    <button
                        className={classes.submit}
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {formAction.buttonLabel}
                    </button>

                    {formAction.id === FormActions.Edit.id &&
                        Object.keys(hotspotUsage ?? {})?.length > 0 && (
                            <UsageDisplay
                                data={hotspotUsage}
                                listHeader="Hotspot Icon Locations"
                                id="hotspot-usage-id"
                            />
                        )}
                </Form>
            )}
        </Formik>
    );
};

export { HotspotForm };
