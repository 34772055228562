import { makeStyles } from '@mui/styles';

const recommendationStyles = makeStyles(() => ({
    recommendation: {
        display: 'flex',
        height: '100px',
        color: 'white',
        alignItems: 'center',
        fontSize: '18px',
        margin: '10px 0px',
        width: '50%',
        cursor: 'pointer',
    },
    recInfo: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#002A50',
        width: '100%',
        height: '100%',
        margin: '0px 10px',
    },
    title: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '75%',
        height: '100%',
    },
    certainty: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '25%',
        height: '100%',
        backgroundColor: '#a56e7b',
    },
}));

export { recommendationStyles };
