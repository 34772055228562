import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Chip } from '@mui/material';
import Dropzone from 'react-dropzone';
import { MdClose } from 'react-icons/md';

import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { FormActions } from '_helpers/form-action';
import { LinkTypes } from '_helpers';
import { snackbarHandler } from 'GeminiViewerComponent/_helpers/snackbar-handler';
import { makeFormStyles } from '../styles';
import {
    setProgress,
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { panelStyles } from 'GeminiViewerComponent/components/styles';
import SetDirtyForm from 'forms/SetDirtyForm';
import { LoadingLogo } from 'GeminiViewerComponent/components/LoadingLogo/LoadingLogo';
import { SelectionAutocomplete } from 'components';
import {
    getProceduresPicklist,
    selectProceduresPicklist,
    selectProceduresPicklistLoadingStatus,
} from '_features/picklists/pickListsSlice';
import {
    createPart,
    fetchPartById,
    updatePart,
} from '_features/parts/partSlice';
import {
    createPartCategory,
    fetchPartCategoryPage,
    getPartCategoryStatus,
    selectPartCategories,
} from '_features/parts/partCategorySlice';
import {
    createPartGroup,
    fetchPartGroupPage,
    getPartGroupStatus,
    selectPartGroup,
} from '_features/parts/partGroupSlice';
import { FormTextField } from 'components/TextField';

const PartForm = ({ formAction, partId }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [loaded, setLoaded] = useState(
        formAction.id === FormActions.Create.id ? true : false
    );
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);
    const panelClasses = panelStyles(theme);
    const [initValues, setInitValues] = useState({
        display_name: '',
        description: '',
        oem: '',
        number: '',
        part_group_ids: [],
        procedure_ids: [],
        part_category_id: 0,
        image_url: '',
        image: null,
    });
    const partNameRef = useRef(null);

    useEffect(() => {
        if (formAction.id === FormActions.Edit.id) {
            const fetchData = async () => {
                let objectData = await dispatch(fetchPartById(partId));
                let fetchedObject = objectData.payload;
                setInitValues({
                    display_name: fetchedObject?.display_name,
                    part_category_id: fetchedObject?.part_category_id,
                    oem: fetchedObject?.oem,
                    number: fetchedObject?.number,
                    part_group_ids: Array.isArray(fetchedObject?.groups)
                        ? fetchedObject?.groups?.map(
                              (grp) => grp?.part_group_id
                          )
                        : [],
                    procedure_ids: Array.isArray(fetchedObject?.procedures)
                        ? fetchedObject?.procedures?.map(
                              (proc) => proc?.procedure_id
                          )
                        : [],
                    description: fetchedObject?.description,
                    image_url: fetchedObject?.image_url,
                    image: null,
                });
                setLoaded(true);
            };
            fetchData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        dispatch(activateLoading({ showProgress: true }));
        let resultAction = null;
        switch (formAction.id) {
            case FormActions.Edit.id:
                if (values?.image) {
                    delete values?.image_url;
                }
                resultAction = await dispatch(
                    updatePart({ ...values, part_id: partId, dispatch })
                );
                if (!resultAction.error) {
                    setInitValues({
                        ...initValues,
                        display_name: resultAction?.payload.display_name,
                        part_category_id:
                            resultAction?.payload.part_category_id,
                        oem: resultAction?.payload?.oem,
                        number: resultAction?.payload?.number,
                        part_group_ids: Array.isArray(
                            resultAction?.payload.groups
                        )
                            ? resultAction?.payload.groups.map(
                                  (grp) => grp?.part_group_id
                              )
                            : [],
                        procedure_ids: Array.isArray(
                            resultAction?.payload.procedures
                        )
                            ? resultAction?.payload.procedures.map(
                                  (proc) => proc?.procedure_id
                              )
                            : [],
                        description: resultAction?.payload.description,
                        image_url: resultAction?.payload?.image_url,
                        image: null,
                    });
                }
                break;
            case FormActions.Create.id:
                dispatch(
                    setProgress({
                        progress: 0,
                        label: 'Step 1 of 2: Uploading',
                        noAnimation: true,
                    })
                );
                resultAction = await dispatch(
                    createPart({ ...values, dispatch })
                );
                if (!resultAction.error) {
                    resetForm();
                    if (partNameRef.current) {
                        partNameRef.current.focus();
                    }
                }
                break;
            default:
                break;
        }

        dispatch(deactivateLoading());
        const { message, variant } = snackbarHandler(
            resultAction.meta.requestStatus,
            formAction.label
        );
        enqueueSnackbar(message, {
            action: (key) => <SnackbarDismiss key={key} />,
            variant: variant,
        });
        setSubmitting(false);
    };

    const onDrop = useCallback(({ file, setFieldValue }) => {
        setFieldValue('image', file);
        const reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = (e) => {
            setFieldValue('image_url', e.target.result);
        };
        reader.readAsDataURL(file);
    }, []);

    const PartFormProps = [
        {
            inputRef: partNameRef,
            autoFocus: true,
            label: 'Part Name',
            name: 'display_name',
            placeholder: 'Part Name',
        },
        {
            label: 'Description',
            name: 'description',
            placeholder: 'Description',
        },
        {
            label: 'OEM',
            name: 'oem',
            placeholder: 'OEM',
        },
        {
            label: 'Part Number',
            name: 'number',
            placeholder: 'Part Number',
        },
    ];

    return !loaded ? (
        <LoadingLogo styleClassName={classes.loadingSvg} />
    ) : (
        <Formik
            enableReinitialize={true}
            initialValues={{ ...initValues }}
            validationSchema={Yup.object({
                display_name: Yup.string()
                    .required('Required')
                    .max(150, 'Must be 150 characters or less'),
                description: Yup.string().max(
                    255,
                    'Must be 255 characters or less'
                ),
                oem: Yup.string().max(150, 'Must be 150 characters or less'),
                number: Yup.string().max(150, 'Must be 150 characters or less'),
                part_group_ids: Yup.array(),
                procedure_ids: Yup.array(),
                part_category_id: Yup.number()
                    .notOneOf([0, null, ''], 'Required')
                    .required('Required'),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                handleSubmit(values, setSubmitting, resetForm);
            }}
        >
            {({ isSubmitting, values, errors, touched }) => (
                <Form className={classes.form}>
                    <div className={classes.formHeader}>Part Details</div>
                    {PartFormProps.map((field, index) => (
                        <FormTextField key={index} {...field} />
                    ))}
                    <Field className={classes.input} name="part_category_id">
                        {({ field: { value }, form: { setFieldValue } }) => (
                            <SelectionAutocomplete
                                title="Category"
                                placeholder="Category"
                                keyProperty="part_category_id"
                                nameProperty="display_name"
                                entityIds={value}
                                setEntityIds={setFieldValue}
                                entitySelector={selectPartCategories}
                                entityStatusSelector={getPartCategoryStatus}
                                fetchEntityPage={fetchPartCategoryPage}
                                formField={'part_category_id'}
                                multiSelection={false}
                                enableCreatable
                                creatableDialogTitle={'part category'}
                                creatableDialogFieldLabel={'Part Category'}
                                creatableDispatch={createPartCategory}
                                fetchParams={{}}
                                creatableParams={{}}
                                touched={touched['part_category_id']}
                                errorMessage={errors['part_category_id']}
                                fieldValidationLength={150}
                            />
                        )}
                    </Field>
                    <div className={classes.formErrorDiv}>
                        <ErrorMessage name="part_category_id" component="div" />
                    </div>
                    <Field className={classes.input} name="part_group_ids">
                        {({ field: { value }, form: { setFieldValue } }) => (
                            <SelectionAutocomplete
                                title="Groups"
                                placeholder="Groups"
                                keyProperty="part_group_id"
                                nameProperty="display_name"
                                entityIds={value}
                                setEntityIds={setFieldValue}
                                entitySelector={selectPartGroup}
                                entityStatusSelector={getPartGroupStatus}
                                fetchEntityPage={fetchPartGroupPage}
                                formField={'part_group_ids'}
                                multiSelection={true}
                                enableCreatable
                                creatableDialogTitle={'Part Group'}
                                creatableDialogFieldLabel={'Part Group'}
                                creatableDispatch={createPartGroup}
                                fetchParams={{}}
                                creatableParams={{}}
                                touched={touched['part_group_ids']}
                                errorMessage={errors['part_group_ids']}
                                fieldValidationLength={150}
                            />
                        )}
                    </Field>
                    <div className={classes.formErrorDiv}>
                        <ErrorMessage name="part_group_ids" component="div" />
                    </div>
                    <Field className={classes.input} name="procedure_ids">
                        {({ field: { value }, form: { setFieldValue } }) => (
                            <SelectionAutocomplete
                                title="Procedures"
                                placeholder="Procedures"
                                keyProperty="procedure_id"
                                nameProperty="display_name"
                                entityIds={value}
                                setEntityIds={setFieldValue}
                                entitySelector={selectProceduresPicklist}
                                fetchEntityPage={getProceduresPicklist}
                                formField={'procedure_ids'}
                                multiSelection={true}
                                entityStatusSelector={
                                    selectProceduresPicklistLoadingStatus
                                }
                                touched={touched['procedure_ids']}
                                errorMessage={errors['procedure_ids']}
                            />
                        )}
                    </Field>
                    <div className={classes.formErrorDiv}>
                        <ErrorMessage name="procedure_ids" component="div" />
                    </div>
                    <Field className={classes.input} name="image" type="file">
                        {({ field: { value }, form: { setFieldValue } }) => (
                            <div className={panelClasses.dropzoneContainer}>
                                <Dropzone
                                    accept={LinkTypes.Image.contentTypes.map(
                                        (at) => at.extension
                                    )}
                                    maxFiles={1}
                                    maxSize={LinkTypes.Image.fileSizeLimit}
                                    multiple={false}
                                    onDropAccepted={(files) => {
                                        onDrop({
                                            file: files[0],
                                            setFieldValue: setFieldValue,
                                        });
                                    }}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div
                                            {...getRootProps()}
                                            className={
                                                panelClasses.dropzoneArea
                                            }
                                            style={{
                                                backgroundImage: `url(${values?.image_url})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'contain',
                                                backgroundPosition:
                                                    'center center',
                                            }}
                                        >
                                            <input {...getInputProps()} />
                                            {!values.image_url && (
                                                <div
                                                    className={
                                                        panelClasses.dropzoneText
                                                    }
                                                >
                                                    Drop Part Icon Here
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Dropzone>
                                <div className={panelClasses.previewContainer}>
                                    {(value && Object.keys(value).length > 0) ||
                                    values?.image_url ? (
                                        <Chip
                                            className={
                                                panelClasses.previewUploadFileName
                                            }
                                            color="secondary"
                                            size="small"
                                            deleteIcon={
                                                <MdClose className="react-icon" />
                                            }
                                            onDelete={() => {
                                                setFieldValue('image', null);
                                                setFieldValue('image_url', '');
                                            }}
                                            label={value?.name}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        )}
                    </Field>
                    <SetDirtyForm />
                    <button
                        className={classes.submit}
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {formAction.buttonLabel}
                    </button>
                </Form>
            )}
        </Formik>
    );
};

export { PartForm };
