import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    items: {
        background: (props) => props.colors.primarySectionBackground,
        display: 'flex',
        borderBottom: '1px solid',
        width: '100%',
        gap: '5px',
        overflow: 'visible',
        position: 'absolute',
        bottom: '49px',
        alignItems: 'center',
        minHeight: '35px',
    },
    chip: {
        '&:hover': {
            background: (props) => props.colors.button.primaryBackground,
            color: (props) => props.colors.button.primaryForeground,
            cursor: 'pointer',
        },
        userSelect: 'none',
        background: (props) => props.colors.button.primaryBackground,
        color: (props) => props.colors.button.primaryForeground,
    },
    allZoneMediaIcon: {
        height: '30px',
        color: (props) => props.colors.secondarySectionForeground,
        cursor: 'pointer',
        pointerEvents: 'auto',
        transition: 'transform .7s ease-in-out',
        '&:hover': {
            scale: '1.15',
        },
        opacity: '0.5',
        position: 'sticky',
        left: '0',
        background: (props) => props.colors.primarySectionBackground,
        display: 'inline-block',
        borderRadius: '0',
    },
    displayZoneMediaIcon: {
        opacity: '1 !important',
    },
    zoneMediaIcon: {
        height: '35px',
        width: '35px',
    },
    itemsWrapper: {
        background: 'transparent',
        boxShadow: 'none',
        height: 'auto',
        display: 'flex',
        width: '100%',
        padding: '5px 0',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            height: '6px',
            backgroundColor: '#F5F5F5',
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
            backgroundColor: '#F5F5F5',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#a4a4a4',
        },
    },
}));

export { useStyles };
