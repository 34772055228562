import React, { useMemo } from 'react';
import { Box, Grid, IconButton, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import useResizeObserver from 'use-resize-observer';

import { useStyles } from './styles';
import {
    getActiveTree,
    getCurrentNode,
} from 'GeminiViewerComponent/components/Procedure/_features/procedureSlice';
import {
    getActiveSessionId,
    getChecklistData,
} from '../../_features/checklistDataSlice';
import { ImageAttachments } from '../ImageAttachments';
import { ProcedureNodeTypes } from '../../ProcedureNodeTypes';
import { SessionSubmitBtn } from '../SessionSubmit';
import { getConfig } from 'GeminiViewerComponent/_features/config/configSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    displayProcedureContentVersion,
    getLoadedAssetType,
} from 'GeminiViewerComponent/_features/globals/visibilitySlice';
import { MdClose } from 'react-icons/md';

const ProcedureHeader = ({ epssPanelRef }) => {
    const dispatch = useDispatch();
    const activeTheme = useSelector(selectActiveTheme);
    const classes = useStyles(activeTheme);
    const config = useSelector(getConfig);
    const userData = useSelector(getChecklistData);
    const activeTree = useSelector(getActiveTree);
    const currentNode = useSelector(getCurrentNode);
    const activeSessionId = useSelector(getActiveSessionId);
    const loadedAssetType = useSelector(getLoadedAssetType);
    const { ref, width = 1 } = useResizeObserver();

    const theme = useTheme();
    const mdWidth = theme.breakpoints.values.md;
    const isFormSubmitted =
        userData &&
        typeof userData === 'object' &&
        Array.isArray(activeTree?.header_variables) &&
        activeTree?.header_variables?.length > 0 &&
        Object.keys(userData)?.length > 0
            ? activeTree?.header_variables?.filter(
                  (hdVar) =>
                      Object.keys(userData).includes(hdVar.name) &&
                      userData[hdVar.name]
              )?.length > 0
                ? true
                : false
            : false;
    const headerColumns = width > mdWidth && isFormSubmitted ? 8 : 12;

    const formColumns = width > mdWidth && isFormSubmitted ? 4 : 12;

    const nodes = Array.isArray(activeTree.nodes) && activeTree.nodes;
    const completionNode = nodes.find((node) => node?.completion === true);
    const isCompletionNode = useMemo(
        () =>
            config.modeType === 'viewer' &&
            activeTree?.save_sessions &&
            activeSessionId &&
            (currentNode?.type === ProcedureNodeTypes.content.type ||
                currentNode?.type === ProcedureNodeTypes.results.type) &&
            completionNode?.id === currentNode.id,
        [config, activeTree, activeSessionId, currentNode, completionNode]
    );

    const closeDrawer = () => {
        if (epssPanelRef.current) {
            epssPanelRef.current.style.width = '100vw';
        }

        dispatch(displayProcedureContentVersion(null));

        if (window.geminiViewManager) {
            window.geminiViewManager.updateView();
        }
    };

    return (
        <>
            <Grid
                container
                className={classes.container}
                ref={ref}
                style={
                    (activeTree?.image_attachments_allow === true
                        ? { minHeight: '52px' }
                        : {},
                    { position: 'relative', top: '0px' })
                }
            >
                {(isCompletionNode ||
                    (!activeTree?.hide_close_button && loadedAssetType !== 3) ||
                    activeTree.title) && (
                    <Grid
                        className={clsx(
                            {
                                [classes.childHeader]:
                                    activeTree?.parent_tree_id,
                            },
                            classes.header
                        )}
                        justifyContent="space-between"
                        paddingRight="55px !important"
                        item
                        xs={headerColumns}
                    >
                        <div className={classes.headerTitle}>
                            {activeTree.title}
                        </div>
                        <Box display="flex">
                            {isCompletionNode && (
                                <Box display="flex" justifyContent="center">
                                    <SessionSubmitBtn />
                                </Box>
                            )}
                            {!activeTree?.hide_close_button &&
                                loadedAssetType !== 3 &&
                                config.modeType === 'viewer' && (
                                    <IconButton
                                        color="inherit"
                                        onClick={closeDrawer}
                                    >
                                        <MdClose className="react-icon" />
                                    </IconButton>
                                )}
                        </Box>
                    </Grid>
                )}
                {isFormSubmitted && (
                    <Grid
                        className={classes.userInfoWrap}
                        item
                        xs={formColumns}
                    >
                        <Grid container className={classes.userInfoInnerWrap}>
                            {activeTree?.header_variables?.map((hdVar) => {
                                return userData?.[hdVar?.name] ? (
                                    <Grid
                                        item
                                        id={'user-info'}
                                        className={classes.userInfo}
                                        xs={12}
                                        sm={4}
                                        md={4}
                                    >
                                        {typeof userData?.[hdVar?.name] ===
                                        'object'
                                            ? userData?.[hdVar?.name].join(', ')
                                            : userData?.[hdVar?.name]}
                                    </Grid>
                                ) : null;
                            })}
                        </Grid>
                    </Grid>
                )}
            </Grid>
            {activeTree?.image_attachments_allow === true && (
                <Box position="absolute" zIndex="2" right="55px" top="15px">
                    <ImageAttachments
                        var_name={'procedure_' + activeTree?.procedure_id}
                        level="procedure"
                    />
                </Box>
            )}
            {currentNode?.image_attachments_allow === true &&
                currentNode?.type === ProcedureNodeTypes.content.type && (
                    <Box display="flex" justifyContent="center">
                        <ImageAttachments
                            var_name={'form_' + currentNode?.id}
                            level="form"
                        />
                    </Box>
                )}
        </>
    );
};

export { ProcedureHeader };
