import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AutoSizer } from 'react-virtualized';
import { MdAdd, MdRefresh } from 'react-icons/md';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useStyles } from './styles';
import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';
import { Action } from '_helpers';
import { tableStyles } from 'components/_Tables/TableCells/styles';
import { EnhancedTableToolbar } from 'GeminiViewerComponent/components/EnhancedTableToolbar';
import {
    labelHeader,
    labelCell,
    actionCell,
    checkCell,
    checkHeaderCell,
    VirtualTable,
    tagButtonTypeCell,
} from 'components';
import { fetchTags } from '../../../../../src/_features/tags/tagsSlice';
import { deleteKey } from 'GeminiViewerComponent/_helpers/cacheStore';
import SearchWithFilters from 'components/SearchWithFilters';
import {
    closePanel,
    openPanel,
    selectFormInfo,
    setEditObjectId,
} from '_features/common/formSlice';
import { makeButtonStyles } from '../../../../GeminiViewerComponent/components/styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { getAsset } from '_features/assets/assetManagerSlice';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { fetchAllHotspotIcons, getHotspotStatus } from 'shared/hotspotsSlice';
import { loadZone } from 'shared/loadZoneSlice';
import {
    selectVisualEditZone,
    setVisualEditZone,
} from '_features/common/editZoneSlice';
import {
    setDefaultItemPanelSection,
    setViewContentPanel,
} from 'GeminiViewerComponent/_features/contentPanel/contentPanelItemSlice';
import { displayProcedureContentVersion } from 'GeminiViewerComponent/_features/globals/visibilitySlice';
import { loadItem } from '_features/common/editItemSlice';
import { debounce } from 'GeminiViewerComponent/_helpers/lodashUtils';

function ItemTagTable({
    tags,
    pageInfo,
    itemTagPayload,
    setItemTagPayload,
    sortDirection,
    sortBy,
    handleSort,
}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useSelector(selectActiveTheme);
    const buttonStyles = makeButtonStyles(theme);
    const classes = useStyles();
    const tableClasses = tableStyles();
    const [selected, setSelected] = useState([]);
    const [selectedAll, setSelectedAll] = React.useState(false);

    const loadedRowsMap = useSelector((state) => state.tags.loadedTagsMap);
    const hotspotStatus = useSelector(getHotspotStatus);
    const visualEditZone = useSelector(selectVisualEditZone);
    const isFormOpen = useSelector(selectFormInfo);

    const headerHeight = 40;
    const rowHeight = 60;

    const isSelected = (id) => selected.indexOf(id) !== -1;
    const user = JSON.parse(localStorage.getItem('user'));

    const prevSelectedAll = usePrevious(selectedAll);
    if (prevSelectedAll && selectedAll) {
        if (selected.length !== tags.length) {
            setSelected(tags.map((row) => row.tag_id));
            setSelectedAll(true);
        }
    }

    const onRefreshIconClick = () => {
        deleteKey(`tags_${user.user_id}`);
        dispatch(fetchTags({ ...itemTagPayload }));
    };

    const handleClick = (event, tag_id) => {
        event.stopPropagation();
        const selectedIndex = selected.indexOf(tag_id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, tag_id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
        setSelectedAll(selected.length === tags.length);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelectedIds = tags.map((tag) => tag.tag_id);
            setSelected(newSelectedIds);
            setSelectedAll(true);
            return;
        }
        setSelected([]);
        setSelectedAll(false);
    };

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const handleRowClick = async ({ rowData }) => {
        await dispatch(closePanel({ formKey: 'itemTagForm' }));
        if (rowData?.tag_id) {
            await dispatch(
                setEditObjectId({
                    formKey: `itemTagForm`,
                    object_id: rowData?.tag_id,
                })
            );
            dispatch(openPanel({ formKey: `itemTagForm`, formAction: 'Edit' }));
        }
    };

    const clearLastViewerProperties = async () => {
        await dispatch(setVisualEditZone(false));
        await dispatch(setViewContentPanel(false));
        await dispatch(setDefaultItemPanelSection(0));
        await dispatch(displayProcedureContentVersion(null));
        await dispatch(
            closePanel({
                formKey: 'zoneForm',
                formAction: 'Edit',
                isContentPanelOpen: false,
            })
        );
        await dispatch(
            closePanel({
                formKey: 'itemForm',
                formAction: 'Edit',
                isContentPanelOpen: false,
            })
        );
        await dispatch(
            closePanel({
                formKey: 'contentForm',
                formAction: 'Edit',
                isContentPanelOpen: false,
            })
        );
        if (window.geminiViewManager) {
            window.geminiViewManager.updateView();
        }
    };

    const handleItemClick = async (event, zone_id, hotspot, link = null) => {
        event.stopPropagation();
        isFormOpen?.zoneForm?.isOpen &&
            dispatch(
                closePanel({ formKey: 'zoneForm', isContentPanelOpen: true })
            );
        isFormOpen?.itemForm?.isOpen &&
            dispatch(
                closePanel({ formKey: 'itemForm', isContentPanelOpen: true })
            );

        await dispatch(setViewContentPanel(true));
        if (link !== null) {
            await dispatch(setDefaultItemPanelSection(link.link_type_id));
        }
        await dispatch(
            loadItem({
                zoneId: zone_id,
                itemId: hotspot.item_id,
            })
        );
    };

    const handleAssetClick = async (event, asset_id = null) => {
        event.stopPropagation();
        if (asset_id) {
            if (visualEditZone === true) {
                clearLastViewerProperties();
            }
            navigate(`/asset/${asset_id}`);
        }
    };

    const handleZoneClick = async (
        event,
        rowData,
        assetId,
        item = null,
        link = null
    ) => {
        event.stopPropagation();
        dispatch(activateLoading());
        if (visualEditZone === true) {
            await clearLastViewerProperties();
        }
        dispatch(
            closePanel({
                formKey: 'contentForm',
            })
        );
        await dispatch(getAsset(assetId));
        if (hotspotStatus === LoadingStatus.Idle) {
            await dispatch(fetchAllHotspotIcons());
        }
        await dispatch(loadZone({ zoneId: rowData?.zone_id, refresh: false }));
        await dispatch(setVisualEditZone(true));
        if (item !== null) {
            handleItemClick(rowData.zone_id, item, link);
        }
        dispatch(deactivateLoading());
    };

    const columns = [
        {
            classes: tableClasses,
            minWidth: 50,
            maxWidth: 50,
            width: 50,
            label: '',
            dataKey: 'tag_id',
            padding: 'checkbox',
            cellLevel: 'tag',
            numSelected: selected.length,
            rowCount: tags.length,
            handleClick: handleClick,
            handleSelectAllClick: handleSelectAllClick,
            isSelected: isSelected,
            component: checkCell,
            headerComponent: checkHeaderCell,
        },
        {
            classes: tableClasses,
            minWidth: 50,
            maxWidth: 50,
            width: 50,
            label: '',
            dataKey: 'tag_id',
            padding: 'none',
            actionCellLevel: 'tag',
            targetPopup: 'tagManagement',
            formKey: 'itemTagForm',
            dispatch: dispatch,
            component: actionCell,
            headerComponent: labelHeader,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 120,
            minWidth: 120,
            label: 'Name',
            dataKey: 'name',
            padding: 'normal',
            component: labelCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 200,
            minWidth: 200,
            label: 'Assets',
            dataKey: 'assets',
            displayDataKey: 'asset_name',
            padding: 'none',
            component: tagButtonTypeCell,
            headerComponent: labelHeader,
            onClick: handleAssetClick,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 200,
            minWidth: 200,
            label: 'Zones',
            dataKey: 'zones',
            displayDataKey: 'zone_name',
            padding: 'none',
            component: tagButtonTypeCell,
            headerComponent: labelHeader,
            onClick: handleZoneClick,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 200,
            minWidth: 200,
            label: 'Items',
            dataKey: 'items',
            displayDataKey: 'item_name',
            padding: 'none',
            component: tagButtonTypeCell,
            headerComponent: labelHeader,
            onClick: handleZoneClick,
        },
    ];

    // eslint-disable-next-line no-unused-vars
    function loadMoreRows({ startIndex, stopIndex }) {}

    const debouncedSearch = useCallback(
        debounce((searchString) => {
            setItemTagPayload({
                ...itemTagPayload,
                searchString: searchString,
            });
        }, 1000),
        [itemTagPayload]
    );

    const handleCreateNew = () => {
        dispatch(
            openPanel({
                formKey: 'itemTagForm',
                formAction: 'Create',
                clearEditId: true,
            })
        );
    };

    return (
        <div className={classes.root}>
            {selected.length > 0 && (
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    toolTipTitle={'Delete'}
                    totalCount={tags?.length}
                >
                    <PopupAction
                        action={Action.DeleteArray}
                        object={{ tagIds: selected, setTagIds: setSelected }}
                        level={'tag'}
                        showLabel={false}
                    />
                </EnhancedTableToolbar>
            )}
            <div style={{ display: 'flex', gap: '5px' }}>
                <Tooltip title="Add New Account">
                    <IconButton
                        onClick={handleCreateNew}
                        size="large"
                        className={buttonStyles.contentAddButton}
                    >
                        <MdAdd className="react-icon" />
                    </IconButton>
                </Tooltip>
                <div style={{ flexGrow: 1 }}></div>
                <IconButton
                    onClick={onRefreshIconClick}
                    aria-label="close"
                    sx={{
                        color: '#707070',
                    }}
                    size="large"
                >
                    <MdRefresh
                        className="react-icon"
                        style={{ fontSize: '18px', color: 'black' }}
                    />
                </IconButton>
                <SearchWithFilters
                    filter={false}
                    onChange={(evt) => debouncedSearch(evt.target.value)}
                />
            </div>
            <div
                style={{
                    height: 'calc(100vh - 295px)',
                    minWidth: '500px',
                }}
            >
                <AutoSizer>
                    {({ width, height }) => (
                        <VirtualTable
                            initialRows={tags}
                            allRows={tags}
                            totalRowCount={pageInfo.TotalCount}
                            loadedRowsMap={loadedRowsMap}
                            loadMoreRows={loadMoreRows}
                            columnDefinitions={columns}
                            cellClassName={tableClasses.flexContainer}
                            className={tableClasses.table}
                            rowClassName={tableClasses.flexContainer}
                            headerHeight={headerHeight}
                            width={width}
                            height={height}
                            rowHeight={rowHeight}
                            onRowClick={handleRowClick}
                        />
                    )}
                </AutoSizer>
            </div>
        </div>
    );
}

export { ItemTagTable };
