// eslint-disable-next-line
import React, { createElement, useEffect } from 'react';
import clsx from 'clsx';
import { Box, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { orbitPreviewsStyles } from './styles';
import { loadZone } from 'shared/loadZoneSlice';
import { getConfig } from 'GeminiViewerComponent/_features/config/configSlice';
import { getScenesOpen } from 'GeminiViewerComponent/_features/globals/visibilitySlice';
import { getActiveAssetActiveZone } from 'GeminiViewerComponent/_features/asset/assetSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';

const OrbitPreviews = ({
    onCategoryClick,
    zonesByCategory,
    searchMenu,
    controlMenu,
}) => {
    const dispatch = useDispatch();

    const theme = useSelector(selectActiveTheme);

    const classes = orbitPreviewsStyles(theme);

    const config = useSelector(getConfig);
    const activeZone = useSelector(getActiveAssetActiveZone);
    const scenesOpen = useSelector(getScenesOpen);

    function handleClick(zone) {
        dispatch(loadZone({ zoneId: zone.zone_id }));
    }

    useEffect(() => {
        if (activeZone) {
            let domElement = document.getElementById(
                `zone_${activeZone.zone_id}`
            );
            if (domElement) {
                domElement.scrollIntoView();
            }
        }
    }, [activeZone]);

    const activeZoneId = activeZone == null ? '' : activeZone.zone_id;

    const zonesByCategoryKeys = Object.keys(zonesByCategory ?? {});
    const noCategories =
        zonesByCategoryKeys.length === 1 && zonesByCategoryKeys[0] === 'NONE';

    var categories = [];
    const allZones = [];

    var sortedKeys = zonesByCategoryKeys.sort((key1, key2) => {
        if (key1 === 'NONE') {
            return 1;
        }
        if (key2 === 'NONE') {
            return -1;
        }
        return key1.localeCompare(key2);
    });

    for (const idx in sortedKeys) {
        const categoryName = sortedKeys[idx];
        let category = zonesByCategory[categoryName];

        const zoneComponents = [];
        for (const idx in category.zones) {
            const zone = category.zones[idx];
            var previewUrl = zone.equirect_image_preview_url
                ? zone.equirect_image_preview_url
                : zone.flat_image_preview_url
                ? zone.flat_image_preview_url
                : zone.pano_id && config.paths.BASE_STORE_URL
                ? config.paths.BASE_STORE_URL +
                  `tiles/${zone.pano_id}/1/f/0/0.jpg`
                : '';

            const zoneComponent = (
                <div
                    id={`zone_${zone.zone_id}`}
                    key={zone.zone_id}
                    className={classes.scenePreviewContainer}
                    onClick={() => {
                        handleClick(zone);
                    }}
                >
                    <Tooltip title={zone.display_name}>
                        <Box style={{ width: '100%' }}>
                            {previewUrl ? (
                                <img
                                    className={clsx(classes.sceneImage, {
                                        [classes.sceneActive]:
                                            zone.zone_id === activeZoneId,
                                    })}
                                    src={previewUrl}
                                    alt={`${previewUrl}`.toLowerCase()}
                                />
                            ) : (
                                <div
                                    className={clsx(classes.sceneNoImage, {
                                        [classes.sceneActive]:
                                            zone.zone_id === activeZoneId,
                                    })}
                                >
                                    No Image
                                </div>
                            )}
                            <div
                                className={clsx(classes.scenePreviewTitle, {
                                    [classes.scenePreviewTitleActive]:
                                        zone.zone_id === activeZoneId,
                                })}
                            >
                                {zone.display_name}
                            </div>
                        </Box>
                    </Tooltip>
                </div>
            );

            if (noCategories) {
                allZones.push(zoneComponent);
            } else {
                zoneComponents.push(zoneComponent);
            }
        }

        if (!noCategories) {
            categories.push(
                <div className={classes.categoryContainer}>
                    <Tooltip title={categoryName}>
                        <div
                            className={classes.categoryTitle}
                            onClick={() => {
                                onCategoryClick(categoryName);
                            }}
                        >
                            {categoryName}
                        </div>
                    </Tooltip>
                    <div
                        className={clsx(classes.categoryZones, {
                            categoryOpen: category.open,
                        })}
                    >
                        {zoneComponents}
                    </div>
                </div>
            );
        }
    }
    let menu = 0;
    if (searchMenu === true) menu++;
    if (controlMenu === true) menu++;
    return (
        <div
            className={classes.scenePreviewsContainer}
            style={{ top: `${menu * 40}px` }}
        >
            <div
                className={clsx(classes.scenePreviews, {
                    [classes.sceneOpen]: scenesOpen,
                })}
            >
                {noCategories ? allZones : categories}
            </div>
        </div>
    );
};

export { OrbitPreviews };
