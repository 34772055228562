import React from 'react';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useStyles } from './styles';

const ClientDropdown = ({ clients, onSelect, clientId }) => {
    const classes = useStyles();
    const handleChange = (event) => {
        const clientId = event.target.value;
        if (onSelect) {
            onSelect(clientId);
        }
    };
    return (
        <FormControl
            sx={{
                minWidth: '200px',
                ml: 'auto',
                mr: '1rem',
                top: { sx: '0', md: '-20px' },
            }}
        >
            <InputLabel
                id="client-dropdown-label"
                className={classes.labelText}
            >
                Select Client
            </InputLabel>
            <Select
                labelId="client-dropdown-label"
                id="client-dropdown"
                value={clientId || null}
                onChange={handleChange}
            >
                {clients?.map((client) => (
                    <MenuItem key={client.client_id} value={client.client_id}>
                        {client.client_name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default ClientDropdown;
