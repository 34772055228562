import React, { useState, useEffect, useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { accountsSlice } from 'app/store';
import { makeFormStyles } from '../styles';
import { AppList } from 'components/AppList';
import SetDirtyForm from 'forms/SetDirtyForm';
import { FormActions } from '_helpers/form-action';
import { LinkTypes, ContentTypes } from '_helpers';
import { useTinyMCEInit } from 'hooks/useTinyMCEInit';
import { useConfigValues } from 'hooks/useConfigValues';
import { TinyMCEEditor } from 'components/TinyMCEEditor';
import { ImageResizer } from 'forms/ZoneForm/ImageResizer';
import {
    CaptionLanguages,
    mapVideoContentURL,
} from 'GeminiViewerComponent/_helpers';
import { ContentFormFields } from './components/ContentFormFields';
import {
    validChapterTime,
    validateContentForm,
} from './_helpers/contentFormValidations';
import { FormSwitchField, FormTextField } from 'components/TextField';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { blobToBase64, handleFileExtension } from 'hooks/useImageUploadHelper';
import { snackbarHandler } from 'GeminiViewerComponent/_helpers/snackbar-handler';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { LoadingLogo } from 'GeminiViewerComponent/components/LoadingLogo/LoadingLogo';
import { SelectionAutocomplete } from 'components/_Autocomplete/SelectionAutocomplete';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    setContentValidationModalContent,
    toggleContentValidationModal,
} from '_features/globals/visibilitySlice';
import {
    getProcedureTypes,
    selectProcedureTypesStatus,
} from '_features/procedures/proceduresSlice';
import {
    openPanel,
    setEditObjectId,
    setIsDirty,
} from '_features/common/formSlice';
import {
    getAssetsPicklist,
    selectAssetsPicklist,
    selectAssetsPicklistLoadingStatus,
} from '_features/picklists/pickListsSlice';
import {
    selectConfigValuesByFields,
    setConfigAssetClient,
    setConfigValuesAssetId,
} from '_features/configValues/configValuesSlice';
import {
    fetchContentById,
    fetchContentUsageById,
    editContentById,
    createContent,
    resetContentStatus,
    getContentUsage,
    getContentVersions,
    fetchContentVersions,
    updateContentVersion,
    setContentIdToCreateLink,
    createContentVersion,
    deleteContentVersionArray,
} from '_features/content/contentSlice';

const ContentForm = ({
    formAction,
    contentId,
    linkTypeId,
    tabView,
    setToggleView,
    selectVisibleAsset = null,
    navigateToLinkForm = false,
}) => {
    const dispatch = useDispatch();
    const tinyMCEInit = useTinyMCEInit();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);
    const contentUsage = useSelector(getContentUsage);
    const activeUser = useSelector(accountsSlice.selectActiveUser);
    const readOnlyMode = activeUser?.role === 'User' ? true : false;
    const [contentLinkTypeId, setContentLinkTypeId] = useState(null);
    const [imageSettings, setImageSettings] = useState({
        convert_image_to_jpeg: 'false',
        image_compression_percent: '4',
        max_image_width: 'None',
    });
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [isVideoCompressionEnable, setIsVideoCompressionEnable] =
        useState(false);
    const contentVersions = useSelector(getContentVersions);
    const procedureTypesStatus = useSelector(selectProcedureTypesStatus);
    const { configValues, configFields, validationTypes } = useConfigValues({
        clientId: activeUser?.client_id,
        assetId: selectedAsset,
    });
    const [loaded, setLoaded] = useState(
        formAction.id === FormActions.Create.id ? true : false
    );

    const createMode = useMemo(() => {
        return formAction.id === FormActions.Create.id;
    }, [formAction]);

    const editMode = useMemo(() => {
        return formAction.id === FormActions.Edit.id;
    }, [formAction]);

    const getContentTypes = (linkTypeId) => {
        let linkType = Object.keys(LinkTypes).find((lt) => {
            return LinkTypes[lt]?.id === linkTypeId;
        });
        let contentTypes = LinkTypes[linkType]?.contentTypes;
        return contentTypes || [];
    };

    const getDefaultContentTypeId = (linkTypeId) => {
        const contentTypes = getContentTypes(linkTypeId);
        if (contentTypes.length === 1) {
            return contentTypes[0].id;
        }
        return null;
    };

    const defaultContentTypeId = getDefaultContentTypeId(linkTypeId);

    const init = {
        display_name: '',
        skill_id: '',
        global: selectVisibleAsset ? false : true,
        asset_id: selectVisibleAsset ? selectVisibleAsset : '',
        external_id: '',
        additional_data: '',
        link_type_id: linkTypeId ?? '',
        content_data: null,
        img_name: null,
        url: '',
        content_type_id: defaultContentTypeId ? defaultContentTypeId : '',
        toggleFormType: '',
        content_versions: [],
        version: '',
        content_version_type: 1,
        embed_data: '',
        procedure_id: '',
        overlay_text: linkTypeId === LinkTypes.Image.id ? { html: '' } : null,
        transcribe_videos: false,
        transcribe_language: '',
        caption_files: [],
        caption_languages: [],
        chapters: [],
        sme_name: '',
        sme_title: '',
        sme_email: '',
        sme_phone: '',
        sme_zoom: '',
        sme_teams: '',
    };
    const [initValues, setInitValues] = useState({ ...init });

    useEffect(() => {
        const updateImageSettings = async () => {
            const updatedResponse = selectConfigValuesByFields(
                configValues,
                configFields,
                [
                    'image_compression_percent',
                    'max_image_width',
                    'convert_image_to_jpeg',
                    'compress_videos',
                ]
            );
            if (updatedResponse) {
                setIsVideoCompressionEnable(
                    updatedResponse?.['compress_videos'] === 'true'
                        ? true
                        : false
                );
                delete updatedResponse?.['compress_videos'];
                setImageSettings(updatedResponse);
            }
        };
        updateImageSettings();
    }, [configValues]);

    useEffect(() => {
        if (procedureTypesStatus === LoadingStatus.Idle) {
            dispatch(getProcedureTypes());
        }
    }, []);

    useEffect(() => {
        // Fetch simpleList on component load as the data get's updated on any asset change event.
        dispatch(getAssetsPicklist());
        if (formAction.id === FormActions.Edit.id) {
            setLoaded(false);
            const fetchData = async () => {
                await dispatch(fetchContentUsageById(contentId));
                await dispatch(fetchContentVersions(contentId));
                const objectData = await dispatch(
                    fetchContentById({ contentId: contentId })
                );
                let fetchedObject = objectData.payload;
                if (
                    fetchedObject.content_type_id === ContentTypes.Sme.id &&
                    fetchedObject.additional_data
                ) {
                    setContentLinkTypeId(+fetchedObject?.default_link_type_id);
                    let sme_data = JSON.parse(fetchedObject.additional_data);
                    delete fetchedObject.additional_data;
                    fetchedObject = { ...fetchedObject, ...sme_data };
                }

                const captionFiles =
                    fetchedObject?.last_content_version?.caption_languages?.map(
                        (language) => ({
                            name: Object.values(CaptionLanguages).find(
                                (captionLanguage) =>
                                    captionLanguage.LanguageCode === language
                            )?.DisplayName,
                            url: Object.values(CaptionLanguages).find(
                                (captionLanguage) =>
                                    captionLanguage.LanguageCode === language
                            )?.DisplayName,
                        })
                    );

                const captionLanguages =
                    fetchedObject?.last_content_version?.caption_languages?.map(
                        (language) =>
                            Object.values(CaptionLanguages).find(
                                (captionLanguage) =>
                                    captionLanguage.LanguageCode === language
                            )?.DisplayName
                    );

                setInitValues({
                    display_name: fetchedObject.display_name,
                    global: !fetchedObject.asset_id,
                    external_id: fetchedObject.external_id,
                    additional_data: fetchedObject.additional_data ?? '',
                    skill_id: fetchedObject.skill_id ?? '',
                    content_id: fetchedObject.content_id,
                    asset_id: fetchedObject.asset_id,
                    content_type_id: fetchedObject.content_type_id,
                    link_type_id: linkTypeId
                        ? +linkTypeId
                        : fetchedObject?.default_link_type_id,
                    toggleFormType: fetchedObject.last_content_version
                        ?.embed_data
                        ? ''
                        : fetchedObject.last_content_version?.original_file_name
                        ? 'file'
                        : 'url',
                    url:
                        fetchedObject.last_content_version?.embed_data ||
                        fetchedObject.last_content_version?.procedure_id ||
                        fetchedObject.last_content_version
                            ?.original_file_name ||
                        fetchedObject.last_content_version
                            ?.content_version_type == 1
                            ? fetchedObject.last_content_version?.url
                            : '',
                    version:
                        fetchedObject.last_content_version?.content_version_id,
                    content_data: null,
                    img_name:
                        fetchedObject.last_content_version?.original_file_name,
                    image_url:
                        fetchedObject.last_content_version
                            ?.content_version_type === 2
                            ? fetchedObject.last_content_version
                                  ?.encoding_status &&
                              fetchedObject.last_content_version?.encoding_status.toLowerCase() ===
                                  'complete'
                                ? fetchedObject.last_content_version
                                      ?.encoded_url
                                : fetchedObject.last_content_version?.url
                            : '',
                    embed_data: fetchedObject.last_content_version?.embed_data,
                    content_versions: contentVersions,
                    content_version_type: 1,
                    procedure_id:
                        fetchedObject.last_content_version?.procedure_id,
                    procedure_start_node:
                        fetchedObject.last_content_version
                            ?.procedure_start_node,
                    procedure_end_node:
                        fetchedObject.last_content_version?.procedure_end_node,
                    chapters:
                        fetchedObject.last_content_version?.chapters?.sort(
                            (a, b) => (a.chapter_id > b.chapter_id ? 1 : -1)
                        ),
                    overlay_text:
                        (linkTypeId
                            ? +linkTypeId
                            : fetchedObject?.default_link_type_id) ===
                            LinkTypes.Image.id &&
                        fetchedObject.last_content_version?.overlay_text
                            ? JSON.parse(
                                  fetchedObject.last_content_version
                                      ?.overlay_text
                              )
                            : { html: '' },
                    sme_email: fetchedObject.sme_email ?? '',
                    sme_name: fetchedObject.sme_name ?? '',
                    sme_title: fetchedObject.sme_title ?? '',
                    sme_phone: fetchedObject.sme_phone ?? '',
                    sme_zoom: fetchedObject.sme_zoom ?? '',
                    sme_teams: fetchedObject.sme_teams ?? '',
                    caption_files: captionFiles,
                    caption_languages: captionLanguages,
                });
                setLoaded(true);
            };
            fetchData();
        } else {
            setInitValues(init);
        }
    }, [formAction.id]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleAssetChange = async (assetId) => {
        await dispatch(
            setConfigAssetClient({
                assetId: 0,
                clientId: activeUser?.client_id,
            })
        );
        setSelectedAsset(assetId);
        await dispatch(setIsDirty(false));
    };

    const validateVideoCompression = useCallback(
        (file, link_type_id) => {
            const re = /(?:\.([^.]+))?$/;
            const extension = re.exec(file?.name)[1];
            let ext = extension.replace(/^.{1}/g, extension[0]);
            ext = ext ? ext.toLowerCase() : '';
            if (
                link_type_id === LinkTypes.Video.id &&
                !isVideoCompressionEnable &&
                ext !== 'mp4'
            ) {
                return [
                    {
                        code: 'allow-video-compression',
                        message:
                            'Enable video compression for uploading .webm, .mov, .flv, .avi formats',
                    },
                ];
            }
            return null;
        },
        [isVideoCompressionEnable]
    );

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        dispatch(activateLoading({ showProgress: true }));
        let resultAction = null;
        let updatedValues = null;
        if (values?.link_type_id === LinkTypes.SubjectMatterExpert.id) {
            const smeData = {
                sme_name: values?.sme_name,
                sme_title: values?.sme_title,
                sme_email: values?.sme_email,
                sme_phone: values?.sme_phone,
                sme_zoom: values?.sme_zoom,
                sme_teams: values?.sme_teams,
            };
            values = {
                ...values,
                display_name: `${values.sme_name} SME`,
                additional_data: JSON.stringify(smeData),
                content_type_id: ContentTypes.Sme.id,
            };
        }
        if (
            values.link_type_id === LinkTypes.Image.id &&
            values.toggleFormType === 'file'
        ) {
            if (values?.raw_file) {
                values = await compressImages({
                    file: values.raw_file,
                    mode: 'submit',
                    values: values,
                });
            }
        } else {
            delete values.overlay_text;
        }
        if (
            values.link_type_id === LinkTypes.Video.id &&
            values.toggleFormType === 'file'
        ) {
            if (values?.transcribe_videos === true) {
                if (!values?.transcribe_language) {
                    values = { ...values, transcribe_language: 'Auto Detect' };
                }
            } else {
                delete values?.transcribe_language;
            }
            if (
                values?.caption_languages?.filter(
                    (lang) =>
                        !lang ||
                        lang === '' ||
                        lang === 'Auto Detect' ||
                        lang === values?.transcribe_language
                )?.length > 0
            ) {
                dispatch(deactivateLoading());
                enqueueSnackbar(
                    'Please select caption language for all caption files',
                    {
                        action: (key) => <SnackbarDismiss key={key} />,
                        variant: 'error',
                    }
                );
                setSubmitting(false);
                return;
            }
            if (
                values?.caption_languages?.find((lang, index) =>
                    values?.caption_languages?.find(
                        (language, idx) => idx !== index && lang === language
                    )
                )
            ) {
                dispatch(deactivateLoading());
                enqueueSnackbar(
                    'Please select different language for caption files',
                    {
                        action: (key) => <SnackbarDismiss key={key} />,
                        variant: 'error',
                    }
                );
                setSubmitting(false);
                return;
            }
        } else {
            delete values?.transcribe_videos;
            delete values?.transcribe_language;
            delete values?.caption_files;
            delete values?.caption_languages;
        }
        delete values?.raw_file;
        delete values?.sme_name;
        delete values?.sme_title;
        delete values?.sme_email;
        delete values?.sme_phone;
        delete values?.sme_zoom;
        delete values?.sme_teams;
        let contentVersionsParams;
        try {
            switch (formAction.id) {
                case FormActions.Edit.id: {
                    updatedValues = { ...values };
                    delete updatedValues.global;
                    if (values.global) {
                        updatedValues.asset_id = null;
                    }
                    if (updatedValues.link_type_id === LinkTypes.Embed.id) {
                        contentVersionsParams = {
                            content_id: updatedValues.content_id,
                            embed_data: updatedValues.embed_data,
                            content_type_id: updatedValues.content_type_id,
                            version: updatedValues.version,
                            content_version_type: 3,
                        };
                    } else if (
                        updatedValues.link_type_id === LinkTypes.Procedure.id
                    ) {
                        contentVersionsParams = {
                            content_id: updatedValues.content_id,
                            procedure_id: updatedValues.procedure_id,
                            content_type_id: updatedValues.content_type_id,
                            version: updatedValues.version,
                            content_version_type: 4,
                            procedure_start_node:
                                updatedValues?.procedure_start_node,
                            procedure_end_node:
                                updatedValues?.procedure_end_node,
                        };
                    } else if (
                        updatedValues.link_type_id ===
                        LinkTypes.SubjectMatterExpert.id
                    ) {
                        contentVersionsParams = {
                            content_id: updatedValues.content_id,
                            url: '',
                            version: updatedValues.version,
                            content_data: updatedValues.content_data,
                            content_type_id: updatedValues.content_type_id,
                            content_version_type: 2,
                        };
                    } else {
                        if (
                            updatedValues.toggleFormType === 'url' &&
                            updatedValues.version
                        ) {
                            contentVersionsParams = {
                                content_id: updatedValues.content_id,
                                url: updatedValues.url,
                                content_data: null,
                                content_type_id: updatedValues.content_type_id,
                                version: updatedValues.version,
                                content_version_type: 1,
                            };
                        }
                        if (updatedValues.toggleFormType === 'file') {
                            contentVersionsParams = {
                                content_id: updatedValues.content_id,
                                url: '',
                                content_data: updatedValues.content_data,
                                content_type_id: updatedValues.content_type_id,
                                version: updatedValues.version,
                                content_version_type: 2,
                            };
                        }
                        if (updatedValues.link_type_id === LinkTypes.Image.id) {
                            contentVersionsParams = {
                                ...contentVersionsParams,
                                overlay_text: updatedValues?.overlay_text ?? {
                                    html: '',
                                },
                            };
                        }
                        if (
                            updatedValues.link_type_id === LinkTypes.Video.id &&
                            contentVersionsParams?.content_version_type === 2
                        ) {
                            if (
                                !(
                                    `${updatedValues?.compress_videos}` ===
                                        updatedValues?.defaultConfigValues
                                            .compress_videos.value &&
                                    `${updatedValues?.enable_hls}` ===
                                        updatedValues?.defaultConfigValues
                                            .enable_hls.value &&
                                    updatedValues?.max_video_height ===
                                        updatedValues?.defaultConfigValues
                                            .max_video_height.value
                                )
                            ) {
                                contentVersionsParams = {
                                    ...contentVersionsParams,
                                    compress_videos: `${updatedValues?.compress_videos}`,
                                    enable_hls: `${updatedValues?.enable_hls}`,
                                    max_video_height:
                                        updatedValues?.max_video_height,
                                };
                            }
                            contentVersionsParams = {
                                ...contentVersionsParams,
                                transcribe_videos:
                                    updatedValues?.transcribe_videos,
                                transcribe_language:
                                    updatedValues?.transcribe_language,
                            };
                            if (
                                Array.isArray(updatedValues?.caption_files) &&
                                Array.isArray(
                                    updatedValues?.caption_languages
                                ) &&
                                updatedValues?.caption_files?.length > 0 &&
                                updatedValues?.caption_languages?.length > 0 &&
                                updatedValues?.caption_languages?.length ===
                                    updatedValues?.caption_files?.length
                            ) {
                                contentVersionsParams = {
                                    ...contentVersionsParams,
                                    caption_files: updatedValues?.caption_files,
                                    caption_languages:
                                        updatedValues?.caption_languages,
                                };
                            }
                        }
                        if (updatedValues.link_type_id === LinkTypes.Video.id) {
                            contentVersionsParams = {
                                ...contentVersionsParams,
                                chapters: await validChapterTime(
                                    updatedValues?.chapters,
                                    values?.videoDuration
                                ),
                            };
                        }
                    }
                    delete updatedValues?.videoDuration;
                    delete updatedValues?.overlay_text;
                    delete updatedValues?.convert_image_to_jpeg;
                    delete updatedValues?.image_compression_percent;
                    delete updatedValues?.max_image_width;
                    delete updatedValues?.compress_videos;
                    delete updatedValues?.enable_hls;
                    delete updatedValues?.max_video_height;
                    delete updatedValues?.transcribe_videos;
                    delete updatedValues?.transcribe_language;
                    delete updatedValues?.caption_files;
                    delete updatedValues?.caption_languages;
                    delete updatedValues?.defaultConfigValues;
                    resultAction = await dispatch(
                        editContentById({
                            ...updatedValues,
                            content_id: contentId,
                        })
                    );
                    if (resultAction.meta.requestStatus === 'rejected') {
                        throw new SyntaxError(
                            `${resultAction?.error?.message}`
                        );
                    }
                    let updatedVersion;
                    let captionFiles = [];
                    let captionLanguages = [];

                    if (contentVersionsParams) {
                        // If SME and no previous content version then create new one now
                        if (
                            updatedValues.link_type_id ===
                            LinkTypes.SubjectMatterExpert.id
                        ) {
                            if (contentVersionsParams.content_data) {
                                updatedVersion = await dispatch(
                                    createContentVersion({
                                        ...contentVersionsParams,
                                    })
                                );
                            } else if (
                                updatedValues.image_url == null &&
                                contentVersionsParams.version
                            ) {
                                await dispatch(
                                    deleteContentVersionArray({
                                        contentIds: [
                                            contentVersionsParams.version,
                                        ],
                                    })
                                );
                            }
                        } else {
                            updatedVersion = await dispatch(
                                updateContentVersion({
                                    ...contentVersionsParams,
                                })
                            );
                        }
                        if (
                            updatedVersion?.meta?.requestStatus === 'rejected'
                        ) {
                            throw new SyntaxError(
                                `${updatedVersion?.error?.message}`
                            );
                        }
                    }
                    if (
                        resultAction?.payload.content_type_id ===
                            ContentTypes.Sme.id &&
                        resultAction?.payload.additional_data
                    ) {
                        let sme_data = JSON.parse(
                            resultAction?.payload.additional_data
                        );
                        delete resultAction?.payload?.additional_data;
                        resultAction.payload = {
                            ...resultAction?.payload,
                            ...sme_data,
                        };
                    }

                    if (updatedVersion) {
                        captionFiles =
                            updatedVersion?.payload?.caption_languages?.map(
                                (language) => ({
                                    name: Object.values(CaptionLanguages).find(
                                        (captionLanguage) =>
                                            captionLanguage.LanguageCode ===
                                            language
                                    )?.DisplayName,
                                    url: Object.values(CaptionLanguages).find(
                                        (captionLanguage) =>
                                            captionLanguage.LanguageCode ===
                                            language
                                    )?.DisplayName,
                                })
                            );

                        captionLanguages =
                            updatedVersion?.payload?.caption_languages?.map(
                                (language) =>
                                    Object.values(CaptionLanguages).find(
                                        (captionLanguage) =>
                                            captionLanguage.LanguageCode ===
                                            language
                                    )?.DisplayName
                            );
                    }

                    setInitValues({
                        ...initValues,
                        global: !resultAction?.payload?.asset_id,
                        asset_id: resultAction?.payload?.asset_id,
                        url: updatedVersion?.payload?.url,
                        image_url: mapVideoContentURL(updatedVersion?.payload),
                        toggleFormType:
                            updatedValues.link_type_id === LinkTypes.Embed.id ||
                            updatedValues.link_type_id ===
                                LinkTypes.Procedure.id
                                ? ''
                                : updatedValues.toggleFormType,
                        content_data: null,
                        img_name: updatedVersion?.payload?.original_file_name,
                        chapters: updatedVersion?.payload?.chapters?.sort(
                            (a, b) => (a.chapter_id > b.chapter_id ? 1 : -1)
                        ),
                        embed_data: updatedVersion?.payload?.embed_data,
                        link_type_id: updatedValues.link_type_id,
                        procedure_id: updatedVersion?.payload?.procedure_id,
                        procedure_start_node:
                            updatedVersion?.payload?.procedure_start_node,
                        procedure_end_node:
                            updatedVersion?.payload?.procedure_end_node,
                        overlay_text:
                            (updatedVersion?.payload?.overlay_text
                                ? JSON.parse(
                                      updatedVersion?.payload?.overlay_text
                                  )
                                : '') ?? null,
                        display_name: resultAction?.payload?.display_name ?? '',
                        skill_id: resultAction?.payload?.skill_id ?? '',
                        sme_email: resultAction?.payload?.sme_email ?? '',
                        sme_name: resultAction?.payload?.sme_name ?? '',
                        sme_title: resultAction?.payload?.sme_title ?? '',
                        sme_phone: resultAction?.payload?.sme_phone ?? '',
                        sme_zoom: resultAction?.payload?.sme_zoom ?? '',
                        sme_teams: resultAction?.payload?.sme_teams ?? '',
                        caption_files: captionFiles,
                        caption_languages: captionLanguages,
                    });
                    await dispatch(fetchContentVersions(contentId));
                    break;
                }
                case FormActions.Create.id: {
                    updatedValues = { ...values };
                    delete updatedValues.global;
                    if (values.global) {
                        updatedValues.asset_id = null;
                    }

                    if (updatedValues.link_type_id === LinkTypes.Embed.id) {
                        contentVersionsParams = {
                            embed_data: updatedValues.embed_data,
                            content_type_id: updatedValues.content_type_id,
                            content_version_type: 3,
                        };
                    } else if (
                        updatedValues.link_type_id === LinkTypes.Procedure.id
                    ) {
                        contentVersionsParams = {
                            procedure_id: updatedValues.procedure_id,
                            content_type_id: updatedValues.content_type_id,
                            content_version_type: 4,
                            procedure_start_node:
                                updatedValues?.procedure_start_node,
                            procedure_end_node:
                                updatedValues?.procedure_end_node,
                        };
                    } else if (
                        updatedValues.link_type_id ===
                        LinkTypes.SubjectMatterExpert.id
                    ) {
                        contentVersionsParams = {
                            url: '',
                            content_data: updatedValues.content_data,
                            content_type_id: updatedValues.content_type_id,
                            content_version_type: 2,
                        };
                    } else {
                        if (updatedValues.toggleFormType === 'url') {
                            contentVersionsParams = {
                                url: updatedValues.url,
                                content_type_id: updatedValues.content_type_id,
                                content_version_type: 1,
                            };
                        }
                        if (updatedValues.toggleFormType === 'file') {
                            contentVersionsParams = {
                                content_data: updatedValues.content_data,
                                content_type_id: updatedValues.content_type_id,
                                content_version_type: 2,
                            };
                        }
                        if (updatedValues.link_type_id === LinkTypes.Image.id) {
                            contentVersionsParams = {
                                ...contentVersionsParams,
                                overlay_text: updatedValues?.overlay_text ?? {
                                    html: '',
                                },
                            };
                        }
                        if (
                            updatedValues.link_type_id === LinkTypes.Video.id &&
                            contentVersionsParams?.content_version_type === 2
                        ) {
                            if (
                                !(
                                    `${updatedValues?.compress_videos}` ===
                                        updatedValues?.defaultConfigValues
                                            .compress_videos.value &&
                                    `${updatedValues?.enable_hls}` ===
                                        updatedValues?.defaultConfigValues
                                            .enable_hls.value &&
                                    updatedValues?.max_video_height ===
                                        updatedValues?.defaultConfigValues
                                            .max_video_height.value
                                )
                            ) {
                                contentVersionsParams = {
                                    ...contentVersionsParams,
                                    compress_videos: `${updatedValues?.compress_videos}`,
                                    enable_hls: `${updatedValues?.enable_hls}`,
                                    max_video_height:
                                        updatedValues?.max_video_height,
                                };
                            }
                            contentVersionsParams = {
                                ...contentVersionsParams,
                                transcribe_videos:
                                    updatedValues?.transcribe_videos,
                                transcribe_language:
                                    updatedValues?.transcribe_language,
                            };
                            if (
                                Array.isArray(updatedValues?.caption_files) &&
                                Array.isArray(
                                    updatedValues?.caption_languages
                                ) &&
                                updatedValues?.caption_files?.length > 0 &&
                                updatedValues?.caption_languages?.length > 0 &&
                                updatedValues?.caption_languages?.length ===
                                    updatedValues?.caption_files?.length
                            ) {
                                contentVersionsParams = {
                                    ...contentVersionsParams,
                                    caption_files: updatedValues?.caption_files,
                                    caption_languages:
                                        updatedValues?.caption_languages,
                                };
                            }
                        }
                        if (updatedValues.link_type_id === LinkTypes.Video.id) {
                            contentVersionsParams = {
                                ...contentVersionsParams,
                                chapters: await validChapterTime(
                                    updatedValues?.chapters,
                                    values?.videoDuration
                                ),
                            };
                        }
                    }
                    delete updatedValues?.videoDuration;
                    delete updatedValues?.overlay_text;
                    delete updatedValues?.convert_image_to_jpeg;
                    delete updatedValues?.image_compression_percent;
                    delete updatedValues?.max_image_width;
                    delete updatedValues?.compress_videos;
                    delete updatedValues?.enable_hls;
                    delete updatedValues?.max_video_height;
                    delete updatedValues?.transcribe_videos;
                    delete updatedValues?.transcribe_language;
                    delete updatedValues?.caption_files;
                    delete updatedValues?.caption_languages;
                    delete updatedValues?.defaultConfigValues;
                    delete updatedValues?.image_url;
                    resultAction = await dispatch(
                        createContent({
                            ...updatedValues,
                            content_version: contentVersionsParams,
                        })
                    );
                    if (resultAction.meta.requestStatus === 'rejected') {
                        throw new SyntaxError(
                            `${resultAction?.error?.message}`
                        );
                    }
                    if (!resultAction.error) {
                        setInitValues({ ...init });
                        resetForm();
                    }
                    if (navigateToLinkForm && !resultAction.error) {
                        await dispatch(
                            setContentIdToCreateLink(
                                resultAction?.payload?.content_id
                            )
                        );
                        navigateToLinkForm();
                    }
                    break;
                }
                default:
                    break;
            }

            unwrapResult(resultAction);
            const { message, variant } = snackbarHandler(
                resultAction.meta.requestStatus,
                formAction.label
            );
            enqueueSnackbar(message, {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: variant,
            });
            await dispatch(resetContentStatus());
            dispatch(deactivateLoading());
        } catch (err) {
            dispatch(deactivateLoading());
            enqueueSnackbar(
                err.message !== 'undefined'
                    ? err.message
                    : formAction.id === FormActions.Edit.id
                    ? 'Update Failed'
                    : 'Create Failed',
                {
                    action: (key) => <SnackbarDismiss key={key} />,
                    variant: 'error',
                }
            );
        }

        setSubmitting(false);
    };

    const handleToggle = (value, setFieldValue) => {
        setFieldValue('toggleFormType', value);
        setFieldValue('content_version_type', value === 'file' ? 2 : 1);
    };

    const handleViewLink = (content) => {
        dispatch(setContentValidationModalContent(content));
        dispatch(toggleContentValidationModal());
    };

    function handleCreateContentClick(contentId) {
        dispatch(
            setEditObjectId({
                formKey: `contentVersionForm`,
                object_id: contentId,
            })
        );
        dispatch(
            openPanel({ formKey: `contentVersionForm`, formAction: 'Create' })
        );
    }

    const handleContentVersionFields = (e, setFieldValue) => {
        const value = e.target.value === '' ? '' : +e.target.value;
        setFieldValue('link_type_id', value);
        setFieldValue(
            'content_type_id',
            getDefaultContentTypeId(value) ? getDefaultContentTypeId(value) : ''
        );
        setFieldValue('embed_data', null);
        setFieldValue('content_data', null);
        setFieldValue('img_name', null);
        setFieldValue('procedure_id', null);
        setFieldValue('sme_name', '');
        setFieldValue('sme_title', '');
        setFieldValue('sme_email', '');
        setFieldValue('sme_phone', '');
        setFieldValue('sme_zoom', '');
        setFieldValue('sme_teams', '');
        if (value === LinkTypes.Embed.id || value === LinkTypes.Procedure.id) {
            setFieldValue('toggleFormType', '');
        } else {
            setFieldValue('toggleFormType', 'url');
        }
    };

    const compressImages = useCallback(
        async ({
            file,
            mode = 'drop',
            setFieldValue = null,
            values = null,
        }) => {
            let updatedUrl = '';
            let previewFile = null;
            let imageResizer = new ImageResizer(file);
            let updateValues = values;
            if (imageSettings?.['convert_image_to_jpeg'] === 'true') {
                let compressPercent =
                    +imageSettings?.['image_compression_percent'] / 5;
                previewFile = await imageResizer.run(
                    +imageSettings?.['max_image_width'],
                    0,
                    compressPercent
                );
            } else {
                previewFile = await imageResizer.run(
                    +imageSettings?.['max_image_width'],
                    0,
                    null
                );
            }
            updatedUrl = await blobToBase64(previewFile);
            if (mode === 'drop' && setFieldValue) {
                setFieldValue('image_url', updatedUrl);
            }
            if (mode === 'submit' && values) {
                updateValues = { ...updateValues, image_url: updatedUrl };
            }
            // Only try to set name if it has a setter
            if (Object.getOwnPropertyDescriptor(previewFile, 'name')?.set) {
                previewFile.name = file.name;
            }
            if (mode === 'drop' && setFieldValue) {
                setFieldValue('content_data', previewFile);
                setFieldValue('img_name', file.name);
                handleFileExtension(file, setFieldValue);
            }
            if (mode === 'submit' && values) {
                updateValues = {
                    ...updateValues,
                    content_data: previewFile,
                };
                return updateValues;
            }
            return null;
        },
        [imageSettings]
    );

    const generateVideoThumbnail = (file) => {
        return new Promise((resolve) => {
            const canvas = document.createElement('canvas');
            const video = document.createElement('video');

            video.autoplay = true;
            video.muted = true;
            video.src = URL.createObjectURL(file) + '#t=0.5';
            video.onloadeddata = () => {
                let ctx = canvas.getContext('2d');

                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;

                ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
                video.pause();
                return resolve(canvas.toDataURL('image/png'));
            };
        });
    };

    const onDrop = useCallback(
        async ({ file, setFieldValue, values }) => {
            if (values?.link_type_id === LinkTypes.Image.id) {
                setFieldValue('raw_file', file);
                await compressImages({
                    file: file,
                    mode: 'drop',
                    setFieldValue: setFieldValue,
                });
            } else {
                const reader = new FileReader();

                reader.onabort = () => console.log('file reading was aborted');
                reader.onerror = () => console.log('file reading has failed');
                reader.onload = async (e) => {
                    setFieldValue('content_data', file);
                    setFieldValue('img_name', file?.name);
                    handleFileExtension(file, setFieldValue);
                    if (values?.link_type_id === LinkTypes.Video.id) {
                        setFieldValue('raw_file', file);
                        const re = /(?:\.([^.]+))?$/;
                        const extension = re.exec(file?.name)[1];
                        if (
                            ContentTypes.Mov.extension.endsWith(extension) ||
                            ContentTypes.Flv.extension.endsWith(extension) ||
                            ContentTypes.Avi.extension.endsWith(extension)
                        ) {
                            setFieldValue('image_url', 'no_video_preview.svg');
                        } else {
                            setFieldValue(
                                'image_url',
                                await generateVideoThumbnail(file)
                            );
                        }
                    } else {
                        setFieldValue('image_url', e.target.result);
                    }
                };
                reader.readAsDataURL(file);
            }
        },
        [imageSettings, configValues]
    );

    const getContentMaxSize = (linkTypeId) => {
        let linkType = Object.keys(LinkTypes).find((lt) => {
            return LinkTypes[lt]?.id === linkTypeId;
        });
        return LinkTypes[linkType]?.fileSizeLimit || 104715200;
    };

    const onDropError = useCallback(
        ({ err, setFieldError, setFieldTouched }) => {
            if (err?.[0]?.errors?.[0]?.message) {
                setFieldError('content_data', err[0].errors[0].message);
                setFieldTouched('content_data', true, false);
            }
        },
        []
    );

    return !loaded ? (
        <LoadingLogo styleClassName={classes.loadingSvg} />
    ) : (
        <Formik
            enableReinitialize={true}
            initialValues={{ ...initValues }}
            validationSchema={Yup.object({
                display_name: Yup.string()
                    .max(150, 'Must be 150 characters or less')
                    .required('Required'),
                asset_id: Yup.string(),
                skill_id: Yup.number()
                    .min(1, 'Must be between 1 to 1,000,000')
                    .max(1000000, 'Must be between 1 to 1,000,000'),
                global: Yup.boolean(),
                external_id: Yup.string(),
                // additional_data: Yup.string(),
                ...(createMode && {
                    link_type_id: Yup.string().required('Required'),
                }),
                ...(initValues.toggleFormType === 'url' && {
                    content_type_id: Yup.string().required('Required'),
                }),
                ...(createMode &&
                    initValues.toggleFormType === 'url' && {
                        url: Yup.string().required('Required'),
                    }),
                ...(createMode &&
                    initValues.toggleFormType === 'file' && {
                        content_data: Yup.mixed()
                            .nullable(false)
                            .required('Required'),
                    }),
                ...(contentLinkTypeId === LinkTypes.SubjectMatterExpert.id && {
                    sme_name: Yup.string()
                        .max(150, 'Must be 150 characters or less')
                        .required('Required'),
                    sme_email: Yup.string()
                        .email('Invalid email')
                        .required('Required'),
                    sme_phone: Yup.string()
                        .length(10, 'Invalid Phone Number')
                        .required('Required'),
                }),
            })}
            validate={validateContentForm}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                handleSubmit(values, setSubmitting, resetForm);
            }}
        >
            {({
                values,
                setFieldValue,
                isSubmitting,
                setFieldError,
                setFieldTouched,
            }) => (
                <Form className={classes.form}>
                    {!tabView && (
                        <>
                            <FormSwitchField
                                label="Visible to all assets"
                                type="checkbox"
                                checked={values.global}
                                onChange={async (e) => {
                                    setFieldValue(
                                        'global',
                                        e.target.checked,
                                        false
                                    );
                                    dispatch(
                                        setConfigValuesAssetId({
                                            assetId: 0,
                                            clientId: activeUser?.client_id,
                                        })
                                    );
                                    setSelectedAsset(0);
                                    if (e.target.checked === true) {
                                        setFieldValue('asset_id', '');
                                    }
                                }}
                                name="global"
                                disabled={readOnlyMode}
                            />

                            {!values.global && (
                                <Field
                                    className={classes.input}
                                    name="asset_id"
                                >
                                    {({
                                        field: { value },
                                        form: { setFieldValue },
                                    }) => (
                                        <SelectionAutocomplete
                                            title="Asset assignment"
                                            keyProperty="asset_id"
                                            nameProperty="display_name"
                                            entityIds={value}
                                            setEntityIds={setFieldValue}
                                            entitySelector={
                                                selectAssetsPicklist
                                            }
                                            fetchEntityPage={getAssetsPicklist}
                                            formField={'asset_id'}
                                            multiSelection={false}
                                            entityStatusSelector={
                                                selectAssetsPicklistLoadingStatus
                                            }
                                            readOnly={readOnlyMode}
                                            onCustomChange={(e) =>
                                                handleAssetChange(e?.asset_id)
                                            }
                                        />
                                    )}
                                </Field>
                            )}
                        </>
                    )}
                    {values.link_type_id !==
                        LinkTypes.SubjectMatterExpert.id && (
                        <>
                            <FormTextField
                                label="Content Name"
                                name="display_name"
                                placeholder="Content Name"
                                disabled={readOnlyMode}
                            />
                            <FormTextField
                                label="Additional Data"
                                name="additional_data"
                                placeholder="Additional Data"
                                disabled={readOnlyMode}
                            />
                        </>
                    )}
                    {values.link_type_id !==
                        LinkTypes.SubjectMatterExpert.id && (
                        <FormTextField
                            label="Skill Id"
                            type="number"
                            name="skill_id"
                            placeholder="Skill Id"
                            disabled={readOnlyMode}
                        />
                    )}
                    {!tabView && (
                        <>
                            <Field
                                className={classes.input}
                                style={{ padding: '7px 10px' }}
                                as="select"
                                name="link_type_id"
                                onChange={(e) => {
                                    setFieldValue(
                                        'link_type_id',
                                        e.target.value
                                    );
                                    setContentLinkTypeId(+e.target.value);
                                    handleContentVersionFields(
                                        e,
                                        setFieldValue
                                    );
                                }}
                                disabled={readOnlyMode}
                            >
                                <option value="" defaultValue disabled>
                                    Select Type
                                </option>
                                {Object.keys(LinkTypes).map((lt) => (
                                    <option
                                        key={LinkTypes[lt].label}
                                        value={LinkTypes[lt].id}
                                        defaultValue={LinkTypes[lt].id === 1}
                                    >
                                        {LinkTypes[lt].label}
                                    </option>
                                ))}
                            </Field>
                            <ErrorMessage
                                className={classes.inputErr}
                                name="link_type_id"
                                component="div"
                            />
                        </>
                    )}

                    {(editMode ||
                        (createMode && values.link_type_id !== '')) && (
                        <ContentFormFields
                            contentId={contentId}
                            editMode={editMode}
                            createMode={createMode}
                            readOnlyMode={readOnlyMode}
                            values={values}
                            getContentTypes={getContentTypes}
                            getDefaultContentTypeId={getDefaultContentTypeId}
                            initValues={initValues}
                            setInitValues={setInitValues}
                            handleToggle={handleToggle}
                            getContentMaxSize={getContentMaxSize}
                            onDrop={onDrop}
                            onDropError={onDropError}
                            validateVideoCompression={validateVideoCompression}
                            handleViewLink={handleViewLink}
                            handleCreateContentClick={handleCreateContentClick}
                            setFieldError={setFieldError}
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                            imageSettings={imageSettings}
                            setImageSettings={setImageSettings}
                            setIsVideoCompressionEnable={
                                setIsVideoCompressionEnable
                            }
                            configValues={configValues}
                            configFields={configFields}
                            validationTypes={validationTypes}
                        />
                    )}

                    {values?.link_type_id === LinkTypes.Image.id && (
                        <>
                            <Box mt="12px" />
                            <Field name="overlay_text">
                                {({ field, form: { setFieldValue } }) => (
                                    <TinyMCEEditor
                                        id="overlay_text"
                                        tinymceScriptSrc={
                                            process.env.PUBLIC_URL +
                                            '/tinymce/tinymce.min.js'
                                        }
                                        init={{
                                            ...tinyMCEInit,
                                        }}
                                        textareaName="overlay_text"
                                        onBlur={field.onBlur}
                                        onEditorChange={(content) => {
                                            if (!readOnlyMode) {
                                                setFieldValue('overlay_text', {
                                                    html: content,
                                                });
                                            }
                                        }}
                                        value={values?.overlay_text?.html ?? ''}
                                    />
                                )}
                            </Field>
                        </>
                    )}

                    {readOnlyMode !== true && (
                        <>
                            <SetDirtyForm />
                            <button
                                className={classes.submit}
                                type="submit"
                                disabled={isSubmitting}
                            >
                                Submit
                            </button>
                        </>
                    )}

                    {tabView && (
                        <button
                            type="button"
                            className={classes.cancel}
                            onClick={() => setToggleView(false)}
                        >
                            Cancel
                        </button>
                    )}

                    {editMode && contentUsage?.assets?.length > 0 && (
                        <AppList
                            data={contentUsage}
                            listHeader={`Content Locations (${contentUsage.assets.length})`}
                            keyProperty="asset_name"
                            nameProperty="asset_name"
                            zoneKey="zones"
                            id="content-usage-id"
                            zoneProperty="zone_name"
                            itemKey="items"
                            itemProperty="item_name"
                            linkKey="links"
                            linkProperty="link_name"
                        />
                    )}
                </Form>
            )}
        </Formik>
    );
};

export { ContentForm };
