import { createSlice } from '@reduxjs/toolkit';
import { last } from 'GeminiViewerComponent/_helpers/lodashUtils';

const initialState = {
    orientation: 'vertical',
    zoneIdStack: [],
    displayTooltip: {
        itemLabel: false,
        navLabel: false,
    },
};

const zonePreviewSlice = createSlice({
    name: 'zonePreview',
    initialState,
    reducers: {
        setOrientation: (state, action) => {
            state.orientation = action.payload;
        },
        resetZonePreviewState: (state) => initialState,
        clearActiveZones: (state, action) => {
            state.zoneIdStack = [];
        },
        pushZone: (state, action) => {
            const activeZoneId = last(state.zoneIdStack);
            if (action.payload !== activeZoneId) {
                state.zoneIdStack.push(action.payload);
            }
        },
        popZone: (state, action) => {
            if (state.zoneIdStack.length > 1) {
                state.zoneIdStack.pop();
            }
        },
        setItemTooltip: (state, action) => {
            state.displayTooltip.itemLabel = action.payload;
        },
        setNavTooltip: (state, action) => {
            state.displayTooltip.navLabel = action.payload;
        },
    },
    extraReducers: {},
});

export const getPreviousZoneId = (state) =>
    state.zonePreview.zoneIdStack.length > 1
        ? state.zonePreview.zoneIdStack[
              state.zonePreview.zoneIdStack.length - 2
          ]
        : null;

export const getOrientation = (state) => state.zonePreview.orientation;
export const getItemTooltip = (state) =>
    state.zonePreview.displayTooltip.itemLabel;
export const getNavTooltip = (state) =>
    state.zonePreview.displayTooltip.navLabel;

export const {
    clearActiveZones,
    pushZone,
    popZone,
    setOrientation,
    setItemTooltip,
    setNavTooltip,
    resetZonePreviewState,
} = zonePreviewSlice.actions;

export default zonePreviewSlice.reducer;
