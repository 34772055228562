function isHotSpotVisible(audienceIds, hotspot, hotspotItems) {
    if (!audienceIds) {
        return true;
    }

    const item = hotspotItems.find((x) => hotspot.item_id === x.item_id);

    if (item) {
        return (
            !item.audience_ids ||
            item.audience_ids.length === 0 ||
            item.audience_ids.some((audienceId) => {
                return audienceIds.includes(audienceId);
            })
        );
    }

    return true;
}

function isZoneVisible(audienceIds, zone) {
    if (!audienceIds) {
        return true;
    }
    if (zone) {
        return (
            !zone.audience_ids ||
            zone.audience_ids.length === 0 ||
            zone.audience_ids.some((audienceId) => {
                return audienceIds.includes(audienceId);
            })
        );
    }

    return true;
}

function hotSpotHasTag(tagIds, hotspot, allHotspotItems, ShowOnlyByFilter) {
    if ((!tagIds || tagIds.length === 0) && ShowOnlyByFilter === false) {
        return true;
    }

    const hasTag = hotspot.tags?.some((tag) => {
        return tagIds.includes(tag.tag_id);
    });

    return hasTag;
}

function zoneHasTag(tagIds, zone) {
    if (!zone || !tagIds || tagIds.length === 0) {
        return true;
    }
    const zoneTagsIds = zone?.zone_tags?.map((tag) => tag.tag_id);
    const zoneItemIds = zone?.item_tags?.map((tag) => tag.tag_id);
    const hasZoneTag = zoneItemIds?.some((tagId) => {
        return tagIds.includes(tagId);
    });
    const hasItemTag = zoneTagsIds?.some((tagId) => {
        return tagIds.includes(tagId);
    });
    return hasZoneTag || hasItemTag;
}

function getUnPositionedItems(activeZone) {
    return activeZone?.items?.length > 0
        ? activeZone?.items.filter(
              (item) =>
                  (!item.yaw && !item.flat_x) ||
                  (!item.flat_x && (item.flat_x < 0 || item.flat_y < 0))
          )
        : [];
}

export {
    isHotSpotVisible,
    isZoneVisible,
    hotSpotHasTag,
    zoneHasTag,
    getUnPositionedItems,
};
