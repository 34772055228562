import React, { useState, useRef, useEffect, useCallback } from 'react';
import { MdAdd } from 'react-icons/md';
import { AutoSizer } from 'react-virtualized';
import { IconButton, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { Action } from '_helpers';
import { useStyles } from './styles';
import SearchWithFilters from 'components/SearchWithFilters';
import { tableStyles } from 'components/_Tables/TableCells/styles';
import { debounce } from 'GeminiViewerComponent/_helpers/lodashUtils';
import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { makeButtonStyles } from '../../../../GeminiViewerComponent/components/styles';
import { EnhancedTableToolbar } from 'GeminiViewerComponent/components/EnhancedTableToolbar';
import {
    setHotspotAsDefault,
    setHotspotAsHighlight,
} from 'shared/hotspotsSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    closePanel,
    openPanel,
    setEditObjectId,
} from '_features/common/formSlice';
import {
    labelHeader,
    labelCell,
    actionCell,
    checkCell,
    hotspotPreviewCell,
    checkHeaderCell,
    conditionalLabelCell,
    hotspotDefaultIcon,
    VirtualTable,
} from 'components';

function HotspotTable({
    hotspots,
    pageInfo,
    hotspotPayload,
    setHotspotPayload,
    sortDirection,
    sortBy,
    handleSort,
}) {
    const dispatch = useDispatch();
    const theme = useSelector(selectActiveTheme);
    const buttonStyles = makeButtonStyles(theme);
    const classes = useStyles();
    const tableClasses = tableStyles();

    const [selected, setSelected] = useState([]);
    const [selectedAll, setSelectedAll] = React.useState(false);

    const loadedRowsMap = useSelector(
        (state) => state.hotspots.loadedHotspotsMap
    );

    const headerHeight = 40;
    const rowHeight = 60;
    const isSelected = (id) => selected.indexOf(id) !== -1;

    const prevSelectedAll = usePrevious(selectedAll);
    if (prevSelectedAll && selectedAll) {
        if (selected.length !== hotspots.length) {
            setSelected(hotspots.map((row) => row.hotspot_icon_id));
            setSelectedAll(true);
        }
    }

    const handleClick = (event, hotspot_icon_id) => {
        event.stopPropagation();
        const selectedIndex = selected.indexOf(hotspot_icon_id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, hotspot_icon_id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
        setSelectedAll(selected.length === hotspots.length);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelectedIds = hotspots.map(
                (hotspot) => hotspot.hotspot_icon_id
            );
            setSelected(newSelectedIds);
            setSelectedAll(true);
            return;
        }
        setSelected([]);
        setSelectedAll(false);
    };

    const updateDefaultHotspotIcon = async (rowData) => {
        if (!rowData.is_default) {
            await dispatch(activateLoading());
            await dispatch(setHotspotAsDefault(rowData?.hotspot_icon_id));
            await dispatch(deactivateLoading());
        }
    };

    const updateHighlightHotspotIcon = async (rowData) => {
        if (!rowData.is_highlight) {
            await dispatch(activateLoading());
            await dispatch(setHotspotAsHighlight(rowData?.hotspot_icon_id));
            await dispatch(deactivateLoading());
        }
    };

    const handleRowClick = async ({ rowData }) => {
        await dispatch(closePanel({ formKey: 'hotspotForm' }));
        if (rowData?.hotspot_icon_id) {
            await dispatch(
                setEditObjectId({
                    formKey: `hotspotForm`,
                    object_id: rowData?.hotspot_icon_id,
                })
            );
            dispatch(openPanel({ formKey: `hotspotForm`, formAction: 'Edit' }));
        }
    };

    const columns = [
        {
            classes: tableClasses,
            minWidth: 50,
            maxWidth: 50,
            width: 50,
            label: 'Select',
            dataKey: 'hotspot_icon_id',
            padding: 'checkbox',
            cellLevel: 'hotspot_icon',
            numSelected: selected.length,
            rowCount: hotspots.length,
            handleClick: handleClick,
            handleSelectAllClick: handleSelectAllClick,
            isSelected: isSelected,
            component: checkCell,
            headerComponent: checkHeaderCell,
        },
        {
            classes: tableClasses,
            minWidth: 50,
            maxWidth: 50,
            width: 50,
            label: '',
            dataKey: 'hotspot_icon_id',
            padding: 'none',
            actionCellLevel: 'hotspot_icon',
            targetPopup: 'hotspotManagement',
            formKey: 'hotspotForm',
            dispatch: dispatch,
            component: actionCell,
            headerComponent: labelHeader,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 120,
            minWidth: 120,
            label: 'Hotspot',
            dataKey: 'display_name',
            padding: 'normal',
            component: labelCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 120,
            minWidth: 120,
            label: 'Preview Image',
            dataKey: 'image_url',
            padding: 'normal',
            component: hotspotPreviewCell,
            headerComponent: labelHeader,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 120,
            minWidth: 120,
            label: 'Type',
            dataKey: 'hotspot_type_id',
            padding: 'normal',
            conditionalFn: (data) => (data === 1 ? 'Item' : 'Navigation'),
            component: conditionalLabelCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 120,
            minWidth: 120,
            label: 'Default',
            dataKey: 'is_default',
            padding: 'normal',
            component: hotspotDefaultIcon,
            onClick: updateDefaultHotspotIcon,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 120,
            minWidth: 120,
            label: 'Highlight',
            dataKey: 'is_highlight',
            padding: 'normal',
            component: hotspotDefaultIcon,
            onClick: updateHighlightHotspotIcon,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
    ];

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    // eslint-disable-next-line no-unused-vars
    function loadMoreRows({ startIndex, stopIndex }) {}

    const debouncedSearch = useCallback(
        debounce((searchString) => {
            setHotspotPayload({
                ...hotspotPayload,
                searchString: searchString,
            });
        }, 1000),
        [hotspotPayload]
    );

    const handleCreateNew = () => {
        dispatch(
            openPanel({
                formKey: `hotspotForm`,
                formAction: 'Create',
                clearEditId: true,
            })
        );
    };

    return (
        <div className={classes.root}>
            {selected.length > 0 && (
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    toolTipTitle={'Delete'}
                    totalCount={pageInfo.TotalCount}
                >
                    <PopupAction
                        action={Action.DeleteArray}
                        object={{
                            hotspotIds: selected,
                            setHotspotIds: setSelected,
                        }}
                        level={'hotspot_icon'}
                        showLabel={false}
                    />
                </EnhancedTableToolbar>
            )}
            <div style={{ display: 'flex', gap: '5px' }}>
                <Tooltip title="Add New Hotspot Icon">
                    <IconButton
                        onClick={handleCreateNew}
                        size="large"
                        className={buttonStyles.contentAddButton}
                    >
                        <MdAdd className="react-icon" />
                    </IconButton>
                </Tooltip>
                <div style={{ flexGrow: 1 }}></div>
                <SearchWithFilters
                    filter={false}
                    onChange={(evt) => debouncedSearch(evt.target.value)}
                />
            </div>
            <div style={{ height: 'calc(100vh - 295px)', minWidth: '500px' }}>
                <AutoSizer>
                    {({ width, height }) => (
                        <VirtualTable
                            initialRows={hotspots}
                            allRows={hotspots}
                            totalRowCount={pageInfo.TotalCount}
                            loadedRowsMap={loadedRowsMap}
                            loadMoreRows={loadMoreRows}
                            columnDefinitions={columns}
                            cellClassName={tableClasses.flexContainer}
                            className={tableClasses.table}
                            rowClassName={tableClasses.flexContainer}
                            headerHeight={headerHeight}
                            width={width}
                            height={height}
                            rowHeight={rowHeight}
                            onRowClick={handleRowClick}
                        />
                    )}
                </AutoSizer>
            </div>
        </div>
    );
}

export { HotspotTable };
