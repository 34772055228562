import React, { useEffect } from 'react';
import { Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Box, FormControl, TextField } from '@mui/material';

import { ActionsDropdown } from './components/ActionsDropdown';
import MediaField from './MediaField';
import MediaCompareField from './MediaCompareField';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import {
    fetchInspectionActionList,
    fetchInspectionReasonList,
    getActionListLoadingStatus,
    getReasonListLoadingStatus,
} from 'GeminiViewerComponent/_features/inspection/inspectionSlice';
import { getConfig } from 'GeminiViewerComponent/_features/config/configSlice';

const InspectionFieldData = ({
    data,
    fieldValue,
    setFieldValue,
    isSubmitAllow,
    validationError,
    handleClick,
    handleBlur,
}) => {
    let mediaData = { ...data };
    if (data?.image_attachments_allow === true) {
        mediaData.label = 'Take Photos';
        mediaData.required = false;
    }

    let referenceData = { ...data };
    if (data?.image_attachments_allow === true) {
        referenceData.comparison_field = data?.variable;
    }
    let variable = data?.variable;
    if (data?.duplicateCount) {
        let lastIndex = data?.variable.lastIndexOf(`-${data?.duplicateCount}`);
        variable = data?.variable.slice(0, lastIndex);
    }
    const commentValue = data?.duplicateCount
        ? fieldValue[variable]?.[data.duplicateCount]?.['comment']
        : fieldValue[variable]?.[0]?.['comment'] ||
          fieldValue[variable]?.['comment'];

    return (
        <Box
            component="fieldset"
            border="1px solid #cccccc"
            borderRadius="5px"
            padding="5px"
            width="100%"
            onClick={(e) => {
                if (!isSubmitAllow) {
                    e.stopPropagation();
                    handleClick(e, data);
                }
            }}
        >
            {data?.hide_label !== true && data?.label && (
                <Box
                    component="legend"
                    marginLeft="10px"
                    padding="3px"
                    fontSize="14px"
                    color="#6d7178"
                >
                    {data?.label}
                </Box>
            )}
            <Box
                width={data?.field_width ? data.field_width + '%' : '100%'}
                display="flex"
                flexWrap="wrap"
                gap="10px"
            >
                {data?.action_list_type && (
                    <ActionsDropdown
                        data={data}
                        isSubmitAllow={isSubmitAllow}
                        fieldValue={fieldValue}
                        setFieldValue={setFieldValue}
                        validationError={validationError}
                        inspectionActionId={data?.action_list_type}
                        handleBlur={handleBlur}
                    />
                )}
                {data?.comment === true && (
                    <FormControl
                        sx={{
                            width: { xs: '100%', sm: 'calc(20% - 10px)' },
                            minWidth: '200px',
                        }}
                    >
                        <Field name={fieldValue?.[data?.variable]?.comment}>
                            {() => (
                                <TextField
                                    disabled={!isSubmitAllow}
                                    multiline={true}
                                    margin="dense"
                                    label={'Comment'}
                                    onChange={(e) => {
                                        if (isSubmitAllow) {
                                            if (data?.duplicateCount) {
                                                setFieldValue(
                                                    `${variable}.${[
                                                        data?.duplicateCount,
                                                    ]}.comment`,
                                                    e.target.value
                                                );
                                            } else {
                                                setFieldValue(
                                                    `${variable}.${[
                                                        0,
                                                    ]}.comment`,
                                                    e.target.value
                                                );
                                            }
                                        }
                                    }}
                                    value={commentValue || ''}
                                    onBlur={() =>
                                        isSubmitAllow && handleBlur(fieldValue)
                                    }
                                    placeholder={'Comment'}
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                        </Field>
                    </FormControl>
                )}
                {data?.image_attachments_allow === true && (
                    <Box
                        sx={{
                            width: { xs: '100%', sm: 'calc(20% - 10px)' },
                            minWidth: '200px',
                        }}
                    >
                        <MediaField
                            isSubmitAllow={isSubmitAllow}
                            data={mediaData}
                            inspectionMedia={true}
                        />
                    </Box>
                )}
                {data?.media_reference === true &&
                    data?.references?.length > 0 && (
                        <Box
                            sx={{
                                width: { xs: '100%', sm: 'calc(20% - 10px)' },
                                minWidth: '200px',
                            }}
                        >
                            <MediaCompareField
                                data={referenceData}
                                isSubmitAllow={isSubmitAllow}
                                handleClick={handleClick}
                            />
                        </Box>
                    )}
            </Box>
        </Box>
    );
};

const InspectionField = ({
    data,
    fieldValue,
    setFieldValue,
    isSubmitAllow,
    validationError,
    inGroup = false,
    handleClick = () => {},
    handleBlur,
}) => {
    const dispatch = useDispatch();
    const config = useSelector(getConfig);
    const actionListLoadingStatus = useSelector(getActionListLoadingStatus);
    const reasonListLoadingStatus = useSelector(getReasonListLoadingStatus);

    useEffect(() => {
        const fetchInspectionActionData = async () => {
            if (
                config.modeType !== 'viewer' &&
                isSubmitAllow &&
                actionListLoadingStatus === LoadingStatus.Idle
            ) {
                await dispatch(fetchInspectionActionList());
            }
        };
        fetchInspectionActionData();
    }, [actionListLoadingStatus]);

    useEffect(() => {
        const fetchInspectionReasonData = async () => {
            if (
                config.modeType !== 'viewer' &&
                isSubmitAllow &&
                reasonListLoadingStatus === LoadingStatus.Idle
            ) {
                await dispatch(fetchInspectionReasonList());
            }
        };

        fetchInspectionReasonData();
    }, [reasonListLoadingStatus]);

    return inGroup ? (
        <Box
            width={data?.field_width ? data.field_width + '%' : '100%'}
            display="inline-flex"
            padding="13px 5px 5px"
        >
            <InspectionFieldData
                data={data}
                fieldValue={fieldValue}
                setFieldValue={setFieldValue}
                isSubmitAllow={isSubmitAllow}
                validationError={validationError}
                handleClick={handleClick}
                handleBlur={handleBlur}
            />
        </Box>
    ) : (
        <Box width="100%">
            <Box
                width={data?.field_width ? data.field_width + '%' : '100%'}
                display="inline-flex"
                padding="5px"
            >
                <InspectionFieldData
                    data={data}
                    fieldValue={fieldValue}
                    setFieldValue={setFieldValue}
                    isSubmitAllow={isSubmitAllow}
                    validationError={validationError}
                    handleClick={handleClick}
                    handleBlur={handleBlur}
                />
            </Box>
        </Box>
    );
};

export default InspectionField;
