import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    updateZoneInActiveAsset,
    addActiveZoneNavLink,
    deleteActiveZoneNavLink,
    updateActiveZoneNavLink,
    addZoneToActiveAsset,
    removeZoneFromActiveAsset,
} from 'GeminiViewerComponent/_features/asset/assetSlice';
import {
    resetTableZones,
    selectTableZoneById,
} from '_features/zones/zonesTableSlice';
import { zoneService, navLinkService } from '_features/_services';
import {
    LoadingStatus,
    SavingStatus,
} from 'GeminiViewerComponent/_helpers/AsyncStatus';
import {
    addNavLinkToUndoStack,
    setStackPointer,
    updateNavItemStack,
} from 'shared/loadZoneSlice';

const initZoneInfo = {
    display_name: '',
    description: '',
    tag: '',
    content_tag: '',
    pano_id: '',
    audiences: [],
    equirect_image: { name: '' },
    flat_image: { name: '' },
    equirect_image_preview: { name: '' },
    flat_image_preview: { name: '' },
    nav_links: [],
    visualEditZone: false,
    tag_ids: [],
};

const initialState = {
    editZone: initZoneInfo,
    loadingStatus: LoadingStatus.Idle,
    savingStatus: SavingStatus.Idle,
};

function addImageProps(zone) {
    if (zone.equirect_image_preview_url) {
        zone.zone_image_preview = {
            file: null,
            length: 0,
            name: zone.original_equirect_image_preview_name,
        };
    }
    if (zone.flat_image_preview_url) {
        zone.zone_image_preview = {
            file: null,
            length: 0,
            name: zone.original_flat_image_preview_name,
        };
    }
    if (zone.equirect_image_url) {
        zone.equirect_image = {
            file: null,
            length: 0,
            name: zone.original_equirect_image_name,
        };
    }
    if (zone.flat_image_url) {
        zone.flat_image = {
            file: null,
            length: 0,
            name: zone.original_flat_image_name,
        };
    }
}

export const loadEditZone = createAsyncThunk(
    'editZone/loadEditZone',
    async (zoneId, { getState }) => {
        let zone = await zoneService.getById(
            zoneId,
            getState().accounts.activeUser.s3_prefix
        );
        addImageProps(zone);
        return zone;
    }
);

export const editZone = createAsyncThunk(
    'editZone/editZone',
    async (props, { getState, dispatch }) => {
        let zone = await zoneService.update(
            { ...props, dispatch },
            getState().accounts.activeUser.s3_prefix
        );
        if (zone) {
            addImageProps(zone);
            await dispatch(updateZoneInActiveAsset(zone));
            await dispatch(resetTableZones());
        }
        return zone;
    }
);

export const pinZone = createAsyncThunk(
    'editZone/pinZone',
    async (zoneId, { getState, dispatch, rejectWithValue }) => {
        // Reverse current pinned state
        let zone = selectTableZoneById(getState(), zoneId);
        if (zone) {
            zone = { ...zone };
            zone.is_pinned = !zone.is_pinned;
            const params = { object_id: zoneId, is_pinned: zone.is_pinned };
            var response = await zoneService.zonePinning(
                params,
                getState().accounts.activeUser.s3_prefix
            );
            response.pinId = zoneId;
            response.isPinned = zone.is_pinned;
            await dispatch(
                updateZoneInActiveAsset({
                    zone_id: zoneId,
                    is_pinned: zone.is_pinned,
                })
            );
            await dispatch(resetTableZones());
            return response;
        } else {
            return rejectWithValue(null);
        }
    }
);

export const addNewZone = createAsyncThunk(
    'editZone/addNewZone',
    async (props, { getState, dispatch }) => {
        let zone = await zoneService.create(
            props,
            getState().accounts.activeUser.s3_prefix
        );
        if (zone) {
            addImageProps(zone);
            zone.items = [];
            zone.nav_links = [];
            await dispatch(addZoneToActiveAsset(zone));
            await dispatch(resetTableZones());
        }
        return zone;
    }
);

export const addMultipleNewZone = createAsyncThunk(
    'editZone/addMultipleNewZone',
    async (props, { getState, dispatch }) => {
        let zone = await zoneService.createMultiple(
            props,
            getState().accounts.activeUser.s3_prefix
        );
        return zone;
    }
);

export const deleteZone = createAsyncThunk(
    'editZone/deleteZone',
    async ({ zoneId, permanentDelete }, { dispatch }) => {
        let response = null;
        if (permanentDelete) {
            response = await zoneService.delete(zoneId + '?delete_files=true');
        } else {
            response = await zoneService.delete(zoneId);
        }
        response.id = zoneId;
        await dispatch(removeZoneFromActiveAsset({ zoneId: zoneId }));
        // Reload displayed zones
        await dispatch(resetTableZones());
        return response;
    }
);

export const addNewZoneNavLink = createAsyncThunk(
    'editZone/addNewZoneNavLink',
    async (newNavLink, { dispatch }) => {
        const response = await navLinkService.create(newNavLink);
        if (response) {
            dispatch(addActiveZoneNavLink(response));
            dispatch(addNavLinkToUndoStack(response));
        }
        return response;
    }
);

export const editZoneNavLink = createAsyncThunk(
    'editZone/editZoneNavLink',
    async (navLink, { dispatch }) => {
        const response = await navLinkService.update(
            navLink.nav_link_id,
            navLink
        );
        if (response) {
            if (navLink.action === 'undo' || navLink.action === 'redo') {
                dispatch(setStackPointer({ ...navLink, type: 'nav_links' }));
            } else {
                dispatch(updateNavItemStack({ ...navLink, type: 'nav_links' }));
            }
            dispatch(updateActiveZoneNavLink(response));
        }
        return response;
    }
);

export const deleteZoneNavLink = createAsyncThunk(
    'editZone/deleteZoneNavLink',
    async (navLink, { dispatch }) => {
        const response = await navLinkService.delete(navLink.nav_link_id);
        if (response) {
            dispatch(
                deleteActiveZoneNavLink({
                    zone_id: navLink.zone_id,
                    nav_link_id: navLink.nav_link_id,
                })
            );
        }
        return response;
    }
);
const editZoneSlice = createSlice({
    name: 'editZone',
    initialState,
    reducers: {
        clearEditZone: (state) => {
            state.editZone = initZoneInfo;
        },
        setVisualEditZone: (state, action) => {
            state.visualEditZone = action.payload;
        },
        setZone: (state, action) => {
            state.editZone = action.payload.activeZone;
        },
    },
    extraReducers: {
        [loadEditZone.pending]: (state) => {
            state.loadingStatus = LoadingStatus.Loading;
        },
        [loadEditZone.fulfilled]: (state, action) => {
            state.editZone = action.payload;
            state.loadingStatus = LoadingStatus.Loaded;
        },
        [loadEditZone.rejected]: (state) => {
            state.loadingStatus = LoadingStatus.Failed;
        },
        [editZone.pending]: (state) => {
            state.savingStatus = SavingStatus.Saving;
        },
        [editZone.fulfilled]: (state, action) => {
            state.editZone = action.payload;
            state.savingStatus = SavingStatus.Saved;
        },
        [editZone.rejected]: (state) => {
            state.savingStatus = SavingStatus.Failed;
        },
        [pinZone.pending]: (state) => {
            state.savingStatus = SavingStatus.Saving;
        },
        [pinZone.fulfilled]: (state) => {
            state.savingStatus = SavingStatus.Saved;
        },
        [pinZone.rejected]: (state) => {
            state.savingStatus = SavingStatus.Failed;
        },
        [addNewZone.pending]: (state) => {
            state.savingStatus = SavingStatus.Saving;
        },
        [addNewZone.fulfilled]: (state) => {
            state.savingStatus = SavingStatus.Saved;
        },
        [addNewZone.rejected]: (state) => {
            state.savingStatus = SavingStatus.Failed;
        },
        [addMultipleNewZone.pending]: (state) => {
            state.savingStatus = SavingStatus.Saving;
        },
        [addMultipleNewZone.fulfilled]: (state) => {
            state.savingStatus = SavingStatus.Saved;
        },
        [addMultipleNewZone.rejected]: (state) => {
            state.savingStatus = SavingStatus.Failed;
        },
        [deleteZone.pending]: (state) => {
            state.savingStatus = SavingStatus.Saving;
        },
        [deleteZone.fulfilled]: (state) => {
            state.savingStatus = SavingStatus.Saved;
        },
        [deleteZone.rejected]: (state) => {
            state.savingStatus = SavingStatus.Failed;
        },
        [addNewZoneNavLink.pending]: (state, action) => {},
        [addNewZoneNavLink.fulfilled]: (state, action) => {},
        [addNewZoneNavLink.rejected]: (state, action) => {},
        [deleteZoneNavLink.fulfilled]: (state, action) => {},
        [deleteZoneNavLink.rejected]: (state, action) => {},
        [editZoneNavLink.fulfilled]: (state, action) => {},
        [editZoneNavLink.rejected]: (state, action) => {
            state.status = SavingStatus.Failed;
            state.error = action.error.message;
        },
    },
});

export const selectEditZone = (state) => state.editZone.editZone;

export const selectEditZoneLoadingStatus = (state) =>
    state.editZone.loadingStatus;
export const selectEditZoneSavingStatus = (state) =>
    state.editZone.savingStatus;

export const selectVisualEditZone = (state) => state.editZone.visualEditZone;

export const { clearEditZone, setVisualEditZone, setZone } =
    editZoneSlice.actions;

export default editZoneSlice.reducer;
