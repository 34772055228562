import React from 'react';
import { config } from 'gemini-config';
import { Divider, List } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { Role } from '_helpers';
import { useStyles } from './styles';
import NavItem from './components/NavItem';
import { accountsSlice, accountsThunk } from 'app/store';
import { resetGroupState } from '_features/groups/groupsSlice';
import { resetAssetsTableState } from '_features/assets/assetsTableSlice';
import { resetLoadingState } from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';

// ICONS
import {
    FiGrid,
    FiLayers,
    FiBox,
    FiLifeBuoy,
    FiCheckCircle,
    FiInfo,
} from 'react-icons/fi';
import {
    MdHomeRepairService,
    MdPrecisionManufacturing,
    MdSpeakerNotes,
} from 'react-icons/md';

const { logoutUser } = accountsThunk;
import ProfileItem from './components/ProfileItem';
import NavItemModal from './components/NavItemModal';
const { resetAccountState, selectActiveUser } = accountsSlice;
import { ReactComponent as ProcessIcon } from 'assets/process.svg';
import { showAboutModal } from 'GeminiViewerComponent/_features/common/actionModalSlice';

export default function NavList({ drawerOpen }) {
    const classes = useStyles();

    const dispatch = useDispatch();
    const activeUser = useSelector(selectActiveUser);

    const submitLogout = (event) => {
        event.preventDefault();
        dispatch(logoutUser());
        dispatch(resetAssetsTableState());
        dispatch(resetGroupState());
        dispatch(resetAccountState());
        dispatch(resetLoadingState());
    };

    return (
        <List className={classes.navContainer}>
            <List>
                {/* {activeUser?.role == 'IPS Administrator' && (
                    <NavItem to="/" icon={<FiHome />} text="Home" />
                )} */}
                <NavItem to="/assets" icon={<FiGrid />} text="Assets" />
                <NavItem
                    to="/procedures"
                    icon={<ProcessIcon className={classes.procedureIcon} />}
                    text="Procedures"
                />
                <NavItem
                    to="/content"
                    icon={<FiLayers />}
                    text="Content Manager"
                />

                {!Role?.DisableAdminRoles?.includes(activeUser?.role) && (
                    <NavItem
                        to="/jobs"
                        icon={<FiCheckCircle />}
                        text="Job Status"
                    />
                )}
                {!Role?.DisableAdminRoles?.includes(activeUser?.role) && (
                    <NavItem
                        to="/tools"
                        icon={<MdHomeRepairService className="react-icon" />}
                        text="Tools Manager"
                    />
                )}
                {!Role?.DisableAdminRoles?.includes(activeUser?.role) && (
                    <NavItem
                        to="/parts"
                        icon={
                            <MdPrecisionManufacturing className="react-icon" />
                        }
                        text="Parts Manager"
                    />
                )}
                <NavItem
                    to="/newsflash"
                    icon={<MdSpeakerNotes className="react-icon" />}
                    text="News Flash Manager"
                />
            </List>
            <List>
                {!Role?.DisableAdminRoles?.includes(activeUser?.role) && (
                    <NavItem
                        to="/admin"
                        icon={<FiBox />}
                        text="Admin Manager"
                    />
                )}
                <NavItem to="/help" icon={<FiLifeBuoy />} text="Help" />
                <NavItemModal
                    icon={<FiInfo />}
                    text="INFO"
                    onClick={() => {
                        dispatch(showAboutModal(true));
                    }}
                />
                <Divider style={{ marginTop: 10 }} />
                <ProfileItem drawerOpen={drawerOpen}></ProfileItem>
                <div className={classes.logoutContainer}>
                    <a
                        href="/#"
                        onClick={(e) => submitLogout(e)}
                        className={classes.accountLogout}
                    >
                        Logout
                    </a>
                </div>
                <div className={classes.version}>v{config.appVersion}</div>
            </List>
        </List>
    );
}
