import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

// REDUX
import { currentFormInfo, selectFormInfo } from '_features/common/formSlice';

// PANELS
import { SidePanelHeader } from 'components/_Layout/SidePanel/SidePanelHeader';

// FORMS
import { ItemForm } from 'forms/ItemForm';
import { LinkForm } from 'forms/LinkForm';
import { ToolForm } from 'forms/ToolForm';
import { PartForm } from 'forms/PartForm';
import { ZoneForm } from 'forms/ZoneForm';
import { AssetForm } from 'forms/AssetForm';
import { GroupForm } from 'forms/GroupForm';
import { AccountForm } from 'forms/AccountForm';
import { ContentForm } from 'forms/ContentForm';
import { HotspotForm } from 'forms/HotspotForm';
import { ItemTagForm } from 'forms/ItemTagForm';
import { AudienceForm } from 'forms/AudienceForm';
import { CategoryForm } from 'forms/CategoryForm';
import { LocationForm } from 'forms/LocationForm';
import { ProcedureForm } from 'forms/ProcedureForm';
import { CompanionForm } from 'forms/CompanionForm';
import { NewsFlashForm } from 'forms/NewsFlashForm';
import { PartGroupForm } from 'forms/PartGroupForm';
import { AssetExportForm } from 'forms/AssetExportForm';
import { ToolCategoryForm } from 'forms/ToolCategoryForm';
import { PartCategoryForm } from 'forms/PartCategoryForm';
import { ContentVersionForm } from 'forms/ContentVersionForm';
import { selectEditItem } from '_features/common/editItemSlice';
import { selectEditZone } from '_features/common/editZoneSlice';

// ICONS
import { FiServer } from 'react-icons/fi';
import { DeploymentPackageForm } from 'forms/jobs/Management/DepolymentPackage';

const FormDisplayer = () => {
    const formInfo = useSelector(selectFormInfo);
    const currentForm = useSelector(currentFormInfo);
    // const asset = useSelector((state) => getDisplayAsset(state));
    const editZone = useSelector(selectEditZone);
    const editItem = useSelector(selectEditItem);

    // Need latest value of formInfo when scene changes
    const formInfoRef = useRef();
    formInfoRef.current = formInfo;

    // const activeZone = useSelector(state => getActiveZone(state)); // Active scene in marzipano
    // const viewerLoaded = useSelector(state => getViewerLoaded(state)); // Active scene in marzipano

    return (
        <>
            {currentForm === 'accountForm' && formInfo.accountForm.isOpen && (
                <>
                    <SidePanelHeader
                        icon={<FiServer />}
                        header={`Account Panel`}
                        formKey={formInfo.accountForm.formKey}
                    />
                    <AccountForm
                        formAction={formInfo.accountForm.formAction}
                        accountId={formInfo.accountForm.editObjectId}
                    />
                </>
            )}

            {currentForm === 'contentForm' && formInfo.contentForm.isOpen && (
                <>
                    <SidePanelHeader
                        icon={<FiServer />}
                        header={'Content Panel'}
                        formKey={formInfo.contentForm.formKey}
                    />
                    <ContentForm
                        formAction={formInfo.contentForm.formAction}
                        contentId={formInfo.contentForm.editObjectId}
                    />
                </>
            )}

            {currentForm === 'contentVersionForm' &&
                formInfo.contentVersionForm.isOpen && (
                    <>
                        <SidePanelHeader
                            icon={<FiServer />}
                            header={'Content Version Panel'}
                            formKey={formInfo.contentVersionForm.formKey}
                        />
                        <ContentVersionForm
                            contentId={formInfo.contentVersionForm.editObjectId}
                        />
                    </>
                )}

            {currentForm === 'zoneForm' && formInfo.zoneForm.isOpen && (
                <>
                    <SidePanelHeader
                        icon={<FiServer />}
                        header={`Zone Panel`}
                        formKey={formInfo.zoneForm.formKey}
                        data={editZone}
                    />
                    <ZoneForm
                        formAction={formInfo.zoneForm.formAction}
                        zone={editZone}
                    />
                </>
            )}

            {currentForm === 'itemForm' && formInfo.itemForm.isOpen && (
                <>
                    <SidePanelHeader
                        icon={<FiServer />}
                        header={`Item Panel`}
                        formKey={formInfo.itemForm.formKey}
                    />
                    <ItemForm
                        formAction={formInfo.itemForm.formAction}
                        item={editItem}
                    />
                </>
            )}

            {currentForm === 'linkForm' && formInfo.linkForm.isOpen && (
                <>
                    <SidePanelHeader
                        icon={<FiServer />}
                        header={`Link Panel`}
                        formKey={formInfo.linkForm.formKey}
                    />
                    <LinkForm
                        formAction={formInfo.linkForm.formAction}
                        linkId={formInfo.linkForm.editObjectId}
                    />
                </>
            )}

            {currentForm === 'groupForm' && formInfo.groupForm.isOpen && (
                <>
                    <SidePanelHeader
                        icon={<FiServer />}
                        header={`Group Panel`}
                        formKey={formInfo.groupForm.formKey}
                    />
                    <GroupForm
                        formAction={formInfo.groupForm.formAction}
                        groupId={formInfo.groupForm.editObjectId}
                    />
                </>
            )}

            {currentForm === 'categoryForm' && formInfo.categoryForm.isOpen && (
                <>
                    <SidePanelHeader
                        icon={<FiServer />}
                        header={`Category Panel`}
                        formKey={formInfo.categoryForm.formKey}
                    />
                    <CategoryForm
                        formAction={formInfo.categoryForm.formAction}
                        categoryId={formInfo.categoryForm.editObjectId}
                    />
                </>
            )}

            {currentForm === 'managementDeployForm' &&
                formInfo.managementDeployForm.isOpen && (
                    <>
                        <SidePanelHeader
                            icon={<FiServer />}
                            header={`Deployment Package Panel`}
                            formKey={formInfo.managementDeployForm.formKey}
                        />
                        <DeploymentPackageForm
                            formAction={
                                formInfo.managementDeployForm.formAction
                            }
                            deploymentId={
                                formInfo.managementDeployForm.editObjectId
                            }
                        />
                    </>
                )}
            {currentForm === 'hotspotForm' && formInfo.hotspotForm.isOpen && (
                <>
                    <SidePanelHeader
                        icon={<FiServer />}
                        header={`Hotspot Panel`}
                        formKey={formInfo.hotspotForm.formKey}
                    />
                    <HotspotForm
                        formAction={formInfo.hotspotForm.formAction}
                        hotspotId={formInfo.hotspotForm.editObjectId}
                    />
                </>
            )}

            {currentForm === 'assetForm' && formInfo.assetForm.isOpen && (
                <>
                    <SidePanelHeader
                        icon={<FiServer />}
                        header={`Asset Panel`}
                        formKey={formInfo.assetForm.formKey}
                    />
                    <AssetForm
                        formAction={formInfo.assetForm.formAction}
                        assetId={formInfo.assetForm.editObjectId}
                    />
                </>
            )}

            {currentForm === 'procedureForm' &&
                formInfo.procedureForm.isOpen && (
                    <>
                        <SidePanelHeader
                            icon={<FiServer />}
                            header={`Procedure Panel`}
                            formKey={formInfo.procedureForm.formKey}
                        />
                        <ProcedureForm
                            formAction={formInfo.procedureForm.formAction}
                            assetId={formInfo.procedureForm.editObjectId}
                        />
                    </>
                )}

            {currentForm === 'itemTagForm' && formInfo.itemTagForm.isOpen && (
                <>
                    <SidePanelHeader
                        icon={<FiServer />}
                        header={`Item Tag Panel`}
                        formKey={formInfo.itemTagForm.formKey}
                    />
                    <ItemTagForm
                        formAction={formInfo.itemTagForm.formAction}
                        itemTagId={formInfo.itemTagForm.editObjectId}
                    />
                </>
            )}

            {currentForm === 'audienceForm' && formInfo.audienceForm.isOpen && (
                <>
                    <SidePanelHeader
                        icon={<FiServer />}
                        header={`Audience Panel`}
                        formKey={formInfo.audienceForm.formKey}
                    />
                    <AudienceForm
                        formAction={formInfo.audienceForm.formAction}
                        audienceId={formInfo.audienceForm.editObjectId}
                    />
                </>
            )}

            {currentForm === 'assetExportForm' &&
                formInfo.assetExportForm.isOpen && (
                    <>
                        <SidePanelHeader
                            icon={<FiServer />}
                            header={'Export Asset'}
                            formKey={formInfo.assetExportForm.formKey}
                        />
                        <AssetExportForm
                            formAction={formInfo.assetExportForm.formAction}
                            assetId={formInfo.assetExportForm.editObjectId}
                        />
                    </>
                )}

            {currentForm === 'toolCategoryForm' &&
                formInfo.toolCategoryForm.isOpen && (
                    <>
                        <SidePanelHeader
                            icon={<FiServer />}
                            header={`Tool Category Panel`}
                            formKey={formInfo.toolCategoryForm.formKey}
                        />
                        <ToolCategoryForm
                            formAction={formInfo.toolCategoryForm.formAction}
                            categoryId={formInfo.toolCategoryForm.editObjectId}
                        />
                    </>
                )}
            {currentForm === 'locationForm' && formInfo.locationForm.isOpen && (
                <>
                    <SidePanelHeader
                        icon={<FiServer />}
                        header={`Tool Location Panel`}
                        formKey={formInfo.locationForm.formKey}
                    />
                    <LocationForm
                        formAction={formInfo.locationForm.formAction}
                        locationId={formInfo.locationForm.editObjectId}
                    />
                </>
            )}
            {currentForm === 'toolForm' && formInfo.toolForm.isOpen && (
                <>
                    <SidePanelHeader
                        icon={<FiServer />}
                        header={`Tool Panel`}
                        formKey={formInfo.toolForm.formKey}
                    />
                    <ToolForm
                        formAction={formInfo.toolForm.formAction}
                        toolId={formInfo.toolForm.editObjectId}
                    />
                </>
            )}
            {currentForm === 'partCategoryForm' &&
                formInfo.partCategoryForm.isOpen && (
                    <>
                        <SidePanelHeader
                            icon={<FiServer />}
                            header={`Part Category Panel`}
                            formKey={formInfo.partCategoryForm.formKey}
                        />
                        <PartCategoryForm
                            formAction={formInfo.partCategoryForm.formAction}
                            partCategoryId={
                                formInfo.partCategoryForm.editObjectId
                            }
                        />
                    </>
                )}
            {currentForm === 'partGroupForm' &&
                formInfo.partGroupForm.isOpen && (
                    <>
                        <SidePanelHeader
                            icon={<FiServer />}
                            header={`Part Group Panel`}
                            formKey={formInfo.partGroupForm.formKey}
                        />
                        <PartGroupForm
                            formAction={formInfo.partGroupForm.formAction}
                            partGroupId={formInfo.partGroupForm.editObjectId}
                        />
                    </>
                )}
            {currentForm === 'partForm' && formInfo.partForm.isOpen && (
                <>
                    <SidePanelHeader
                        icon={<FiServer />}
                        header={`Part Panel`}
                        formKey={formInfo.partForm.formKey}
                    />
                    <PartForm
                        formAction={formInfo.partForm.formAction}
                        partId={formInfo.partForm.editObjectId}
                    />
                </>
            )}
            {currentForm === 'companionForm' &&
                formInfo.companionForm.isOpen && (
                    <>
                        <SidePanelHeader
                            icon={<FiServer />}
                            header={`Companion App Panel`}
                            formKey={formInfo.companionForm.formKey}
                        />
                        <CompanionForm
                            formAction={formInfo.companionForm.formAction}
                            companionAppId={formInfo.companionForm.editObjectId}
                        />
                    </>
                )}
            {currentForm === 'newsFlashForm' &&
                formInfo.newsFlashForm.isOpen && (
                    <>
                        <SidePanelHeader
                            icon={<FiServer />}
                            header="News Flash Panel"
                            formKey={formInfo.newsFlashForm.formKey}
                        />
                        <NewsFlashForm
                            formAction={formInfo.newsFlashForm.formAction}
                            newsFlashId={formInfo.newsFlashForm.editObjectId}
                        />
                    </>
                )}
        </>
    );
};

export { FormDisplayer };
