import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchProcedure,
    getLoadingStatus,
} from 'GeminiViewerComponent/components/Procedure/_features/procedureSlice';
import {
    assetService,
    contentService,
    procedureService,
} from '_features/_services';
import { Procedure } from 'GeminiViewerComponent/components/Procedure';
import { useZoneNav } from 'shared/hooks/useZoneNav';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { LoadingLogo } from 'GeminiViewerComponent/components/LoadingLogo/LoadingLogo';

export const ProcedureContentLoader = ({
    contentVersion,
    setProcedureTitle,
}) => {
    //#region Constants
    //#endregion Constants

    //#region Hooks

    const dispatch = useDispatch();

    //#endregion Hooks

    //#region State
    //#endregion State

    //#region Selectors

    const loadingStatus = useSelector(getLoadingStatus);

    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Render time calcs
    //#endregion Render time calcs

    //#region Effects

    useEffect(() => {
        const loadProcedure = async (contentVersion) => {
            let response = null;
            if (contentVersion?.procedure_start_node) {
                response = await dispatch(
                    fetchProcedure({
                        service: procedureService,
                        procedureId: contentVersion.procedure_id,
                        start_node_id: contentVersion?.procedure_start_node,
                    })
                );
            } else {
                response = await dispatch(
                    fetchProcedure({
                        service: procedureService,
                        procedureId: contentVersion.procedure_id,
                    })
                );
            }
            const procedure = JSON.parse(response.payload.procedure_json);
            assetService.updateProcedureNodes(procedure);
            setProcedureTitle(procedure.title ?? '');
        };
        loadProcedure(contentVersion);
    }, [contentVersion, setProcedureTitle, dispatch]);

    //#endregion Effects

    //#region Methods
    //#endregion Methods

    //#region Render

    return (
        <>
            {loadingStatus === LoadingStatus.Loading && <LoadingLogo />}
            {loadingStatus === LoadingStatus.Loaded && (
                <Procedure
                    procedureService={procedureService}
                    contentService={contentService}
                    useZoneNav={useZoneNav}
                />
            )}
        </>
    );

    //#endregion Render
};
