import { alpha, lighten } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const makeButtonStyles = makeStyles((theme) => ({
    contentAddButton: {
        padding: '10px !important',
        borderRadius: '25px !important',
        background: (props) =>
            `${props.colors.button.primaryBackground} !important`,
        color: (props) => `${props.colors.button.primaryForeground} !important`,
    },
}));

const accordionStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: '8px',
        marginBottom: '4px',
    },
    heading: {
        fontSize: '16px',
        fontWeight: theme.typography.fontWeightRegular,
        color: 'grey',
    },
    accordion: {
        border: '1px solid #c7c7c7',
        boxShadow: 'none',
        '&:hover': {
            border: '1px solid black',
        },
    },
    customAccordionSummary: {
        height: '40px',
        minHeight: '0px',
        '& div': {
            margin: '0 -12px 0 0',
        },
    },
    accordionSummary: {
        minHeight: '40px',
        height: '40px',
        '&.Mui-expanded': {
            minHeight: '40px',
            height: '40px',
        },
    },
    inputRoot: {
        color: 'inherit',
        border: '1px solid #BDC3C7',
        borderRadius: '5px',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

const autoCompleteStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: '8px',
        marginBottom: '4px',
        '& .MuiAutocomplete-root': {
            position: 'relative',
        },
    },
    clearIndicator: {
        '& .MuiInputBase-root': {
            '& .MuiAutocomplete-endAdornment': {
                '& .MuiAutocomplete-clearIndicator': {
                    right: '20px',
                },
            },
        },
    },
    heading: {
        fontSize: '16px',
        fontWeight: theme.typography.fontWeightRegular,
        color: 'grey',
    },
    accordion: {
        border: '1px solid #c7c7c7',
        boxShadow: 'none',
        '&:hover': {
            border: '1px solid black',
        },
    },
    customAccordionSummary: {
        height: '40px',
        minHeight: '0px',
        '& div': {
            margin: '0 -12px 0 0',
        },
    },
    accordionSummary: {
        minHeight: '40px',
        height: '40px',
        '&.Mui-expanded': {
            minHeight: '40px',
            height: '40px',
        },
    },
    inputRoot: {
        color: 'inherit',
        border: '1px solid #BDC3C7',
        borderRadius: '5px',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    errorField: {
        '& .MuiAutocomplete-inputRoot .MuiOutlinedInput-notchedOutline': {
            borderColor: '#f44336',
        },
        '& .MuiAutocomplete-inputRoot:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#f44336',
        },
        '& .MuiAutocomplete-inputRoot.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
                borderColor: '#f44336',
            },
        '& .MuiInputLabel-root, & .MuiInputLabel-root.Mui-focused ': {
            color: '#f44336',
        },
    },
}));

const panelStyles = makeStyles(() => ({
    popupTitle: {
        display: 'flex',
        alignItems: 'center',
        color: '#7E8C8D',
        padding: '10px',
        fontSize: '16px',
        fontWeight: 'bold',
    },
    popupContainer: {
        display: 'flex',
        alignItems: 'center',
        color: '#7E8C8D',
        padding: '10px',
        fontSize: '14px',
    },
    drawerPaper: {
        '& .MuiDrawer-paper': {
            overflowY: 'auto !important',
        },
    },
    paper: {
        background: 'transparent',
    },
    paperOverflowVisible: {
        overflowY: 'visible',
        backgroundColor: (props) => props.colors?.body,
    },
    paperOverflowHidden: {
        overflowY: 'hidden',
    },
    list: {
        width: 576,
    },
    virtualDragger: {
        width: '20px',
        cursor: 'ew-resize',
        padding: '4px 0 0',
        position: 'absolute',
        top: 0,
        left: '60vw',
        bottom: 0,
        pointerEvents: 'all',
    },
    dragger: {
        width: '5px',
        padding: '4px 0 0',
        borderTop: '1px solid #ddd',
        position: 'absolute',
        top: 0,
        left: '60vw',
        bottom: 0,
        backgroundColor: '#f2f2f2',
        '& button': {
            position: 'absolute',
            top: '50%',
            transform: 'translate(-52%,-50%)',
            cursor: 'col-resize',
            background: '#ffffff',
            border: '1px solid #c1c1c1',
            fontSize: '18px',
            padding: '6px',
        },
    },
    draggerParent: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: '1299',
        pointerEvents: 'none',
    },
    contentPanelList: {
        width: '40vw',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        minWidth: '300px',
        overflow: 'auto',
    },
    itemPreviewPanel: {
        width: '50vw',
        height: '100%',
    },
    itemFullPanel: {
        width: '100vw',
        height: '100%',
    },
    itemPreviewFrame: {
        height: '100%',
        overflow: 'hidden',
        paddingTop: '70%',
        background: 'white',
        position: 'relative',
    },
    itemPreviewFrameInside: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
    },
    procedureContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '5px',
        backgroundColor: '#ECF0F1',
        color: '#2D3D50',
    },
    procedureHeader: {
        display: 'flex',
        alignItems: 'center',
        textTransform: 'uppercase',
        flexDirection: 'column',
        fontSize: '18px',
    },
    createContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px',
        backgroundColor: '#ECF0F1',
        color: '#2D3D50',
    },
    createHeader: {
        display: 'flex',
        textTransform: 'uppercase',
        flexDirection: 'column',
        fontSize: '24px',
        gap: '5px',
        width: '95%',
    },
    createHeaderTopRow: {
        display: 'flex',
        alignSelf: 'start',
    },
    createHeaderBottomRow: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    createHeaderText: {
        fontWeight: 800,
        paddingLeft: '10px',
        marginBottom: '5px',
    },
    createHeaderLink: {
        cursor: 'pointer',
        marginLeft: '10px',
    },
    createHeaderButton: {
        marginLeft: '10px',
        marginBottom: '5px',
        cursor: 'pointer',
        color: (props) => props.colors.button.primaryBackground,
    },
    createHeaderCloseButton: {
        alignSelf: 'start',
    },
    badgeMargin: {
        marginRight: '10px',
    },
    panel: {
        padding: '25px',
    },
    contentPanel: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        padding: '25px',
        height: '100%',
    },
    panelHeader: {
        paddingBottom: '15px',
        fontWeight: 800,
        color: '#2D3D50',
    },
    panelInputBottom: {
        marginTop: '15px',
        width: '100%',
    },
    stackInputs: {
        display: 'flex',
        flexDirection: 'column',
    },
    stackInput: {
        marginTop: '15px',
    },
    selectBox: {
        marginTop: '10px',
        border: '1px solid #c1c1c1',
        borderRadius: '5px',
        padding: '2px 10px',
        color: '#838383',
        '&:before': {
            borderBottom: 'unset !important',
        },
        '&:after': {
            borderBottom: 'unset !important',
        },
        '& .MuiNativeSelect-select:focus': {
            backgroundColor: 'inherit',
        },
    },
    dropzoneContainer: {
        marginTop: '15px',
        flexGrow: 2,
    },
    dropzoneArea: {
        minHeight: '160px !important',
        '& svg': {
            width: '1em',
            height: '1em',
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid',
        borderRadius: '5px',
    },
    dropZoneMinHeight50: {
        minHeight: '50px !important',
    },
    dropzoneAreaCompressed: {
        minHeight: '80px !important',
        '& svg': {
            width: '1em',
            height: '1em',
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    dropzoneText: {
        fontSize: '1em !important',
        margin: '10px !important',
        backgroundColor: '#0009',
        color: 'white',
        cursor: 'default',
        borderRadius: '5px',
        padding: '5px',
        width: 'max-content',
    },
    previewUploadFileName: {
        backgroundColor: '#18BC9B',
        color: 'white',
        width: '100%',
    },
    dropzonePreviewContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    },
    originalAsset: {
        display: 'flex',
        height: '150px',
        marginTop: '15px',
        '& img': {
            objectFit: 'cover',
            width: '100px',
        },
    },
    hidden: {
        display: 'none',
    },
    unsaveData: {
        backgroundColor: 'white',
        zIndex: 2,
        pointerEvents: 'auto',
        position: 'relative',
    },
}));

const tabStyles = makeStyles(() => ({
    tabPanel: {
        flexGrow: 1,
    },
    tabPanelBox: {
        height: '100%',
        paddingTop: '10px',
    },
    tabContent: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    tabButtons: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    tabBottom: {
        flexGrow: 1,
    },
    tabButton: {
        marginRight: '5px',
        marginBottom: '5px',
    },
    tabRoot: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        height: '100%',
        backgroundColor: 'inherit',
        '& header': {
            backgroundColor: 'inherit',
            boxShadow: 'unset',
            borderBottom: '1px solid #BDC3C7',
        },
    },
    linksHeader: {
        fontWeight: 'bold',
        marginTop: '10px',
        marginBottom: '-10px',
        paddingTop: '3px',
        paddingBottom: '3px',
        paddingLeft: '5px',
        paddingRight: '5px',
        backgroundColor: '#ECF0F1',
    },
    tabs: {
        '& button': {
            fontSize: '16px',
            color: '#BDC3C7',
        },
        '& .Mui-selected': {
            color: '#34485E',
            fontWeight: '900',
        },
        '& .MuiTabs-indicator': {
            height: '5px',
            backgroundColor: '#18BC9B',
        },
    },
}));

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.mode === 'light'
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    title: {
        flex: '1 1 100%',
    },
}));

const modalStyles = makeStyles(() => ({
    modalContainer: {
        position: 'absolute',
        backgroundColor: 'white',
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        '&:focus': {
            outline: 'none',
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        borderRadius: '5px',
        boxShadow: '0 0 5px #444444',
        padding: '20px',
        '& .react-transform-wrapper': {
            width: '100%',
            height: '100%',
            maxHeight: '60vh',
        },
        '& .react-transform-component': {
            width: '100%',
            height: '60vh',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    dargModalContainer: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    dragModalWrapper: {
        backgroundColor: 'white',
        borderRadius: '5px',
        boxShadow: '0 0 5px #444444',
        padding: '20px',
        '&:focus': {
            outline: 'none',
        },
    },
    modalContainerLarge: {
        width: '75%',
        height: '75%',
    },
    actionMessage: {
        marginBottom: '25px',
        color: '#2D3D50',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    buttonSpacing: {
        margin: '0px 10px',
    },
    actionButton: {
        backgroundColor: '#E84C3C',
        color: 'white',
        '&:hover': {
            backgroundColor: '#E84C3C',
            filter: 'brightness(75%)',
        },
    },
    actionIcon: {
        '& svg': {
            color: '#E84C3C',
            marginBottom: '10px',
            height: '35px',
            width: '35px',
        },
    },
    embedContainer: {
        position: 'relative',
        '& iframe': {
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
        },
    },
    videoContainer: {
        width: '100%',
        height: 'inherit',
    },
    close: {
        marginTop: 'auto',
        width: '100%',
        background: (props) => props.colors.button.primaryBackground,
        color: (props) => props.colors.button.primaryForeground,
        '&:hover': {
            background: (props) => props.colors.button.primaryBackground,
            color: (props) => props.colors.button.primaryForeground,
            filter: 'brightness(90%)',
        },
    },
    roundedCornerContainer: {
        borderRadius: '20px',
        width: '50%',
        '& div': {
            padding: '50px',
            width: '100%',
            lineBreak: 'anywhere',
        },
        '& strong': {
            fontWeight: 'bold',
        },
        lineHeight: '1.5em',
    },
    overlayText: {
        zIndex: 2,
        top: '0',
        left: '70px',
        position: 'absolute',
        width: '100%',
        height: 'auto',
        userSelect: 'none',
    },
    imageWrapper: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        position: 'relative',
        maxHeight: 'calc(100% - 84px)',
        marginBottom: '10px',
    },
    imageWrapperBlock: {
        position: 'relative',
        width: 'auto',
        display: 'inline-block',
        overflow: 'auto',
    },
    imageZoomButtons: {
        position: 'absolute',
        cursor: 'pointer',
        top: '-1px',
        left: '0',
        background: '#ffffff',
        padding: '0.5rem',
        boxShadow: 'inherit',
        zIndex: '3',
        '& div': {
            position: 'relative',
            padding: '5px',
            '& svg': {
                color: 'white',
                width: '25px',
                height: '25px',
            },
        },
    },
    zoomButton: {
        borderRadius: '6px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: (props) => props.colors.button.primaryBackground,
    },
    zoomWrapperMain: {
        width: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    chaptersWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '190px',
        overflow: 'auto',
        height: 'calc(100vh - 180px)',
    },
    chaptersLabel: {
        width: '150px',
        backgroundColor: '#000000',
        borderRadius: '4px',
        padding: '5px',
        color: '#FFFFFF',
        fontWeight: '600',
        textAlign: 'center',
        marginBottom: '5px',
    },
    chaptersCollapseButton: {
        zIndex: 9,
        position: 'absolute !important',
        color: '#fff !important',
        borderRadius: '4px !important',
    },
    chaptersButton: {
        backgroundColor: (props) =>
            `${props.colors.chapter_button.background_color} !important`,
        color: (props) =>
            `${props.colors.chapter_button.text_color} !important`,
        '&:hover': {
            filter: 'brightness(90%)',
        },
        width: '150px',
        maxWidth: '150px',
        border: '1px solid #ffffff',
    },
    activeChapterButton: {
        backgroundColor: (props) =>
            `${props.colors.chapter_button.active_color} !important`,
        color: (props) =>
            `${props.colors.chapter_button.text_color} !important`,
        '&:hover': {
            filter: 'brightness(90%)',
        },
    },
}));

const procedureSessionStyles = makeStyles((theme) => ({
    paper: {
        boxShadow: 'unset',
    },
    content: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh)',
        padding: '25px 50px',
        overflow: 'auto',
    },
    assetHeader: {
        display: 'flex',
        alignItems: 'center',
        color: '#297FB9',
        fontSize: '22px',
        fontWeight: 'bold',
    },
    assetS3: {
        display: 'flex',
        color: '#7E8C8D',
    },
    backArrow: {
        display: 'flex',
        marginRight: '10px',
        color: '#297FB9',
    },
    '@keyframes rotate': {
        from: { transform: 'rotate(0deg)' },
        to: { transform: 'rotate(360deg)' },
    },
    loadingSvg: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        marginLeft: '-50px',
        marginTop: '-50px',
        height: '100px',
        width: '100px',
    },
    rotateSvg: {
        animationName: '$rotate',
        animationDuration: '1.5s',
        animationTimingFunction: 'ease-in-out',
        animationIterationCount: 'infinite',
    },
}));

const sessionManagerStyles = makeStyles((theme) => ({
    alignCenter: {
        display: 'flex',
        alignItems: 'center',
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            // marginLeft: theme.spacing(3),
            width: 'auto',
            margin: '0px',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#BDC3C7',
    },
    inputRoot: {
        color: 'inherit',
        border: '1px solid #BDC3C7',
        borderRadius: '5px',
    },
    inputInput: {
        padding: theme.spacing(1),
        // vertical padding + font size from searchIcon
        // paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    actionBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '10px',
    },
    actionBarRight: {
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
    },
    actionButton: {
        margin: '15px',
        background: (props) => props.colors.button.primaryBackground,
        color: (props) => props.colors.button.primaryForeground,
        fontWeight: 600,
        '&:hover': {
            background: (props) => props.colors.button.primaryBackground,
            color: (props) => props.colors.button.primaryForeground,
            filter: 'brightness(90%)',
        },
    },
    content: {
        flexGrow: 1,
        padding: '25px 50px',
    },
    pin: {
        display: 'flex',
        cursor: 'pointer',
        '& svg': {
            height: '20px',
            width: '20px',
        },
        '& svg:hover': {
            filter: 'brightness(50%)',
        },
    },
    isPinned: {
        color: '#E84C3C',
        display: 'flex',
    },
    advancedSearchContainer: {
        backgroundColor: '#ecf0f1',
        borderRadius: '2px',
        padding: '15px',
    },
    contentAddButton: {
        padding: '10px !important',
        borderRadius: '25px !important',
        background: (props) =>
            `${props.colors.button.primaryBackground} !important`,
        color: (props) => `${props.colors.button.primaryForeground} !important`,
    },
    sortContainer: {
        display: 'flex',
        marginTop: '16px',
    },
    selectRoot: {
        '& .MuiSelect-root': {
            padding: '13px 14px',
        },
    },
    sessionActionBar: {
        width: '100%',
        marginTop: '12px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        gap: '20px',
        alignItems: 'center',
    },
    filterFieldset: {
        padding: '7px',
        border: '1px solid #cccccc',
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'left',
        gap: '10px',
        flexWrap: 'wrap',
    },
    filterFieldsetLegend: {
        marginLeft: '10px',
        padding: '5px',
        fontSize: '13px',
        color: '#6d7178',
    },
    ExcludeGlobalFieldset: {
        marginTop: '-12px',
        border: '1px solid #cccccc',
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'center',
    },
    ExcludeGlobalFieldsetLegend: {
        margin: 'auto 8px',
        padding: '5px',
        fontSize: '13px',
        color: '#6d7178',
    },
}));

export {
    accordionStyles,
    autoCompleteStyles,
    panelStyles,
    tabStyles,
    useToolbarStyles,
    modalStyles,
    makeButtonStyles,
    procedureSessionStyles,
    sessionManagerStyles,
};
