import { useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Button, Typography } from '@mui/material';

import { useStyles } from './styles';
// import ProcedureFormNode from '../ProcedureFormNode';
import { ProcedureQuestions } from '../ProcedureQuestions';
import { ProcedureNodeTypes } from '../../ProcedureNodeTypes';
import { getChecklistData } from '../../_features/checklistDataSlice';
import { LrsVerbs } from 'GeminiViewerComponent/_features/xAPI/xApiData';
import { saveStatement } from 'GeminiViewerComponent/_features/xAPI/xapiSlice';
import {
    getActiveTree,
    gotoNode,
    resetCurrentTree,
    setActiveTreeId,
} from '../../_features/procedureSlice';

const ProcedureResult = ({
    currentNode,
    contentService,
    useZoneNav,
    questionsData,
    hasAnswer = true,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const activeTree = useSelector(getActiveTree);
    const userData = useSelector(getChecklistData);

    useEffect(() => {
        // if (!hasAnswer) {
        //     dispatch(setChecklistInitialState());
        // }
        dispatch(
            saveStatement({
                LrsVerb: LrsVerbs.Answered,
                response: {
                    action: 'procedureResults',
                    node_id: currentNode.node_id,
                    statementType: 'REPORT_FINAL',
                },
                extensions: userData, // TODO HERE
            })
        );
    }, []);

    const reactToPrintContent = () => {
        return document.getElementById('procedure-results');
    };

    const handlePdfDownload = useReactToPrint({
        content: reactToPrintContent,
        pageStyle: `
            @page {
                size: auto;
                margin: 10mm;
            }
            @media print {
                html,
                body {
                    height: initial !important;
                    overflow: initial !important;
                    -webkit-print-color-adjust: exact;
                }
            }
        `,
    });

    const handleReturn = () => {
        if (activeTree.parent_tree_id) {
            dispatch(resetCurrentTree());
            dispatch(setActiveTreeId(activeTree.parent_tree_id));
            dispatch(gotoNode(activeTree.return_node_id));
        }
    };

    return (
        <Grid
            id={'procedure-results'}
            container
            className={classes.procedureResults}
        >
            <style>
                {`@media print {
                    #root {
                        width:98% !important;
                    }
                    * {
                        margin-bottom: 0px !important;
                        margin-top: 0px !important;
                    }
                    div {
                        float: none !important;
                        //position: static !important;
                        display: block !important;
                        box-sizing: content-box !important;
                    }
                    .header {
                        display: none !important;
                    }
                    div.Pane.vertical.Pane1 > div, .Resizer.vertical  {
                        border:0 !important;
                        display: none;
                    }
                    button {
                        display: none !important;
                    }
                    legend {
                        display: none !important;
                    }
                    div.MuiTextField-root {
                        margin-top:5px !important;
                    }
                    input.Mui-disabled, .multiline-readonly {
                        -webkit-text-fill-color: #000000;
                        //border: 1px solid rgba(0, 0, 0, 0.38);
                        //border-radius: 4px;
                        max-width: 200px;
                        height: 0;
                        padding: 16.5px 14px;
                        line-height: 1;
                        width: 80%;
                    }

                    label.MuiFormLabel-root {
                        padding: 0 5px !important;
                        background-color: #ffffff;
                    }

                    div.MuiFormControl-root {
                        padding: 5px 0px !important;
                        margin-top: 5px !important;
                    }

                    .MuiSelect-select {
                        padding: 5px 15px;
                    }

                    div.MuiInputBase-root {
                        max-width: 200px;
                        padding: 5px 0px;
                    }

                    .multiline-readonly {
                        max-width: 100%;
                        border: 1px solid rgba(0, 0, 0, 0.38);
                        border-radius: 4px;
                        width: 95%;
                        height: auto;
                    }

                    div.initial-text input {
                        width: 25px;
                    }

                    div.MuiInput-underline {
                        width: 50px;
                        margin-right: auto;
                        margin-left: auto;
                    }

                    #user-info {
                        color:black;
                    }
                }
            `}
            </style>
            <Grid item xs={12} sm={12} md={12}>
                {questionsData.length > 0 ? (
                    <div
                        id="print-pdf-btn-container"
                        style={{
                            width: '200px',
                            marginRight: 'auto',
                            marginLeft: 'auto',
                        }}
                    >
                        <Button
                            onClick={handlePdfDownload}
                            variant="contained"
                            disableElevation
                        >
                            Download PDF
                        </Button>
                    </div>
                ) : (
                    <Typography
                        width="100%"
                        textAlign="center"
                        p={1}
                        variant="h5"
                    >
                        No Result
                    </Typography>
                )}
                {activeTree.parent_tree_id && (
                    <div
                        style={{
                            width: 'fit-content',
                            marginLeft: 'auto',
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleReturn}
                        >
                            Return
                        </Button>
                    </div>
                )}
                {questionsData.map((node, index) => {
                    switch (node?.type) {
                        // case ProcedureNodeTypes.form.type:
                        //     return (
                        //         <div
                        //             className={
                        //                 index == 0 ? '' : classes.pageBreak
                        //             }
                        //         >
                        //             <ProcedureFormNode
                        //                 keyPrefix={`proc_results_${currentNode.id}`}
                        //                 node={node}
                        //                 formFields={node?.fields}
                        //                 isSubmitAllow={false}
                        //                 isReadOnly={true}
                        //             />
                        //         </div>
                        //     );
                        case ProcedureNodeTypes.content.type:
                            return (
                                <div
                                    className={
                                        index == 0 ? '' : classes.pageBreak
                                    }
                                >
                                    <ProcedureQuestions
                                        key={`proc_results_${currentNode.id}_${node.id}`}
                                        contentService={contentService}
                                        useZoneNav={useZoneNav}
                                        displayNode={node}
                                        displayNodesFields={node?.fields}
                                        isSubmitAllow={false}
                                        isReadOnly={true}
                                        idx={index}
                                    />
                                </div>
                            );
                        default:
                            return;
                    }
                })}
            </Grid>
        </Grid>
    );
};

export { ProcedureResult };
