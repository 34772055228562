// eslint-disable-next-line
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { MdClose } from 'react-icons/md';
import { createRoot } from 'react-dom/client';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton, Tooltip } from '@mui/material';

import { TagBar } from './TagBar';
import { OrbitViewer } from './OrbitViewer';
import { loadZone } from 'shared/loadZoneSlice';
import { makeCommonStyles } from './Common/styles';
import { MarzipanoViewer } from './MarzipanoViewer';
import { SidePanel } from '../components/SidePanel';
import { GeminiTitleBar } from './Common/GeminiTitleBar';
import { FlyerDisplay } from '../components/FlyerDisplay';
import { getConfig } from 'GeminiViewerComponent/_features/config/configSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    isZoneVisible,
    zoneHasTag,
} from 'GeminiViewerComponent/_helpers/hotspot_helpers';
import {
    HotspotIconTypes,
    ProcedureSplitViewTypes,
} from 'GeminiViewerComponent/_helpers';
import {
    getActiveAssetActiveZone,
    getDefaultHighlightIconId,
    getSelectedSearchItemId,
    selectActiveAsset,
    selectCompactZones,
} from 'GeminiViewerComponent/_features/asset/assetSlice';
import {
    displayProcedureContentVersion,
    getLoadedAssetType,
    getProcedureContentVersion,
    getProcedureLoaded,
    getTagFilters,
    loadAssociateAsset,
    loadChecklist,
    setDisplayAssetButton,
} from 'GeminiViewerComponent/_features/globals/visibilitySlice';

const SplitViewButton = ({ viewType, splitViewMode, setSplitViewMode }) => {
    return (
        <Tooltip title={viewType?.type?.toUpperCase()}>
            <IconButton
                onClick={() => setSplitViewMode(viewType?.id)}
                style={{
                    padding: 0,
                    backgroundColor: '#ffffff',
                    borderRadius: 'inherit',
                }}
            >
                <Box
                    style={{
                        width: '35px',
                        height: '35px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '3px',
                        border: '1px solid #ffffff',
                        backgroundColor: `${
                            viewType.id === splitViewMode
                                ? 'white'
                                : 'lightgray'
                        }`,
                    }}
                >
                    <img
                        src={viewType.icon}
                        alt={`split view ${viewType?.type}`}
                        height={'30px'}
                        width={'30px'}
                    />
                </Box>
            </IconButton>
        </Tooltip>
    );
};

const GeminiContainer = ({
    draggableFlag,
    setDraggableFlag,
    hotspotIcons,
    hotspotElements,
    setHotspotElements,
    onSelectHotspot,
    onShiftRightClick,
    onRightClick,
    onClick,
    onUpdatedHotspot,
    onDeletedHotspot,
    onUpdatedLinkHotspot,
    onDeletedLinkHotspot,
    onEditClicked,
    onItemNavClicked,
    onNavClicked,
    onClose,
    PanoItemHotspot,
    PanoLinkHotspot,
    isMultipleHotspotEnabled,
    itemHotspots,
    navigationHotspots,
    onSearchItems,
    searchItemSelected,
    onClickRefreshItem,
    setActiveItem,
    readOnly,
    handleItemDragging,
    handleNavDragging,
    enableItemDragging,
    enableNavDragging,
    undoRedoProps,
    handleMultipleHotspot,
    viewerRef = null,
    splitViewMode = null,
    setSplitViewMode = () => {},
    headerRef = null,
    zoneMediaConfigValue = false,
}) => {
    const dispatch = useDispatch();
    const theme = useSelector(selectActiveTheme);
    const commonStyles = makeCommonStyles(theme);
    const INIT_HIDE_NAV_OPTIONS = {
        hideNavBar: false,
        hideNavZones: false,
        hideNavSearch: false,
        hideNavTitle: false,
        hideNavControls: false,
    };
    const [hideNavOptions, setHideNavOptions] = useState(INIT_HIDE_NAV_OPTIONS);
    const tridiRef = useRef(null);
    const allZones = useSelector(selectCompactZones);
    const config = useSelector(getConfig);
    const isProcedureLoaded = useSelector(getProcedureLoaded);
    const activeAsset = useSelector(selectActiveAsset);
    const selectedSearchItemId = useSelector(getSelectedSearchItemId);
    const defaultHighlightHotspot = useSelector(getDefaultHighlightIconId);
    const loadedAssetType = useSelector(getLoadedAssetType);
    const [itemTagsList, setItemTagsList] = useState([]);
    const tagContainerRef = useRef(null);
    const procedureContentVersion = useSelector(getProcedureContentVersion);
    const [splitViewTopPos, setSplitViewTopPos] = useState(0);

    useEffect(() => {
        if (config.modeType === 'viewer') {
            setHideNavOptions({
                hideNavBar:
                    activeAsset?.options?.hide_nav_zones &&
                    activeAsset?.options?.hide_nav_controls &&
                    activeAsset?.options?.hide_nav_search &&
                    activeAsset?.options?.hide_nav_title,
                hideNavZones: activeAsset?.options?.hide_nav_zones,
                hideNavSearch: activeAsset?.options?.hide_nav_search,
                hideNavTitle: activeAsset?.options?.hide_nav_title,
                hideNavControls: activeAsset?.options?.hide_nav_controls,
            });
        } else if (config.modeType === 'author') {
            setHideNavOptions(INIT_HIDE_NAV_OPTIONS);
        }
    }, [config, isProcedureLoaded, activeAsset]);

    let activeZone = useSelector(getActiveAssetActiveZone);

    const tagFilters = useSelector(getTagFilters);

    const availableZonesMemo = useMemo(() => {
        let availableZones = {};
        let selected_item_tags = [];
        if (
            Array.isArray(tagFilters.item_tags) &&
            tagFilters.item_tags.length > 0
        ) {
            tagFilters.item_tags.forEach((tag) => {
                selected_item_tags.push(tag.tag_id);
            });
            allZones.forEach((zone) => {
                let zone_tags = [];
                zone.item_tags.forEach((tag) => {
                    zone_tags.push(tag.tag_id);
                });
                if (
                    zoneHasTag(
                        tagFilters.zone_tags.map((tag) => tag.tag_id),
                        zone
                    ) &&
                    (config.user.role.toUpperCase() === 'ADMIN' ||
                        isZoneVisible(config.user.audienceIds, zone)) &&
                    zone_tags.length > 0 &&
                    zone_tags.filter((tag) => selected_item_tags.includes(tag))
                        .length > 0
                ) {
                    availableZones[zone.zone_id] = zone;
                }
            });
        } else {
            allZones.forEach((zone) => {
                if (
                    zoneHasTag(
                        tagFilters.zone_tags.map((tag) => tag.tag_id),
                        zone
                    ) &&
                    (config.user.role.toUpperCase() === 'ADMIN' ||
                        isZoneVisible(config.user.audienceIds, zone))
                ) {
                    availableZones[zone.zone_id] = zone;
                }
            });
        }
        return availableZones;
    }, [allZones, config.user.role, config.user.audienceIds, tagFilters]);

    function createZonesByCategory(zones) {
        const zonesByCategory = {};

        for (var zoneId in zones) {
            let zone = zones[zoneId];
            const category = zone.category ? zone.category : 'NONE';
            var zoneList = zonesByCategory[category];
            if (!zoneList) {
                zoneList = [];
                zonesByCategory[category] = {
                    open: false,
                    zones: zoneList,
                };
            } else {
                zoneList = zoneList.zones;
            }
            zoneList.push(zone);
        }
        return zonesByCategory;
    }

    const zonesByCategoryInit = createZonesByCategory(availableZonesMemo);

    const [zonesByCategory, setZonesByCategory] = useState(zonesByCategoryInit);

    useEffect(() => {
        const zoneByCategoryUpdated = createZonesByCategory(availableZonesMemo);
        setZonesByCategory(zoneByCategoryUpdated);
    }, [tagFilters, availableZonesMemo]);

    function handleCategoryClick(categoryName) {
        const open = !zonesByCategory[categoryName].open;

        const zoneByCategoryUpdated = createZonesByCategory(availableZonesMemo);
        zoneByCategoryUpdated[categoryName].open = open;

        setZonesByCategory(zoneByCategoryUpdated);
    }

    const hotspotItemIcon = useCallback(
        (item) => {
            if (
                (selectedSearchItemId &&
                    selectedSearchItemId === item.item_id) ||
                procedureContentVersion?.doAction?.item_id === item.item_id
            ) {
                return (
                    hotspotIcons?.length > 0 &&
                    hotspotIcons.find(
                        (data) =>
                            data?.hotspot_icon_id === defaultHighlightHotspot
                    )
                );
            }
            const iconId =
                item.hotspot_icon_id || item.hotspot_icon_id === 0
                    ? item.hotspot_icon_id
                    : item.hotspot_icon
                    ? item.hotspot_icon.hotspot_icon_id
                    : activeAsset.item_hotspot_icon_id;
            if (!iconId || iconId === 0) {
                const defaultIcon =
                    hotspotIcons?.length > 0 &&
                    hotspotIcons.find(
                        (data) =>
                            data?.is_default &&
                            data?.hotspot_type_id === HotspotIconTypes.Item.id
                    );
                if (!defaultIcon) {
                    return hotspotIcons.find(
                        (data) =>
                            data?.hotspot_type_id === HotspotIconTypes.Item.id
                    );
                }
                return defaultIcon;
            }
            return (
                hotspotIcons?.length > 0 &&
                hotspotIcons.find((data) => data?.hotspot_icon_id === iconId)
            );
        },
        [selectedSearchItemId, procedureContentVersion, defaultHighlightHotspot]
    );

    const hotspotNavIcon = (link) => {
        const iconId =
            link.hotspot_icon_id || link.hotspot_icon_id === 0
                ? link.hotspot_icon_id
                : link.hotspot_icon
                ? link.hotspot_icon.hotspot_icon_id
                : activeAsset.nav_hotspot_icon_id;
        if (!iconId || iconId === 0) {
            const defaultIcon =
                hotspotIcons?.length > 0 &&
                hotspotIcons.find(
                    (data) =>
                        data?.is_default &&
                        data?.hotspot_type_id === HotspotIconTypes.Navigation.id
                );

            if (!defaultIcon) {
                return hotspotIcons.find(
                    (data) =>
                        data?.hotspot_type_id === HotspotIconTypes.Navigation.id
                );
            }
            return defaultIcon;
        }
        return (
            hotspotIcons?.length > 0 &&
            hotspotIcons.find((data) => data?.hotspot_icon_id === iconId)
        );
    };

    function CreateHotspotElementsFromZone(zoneInfo) {
        var hotspots = [];
        let navLinks = zoneInfo.nav_links;
        let items = zoneInfo.items;

        items?.forEach((item) => {
            let element = document.querySelector(`#info-${item.item_id}`);
            if (!element) {
                element = document.createElement('div');
                element.classList.add('hotspot');
                element.classList.add('info-hotspot');
                element.id = `info-${item.item_id}`;
            }

            const root = createRoot(element);

            root.render(
                <PanoItemHotspot
                    zoneId={zoneInfo.zone_id}
                    config={config}
                    hotspot={item}
                    icon={hotspotItemIcon(item)}
                    onClicked={onSelectHotspot}
                    setDraggableFlag={setDraggableFlag}
                    readOnly={readOnly}
                    enableDragging={enableItemDragging}
                />
            );

            hotspots.push({
                item_id: item.item_id,
                element: element,
            });
        });

        navLinks?.forEach((navLink) => {
            let element = document.querySelector(
                `#link-${navLink.nav_link_id}`
            );
            if (!element) {
                element = document.createElement('div');
                element.classList.add('hotspot');
                element.classList.add('link-hotspot');
                element.id = `link-${navLink.nav_link_id}`;
            }

            const root = createRoot(element);

            root.render(
                <PanoLinkHotspot
                    config={config}
                    hotspot={navLink}
                    icon={hotspotNavIcon(navLink)}
                    allZones={allZones}
                    onNavClicked={onLocalNavClicked}
                    setDraggableFlag={setDraggableFlag}
                    readOnly={readOnly}
                    enableDragging={enableNavDragging}
                />
            );

            hotspots.push({
                nav_link_id: navLink.nav_link_id,
                element: element,
            });
        });

        return hotspots;
    }

    function CreateHotspotElements() {
        let hotspotByZone = {};

        allZones.forEach((activeZone) => {
            const hotspots = CreateHotspotElementsFromZone(activeZone);
            hotspotByZone[activeZone.zone_id] = hotspots;
        });

        return hotspotByZone;
    }

    useEffect(() => {
        if (setHotspotElements) {
            setHotspotElements(CreateHotspotElements());
        }
    }, [activeZone, selectedSearchItemId]); // eslint-disable-line react-hooks/exhaustive-deps

    const onLocalNavClicked = (zoneId) => {
        const zone = allZones.find((zone) => zone.zone_id === zoneId);
        if (zone) {
            dispatch(loadZone({ zoneId: zone.zone_id }));
        }
    };

    if (!activeZone || !activeZone.display_name) {
        return null;
    }

    const handleResetPosition = () => {
        if (window.geminiViewManager) {
            window.geminiViewManager.resetViewParams();
        }
    };

    const orbitMode = activeZone.orbit_frame === undefined ? false : true;

    return (
        <div
            style={{ height: '100%', position: 'relative', overflow: 'hidden' }}
        >
            <GeminiTitleBar
                hideNavOptions={hideNavOptions}
                activeZone={activeZone}
                zonesByCategory={zonesByCategory}
                onCategoryClick={handleCategoryClick}
                onSearchItems={onSearchItems}
                searchItemSelected={searchItemSelected}
                handleResetPosition={handleResetPosition}
                onClose={onClose}
                onClickRefreshItem={onClickRefreshItem}
                handleItemDragging={handleItemDragging}
                handleNavDragging={handleNavDragging}
                enableItemDragging={enableItemDragging}
                enableNavDragging={enableNavDragging}
                undoRedoProps={undoRedoProps}
                isOrbitMode={orbitMode}
                tridiRef={tridiRef}
                setSplitViewTopPos={setSplitViewTopPos}
                zoneMediaConfigValue={zoneMediaConfigValue}
            />
            <div style={{ height: 'calc(100% - 40px)', width: '100%' }}>
                {isProcedureLoaded && config.modeType === 'viewer' && (
                    <Box
                        className={commonStyles.splitViewModes}
                        style={{
                            top: `${splitViewTopPos * 40}px`,
                        }}
                    >
                        {Object.keys(ProcedureSplitViewTypes).map(
                            (viewType) =>
                                viewType !== 'None' && (
                                    <SplitViewButton
                                        key={`${ProcedureSplitViewTypes[viewType]?.id}`}
                                        viewType={
                                            ProcedureSplitViewTypes[viewType]
                                        }
                                        splitViewMode={splitViewMode}
                                        setSplitViewMode={setSplitViewMode}
                                    />
                                )
                        )}
                        {loadedAssetType === 3 && (
                            <IconButton
                                onClick={() => {
                                    dispatch(loadAssociateAsset(false));
                                    dispatch(setDisplayAssetButton(true));
                                    dispatch(loadChecklist(true));
                                    dispatch(
                                        displayProcedureContentVersion(null)
                                    );
                                }}
                                style={{
                                    padding: 0,
                                    color: '#ffffff',
                                    fontWeight: 'bold',
                                }}
                            >
                                <MdClose
                                    className="react-icon"
                                    fontWeight={'1000'}
                                />
                            </IconButton>
                        )}
                    </Box>
                )}
                <div
                    ref={viewerRef}
                    className={clsx([
                        commonStyles.viewWrapper,
                        orbitMode
                            ? commonStyles.orbitViewWrapper
                            : commonStyles.panoViewWrapper,
                    ])}
                    style={{ height: '100%' }}
                    // onPointerMove={(e) => onDragTagContainer(e)}
                    // onPointerUp={(e) => setAllowMoving(false)}
                >
                    {orbitMode ? (
                        <OrbitViewer
                            config={config}
                            hotspotIcons={hotspotIcons}
                            draggableFlag={draggableFlag}
                            setDraggableFlag={setDraggableFlag}
                            onSelectHotspot={onSelectHotspot}
                            onShiftRightClick={onShiftRightClick}
                            onRightClick={onRightClick}
                            onUpdatedHotspot={onUpdatedHotspot}
                            onDeletedHotspot={onDeletedHotspot}
                            onUpdatedLinkHotspot={onUpdatedLinkHotspot}
                            onDeletedLinkHotspot={onDeletedLinkHotspot}
                            onEditClicked={onEditClicked}
                            onItemNavClicked={onItemNavClicked}
                            onNavClicked={
                                onNavClicked ? onNavClicked : onLocalNavClicked
                            }
                            onClose={onClose}
                            PanoItemHotspot={PanoItemHotspot}
                            PanoLinkHotspot={PanoLinkHotspot}
                            onClick={onClick}
                            isMultipleHotspotEnabled={isMultipleHotspotEnabled}
                            itemHotspots={itemHotspots}
                            navigationHotspots={navigationHotspots}
                            zonesByCategory={zonesByCategory}
                            onCategoryClick={handleCategoryClick}
                            onSearchItems={onSearchItems}
                            searchItemSelected={searchItemSelected}
                            onClickRefreshItem={onClickRefreshItem}
                            hideNavOptions={hideNavOptions}
                            enableItemDragging={enableItemDragging}
                            enableNavDragging={enableNavDragging}
                            undoRedoProps={undoRedoProps}
                            tridiRef={tridiRef}
                            splitViewMode={splitViewMode}
                            viewerRef={viewerRef}
                        />
                    ) : (
                        <MarzipanoViewer
                            hotspotElements={hotspotElements}
                            // onSelectHotSpot={onSelectHotspot}
                            onShiftRightClick={onShiftRightClick}
                            onRightClick={onRightClick}
                            onClick={onClick}
                            isMultipleHotspotEnabled={isMultipleHotspotEnabled}
                            onClose={onClose}
                            zonesByCategory={zonesByCategory}
                            onCategoryClick={handleCategoryClick}
                            onSearchItems={onSearchItems}
                            searchItemSelected={searchItemSelected}
                            onClickRefreshItem={onClickRefreshItem}
                            setActiveItem={setActiveItem}
                            hideNavOptions={hideNavOptions}
                            undoRedoProps={undoRedoProps}
                            handleMultipleHotspot={handleMultipleHotspot}
                        />
                    )}
                    {config.modeType === 'author' && (
                        <>
                            <TagBar
                                tagContainerRef={tagContainerRef}
                                itemTagsList={itemTagsList}
                                setItemTagsList={setItemTagsList}
                            />
                            <SidePanel
                                linkTypes={config.linkConfig.LinkTypes}
                                audienceIds={config.user.audienceIds}
                                zoneMediaConfigValue={zoneMediaConfigValue}
                            />
                            <FlyerDisplay />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export { GeminiContainer };
