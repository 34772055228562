import { config } from 'gemini-config';
import { getNetworkService } from 'GeminiViewerComponent/_helpers';
import { getAccountService } from 'GeminiViewerComponent/_features/services/account.service';

const accountService = getAccountService({ config: config });
const networkService = getNetworkService({
    config: config,
    checkAuth: accountService.checkAuth,
});
const baseUrl = `${config.apiUrl}/category`;

const getAll = async (
    page = 1,
    pageSize = 10,
    searchString = '',
    sort = 'display_name'
) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const params = {
        filters: `display_name@=*${searchString}`,
        sorts: sort,
        page: page,
        pageSize: pageSize,
    };

    const query = new URLSearchParams(params).toString();
    try {
        const response = await networkService.get(
            `${baseUrl}/getallbyclientid/${user.client_id}?${query}`
        );
        const categories = response.data;
        const pageInfo = response.headers['x-pagination'];
        return { pageInfo: pageInfo, categories: categories };
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getById = async (id) => {
    try {
        const response = await networkService.get(`${baseUrl}/${id}`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const create = async (data) => {
    try {
        const user = JSON.parse(localStorage.getItem('user'));

        const params = { ...data, client_id: user?.client_id };

        const response = await networkService.post(baseUrl, params);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const update = async (params) => {
    try {
        const response = await networkService.put(
            `${baseUrl}/${params?.category_id}`,
            params
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const _delete = async (id) => {
    try {
        const response = await networkService.delete(`${baseUrl}/${id}`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const deleteArray = async (ids) => {
    try {
        const query = ids.join('&ids=');
        const response = await networkService.delete(
            `${baseUrl}/?ids=${query}`
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

export const categoryService = {
    getAll,
    getById,
    create,
    update,
    delete: _delete,
    deleteArray,
};
