import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MdArrowLeft, MdArrowRight } from 'react-icons/md';
import { Box, IconButton, Typography } from '@mui/material';

import { linkDisplayStyles } from '../../styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    getAllSimilarLinks,
    getLinkDisplayData,
} from 'GeminiViewerComponent/_features/globals/visibilitySlice';

export const LinkPagination = ({
    handleLinkNavigation,
    currentActiveLinkIndex,
    setCurrentActiveLinkIndex,
}) => {
    const theme = useSelector(selectActiveTheme);
    const classes = linkDisplayStyles(theme);
    const allContentLinks = useSelector(getAllSimilarLinks);
    const linkData = useSelector(getLinkDisplayData);

    useEffect(() => {
        if (allContentLinks?.length > 0 && Object.keys(linkData).length > 0) {
            setCurrentActiveLinkIndex(
                allContentLinks.findIndex(
                    (link) => link.link_id === linkData?.link_id
                )
            );
        }
    }, [linkData, allContentLinks]);

    return (
        <>
            <Box className={classes.linkTitleWrapper}>
                <Typography className={classes.linkTitleText}>
                    {linkData?.display_name}
                </Typography>
                {allContentLinks?.length > 1 && (
                    <Typography className={classes.linkIndex}>
                        {`${currentActiveLinkIndex + 1} / ${
                            allContentLinks?.length
                        }`}
                    </Typography>
                )}
            </Box>
            {allContentLinks?.length > 1 && (
                <Box className={classes.navigationButtonWrapper}>
                    <IconButton
                        className={classes.navigationButton}
                        style={{ left: '20px' }}
                        variant="contained"
                        size="small"
                        onClick={() => {
                            handleLinkNavigation(currentActiveLinkIndex - 1);
                        }}
                        disabled={currentActiveLinkIndex === 0}
                    >
                        <MdArrowLeft className="react-icon" />
                    </IconButton>
                    <IconButton
                        className={classes.navigationButton}
                        style={{ right: '20px' }}
                        variant="contained"
                        size="small"
                        onClick={() => {
                            handleLinkNavigation(currentActiveLinkIndex + 1);
                        }}
                        disabled={
                            currentActiveLinkIndex ===
                            allContentLinks.length - 1
                        }
                    >
                        <MdArrowRight className="react-icon" />
                    </IconButton>
                </Box>
            )}
        </>
    );
};
