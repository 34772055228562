import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Chip, IconButton, Paper, Tooltip } from '@mui/material';
import clsx from 'clsx';
import { IoMdInfinite } from 'react-icons/io';

import { useStyles } from './styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { getUnPositionedItems } from 'GeminiViewerComponent/_helpers/hotspot_helpers';
import { panoDraggable } from '../../../PanoControls/PanoDraggable';
import {
    getShowOnlyByFilter,
    getTagFilters,
} from 'GeminiViewerComponent/_features/globals/visibilitySlice';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import {
    getAllAssetItems,
    resetAssetItem,
    selectAllAssetItem,
    selectAllAssetItemStatus,
} from '_features/items/itemsTableSlice';
import { getActiveAssetActiveZone } from 'GeminiViewerComponent/_features/asset/assetSlice';

const UnPositionedItemsBar = ({ itemChipsRef, onMovedHotspot, assetId }) => {
    //#region Constants
    const dispatch = useDispatch();
    //#endregion Constants

    //#region Hooks
    //#endregion Hooks

    //#region State
    const [isDisplayAllZone, setIsDisplayAllZone] = useState(false);
    const activeZone = useSelector(getActiveAssetActiveZone);
    const unPositionedItems = getUnPositionedItems(activeZone);
    const [allZoneItems, setAllZoneItems] = useState([...unPositionedItems]);
    //#endregion State

    //#region Selectors
    const theme = useSelector(selectActiveTheme);
    const classes = useStyles(theme);
    const tagFilters = useSelector(getTagFilters);
    const allAssetItem = useSelector(selectAllAssetItem);
    const showOnlyByFilter = useSelector(getShowOnlyByFilter);
    const allAssetItemStatus = useSelector(selectAllAssetItemStatus);
    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Effects
    useEffect(() => {
        if (allAssetItemStatus === LoadingStatus.Idle) {
            fetchItemAsset(allAssetItemStatus);
        }
    }, [allAssetItemStatus]);

    useEffect(() => {
        updateList();
    }, [activeZone?.items]);

    useEffect(() => {
        return () => {
            dispatch(resetAssetItem());
        };
    }, []);
    //#endregion Effects

    //#region Methods
    const fetchItemAsset = async (allAssetItemStatus) => {
        await dispatch(getAllAssetItems({ assetId: assetId }));
    };

    const updateList = useCallback(() => {
        toggleZoneMedia(isDisplayAllZone, false);
    }, [activeZone?.items, isDisplayAllZone]);

    const toggleZoneMedia = useCallback(
        async (displayAllZone, isToggle = true) => {
            if (displayAllZone) {
                if (allAssetItem && allAssetItem?.length > 0) {
                    const assetUnpositionedItems = allAssetItem?.filter(
                        (item) =>
                            (!item.yaw && !item.flat_x) ||
                            (!item.flat_x &&
                                (item.flat_x < 0 || item.flat_y < 0))
                    );
                    setAllZoneItems([...assetUnpositionedItems]);
                }
            } else {
                const currentZoneUnpositionedItems = activeZone?.items?.filter(
                    (item) =>
                        (!item.yaw && !item.flat_x) ||
                        (!item.flat_x && (item.flat_x < 0 || item.flat_y < 0))
                );
                setAllZoneItems([...currentZoneUnpositionedItems]);
            }
            if (isToggle) {
                setIsDisplayAllZone((prev) => !prev);
            }
        },
        [
            tagFilters,
            allZoneItems,
            activeZone?.items,
            showOnlyByFilter,
            allAssetItem,
        ]
    );

    const isCurrentZoneItem = activeZone?.items?.filter(
        (item) => !item?.flat_x && !item?.flat_y
    );
    const isAllZoneItem =
        allAssetItem &&
        allAssetItem?.length > 0 &&
        allAssetItem?.filter((item) => !item?.flat_x && !item?.flat_y);
    //#endregion Methods

    //#region Render time calcs
    //#endregion Render time calcs

    //#region Render
    return (
        <>
            {(isCurrentZoneItem?.length > 0 || isAllZoneItem?.length > 0) && (
                <Box className={classes.items}>
                    <Tooltip
                        title={`${
                            isDisplayAllZone ? 'Current Zone' : 'All Zones'
                        }`}
                    >
                        <IconButton
                            onClick={() => toggleZoneMedia(!isDisplayAllZone)}
                            size="large"
                            className={clsx(classes.allZoneMediaIcon, {
                                [classes.displayZoneMediaIcon]:
                                    isDisplayAllZone,
                            })}
                            sx={{ py: 0, px: '15px' }}
                        >
                            <IoMdInfinite
                                className={`react-icon ${classes.zoneMediaIcon}`}
                            />
                        </IconButton>
                    </Tooltip>
                    {allZoneItems && allZoneItems?.length > 0 && (
                        <Paper
                            component="ul"
                            ref={itemChipsRef}
                            className={classes.itemsWrapper}
                        >
                            {allZoneItems.map((item) => {
                                return (
                                    <li
                                        style={{
                                            marginRight: '10px',
                                        }}
                                        key={item?.item_id + '' + item?.zone_id}
                                    >
                                        <Tooltip title="Drag into scene to place item">
                                            <Chip
                                                label={item.display_name}
                                                size="small"
                                                className={classes.chip}
                                                onPointerDown={(event) => {
                                                    itemChipsRef.current.style.overflow =
                                                        'visible';
                                                    panoDraggable.handleMouseDown(
                                                        event,
                                                        event.currentTarget,
                                                        item,
                                                        onMovedHotspot,
                                                        false,
                                                        activeZone.orbit_frame ===
                                                            undefined
                                                            ? true
                                                            : false
                                                    );
                                                }}
                                            />
                                        </Tooltip>
                                    </li>
                                );
                            })}
                        </Paper>
                    )}
                </Box>
            )}
        </>
    );
    //#endregion Render
};

export { UnPositionedItemsBar };
