// eslint-disable-next-line
import React, { createElement } from 'react';
import { useSelector } from 'react-redux';
import { DtBackAction } from './components/DtBackAction';
import { DtResetAction } from './components/DtResetAction';

import { getCurrentNode } from '../../../../_features/decisionTree/decisionTreeSlice';

const DtNodeActions = () => {
    const currentNode = useSelector((state) => getCurrentNode(state));

    return (
        <div style={{ display: 'flex' }}>
            {!currentNode.isRoot && <DtBackAction />}
            {currentNode.isEnd && <DtResetAction />}
        </div>
    );
};

export { DtNodeActions };
