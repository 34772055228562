import React, { useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';
import { Chip } from '@mui/material';
import Dropzone from 'react-dropzone';
import { useSnackbar } from 'notistack';
import { MdClose } from 'react-icons/md';
import { CheckboxWithLabel } from 'formik-mui';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { accountsSlice } from 'app/store';
import { makeFormStyles } from '../styles';
const { selectActiveUser } = accountsSlice;
import SetDirtyForm from 'forms/SetDirtyForm';
import { ImageResizer } from './ImageResizer';
import { SelectionAutocomplete } from 'components';
import { FormActions } from '_helpers/form-action';
import { FormTextField } from 'components/TextField';
import { closePanel } from '_features/common/formSlice';
import { editAsset } from '_features/assets/editAssetSlice';
import { deleteKey } from 'GeminiViewerComponent/_helpers';
import { panelStyles } from 'GeminiViewerComponent/components/styles';
import { snackbarHandler } from 'GeminiViewerComponent/_helpers/snackbar-handler';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { selectActiveAsset } from 'GeminiViewerComponent/_features/asset/assetSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { LoadingLogo } from 'GeminiViewerComponent/components/LoadingLogo/LoadingLogo';
import {
    LoadingStatus,
    SavingStatus,
} from 'GeminiViewerComponent/_helpers/AsyncStatus';
import {
    selectAllAudiences,
    getAudienceStatus,
    fetchAudiencesPage,
} from '_features/audiences/audiencesSlice';
import {
    fetchAllNewsFlashPage,
    getAllNewsFlash,
    getNewsFlashStatus,
    setNewsFlashStatus,
} from 'GeminiViewerComponent/_features/newsFlash/newsFlashSlice';
import {
    activateLoading,
    deactivateLoading,
    setProgress,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    fetchTags,
    getTagStatus,
    selectTags,
    createTag,
} from '_features/tags/tagsSlice';
import {
    createZoneCategory,
    fetchZoneCategory,
    selectCategoryStatus,
    selectZoneCategory,
} from '_features/zones/zonesTableSlice';
import {
    addNewZone,
    clearEditZone,
    editZone,
    selectEditZoneLoadingStatus,
    selectEditZoneSavingStatus,
} from '_features/common/editZoneSlice';
import FieldValidationError from 'GeminiViewerComponent/components/Procedure/components/ProcedureFormNode/FormFields/FieldValidationError';

const ZoneForm = ({ formAction, zone }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);
    const panelClasses = panelStyles(theme);
    const activeUser = useSelector(selectActiveUser);
    const readOnlyMode =
        activeUser?.role === 'User' && formAction?.id === 2 ? true : false;
    let zoneSelectedAudienceIds = {
        ...zone,
        audiences:
            typeof zone?.audiences === 'undefined'
                ? []
                : zone?.audiences?.map((audience) => audience?.audience_id),
        tag_ids:
            typeof zone?.tags === 'undefined'
                ? []
                : zone?.tags?.map((tag) => tag?.tag_id),
        image_url: zone?.equirect_image_preview_url
            ? zone.equirect_image_preview_url
            : '',
        image_url1: zone?.flat_image_preview_url
            ? zone?.flat_image_preview_url
            : zone?.flat_image_url
            ? zone.flat_image_url
            : '',
        image_url2:
            zone?.equirect_image_preview_url !== undefined
                ? zone?.equirect_image_preview_url
                : zone?.flat_image_preview_url !== undefined
                ? zone?.flat_image_preview_url
                : '',
        news_flash_ids:
            typeof zone?.news_flashes === 'undefined'
                ? []
                : zone?.news_flashes?.map((tag) => tag?.news_flash_id),
    };
    const user = JSON.parse(localStorage.getItem('user'));
    const [initValues, setInitValues] = useState(zoneSelectedAudienceIds);

    // Reset init values when zone changes
    useEffect(() => {
        dispatch(setNewsFlashStatus(LoadingStatus.Idle));
        setInitValues({ description: '', ...zoneSelectedAudienceIds });
        //create mode
        if (formAction.id === FormActions.Create.id) {
            dispatch(clearEditZone());
        }
        // eslint-disable-next-line
    }, [zone]);

    // Get asset ID in the event form is creating new zone
    const asset = useSelector(selectActiveAsset);
    const editZoneLoadingStatus = useSelector(selectEditZoneLoadingStatus);
    const editZoneSavingStatus = useSelector(selectEditZoneSavingStatus);

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        var resultAction = null;
        delete values?.image_url;
        delete values?.image_url1;
        delete values?.image_url2;
        if (values?.flat_image?.name !== '') {
            values.flat_image_preview = values.zone_image_preview;
        }
        if (values?.equirect_image?.name !== '') {
            values.equirect_image_preview = values.zone_image_preview;
        }
        switch (formAction.id) {
            case FormActions.Edit.id:
                dispatch(activateLoading({ showProgress: true }));
                dispatch(
                    setProgress({
                        progress: 0,
                        label: 'Step 1 of 2: Uploading',
                        noAnimation: true,
                    })
                );

                resultAction = await dispatch(
                    editZone({
                        ...values,
                        ...{
                            zone_category_id:
                                values.zone_category_id === ''
                                    ? null
                                    : values.zone_category_id,
                        },
                        dispatch,
                    })
                );
                if (!resultAction.error) {
                    // Assign as initial view if specified
                    if (
                        asset.initial_zone_id === values.zone_id &&
                        values.is_initial_view === false
                    ) {
                        await dispatch(
                            editAsset({
                                asset_id: asset.asset_id,
                                initial_zone_id: null,
                            })
                        );
                    }
                    if (values.is_initial_view === true) {
                        await dispatch(
                            editAsset({
                                asset_id: asset.asset_id,
                                initial_zone_id: values.zone_id,
                            })
                        );
                    }
                    dispatch(closePanel({ formKey: 'zoneForm' }));
                }

                dispatch(deactivateLoading());
                break;
            case FormActions.Create.id:
                dispatch(activateLoading({ showProgress: true }));
                dispatch(
                    setProgress({
                        progress: 0,
                        label: 'Step 1 of 2: Uploading',
                        noAnimation: true,
                    })
                );
                resultAction = await dispatch(
                    addNewZone({
                        ...values,
                        ...{ asset_id: asset.asset_id, dispatch: dispatch },
                    })
                );
                if (!resultAction.error) {
                    const newZone = resultAction.payload;
                    // Assign as initial view if specified
                    if (
                        asset.initial_zone_id === newZone.zone_id &&
                        values.is_initial_view === false
                    ) {
                        await dispatch(
                            editAsset({
                                asset_id: asset.asset_id,
                                initial_zone_id: null,
                            })
                        );
                    }
                    if (values.is_initial_view === true) {
                        await dispatch(
                            editAsset({
                                asset_id: asset.asset_id,
                                initial_zone_id: newZone.zone_id,
                            })
                        );
                    }
                    resetForm();
                }
                dispatch(deactivateLoading());
                break;
            default:
                break;
        }

        // await dispatch(resetAssetZone());
        const { message, variant } = snackbarHandler(
            resultAction.meta.requestStatus,
            formAction.label
        );
        enqueueSnackbar(message, {
            action: (key) => <SnackbarDismiss key={key} />,
            variant: variant,
        });
        setSubmitting(false);
        deleteKey(`tags_${user.user_id}`);
        dispatch(fetchTags());
    };

    /* function onSceneChanged(newZoneId) {
        dispatch(setEditObjectId({formKey: 'zoneForm', object_id: newZoneId}));
        NOTE: Using reference to get latest value of formInfo, otherwise regular formInfo
        will be stale due to closure
        if (formInfoRef.current && formInfoRef.current.zoneForm.isOpen && formInfoRef.current.zoneForm.editObjectId !== newZoneId) {
            dispatch(setEditObjectId({formKey: 'zoneForm', object_id: newZoneId}));
        }
    } */

    const isBusy =
        editZoneLoadingStatus === LoadingStatus.Loading ||
        editZoneSavingStatus === SavingStatus.Saving;

    function appendToFilename(filename, appendString) {
        let dotIdx = filename.lastIndexOf('.');
        return `${filename.substring(
            0,
            dotIdx
        )}${appendString}${filename.substring(dotIdx)}`;
    }

    const onEquirectDrop = useCallback(
        async ({
            file,
            setFieldValue,
            setFieldError,
            setFieldTouched,
            values,
        }) => {
            setFieldError('equirect_image', '');
            if (values?.image_url1) {
                enqueueSnackbar('Already Regular Image is Uploaded', {
                    action: (key) => <SnackbarDismiss key={key} />,
                    variant: 'error',
                });
                return;
            }
            var img = new Image();
            img.src = window.URL.createObjectURL(file);
            img.onload = async function () {
                setFieldValue('equirect_image_width', img.naturalWidth, false);
                setFieldValue(
                    'equirect_image_height',
                    img.naturalHeight,
                    false
                );

                if (img.naturalWidth === img.naturalHeight * 2) {
                    setFieldValue('equirect_image', file, false);
                    const reader = new FileReader();

                    reader.onabort = () =>
                        console.log('file reading was aborted');
                    reader.onerror = () =>
                        console.log('file reading has failed');
                    reader.onload = (e) => {
                        setFieldValue('image_url', e.target.result);
                    };
                    reader.readAsDataURL(file);
                } else {
                    setFieldError('equirect_image', 'Image must be 2:1 ratio');
                    setFieldTouched('equirect_image', true, false);
                }
                window.URL.revokeObjectURL(img.src);
            };
        },
        []
    );

    const onDropPreviewImage = useCallback(async ({ file, setFieldValue }) => {
        if (file) {
            var img = new Image();
            img.src = window.URL.createObjectURL(file);
            img.onload = function () {
                setFieldValue('zone_image_width', img.naturalWidth);
                setFieldValue('zone_image_height', img.naturalHeight);
                window.URL.revokeObjectURL(img.src);
            };
            let imageResizer = new ImageResizer(file);
            let previewFile = await imageResizer.run(400, 400);

            let previewFilename = appendToFilename(file.name, '_preview');
            previewFile.name = previewFilename;

            setFieldValue('zone_image_preview', previewFile);
        }
        setFieldValue('preview_image', file);
        const reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = (e) => {
            setFieldValue('image_url2', e.target.result);
        };
        reader.readAsDataURL(file);
    }, []);

    const onFlatImageDrop = async ({ file, setFieldValue, values }) => {
        if (values?.image_url) {
            enqueueSnackbar('Already Pano Image is Uploaded', {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: 'error',
            });
            return;
        }
        if (file) {
            var img = new Image();
            img.src = window.URL.createObjectURL(file);
            img.onload = function () {
                setFieldValue('flat_image_width', img.naturalWidth);
                setFieldValue('flat_image_height', img.naturalHeight);
                window.URL.revokeObjectURL(img.src);
            };
            let imageResizer = new ImageResizer(file);
            let previewFile = await imageResizer.run(400, 400);

            let previewFilename = appendToFilename(file.name, '_preview');
            previewFile.name = previewFilename;

            setFieldValue('flat_image_preview', previewFile);
        }
        setFieldValue('flat_image', file);
        const reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = (e) => {
            setFieldValue('image_url1', e.target.result);
        };
        reader.readAsDataURL(file);
    };

    return isBusy ? (
        <LoadingLogo styleClassName={classes.loadingSvg} />
    ) : (
        <Formik
            enableReinitialize={true}
            initialValues={{
                is_initial_view: asset.initial_zone_id === initValues.zone_id,
                ...initValues,
            }}
            validate={(values) => {
                let errors = {};
                if (!values?.image_url && !values?.image_url1) {
                    errors = {
                        ...errors,
                        zone_image:
                            'Please upload either a Pano or Regular image',
                    };
                }
                return errors;
            }}
            validationSchema={Yup.object({
                display_name: Yup.string()
                    .max(150, 'Must be 150 characters or less')
                    .required('Required'),
                description: Yup.string().max(
                    255,
                    'Must be 255 characters or less'
                ),
                content_tag: Yup.string().max(
                    150,
                    'Must be 150 characters or less'
                ),
                audiences: Yup.array(),
                equirect_image: Yup.mixed(),
                flat_image: Yup.mixed(),
                is_initial_view: Yup.boolean(),
                zone_category_id: Yup.number(),
                tag_ids: Yup.array(),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                handleSubmit(values, setSubmitting, resetForm);
            }}
        >
            {({
                values,
                setFieldTouched,
                isSubmitting,
                setFieldValue,
                setFieldError,
                errors,
            }) => (
                <Form className={classes.form}>
                    <div className={classes.formHeader}>Zone Details</div>
                    <FormTextField
                        autoFocus
                        label="Zone Name"
                        name="display_name"
                        placeholder="Zone Name"
                        disabled={readOnlyMode}
                    />
                    <FormTextField
                        label="Description"
                        name="description"
                        placeholder="Description"
                        disabled={readOnlyMode}
                    />
                    {/* <ZoneFormTextField
                        label="Content Tag"
                        name="content_tag"
                        placeholder="Content Tag"
                        disabled={readOnlyMode}
                    />
                    <ZoneFormTextField
                        label="Pano ID"
                        name="pano_id"
                        placeholder="Pano ID"
                        disabled={disableSubmit}
                    /> */}
                    <Field className={classes.input} name="audiences">
                        {({ field: { value } }) => (
                            <SelectionAutocomplete
                                title="Audiences"
                                placeholder="Audiences"
                                keyProperty="audience_id"
                                nameProperty="display_name"
                                entityIds={value}
                                setEntityIds={setFieldValue}
                                entitySelector={selectAllAudiences}
                                entityStatusSelector={getAudienceStatus}
                                fetchEntityPage={fetchAudiencesPage}
                                formField={'audiences'}
                                fetchParams={{ page: 1, pageSize: 100 }}
                                readOnly={readOnlyMode}
                            />
                        )}
                    </Field>
                    <Field className={classes.input} name="zone_category_id">
                        {({ field: { value } }) => (
                            <SelectionAutocomplete
                                title="Zone Category"
                                placeholder="Zone Category"
                                keyProperty="zone_category_id"
                                nameProperty="display_name"
                                entityIds={value}
                                setEntityIds={setFieldValue}
                                entitySelector={selectZoneCategory}
                                entityStatusSelector={selectCategoryStatus}
                                fetchEntityPage={fetchZoneCategory}
                                formField={'zone_category_id'}
                                multiSelection={false}
                                enableCreatable
                                creatableDialogTitle={'zone category'}
                                creatableDialogFieldLabel={'Category'}
                                creatableDispatch={createZoneCategory}
                                fetchParams={{
                                    asset_id: asset.asset_id,
                                }}
                                creatableParams={{
                                    asset_id: asset.asset_id,
                                }}
                                readOnly={readOnlyMode}
                                fieldValidationLength={150}
                            />
                        )}
                    </Field>
                    <Field className={classes.input} name="tag_ids">
                        {({ field: { value } }) => (
                            <SelectionAutocomplete
                                title="Zone Tags"
                                placeholder="Zone Tags"
                                keyProperty="tag_id"
                                nameProperty="name"
                                entityIds={value}
                                setEntityIds={setFieldValue}
                                entitySelector={selectTags}
                                entityStatusSelector={getTagStatus}
                                fetchEntityPage={fetchTags}
                                formField={'tag_ids'}
                                enableCreatable
                                creatableDialogTitle={'tag'}
                                creatableDialogFieldLabel={'Tag'}
                                creatableDispatch={createTag}
                                enableCache
                                refreshCacheType={`tags_${user.user_id}`}
                                readOnly={readOnlyMode}
                                fieldValidationLength={150}
                            />
                        )}
                    </Field>
                    <Field className={classes.input} name="news_flash_ids">
                        {({ field: { value } }) => (
                            <SelectionAutocomplete
                                title="News Flash"
                                placeholder="News Flash"
                                keyProperty="news_flash_id"
                                nameProperty="display_name"
                                entityIds={value}
                                setEntityIds={setFieldValue}
                                entitySelector={getAllNewsFlash}
                                entityStatusSelector={getNewsFlashStatus}
                                fetchEntityPage={fetchAllNewsFlashPage}
                                formField={'news_flash_ids'}
                                readOnly={readOnlyMode}
                            />
                        )}
                    </Field>
                    <FormTextField
                        component={CheckboxWithLabel}
                        Label={{ label: 'Initial View' }}
                        label="Initial View"
                        type="checkbox"
                        checked={!!values?.is_initial_view}
                        onChange={(e) => {
                            setFieldValue('is_initial_view', e.target.checked);
                        }}
                        placeholder="Initial View"
                        disabled={readOnlyMode}
                    />
                    <Field
                        className={classes.input}
                        name="zone_image_preview"
                        type="file"
                    >
                        {({ field: { value } }) => (
                            <div className={panelClasses.dropzoneContainer}>
                                <Dropzone
                                    accept={['image/jpeg', 'image/png']}
                                    maxFiles={1}
                                    maxSize={50000000}
                                    multiple={false}
                                    onDropAccepted={async (files) => {
                                        if (!readOnlyMode) {
                                            if (files && files.length > 0) {
                                                if (files[0]) {
                                                    onDropPreviewImage({
                                                        file: files[0],
                                                        setFieldValue:
                                                            setFieldValue,
                                                        setFieldError:
                                                            setFieldError,
                                                        setFieldTouched:
                                                            setFieldTouched,
                                                    });
                                                }
                                            }
                                        }
                                    }}
                                    noClick={readOnlyMode}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div
                                            {...getRootProps()}
                                            className={
                                                panelClasses.dropzoneArea
                                            }
                                            style={{
                                                backgroundImage: `url(${values.image_url2})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'contain',
                                                backgroundPosition:
                                                    'center center',
                                            }}
                                        >
                                            <input {...getInputProps()} />
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    width: 'auto',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <div
                                                    className={
                                                        panelClasses.dropzoneText
                                                    }
                                                >
                                                    {zone.equirect_image_preview_url ||
                                                    zone.flat_image_preview_url
                                                        ? 'Drop Replacement Preview Image Here'
                                                        : 'Drop Preview Image Here'}
                                                </div>
                                                <div
                                                    className={
                                                        panelClasses.dropzoneText
                                                    }
                                                >
                                                    Auto generated from pano if
                                                    not specified
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                                <ErrorMessage
                                    component="div"
                                    className={classes.inputErr}
                                    name="preview_image"
                                />
                                <div className={panelClasses.previewContainer}>
                                    {value && value.name && (
                                        <Chip
                                            className={
                                                panelClasses.previewUploadFileName
                                            }
                                            color="secondary"
                                            size="small"
                                            deleteIcon={
                                                <MdClose className="react-icon" />
                                            }
                                            onDelete={() => {
                                                setFieldValue(
                                                    'zone_image_preview',
                                                    {}
                                                );
                                                setFieldValue('image_url2', '');
                                            }}
                                            label={value?.name}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </Field>
                    <Field
                        className={classes.input}
                        name="equirect_image"
                        type="file"
                    >
                        {({ field: { value } }) => (
                            <div className={panelClasses.dropzoneContainer}>
                                <Dropzone
                                    accept={['image/jpeg', 'image/png']}
                                    maxFiles={1}
                                    maxSize={50000000}
                                    multiple={false}
                                    onDropAccepted={async (files) => {
                                        if (!readOnlyMode) {
                                            if (files && files.length > 0) {
                                                if (files[0]) {
                                                    onEquirectDrop({
                                                        file: files[0],
                                                        setFieldValue:
                                                            setFieldValue,
                                                        setFieldError:
                                                            setFieldError,
                                                        setFieldTouched:
                                                            setFieldTouched,
                                                        values,
                                                    });
                                                }
                                            }
                                        }
                                    }}
                                    noClick={readOnlyMode}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div
                                            {...getRootProps()}
                                            className={
                                                panelClasses.dropzoneArea
                                            }
                                            style={{
                                                backgroundImage: `url(${values.image_url})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'contain',
                                                backgroundPosition:
                                                    'center center',
                                            }}
                                        >
                                            <input {...getInputProps()} />
                                            <div
                                                className={
                                                    panelClasses.dropzoneText
                                                }
                                            >
                                                {zone.equirect_image_url
                                                    ? 'Drop Replacement Pano Image Here'
                                                    : 'Drop Pano Image Here'}
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                                <div className={panelClasses.previewContainer}>
                                    {value && value.name && (
                                        <Chip
                                            className={
                                                panelClasses.previewUploadFileName
                                            }
                                            color="secondary"
                                            size="small"
                                            deleteIcon={
                                                <MdClose className="react-icon" />
                                            }
                                            onDelete={() => {
                                                setFieldValue(
                                                    'equirect_image',
                                                    {}
                                                );
                                                setFieldValue('image_url', '');
                                            }}
                                            label={value?.name}
                                        />
                                    )}
                                </div>
                                {errors?.equirect_image && (
                                    <FieldValidationError
                                        validationError={errors.equirect_image}
                                    />
                                )}
                            </div>
                        )}
                    </Field>
                    <Field
                        className={classes.input}
                        name="flat_image"
                        type="file"
                        disabled={readOnlyMode}
                    >
                        {({ field: { value }, form: { setFieldValue } }) => (
                            <div className={panelClasses.dropzoneContainer}>
                                <Dropzone
                                    accept={['image/jpeg', 'image/png']}
                                    maxFiles={1}
                                    maxSize={50000000}
                                    multiple={false}
                                    onDropAccepted={async (files) => {
                                        if (!readOnlyMode) {
                                            if (files && files.length > 0) {
                                                onFlatImageDrop({
                                                    file: files[0],
                                                    setFieldValue:
                                                        setFieldValue,
                                                    values,
                                                });
                                            }
                                        }
                                    }}
                                    noClick={readOnlyMode}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div
                                            {...getRootProps()}
                                            className={
                                                panelClasses.dropzoneArea
                                            }
                                            style={{
                                                backgroundImage: `url(${values.image_url1})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'contain',
                                                backgroundPosition:
                                                    'center center',
                                            }}
                                        >
                                            <input {...getInputProps()} />
                                            <div
                                                className={
                                                    panelClasses.dropzoneText
                                                }
                                            >
                                                {zone.flat_image_url
                                                    ? 'Drop Replacement Regular Image Here'
                                                    : 'Drop Regular Image Here'}
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                                <div className={panelClasses.previewContainer}>
                                    {value && value.name && (
                                        <Chip
                                            className={
                                                panelClasses.previewUploadFileName
                                            }
                                            color="secondary"
                                            size="small"
                                            deleteIcon={
                                                <MdClose className="react-icon" />
                                            }
                                            onDelete={() => {
                                                if (!readOnlyMode) {
                                                    setFieldValue(
                                                        'flat_image',
                                                        {}
                                                    );
                                                    setFieldValue(
                                                        'image_url1',
                                                        ''
                                                    );
                                                }
                                            }}
                                            label={value?.name}
                                        />
                                    )}
                                </div>
                                {errors?.flat_image && (
                                    <FieldValidationError
                                        validationError={errors.flat_image}
                                    />
                                )}
                            </div>
                        )}
                    </Field>
                    {errors?.zone_image && (
                        <FieldValidationError
                            validationError={errors.zone_image}
                        />
                    )}
                    {readOnlyMode !== true && (
                        <>
                            <SetDirtyForm />
                            <button
                                className={classes.submit}
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {formAction.buttonLabel}
                            </button>
                        </>
                    )}
                </Form>
            )}
        </Formik>
    );
};

export { ZoneForm };
