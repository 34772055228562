export const GROUP_FIELD_TYPE = { id: 'group', display_name: 'Group' };
export const TEXT_FIELD_TYPE = { id: 'text', display_name: 'Text' };
export const MULTILINE_FIELD_TYPE = {
    id: 'multiline',
    display_name: 'Multiline',
};
export const CHECKBOX_FIELD_TYPE = { id: 'checkbox', display_name: 'Checkbox' };
export const SELECT_FIELD_TYPE = { id: 'select', display_name: 'Select' };
export const DATETIME_FIELD_TYPE = { id: 'datetime', display_name: 'Date' };
export const RADIOGROUP_FIELD_TYPE = {
    id: 'radiogroup',
    display_name: 'Radio Group',
};
export const LABEL_FIELD_TYPE = { id: 'label', display_name: 'Label' };
export const LINK_FIELD_TYPE = { id: 'link', display_name: 'Link' };
export const INITIAL_FIELD_TYPE = { id: 'initial', display_name: 'Initial' };
export const TABLE_FIELD_TYPE = { id: 'table', display_name: 'Table' };
export const HIDDEN_FIELD_TYPE = {
    id: 'hidden',
    display_name: 'Hidden Field',
};
export const MEDIA_FIELD_TYPE = { id: 'media', display_name: 'Media' };
export const MEDIACOMPARE_FIELD_TYPE = {
    id: 'mediacompare',
    display_name: 'Media Compare',
};
export const INSPECTIONPOINT_FIELD_TYPE = {
    id: 'inspectionpoint',
    display_name: 'Inspection Point',
};

export const LATEST_RELEASE = 'Latest Release';
export const LATEST_DRAFT = 'Latest Draft';

export const IS_LABEL_FIELD = [
    GROUP_FIELD_TYPE.id,
    TEXT_FIELD_TYPE.id,
    MULTILINE_FIELD_TYPE.id,
    CHECKBOX_FIELD_TYPE.id,
    SELECT_FIELD_TYPE.id,
    DATETIME_FIELD_TYPE.id,
    RADIOGROUP_FIELD_TYPE.id,
    LINK_FIELD_TYPE.id,
    INITIAL_FIELD_TYPE.id,
    TABLE_FIELD_TYPE.id,
    HIDDEN_FIELD_TYPE.id,
    MEDIA_FIELD_TYPE.id,
    MEDIACOMPARE_FIELD_TYPE.id,
    INSPECTIONPOINT_FIELD_TYPE.id,
    LABEL_FIELD_TYPE.id,
];

export const IS_TYPE_FIELD = [
    TEXT_FIELD_TYPE.id,
    MULTILINE_FIELD_TYPE.id,
    CHECKBOX_FIELD_TYPE.id,
    SELECT_FIELD_TYPE.id,
    DATETIME_FIELD_TYPE.id,
    RADIOGROUP_FIELD_TYPE.id,
    INITIAL_FIELD_TYPE.id,
    MEDIA_FIELD_TYPE.id,
    INSPECTIONPOINT_FIELD_TYPE.id,
    HIDDEN_FIELD_TYPE.id,
];

export const IS_VARIABLE_FIELD = [
    TEXT_FIELD_TYPE.id,
    MULTILINE_FIELD_TYPE.id,
    CHECKBOX_FIELD_TYPE.id,
    SELECT_FIELD_TYPE.id,
    DATETIME_FIELD_TYPE.id,
    RADIOGROUP_FIELD_TYPE.id,
    INITIAL_FIELD_TYPE.id,
    HIDDEN_FIELD_TYPE.id,
    MEDIA_FIELD_TYPE.id,
    INSPECTIONPOINT_FIELD_TYPE.id,
];

export const IS_WIDTH_FIELD = [
    GROUP_FIELD_TYPE.id,
    TABLE_FIELD_TYPE.id,
    HIDDEN_FIELD_TYPE.id,
];

export const IS_REQUIRED_FIELD = [
    TEXT_FIELD_TYPE.id,
    MULTILINE_FIELD_TYPE.id,
    CHECKBOX_FIELD_TYPE.id,
    SELECT_FIELD_TYPE.id,
    DATETIME_FIELD_TYPE.id,
    RADIOGROUP_FIELD_TYPE.id,
    INITIAL_FIELD_TYPE.id,
    MEDIA_FIELD_TYPE.id,
    INSPECTIONPOINT_FIELD_TYPE.id,
];

export const IS_HIDE_LABEL_FIELD = [
    GROUP_FIELD_TYPE.id,
    TEXT_FIELD_TYPE.id,
    MULTILINE_FIELD_TYPE.id,
    CHECKBOX_FIELD_TYPE.id,
    SELECT_FIELD_TYPE.id,
    DATETIME_FIELD_TYPE.id,
    RADIOGROUP_FIELD_TYPE.id,
    INITIAL_FIELD_TYPE.id,
    INSPECTIONPOINT_FIELD_TYPE.id,
];

export const IS_IMAGE_ATTACHMENT_FIELD = [
    TEXT_FIELD_TYPE.id,
    MULTILINE_FIELD_TYPE.id,
    CHECKBOX_FIELD_TYPE.id,
    SELECT_FIELD_TYPE.id,
    DATETIME_FIELD_TYPE.id,
    RADIOGROUP_FIELD_TYPE.id,
    INITIAL_FIELD_TYPE.id,
    INSPECTIONPOINT_FIELD_TYPE.id,
];

export const IS_COLSPAN_FIELD = [
    TEXT_FIELD_TYPE.id,
    MULTILINE_FIELD_TYPE.id,
    CHECKBOX_FIELD_TYPE.id,
    SELECT_FIELD_TYPE.id,
    DATETIME_FIELD_TYPE.id,
    RADIOGROUP_FIELD_TYPE.id,
    LABEL_FIELD_TYPE.id,
    LINK_FIELD_TYPE.id,
    INITIAL_FIELD_TYPE.id,
    HIDDEN_FIELD_TYPE.id,
    INSPECTIONPOINT_FIELD_TYPE.id,
];

export const FIELD_TYPES = [
    GROUP_FIELD_TYPE,
    TEXT_FIELD_TYPE,
    MULTILINE_FIELD_TYPE,
    CHECKBOX_FIELD_TYPE,
    SELECT_FIELD_TYPE,
    DATETIME_FIELD_TYPE,
    RADIOGROUP_FIELD_TYPE,
    LABEL_FIELD_TYPE,
    LINK_FIELD_TYPE,
    INITIAL_FIELD_TYPE,
    TABLE_FIELD_TYPE,
    HIDDEN_FIELD_TYPE,
    MEDIA_FIELD_TYPE,
    MEDIACOMPARE_FIELD_TYPE,
    INSPECTIONPOINT_FIELD_TYPE,
];
