import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { SnackbarProvider } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, Navigate } from 'react-router-dom';

import { Role } from '_helpers';
import { useStyles } from './styles';
import { config } from 'gemini-config';
import { Nav, PrivateRoute } from 'components';
import { fetchTags } from '_features/tags/tagsSlice';
import { Login } from 'GeminiViewerComponent/scenes/Account/components/Login';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { ResetPassword } from 'GeminiViewerComponent/scenes/Account/components/ResetPassword';
import {
    ContentManager,
    Help,
    Admin,
    Asset,
    Assets,
    VideoImporter,
    PanoImporter,
    Jobs,
    Tools,
    Parts,
    NewsFlash,
} from 'scenes';

if (config.gaTrackingId) {
    ReactGA.initialize(config.gaTrackingId);
}

const AppNavigation = ({ activeUser }) => {
    const theme = useSelector(selectActiveTheme);
    const classes = useStyles(theme);
    const dispatch = useDispatch();

    const handleLoginCompleted = async () => {
        await dispatch(fetchTags({ sort: 'name' }));
    };

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: window.location.pathname + window.location.search,
        });
    }, []);

    return (
        <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            classes={{
                variantSuccess: classes.success,
                variantError: classes.error,
                variantWarning: classes.warning,
                variantInfo: classes.info,
            }}
        >
            <div className={classes.root}>
                <Nav />
                <Routes>
                    <Route
                        path="/"
                        element={
                            <Navigate to="/assets" />
                            // activeUser?.role == 'IPS Administrator' ? (
                            //     <PrivateRoute>
                            //         <PrivateRoute>
                            //             <Home />
                            //         </PrivateRoute>
                            //     </PrivateRoute>
                            // ) : (
                            //     <Navigate to="/assets" />
                            // )
                        }
                    />
                    <Route
                        path="/admin"
                        element={
                            !Role?.DisableAdminRoles?.includes(
                                activeUser?.role
                            ) ? (
                                <PrivateRoute>
                                    <Admin
                                        roles={[Role.Admin, Role.IPSAdmin]}
                                    />
                                </PrivateRoute>
                            ) : (
                                <Navigate to="/" />
                            )
                        }
                    />
                    <Route
                        path="/jobs"
                        element={
                            !Role?.DisableAdminRoles?.includes(
                                activeUser?.role
                            ) ? (
                                <PrivateRoute>
                                    <Jobs />
                                </PrivateRoute>
                            ) : (
                                <Navigate to="/" />
                            )
                        }
                    />
                    <Route
                        path="/tools"
                        element={
                            !Role?.DisableAdminRoles?.includes(
                                activeUser?.role
                            ) ? (
                                <PrivateRoute>
                                    <Tools />
                                </PrivateRoute>
                            ) : (
                                <Navigate to="/" />
                            )
                        }
                    />
                    <Route
                        path="/parts"
                        element={
                            !Role?.DisableAdminRoles?.includes(
                                activeUser?.role
                            ) ? (
                                <PrivateRoute>
                                    <Parts />
                                </PrivateRoute>
                            ) : (
                                <Navigate to="/" />
                            )
                        }
                    />
                    <Route
                        path="/newsflash"
                        element={
                            <PrivateRoute>
                                <NewsFlash />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/home"
                        element={
                            <Navigate to="/assets" />
                            // <PrivateRoute>
                            //     <Home />
                            // </PrivateRoute>
                        }
                    />
                    <Route
                        path="/help"
                        element={
                            <PrivateRoute>
                                <Help />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/assets"
                        element={
                            <PrivateRoute>
                                <Assets assetType="assets" />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/procedures"
                        element={
                            <PrivateRoute>
                                <Assets assetType="procedures" />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/asset/:id"
                        element={
                            <PrivateRoute>
                                <Asset />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/procedure/:id"
                        element={
                            <PrivateRoute>
                                <Asset />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/asset/:id/videoimporter"
                        element={
                            <PrivateRoute>
                                <VideoImporter />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/asset/:id/panoimporter"
                        element={
                            <PrivateRoute>
                                <PanoImporter />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/content"
                        element={
                            <PrivateRoute>
                                <ContentManager />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/account/login"
                        element={
                            <Login
                                config={config}
                                loginCompleted={handleLoginCompleted}
                            />
                        }
                    />
                    <Route
                        path="/account/login/forgot-password"
                        element={
                            <Login
                                config={config}
                                loginCompleted={handleLoginCompleted}
                            />
                        }
                    />
                    <Route
                        path="/account/reset-password/:token"
                        element={<ResetPassword config={config} />}
                    />
                    {/* 404/invalid route */}
                    <Route path="*" element={<Navigate to="/assets" />} />{' '}
                </Routes>
            </div>
        </SnackbarProvider>
    );
};

export { AppNavigation };
