// eslint-disable-next-line
import React, { createElement, useEffect } from 'react';
import { dtContentStyles } from './styles';

const DtContent = ({ nodeContent }) => {
    const classes = dtContentStyles();

    if (nodeContent === '') return null;

    return (
        <div
            className={classes.dtContentContainer}
            style={{ flexDirection: 'column' }}
        >
            <div
                className={classes.dtContent}
                dangerouslySetInnerHTML={{ __html: nodeContent }}
            ></div>
        </div>
    );
};

export { DtContent };
