import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';

import { makeFormStyles } from 'forms/styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { ReferenceImagesDialog } from 'components/ProcedureDesigner/components/ContentNodeEditor/ReferenceImagesDialog';
import { InspectionActionField } from './components/InspectionActionField';
import {
    IS_COLSPAN_FIELD,
    IS_HIDE_LABEL_FIELD,
    IS_IMAGE_ATTACHMENT_FIELD,
    IS_LABEL_FIELD,
    IS_REQUIRED_FIELD,
    IS_VARIABLE_FIELD,
    IS_WIDTH_FIELD,
    DATETIME_FIELD_TYPE,
    FIELD_TYPES,
    HIDDEN_FIELD_TYPE,
    INSPECTIONPOINT_FIELD_TYPE,
    IS_TYPE_FIELD,
    LABEL_FIELD_TYPE,
    LINK_FIELD_TYPE,
    MEDIACOMPARE_FIELD_TYPE,
    MULTILINE_FIELD_TYPE,
    RADIOGROUP_FIELD_TYPE,
    SELECT_FIELD_TYPE,
    TABLE_FIELD_TYPE,
    TEXT_FIELD_TYPE,
    CHECKBOX_FIELD_TYPE,
} from './_helpers/constant';
import { FieldType } from './components/FieldType';
import RadioField from './components/RadioField';
import EditorLabelField from './components/EditorLabelField';
import LinkField from './components/LinkField';
import TableField from './components/TableField';
import DateTimeField from './components/DateTimeField';
import ReferenceImagesField from './components/ReferenceImagesField';
import ComparisonField from './components/ComparisonField';
import HiddenField from './components/HiddenField';
import SelectFormField from './components/SelectFormField';
import { makeFieldFormStyles } from './styles';
import LabelField from './components/LabelField';
import TextFormField from './components/TextFormField';
import CommentField from './components/CommentField';
import MediaReferenceField from './components/MediaReferenceField';
import VariableField from './components/VariableField';
import WidthField from './components/WidthField';
import CellAlignmentField from './components/CellAlignmentField';
import MultiLineField from './components/MultiLineField';
import RequiredField from './components/RequiredField';
import HideLabel from './components/HideLabel';
import PasswordField from './components/PasswordField';
import ImageAttachmentField from './components/ImageAttachmentField';
import AutoFillField from './components/AutoFill';
import ReadonlyField from './components/ReadonlyField';
import ColspanField from './components/ColspanField';
import { getInspectionActionList } from 'GeminiViewerComponent/_features/inspection/inspectionSlice';
import {
    selectFieldValidation,
    variableValidation,
} from './_helpers/fieldValidation';

const FieldForm = ({
    node,
    procedureNodes,
    onSubmit,
    onCancel,
    isValidVariableName,
    checkUniqueVariableName,
    unsavedVariables,
    getComparisonField = () => {},
}) => {
    let fieldTypes = FIELD_TYPES;
    const theme = useSelector(selectActiveTheme);
    const nodeFormStyles = makeFieldFormStyles(theme);
    const commonFormStyles = makeFormStyles(theme);
    const [openReferenceImageDialog, setOpenReferenceImageDialog] =
        useState(false);
    const getActionList = useSelector(getInspectionActionList);
    const [formValues, setFormValues] = useState(node);

    if (node?.has_group === true) {
        fieldTypes = fieldTypes.filter(
            (type) => type.id !== 'group' && type.id !== 'table'
        );
        // delete node.has_group;
    }

    const validateFields = useCallback(
        (values) => {
            let errors = {};
            if (!values?.type) {
                errors = { ...errors, type: 'Required' };
            }
            if (values?.field_width < 0 || values?.field_width > 100) {
                errors = {
                    ...errors,
                    field_width: 'Width must be between 0 to 100.',
                };
            }
            if (IS_TYPE_FIELD.includes(values?.type)) {
                errors = {
                    ...errors,
                    ...variableValidation(
                        errors,
                        values,
                        isValidVariableName,
                        checkUniqueVariableName,
                        unsavedVariables
                    ),
                };
            }
            if ([DATETIME_FIELD_TYPE.id].includes(values?.type)) {
                if (!values?.date_time_type) {
                    errors = {
                        ...errors,
                        date_time_type: 'Required',
                    };
                }
            }
            if ([CHECKBOX_FIELD_TYPE.id].includes(values?.type)) {
                if (values?.label?.length > 20) {
                    errors = {
                        ...errors,
                        label: 'Must be 20 characters or less.',
                    };
                }
            }
            if (values?.type === SELECT_FIELD_TYPE.id) {
                errors = {
                    ...errors,
                    ...selectFieldValidation(errors, values),
                };
            }
            return errors;
        },
        [isValidVariableName, checkUniqueVariableName, unsavedVariables]
    );

    useEffect(() => {
        setFormValues(node);
    }, [node]);

    return (
        <div className={nodeFormStyles.container}>
            <div className={nodeFormStyles.form}>
                <Formik
                    initialValues={formValues}
                    validate={validateFields}
                    onSubmit={(values, { setSubmitting }) => {
                        if (values?.action_list) {
                            delete values.action_list;
                        }
                        if (values?.action_name) {
                            delete values?.action_name;
                        }
                        if (values?.has_group) {
                            delete values?.has_group;
                        }
                        setTimeout(() => {
                            setSubmitting(false);
                            onSubmit(values);
                        }, 400);
                    }}
                >
                    {({
                        values,
                        setFieldValue,
                        isSubmitting,
                        resetForm,
                        errors,
                        setFieldError,
                    }) => (
                        <Form className={nodeFormStyles.formBody}>
                            {/* Field Type */}
                            <FieldType
                                resetForm={resetForm}
                                setFieldValue={setFieldValue}
                                node={node}
                                values={values}
                                fieldTypes={fieldTypes}
                                validationError={errors?.types}
                            />

                            {/* Label expect label field */}
                            {IS_LABEL_FIELD.includes(values.type) && (
                                <LabelField
                                    text={`${
                                        values.type === LABEL_FIELD_TYPE.id
                                            ? 'Title'
                                            : 'Label'
                                    }`}
                                    validationError={errors?.label}
                                />
                            )}

                            {/* Text -> Display label beside field */}
                            {values.type === TEXT_FIELD_TYPE.id && (
                                <TextFormField
                                    value={values.display_label_beside_field}
                                    setFieldValue={setFieldValue}
                                />
                            )}

                            {/* radiogroup */}
                            {values.type === RADIOGROUP_FIELD_TYPE.id && (
                                <RadioField
                                    values={values}
                                    setFieldValue={setFieldValue}
                                />
                            )}

                            {/* select */}
                            {values.type === SELECT_FIELD_TYPE.id && (
                                <SelectFormField
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    validationError={errors}
                                />
                            )}

                            {/* label with editor */}
                            {values.type === LABEL_FIELD_TYPE.id && (
                                <EditorLabelField />
                            )}

                            {/* Link */}
                            {values.type === LINK_FIELD_TYPE.id && (
                                <LinkField
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    procedureNodes={procedureNodes}
                                />
                            )}

                            {[INSPECTIONPOINT_FIELD_TYPE.id].includes(
                                values.type
                            ) && (
                                <>
                                    {/* Actions list */}
                                    <InspectionActionField
                                        node={node}
                                        setFieldValue={setFieldValue}
                                        values={values}
                                        inspectionActionList={
                                            getActionList || []
                                        }
                                        setFieldError={setFieldError}
                                    />

                                    {/* Comment */}
                                    <CommentField
                                        value={values.comment}
                                        setFieldValue={setFieldValue}
                                    />

                                    {/* Media References */}
                                    <MediaReferenceField
                                        value={values.media_reference}
                                        setFieldValue={setFieldValue}
                                    />
                                </>
                            )}

                            {/* Variable */}
                            {IS_VARIABLE_FIELD.includes(values.type) && (
                                <VariableField
                                    validationError={errors?.variable}
                                />
                            )}

                            {/* Width */}
                            {values?.type &&
                                values.type !== '' &&
                                !IS_WIDTH_FIELD.includes(values.type) && (
                                    <WidthField
                                        validationError={errors?.field_width}
                                    />
                                )}

                            {/* checkbox alignment */}
                            {values.type === CHECKBOX_FIELD_TYPE.id && (
                                <CellAlignmentField
                                    values={values}
                                    setFieldValue={setFieldValue}
                                />
                            )}

                            {/* table */}
                            {values.type === TABLE_FIELD_TYPE.id && (
                                <TableField
                                    values={values}
                                    setFieldValue={setFieldValue}
                                />
                            )}

                            {/* Multiline/Textarea -> No of lines */}
                            {values.type === MULTILINE_FIELD_TYPE.id && (
                                <MultiLineField />
                            )}

                            {/* DateTime -> Date Type */}
                            {values.type === DATETIME_FIELD_TYPE.id && (
                                <DateTimeField
                                    validationError={errors?.date_time_type}
                                />
                            )}

                            {/* Required */}
                            {IS_REQUIRED_FIELD.includes(values.type) && (
                                <RequiredField
                                    value={values.required}
                                    setFieldValue={setFieldValue}
                                />
                            )}

                            {/* Hide Label except table & link field */}
                            {IS_HIDE_LABEL_FIELD.includes(values.type) && (
                                <HideLabel
                                    value={values.hide_label}
                                    setFieldValue={setFieldValue}
                                />
                            )}

                            {/* Text -> Password */}
                            {values.type === TEXT_FIELD_TYPE.id && (
                                <PasswordField
                                    value={values.password}
                                    setFieldValue={setFieldValue}
                                />
                            )}

                            {/* Image attachments */}
                            {IS_IMAGE_ATTACHMENT_FIELD.includes(
                                values.type
                            ) && (
                                <ImageAttachmentField
                                    value={values.image_attachments_allow}
                                    setFieldValue={setFieldValue}
                                />
                            )}

                            {/* Date -> autofill */}
                            {values.type === DATETIME_FIELD_TYPE.id && (
                                <>
                                    <AutoFillField
                                        value={values.autofill}
                                        setFieldValue={setFieldValue}
                                    />
                                    <ReadonlyField
                                        value={values.readonly}
                                        setFieldValue={setFieldValue}
                                    />
                                </>
                            )}

                            {/* Hidden fields */}
                            {values.type === HIDDEN_FIELD_TYPE.id && (
                                <HiddenField
                                    values={values}
                                    setFieldValue={setFieldValue}
                                />
                            )}

                            {/* Colspan except table & group */}
                            {IS_COLSPAN_FIELD.includes(values.type) && (
                                <ColspanField />
                            )}

                            {/* Comparison Field */}
                            {[MEDIACOMPARE_FIELD_TYPE.id].includes(
                                values?.type
                            ) && (
                                <ComparisonField
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    getComparisonField={getComparisonField}
                                />
                            )}

                            {/* Reference Images */}
                            {([MEDIACOMPARE_FIELD_TYPE.id].includes(
                                values?.type
                            ) ||
                                !!values.media_reference) && (
                                <>
                                    {openReferenceImageDialog && (
                                        <ReferenceImagesDialog
                                            selectedImages={
                                                Array.isArray(
                                                    values?.references
                                                )
                                                    ? values?.references
                                                    : []
                                            }
                                            openReferenceImageDialog={
                                                openReferenceImageDialog
                                            }
                                            setOpenReferenceImageDialog={
                                                setOpenReferenceImageDialog
                                            }
                                            setFieldValue={setFieldValue}
                                        />
                                    )}

                                    <ReferenceImagesField
                                        setOpenReferenceImageDialog={
                                            setOpenReferenceImageDialog
                                        }
                                        values={values}
                                    />
                                </>
                            )}

                            <Box className={nodeFormStyles.submitButtonRow}>
                                <button
                                    className={commonFormStyles.cancel}
                                    type="button"
                                    disabled={isSubmitting}
                                    onClick={onCancel}
                                >
                                    Cancel
                                </button>
                                <button
                                    className={commonFormStyles.submit}
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Submit
                                </button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default FieldForm;
