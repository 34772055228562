import React, { useEffect, useState } from 'react';
import { PageHeader } from 'components';
import { useStyles } from '../styles';
import { FiArrowLeft, FiGrid } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getEditAsset, selectEditAsset } from '_features/assets/editAssetSlice';
import { PanoEditor } from './components/PanoEditor';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { useSnackbar } from 'notistack';
import {
    activateLoading,
    deactivateLoading,
    setProgress,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { addNewZone } from '_features/common/editZoneSlice';
import { PanoImportDropzoneDialog } from './components/PanoImportDropzoneDialog';

function PanoImporter() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const asset = useSelector(selectEditAsset);
    const { enqueueSnackbar } = useSnackbar();
    const [openDialog, setOpenDialog] = useState(true);
    const [hasImages, setHasImages] = useState(false);
    const [sequenceImages, setSequenceImages] = useState([]);
    const [nextUploadImageIndex, setNextUploadImageIndex] = useState(0);

    let params = useParams();
    let id = params.id;

    useEffect(() => {
        async function fetchAsset() {
            await dispatch(getEditAsset(id));
        }
        fetchAsset();
    }, [id, dispatch]);

    const handleSaveZones = async () => {
        dispatch(activateLoading({ showProgress: true }));
        let emptyName = false;
        for (const panoImage of sequenceImages) {
            if (Boolean(panoImage?.display_name) === false) {
                emptyName = true;
                enqueueSnackbar(`Zone name can not be empty.`, {
                    action: (key) => <SnackbarDismiss key={key} />,
                    variant: 'error',
                });
            }
        }
        if (emptyName) {
            dispatch(deactivateLoading());
            return false;
        }
        let zoneData = [];
        let resultAction;
        let zoneSeq = -1;
        let isNavigate = true;
        for (const panoImage of sequenceImages) {
            if (panoImage.zone_create_status === 'Completed') {
                zoneData[++zoneSeq] = panoImage;
                continue;
            }
            let zone = {
                description: '',
                pano_id: '',
                audiences: [],
                flat_image: { name: '' },
                equirect_image_preview: { name: '' },
                flat_image_preview: { name: '' },
                tag_ids: [],
                asset_id: asset.asset_id,
                display_name: panoImage.display_name,
                equirect_image: panoImage.fileObj,
                equirect_image_width: panoImage.equirect_image_width,
                equirect_image_height: panoImage.equirect_image_height,
            };
            dispatch(
                setProgress({
                    progress: 0,
                    label: 'Step 1 of 2: Uploading',
                    noAnimation: true,
                })
            );
            resultAction = await dispatch(
                addNewZone({
                    ...zone,
                    dispatch,
                })
            );
            if (resultAction.error) {
                isNavigate = false;
                zoneData[++zoneSeq] = {
                    ...panoImage,
                    zone_create_status: 'Failed',
                };
                enqueueSnackbar(
                    `${panoImage.display_name} zone creation failed.`,
                    {
                        action: (key) => <SnackbarDismiss key={key} />,
                        variant: 'error',
                    }
                );
            } else {
                zoneData[++zoneSeq] = {
                    ...panoImage,
                    zone_create_status: 'Completed',
                };
                enqueueSnackbar(`${panoImage.display_name} zone created.`, {
                    action: (key) => <SnackbarDismiss key={key} />,
                    variant: 'success',
                });
            }
        }
        dispatch(deactivateLoading());
        if (isNavigate === true) {
            navigate(`/asset/${asset.asset_id}`);
        } else {
            setSequenceImages(zoneData);
        }
    };

    const createImageBlob = (file, currentIndex) => {
        return new Promise((resolve, reject) => {
            let img = new Image();
            img.src = window.URL.createObjectURL(file);
            let removeExt = file?.name?.substring(
                0,
                file?.name?.lastIndexOf('.')
            );
            img.onload = function () {
                if (img.naturalWidth === img.naturalHeight * 2) {
                    const reader = new FileReader();

                    reader.onabort = () => {
                        resolve(false);
                        console.log('file reading was aborted');
                    };
                    reader.onerror = () => {
                        resolve(false);
                        console.log('file reading has failed');
                    };

                    reader.onload = async (e) => {
                        resolve({
                            id: currentIndex,
                            display_name: removeExt,
                            fileObj: file,
                            equirect_image_width: img.naturalWidth,
                            equirect_image_height: img.naturalHeight,
                            equirect_image_url: e.target.result,
                            equirect_image_preview_url: e.target.result,
                            zone_create_status: 'Pending',
                        });
                    };
                    reader.readAsDataURL(file);
                } else {
                    enqueueSnackbar(`${file?.name} Image must be 2:1 ratio`, {
                        action: (key) => <SnackbarDismiss key={key} />,
                        variant: 'error',
                    });
                    resolve(false);
                }
                window.URL.revokeObjectURL(img.src);
            };
        });
    };

    const handleFileChange = async (files) => {
        dispatch(activateLoading({ showProgress: true }));
        const isImageType = files.some((file) => file?.type.includes('image'));
        if (files && files.length > 0) {
            if (!isImageType) {
                dispatch(deactivateLoading());
                return false;
            }
            let hasError = false;
            let filteredFiles = [];
            let currentIndex = nextUploadImageIndex;
            for (const file of files) {
                if (!hasError) {
                    const getBlob = await createImageBlob(
                        file,
                        currentIndex + 1
                    );
                    if (getBlob) {
                        filteredFiles.push(getBlob);
                        ++currentIndex;
                    } else {
                        hasError = true;
                        filteredFiles = [];
                        currentIndex = nextUploadImageIndex;
                    }
                }
            }
            if (!hasError && filteredFiles?.length > 0) {
                if (!hasImages) {
                    setHasImages(true);
                }
                setSequenceImages([...sequenceImages, ...filteredFiles]);
                setNextUploadImageIndex(currentIndex);
                setOpenDialog(false);
            }
        }
        dispatch(deactivateLoading());
    };

    return (
        <div
            style={{ height: 'calc(100vh)', width: 'calc(100% - 165px)' }}
            className={classes.content}
        >
            <PageHeader header={'360 Pano Import'} icon={<FiGrid />} />
            <div
                className={classes.assetHeader}
                style={{ marginBottom: '10px' }}
            >
                <Link to={`/asset/${id}`} className={classes.backArrow}>
                    <FiArrowLeft />
                </Link>
                <span>{asset.display_name}</span>
            </div>
            <div style={{ overflow: 'auto' }}>
                <PanoEditor
                    sequenceImages={sequenceImages}
                    setSequenceImages={setSequenceImages}
                    handleFileChange={handleFileChange}
                    handleSaveZones={handleSaveZones}
                />
            </div>
            {openDialog && (
                <PanoImportDropzoneDialog
                    setOpenDialog={setOpenDialog}
                    handleFileChange={handleFileChange}
                />
            )}
        </div>
    );
}

export { PanoImporter };
