import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { AutoSizer } from 'react-virtualized';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Toolbar, Tooltip, Typography } from '@mui/material';

import { Action } from '_helpers';
import { tableStyles } from 'components/_Tables/TableCells/styles';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { useToolbarStyles } from 'GeminiViewerComponent/components/styles';
import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';
import {
    closeAllPanel,
    openPanel,
    setEditObjectId,
} from '_features/common/formSlice';
import {
    fetchAllNewsFlash,
    getAllNewsFlash,
    getNewsFlashLoadedRowsMap,
    getNewsFlashStatus,
} from 'GeminiViewerComponent/_features/newsFlash/newsFlashSlice';
import {
    labelHeader,
    labelCell,
    actionCell,
    checkCell,
    checkHeaderCell,
    VirtualTable,
} from 'components';

const EnhancedTableToolbar = (props) => {
    //#region Constants
    const { numSelected, selectedItems, totalItems, setSelected } = props;
    //#endregion Constants

    //#region Hooks
    const classes = useToolbarStyles();
    //#endregion Hooks

    //#region State
    //#endregion State

    //#region Selectors
    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Effects
    //#endregion Effects

    //#region Methods
    //#endregion Methods

    //#region Render time calcs
    //#endregion Render time calcs

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography
                    className={classes.title}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} of {totalItems} selected
                </Typography>
            ) : (
                <Typography
                    className={classes.title}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                ></Typography>
            )}

            {numSelected > 0 && (
                <Tooltip title="Publish">
                    <PopupAction
                        action={Action.DeleteArray}
                        object={{
                            newsFlashIds: selectedItems,
                            setNewsFlashIds: setSelected,
                        }}
                        level={'news_flash'}
                        showLabel={false}
                    />
                </Tooltip>
            )}
        </Toolbar>
    );
};

function NewsFlashTable({
    newsFlashes,
    pageInfo,
    searchString,
    sortDirection,
    sortBy,
    handleSort,
    newsFlashType,
    newsFlashPayload,
    readOnly,
}) {
    //#endregion Constants
    const dispatch = useDispatch();
    const headerHeight = 40;
    const rowHeight = 60;
    const tableClasses = tableStyles();
    //#region Constants

    //#region Hooks
    //#endregion Hooks

    //#region State
    const [selected, setSelected] = useState([]);
    const [selectedAll, setSelectedAll] = useState(false);
    //#endregion State

    //#region Selectors
    const allRows = useSelector(getAllNewsFlash);
    const newsFlashStatus = useSelector(getNewsFlashStatus);
    const loadedRowsMap = useSelector(getNewsFlashLoadedRowsMap);
    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Effects
    useEffect(() => {
        setSelected([]);
        setSelectedAll(false);
    }, [newsFlashType]);
    //#endregion Effects

    //#region Methods
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    let prevSelectedAll = usePrevious(selectedAll);

    const isSelected = (id) => selected.indexOf(id) !== -1;

    if (prevSelectedAll && selectedAll) {
        if (selected.length !== allRows?.length) {
            setSelected(allRows.map((row) => row.news_flash_id));
            setSelectedAll(true);
        }
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = allRows.map((row) => row.news_flash_id);
            setSelected(newSelecteds);
            setSelectedAll(true);
            return;
        }
        setSelected([]);
        setSelectedAll(false);
    };

    const handleClick = (event, id) => {
        event.stopPropagation();
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else {
            newSelected = selected.filter((selectedId) => selectedId !== id);
        }
        setSelected(newSelected);
        setSelectedAll(newSelected.length === allRows?.length);
    };

    const handleRowClick = async ({ rowData }) => {
        if (rowData?.news_flash_id) {
            dispatch(closeAllPanel());
            await dispatch(
                setEditObjectId({
                    formKey: `newsFlashForm`,
                    object_id: rowData?.news_flash_id,
                })
            );
            dispatch(
                openPanel({ formKey: `newsFlashForm`, formAction: 'Edit' })
            );
        }
    };

    function loadMoreRows({ startIndex, stopIndex }) {
        if (newsFlashStatus !== LoadingStatus.Loading) {
            dispatch(
                fetchAllNewsFlash({
                    ...newsFlashPayload,
                    reset: false,
                    startIndex: startIndex,
                    stopIndex: stopIndex,
                    searchString: searchString,
                })
            );
        }
    }
    //#endregion Methods

    const columns = [
        {
            classes: tableClasses,
            minWidth: 50,
            maxWidth: 50,
            width: 50,
            label: 'Select',
            dataKey: 'news_flash_id',
            padding: 'checkbox',
            cellLevel: 'news_flash',
            numSelected: selected.length,
            rowCount: allRows.length,
            handleClick: handleClick,
            handleSelectAllClick: handleSelectAllClick,
            isSelected: isSelected,
            component: checkCell,
            headerComponent: checkHeaderCell,
        },
        {
            classes: tableClasses,
            minWidth: 50,
            maxWidth: 50,
            width: 50,
            label: '',
            dataKey: 'news_flash_id',
            padding: 'none',
            actionCellLevel: 'news_flash',
            targetPopup: 'newsFlash',
            component: actionCell,
            headerComponent: labelHeader,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 200,
            minWidth: 120,
            label: 'Display Name',
            dataKey: 'display_name',
            padding: 'normal',
            component: labelCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 200,
            minWidth: 120,
            label: 'Display Option Name',
            dataKey: 'news_flash_display_option_display_name',
            padding: 'normal',
            component: labelCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 200,
            minWidth: 120,
            label: 'Start Date',
            dataKey: 'start_date',
            padding: 'normal',
            component: labelCell,
            headerComponent: labelHeader,
            transform: (data) =>
                data ? moment(data).local().format('MM/DD/YYYY hh:mm A') : '',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 200,
            minWidth: 120,
            label: 'End Date',
            dataKey: 'end_date',
            padding: 'normal',
            component: labelCell,
            headerComponent: labelHeader,
            transform: (data) =>
                data ? moment(data).local().format('MM/DD/YYYY hh:mm A') : '',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
    ];

    //#region Render time calcs
    //#endregion Render time calcs

    return (
        <Box>
            <EnhancedTableToolbar
                numSelected={selected.length}
                selectedItems={selected}
                totalItems={pageInfo.TotalCount}
                setSelected={setSelected}
            />
            <Box
                style={{
                    height: 'calc(100vh - 250px)',
                    minWidth: '500px',
                    overflowX: 'auto',
                    overflowY: 'hidden',
                }}
            >
                <AutoSizer>
                    {({ width, height }) => (
                        <VirtualTable
                            initialRows={newsFlashes}
                            allRows={allRows}
                            totalRowCount={pageInfo.TotalCount}
                            loadedRowsMap={loadedRowsMap}
                            loadMoreRows={loadMoreRows}
                            columnDefinitions={columns}
                            cellClassName={tableClasses.flexContainer}
                            className={tableClasses.table}
                            rowClassName={clsx(
                                tableClasses.flexContainer,
                                tableClasses.tableRowHover
                            )}
                            dispatch={dispatch}
                            headerHeight={headerHeight}
                            width={width}
                            height={height}
                            rowHeight={rowHeight}
                            onRowClick={handleRowClick}
                        />
                    )}
                </AutoSizer>
            </Box>
        </Box>
    );
}

export { NewsFlashTable };
