import { List, ListItemButton, ListItemText } from '@mui/material';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { useSelector } from 'react-redux';

const LinkList = ({
    zoneItem,
    linkItem,
    hotspotItem,
    item,
    linkProperty,
    handleZoneClick,
}) => {
    const theme = useSelector(selectActiveTheme);
    return (
        <List component="div" disablePadding>
            <ListItemButton
                sx={{ pl: 6 }}
                onClick={(e) => {
                    e.stopPropagation();
                    handleZoneClick(
                        zoneItem,
                        item?.asset_id,
                        hotspotItem,
                        linkItem
                    );
                }}
            >
                <span style={{ color: theme.colors.text, fontWeight: 'bold' }}>
                    Link:&nbsp;
                </span>
                <ListItemText primary={linkItem[linkProperty]} />
            </ListItemButton>
        </List>
    );
};

export default LinkList;
