import React, { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { MdClear } from 'react-icons/md';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { CheckboxWithLabel } from 'formik-mui';
import { FormTextField } from 'components/TextField';
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    TextField,
    createFilterOptions,
} from '@mui/material';

import { makeFormStyles } from 'forms/styles';
import { sortBy } from 'GeminiViewerComponent/_helpers/lodashUtils';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
const filter = createFilterOptions();

const CommonNodeFormFIelds = () => {
    return (
        <>
            <FormTextField
                label="Id"
                name="id"
                placeholder="Id"
                disabled={true}
            />
            <FormTextField
                autoFocus={true}
                label="Title"
                name="title"
                placeholder="Title"
            />
        </>
    );
};

const ExcludeFromNavigationField = () => {
    const { setFieldValue, values } = useFormikContext();
    return (
        <FormTextField
            component={CheckboxWithLabel}
            Label={{
                label: 'Exclude from navigation',
            }}
            label="Exclude from navigation"
            type="checkbox"
            checked={Boolean(values.exclude_from_navigation)}
            onChange={(evt) => {
                setFieldValue('exclude_from_navigation', evt.target.checked);
            }}
        />
    );
};

const NodeCategoryModal = ({ openModal, setOpenModal }) => {
    const { setFieldValue, values } = useFormikContext();
    const theme = useSelector(selectActiveTheme);
    const formClasses = makeFormStyles(theme);
    const [errors, setErrors] = useState(null);

    const closeModal = () => {
        setOpenModal({ nodeCategory: null, isDialogOpen: false });
    };

    const validateNodeCategory = (value) => {
        setOpenModal({
            node_category: value,
            isDialogOpen: true,
        });
        const isExisting = values.procedureCategories?.some(
            (option) => option?.name === value?.trim()
        );
        if (isExisting) {
            setErrors(`Node Category must be unique.`);
        } else {
            setErrors(null);
        }
    };

    const addNodeCategory = useCallback(() => {
        if (
            values?.procedureCategories &&
            values?.procedureCategories?.length > 0
        ) {
            const newCategory = {
                id: uuidv4(),
                name: openModal?.node_category?.trim(),
            };
            const sortedProcedureCategories = [
                ...values.procedureCategories,
                newCategory,
            ].sort(sortBy('name', 'DESC'));

            const updatedProcedureCategories = [];
            sortedProcedureCategories.forEach((category, index) => {
                updatedProcedureCategories.push({
                    ...category,
                    order: index + 1,
                });
            });

            setFieldValue('procedureCategories', [
                ...updatedProcedureCategories,
            ]);
            setFieldValue('node_category', newCategory);
        } else {
            const categoryId = uuidv4();
            setFieldValue('procedureCategories', [
                {
                    id: categoryId,
                    name: openModal?.node_category?.trim(),
                    order: 1,
                },
            ]);
            setFieldValue('node_category', {
                id: categoryId,
                name: openModal?.node_category?.trim(),
            });
        }
        closeModal();
    }, [openModal, values]);

    return (
        <Dialog open={openModal.isDialogOpen} onClose={closeModal}>
            <DialogTitle>Add a new Node Category</DialogTitle>
            <DialogContent sx={{ width: '400px' }}>
                <TextField
                    fullWidth
                    autoFocus
                    margin="dense"
                    id="name"
                    value={openModal?.node_category}
                    onChange={(event) => {
                        validateNodeCategory(event.target.value);
                    }}
                    label="Node Category"
                    type="text"
                    variant="outlined"
                    error={!!errors}
                    helperText={errors}
                />
            </DialogContent>
            <DialogActions>
                <Button className={formClasses.cancel} onClick={closeModal}>
                    Cancel
                </Button>
                <Button
                    className={formClasses.submit}
                    onClick={addNodeCategory}
                    disabled={errors}
                    type="button"
                >
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const NodeCategoryField = () => {
    const { values, setFieldValue } = useFormikContext();
    const [openNodeCategoryModal, setOpenNodeCategoryModal] = useState({
        nodeCategory: null,
        isDialogOpen: false,
    });

    const filterOption = (options, params) => {
        const filtered = filter(options, params);
        if (params?.inputValue !== '') {
            const isExisting = options.some(
                (option) =>
                    params?.inputValue?.trim() === option['name']?.trim()
            );
            if (params?.inputValue !== '' && !isExisting) {
                filtered.push({
                    id:
                        values?.procedureCategories &&
                        values?.procedureCategories?.length > 0
                            ? [...values.procedureCategories].pop()?.id + 1
                            : 1,
                    inputValue: params.inputValue?.trim(),
                    name: `Add "${params.inputValue?.trim()}"`,
                });
            }
        }
        return filtered;
    };

    const onCategoryChange = (event, newInputValue) => {
        if (newInputValue && newInputValue?.inputValue) {
            setOpenNodeCategoryModal({
                node_category: newInputValue?.inputValue,
                isDialogOpen: true,
            });
        } else {
            setFieldValue('node_category', newInputValue ? newInputValue : '');
        }
    };

    return (
        <>
            <FormControl
                variant="outlined"
                style={{
                    position: 'relative',
                }}
            >
                <Autocomplete
                    variant="outline"
                    id="node_category"
                    name="node_category"
                    isOptionEqualToValue={(option, value) =>
                        option?.id === value?.id
                    }
                    options={
                        values?.procedureCategories &&
                        values?.procedureCategories?.length > 0
                            ? [...values.procedureCategories].sort(
                                  sortBy('order', 'DESC')
                              )
                            : []
                    }
                    value={values?.node_category || null}
                    onChange={onCategoryChange}
                    disableClearable={true}
                    getOptionLabel={(option) => option?.name?.trim()}
                    renderInput={(params) => (
                        <>
                            <TextField
                                {...params}
                                variant="outlined"
                                margin="dense"
                                label="Node Category"
                            />
                            <Box
                                sx={{
                                    position: 'absolute',
                                    right: '25px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    fontSize: '20px',
                                }}
                                className={'clearSelect'}
                            >
                                <IconButton
                                    onClick={() => {
                                        setFieldValue('node_category', null);
                                    }}
                                >
                                    <MdClear className="react-icon" />
                                </IconButton>
                            </Box>
                        </>
                    )}
                    filterOptions={(options, params) => {
                        return filterOption(options, params);
                    }}
                />
            </FormControl>
            <NodeCategoryModal
                openModal={openNodeCategoryModal}
                setOpenModal={setOpenNodeCategoryModal}
            />
        </>
    );
};

export { CommonNodeFormFIelds, ExcludeFromNavigationField, NodeCategoryField };
