import { useSelector } from 'react-redux';

import { accountsSlice } from 'app/store';
const { selectActiveUser } = accountsSlice;
import { useConfigValues } from 'hooks/useConfigValues';
import { selectActiveAsset } from 'GeminiViewerComponent/_features/asset/assetSlice';

export const zoneMediaConfigValue = () => {
    const activeAsset = useSelector(selectActiveAsset);
    const activeUser = useSelector(selectActiveUser);

    const { configValues, configFields } = useConfigValues({
        clientId: activeUser?.client_id,
        assetId: activeAsset?.asset_id,
    });

    let defaultConfig = {};
    var configKeys = ['ZoneMedia'];
    let zoneMediaConfigFields = configFields?.filter((fld) =>
        configKeys.includes(fld.key)
    );
    zoneMediaConfigFields.map((fld) => {
        defaultConfig = configValues.find(
            (data) => data.config_field_id == fld.config_field_id
        );
    });
    return defaultConfig?.value && defaultConfig?.value === 'true';
};
