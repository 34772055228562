import React, { useEffect, useRef } from 'react';
import {
    Table,
    Column,
    AutoSizer,
    InfiniteLoader,
    defaultTableRowRenderer,
} from 'react-virtualized';

import { useStyles } from './styles';
import Loader from 'GeminiViewerComponent/components/Loader';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';

function VirtualTable({
    initialRows,
    allRows,
    totalRowCount,
    loadedRowsMap,
    loadMoreRows,
    columnDefinitions,
    cellClassName,
    disableHeader,
    loadingStatus = '',
    accordianTable = false,
    selected,
    setCardWrapperHeight = () => {},
    ...tableProps
}) {
    const infiniteLoaderRef = useRef(null);
    const hasMountedRef = useRef(false);
    const classes = useStyles();
    let minTableWidth = 0;
    columnDefinitions.forEach((element) => {
        if (element?.width) {
            minTableWidth += +element?.width;
        }
    });
    // const [rowIndex, setRowIndex] = useState(null)

    // Each time initialRows prop changes call resetLoadMoreRowsCache to clear the cache
    useEffect(() => {
        if (hasMountedRef.current) {
            if (infiniteLoaderRef.current) {
                infiniteLoaderRef.current.resetLoadMoreRowsCache();
            }
        }
        hasMountedRef.current = true;
    }, [initialRows]);

    useEffect(() => {
        if (accordianTable) {
            const rowCount = initialRows.length;
            if (rowCount <= 11) {
                setCardWrapperHeight(tableProps?.rowHeight * rowCount + 55);
            } else {
                setCardWrapperHeight(null);
            }
        }
    }, [initialRows.length]);

    const dragStartHandler = (e, asset) => {
        e.dataTransfer.setData('cardInfo', JSON.stringify(asset));
        e.dataTransfer.setData('assetIds', JSON.stringify(selected));
    };

    const SortableTableRowRenderer = defaultTableRowRenderer;

    function rowRenderer(props) {
        return (
            <div
                className={
                    selected && selected?.length > 0
                        ? selected.includes(props?.rowData.asset_id)
                            ? 'virtualTableRows'
                            : ''
                        : 'virtualTableRows'
                }
                draggable="true"
                style={{
                    userSelect: 'all',
                    height: '60px',
                }}
                onDragStart={(e) => dragStartHandler(e, props?.rowData)}
            >
                <SortableTableRowRenderer {...props} />
            </div>
        );
    }

    return (
        <InfiniteLoader
            isRowLoaded={isRowLoaded}
            loadMoreRows={loadMoreRows}
            rowCount={totalRowCount}
            ref={infiniteLoaderRef}
        >
            {({ onRowsRendered, ref }) => (
                <AutoSizer>
                    {({ width }) => (
                        <Table
                            ref={ref}
                            disableHeader={disableHeader}
                            onRowsRendered={onRowsRendered}
                            rowCount={
                                accordianTable
                                    ? initialRows.length
                                    : allRows.length
                            }
                            rowGetter={({ index }) =>
                                accordianTable
                                    ? initialRows[index]
                                    : allRows[index]
                            }
                            rowRenderer={rowRenderer}
                            {...tableProps}
                            height={tableProps?.height - 10}
                            width={
                                minTableWidth > +tableProps?.width
                                    ? minTableWidth
                                    : +tableProps?.width
                            }
                            className={classes.virtualTable}
                            noRowsRenderer={() =>
                                loadingStatus === LoadingStatus.Loading ? (
                                    <Loader
                                        styles={{
                                            width: '100%',
                                            height: '100%',
                                            borderBottom: '1px solid #e0e0e0',
                                        }}
                                    />
                                ) : loadingStatus === LoadingStatus.Idle ? (
                                    ''
                                ) : (
                                    <div
                                        style={{
                                            fontSize: '18px',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            display: 'flex',
                                            height: '100%',
                                        }}
                                    >
                                        {loadingStatus}
                                    </div>
                                )
                            }
                        >
                            {columnDefinitions.map(
                                ({ dataKey, ...other }, index) => (
                                    <Column
                                        key={dataKey}
                                        headerRenderer={(headerProps) =>
                                            headerRenderer({
                                                ...headerProps,
                                                columnIndex: index,
                                            })
                                        }
                                        className={cellClassName}
                                        cellRenderer={cellRenderer}
                                        dataKey={dataKey}
                                        {...other}
                                    />
                                )
                            )}
                        </Table>
                    )}
                </AutoSizer>
            )}
        </InfiniteLoader>
    );

    function isRowLoaded({ index }) {
        // No entry in this map signifies that the row has never been loaded before
        // An entry (either LOADING or LOADED) can be treated as loaded as far as InfiniteLoader is concerned
        return !!loadedRowsMap[index];
    }

    function cellRenderer(props) {
        const { columnIndex } = props;

        if (columnIndex == null) return null;

        const column = columnDefinitions[columnIndex];

        return column.component(props, column);
    }

    function headerRenderer(props) {
        const { columnIndex } = props;
        const column = columnDefinitions[columnIndex];
        return column.headerComponent(props, column);
    }
}

export { VirtualTable };
