import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

const HiddenField = ({
    data,
    key,
    fieldValue,
    setFieldValue,
    errors,
    setErrors,
    isSubmitAllow = true,
    handleClick = () => {},
}) => {
    let hiddenFieldValue = data?.variableValue;
    if (data?.autoGenerateGuid === true) {
        hiddenFieldValue = uuidv4();
    }

    useEffect(() => {
        isSubmitAllow && setFieldValue(data.variable, hiddenFieldValue);
    }, []);

    return (
        <input
            type="hidden"
            id={data.variable}
            key={data.id}
            value={hiddenFieldValue}
            onChange={(e) => {
                console.log(e);
            }}
            onClick={(e) => {
                !isSubmitAllow && handleClick(e, data);
            }}
        />
    );
};

export default HiddenField;
