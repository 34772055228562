import React from 'react';
import { Divider } from '@mui/material';

import { Action, ContentTypes } from '_helpers';
import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';

function ContentPopup({ object, level, onClose, readOnlyMode }) {
    return (
        <React.Fragment>
            <PopupAction
                action={Action.Edit}
                object={object}
                level={level}
                showLabel={true}
                onActionHandled={onClose}
            />
            {readOnlyMode === false && (
                <>
                    {object?.content_type_id !== ContentTypes.Sme.id && (
                        <>
                            <Divider />
                            <PopupAction
                                action={Action.CreateContentVersion}
                                object={object}
                                level={level}
                                showLabel={true}
                                onActionHandled={onClose}
                            />
                        </>
                    )}
                    <Divider />
                    <PopupAction
                        action={Action.Delete}
                        object={object}
                        level={level}
                        showLabel={true}
                        onActionHandled={onClose}
                    />
                </>
            )}
        </React.Fragment>
    );
}

export { ContentPopup };
