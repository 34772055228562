import React, { useEffect, useState } from 'react';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import { makeFormStyles } from 'forms/styles';
import { CheckboxWithLabel } from 'formik-mui';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    FormControl,
    Grid,
    Autocomplete,
    TextField as MuiText,
} from '@mui/material';
import { SelectionAutocomplete } from 'components/_Autocomplete/SelectionAutocomplete';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    getAssetsPicklist,
    getItemsPicklist,
    getLinksPicklist,
    getZonesPicklist,
    resetItemsPicklistStatus,
    resetLinksPicklistStatus,
    resetZonesPicklistStatus,
    selectAssetsPicklist,
    selectAssetsPicklistLoadingStatus,
    selectItemsPicklist,
    selectItemsPicklistLoadingStatus,
    selectLinksPicklist,
    selectLinksPicklistLoadingStatus,
    selectZonesPicklist,
    selectZonesPicklistLoadingStatus,
} from '_features/picklists/pickListsSlice';
import { FormTextField } from 'components/TextField';

const SelectTargetNodeForm = ({
    nodes,
    activeNode,
    onSelectedTargetNode,
    loadedTargetNode,
}) => {
    const dispatch = useDispatch();
    const theme = useSelector(selectActiveTheme);
    const commonFormStyles = makeFormStyles(theme);
    const INIT_VALUES = {
        link_text: '',
        is_button: false,
        auto_play: false,
        asset_id: null,
        zone_id: null,
        item_id: null,
        link_id: null,
        node_id: null,
    };
    let filteredNodes = nodes.filter((nd) => nd.id !== activeNode?.id);
    filteredNodes = filteredNodes.map((ele) => {
        return { id: ele.id, label: `${ele.title}  - #${ele?.id}` };
    });
    const href = loadedTargetNode?.href?.split('?')[1].split('&');
    let onEditValues = Object.create(null);
    if (href) {
        for (let index = 0; index < href.length; index++) {
            const str = href[index].split('=');

            onEditValues[str[0]] = Number(str[1]);
            if (str[0] === 'node_id') {
                onEditValues[str[0]] = filteredNodes.find(
                    (ele) => ele.id === Number(str[1])
                );
            }
        }
    }
    if (loadedTargetNode?.link_text) {
        onEditValues['link_text'] = loadedTargetNode?.link_text;
    }
    if (loadedTargetNode?.isButton) {
        onEditValues['is_button'] = true;
    }

    if (loadedTargetNode?.autoPlay) {
        onEditValues['auto_play'] = true;
    }

    const [assetId, setAssetId] = useState(null);
    const [zoneId, setZoneId] = useState(null);
    const [itemId, setItemId] = useState(null);
    const [initValues, setInitValues] = useState({
        ...INIT_VALUES,
        ...onEditValues,
    });

    useEffect(() => {
        setAssetId(null);
        dispatch(resetZonesPicklistStatus());
        setZoneId(null);
        dispatch(resetItemsPicklistStatus());
        setItemId(null);
        dispatch(resetLinksPicklistStatus());
    }, []); // eslint-disable-line

    useEffect(() => {
        if (assetId) {
            dispatch(resetZonesPicklistStatus());
            setZoneId(null);
            dispatch(resetItemsPicklistStatus());
            setItemId(null);
            dispatch(resetLinksPicklistStatus());
        }
    }, [assetId]); // eslint-disable-line

    useEffect(() => {
        if (zoneId) {
            dispatch(resetItemsPicklistStatus());
            setItemId(null);
            dispatch(resetLinksPicklistStatus());
        }
    }, [zoneId]); // eslint-disable-line

    useEffect(() => {
        if (itemId) {
            dispatch(resetLinksPicklistStatus());
        }
    }, [itemId]); // eslint-disable-line

    useEffect(() => {
        if (loadedTargetNode) {
            setInitValues({
                ...INIT_VALUES,
                ...onEditValues,
            });
        }
    }, [loadedTargetNode]); // eslint-disable-line

    return (
        <Dialog open={true} fullWidth maxWidth="sm">
            <DialogTitle>Select Target Node</DialogTitle>
            <DialogContent
                style={{
                    paddingTop: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Formik
                            initialValues={initValues}
                            validationSchema={Yup.object({
                                link_text: Yup.string().required('Required'),
                                node_id: Yup.object()
                                    .required('Required')
                                    .nullable(),
                            })}
                            onSubmit={(values) => {
                                onSelectedTargetNode(values, loadedTargetNode);
                            }}
                        >
                            {({ setFieldValue, values, errors, touched }) => (
                                <Form>
                                    <FormControl fullWidth>
                                        <FormTextField
                                            label="Title"
                                            value={values.link_text}
                                            name="link_text"
                                            placeholder="Title"
                                            onChange={(evt) =>
                                                setFieldValue(
                                                    'link_text',
                                                    evt.target.value
                                                )
                                            }
                                        />
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <FormTextField
                                            component={CheckboxWithLabel}
                                            Label={{
                                                label: 'Display as a button',
                                            }}
                                            label="Display as a button"
                                            type="checkbox"
                                            checked={!!values.is_button}
                                            onChange={(evt) =>
                                                setFieldValue(
                                                    'is_button',
                                                    evt.target.checked
                                                )
                                            }
                                        />
                                    </FormControl>
                                    <FormControl
                                        fullWidth
                                        style={{ marginTop: '10px' }}
                                    >
                                        <Field name="asset_id">
                                            {({
                                                field: { value },
                                                form: { setFieldValue },
                                            }) => (
                                                <SelectionAutocomplete
                                                    title="Asset"
                                                    keyProperty="asset_id"
                                                    nameProperty="display_name"
                                                    entityIds={value}
                                                    setEntityIds={setFieldValue}
                                                    onCustomChange={(evt) => {
                                                        setFieldValue(
                                                            'zone_id',
                                                            null
                                                        );
                                                        setFieldValue(
                                                            'item_id',
                                                            null
                                                        );
                                                        setFieldValue(
                                                            'link_id',
                                                            null
                                                        );
                                                        setAssetId(
                                                            evt?.asset_id
                                                        );
                                                    }}
                                                    entitySelector={
                                                        selectAssetsPicklist
                                                    }
                                                    fetchEntityPage={
                                                        getAssetsPicklist
                                                    }
                                                    formField={'asset_id'}
                                                    multiSelection={false}
                                                    entityStatusSelector={
                                                        selectAssetsPicklistLoadingStatus
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </FormControl>
                                    {!!values.asset_id && (
                                        <FormControl
                                            fullWidth
                                            style={{ marginTop: '10px' }}
                                        >
                                            <Field name="zone_id">
                                                {({
                                                    field: { value },
                                                    form: { setFieldValue },
                                                }) => (
                                                    <SelectionAutocomplete
                                                        title="Zone"
                                                        keyProperty="zone_id"
                                                        nameProperty="display_name"
                                                        entityIds={value}
                                                        setEntityIds={
                                                            setFieldValue
                                                        }
                                                        onCustomChange={(
                                                            evt
                                                        ) => {
                                                            setFieldValue(
                                                                'item_id',
                                                                null
                                                            );
                                                            setFieldValue(
                                                                'link_id',
                                                                null
                                                            );
                                                            setZoneId(
                                                                evt?.zone_id
                                                            );
                                                        }}
                                                        entitySelector={
                                                            selectZonesPicklist
                                                        }
                                                        fetchEntityPage={
                                                            getZonesPicklist
                                                        }
                                                        formField={'zone_id'}
                                                        multiSelection={false}
                                                        entityStatusSelector={
                                                            selectZonesPicklistLoadingStatus
                                                        }
                                                        fetchParams={{
                                                            assetId:
                                                                values?.asset_id,
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </FormControl>
                                    )}

                                    {!!values.asset_id && !!values.zone_id && (
                                        <FormControl
                                            fullWidth
                                            style={{ marginTop: '10px' }}
                                        >
                                            <Field name="item_id">
                                                {({
                                                    field: { value },
                                                    form: { setFieldValue },
                                                }) => (
                                                    <SelectionAutocomplete
                                                        title="Item"
                                                        keyProperty="item_id"
                                                        nameProperty="display_name"
                                                        entityIds={value}
                                                        setEntityIds={
                                                            setFieldValue
                                                        }
                                                        onCustomChange={(
                                                            evt
                                                        ) => {
                                                            setFieldValue(
                                                                'link_id',
                                                                null
                                                            );
                                                            setItemId(
                                                                evt?.item_id
                                                            );
                                                        }}
                                                        entitySelector={
                                                            selectItemsPicklist
                                                        }
                                                        fetchEntityPage={
                                                            getItemsPicklist
                                                        }
                                                        formField={'item_id'}
                                                        multiSelection={false}
                                                        entityStatusSelector={
                                                            selectItemsPicklistLoadingStatus
                                                        }
                                                        fetchParams={{
                                                            zoneId: values?.zone_id,
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </FormControl>
                                    )}

                                    {!!values.asset_id &&
                                        !!values.zone_id &&
                                        !!values.item_id && (
                                            <FormControl
                                                fullWidth
                                                style={{ marginTop: '10px' }}
                                            >
                                                <Field name="link_id">
                                                    {({
                                                        field: { value },
                                                        form: { setFieldValue },
                                                    }) => (
                                                        <SelectionAutocomplete
                                                            title="Link"
                                                            keyProperty="link_id"
                                                            nameProperty="display_name"
                                                            entityIds={value}
                                                            setEntityIds={
                                                                setFieldValue
                                                            }
                                                            entitySelector={
                                                                selectLinksPicklist
                                                            }
                                                            fetchEntityPage={
                                                                getLinksPicklist
                                                            }
                                                            formField={
                                                                'link_id'
                                                            }
                                                            multiSelection={
                                                                false
                                                            }
                                                            entityStatusSelector={
                                                                selectLinksPicklistLoadingStatus
                                                            }
                                                            fetchParams={{
                                                                itemId: values?.item_id,
                                                                linkTypeId: '',
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                            </FormControl>
                                        )}

                                    <FormControl
                                        fullWidth
                                        style={{ marginTop: '10px' }}
                                    >
                                        <Field name="node_id">
                                            {({
                                                field: { value },
                                                form: { setFieldValue },
                                            }) => (
                                                <Autocomplete
                                                    fullWidth={true}
                                                    isOptionEqualToValue={(
                                                        option,
                                                        value
                                                    ) => option.id === value.id}
                                                    variant="outline"
                                                    id="node_id"
                                                    options={filteredNodes}
                                                    value={value}
                                                    onChange={(
                                                        event,
                                                        newInputValue
                                                    ) => {
                                                        setFieldValue(
                                                            'node_id',
                                                            newInputValue
                                                        );
                                                    }}
                                                    renderInput={(params) => (
                                                        <MuiText
                                                            {...params}
                                                            error={
                                                                errors.node_id &&
                                                                touched.node_id
                                                            }
                                                            variant="outlined"
                                                            label="Target Node"
                                                            placeholder="Target Node"
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage
                                            className={
                                                commonFormStyles.inputErr
                                            }
                                            name="node_id"
                                            component="div"
                                        />
                                    </FormControl>

                                    <div
                                        style={{
                                            justifyContent: 'end',
                                            display: 'flex',
                                            marginTop: '25px',
                                        }}
                                    >
                                        <Button
                                            onClick={() => {
                                                onSelectedTargetNode(
                                                    INIT_VALUES
                                                );
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button type="submit">Save</Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default SelectTargetNodeForm;
