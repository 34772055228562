import {
    hotSpotHasTag,
    isHotSpotVisible,
    isZoneVisible,
    zoneHasTag,
} from '../../_helpers/hotspot_helpers';
import { v4 as uuidv4 } from 'uuid';
import { nearestPow2 } from '../../_helpers';
import { GeminiViewManager } from '../GeminiViewManager';

class MarzipanoManager {
    config = {};
    hotspotItems = {};
    zoneItems = {};
    scenes = [];
    onSceneChanged = undefined;
    onShiftRightClick = undefined;
    onRightClick = undefined;
    onClick = undefined;
    viewer = undefined;
    data = undefined;
    autorotateToggleElement = undefined;
    disableClick = true;
    panoElement = undefined;
    isMultipleHotspotEnabled = undefined;
    itemHotspots = [];
    tagFilters = [];
    showOnlyByFilter = undefined;
    constructor(props) {
        this.config = props.config;
        this.initialZoneId = props.initialZoneId;
        this.hotspotItems = props.hotspotItems;
        this.zoneItems = props.zoneItems;
        this.itemHotspots = props.itemHotspots;
        this.onSceneChanged = props.onSceneChanged;
        this.onShiftRightClick = props.onShiftRightClick;
        this.onRightClick = props.onRightClick;
        this.onClick = props.onClick;
        this.isMultipleHotspotEnabled = props.isMultipleHotspotEnabled;
        this.tagFilters = props.tagFilters;
        this.handleMultipleHotspot = props.handleMultipleHotspot;
        this.showOnlyByFilter = props.showOnlyByFilter;
        window.geminiViewManager = new GeminiViewManager(this);
    }
    init() {
        this.Marzipano = window.Marzipano;
        var bowser = window.bowser;
        var screenfull = window.screenfull;

        this.data = this.config.data;

        if (this.data && this.data.scenes) {
            this.data.scenes = this.data.scenes.filter(function (data) {
                return (
                    zoneHasTag(
                        this.tagFilters.zone_tags.map(
                            (tag) => tag.tag_id,
                            this.zoneItems[data.id]
                        ) && this.config.user.role.toUpperCase() === 'ADMIN'
                    ) ||
                    isZoneVisible(
                        this.config.user.audienceIds,
                        this.zoneItems[data.id]
                    )
                );
            }, this);
        }

        // Grab elements from DOM.
        this.panoElement = document.querySelector('#pano');
        var fullscreenToggleElement =
            document.querySelector('#fullscreenToggle');

        this.autorotateToggleElement =
            document.querySelector('#autorotateToggle');
        this.sceneNameElement = document.querySelector('#titleBar .sceneName');

        // Detect desktop or mobile mode.
        if (window.matchMedia) {
            var setMode = function () {
                if (mql.matches) {
                    document.body.classList.remove('desktop');
                    document.body.classList.add('mobile');
                } else {
                    document.body.classList.remove('mobile');
                    document.body.classList.add('desktop');
                }
            };
            var mql = matchMedia('(max-width: 500px), (max-height: 500px)');
            setMode();
            mql.addListener(setMode);
        } else {
            document.body.classList.add('desktop');
        }

        // Detect whether we are on a touch device.
        document.body.classList.add('no-touch');
        window.addEventListener('touchstart', function () {
            document.body.classList.remove('no-touch');
            document.body.classList.add('touch');
        });

        // Use tooltip fallback mode on IE < 11.
        if (bowser.msie && parseFloat(bowser.version) < 11) {
            document.body.classList.add('tooltip-fallback');
        }

        // Viewer options.
        var viewerOpts = {
            controls: {
                mouseViewMode: this.data.settings.mouseViewMode,
            },
        };

        // Reset viewer if previous pano element exist.
        this._resetViewer(this.panoElement);

        // Initialize viewer.
        this.viewer = new this.Marzipano.Viewer(this.panoElement, viewerOpts);

        this.viewer.addEventListener('sceneChange', () => {
            if (this.onSceneChanged !== undefined) {
                const scene = this._activeScene();
                if (scene !== null) {
                    this.onSceneChanged(scene);
                }
            }
        });
        this.viewer.addEventListener('viewChange', () => {
            if (this.isMultipleHotspotEnabled) {
                this.handleMultipleHotspot();
            }
        });

        const handleContextClick = (e) => {
            e.preventDefault();

            if (!this.viewer.view()) {
                return;
            }

            if (e.shiftKey) {
                let loc = this.viewer
                    .view()
                    .screenToCoordinates({ x: e.offsetX, y: e.offsetY });
                if (this.onShiftRightClick) {
                    const activeScene = this._activeScene();
                    this.onShiftRightClick({
                        location: loc,
                        target: activeScene.data.id,
                        zone_id: activeScene.data.zone_id,
                    });
                }
            } else {
                let loc = this.viewer
                    .view()
                    .screenToCoordinates({ x: e.offsetX, y: e.offsetY });
                if (this.onRightClick) {
                    const activeScene = this._activeScene();
                    this.onRightClick({
                        location: loc,
                        target: activeScene.data.id,
                        zone_id: activeScene.data.zone_id,
                    });
                }
            }
        };

        const handlePanoClick = (e) => {
            e.preventDefault();
            if (!this.viewer.view()) {
                return;
            }
            if (this.onClick && this.isMultipleHotspotEnabled) {
                let loc = this.viewer
                    .view()
                    .screenToCoordinates({ x: e.offsetX, y: e.offsetY });
                if (this.onClick) {
                    const activeScene = this._activeScene();
                    this.onClick({
                        location: loc,
                        target: activeScene.data.id,
                        zone_id: activeScene.data.zone_id,
                        isMultipleHotspotEnabled: this.isMultipleHotspotEnabled,
                        itemHotspots: this.itemHotspots,
                    });
                }
            }
        };

        if (window.previousEventListener) {
            this.panoElement.removeEventListener(
                'contextmenu',
                window.previousEventListener
            );
        }
        window.previousEventListener = handleContextClick;
        this.panoElement.addEventListener('contextmenu', handleContextClick);
        this.panoElement.addEventListener('click', handlePanoClick);

        // Create scenes.
        // this.scenes = this.data.scenes.map(function (data) {

        //     const scene = this.createScene(data.id, this.config.paths.TILES_URL, data.levels, data.faceSize, data.initialViewParameters);

        //     this.addSceneHotSpots(scene, data.linkHotspots, data.infoHotspots);

        // var source = null;

        // if (this.config.paths.TILES_URL) {
        //     var urlPrefix = this.config.paths.TILES_URL;
        //     source = Marzipano.ImageUrlSource.fromString(
        //         urlPrefix + "/" + data.id + "/{z}/{f}/{y}/{x}.jpg", {
        //             cubeMapPreviewUrl: urlPrefix + "/" + data.id + "/preview.jpg"
        //         });
        // }
        // if (this.config.imageUrlFunction) {
        //     source = new Marzipano.ImageUrlSource(this.config.imageUrlFunction);
        // }

        // var geometry = new Marzipano.CubeGeometry(data.levels);

        // var limiter = Marzipano.RectilinearView.limit.traditional(data.faceSize, 100 * Math.PI / 180, 120 * Math.PI / 180);
        // var view = new Marzipano.RectilinearView(data.initialViewParameters, limiter);

        // var scene = null;
        // if (source) {
        //     scene = this.viewer.createScene({
        //         source: source,
        //         geometry: geometry,
        //         view: view,
        //         pinFirstLevel: true
        //     });
        // }

        // Create link hotspots.
        // data.linkHotspots?.forEach(function (hotspot) {

        //     if (this.config.user.role.toUpperCase() !== "ADMIN" && !isZoneVisible(this.config.user.audienceIds, this.zoneItems[hotspot.target])) {
        //         return;
        //     }

        //     var element = this._createLinkHotspotElement(hotspot);
        //     scene.hotspotContainer().createHotspot(element, {
        //         yaw: hotspot.yaw,
        //         pitch: hotspot.pitch
        //     });
        //     element.addEventListener('click', () => {
        //         console.log(hotspot)
        //     })
        // }, this);

        // // Create info hotspots.
        // data.infoHotspots?.forEach(function (hotspot) {

        //     if (this.config.user.role.toUpperCase() === "ADMIN" ||
        //         isHotSpotVisible(this.config.user.audienceIds, hotspot.pano_id, this.hotspotItems)) {
        //         var element = this._createInfoHotspotElement(hotspot);
        //         scene.hotspotContainer().createHotspot(element, {
        //             yaw: hotspot.yaw,
        //             pitch: hotspot.pitch
        //         });
        //         element.addEventListener('click', () => {
        //             if (hotspot.pano_id) { window.handleHotspot(hotspot.pano_id); };
        //         })
        //     }
        // }, this);

        //     return {
        //         data: data,
        //         scene: scene
        //         // view: scene.view()
        //     };
        // }, this);

        // Set up autorotate, if enabled.
        this.autorotate = this.Marzipano.autorotate({
            yawSpeed: 0.03,
            targetPitch: 0,
            targetFov: Math.PI / 2,
        });
        if (this.data.settings.autorotateEnabled) {
            this.autorotateToggleElement.classList.add('enabled');
            // Set handler for autorotate toggle.
            this.autorotateToggleElement.addEventListener(
                'click',
                this._toggleAutorotate
            );
        }

        // Set up fullscreen mode, if supported.
        if (screenfull?.enabled && this.data.settings.fullscreenButton) {
            document.body.classList.add('fullscreen-enabled');
            fullscreenToggleElement.addEventListener('click', function () {
                screenfull.toggle();
            });
            screenfull.on('change', function () {
                if (screenfull.isFullscreen) {
                    fullscreenToggleElement.classList.add('enabled');
                } else {
                    fullscreenToggleElement.classList.remove('enabled');
                }
            });
        } else {
            document.body.classList.add('fullscreen-disabled');
        }

        // DOM elements for view controls.

        if (this.data.settings.viewControlButtons) {
            var viewUpElement = document.querySelector('#viewUp');
            var viewDownElement = document.querySelector('#viewDown');
            var viewLeftElement = document.querySelector('#viewLeft');
            var viewRightElement = document.querySelector('#viewRight');
            var viewInElement = document.querySelector('#viewIn');
            var viewOutElement = document.querySelector('#viewOut');

            // Dynamic parameters for controls.
            var velocity = 0.7;
            var friction = 3;

            // Associate view controls with elements.
            var controls = this.viewer.controls();
            controls.registerMethod(
                'upElement',
                new this.Marzipano.ElementPressControlMethod(
                    viewUpElement,
                    'y',
                    -velocity,
                    friction
                ),
                true
            );
            controls.registerMethod(
                'downElement',
                new this.Marzipano.ElementPressControlMethod(
                    viewDownElement,
                    'y',
                    velocity,
                    friction
                ),
                true
            );
            controls.registerMethod(
                'leftElement',
                new this.Marzipano.ElementPressControlMethod(
                    viewLeftElement,
                    'x',
                    -velocity,
                    friction
                ),
                true
            );
            controls.registerMethod(
                'rightElement',
                new this.Marzipano.ElementPressControlMethod(
                    viewRightElement,
                    'x',
                    velocity,
                    friction
                ),
                true
            );
            controls.registerMethod(
                'inElement',
                new this.Marzipano.ElementPressControlMethod(
                    viewInElement,
                    'zoom',
                    -velocity,
                    friction
                ),
                true
            );
            controls.registerMethod(
                'outElement',
                new this.Marzipano.ElementPressControlMethod(
                    viewOutElement,
                    'zoom',
                    velocity,
                    friction
                ),
                true
            );
        }

        // Display the initial scene.
        //this.switchScene(this.scenes[0]);

        // window.switchScene = switchScene;
        // window.switchSceneById = switchSceneById;
        // window.switchToHomeScene = switchToHomeScene;
    }

    updateSceneData(multipleHotspotFlag, activeZone, handleMultipleHotspot) {
        this.isMultipleHotspotEnabled = multipleHotspotFlag;
        this.itemHotspots = activeZone?.items;
        this.handleMultipleHotspot = handleMultipleHotspot;
    }

    screenToCoordinates(coords) {
        return this.viewer.view().screenToCoordinates(coords);
    }

    getCurrentViewParams() {
        return this.viewer.view().parameters();
    }

    getCurrentViewImage() {
        return this.viewer.stage().takeSnapshot();
    }

    setSceneInitView() {
        const activeScene = this.findSceneFromViewScene(this.viewer.scene());
        let viewParams = this.viewer.view().parameters();

        var icon = document.createElement('object');
        icon.type = 'image/svg+xml';
        icon.data = this.config.paths.SCENE_INITVIEW_ANIM_URL;
        icon.classList.add('scene-initview-anim');
        this.panoElement.appendChild(icon);
        setTimeout(() => {
            icon.remove();
        }, 3000);

        if (this.onSceneInitViewChanged) {
            this.onSceneInitViewChanged(activeScene.data.zone_id, viewParams);
        }
    }

    addInfoHotspot() {
        const activeScene = this.findSceneFromViewScene(this.viewer.scene());
        const params = this.viewer.view().parameters();
        let hotspot = {
            item_id: uuidv4(),
            zone_id: activeScene.data.id,
            yaw: params.yaw,
            pitch: params.pitch,
            flat_x: params.flat_x,
            flat_y: params.flat_y,
        };
        this.updateSceneInfoHotspot(activeScene, hotspot, true);
    }

    addLinkHotspot() {
        const activeScene = this.findSceneFromViewScene(this.viewer.scene());
        const params = this.viewer.view().parameters();
        let hotspot = {
            link_hotspot_id: uuidv4(),
            zone_id: activeScene.data.id,
            yaw: params.yaw,
            pitch: params.pitch,
            flat_x: params.flat_x,
            flat_y: params.flat_y,
            targetName: activeScene.data.name,
            target: activeScene.data.id,
            rotation: 0,
            scale: 1.0,
        };
        this.updateSceneLinkHotspot(activeScene, hotspot, true);
    }

    addAllScenes(scenes) {
        scenes.forEach((data) => {
            this.addScene(
                data.name,
                data.id,
                data.zone_id,
                this.config.paths.TILES_URL,
                null,
                data.levels,
                data.faceSize,
                data.initialViewParameters
            );
        });
    }

    updatesceneView(zone) {
        if (this.viewer) {
            this.viewer.lookTo({
                yaw: zone?.init_view_yaw,
                pitch: zone?.init_view_pitch,
                fov: zone?.init_view_fov || 1,
            });
        }
    }

    async addScenesFromZones(
        zones,
        reactHotspots,
        tagIdFilter,
        showOnlyByFilter
    ) {
        this.reactHotspots = reactHotspots;
        this.showOnlyByFilter = showOnlyByFilter;

        // Add all scenes before adding hotspot to each one since hotspots may reference other scenes
        for (let zone of zones) {
            var zoneObj = zone.editObject
                ? zone.editObject
                : zone.zone
                ? zone.zone
                : zone;
            if (
                zoneObj.cubeFaces ||
                zoneObj.flat_image_url ||
                zoneObj.equirect_image_url
            ) {
                await this.addPreviewSceneFromZone(zoneObj);
            }
        }

        zones.forEach((zone) => {
            var zoneObj = zone.editObject
                ? zone.editObject
                : zone.zone
                ? zone.zone
                : zone;
            if (
                zoneObj.cubeFaces ||
                zoneObj.flat_image_url ||
                zoneObj.equirect_image_url
            ) {
                const scene = this.findSceneById(zoneObj.zone_id);
                this.updateSceneHotSpots(
                    scene,
                    zone.nav_links,
                    zone.items,
                    tagIdFilter
                );
            }
        });
    }

    async _imageExists(url) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.src = url;

            if (img.complete) {
                resolve(true);
            } else {
                img.onload = () => {
                    resolve(true);
                };
                img.onerror = (e) => {
                    resolve(false);
                };
            }
        });
    }

    async addPreviewSceneFromZone(zone) {
        var imageUrlSource;
        var geometry;
        var limiter;
        var view;
        var levels;

        let viewParams = {
            yaw: zone.init_view_yaw,
            pitch: zone.init_view_pitch,
            roll: zone.init_view_roll,
            fov: zone.init_view_fov || 1,
            x: zone.init_view_flat_x,
            y: zone.init_view_flat_y,
            zoom: zone.init_view_zoom,
        };

        if (zone.flat_image_url) {
            var tileUrl = function (z, x, y) {
                return zone.flat_image_url;
            };
            imageUrlSource = new this.Marzipano.ImageUrlSource(function (tile) {
                return { url: tileUrl(tile.z + 1, tile.x + 1, tile.y + 1) };
            });
            levels = [
                { width: 756, height: 756, tileWidth: 756, tileHeight: 756 },
            ];
            geometry = new this.Marzipano.FlatGeometry(levels);
            limiter = this.Marzipano.util.compose(
                // this.Marzipano.FlatView.limit.resolution(
                //     zone.flat_image_height
                // ),
                this.Marzipano.FlatView.limit.letterbox()
            );
            view = new this.Marzipano.FlatView(
                {
                    x: viewParams.x,
                    y: viewParams.y,
                    mediaAspectRatio:
                        zone.flat_image_width / zone.flat_image_height,
                },
                limiter
            );
        } else if (zone.equirect_image_url) {
            const faceSize = Math.max(
                nearestPow2(zone.equirect_image_width / 4),
                512
            );

            levels = [
                {
                    tileSize: 256,
                    size: 256,
                    fallbackOnly: true,
                },
            ];

            var levelWidth = faceSize;
            while (levelWidth >= 512) {
                levels.push({
                    tileSize: 512,
                    size: levelWidth,
                });
                levelWidth /= 2;
            }

            if (zone.equirect_image_url.endsWith('_tiles')) {
                geometry = new this.Marzipano.CubeGeometry(levels);

                // NOTE: Originally preview images were mistakenly named preview.png
                // This code accounts for that by attempting to load the correct
                // jpg version first and if that doesn't work then it attempts
                // to load the old png version. Without this, Marzipano will
                // continuosly try to load the missing preview.jpg/png image
                // if it's not found,

                const urlPrefix = zone.equirect_image_url;
                var previewImageName = 'preview.jpg';

                var imageExists = await this._imageExists(
                    `${urlPrefix}/${previewImageName}`
                );
                if (!imageExists) {
                    previewImageName = 'preview.png';
                    imageExists = await this._imageExists(
                        `${urlPrefix}/${previewImageName}`
                    );
                    if (!imageExists) {
                        previewImageName = null;
                    }
                }

                if (previewImageName) {
                    imageUrlSource = this.Marzipano.ImageUrlSource.fromString(
                        urlPrefix + '/{z}/{f}/{y}/{x}.jpg',
                        {
                            cubeMapPreviewUrl: `${urlPrefix}/${previewImageName}`,
                        }
                    );
                } else {
                    imageUrlSource = this.Marzipano.ImageUrlSource.fromString(
                        urlPrefix + '/{z}/{f}/{y}/{x}.jpg'
                    );
                }
            } else {
                imageUrlSource = this.Marzipano.ImageUrlSource.fromString(
                    zone.equirect_image_url
                );
                geometry = new this.Marzipano.EquirectGeometry(levels);
            }

            limiter = this.Marzipano.RectilinearView.limit.traditional(
                faceSize * 4,
                (140 * Math.PI) / 180,
                (140 * Math.PI) / 180
            );
            view = new this.Marzipano.RectilinearView(viewParams, limiter);
        } else {
            const faceSize = nearestPow2(zone.equirect_image_width / 4);

            const faceToCubeFolderName = {
                pz: 'b',
                nz: 'f',
                px: 'l',
                nx: 'r',
                py: 'u',
                ny: 'd',
            };

            const imageUrlFunction = (tile) => {
                let targetFace = undefined;
                zone.cubeFaces.forEach((cubeFace) => {
                    const marzipanoFaceName =
                        faceToCubeFolderName[cubeFace.faceName];
                    if (marzipanoFaceName === tile.face) {
                        targetFace = cubeFace;
                        return;
                    }
                });

                return { url: targetFace ? targetFace.url : '' };
            };

            imageUrlSource = new this.Marzipano.ImageUrlSource(
                imageUrlFunction
            );
            geometry = new this.Marzipano.CubeGeometry(levels);
            limiter = this.Marzipano.RectilinearView.limit.traditional(
                faceSize * 4,
                (100 * Math.PI) / 180,
                (120 * Math.PI) / 180
            );
            view = new this.Marzipano.RectilinearView(viewParams, limiter);
        }

        let scene = this.findSceneById(zone.zone_id);

        // If scene image has been changed then recreate the scene
        if (scene) {
            const equiImageChanged =
                scene.data.equirect_image_url &&
                scene.data.equirect_image_url !== zone.equirect_image_url;
            const flatImageChanged =
                scene.data.flat_image_url &&
                scene.data.flat_image_url !== zone.flat_image_url;
            if (equiImageChanged || flatImageChanged) {
                this.deleteScene(scene);
                scene = null;
            }
        }

        if (scene) {
            // If scene already exists then update items that might have changed
            scene.data.initialViewParameters = viewParams;
            scene.data.name = zone.display_name;
            scene.data.id = zone.zone_id;
        } else {
            scene = this.addScene(
                zone.display_name,
                zone.zone_id,
                zone.zone_id,
                imageUrlSource,
                geometry,
                view,
                viewParams
            );
            scene.data.equirect_image_url = zone.equirect_image_url;
            scene.data.flat_image_url = zone.flat_image_url;
        }
    }

    addScene(
        name,
        id,
        zoneId,
        imageUrlSource,
        geometry,
        view,
        initialViewParameters
    ) {
        const newScene = {
            data: {
                id: id,
                zone_id: zoneId,
                initialViewParameters: initialViewParameters,
                name: name,
            },
            hotspots: [],
            scene: this.createScene(id, imageUrlSource, geometry, view),
        };
        this.scenes.push(newScene);
        return newScene;
    }

    createScene(id, imageSource, geometry, view) {
        var scene = null;
        if (imageSource) {
            scene = this.viewer.createScene({
                source: imageSource,
                geometry: geometry,
                view: view,
                pinFirstLevel: true,
            });
        }

        return scene;
    }

    updateSceneLinkHotspot(scene, hotspot, userAdded = false) {
        const existHotspot = scene.hotspots.find(
            (hs) => hs.nav_link_id === hotspot.nav_link_id
        );

        if (existHotspot) {
            existHotspot.target = hotspot.target;
            existHotspot.rotation = hotspot.rotation;
            existHotspot.scale = hotspot.scale;
            existHotspot.yaw = hotspot.yaw;
            existHotspot.pitch = hotspot.pitch;
            existHotspot.flat_x = hotspot.flat_x;
            existHotspot.flat_y = hotspot.flat_y;
            existHotspot.hotspot.setPosition({
                yaw: hotspot.yaw,
                pitch: hotspot.pitch,
                x: hotspot.flat_x,
                y: hotspot.flat_y,
            });
            return;
        }

        var element = this._createLinkHotspotElement(scene.data.id, hotspot);
        if (element) {
            var hotspotObj = scene.scene
                .hotspotContainer()
                .createHotspot(element, {
                    yaw: hotspot.yaw,
                    pitch: hotspot.pitch,
                    x: hotspot.flat_x,
                    y: hotspot.flat_y,
                });

            scene.hotspots.push({
                nav_link_id: hotspot.nav_link_id,
                hotspot: hotspotObj,
                rotation: hotspot.rotation,
                scale: hotspot.scale,
                yaw: hotspot.yaw,
                pitch: hotspot.pitch,
                x: hotspot.flat_x,
                y: hotspot.flat_y,
                target: hotspot.target,
            });

            // if (userAdded && this.onLinkHotspotAdded !== undefined) {
            //     const activeScene = this._activeScene();
            //     hotspot.target = activeScene.data.id;
            //     hotspot.zone_id = activeScene.data.zone_id;
            //     this.onLinkHotspotAdded(hotspot, hotspotObj);
            // }
        }

        return;
    }

    updateSceneInfoHotspot(scene, hotspot, userAdded = false) {
        const existHotspot = scene.hotspots.find(
            (hs) =>
                hs.item_id &&
                (hs.item_id === hotspot.item_id ||
                    hs.item_id === hotspot.local_item_id)
        );

        if (existHotspot) {
            existHotspot.local_item_id = hotspot.local_item_id;
            existHotspot.rotation = hotspot.rotation;
            existHotspot.scale = hotspot.scale;
            existHotspot.yaw = hotspot.yaw;
            existHotspot.pitch = hotspot.pitch;
            existHotspot.flat_x = hotspot.flat_x;
            existHotspot.flat_y = hotspot.flat_y;
            existHotspot.hotspot.setPosition({
                yaw: hotspot.yaw,
                pitch: hotspot.pitch,
                x: hotspot.flat_x,
                y: hotspot.flat_y,
            });

            // const foundHotspot = this.reactHotspots?.find(reactHotspot => {
            //     return reactHotspot.item_id === existHotspot.item_id;
            // });

            return;
        }

        var element = this._createInfoHotspotElement(scene.data.id, hotspot);
        if (element) {
            var hotspotObj = scene.scene
                .hotspotContainer()
                .createHotspot(element, {
                    yaw: hotspot.yaw,
                    pitch: hotspot.pitch,
                    x: hotspot.flat_x,
                    y: hotspot.flat_y,
                });

            scene.hotspots.push({
                item_id: hotspot.item_id,
                hotspot: hotspotObj,
                rotation: hotspot.rotation,
                scale: hotspot.scale,
                yaw: hotspot.yaw,
                pitch: hotspot.pitch,
                x: hotspot.flat_x,
                y: hotspot.flat_y,
            });

            // if (userAdded && this.onHotspotAdded !== undefined) {
            //     this.onHotspotAdded(hotspot, hotspotObj);
            // }

            element.addEventListener('click', () => {
                if (
                    !this.disableClick &&
                    (hotspot.pano_id || hotspot.item_id)
                ) {
                    window.handleHotspot(
                        hotspot.zone_id,
                        hotspot.pano_id ? hotspot.pano_id : hotspot.item_id
                    );
                }
            });
        }

        return element;
    }

    disableControls() {
        this.viewer.controls().disable();
    }
    enableControls() {
        this.viewer.controls().enable();
    }

    updateSceneHotSpots(scene, linkHotspots, infoHotspots, tagIdFilter) {
        // Create or update link hotspots.
        var visibleLinkHotspots = [];
        var zoneTags = tagIdFilter?.zone_tags.map((tag) => tag.tag_id);
        linkHotspots?.forEach(function (hotspot) {
            const zone = this.zoneItems.find(
                (zone) => zone.zone_id === hotspot.target_zone_id
            );

            if (
                zoneHasTag(zoneTags, zone) &&
                (this.config.user.role.toUpperCase() === 'ADMIN' ||
                    isZoneVisible(this.config.user.audienceIds, zone))
            ) {
                visibleLinkHotspots.push(hotspot);
                this.updateSceneLinkHotspot(scene, hotspot);
            }
        }, this);

        // Remove deleted link hotspots
        scene.hotspots = scene.hotspots.filter((hotspot) => {
            if (hotspot.nav_link_id) {
                const index = visibleLinkHotspots?.findIndex(
                    (newHotspot) =>
                        newHotspot.nav_link_id === hotspot.nav_link_id
                );
                if (index === -1) {
                    this.removeLinkHotspot(hotspot);
                    return false;
                }
            }
            return true;
        });

        var visibleHotspots = [];
        // Create info hotspots.
        var itemTags = tagIdFilter?.item_tags.map((tag) => tag.tag_id);
        infoHotspots?.forEach(function (hotspot) {
            let hasLocation = hotspot.yaw || hotspot.flat_x;
            if (
                hasLocation &&
                hotSpotHasTag(
                    itemTags,
                    hotspot,
                    this.hotspotItems,
                    this.showOnlyByFilter
                ) &&
                (this.config.user.role.toUpperCase() === 'ADMIN' ||
                    isHotSpotVisible(
                        this.config.user.audienceIds,
                        hotspot,
                        this.hotspotItems
                    ))
            ) {
                visibleHotspots.push(hotspot);
                this.updateSceneInfoHotspot(scene, hotspot);
            }
        }, this);

        // Remove deleted item hotspots
        scene.hotspots = scene.hotspots.filter((hotspot) => {
            if (hotspot.item_id) {
                const index = visibleHotspots?.findIndex(
                    (newHotspot) => newHotspot.item_id === hotspot.item_id
                );
                if (index === -1) {
                    this.removeItemHotspot(hotspot);
                    return false;
                }
            }
            return true;
        });
    }

    switchScene(scene) {
        if (!scene) {
            console.log(`MarzipanoManager: switch scene was null`);
            return false;
        }
        if (!scene.scene) {
            console.log(`MarzipanoManager: switch Scene.scene was null`);
            return false;
        }

        const currentScene = this.viewer.scene();

        if (scene.scene === currentScene) {
            return true;
        }

        this._stopAutorotate();
        scene.scene.view().setParameters(scene.data.initialViewParameters);
        scene.scene.switchTo();
        this._startAutorotate();
        this._updateSceneName(scene);
        // setTimeout(() => {
        //     console.log('MarzipanoManager: Updating size after switch scene');
        //     scene.scene.viewer().updateSize();
        // }, 500);

        return true;
    }

    switchSceneById(sceneId) {
        const scene = this.findSceneById(sceneId);
        if (scene !== undefined) {
            return this.switchScene(scene);
        }
        return false;
    }

    deleteView() {
        try {
            this.viewer.destroyAllScenes();
        } catch {
            // TODO: Investigate issue where exception is thrown by
            // marzipano code deleting hotspots nodes that don't belong
            // to the parent
        }
        this.hotspotItems = {};
        this.zoneItems = {};
        this.scenes = [];
    }

    updateView() {
        this.viewer.updateSize();
    }

    resetViewParams() {
        const scene = this._activeScene();
        scene.scene.view().setParameters(scene.data.initialViewParameters);
    }

    deleteScene(scene) {
        this.viewer.destroyScene(scene.scene);
        for (var i = 0; i < this.scenes.length; i++) {
            if (this.scenes[i] === scene) {
                this.scenes.splice(i, 1);
                break;
            }
        }
    }

    findSceneById(id) {
        for (var i = 0; i < this.scenes.length; i++) {
            if (this.scenes[i].data.id === id) {
                return this.scenes[i];
            }
        }
        return null;
    }

    findSceneDataById(id) {
        for (var i = 0; i < this.data.scenes.length; i++) {
            if (this.data.scenes[i].id === id) {
                return this.data.scenes[i];
            }
        }
        return null;
    }

    findSceneFromViewScene(viewScene) {
        for (var sceneKey in this.scenes) {
            const scene = this.scenes[sceneKey];
            if (scene.scene === viewScene) {
                return scene;
            }
        }
        return null;
    }

    switchToHomeScene() {
        if (this.scenes.length > 0) {
            if (this.initialZoneId) {
                this.switchSceneById(this.initialZoneId);
            } else {
                this.switchScene(
                    this.initialZoneId ? this.initialZoneId : this.scenes[0]
                );
            }
        }
    }

    // PRIVATE METHODS

    _activeScene() {
        return this.findSceneFromViewScene(this.viewer.scene());
    }

    _sanitize(s) {
        return s
            .replace('&', '&amp;')
            .replace('<', '&lt;')
            .replace('>', '&gt;');
    }

    _updateSceneName(scene) {
        //this.sceneNameElement.innerHTML = this._sanitize(scene.data.name);
    }

    _startAutorotate() {
        if (!this.data.settings.autorotateEnabled) {
            return;
        }

        if (!this.autorotateToggleElement.classList.contains('enabled')) {
            return;
        }
        this.viewer.startMovement(this.autorotate);
        this.viewer.setIdleMovement(3000, this.autorotate);
    }

    _stopAutorotate() {
        if (!this.data.settings.autorotateEnabled) {
            return;
        }

        this.viewer.stopMovement();
        this.viewer.setIdleMovement(Infinity);
    }

    _toggleAutorotate() {
        if (!this.data.settings.autorotateEnabled) {
            return;
        }

        if (this.autorotateToggleElement.classList.contains('enabled')) {
            this.autorotateToggleElement.classList.remove('enabled');
            this._stopAutorotate();
        } else {
            this.autorotateToggleElement.classList.add('enabled');
            this._startAutorotate();
        }
    }

    removeLinkHotspot(hotspot) {
        const scene = this._activeScene();
        if (scene) {
            const existHotspot = scene.hotspots.find(
                (hs) => hs.nav_link_id === hotspot.nav_link_id
            );
            scene.scene.hotspotContainer().destroyHotspot(existHotspot.hotspot);
        }
    }

    removeItemHotspot(hotspot) {
        const scene = this._activeScene();
        const existHotspot = scene.hotspots.find(
            (hs) => hs.item_id === hotspot.item_id
        );
        scene.scene.hotspotContainer().destroyHotspot(existHotspot.hotspot);
    }

    _createLinkHotspotElement(zoneId, hotspot) {
        if (this.reactHotspots) {
            const hotspots = this.reactHotspots[zoneId];
            if (hotspots) {
                const foundHotspot = hotspots.find((reactHotspot) => {
                    return reactHotspot.nav_link_id === hotspot.nav_link_id;
                });

                if (foundHotspot) {
                    // Prevent touch and scroll events from reaching the parent element.
                    // This prevents the view control logic from interfering with the hotspot.
                    this._stopTouchAndScrollEventPropagation(
                        foundHotspot.element
                    );
                    return foundHotspot.element;
                }
            }
        }
        return null;
    }

    _createInfoHotspotElement(zoneId, hotspot) {
        if (this.reactHotspots) {
            const hotspots = this.reactHotspots[zoneId];
            if (hotspots) {
                const foundHotspot = hotspots.find((reactHotspot) => {
                    return reactHotspot.item_id === hotspot.item_id;
                });

                if (foundHotspot) {
                    // Prevent touch and scroll events from reaching the parent element.
                    // This prevents the view control logic from interfering with the hotspot.
                    this._stopTouchAndScrollEventPropagation(
                        foundHotspot.element
                    );
                    return foundHotspot.element;
                }
            }
        }
        return null;
    }

    // Prevent touch and scroll events from reaching the parent element.
    _stopTouchAndScrollEventPropagation(element) {
        var eventList = [
            'touchstart',
            'touchmove',
            'touchend',
            'touchcancel',
            'wheel',
            'mousewheel',
        ];
        for (var i = 0; i < eventList.length; i++) {
            element.addEventListener(
                eventList[i],
                function (event) {
                    event.stopPropagation();
                },
                { passive: true }
            );
        }
    }
    _resetViewer(element) {
        if (this.panoElement) {
            while (this.panoElement.firstChild) {
                this.panoElement.removeChild(this.panoElement.lastChild);
            }
        }
    }
}

export { MarzipanoManager };
