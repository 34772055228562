import { useEffect, useState } from 'react';
import clsx from 'clsx';
import Dropzone from 'react-dropzone';
import { BiVideo } from 'react-icons/bi';
import { MdClose } from 'react-icons/md';
import { Field, ErrorMessage } from 'formik';
import { HiOutlineMail } from 'react-icons/hi';
import { FiPhone, FiUpload } from 'react-icons/fi';
import { IoCloseCircleSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import {
    Chip,
    RadioGroup,
    FormControlLabel,
    Radio,
    Badge,
    Avatar,
    Divider,
} from '@mui/material';

import { LinkTypes } from '_helpers';
import { makeFormStyles } from '../../styles';
import { VideoConfigs } from './VideoConfigs';
import { ImageConfigs } from './ImageConfigs';
import { ConfigHeader } from './ConfigSourceHeader';
import { mapVideoContentURL } from 'GeminiViewerComponent/_helpers';
import { FormSelectField, FormTextField } from 'components/TextField';
import { panelStyles } from 'GeminiViewerComponent/components/styles';
import { SelectionAutocomplete } from 'components/_Autocomplete/SelectionAutocomplete';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { ProcedureStartEndNodeField } from 'forms/ContentVersionForm/components/ProcedureStartEndNodeField';
import {
    getContentVersions,
    getContentById,
} from '_features/content/contentSlice';
import {
    getProceduresPicklist,
    selectProceduresPicklist,
    selectProceduresPicklistLoadingStatus,
} from '_features/picklists/pickListsSlice';
import {
    getProcedureById,
    getProcedureOriginalData,
} from '_features/procedures/proceduresSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { ContentChapterFields } from './ContentChapterFields';

export const ContentFormFields = ({
    contentId,
    editMode,
    createMode,
    readOnlyMode,
    values,
    getContentTypes,
    getDefaultContentTypeId,
    initValues,
    setInitValues,
    handleToggle,
    getContentMaxSize,
    onDrop,
    onDropError,
    validateVideoCompression,
    handleViewLink,
    handleCreateContentClick,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    imageSettings,
    setImageSettings,
    setIsVideoCompressionEnable,
    configValues,
    configFields,
    validationTypes,
}) => {
    const dispatch = useDispatch();
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);
    const panelClasses = panelStyles(theme);
    const [defaultConfigValues, setDefaultConfigValues] = useState({});
    const contentVersions = useSelector(getContentVersions);
    const sortedContentVersion = [...contentVersions].sort(
        (a, b) => a.version - b.version
    );
    const procedureOriginalData = useSelector(getProcedureOriginalData);
    const content = useSelector((state) => getContentById(state, contentId));
    const procedureNodes =
        procedureOriginalData?.nodes?.map((node) => {
            return { id: node.id, title: node?.title };
        }) || [];

    const [procedureStartEndNode, setProcedureStartEndNode] = useState({
        procedureStartNode:
            procedureNodes?.find(
                (node) => node?.id === values?.procedure_start_node
            ) || null,
        procedureEndNode:
            procedureNodes?.find(
                (node) => node?.id === values?.procedure_end_node
            ) || null,
    });

    useEffect(() => {
        if (Array.isArray(configValues)) {
            let defaultConfig = {};
            var imageAndVideoConfigKeys = [
                'convert_image_to_jpeg',
                'compress_videos',
                'enable_hls',
                'image_compression_percent',
                'max_image_width',
                'max_video_height',
                'transcribe_videos',
                'transcribe_language',
            ];

            let audioVideoConfigFields = configFields?.filter((fld) =>
                imageAndVideoConfigKeys.includes(fld.key)
            );

            audioVideoConfigFields.map((fld) => {
                defaultConfig = {
                    ...defaultConfig,
                    [fld.key]: configValues.find(
                        (data) => data.config_field_id == fld.config_field_id
                    ),
                };
            });

            setDefaultConfigValues(defaultConfig);
        }
    }, [configValues, configFields]);

    useEffect(() => {
        setFieldValue('compress_videos', null);
        setFieldValue('enable_hls', null);
        setFieldValue('max_video_height', null);
        setFieldValue('convert_image_to_jpeg', null);
        setFieldValue('image_compression_percent', null);
        setFieldValue('max_image_width', null);
        setFieldValue('transcribe_videos', null);
        setFieldValue('transcribe_language', null);
        setFieldValue('defaultConfigValues', defaultConfigValues);

        if (Object.keys(defaultConfigValues).length > 0) {
            if (values.link_type_id === LinkTypes.Video.id) {
                setFieldValue(
                    'compress_videos',
                    defaultConfigValues.compress_videos.value === 'true'
                        ? true
                        : false
                );
                setFieldValue(
                    'enable_hls',
                    defaultConfigValues.enable_hls.value === 'true'
                        ? true
                        : false
                );
                setFieldValue(
                    'max_video_height',
                    defaultConfigValues.max_video_height.value
                );
                setFieldValue(
                    'transcribe_videos',
                    defaultConfigValues?.transcribe_videos?.value === 'true'
                        ? true
                        : false
                );
                setFieldValue(
                    'transcribe_language',
                    defaultConfigValues.transcribe_language.value
                );
            }
            if (values.link_type_id === LinkTypes.Image.id) {
                setFieldValue(
                    'convert_image_to_jpeg',
                    defaultConfigValues.convert_image_to_jpeg.value === 'true'
                        ? true
                        : false
                );
                setFieldValue(
                    'image_compression_percent',
                    defaultConfigValues.image_compression_percent.value
                );
                setFieldValue(
                    'max_image_width',
                    defaultConfigValues.max_image_width.value
                );
            }
        }
    }, [values.link_type_id, defaultConfigValues]);

    useEffect(() => {
        (async () => {
            if (values?.procedure_id) {
                let procedureNodes = [];
                if (
                    values?.procedure_id !== procedureOriginalData?.procedure_id
                ) {
                    dispatch(activateLoading());
                    const resultAction = await dispatch(
                        getProcedureById(values.procedure_id)
                    );

                    const procedure = JSON.parse(
                        resultAction.payload?.procedure_json
                    );
                    procedureNodes =
                        procedure?.nodes?.map((node) => {
                            return { id: node.id, title: node?.title };
                        }) || [];

                    dispatch(deactivateLoading());
                } else {
                    procedureNodes =
                        procedureOriginalData?.nodes?.map((node) => {
                            return { id: node.id, title: node?.title };
                        }) || [];
                }
                setProcedureStartEndNode({
                    procedureStartNode:
                        procedureNodes?.find(
                            (node) => node?.id === values?.procedure_start_node
                        ) || null,
                    procedureEndNode:
                        procedureNodes?.find(
                            (node) => node?.id === values?.procedure_end_node
                        ) || null,
                });
            }
        })();
    }, [
        values.procedure_id,
        values?.procedure_start_node,
        values?.procedure_end_node,
    ]);

    const handleVersionChange = (e) => {
        let data = contentVersions.find(
            (obj) => obj.content_version_id === +e.target.value
        );
        if (data) {
            let chapters = [];
            if (data?.chapters && data?.chapters.length > 0) {
                chapters = [...data.chapters];
                chapters?.sort((a, b) =>
                    a?.chapter_id > b?.chapter_id ? 1 : -1
                );
            }
            setInitValues({
                ...initValues,
                toggleFormType: data?.embed_data
                    ? ''
                    : data?.original_file_name
                    ? 'file'
                    : 'url',
                url:
                    data?.embed_data ||
                    data?.procedure_id ||
                    data?.original_file_name
                        ? ''
                        : data?.url,
                image_url: mapVideoContentURL(data),
                content_version_type: data?.procedure_id
                    ? 4
                    : data?.embed_data
                    ? 3
                    : data?.original_file_name
                    ? 2
                    : 1,
                procedure_start_node: data?.procedure_start_node,
                procedure_end_node: data?.procedure_end_node,
                chapters: chapters,
                version: data.content_version_id,
                content_data: null,
                img_name: data?.original_file_name,
                embed_data: data?.embed_data,
                procedure_id: data?.procedure_id,
                overlay_text:
                    (data?.overlay_text
                        ? JSON.parse(data?.overlay_text)
                        : '') ?? null,
            });
        }
    };

    const getConfigOptionsArray = (
        configFields,
        validationTypes,
        defaultConfigValues,
        key = null
    ) => {
        if (
            Array.isArray(configFields) &&
            Array.isArray(validationTypes) &&
            Object.keys(defaultConfigValues ?? {})?.length > 0 &&
            key
        ) {
            let configFieldId = defaultConfigValues?.[key]?.config_field_id;
            if (configFieldId) {
                let validationTypeId = configFields.find(
                    (fld) => fld.config_field_id === configFieldId
                )?.validation_type_id;
                if (validationTypeId) {
                    let options = validationTypes.find(
                        (valid) =>
                            valid?.validation_type_id === validationTypeId
                    )?.options_array;
                    if (Array.isArray(options)) {
                        return options;
                    }
                }
            }
        }
        return [];
    };

    return (
        <>
            {editMode &&
                values.link_type_id !== LinkTypes.SubjectMatterExpert.id && (
                    <FormSelectField
                        name="version"
                        entities={sortedContentVersion}
                        onChange={handleVersionChange}
                        valueProperty="content_version_id"
                        nameProperty="version"
                        keyProperty="content_version_id"
                        namePrefix="Version "
                    />
                )}
            {values.link_type_id !== LinkTypes.Procedure.id &&
                values.link_type_id !== LinkTypes.Embed.id &&
                values.link_type_id !== LinkTypes.SubjectMatterExpert.id && (
                    <>
                        <RadioGroup
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '150px',
                            }}
                            name="type"
                            value={values.toggleFormType}
                            onChange={(e) =>
                                handleToggle(e.target.value, setFieldValue)
                            }
                            disabled={readOnlyMode}
                        >
                            <FormControlLabel
                                value="url"
                                control={<Radio color="primary" />}
                                label="URL"
                                disabled={readOnlyMode}
                            />
                            <FormControlLabel
                                value="file"
                                control={<Radio color="primary" />}
                                label="File"
                                disabled={readOnlyMode}
                            />
                        </RadioGroup>
                        {values.toggleFormType === 'url' && (
                            <>
                                <FormTextField
                                    label="Content URL"
                                    name="url"
                                    placeholder={`Content URL${
                                        createMode && values.link_type_id !== ''
                                            ? '*'
                                            : ''
                                    }`}
                                    disabled={readOnlyMode}
                                />
                                <FormSelectField
                                    name="content_type_id"
                                    label="Select Url Type"
                                    entities={getContentTypes(
                                        +values?.link_type_id
                                    )}
                                    valueProperty="id"
                                    nameProperty="label"
                                    keyProperty="label"
                                    defaultOptionName="Select Url Type"
                                    defaultOptionValue
                                    defaultOptionDisabled
                                />
                            </>
                        )}
                        {values.toggleFormType === 'file' && (
                            <>
                                {[
                                    LinkTypes.Video.id,
                                    LinkTypes.Image.id,
                                ].includes(values.link_type_id) === true &&
                                    ((values.link_type_id ===
                                        LinkTypes.Image.id &&
                                        values?.raw_file &&
                                        /(?:\.([^.]+))?$/.exec(
                                            values?.raw_file?.name
                                        )[1] !== 'svg') ||
                                        values.link_type_id ===
                                            LinkTypes.Video.id) &&
                                    Object.keys(defaultConfigValues).length >
                                        0 && (
                                        <fieldset
                                            style={{
                                                border: '1px solid #bdc3c7',
                                                borderRadius: '5px',
                                                padding: '10px',
                                                color: '#000000',
                                            }}
                                        >
                                            {!editMode ||
                                                (values?.raw_file && (
                                                    <ConfigHeader
                                                        link_type_id={
                                                            values.link_type_id
                                                        }
                                                    />
                                                ))}

                                            {values.link_type_id ===
                                                LinkTypes.Video.id && (
                                                <VideoConfigs
                                                    editMode={editMode}
                                                    defaultConfigValues={
                                                        defaultConfigValues
                                                    }
                                                    values={values}
                                                    setFieldValue={
                                                        setFieldValue
                                                    }
                                                    configFields={configFields}
                                                    validationTypes={
                                                        validationTypes
                                                    }
                                                    readOnlyMode={readOnlyMode}
                                                    setIsVideoCompressionEnable={
                                                        setIsVideoCompressionEnable
                                                    }
                                                    getConfigOptionsArray={
                                                        getConfigOptionsArray
                                                    }
                                                />
                                            )}
                                            {values.link_type_id ===
                                                LinkTypes.Image.id && (
                                                <ImageConfigs
                                                    defaultConfigValues={
                                                        defaultConfigValues
                                                    }
                                                    values={values}
                                                    setFieldValue={
                                                        setFieldValue
                                                    }
                                                    configFields={configFields}
                                                    validationTypes={
                                                        validationTypes
                                                    }
                                                    readOnlyMode={readOnlyMode}
                                                    imageSettings={
                                                        imageSettings
                                                    }
                                                    setImageSettings={
                                                        setImageSettings
                                                    }
                                                    getConfigOptionsArray={
                                                        getConfigOptionsArray
                                                    }
                                                />
                                            )}
                                        </fieldset>
                                    )}
                                <Field
                                    className={classes.input}
                                    name="content_data"
                                >
                                    {({
                                        field: { value },
                                        form: { setFieldValue },
                                    }) => (
                                        <div
                                            className={
                                                panelClasses.dropzoneContainer
                                            }
                                        >
                                            <Dropzone
                                                maxSize={getContentMaxSize(
                                                    +(values.link_type_id !== ''
                                                        ? values?.link_type_id
                                                        : content?.default_link_type_id)
                                                )}
                                                accept={getContentTypes(
                                                    +(values.link_type_id !== ''
                                                        ? values?.link_type_id
                                                        : content?.default_link_type_id)
                                                ).map((at) => at.extension)}
                                                maxFiles={1}
                                                multiple={false}
                                                onDropAccepted={async (
                                                    files
                                                ) => {
                                                    if (!readOnlyMode) {
                                                        await onDrop({
                                                            file: files[0],
                                                            setFieldValue:
                                                                setFieldValue,
                                                            values: values,
                                                        });
                                                    }
                                                }}
                                                onDropRejected={(err) =>
                                                    onDropError({
                                                        err,
                                                        setFieldError:
                                                            setFieldError,
                                                        setFieldTouched:
                                                            setFieldTouched,
                                                    })
                                                }
                                                validator={(file) =>
                                                    validateVideoCompression(
                                                        file,
                                                        values.link_type_id
                                                    )
                                                }
                                                noClick={
                                                    editMode
                                                        ? readOnlyMode
                                                        : false
                                                }
                                            >
                                                {({
                                                    getRootProps,
                                                    getInputProps,
                                                }) => (
                                                    <div
                                                        {...getRootProps()}
                                                        className={clsx(
                                                            panelClasses.dropzoneArea
                                                        )}
                                                        style={{
                                                            backgroundImage: `url(${values.image_url})`,
                                                            backgroundRepeat:
                                                                'no-repeat',
                                                            backgroundSize:
                                                                'contain',
                                                            backgroundPosition:
                                                                'center center',
                                                        }}
                                                    >
                                                        <input
                                                            {...getInputProps()}
                                                        />
                                                        {!values.image_url && (
                                                            <div
                                                                className={
                                                                    panelClasses.dropzoneText
                                                                }
                                                            >
                                                                Upload Content
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </Dropzone>
                                            <div
                                                className={
                                                    panelClasses.previewContainer
                                                }
                                            >
                                                {values?.img_name && (
                                                    <Chip
                                                        className={
                                                            panelClasses.previewUploadFileName
                                                        }
                                                        color="secondary"
                                                        size="small"
                                                        deleteIcon={
                                                            <MdClose className="react-icon" />
                                                        }
                                                        onDelete={() => {
                                                            if (!readOnlyMode) {
                                                                setFieldValue(
                                                                    'content_data',
                                                                    null
                                                                );
                                                                setFieldValue(
                                                                    'img_name',
                                                                    null
                                                                );
                                                                setFieldValue(
                                                                    'raw_file',
                                                                    null
                                                                );
                                                                setFieldValue(
                                                                    'image_url',
                                                                    ''
                                                                );
                                                            }
                                                        }}
                                                        label={values?.img_name}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </Field>
                                <ErrorMessage
                                    className={classes.inputErr}
                                    name="content_data"
                                    component="div"
                                />
                            </>
                        )}
                    </>
                )}
            {values.link_type_id === LinkTypes.Procedure.id && (
                <>
                    <Field className={classes.input} name="procedure_id">
                        {({
                            field: { value },
                            form: { setFieldValue },
                            meta: { error },
                        }) => (
                            <SelectionAutocomplete
                                title="Procedure"
                                placeholder="Procedure"
                                keyProperty="procedure_id"
                                nameProperty="display_name"
                                entityIds={value}
                                setEntityIds={setFieldValue}
                                entitySelector={selectProceduresPicklist}
                                fetchEntityPage={getProceduresPicklist}
                                formField={'procedure_id'}
                                multiSelection={false}
                                entityStatusSelector={
                                    selectProceduresPicklistLoadingStatus
                                }
                                readOnly={readOnlyMode}
                                fieldValidation={!!error}
                                onCustomChange={() => {
                                    setFieldValue('procedure_start_node', null);
                                    setFieldValue('procedure_end_node', null);
                                    setProcedureStartEndNode({
                                        procedureStartNode: null,
                                        procedureEndNode: null,
                                    });
                                }}
                            />
                        )}
                    </Field>
                    <ErrorMessage
                        className={classes.inputErr}
                        name="procedure_id"
                        component="div"
                    />
                    <ProcedureStartEndNodeField
                        procedureStartEndNode={procedureStartEndNode}
                        setProcedureStartEndNode={setProcedureStartEndNode}
                    />
                    {!getDefaultContentTypeId(+values?.link_type_id) && (
                        <>
                            <Field
                                className={classes.input}
                                as="select"
                                name="content_type_id"
                                disabled={readOnlyMode}
                            >
                                <option value="" defaultValue disabled>
                                    Select Url Type
                                </option>
                                {getContentTypes(+values?.link_type_id).map(
                                    (at) => (
                                        <option
                                            key={at.label}
                                            value={at.id}
                                            defaultValue={at.id === 1}
                                        >
                                            {at.label}
                                        </option>
                                    )
                                )}
                            </Field>
                            <ErrorMessage
                                className={classes.inputErr}
                                name="content_type_id"
                                component="div"
                            />
                        </>
                    )}
                </>
            )}
            {values.link_type_id === LinkTypes.Embed.id && (
                <>
                    <Field
                        margin="dense"
                        as="textarea"
                        variant="outlined"
                        placeholder="Embed Data"
                        className={`${classes.materialTextarea}`}
                        name="embed_data"
                        rows={5}
                        style={{ marginTop: '10px' }}
                        disabled={readOnlyMode}
                    />
                    <Field
                        className={classes.input}
                        as="select"
                        name="content_type_id"
                        disabled={readOnlyMode}
                    >
                        <option value="" defaultValue disabled>
                            Select Url Type
                        </option>
                        {getContentTypes(+values?.link_type_id).map((at) => (
                            <option
                                key={at.label}
                                value={at.id}
                                defaultValue={at.id === 1}
                            >
                                {at.label}
                            </option>
                        ))}
                    </Field>
                    <ErrorMessage
                        className={classes.inputErr}
                        name="content_type_id"
                        component="div"
                    />
                </>
            )}
            {values.link_type_id === LinkTypes.SubjectMatterExpert.id && (
                <>
                    <div className={classes.smeTopInputs}>
                        <div
                            className={clsx({
                                [classes.avatarHidden]: !values?.image_url,
                            })}
                        >
                            <Badge
                                overlap="circular"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                badgeContent={
                                    <IoCloseCircleSharp
                                        className={classes.removeSmeImage}
                                        onClick={() => {
                                            setFieldValue('content_data', null);
                                            setFieldValue('img_name', null);
                                            setFieldValue('image_url', null);
                                        }}
                                    />
                                }
                            >
                                <Avatar
                                    className={clsx(classes.smeAvatar)}
                                    alt={'Avatar'}
                                    src={values?.image_url}
                                />
                            </Badge>
                        </div>
                        <Field className={classes.input} name="content_data">
                            {({
                                field: { value },
                                form: { setFieldValue },
                            }) => (
                                <div
                                    className={clsx(classes.smeAvatar, {
                                        [classes.avatarHidden]:
                                            values?.image_url,
                                    })}
                                >
                                    <Dropzone
                                        className={classes.smeDropzone}
                                        maxSize={getContentMaxSize(
                                            +(createMode &&
                                            values.link_type_id !== ''
                                                ? values?.link_type_id
                                                : content?.default_link_type_id)
                                        )}
                                        accept={getContentTypes(
                                            LinkTypes.Image.id
                                        ).map((at) => at.extension)}
                                        maxFiles={1}
                                        multiple={false}
                                        onDropAccepted={async (files) => {
                                            if (!readOnlyMode) {
                                                await onDrop({
                                                    file: files[0],
                                                    setFieldValue:
                                                        setFieldValue,
                                                    values: values,
                                                });
                                            }
                                        }}
                                        onDropRejected={(err) =>
                                            onDropError({
                                                err,
                                                setFieldError: setFieldError,
                                                setFieldTouched:
                                                    setFieldTouched,
                                            })
                                        }
                                        validator={(file) =>
                                            validateVideoCompression(
                                                file,
                                                values.link_type_id
                                            )
                                        }
                                        noClick={
                                            editMode ? readOnlyMode : false
                                        }
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <div
                                                {...getRootProps()}
                                                className={classes.smeDropzone}
                                            >
                                                <input {...getInputProps()} />
                                                {!values.image_url && (
                                                    <div
                                                        className={
                                                            classes.smeDropzoneText
                                                        }
                                                    >
                                                        <FiUpload
                                                            className={clsx(
                                                                classes.smeInputIcon,
                                                                classes.fiFileUpload
                                                            )}
                                                        />
                                                        <div>Upload</div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </Dropzone>
                                    <div
                                        className={
                                            panelClasses.previewContainer
                                        }
                                    >
                                        {values?.img_name && (
                                            <Chip
                                                className={
                                                    panelClasses.previewUploadFileName
                                                }
                                                color="secondary"
                                                size="small"
                                                deleteIcon={
                                                    <MdClose className="react-icon" />
                                                }
                                                onDelete={() => {
                                                    if (!readOnlyMode) {
                                                        setFieldValue(
                                                            'content_data',
                                                            null
                                                        );
                                                        setFieldValue(
                                                            'img_name',
                                                            null
                                                        );
                                                        setFieldValue(
                                                            'image_url',
                                                            ''
                                                        );
                                                    }
                                                }}
                                                label={values?.img_name}
                                            />
                                        )}
                                    </div>
                                </div>
                            )}
                        </Field>
                        <div className={classes.fieldWrapper}>
                            <FormTextField
                                label="Name"
                                className={classes.smeMaterialInput}
                                name="sme_name"
                                disabled={readOnlyMode}
                                onChange={(e) => {
                                    setFieldValue(
                                        'display_name',
                                        e.target.value
                                    );
                                    setFieldValue('sme_name', e.target.value);
                                }}
                                placeholder={`Name ${
                                    values.sme_name === '' ? '*' : ''
                                }`}
                            />
                            <FormTextField
                                label="Title"
                                className={classes.smeMaterialInput}
                                name="sme_title"
                                disabled={readOnlyMode}
                            />
                        </div>
                    </div>
                    <ErrorMessage
                        className={classes.inputErr}
                        name="content_data"
                        component="div"
                    />
                    <Divider />
                    <div className={classes.smeInputContainer}>
                        <HiOutlineMail className={classes.smeInputIcon} />
                        <div className={classes.smeInput}>
                            <label className={classes.smeLabel}>Email</label>
                            <FormTextField
                                placeholder={`Email ${
                                    values.sme_email === '' ? '*' : ''
                                }`}
                                className={classes.smeMaterialInput}
                                name="sme_email"
                                disabled={readOnlyMode}
                            />
                        </div>
                    </div>
                    <Divider />
                    <div className={classes.smeInputContainer}>
                        <FiPhone className={classes.smeInputIcon} />
                        <div className={classes.smeInput}>
                            <label className={classes.smeLabel}>Phone</label>
                            <FormTextField
                                placeholder="Phone"
                                className={classes.smeMaterialInput}
                                name="sme_phone"
                                type="number"
                                disabled={readOnlyMode}
                            />
                        </div>
                    </div>
                    <Divider />
                    <div className={classes.smeInputContainer}>
                        <BiVideo className={classes.smeInputIcon} />
                        <div className={classes.smeInput}>
                            <label className={classes.smeLabel}>Zoom ID</label>
                            <FormTextField
                                placeholder="Zoom ID"
                                className={classes.smeMaterialInput}
                                name="sme_zoom"
                                disabled={readOnlyMode}
                            />
                        </div>
                    </div>
                    <Divider />
                    <div className={classes.smeInputContainer}>
                        <BiVideo className={classes.smeInputIcon} />
                        <div className={classes.smeInput}>
                            <label className={classes.smeLabel}>Teams ID</label>
                            <FormTextField
                                placeholder="Teams ID"
                                className={classes.smeMaterialInput}
                                name="sme_teams"
                                disabled={readOnlyMode}
                            />
                        </div>
                    </div>
                </>
            )}
            <ContentChapterFields onDropError={onDropError} />
            {editMode &&
                values.link_type_id !== LinkTypes.SubjectMatterExpert.id && (
                    <>
                        <button
                            type="button"
                            className={classes.preview}
                            onClick={() => handleViewLink(content)}
                        >
                            Preview Versions
                        </button>
                        {readOnlyMode !== true && (
                            <button
                                type="button"
                                className={classes.preview}
                                onClick={() =>
                                    handleCreateContentClick(
                                        values['content_id']
                                    )
                                }
                            >
                                Add Version
                            </button>
                        )}
                    </>
                )}
        </>
    );
};
