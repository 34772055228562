import { makeStyles } from '@mui/styles';

const dtOptionPictureStyles = makeStyles(() => ({
    dtOptionPicture: {
        height: '100%',
        minHeight: '45px',
        backgroundColor: 'rgb(231, 231, 231)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        border: '1px solid rgb(228, 228, 228)',
        borderRadius: '5px',
        '&:hover': {
            border: '1px solid #5cdb95',
            backgroundColor: '#8ee4af',
        },
    },
    dtOptionImg: {
        width: '100%',
    },
}));

export { dtOptionPictureStyles };
