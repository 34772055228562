import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './styles';
import { AutoSizer } from 'react-virtualized';
import { Box } from '@mui/material';

import {
    labelHeader,
    labelCell,
    VirtualTable,
    actionCell,
    createCell,
    checkCell,
    checkHeaderCell,
} from 'components';
import { tableStyles } from 'components/_Tables/TableCells/styles';
import { EnhancedTableToolbar } from 'GeminiViewerComponent/components/EnhancedTableToolbar';
import SearchWithFilters from 'components/SearchWithFilters';
import { openPanel, setEditObjectId } from '_features/common/formSlice';
import { closePanel } from '_features/common/editPanelSlice';
import { debounce } from 'GeminiViewerComponent/_helpers/lodashUtils';

function PartCategoryTable({
    partCategories,
    pageInfo,
    partCategoryPayload,
    setPartCategoryPayload,
    sortDirection,
    sortBy,
    handleSort,
}) {
    const classes = useStyles();
    const tableClasses = tableStyles();
    const dispatch = useDispatch();
    const [selected, setSelected] = useState([]);
    const [selectedAll, setSelectedAll] = useState(false);

    const loadedRowsMap = useSelector(
        (state) => state.partCategory.loadedPartCategoryMap
    );

    const headerHeight = 40;
    const rowHeight = 70;

    const prevSelectedAll = usePrevious(selectedAll);
    if (prevSelectedAll && selectedAll) {
        if (selected.length !== partCategories.length) {
            setSelected(partCategories.map((row) => row.part_category_id));
            setSelectedAll(true);
        }
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelectedIds = partCategories.map(
                (category) => category.part_category_id
            );
            setSelected(newSelectedIds);
            setSelectedAll(true);
            return;
        }
        setSelected([]);
        setSelectedAll(false);
    };

    const handleClick = (event, part_category_id) => {
        event.stopPropagation();
        const selectedIndex = selected.indexOf(part_category_id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, part_category_id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
        setSelectedAll(selected.length === partCategories.length);
    };

    const handleRowClick = async ({ rowData }) => {
        await dispatch(closePanel({ formKey: 'partCategoryForm' }));
        if (rowData?.part_category_id) {
            await dispatch(
                setEditObjectId({
                    formKey: `partCategoryForm`,
                    object_id: rowData?.part_category_id,
                })
            );
            dispatch(
                openPanel({ formKey: `partCategoryForm`, formAction: 'Edit' })
            );
        }
    };

    const columns = [
        {
            classes: tableClasses,
            minWidth: 50,
            maxWidth: 50,
            width: 50,
            label: '',
            dataKey: 'part_category_id',
            padding: 'checkbox',
            cellLevel: 'part_category',
            numSelected: selected.length,
            rowCount: partCategories.length,
            handleClick: handleClick,
            handleSelectAllClick: handleSelectAllClick,
            isSelected: isSelected,
            component: checkCell,
            headerComponent: checkHeaderCell,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 120,
            minWidth: 120,
            label: 'Category Name',
            dataKey: 'display_name',
            padding: 'normal',
            component: labelCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 150,
            label: 'Category',
            dataKey: 'part_category_id',
            padding: 'none',
            actionCellLevel: 'part_category',
            targetPopup: 'partCategoryManagement',
            formKey: 'partCategoryForm',
            dispatch: dispatch,
            component: actionCell,
            headerComponent: createCell,
        },
    ];

    // eslint-disable-next-line no-unused-vars
    function loadMoreRows({ startIndex, stopIndex }) {}

    const debouncedSearch = useCallback(
        debounce((searchString) => {
            setPartCategoryPayload({
                ...partCategoryPayload,
                searchString: searchString,
            });
        }, 1000),
        [partCategoryPayload]
    );

    return (
        <div className={classes.root}>
            {selected.length > 0 && (
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    toolTipTitle={'Delete'}
                    totalCount={pageInfo.TotalCount}
                >
                    {/* <PopupAction
                        action={Action.DeleteArray}
                        object={{
                            categoryIds: selected,
                            setCategoryIDs: setSelected,
                        }}
                        level={'category'}
                        showLabel={false}
                    /> */}
                </EnhancedTableToolbar>
            )}
            <div className={classes.categoryWrapper}>
                <Box display="flex" alignItems="center" justifyContent="end">
                    <SearchWithFilters
                        filter={false}
                        onChange={(evt) => debouncedSearch(evt.target.value)}
                    />
                </Box>
                <AutoSizer>
                    {({ width, height }) => (
                        <VirtualTable
                            initialRows={partCategories}
                            allRows={partCategories}
                            totalRowCount={pageInfo.TotalCount}
                            loadedRowsMap={loadedRowsMap}
                            loadMoreRows={loadMoreRows}
                            columnDefinitions={columns}
                            cellClassName={tableClasses.flexContainer}
                            className={tableClasses.table}
                            rowClassName={tableClasses.flexContainer}
                            headerHeight={headerHeight}
                            width={width}
                            height={height}
                            rowHeight={rowHeight}
                            onRowClick={handleRowClick}
                        />
                    )}
                </AutoSizer>
            </div>
        </div>
    );
}

export { PartCategoryTable };
