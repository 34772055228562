import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';

export const ResetCategoryOrderModal = ({
    openModal,
    setOpenModal,
    resetOrder,
}) => {
    return (
        <Dialog open={openModal} onClose={() => setOpenModal(false)}>
            <DialogTitle id="alert-dialog-title">
                Reset Category Order
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    This will reset the categories to alphabetically order. Are
                    you sure?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setOpenModal(false)}
                >
                    Cancel
                </Button>
                <Button
                    autoFocus
                    variant="contained"
                    color="error"
                    onClick={resetOrder}
                >
                    Reset
                </Button>
            </DialogActions>
        </Dialog>
    );
};
