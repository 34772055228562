// eslint-disable-next-line
import clsx from 'clsx';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useRef, useState } from 'react';
import { MdRefresh } from 'react-icons/md';
import { Modal, Button, Box } from '@mui/material';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import { accountsSlice } from 'app/store';
const { selectActiveUser } = accountsSlice;
import { linkDisplayStyles } from './styles';
import GenericModel from '../Models/GenericModel';
import ModelContainer from '../Models/ModelContainer';
import { overrideResetStyles } from '../Procedure/styles';
import { LinkPagination } from './components/LinkPagination';
import { SmeView } from 'GeminiViewerComponent/components/SmeView';
import { DisplayVideoContent } from './components/DisplayVideoContent';
import { LrsVerbs } from 'GeminiViewerComponent/_features/xAPI/xApiData';
import { getConfig } from 'GeminiViewerComponent/_features/config/configSlice';
import { DTContainer } from '../../components/DecisionTree/components/DTContainer';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { getActiveAssetActiveZone } from 'GeminiViewerComponent/_features/asset/assetSlice';
import { resetTree } from '../../components/DecisionTree/_features/decisionTree/decisionTreeSlice';
import { selectContentPanelItemlId } from 'GeminiViewerComponent/_features/contentPanel/contentPanelItemSlice';
import {
    LinkTypeIds,
    mapVideoContentURL,
} from 'GeminiViewerComponent/_helpers';
import {
    getECTLToken,
    getECTLUrl,
    saveCompletion,
    saveStatement,
} from 'GeminiViewerComponent/_features/xAPI/xapiSlice';
import {
    getLinkDisplayContent,
    getLinkDisplayData,
    getlLinkTypeId,
    getLinkDisplayVis,
    setLinkDisplayVis,
    getLinkVideoPlayback,
    getLinkOverlayText,
    getAllSimilarLinks,
} from '../../_features/globals/visibilitySlice';

const LinkDisplay = () => {
    const dispatch = useDispatch();
    const theme = useSelector(selectActiveTheme);
    const classes = linkDisplayStyles(theme);
    const overrideResetClasses = overrideResetStyles();
    var supposedCurrentTime = 0;
    const imageRef = useRef(null);
    const imageDivRef = useRef(null);
    const videoRef = useRef(null);

    const config = useSelector(getConfig);
    const linkDisplay = useSelector(getLinkDisplayVis);
    const linkContent = useSelector(getLinkDisplayContent);
    const linkData = useSelector(getLinkDisplayData);
    const linkTypeState = useSelector(getlLinkTypeId);
    const linkOverlayText = useSelector(getLinkOverlayText);
    const linkVideoPlayback = useSelector(getLinkVideoPlayback);
    const activeUser = useSelector(selectActiveUser);
    const ectlURL = useSelector(getECTLUrl);
    const ectlToken = useSelector(getECTLToken);
    const allContentLinks = useSelector(getAllSimilarLinks);
    const TransformWrapperRef = useRef(null);

    const itemId = useSelector(selectContentPanelItemlId);
    const activeZone = useSelector(getActiveAssetActiveZone);
    const skillContent =
        linkTypeState === LinkTypeIds.Video && linkData?.skill_id;

    const [hideCloseButton, setHideCloseButton] = useState(false);
    const [currentActiveLinkIndex, setCurrentActiveLinkIndex] = useState(null);
    const [isChapterVideo, setIsChapterVideo] = useState({
        chapterPlayed: false,
        chapterCapture: false,
    });

    useEffect(() => {
        if (linkVideoPlayback?.requireVideoPlayback === true) {
            setHideCloseButton(
                linkVideoPlayback?.requireVideoPlayback === true
            );
        }

        if (linkDisplay) {
            dispatch(
                saveStatement({
                    LrsVerb: LrsVerbs.Launched,
                    itemId,
                    zoneId: activeZone?.zone_id,
                    response: {
                        ...{ action: 'linkDisplaye' },
                        video: linkVideoPlayback,
                        content: linkContent,
                    },
                })
            );
        }
    }, [linkVideoPlayback]);

    useEffect(() => {
        setIsMobileSafari(
            navigator.userAgent.indexOf('Safari') !== -1 &&
                navigator.userAgent.indexOf('Chrome') === -1
        );
        if (allContentLinks?.length > 0 && Object.keys(linkData).length > 0) {
            setCurrentActiveLinkIndex(
                allContentLinks.findIndex(
                    (link) => link.link_id === linkData?.link_id
                )
            );
        }
    }, []);

    const handleClose = () => {
        dispatch(
            setLinkDisplayVis({
                open: false,
                linkContent: null,
                linkTypeId: null,
                linkData: null,
                autoPlay: false,
                overlayText: null,
            })
        );
        if (linkType === 'DECISION_TREE') {
            dispatch(resetTree());
        }
    };

    var linkType = Number(linkTypeState);

    const handleLoadedMetadata = () => {
        const video = videoRef.current;
        if (!video) return;
        const videoDuration = video.duration;
        const watchFullVideo =
            linkVideoPlayback?.videoPlaybackEndMargin > videoDuration;
        let showButton = false;

        video.addEventListener('ended', function () {
            dispatch(
                saveStatement({
                    LrsVerb: LrsVerbs.Experienced,
                    itemId,
                    zoneId: activeZone?.zone_id,
                    response: {
                        ...{ watchFullVideo, videoDuration },
                        ...linkVideoPlayback,
                    },
                })
            );
        });

        if (linkVideoPlayback?.requireVideoPlayback === true || skillContent) {
            video.addEventListener('timeupdate', function () {
                if (!video.seeking) {
                    supposedCurrentTime = video.currentTime;
                    if (
                        !showButton &&
                        !watchFullVideo &&
                        linkVideoPlayback?.videoPlaybackEndMargin !== 0 &&
                        supposedCurrentTime >=
                            linkVideoPlayback?.videoPlaybackEndMargin
                    ) {
                        showButton = true;
                        setHideCloseButton(false);
                    }
                }
            });
            video.addEventListener('seeking', function () {
                if (video.currentTime > supposedCurrentTime) {
                    // video.currentTime = supposedCurrentTime;
                }
            });
            video.addEventListener('ended', function () {
                showButton = true;
                setHideCloseButton(false);
                supposedCurrentTime = 0;
                if (skillContent && config.modeType === 'viewer') {
                    const nextYearDate = new Date().setFullYear(
                        new Date().getFullYear() + 1
                    );
                    dispatch(
                        saveCompletion({
                            url: ectlURL,
                            api_token: ectlToken,
                            skill_id: skillContent,
                            // FIXME: remove static value once api side changes done
                            dod_id: activeUser?.dod_id || 'notavailable',
                            completion_time: moment(new Date()).format(
                                'YYYY-MM-DD'
                            ),
                            expiry_date: moment(new Date(nextYearDate)).format(
                                'YYYY-MM-DD'
                            ),
                        })
                    );
                } else {
                    dispatch(
                        saveStatement({
                            LrsVerb: LrsVerbs.Experienced,
                            response: {
                                ...{ watchFullVideo, videoDuration },
                                ...linkVideoPlayback,
                            },
                        })
                    );
                }
            });
        }
    };

    let extension = linkContent ? linkContent.split('.').pop() : '';
    if (extension.indexOf('?') !== -1) {
        extension = extension.split('?')?.[0];
    }

    const [isMobileSafari, setIsMobileSafari] = useState(false);

    if (linkType === LinkTypeIds.Document && isMobileSafari && linkDisplay) {
        window.open(linkContent, '_blank');
        handleClose();
    }

    const handleLinkNavigation = useCallback(
        (nodeIndex) => {
            const link = allContentLinks?.[nodeIndex];
            if (link && Object.keys(link).length > 0) {
                TransformWrapperRef.current?.resetTransform();
                TransformWrapperRef.current?.centerView(1);
                const linkContent = mapVideoContentURL(link);
                dispatch(
                    setLinkDisplayVis({
                        open: true,
                        chapter_data: link?.chapter,
                        allLinks: allContentLinks,
                        linkContent: linkContent,
                        linkData: {
                            ...link,
                            ...link?.content,
                            ...(typeof link.content_version === 'object'
                                ? link.content_version
                                : {}),
                            ...{ show_chapters: link?.show_chapters },
                        },
                        linkTypeId:
                            link?.link_type_id || link?.link_type?.link_type_id,
                        autoPlay: Boolean(link?.auto_play),
                        overlayText: link.content_version?.overlay_text,
                    })
                );
            }
        },
        [allContentLinks, TransformWrapperRef]
    );

    const handleKeyDown = useCallback(
        (e) => {
            if (e.key === 'ArrowLeft') {
                if (currentActiveLinkIndex - 1 >= 0) {
                    handleLinkNavigation(currentActiveLinkIndex - 1);
                }
            } else if (e.key === 'ArrowRight') {
                if (currentActiveLinkIndex + 1 < allContentLinks.length) {
                    handleLinkNavigation(currentActiveLinkIndex + 1);
                }
            }
        },
        [currentActiveLinkIndex, allContentLinks]
    );

    return (
        <div onKeyDown={handleKeyDown}>
            <Modal
                open={linkDisplay}
                onClose={
                    linkVideoPlayback?.requireVideoPlayback ? null : handleClose
                }
            >
                <div
                    className={classes.modalContainer}
                    style={
                        linkType === LinkTypeIds.Image ||
                        linkType === LinkTypeIds.Video
                            ? { height: 'auto' }
                            : {}
                    }
                >
                    {linkType === LinkTypeIds.Model && (
                        <>
                            <LinkPagination
                                handleLinkNavigation={handleLinkNavigation}
                                currentActiveLinkIndex={currentActiveLinkIndex}
                                setCurrentActiveLinkIndex={
                                    setCurrentActiveLinkIndex
                                }
                            />
                            <ModelContainer
                                Model={GenericModel}
                                modelURL={linkContent}
                            />
                        </>
                    )}
                    {/* TODO: using this for now until decision tree link type is added to backend */}
                    {linkType === 'DECISION_TREE' && (
                        <DTContainer config={config} />
                    )}
                    {linkType !== 'DECISION_TREE' &&
                        linkType !== LinkTypeIds.Model &&
                        linkType !== LinkTypeIds.ShoeModel &&
                        (linkType === LinkTypeIds.Embed ? (
                            <>
                                <LinkPagination
                                    handleLinkNavigation={handleLinkNavigation}
                                    currentActiveLinkIndex={
                                        currentActiveLinkIndex
                                    }
                                    setCurrentActiveLinkIndex={
                                        setCurrentActiveLinkIndex
                                    }
                                />
                                <div
                                    className={clsx(
                                        classes.embedContainer,
                                        overrideResetClasses.overrideMarzipanoResetCSS
                                    )}
                                    dangerouslySetInnerHTML={{
                                        __html: linkContent,
                                    }}
                                ></div>
                            </>
                        ) : linkType === LinkTypeIds.Image ? (
                            <>
                                <LinkPagination
                                    handleLinkNavigation={handleLinkNavigation}
                                    currentActiveLinkIndex={
                                        currentActiveLinkIndex
                                    }
                                    setCurrentActiveLinkIndex={
                                        setCurrentActiveLinkIndex
                                    }
                                />
                                <TransformWrapper
                                    ref={TransformWrapperRef}
                                    panning={{
                                        velocityDisabled: true,
                                        velocityAnimation: true,
                                    }}
                                    centerOnInit={true}
                                    centerZoomedOut={true}
                                    disablePadding={true}
                                    pinch={{ step: 5 }}
                                    wheel={{ smoothStep: 0.001, step: 0.2 }}
                                    doubleClick={{ step: 0.7 }}
                                >
                                    {({
                                        zoomIn,
                                        zoomOut,
                                        resetTransform,
                                        ...rest
                                    }) => (
                                        <>
                                            <div
                                                className={
                                                    classes.imageZoomButtons
                                                }
                                            >
                                                <div
                                                    className={
                                                        classes.zoomButton
                                                    }
                                                    onClick={() =>
                                                        resetTransform()
                                                    }
                                                >
                                                    <MdRefresh />
                                                </div>
                                            </div>
                                            <TransformComponent>
                                                <img
                                                    ref={imageRef}
                                                    src={linkContent}
                                                    alt={'LinkImage'}
                                                    style={{
                                                        objectFit: 'cover',
                                                        maxWidth: '100%',
                                                        maxHeight: '100%',
                                                    }}
                                                />
                                            </TransformComponent>
                                            <div ref={imageDivRef}>
                                                {linkOverlayText?.html && (
                                                    <div
                                                        className={clsx(
                                                            classes.overlayText,
                                                            overrideResetClasses.overrideMarzipanoResetCSS
                                                        )}
                                                        dangerouslySetInnerHTML={{
                                                            __html: linkOverlayText?.html,
                                                        }}
                                                    ></div>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </TransformWrapper>
                            </>
                        ) : linkType === LinkTypeIds.Video ? (
                            <>
                                <LinkPagination
                                    handleLinkNavigation={handleLinkNavigation}
                                    currentActiveLinkIndex={
                                        currentActiveLinkIndex
                                    }
                                    setCurrentActiveLinkIndex={
                                        setCurrentActiveLinkIndex
                                    }
                                />
                                <DisplayVideoContent
                                    handleLoadedMetadata={handleLoadedMetadata}
                                    isChapterVideo={isChapterVideo}
                                    setIsChapterVideo={setIsChapterVideo}
                                    videoRef={videoRef}
                                />
                            </>
                        ) : linkType === LinkTypeIds.SME ? (
                            <>
                                <LinkPagination
                                    handleLinkNavigation={handleLinkNavigation}
                                    currentActiveLinkIndex={
                                        currentActiveLinkIndex
                                    }
                                    setCurrentActiveLinkIndex={
                                        setCurrentActiveLinkIndex
                                    }
                                />
                                <SmeView
                                    sme={{
                                        ...linkData,
                                        ...{ url: linkContent },
                                    }}
                                />
                            </>
                        ) : (
                            <>
                                <LinkPagination
                                    handleLinkNavigation={handleLinkNavigation}
                                    currentActiveLinkIndex={
                                        currentActiveLinkIndex
                                    }
                                    setCurrentActiveLinkIndex={
                                        setCurrentActiveLinkIndex
                                    }
                                />
                                <Box
                                    component="iframe"
                                    src={linkContent}
                                    width="100%"
                                    height="100%"
                                    title="content"
                                />
                            </>
                        ))}
                    {!hideCloseButton && (
                        <Button
                            className={classes.close}
                            onClick={handleClose}
                            variant="contained"
                            disableElevation
                        >
                            Close
                        </Button>
                    )}
                </div>
            </Modal>
        </div>
    );
};

export { LinkDisplay };
