import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const ComparisonField = ({ values, setFieldValue, getComparisonField }) => {
    return (
        <FormControl fullWidth>
            <InputLabel id="comparison-field-label">
                Comparison Field
            </InputLabel>
            <Select
                labelId="comparison-field-label"
                id="comparison_field"
                label="Comparison Field"
                value={values?.comparison_field}
                onChange={(e) => {
                    setFieldValue('comparison_field', e.target.value);
                }}
            >
                {getComparisonField()?.map(
                    (item) =>
                        item?.variable && (
                            <MenuItem
                                key={`fieldType-${item?.id}`}
                                value={item?.variable}
                            >
                                {`${item?.label} ~ ${item?.variable}`}
                            </MenuItem>
                        )
                )}
            </Select>
        </FormControl>
    );
};

export default ComparisonField;
