import clsx from 'clsx';
import TableCell from '@mui/material/TableCell';
import InputBase from '@mui/material/InputBase';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import {
    MdArrowDownward,
    MdArrowUpward,
    MdCheck,
    MdDownload,
    MdInfoOutline,
    MdOpenInNew,
    MdSearch,
} from 'react-icons/md';
import Button from '@mui/material/Button';
import { FiExternalLink, FiPlusCircle } from 'react-icons/fi';
import { BiComment } from 'react-icons/bi';
import {
    IconButton,
    Link as MUILink,
    Tooltip,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress, {
    linearProgressClasses,
} from '@mui/material/LinearProgress';

import { PinAction, PopupActions } from 'components';
import {
    ContentTypes,
    Action,
    AssetExportJobStatus,
    DeploymentPackageStatus,
} from '_helpers';
import {
    setContentValidationModalContent,
    setGenericModalContent,
    toggleContentValidationModal,
    toggleGenericModal,
} from '_features/globals/visibilitySlice';
import { openPanel } from '_features/common/formSlice';
import { clearEditZone } from '_features/common/editZoneSlice';
import { clearEditItem } from '_features/common/editItemSlice';
import AppButton from 'components/AppButton';
import getActionIcon from 'GeminiViewerComponent/_helpers/getActionIcon';
import { get } from 'GeminiViewerComponent/_helpers/lodashUtils';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 20,
    borderRadius: 5,
    border: `1px solid ${'#3cbc9b'}`,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#808080',
    },
    [`& .${linearProgressClasses.bar}`]: {
        backgroundColor: '#3cbc9b',
    },
}));

const headerHeight = 40;
const rowHeight = 60;

function checkCell(props, column) {
    const { rowData } = props;
    const { classes, isSelected, handleClick, cellLevel } = column;
    const rowDataId = rowData[`${cellLevel}_id`];
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(classes.tableCell, classes.flexContainer)}
            style={{ height: rowHeight }}
        >
            <Checkbox
                checked={isSelected(rowDataId)}
                onClick={(event) =>
                    handleClick(event, rowDataId, rowData.display_name)
                }
            />
        </TableCell>
    );
}

function checkHeaderCell(props, column) {
    const {
        classes,
        numSelected,
        rowCount,
        handleSelectAllClick,
        hasHeaderSearchBar,
    } = column;

    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(
                classes.tableCell,
                classes.flexContainer,
                classes.noClick,
                hasHeaderSearchBar && classes.searchBarWrapperAll
            )}
            variant="head"
            style={{ height: headerHeight }}
        >
            <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={handleSelectAllClick}
            />
        </TableCell>
    );
}

function actionCell(props, column) {
    const { rowData } = props;
    const { classes, actionCellLevel, childComponents, targetPopup } = column;
    return (
        <TableCell
            padding={column.padding}
            className={clsx(
                classes.popupActions,
                classes.tableCell,
                classes.flexContainer
            )}
            component="div"
            style={{ height: rowHeight }}
        >
            {childComponents?.map((Component) => {
                return (
                    <Component
                        key={rowData[`${actionCellLevel}_id`]}
                        rowData={rowData}
                    />
                );
            })}
            <PopupActions
                object={rowData}
                level={actionCellLevel}
                targetPopup={targetPopup}
            />
        </TableCell>
    );
}

function progressStatusCell(props, column) {
    const { cellData, rowData } = props;
    const { classes, onClick } = column;
    let data = cellData;
    if (column.transform !== undefined) {
        data = column.transform(cellData);
    }
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(classes.tableCell, classes.flexContainer)}
            variant="body"
            style={{ height: rowHeight }}
            align={column.numeric ? 'right' : 'left'}
            onClick={onClick ? () => onClick(rowData) : null}
        >
            <div
                style={{
                    display: 'flex',
                    position: 'relative',
                    width: '100%',
                    justifyContent: 'center',
                }}
            >
                <Box sx={{ flexGrow: 1 }}>
                    {column?.deploymentStatus ? (
                        <BorderLinearProgress
                            variant="determinate"
                            value={
                                rowData['status_id'] === 0
                                    ? 0
                                    : rowData['status_id'] === 2
                                    ? 100
                                    : 0
                            }
                        />
                    ) : (
                        <BorderLinearProgress
                            variant="determinate"
                            value={rowData['progress_percentage'] ?? 0}
                        />
                    )}
                </Box>
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        color: '#ffffff',
                        fontWeight: 600,
                    }}
                >
                    {data}
                </div>
            </div>
        </TableCell>
    );
}

function assetExportDownloadLink(props, column) {
    const { cellData, rowData } = props;
    const { classes, onClick } = column;
    let data = cellData;
    if (column.transform !== undefined) {
        data = column.transform(cellData);
    }
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(classes.tableCell, classes.flexContainer)}
            variant="body"
            style={{ height: rowHeight }}
            align={column.numeric ? 'right' : 'left'}
            onClick={onClick ? () => onClick(rowData) : null}
        >
            {rowData['status_id'] === AssetExportJobStatus.Complete.id && (
                <MUILink
                    href={rowData['file_url']}
                    rel="noreferrer"
                    target="_blank"
                    download={true}
                    sx={{
                        ':hover': { color: '#34485e' },
                        ':visited': { color: '#297fb9' },
                        color: '#297fb9',
                        textDecoration: 'none',
                        display: 'inline-block',
                    }}
                    title={data}
                >
                    <Typography
                        component="span"
                        sx={{
                            whiteSpace: { xs: 'nowrap', md: 'inherit' },
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: { xs: '150px', md: 'auto' },
                            display: { xs: 'inline-block', md: 'inline' },
                        }}
                        variant="subtitle2"
                    >
                        {data}
                    </Typography>
                    <IconButton aria-label="close">
                        <MdDownload
                            className="react-icon"
                            style={{ fontSize: '16px' }}
                        />
                    </IconButton>
                </MUILink>
            )}
        </TableCell>
    );
}

function viewLink(props, column) {
    const { rowData } = props;
    const { classes, dispatch } = column;

    const handleViewLink = (content) => {
        if (content?.display_type_id === 2) {
            window.open(content.url, '_blank');
        } else {
            dispatch(setContentValidationModalContent(content));
            dispatch(toggleContentValidationModal());
        }
    };

    return (
        <TableCell
            component="div"
            scope="row"
            padding={column.padding}
            className={clsx(classes.tableCell, classes.flexContainer)}
            style={{ height: rowHeight, cursor: 'pointer' }}
        >
            <span
                onClick={() => handleViewLink(rowData)}
                className={clsx(classes.gridLink, classes.launchLink)}
            >
                <FiExternalLink />
            </span>
        </TableCell>
    );
}

function contentTypeCell(props, column) {
    const { cellData } = props;
    const { classes } = column;
    let data = cellData;
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(classes.tableCell, classes.flexContainer)}
            variant="body"
            style={{ height: rowHeight }}
            align={column.numeric ? 'right' : 'left'}
        >
            {Object.keys(ContentTypes).find(
                (at) => ContentTypes[at].id === data
            )}
        </TableCell>
    );
}

function ExistingContentTypeCell(props, column) {
    const { cellData, rowData } = props;
    const { classes, onClick } = column;
    let data = cellData;
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(classes.tableCell, classes.flexContainer)}
            variant="body"
            style={{ height: rowHeight }}
            align={column.numeric ? 'right' : 'left'}
            onClick={onClick ? () => onClick(rowData) : null}
        >
            {Object.keys(ContentTypes).find(
                (at) => ContentTypes[at].id === data
            )}
        </TableCell>
    );
}

function contentButtonTypeCell(props, column) {
    const { rowData } = props;
    const { classes } = column;
    let data = rowData?.[column?.dataKey];
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(classes.tableCell)}
            variant="body"
            style={{ height: rowHeight, overflow: 'auto' }}
            align={column.numeric ? 'right' : 'left'}
        >
            {data?.length > 0 &&
                data.map((at, index) => (
                    <label
                        className={classes.usageLabel}
                        key={column?.displayDataKey + index}
                        onClick={(event) => {
                            if (column?.onClick) {
                                if (column?.dataKey === 'asset_usage') {
                                    column.onClick(event, at['asset_id']);
                                } else if (column?.dataKey === 'zone_usage') {
                                    column.onClick(event, at, at['asset_id']);
                                } else if (column?.dataKey === 'item_usage') {
                                    column.onClick(
                                        event,
                                        at,
                                        at['asset_id'],
                                        at
                                    );
                                }
                            }
                        }}
                    >
                        {at[column?.displayDataKey]}
                    </label>
                ))}
        </TableCell>
    );
}

function tagButtonTypeCell(props, column) {
    const { rowData } = props;
    const { classes } = column;
    let data = rowData?.tag_usage?.[column?.dataKey];
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(classes.tableCell)}
            variant="body"
            style={{ height: rowHeight, overflow: 'auto' }}
            align={column.numeric ? 'right' : 'left'}
        >
            {data?.length > 0 &&
                data.map((at, index) => (
                    <label
                        className={classes.usageLabel}
                        key={column?.displayDataKey + index}
                        onClick={(event) => {
                            if (column?.onClick) {
                                if (column?.dataKey === 'assets') {
                                    column.onClick(event, at['assetId']);
                                } else if (column?.dataKey === 'zones') {
                                    column.onClick(event, at, at['asset_id']);
                                } else if (column?.dataKey === 'items') {
                                    column.onClick(
                                        event,
                                        at,
                                        at['asset_id'],
                                        at
                                    );
                                }
                            }
                        }}
                    >
                        {at[column?.displayDataKey]}
                    </label>
                ))}
        </TableCell>
    );
}

function toolButtonTypeCell(props, column) {
    const { rowData } = props;
    const { classes } = column;
    let data = rowData?.[column?.dataKey];

    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(classes.tableCell)}
            variant="body"
            style={{ height: rowHeight, overflow: 'auto' }}
            align={column.numeric ? 'right' : 'left'}
        >
            {data?.length > 0 &&
                data.map((at, index) => (
                    <label
                        className={classes.usageLabel}
                        key={column?.displayDataKey + index}
                        onClick={() => {
                            if (column?.onClick) {
                                if (column?.dataKey === 'locations') {
                                    column.onClick(at['tool_location_id']);
                                } else {
                                    column.onClick(at, at['tool_location_id']);
                                }
                            }
                        }}
                    >
                        {at[column?.displayDataKey]}
                    </label>
                ))}
        </TableCell>
    );
}

function partButtonTypeCell(props, column) {
    const { rowData } = props;
    const { classes } = column;
    let data = rowData?.[column?.dataKey];
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(classes.tableCell)}
            variant="body"
            style={{ height: rowHeight, overflow: 'auto' }}
            align={column.numeric ? 'right' : 'left'}
        >
            {data?.length > 0 &&
                data.map((at, index) => (
                    <label
                        className={classes.usageLabel}
                        key={column?.displayDataKey + index}
                        onClick={() => {
                            if (column?.onClick) {
                                if (column?.dataKey === 'groups') {
                                    column.onClick(at['part_group_id']);
                                } else {
                                    column.onClick(at, at['part_group_id']);
                                }
                            }
                        }}
                    >
                        {at[column?.displayDataKey]}
                    </label>
                ))}
        </TableCell>
    );
}

function noOfRecordCell(props, column) {
    const { rowData } = props;
    const { classes } = column;
    let data = rowData?.[column?.dataKey];

    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(classes.tableCell)}
            variant="body"
            style={{ height: rowHeight, overflow: 'auto' }}
            align={column.numeric ? 'right' : 'left'}
        >
            {Array.isArray(data) ? data.length : 0}
        </TableCell>
    );
}

function activeCell(props, column) {
    const { cellData } = props;
    const { classes } = column;
    let data = cellData;
    if (column.transform !== undefined) {
        data = column.transform(cellData);
    }

    const active = data ? (
        <div className={clsx(classes.activeCellContainer, classes.activeCell)}>
            Active
        </div>
    ) : (
        <div
            className={clsx(classes.activeCellContainer, classes.inactiveCell)}
        >
            Inactive
        </div>
    );

    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(classes.tableCell, classes.flexContainer)}
            variant="body"
            style={{ height: rowHeight }}
            align={column.numeric ? 'right' : 'left'}
        >
            {active}
        </TableCell>
    );
}

function tagCell(props, column) {
    const { rowData } = props;
    const { classes, onClick, childComponents, width } = column;

    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(classes.tableCell, classes.flexContainer)}
            variant="body"
            style={{ height: rowHeight, width: width }}
            align={column.numeric ? 'right' : 'left'}
            onClick={onClick ? () => onClick(rowData) : null}
        >
            {childComponents?.map((Component) => {
                return <Component key={rowData?.item_id} rowData={rowData} />;
            })}
        </TableCell>
    );
}
function shortcutCell(props, column) {
    const { rowData } = props;
    const { classes, onClick, childComponents, displayAsset } = column;

    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(classes.tableCell, classes.flexContainer)}
            variant="body"
            style={{ height: '60px', minHeight: '60px', overflow: 'hidden' }}
            align={column.numeric ? 'right' : 'left'}
            onClick={onClick ? () => onClick(rowData) : null}
        >
            {childComponents?.map((Component) => {
                return (
                    <Component
                        key={rowData?.item_id}
                        displayAsset={displayAsset}
                        rowData={rowData}
                    />
                );
            })}
        </TableCell>
    );
}

function labelCell(props, column) {
    const { cellData, rowData } = props;
    const { classes, onClick, width, assetLabel } = column;
    let data = cellData;
    if (column.transform !== undefined) {
        data = column.transform(cellData, rowData);
    }
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(classes.tableCell, classes.scrollingFlexContainer)}
            variant="body"
            style={{ height: rowHeight, width: width, userSelect: 'none' }}
            align={column.numeric ? 'right' : 'left'}
            onClick={onClick ? () => onClick(rowData) : null}
        >
            <Tooltip title={data}>
                <Typography
                    overflow="hidden"
                    textOverflow="ellipsis"
                    display="inline-block"
                    whiteSpace="nowrap"
                >
                    {data}
                    {assetLabel === true && rowData?.description && (
                        <Typography
                            fontSize="11px"
                            width="100%"
                            textOverflow="ellipsis"
                            overflow="hidden"
                            whiteSpace="nowrap"
                        >
                            {rowData.description}
                        </Typography>
                    )}
                </Typography>
            </Tooltip>
        </TableCell>
    );
}

function conditionalLabelCell(props, column) {
    const { cellData, rowData } = props;
    const { classes, onClick, conditionalFn } = column;
    let data = cellData;
    if (column.transform !== undefined) {
        data = column.transform(cellData);
    }
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(classes.tableCell, classes.flexContainer)}
            variant="body"
            style={{ height: rowHeight }}
            align={column.numeric ? 'right' : 'left'}
            onClick={onClick ? () => onClick(rowData) : null}
        >
            {typeof conditionalFn === 'function' ? conditionalFn(data) : data}
        </TableCell>
    );
}

function hotspotDefaultIcon(props, column) {
    const { cellData, rowData } = props;
    const { classes, onClick } = column;
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(classes.tableCell, classes.flexContainer)}
            variant="body"
            style={{ height: rowHeight }}
            align={'center'}
            onClick={
                onClick
                    ? (e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onClick(rowData);
                      }
                    : null
            }
        >
            {cellData ? (
                <MdCheck
                    className="react-icon"
                    style={{ marginLeft: '10px' }}
                />
            ) : column.dataKey === 'is_default' ||
              (column.dataKey === 'is_highlight' &&
                  rowData.hotspot_type_id === 1) ? (
                <AppButton
                    style={{ marginLeft: '-10px' }}
                    text={'Set'}
                    className={'primaryButton'}
                />
            ) : null}
        </TableCell>
    );
}

function zonePreviewLabelCell(props, column) {
    const { cellData, rowData } = props;
    const { classes, onClick } = column;
    let data = cellData;
    if (column.transform !== undefined) {
        data = column.transform(cellData);
    }

    const previewImage = rowData.equirect_image_preview_url
        ? rowData.equirect_image_preview_url
        : rowData.flat_image_preview_url
        ? rowData.flat_image_preview_url
        : null;

    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(classes.tableCell, classes.flexContainer)}
            variant="body"
            style={{ height: rowHeight, width: '100%' }}
            align={column.numeric ? 'right' : 'left'}
            onClick={onClick ? () => onClick(rowData) : null}
        >
            <span
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    alignContent: 'center',
                    justifyContent: 'left',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                {previewImage && (
                    <img
                        src={previewImage}
                        alt={`${previewImage}`.toLowerCase()}
                        style={{
                            maxWidth: rowHeight - 10,
                            maxHeight: rowHeight,
                            padding: '5px 5px 5px 0px',
                        }}
                    />
                )}
                <Tooltip title={data}>
                    <Typography
                        overflow="hidden"
                        textOverflow="ellipsis"
                        display="inline-block"
                        whiteSpace="nowrap"
                    >
                        {data}
                    </Typography>
                </Tooltip>
            </span>
        </TableCell>
    );
}

function hotspotPreviewCell(props, column) {
    const { cellData, rowData } = props;
    const { classes, onClick } = column;
    if (column.transform !== undefined) {
        column.transform(cellData);
    }

    const previewImage = rowData.image_url ? rowData.image_url : null;

    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(classes.tableCell, classes.flexContainer)}
            variant="body"
            style={{ height: rowHeight }}
            align={column.numeric ? 'right' : 'left'}
            onClick={onClick ? () => onClick(rowData) : null}
        >
            <span
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    alignContent: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {previewImage && (
                    <img
                        src={previewImage}
                        alt={`${previewImage}`.toLowerCase()}
                        style={{
                            width: rowHeight - 20,
                            height: 'auto',
                            padding: '5px 5px 5px 0px',
                        }}
                    />
                )}
            </span>
        </TableCell>
    );
}

function statusCell(props, column) {
    const { cellData, rowData } = props;
    const { classes, onClick } = column;
    let data = cellData;

    if (column.transform !== undefined) {
        data = column.transform(cellData);
    }

    let statusCellType;
    if (data === 'approved') {
        statusCellType = classes.approvedCell;
    } else if (data === 'pending for review') {
        statusCellType = classes.pendingCell;
    } else if (data === 'rejected') {
        statusCellType = classes.rejectedCell;
    }
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(classes.tableCell, classes.flexContainer)}
            variant="body"
            style={{ height: rowHeight }}
            align={column.numeric ? 'right' : 'left'}
            onClick={onClick ? () => onClick(rowData) : null}
        >
            <div className={clsx(classes.statusCell, statusCellType)}>
                {data}
            </div>
        </TableCell>
    );
}

function feedbackCell(props, column) {
    const { cellData, rowData } = props;
    const { classes, onClick } = column;
    let data = cellData;

    if (column.transform !== undefined) {
        data = column.transform(cellData);
    }
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(classes.tableCell, classes.flexContainer)}
            variant="body"
            style={{ height: rowHeight }}
            align={column.numeric ? 'right' : 'left'}
            onClick={onClick ? () => onClick(rowData) : null}
        >
            {data === 'yes' ? <BiComment size={25} color="#BDC3C7" /> : null}
        </TableCell>
    );
}

function labelHeader(props, column) {
    const { label } = props;
    const {
        classes,
        sortDirection,
        sortBy,
        handleSort,
        dataKey,
        allowHeaderSearchBar = false,
        onSearchChange,
        hasHeaderSearchBar = false,
    } = column;
    const isAscendingOrder = sortDirection === 'ASC' && dataKey === sortBy;
    const activeSort =
        sortBy == dataKey
            ? { fontSize: '16px', color: 'black' }
            : { fontSize: '16px' };
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(
                classes.tableCell,
                classes.flexContainer,
                classes.noClick,
                classes.tableCellHeader,
                hasHeaderSearchBar && classes.searchBarWrapperAll,
                allowHeaderSearchBar && classes.searchBarWrapper
            )}
            variant="head"
            style={{ height: headerHeight, userSelect: 'none' }}
            align={column.numeric ? 'right' : 'left'}
        >
            {typeof handleSort === 'function' ? (
                <div
                    className={classes.sortIndicatorContainer}
                    onClick={() =>
                        handleSort(dataKey, isAscendingOrder ? 'DESC' : 'ASC')
                    }
                >
                    <>
                        <span>{label}</span>
                        {isAscendingOrder ? (
                            <MdArrowUpward
                                className="react-icon"
                                style={activeSort}
                            />
                        ) : (
                            <MdArrowDownward
                                className="react-icon"
                                style={activeSort}
                            />
                        )}
                    </>
                </div>
            ) : (
                <span>{label}</span>
            )}
            {allowHeaderSearchBar && (
                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <MdSearch className="react-icon" />
                    </div>
                    <InputBase
                        placeholder="Search…"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        onChange={onSearchChange}
                        inputProps={{ type: 'search' }}
                    />
                </div>
            )}
        </TableCell>
    );
}

function labelHeaderWithSearch(props, column) {
    const { label } = props;
    const {
        classes,
        sortDirection,
        sortBy,
        handleSort,
        dataKey,
        allowHeaderSearchBar = false,
        onSearchChange,
        hasHeaderSearchBar = false,
        isOpenSearch = false,
        toggleOpenSearch = false,
    } = column;
    const isAscendingOrder = sortDirection === 'ASC' && dataKey === sortBy;
    const activeSort =
        sortBy == dataKey
            ? { fontSize: '16px', color: 'black' }
            : { fontSize: '16px' };
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(
                classes.tableCell,
                classes.flexContainer,
                classes.noClick,
                classes.tableCellHeader,
                hasHeaderSearchBar && classes.searchBarWrapperAll,
                allowHeaderSearchBar && classes.searchBarWrapper
            )}
            variant="head"
            style={{ height: headerHeight }}
            align={column.numeric ? 'right' : 'left'}
        >
            {typeof handleSort === 'function' ? (
                <div
                    className={classes.sortIndicatorContainer}
                    onClick={() =>
                        handleSort(dataKey, isAscendingOrder ? 'DESC' : 'ASC')
                    }
                    style={{ width: 'inherit' }}
                >
                    <span>{label}</span>
                    {isAscendingOrder ? (
                        <MdArrowUpward
                            className="react-icon"
                            style={activeSort}
                        />
                    ) : (
                        <MdArrowDownward
                            className="react-icon"
                            style={activeSort}
                        />
                    )}
                </div>
            ) : (
                <span>{label}&nbsp;</span>
            )}

            {allowHeaderSearchBar && (
                <Tooltip title="Click to Search">
                    <IconButton
                        onClick={toggleOpenSearch}
                        aria-label="search"
                        style={{ padding: 0 }}
                    >
                        <MdSearch className="react-icon" sx={activeSort} />
                    </IconButton>
                </Tooltip>
            )}

            {allowHeaderSearchBar && isOpenSearch && (
                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <MdSearch className="react-icon" />
                    </div>
                    <InputBase
                        placeholder="Search…"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        onChange={onSearchChange}
                        inputProps={{ type: 'search' }}
                    />
                </div>
            )}
        </TableCell>
    );
}

function pinCell(props, column) {
    const { rowData } = props;
    const { classes, cellLevel } = column;
    const pinId = rowData[`${cellLevel}_id`];
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(
                classes.tableCell,
                classes.flexContainer,
                classes.pin,
                { [classes.isPinned]: rowData.is_pinned }
            )}
            style={{ height: rowHeight }}
            onClick={(event) => {
                event.stopPropagation();
            }}
        >
            <PinAction pinId={pinId} level={cellLevel} />
        </TableCell>
    );
}

function createCell(props, column) {
    const { label } = props;
    const {
        classes,
        setViewPanel,
        dispatch,
        formKey,
        hasHeaderSearchBar,
        readOnly = false,
    } = column;
    const createLabel = `New ${label}`;

    const handleCreate = () => {
        if (formKey === 'zoneForm') {
            dispatch(clearEditZone());
        }
        if (formKey === 'itemForm') {
            dispatch(clearEditItem());
        }
        dispatch(openPanel({ formKey: formKey, formAction: 'Create' }));
    };

    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(
                classes.tableCell,
                classes.flexContainer,
                classes.noClick,
                hasHeaderSearchBar && classes.searchBarWrapperAll
            )}
            variant="head"
            style={{ height: headerHeight, whiteSpace: 'nowrap' }}
            align={column.numeric || false ? 'left' : 'right'}
        >
            {formKey === '' && (
                <div
                    className={classes.newObject}
                    onClick={() => setViewPanel(true)}
                >
                    {createLabel}
                    <FiPlusCircle style={{ marginLeft: '5px' }} />
                </div>
            )}
            {formKey !== '' && readOnly !== true && (
                <div
                    className={classes.newObject}
                    onClick={() => handleCreate()}
                >
                    {createLabel}
                    <FiPlusCircle style={{ marginLeft: '5px' }} />
                </div>
            )}
        </TableCell>
    );
}

function seeAssetCell(props, column) {
    const { rowData } = props;
    const { classes } = column;
    return (
        <TableCell
            component="div"
            scope="row"
            padding={column.padding}
            className={clsx(classes.tableCell, classes.flexContainer)}
            style={{ height: rowHeight }}
        >
            <Link
                to={`/asset/${rowData.asset_id}`}
                className={classes.gridLink}
            >
                <span className={classes.gridLinkSpan}>See More</span>
            </Link>
        </TableCell>
    );
}

function schooxNestedLabelCell(props, column) {
    const { cellData } = props;
    const { classes } = column;
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(classes.tableCell, classes.flexContainer)}
            variant="body"
            style={{ height: rowHeight, cursor: 'pointer' }}
            align={column.numeric ? 'right' : 'left'}
        >
            {get(cellData, column.nestedDataKey)}
        </TableCell>
    );
}

function assetTypeIconCell(props, column) {
    const { cellData } = props;
    const { classes } = column;
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(classes.tableCell, classes.flexContainer)}
            variant="body"
            style={{ height: rowHeight, cursor: 'pointer' }}
        >
            {cellData === 1
                ? getActionIcon(Action.Product.iconId)
                : cellData === 2
                ? getActionIcon(Action.Place.iconId)
                : getActionIcon(Action.Procedure.iconId)}
        </TableCell>
    );
}

function assetDisclaimerIconCell(props, column) {
    const { classes, dispatch } = column;

    const asset = props.rowData;

    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(classes.tableCell, classes.flexContainer)}
            variant="body"
            style={{ height: rowHeight, cursor: 'pointer' }}
            onClick={(event) => {
                event.stopPropagation();
            }}
        >
            {(asset?.disclaimer_tooltip || asset?.disclaimer_html) && (
                <Tooltip
                    title={asset?.disclaimer_tooltip}
                    componentsProps={{
                        tooltip: { className: classes.tooltip },
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        if (
                            asset?.disclaimer_html &&
                            asset.disclaimer_html !== ''
                        ) {
                            dispatch(
                                setGenericModalContent(
                                    asset?.disclaimer_html ??
                                        asset?.disclaimer_tooltip
                                )
                            );
                            dispatch(toggleGenericModal());
                        }
                    }}
                >
                    <Typography
                        display="flex"
                        className={clsx(
                            classes.disclaimer,
                            classes.cardDisclaimer
                        )}
                    >
                        <MdInfoOutline className="react-icon" />
                    </Typography>
                </Tooltip>
            )}
        </TableCell>
    );
}

function itemLabelCell(props, column) {
    const { cellData, rowData } = props;
    const { classes, onClick } = column;
    let data = cellData;
    if (column.transform !== undefined) {
        data = column.transform(cellData, rowData);
    }
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(classes.tableCell, classes.scrollingFlexContainer)}
            variant="body"
            style={{ height: rowHeight }}
            align={column.numeric ? 'right' : 'left'}
            onClick={onClick ? () => onClick(rowData) : null}
        >
            <Tooltip title={data}>
                <Typography
                    overflow="hidden"
                    textOverflow="ellipsis"
                    display="inline-block"
                    whiteSpace="nowrap"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {data}
                    <Typography
                        fontSize="11px"
                        width="100%"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                    >
                        {rowData.description}
                    </Typography>
                </Typography>
            </Tooltip>
        </TableCell>
    );
}

function checkBoxCell(props, column) {
    const { rowData } = props;
    const { classes, cellLevel } = column;
    const rowDataId = rowData[`${cellLevel}`];
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(classes.tableCell, classes.flexContainer)}
            style={{ height: rowHeight }}
        >
            <Checkbox checked={rowDataId} />
        </TableCell>
    );
}

const labelHeaderDiffSortKey = (props, column) => {
    const { label } = props;
    const {
        classes,
        sortDirection,
        sortBy,
        handleSort,
        sortKey,
        allowHeaderSearchBar = false,
        onSearchChange,
        hasHeaderSearchBar = false,
    } = column;
    const isAscendingOrder = sortDirection === 'ASC' && sortKey === sortBy;
    const activeSort =
        sortBy == sortKey
            ? { fontSize: '16px', color: 'black' }
            : { fontSize: '16px' };
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(
                classes.tableCell,
                classes.flexContainer,
                classes.noClick,
                classes.tableCellHeader,
                hasHeaderSearchBar && classes.searchBarWrapperAll,
                allowHeaderSearchBar && classes.searchBarWrapper
            )}
            variant="head"
            style={{ height: headerHeight }}
            align={column.numeric ? 'right' : 'left'}
        >
            {typeof handleSort === 'function' ? (
                <div
                    className={classes.sortIndicatorContainer}
                    onClick={() =>
                        handleSort(sortKey, isAscendingOrder ? 'DESC' : 'ASC')
                    }
                >
                    <>
                        <span>{label}</span>
                        {isAscendingOrder ? (
                            <MdArrowUpward
                                className="react-icon"
                                style={activeSort}
                            />
                        ) : (
                            <MdArrowDownward
                                className="react-icon"
                                style={activeSort}
                            />
                        )}
                    </>
                </div>
            ) : (
                <span>{label}</span>
            )}
            {allowHeaderSearchBar && (
                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <MdSearch className="react-icon" />
                    </div>
                    <InputBase
                        placeholder="Search…"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        onChange={onSearchChange}
                        inputProps={{ type: 'search' }}
                    />
                </div>
            )}
        </TableCell>
    );
};

const deploymentPackageDownloadLink = (props, column) => {
    const { cellData, rowData } = props;
    const { classes, onClick } = column;
    let data = cellData;
    if (column.transform !== undefined) {
        data = column.transform(cellData);
    }
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(classes.tableCell, classes.flexContainer)}
            variant="body"
            style={{ height: rowHeight }}
            align={column.numeric ? 'right' : 'left'}
            onClick={onClick ? () => onClick(rowData) : null}
        >
            {rowData['status_id'] === DeploymentPackageStatus.Complete.id && (
                <MUILink
                    href={rowData['file_url']}
                    rel="noreferrer"
                    target="_blank"
                    download={true}
                    sx={{
                        ':hover': { color: '#34485e' },
                        ':visited': { color: '#297fb9' },
                        color: '#297fb9',
                        textDecoration: 'none',
                        display: 'inline-block',
                    }}
                    title={data}
                >
                    <Typography
                        component="span"
                        sx={{
                            whiteSpace: { xs: 'nowrap', md: 'inherit' },
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: { xs: '150px', md: 'auto' },
                            display: { xs: 'inline-block', md: 'inline' },
                        }}
                        variant="subtitle2"
                    >
                        {data}
                    </Typography>
                    <IconButton aria-label="close">
                        <MdDownload
                            className="react-icon"
                            style={{ fontSize: '16px' }}
                        />
                    </IconButton>
                </MUILink>
            )}
        </TableCell>
    );
};

const DynamicLinkCell = (props, column) => {
    const { cellData, rowData } = props;
    const { setIndex, link, classes, dataKey, subDataKey } = column;
    let data = cellData;
    if (column.transform !== undefined) {
        data = column.transform(cellData, rowData);
    }
    const handleOpenLink = () => {
        const updatedLink = `${link}?tabIndex=${setIndex}`;
        window.open(updatedLink, '_blank');
    };
    if (subDataKey) {
        data = rowData[dataKey][subDataKey];
    }

    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(classes.tableCell, classes.flexContainer)}
            style={{ height: rowHeight }}
        >
            <Button onClick={handleOpenLink} endIcon={<MdOpenInNew />}>
                {data || '-'}
            </Button>
        </TableCell>
    );
};

export {
    activeCell,
    actionCell,
    contentTypeCell,
    checkCell,
    checkHeaderCell,
    createCell,
    labelHeader,
    labelCell,
    conditionalLabelCell,
    statusCell,
    feedbackCell,
    pinCell,
    schooxNestedLabelCell,
    seeAssetCell,
    viewLink,
    zonePreviewLabelCell,
    hotspotPreviewCell,
    assetTypeIconCell,
    assetDisclaimerIconCell,
    hotspotDefaultIcon,
    tagCell,
    shortcutCell,
    contentButtonTypeCell,
    progressStatusCell,
    assetExportDownloadLink,
    labelHeaderWithSearch,
    toolButtonTypeCell,
    partButtonTypeCell,
    noOfRecordCell,
    tagButtonTypeCell,
    itemLabelCell,
    ExistingContentTypeCell,
    checkBoxCell,
    deploymentPackageDownloadLink,
    labelHeaderDiffSortKey,
    DynamicLinkCell,
};
