import React from 'react';
import { Box, IconButton } from '@mui/material';
import { MdDelete } from 'react-icons/md';

import { TableFieldChildElement } from './TableFieldChildElement';

const TableFIeldArray = ({
    repeatingRow,
    setRepeatingRow,
    fieldData,
    setFieldData,
    rowIndexes,
    cells,
    data,
    index,
    hasHeaders,
    colWidths,
    cols,
    isMobileWidth,
    rows,
    fieldValue,
    setFieldValue,
    errors,
    setErrors,
    isReadOnly,
    isSubmitAllow,
    handleBlur,
    handleClick,
    contentService,
}) => {
    const removeRepeatingRow = (row, index) => {
        let allFields = [...fieldData];
        row.forEach((rowData) => {
            let duplicateCount = rowData?.variable.slice(
                rowData?.variable?.length - 1,
                rowData?.variable?.length
            );
            let lastIndex = rowData?.variable.lastIndexOf(`_${duplicateCount}`);
            let variable = rowData?.variable
                .slice(0, lastIndex)
                .replace('/_*$/g', '');

            if (Array.isArray(fieldValue[variable])) {
                const fieldValues = [...fieldValue[variable]];
                fieldValues?.splice(duplicateCount, 1);
                setFieldValue(variable, fieldValues);
            }
        });
        allFields.splice(index, row.length);
        let newRowStart = false;
        const allField = allFields.map((field, idx) => {
            if (idx >= index) {
                if (field?.duplicateCount && !newRowStart) {
                    let slicedVariable = field.variable.slice(
                        0,
                        field.variable.length - 1
                    );
                    let slicedCount = field.variable.slice(
                        field.variable.length - 1,
                        field.variable.length
                    );
                    field.variable = `${slicedVariable}${slicedCount - 1}`;
                    field.duplicateCount = slicedCount - 1;
                    return field;
                } else {
                    newRowStart = true;
                    return field;
                }
            } else return field;
        });
        setFieldData([...allField]);
    };

    // const isTableFieldFullFilled = fieldData.length % cols;

    return (
        <tr
            style={{
                display: `${isMobileWidth ? 'flex' : 'table-row'}`,
                flexDirection: `${isMobileWidth ? 'column' : 'row'}`,
                borderRadius: '5px',
                boxShadow: `${
                    isMobileWidth && isSubmitAllow
                        ? '0 0 5px 0 rgb(134 128 128 / 64%)'
                        : 'none'
                }`,
                padding: `${isMobileWidth && isSubmitAllow ? '1rem' : '0'}`,
                margin: `${isMobileWidth && isSubmitAllow ? '1rem 0' : '0'}`,
                position: 'relative',
                borderTop: `${rowIndexes[3] ? '1px solid #c4c4c4' : 'none'}`,
            }}
        >
            {cells.map((cellData, cellIdx) => {
                const tdWidth =
                    !hasHeaders && index === 0
                        ? {
                              width: `${colWidths[cellIdx % cols]}%`,
                          }
                        : {};
                return (
                    <td
                        style={{
                            ...tdWidth,
                            verticalAlign: `${
                                cellData.type === 'checkbox'
                                    ? cellData.cellAlignment || 'middle'
                                    : 'middle'
                            }`,
                        }}
                        colSpan={
                            cellData?.colspan ? Number(cellData?.colspan) : 1
                        }
                        key={`table_${data.id}_row_${index}_col_${cellIdx}`}
                    >
                        {isMobileWidth === true && (
                            <>
                                <Box pl={'5px'}>
                                    {
                                        data?.columns?.find(
                                            (d, idx) =>
                                                idx ===
                                                cellIdx %
                                                    (data?.columns?.length ?? 1)
                                        )?.label
                                    }
                                </Box>
                            </>
                        )}
                        <TableFieldChildElement
                            fieldData={cellData}
                            fieldValue={fieldValue}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            setErrors={setErrors}
                            groupKeyPrefix={'Table'}
                            isReadOnly={isReadOnly}
                            isSubmitAllow={isSubmitAllow}
                            handleBlur={handleBlur}
                            handleClick={handleClick}
                            isMobileWidth={isMobileWidth}
                            contentService={contentService}
                        />
                    </td>
                );
            })}
            {cells.some((cell) => !!cell.duplicateIndex) && (
                <IconButton
                    aria-label="close"
                    style={{
                        backgroundColor: 'lightgray',
                        position: 'absolute',
                        width: 'fit-content',
                        marginLeft: 'auto',
                        padding: '8px',
                        top: `${isMobileWidth ? '5px' : '50%'}`,
                        right: `${isMobileWidth ? '5px' : '-7px'}`,
                        transform: 'translate(50%, -50%)',
                    }}
                    size="large"
                    onClick={() => {
                        removeRepeatingRow(cells, rowIndexes[0]);
                    }}
                >
                    <MdDelete
                        className="react-icon"
                        style={{ fontSize: '14px' }}
                    />
                </IconButton>
            )}
        </tr>
    );
};

export { TableFIeldArray };
