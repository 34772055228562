import React from 'react';
import { Box, Typography } from '@mui/material';

import dataNotFoundStyles from './styles';
import create_asset from 'assets/create_asset.png';

const DataNotFound = ({ title, inputLength = null }) => {
    const classes = dataNotFoundStyles();
    return (
        <Box className={classes.container}>
            <Box className={classes.content}>
                <Box className={classes.title}>
                    <Typography className={classes.text}>
                        {inputLength === 0
                            ? `There are no ${title}s created yet. Start by creating
                            one.`
                            : `No Matching ${title}s.`}
                    </Typography>
                    <img
                        className={classes.image}
                        alt="asset"
                        src={create_asset}
                    ></img>
                </Box>
            </Box>
        </Box>
    );
};

export { DataNotFound };
