import React, { useState } from 'react';
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { MdClose } from 'react-icons/md';
import Dropzone from 'react-dropzone';

import { panelStyles } from 'GeminiViewerComponent/components/styles';
import { dialogStyles } from 'GeminiViewerComponent/components/Procedure/styles';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { createContent } from '_features/content/contentSlice';
import { LinkTypes, ContentTypes } from '_helpers/enumerations';

const NewImageDialog = ({
    openDialog,
    setOpenDialog,
    referenceImages,
    setReferenceImages,
}) => {
    const theme = useSelector(selectActiveTheme);
    const classes = dialogStyles(theme);
    const panelClasses = panelStyles(theme);
    const { enqueueSnackbar } = useSnackbar();
    const [newImages, setNewImages] = useState([]);
    const dispatch = useDispatch();

    const onDrop = (file) => setNewImages(file);

    const onDropError = (err) => {
        if (err?.[0]?.errors?.[0]?.message) {
            enqueueSnackbar(err[0].errors[0].message, {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: 'error',
            });
        }
    };

    const handleFileData = (file) => {
        const re = /(?:\.([^.]+))?$/;
        const extension = re.exec(file?.name)[1];
        const ext = extension.replace(/^.{1}/g, extension[0]);
        let fileName = file?.name ? file?.name : '';
        if (fileName.lastIndexOf('.') !== -1) {
            fileName = fileName.slice(0, fileName.lastIndexOf('.'));
        }
        return {
            display_name: fileName,
            content_type_id:
                ContentTypes[
                    ext.charAt(0).toUpperCase() + ext.substring(1).toLowerCase()
                ]?.id,
        };
    };

    const handleSubmit = async () => {
        dispatch(activateLoading());
        let refImages = [];
        for (const image of newImages) {
            let contentData = await handleFileData(image);
            const contentResponse = await dispatch(
                createContent({
                    ...contentData,
                    content_version: {
                        content_data: image,
                        content_version_type: 2,
                        overlay_text: { html: '' },
                    },
                })
            );
            if (contentResponse.meta.requestStatus === 'fulfilled') {
                refImages.push({
                    name: contentData.display_name,
                    url: contentResponse.payload?.content_version?.url,
                    isSaved: true,
                    id: uuidv4(),
                    content_id: contentResponse?.payload?.content_id,
                });
            } else if (contentResponse.meta.requestStatus === 'rejected') {
                enqueueSnackbar('Create content failed!', {
                    action: (key) => <SnackbarDismiss key={key} />,
                    variant: 'error',
                });
            }
        }
        setReferenceImages([...referenceImages, ...refImages.filter((e) => e)]);
        setOpenDialog(false);
        dispatch(deactivateLoading());
    };

    return (
        <Dialog
            fullWidth
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            maxWidth={'sm'}
        >
            <DialogContent style={{ padding: '0' }}>
                <DialogContentText
                    margin={'15px'}
                    display={'flex'}
                    flexDirection={'row'}
                    flexWrap={'wrap'}
                    justifyContent={'center'}
                >
                    <Dropzone
                        accept={['image/*']}
                        maxSize={LinkTypes.Document.fileSizeLimit}
                        multiple={true}
                        onDropAccepted={onDrop}
                        onDropRejected={onDropError}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div
                                {...getRootProps()}
                                className={panelClasses.dropzoneArea}
                                style={{
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center center',
                                    width: '100%',
                                }}
                            >
                                <input {...getInputProps()} />
                                <div className={panelClasses.dropzoneText}>
                                    Drag &apos;n&apos; drop images here, or
                                    click to select files
                                </div>
                            </div>
                        )}
                    </Dropzone>
                    {newImages.length > 0 && (
                        <Chip
                            style={{ width: '100%' }}
                            className={panelClasses.previewUploadFileName}
                            color="secondary"
                            size="small"
                            label={`${newImages.length} images selected`}
                            deleteIcon={<MdClose className="react-icon" />}
                            onDelete={() => setNewImages([])}
                        />
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    className={classes.cancel}
                    onClick={() => setOpenDialog(false)}
                >
                    Cancel
                </Button>
                <Button onClick={handleSubmit} className={classes.submit}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewImageDialog;
