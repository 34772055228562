import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Formik, Form, Field } from 'formik';
import { FiExternalLink } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';

import { LinkTypes } from '_helpers';
import { accountsSlice } from 'app/store';
import { makeFormStyles } from '../styles';
import SetDirtyForm from 'forms/SetDirtyForm';
import { FormActions } from '_helpers/form-action';
import { DisplayTypes } from 'GeminiViewerComponent/_helpers';
import { ContentAccordion, SelectionAutocomplete } from 'components';
import { FormSelectField, FormTextField } from 'components/TextField';
import { fetchContentVersions } from '_features/content/contentSlice';
import { loadItem, selectEditItem } from '_features/common/editItemSlice';
import {
    getLinkById,
    createLink,
    editLink,
} from 'GeminiViewerComponent/_features/links/linksSlice';
import { snackbarHandler } from 'GeminiViewerComponent/_helpers/snackbar-handler';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { LoadingLogo } from 'GeminiViewerComponent/components/LoadingLogo/LoadingLogo';
import { VideoChapterField } from 'components/ProcedureDesigner/components/ContentViewActionNodeEditor/components/VideoChapterField';
import {
    selectAllAudiences,
    getAudienceStatus,
    fetchAudiencesPage,
} from '_features/audiences/audiencesSlice';
import {
    IconButton,
    FormControlLabel,
    Checkbox,
    FormControl,
} from '@mui/material';
import {
    openPanel,
    setEditObjectId,
    setIsDirty,
} from '_features/common/formSlice';

const LinkForm = ({
    formAction,
    linkId,
    contentTypes,
    linkTypeId,
    tabView,
    setToggleView,
    navigateToContentForm = false,
}) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);
    const [loaded, setLoaded] = useState(
        formAction.id === FormActions.Create.id ? true : false
    );
    const [initValues, setInitValues] = useState({
        display_name: '',
        description: '',
        display_type_id: DisplayTypes.Inline.id,
        content_version_id: 'Latest Version',
        content_versions: [],
        audiences: [],
        auto_play: true,
    });
    const [contentDisplayName, setContentDisplayName] = useState('');
    const [contentLinkId, setContentLinkId] = useState(0);
    const [contentTypesState, setContentTypes] = useState(contentTypes);
    const [linkTypeIdState, setLinkTypeId] = useState(linkTypeId);
    const activeUser = useSelector(accountsSlice.selectActiveUser);
    const readOnlyMode = activeUser?.role === 'User' ? true : false;
    const editItem = useSelector(selectEditItem);

    useEffect(() => {
        if (formAction.id === FormActions.Edit.id) {
            const fetchData = async () => {
                var objectData = await dispatch(getLinkById(linkId));
                var fetchedObject = objectData.payload;
                var contentVersionData = await dispatch(
                    fetchContentVersions(fetchedObject.content.content_id)
                );
                var contentVersions = contentVersionData.payload;
                setContentDisplayName(fetchedObject.content.display_name);
                setContentLinkId(fetchedObject.content.content_id);
                if (!contentTypes) {
                    setContentTypes(
                        getContentTypes(fetchedObject.link_type_id)
                    );
                }
                if (!linkTypeId) {
                    setLinkTypeId(fetchedObject.link_type_id);
                }

                setInitValues({
                    display_name: fetchedObject.display_name,
                    description: fetchedObject.description,
                    content_id: fetchedObject?.content?.content_id,
                    chapter_id: fetchedObject?.chapter?.chapter_id,
                    display_type_id: fetchedObject.display_type_id,
                    content_version_id:
                        fetchedObject?.content_version?.content_version_id ??
                        null,
                    content_versions: contentVersions,
                    audiences: fetchedObject.audiences?.map(
                        (x) => x.audience_id
                    ),
                    auto_play: Boolean(fetchedObject?.auto_play),
                    show_chapters: Boolean(fetchedObject?.show_chapters),
                    collapse_chapters: Boolean(
                        fetchedObject?.collapse_chapters
                    ),
                });
                setLoaded(true);
            };
            fetchData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        var resultAction = null;

        switch (formAction.id) {
            case FormActions.Edit.id:
                resultAction = await dispatch(
                    editLink({
                        linkId: linkId,
                        linkUpdates: {
                            ...values,
                            content_version_id:
                                values.content_version_id === '' ||
                                values.content_version_id === 'Latest Version'
                                    ? null
                                    : +values.content_version_id,
                        },
                    })
                );
                if (!resultAction.error) {
                    dispatch(
                        loadItem({
                            zoneId: editItem.zone_id,
                            itemId: editItem.item_id,
                        })
                    );
                    if (setToggleView) {
                        setToggleView(false);
                    }
                }
                break;
            case FormActions.Create.id:
                resultAction = await dispatch(
                    createLink({
                        item_id: editItem.item_id,
                        link_type_id: linkTypeIdState,
                        ...values,
                        content_version_id:
                            values.content_version_id === '' ||
                            values.content_version_id === 'Latest Version'
                                ? null
                                : +values.content_version_id,
                    })
                );
                if (!resultAction.error) {
                    dispatch(
                        loadItem({
                            zoneId: editItem.zone_id,
                            itemId: editItem.item_id,
                        })
                    );
                    resetForm();
                    if (setToggleView) {
                        setToggleView(false);
                    }
                }
                break;
            default:
                break;
        }
        dispatch(setIsDirty(false));
        const { message, variant } = snackbarHandler(
            resultAction.meta.requestStatus,
            formAction.label
        );
        enqueueSnackbar(message, {
            action: (key) => <SnackbarDismiss key={key} />,
            variant: variant,
        });

        setSubmitting(false);
    };

    const getContentTypes = (linkTypeId) => {
        var linkType = Object.keys(LinkTypes).find((lt) => {
            return LinkTypes[lt].id === linkTypeId;
        });
        var contentTypes = LinkTypes[linkType].contentTypes;
        return contentTypes;
    };

    function handleCancel() {
        if (setToggleView) {
            setToggleView(false);
        }
    }
    return !loaded ? (
        <LoadingLogo styleClassName={classes.loadingSvg} />
    ) : (
        <Formik
            enableReinitialize={true}
            initialValues={{ ...initValues }}
            validationSchema={Yup.object({
                display_name: Yup.string()
                    .max(150, 'Must be 150 characters or less')
                    .required('Required'),
                description: Yup.string().max(
                    255,
                    'Must be 255 characters or less'
                ),
                display_type_id: Yup.number(),
                content_version_id: Yup.string().nullable(),
                audiences: Yup.array(),
                content_id: Yup.number(),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                handleSubmit(values, setSubmitting, resetForm);
            }}
        >
            {({ values, isSubmitting, setFieldValue }) => (
                <Form className={classes.linkForm}>
                    <Field className={classes.input} name="content_id">
                        {({ field: { value }, form: { setFieldValue } }) => (
                            <ContentAccordion
                                expandedDefault={!contentDisplayName}
                                title="Select existing content"
                                contentId={value}
                                setFieldValue={setFieldValue}
                                contentDisplayName={contentDisplayName}
                                setContentDisplayName={setContentDisplayName}
                                formField={'content_id'}
                                linkNameValue={values['display_name']}
                                linkNameField={'display_name'}
                                linkDescField={'description'}
                                contentVersionsField={'content_versions'}
                                contentTypes={contentTypesState}
                                linkTypeId={linkTypeIdState}
                                readOnly={readOnlyMode}
                                navigateToContentForm={navigateToContentForm}
                            />
                        )}
                    </Field>
                    {contentDisplayName && (
                        <>
                            <div
                                style={{
                                    marginBottom: '5px',
                                    marginTop: '10px',
                                    fontSize: '18px',
                                    display: 'flex',
                                }}
                            >
                                {formAction.id === FormActions.Edit.id && (
                                    <IconButton
                                        style={{
                                            fontSize: '19px',
                                            padding: '0px',
                                            marginRight: '7px',
                                        }}
                                        size="large"
                                    >
                                        <FiExternalLink
                                            onClick={async () => {
                                                await dispatch(
                                                    setEditObjectId({
                                                        formKey: `contentForm`,
                                                        object_id:
                                                            contentLinkId,
                                                    })
                                                );
                                                await dispatch(
                                                    openPanel({
                                                        formKey: `contentForm`,
                                                        formAction: 'Edit',
                                                    })
                                                );
                                            }}
                                            style={{
                                                color: '#7C8D8C',
                                            }}
                                        />
                                    </IconButton>
                                )}
                                Selected Content:{' '}
                                <span style={{ fontWeight: 'bold' }}>
                                    {contentDisplayName}
                                </span>
                            </div>
                            <FormTextField
                                label="Link Name"
                                name="display_name"
                                placeholder="Link Name"
                                disabled={readOnlyMode}
                            />
                            <FormTextField
                                label="Description"
                                name="description"
                                placeholder="Description"
                                disabled={readOnlyMode}
                            />
                            {linkTypeIdState === 2 && (
                                <>
                                    <FormControlLabel
                                        name="auto_play"
                                        label="Autoplay"
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={Boolean(
                                                    values?.auto_play
                                                )}
                                                onChange={(e) => {
                                                    setFieldValue(
                                                        'auto_play',
                                                        e.target.checked
                                                    );
                                                }}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        name="show_chapters"
                                        label="Display Chapters"
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={Boolean(
                                                    values?.show_chapters
                                                )}
                                                onChange={(e) => {
                                                    if (!e.target.checked) {
                                                        setFieldValue(
                                                            'collapse_chapters',
                                                            false
                                                        );
                                                    }
                                                    setFieldValue(
                                                        'show_chapters',
                                                        e.target.checked
                                                    );
                                                }}
                                            />
                                        }
                                    />
                                    {values?.show_chapters && (
                                        <FormControlLabel
                                            name="collapse_chapters"
                                            label="Collapse Chapters"
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={Boolean(
                                                        values?.collapse_chapters
                                                    )}
                                                    onChange={(e) => {
                                                        setFieldValue(
                                                            'collapse_chapters',
                                                            e.target.checked
                                                        );
                                                    }}
                                                />
                                            }
                                        />
                                    )}
                                </>
                            )}
                            <FormSelectField
                                name="display_type_id"
                                label="Display Type"
                                placeholder="Display type"
                                entities={DisplayTypes}
                                valueProperty="id"
                                nameProperty="label"
                                keyProperty="label"
                            />
                            <FormSelectField
                                name="content_version_id"
                                label="Version"
                                placeholder="Version"
                                disabled={readOnlyMode}
                                entities={values.content_versions}
                                valueProperty="content_version_id"
                                nameProperty="version"
                                keyProperty="content_version_id"
                                namePrefix="Version "
                                defaultOptionValue="Latest Version"
                                defaultOptionName="Latest Version"
                            />
                            <FormControl style={{ margin: '5px 0' }} fullWidth>
                                <VideoChapterField />
                            </FormControl>

                            <Field className={classes.input} name="audiences">
                                {({
                                    field: { value },
                                    form: { setFieldValue },
                                }) => (
                                    <SelectionAutocomplete
                                        title="Audiences"
                                        keyProperty="audience_id"
                                        nameProperty="display_name"
                                        entityIds={value}
                                        setEntityIds={setFieldValue}
                                        entitySelector={selectAllAudiences}
                                        entityStatusSelector={getAudienceStatus}
                                        fetchEntityPage={fetchAudiencesPage}
                                        formField={'audiences'}
                                        fetchParams={{ page: 1, pageSize: 100 }}
                                        readOnly={readOnlyMode}
                                    />
                                )}
                            </Field>
                            {readOnlyMode !== true && (
                                <>
                                    <SetDirtyForm />
                                    <button
                                        className={classes.submit}
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        {formAction.buttonLabel}
                                    </button>
                                </>
                            )}
                        </>
                    )}
                    {tabView && (
                        <button
                            type="button"
                            className={classes.cancel}
                            onClick={handleCancel}
                        >
                            Cancel
                        </button>
                    )}
                </Form>
            )}
        </Formik>
    );
};

export { LinkForm };
