import React, { useMemo } from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    InputLabel,
    FormControl,
    MenuItem,
    Select,
    IconButton,
} from '@mui/material';
import { MdClose } from 'react-icons/md';

const NavIconEditDialog = ({
    open,
    handleClose,
    targetZoneDefaultValue,
    targetZoneOnChange,
    targetZoneData,
    iconsDefaultValue,
    iconsOnChange,
    iconsData,
}) => {
    const sortedTargetZone = useMemo(() => {
        if (Array.isArray(targetZoneData) && targetZoneData.length > 0) {
            return [...targetZoneData]?.sort((a, b) => {
                if (a?.display_name < b?.display_name) return -1;
                if (a?.display_name > b?.display_name) return 1;
                return 0;
            });
        }
        return targetZoneData;
    }, [targetZoneData]);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle
                sx={{
                    backgroundColor: '#78706e',
                    color: '#000',
                    fontSize: '20px',
                    fontWeight: '600',
                    padding: '10px 24px',
                }}
            >
                Edit Navigation Icon
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 6,
                        color: '#000',
                    }}
                    size="large"
                >
                    <MdClose className="react-icon" />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ width: '400px', marginTop: '10px' }}>
                <FormControl sx={{ margin: '8px 0' }} fullWidth>
                    <InputLabel id="target-zone-label">Target Zone</InputLabel>
                    <Select
                        labelId="target-zone-label"
                        id="target-zone-id"
                        label="Target Zone"
                        value={targetZoneDefaultValue}
                        onChange={targetZoneOnChange}
                    >
                        {sortedTargetZone?.length > 0 &&
                            sortedTargetZone.map((item) => (
                                <MenuItem
                                    key={item?.zone_id}
                                    value={item?.zone_id}
                                >
                                    {item?.display_name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>

                <FormControl fullWidth sx={{ margin: '8px 0 0 0' }}>
                    <InputLabel id="icon-label">Icon</InputLabel>
                    <Select
                        labelId="icon-label"
                        id="icon-id"
                        label="Icon"
                        value={iconsDefaultValue}
                        onChange={iconsOnChange}
                    >
                        {iconsData?.length > 0 &&
                            iconsData.map(
                                (item, index) =>
                                    item.is_default && (
                                        <MenuItem
                                            key={index}
                                            style={{
                                                borderBottom:
                                                    '1px solid #424242',
                                                background: '#c7c7c7db',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        marginRight: '15px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <img
                                                        src={item?.image_url}
                                                        alt={item?.display_name}
                                                        style={{
                                                            width: '30px',
                                                        }}
                                                    />
                                                </div>
                                                <div>Default</div>
                                            </div>
                                        </MenuItem>
                                    )
                            )}

                        {iconsData.length > 0 &&
                            iconsData.map((item) => (
                                <MenuItem
                                    key={item?.hotspot_icon_id}
                                    value={item?.hotspot_icon_id}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div
                                            style={{
                                                marginRight: '15px',
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <img
                                                src={item?.image_url}
                                                alt={item?.display_name}
                                                style={{
                                                    width: '30px',
                                                }}
                                            />
                                        </div>
                                        <div>{item?.display_name}</div>
                                    </div>
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </DialogContent>
        </Dialog>
    );
};

export { NavIconEditDialog };
