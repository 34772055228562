import React, { useState, useRef, useEffect, useCallback } from 'react';
import { MdAdd } from 'react-icons/md';
import { AutoSizer } from 'react-virtualized';
import { IconButton, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { Action } from '_helpers';
import { useStyles } from './styles';
import SearchWithFilters from 'components/SearchWithFilters';
import { tableStyles } from 'components/_Tables/TableCells/styles';
import { fetchAllCategories } from '_features/category/categorySlice';
import { debounce } from 'GeminiViewerComponent/_helpers/lodashUtils';
import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { makeButtonStyles } from '../../../../GeminiViewerComponent/components/styles';
import { EnhancedTableToolbar } from 'GeminiViewerComponent/components/EnhancedTableToolbar';
import {
    closePanel,
    openPanel,
    setEditObjectId,
} from '_features/common/formSlice';
import {
    labelHeader,
    labelCell,
    actionCell,
    checkCell,
    checkHeaderCell,
    VirtualTable,
} from 'components';

const CategoryTable = ({
    categories,
    pageInfo,
    categoryPayload,
    setCategoryPayload,
    sortDirection,
    sortBy,
    handleSort,
}) => {
    //#region Constants
    const headerHeight = 40;
    const rowHeight = 60;
    //#endregion Constants

    //#region Hooks
    const dispatch = useDispatch();
    const classes = useStyles();
    const tableClasses = tableStyles();
    //#endregion Hooks

    //#region State
    const [selected, setSelected] = useState([]);
    const [selectedAll, setSelectedAll] = useState(false);
    //#endregion State

    //#region Selectors
    const theme = useSelector(selectActiveTheme);
    const buttonStyles = makeButtonStyles(theme);
    const loadedRowsMap = useSelector(
        (state) => state.category.loadedCategoryMap
    );
    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Effects
    //#endregion Effects

    //#region Methods
    const isSelected = (id) => selected.indexOf(id) !== -1;
    const prevSelectedAll = usePrevious(selectedAll);
    if (prevSelectedAll && selectedAll) {
        if (selected.length !== categories.length) {
            setSelected(categories.map((row) => row.category_id));
            setSelectedAll(true);
        }
    }

    const handleClick = (event, category_id) => {
        event.stopPropagation();
        const selectedIndex = selected.indexOf(category_id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, category_id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
        setSelectedAll(selected.length === categories.length);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelectedIds = categories.map(
                (category) => category.category_id
            );
            setSelected(newSelectedIds);
            setSelectedAll(true);
            return;
        }
        setSelected([]);
        setSelectedAll(false);
    };

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const handleRowClick = async ({ rowData }) => {
        await dispatch(
            closePanel({
                formKey: 'categoryForm',
            })
        );
        if (rowData?.category_id) {
            await dispatch(
                setEditObjectId({
                    formKey: `categoryForm`,
                    object_id: rowData?.category_id,
                })
            );
            dispatch(
                openPanel({
                    formKey: `categoryForm`,
                    formAction: 'Edit',
                })
            );
        }
    };

    const columns = [
        {
            classes: tableClasses,
            minWidth: 50,
            maxWidth: 50,
            width: 50,
            label: 'Select',
            dataKey: 'category_id',
            padding: 'checkbox',
            cellLevel: 'category',
            numSelected: selected.length,
            rowCount: categories.length,
            handleClick: handleClick,
            handleSelectAllClick: handleSelectAllClick,
            isSelected: isSelected,
            component: checkCell,
            headerComponent: checkHeaderCell,
        },
        {
            classes: tableClasses,
            minWidth: 50,
            maxWidth: 50,
            width: 50,
            label: '',
            dataKey: 'category_id',
            padding: 'none',
            actionCellLevel: 'category',
            targetPopup: 'categoryManagement',
            formKey: 'categoryForm',
            dispatch: dispatch,
            component: actionCell,
            headerComponent: labelHeader,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 120,
            minWidth: 120,
            label: 'Category',
            dataKey: 'display_name',
            padding: 'normal',
            component: labelCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },

        {
            classes: tableClasses,
            flexGrow: 1,
            width: 120,
            minWidth: 120,
            label: 'N° Assets',
            dataKey: 'asset_count',
            padding: 'normal',
            component: labelCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
    ];

    function loadMoreRows({ startIndex, stopIndex }) {
        dispatch(
            fetchAllCategories({
                ...categoryPayload,
                pageSize: stopIndex + 1,
            })
        );
    }

    const debouncedSearch = useCallback(
        debounce((searchString) => {
            setCategoryPayload({
                ...categoryPayload,
                searchString: searchString,
            });
        }, 1000),
        [categoryPayload]
    );

    const handleCreateNew = () => {
        dispatch(
            openPanel({
                formKey: 'categoryForm',
                formAction: 'Create',
                clearEditId: true,
            })
        );
    };
    //#endregion Methods

    //#region Render time calcs
    //#endregion Render time calcs

    //#region Render
    return (
        <div className={classes.root}>
            {selected.length > 0 && (
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    toolTipTitle={'Delete'}
                    totalCount={pageInfo.TotalCount}
                >
                    <PopupAction
                        action={Action.DeleteArray}
                        object={{
                            categoryIds: selected,
                            setCategoryIds: setSelected,
                        }}
                        level={'category'}
                        showLabel={false}
                    />
                </EnhancedTableToolbar>
            )}
            <div style={{ display: 'flex', gap: '5px' }}>
                <Tooltip title="Add New Account">
                    <IconButton
                        onClick={handleCreateNew}
                        size="large"
                        className={buttonStyles.contentAddButton}
                    >
                        <MdAdd className="react-icon" />
                    </IconButton>
                </Tooltip>
                <div style={{ flexGrow: 1 }}></div>
                <SearchWithFilters
                    filter={false}
                    onChange={(evt) => debouncedSearch(evt.target.value)}
                />
            </div>
            <div style={{ height: 'calc(100vh - 295px)', minWidth: '500px' }}>
                <AutoSizer>
                    {({ width, height }) => (
                        <VirtualTable
                            initialRows={categories}
                            allRows={categories}
                            totalRowCount={pageInfo.TotalCount}
                            loadedRowsMap={loadedRowsMap}
                            loadMoreRows={loadMoreRows}
                            columnDefinitions={columns}
                            cellClassName={tableClasses.flexContainer}
                            className={tableClasses.table}
                            rowClassName={tableClasses.flexContainer}
                            headerHeight={headerHeight}
                            width={width}
                            height={height}
                            rowHeight={rowHeight}
                            onRowClick={handleRowClick}
                        />
                    )}
                </AutoSizer>
            </div>
        </div>
    );
    //#endregion Render
};

export { CategoryTable };
