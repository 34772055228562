import React, { useEffect, useRef, useState } from 'react';

import { ManageDialog } from 'components/Snippets/components/ManageDialog';
import { CreateDialog } from 'components/Snippets/components/CreateDialog';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import {
    fetchFolders,
    fetchSnippets,
    getAllFolders,
    getAllSnippets,
    getFolderStatus,
    getSnippetStatus,
    setSnippet,
} from '_features/snippets/snippetsSlice';
import { FormActions } from '_helpers';
import { Editor } from '@tinymce/tinymce-react';

const TinyMCEEditor = ({ ...props }) => {
    const dispatch = useDispatch();
    const editorRef = useRef(null);
    const snippetsStatus = useSelector(getSnippetStatus);
    const foldersStatus = useSelector(getFolderStatus);
    const snippets = useSelector(getAllSnippets);
    const folders = useSelector(getAllFolders);
    const [createSnippet, setCreateSnippet] = useState(false);
    const [manageSnippet, setManageSnippet] = useState(null);

    useEffect(() => {
        if (snippetsStatus === LoadingStatus.Idle) {
            dispatch(fetchSnippets());
        }
        if (foldersStatus === LoadingStatus.Idle) {
            dispatch(fetchFolders());
        }
    }, [dispatch, foldersStatus]);

    useEffect(() => {
        if (editorRef.current) {
            const editor = editorRef.current;
            insert_snippet(editor);
            snippet_shortcut(editor);
        }
    }, [snippets, folders]);

    const clear_content = (editor) => {
        editor.ui.registry.addMenuItem('clearcontent', {
            text: 'Clear Content',
            icon: 'new-document',
            onAction: async () => {
                await editor.focus();
                await editor.setContent('');
            },
        });
    };

    const insert_snippet = (editor) => {
        const getSnippets = (folder, editor) => {
            return snippets
                .filter(
                    (item) =>
                        item.snippet_folder_id === folder.snippet_folder_id
                )
                .map((snippet) => {
                    return {
                        type: 'menuitem',
                        text: snippet.display_name,
                        onAction: (e) => {
                            editor.insertContent(`${snippet.source}`);
                        },
                    };
                });
        };

        const getSnippetFolders = (editor) => {
            return folders.map((folder) => ({
                type: 'nestedmenuitem',
                text: folder.display_name,
                icon: 'folderIcon',
                getSubmenuItems: () => {
                    return getSnippets(folder, editor);
                },
            }));
        };

        editor.ui.registry.addMenuButton('insertSnippet', {
            tooltip: 'Insert Snippet',
            icon: 'insertSnippetIcon',
            fetch: (callback) => {
                callback(getSnippetFolders(editor));
            },
        });

        editor.ui.registry.addNestedMenuItem('insert', {
            text: 'Insert',
            icon: 'insertSnippetIcon',
            getSubmenuItems: () => getSnippetFolders(editor),
        });
    };

    const create_icons = (editor) => {
        editor.ui.registry.addIcon(
            'folderIcon',
            '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1.1em" width="1.1em" xmlns="http://www.w3.org/2000/svg"><path d="M464 128H272l-64-64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V176c0-26.51-21.49-48-48-48z"></path></svg>'
        );

        editor.ui.registry.addIcon(
            'insertSnippetIcon',
            '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1.65em" width="1.65em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M20.41 8.41l-4.83-4.83c-.37-.37-.88-.58-1.41-.58H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V9.83c0-.53-.21-1.04-.59-1.42zM7 7h7v2H7V7zm10 10H7v-2h10v2zm0-4H7v-2h10v2z"></path></svg>'
        );

        editor.ui.registry.addIcon(
            'inlineVideoIcon',
            '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2C6.48,2 2,6.48 2,12C2,17.52 6.48,22 12,22C17.52,22 22,17.52 22,12C22,6.48 17.52,2 12,2M10,16.5L16,12L10,7.5V16.5Z" /></svg>'
        );
    };

    const create_snippet = (editor) => {
        editor.ui.registry.addNestedMenuItem('create', {
            text: 'Create',
            icon: 'plus',
            getSubmenuItems: () => {
                return [
                    {
                        type: 'menuitem',
                        text: 'From Selection',
                        onAction: () => {
                            dispatch(
                                setSnippet({
                                    source: editor.selection.getContent(),
                                })
                            );
                            setCreateSnippet(true);
                        },
                    },
                    {
                        type: 'menuitem',
                        text: 'From Content',
                        onAction: () => {
                            dispatch(
                                setSnippet({ source: editor.getContent() })
                            );
                            setCreateSnippet(true);
                        },
                    },
                ];
            },
        });
    };

    const manage_snippet = (editor) => {
        editor.ui.registry.addMenuItem('manage', {
            text: 'Manage...',
            icon: 'edit-block',
            onAction: () => {
                setManageSnippet(true);
            },
        });
    };

    const snippet_shortcut = (editor) => {
        const handleTabKey = (editor) => {
            var isCaret = false;
            var sel = editor.selection.getSel();
            if (sel.type === 'Caret') {
                isCaret = true;
                var rng = new Range();
                rng.setStartBefore(sel.anchorNode);
                rng.setEndAfter(sel.anchorNode);
                editor.selection.setRng(rng);
            }

            var content = editor.selection
                .getContent({ format: 'text' })
                .toLowerCase();

            const sortedSnippets = [
                ...snippets.filter((x) => x.trigger_key !== undefined),
            ];
            sortedSnippets.sort(
                (a, b) => b.trigger_key.length - a.trigger_key.length
            );

            const snippet = sortedSnippets.find((text) => {
                let re = new RegExp(
                    '(^|\\W)' + text.trigger_key + '($|\\W)',
                    'g'
                );
                return content.match(re);
            });

            if (snippet) {
                editor.selection.setContent(snippet?.source);
                editor.selection.collapse(false);
                return true;
            } else {
                if (isCaret) {
                    editor.selection.collapse(false);
                }
            }
            return false;
        };

        editor.on('keydown', (e) => {
            if (e.key === 'Tab') {
                if (handleTabKey(editor) == true) {
                    e.preventDefault();
                }
            }
        });
    };

    return (
        <>
            <Editor
                {...props}
                init={{
                    ...props.init,
                    setup: (editor) => {
                        editorRef.current = editor;
                        if (props?.init?.setup) {
                            props.init.setup(editor);
                        }
                        create_icons(editor);
                        if (snippets.length > 0 && folders.length > 0) {
                            insert_snippet(editor);
                        }
                        create_snippet(editor);
                        manage_snippet(editor);
                        snippet_shortcut(editor);
                        clear_content(editor);
                    },
                }}
            />
            {createSnippet && (
                <CreateDialog
                    formAction={FormActions.Create}
                    setSnippet={setCreateSnippet}
                />
            )}
            {manageSnippet && (
                <ManageDialog setManageSnippet={setManageSnippet} />
            )}
        </>
    );
};

export { TinyMCEEditor };
