const clamp = (value, lower, upper) => {
    const min = Math.min(lower, upper);
    const max = Math.max(lower, upper);
    return Math.min(Math.max(value, min), max);
};

const remove = (array, condition) => {
    if (typeof condition === 'function') {
        array.map(condition).forEach((getCondition, index) => {
            if (getCondition) {
                array.splice(index, 1);
            }
        });
    } else {
        for (let i = array.length - 1; i >= 0; i--) {
            let shouldRemove = true;
            for (const key in condition) {
                if (
                    typeof array[i][key] === 'object' ||
                    typeof condition[key] === 'object'
                ) {
                    if (
                        JSON.stringify(array[i][key]) !==
                        JSON.stringify(condition[key])
                    ) {
                        shouldRemove = false;
                        break;
                    }
                } else {
                    if (array[i][key] !== condition[key]) {
                        shouldRemove = false;
                        break;
                    }
                }
            }
            if (shouldRemove) {
                array.splice(i, 1);
            }
        }
    }
};

const last = (array) => {
    if (array && array.length > 0) {
        return array[array.length - 1];
    }
    return undefined;
};

const uniqBy = (array, iteratee) => {
    const uniqueKeys = new Set();

    return array.filter((item) => {
        const key =
            typeof iteratee === 'function' ? iteratee(item) : item[iteratee];

        if (!uniqueKeys.has(key)) {
            uniqueKeys.add(key);
            return true;
        }

        return false;
    });
};

const orderBy = (array, props, dir) => {
    return array.sort((a, b) => {
        for (let i = 0; i < props.length; i++) {
            const getter = props[i];
            const order = dir[i] === 'asc' ? 1 : -1;

            const aValue = getter(a);
            const bValue = getter(b);

            if (aValue < bValue) return -1 * order;
            if (aValue > bValue) return 1 * order;
        }
        return 0;
    });
};

const cloneDeep = (obj) => {
    return JSON.parse(JSON.stringify(obj));
};

const get = (object, path, defaultValue) => {
    const keys = path.split('.');
    let value = object;

    for (let key of keys) {
        if (value && typeof value === 'object' && key in value) {
            value = value[key];
        } else {
            return defaultValue;
        }
    }

    return value !== undefined ? value : defaultValue;
};

const groupBy = (array, key) => {
    if (Array.isArray(array) && array.length > 0) {
        return array.reduce((result, item) => {
            const keyValue = item[key];
            (result[keyValue] = result[keyValue] || []).push(item);
            return result;
        }, {});
    } else return {};
};

const isEmpty = (value) => {
    if (value == null) return true;
    if (
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (Array.isArray(value) && value.length === 0) ||
        (typeof value === 'string' && value.trim() === '')
    ) {
        return true;
    }
    if (typeof value === 'number' && !isNaN(value)) return true;
    return false;
};

const isEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
};

const sort = (a, b, dir = 'DESC') => {
    if (a === b) return 0;
    if (a > b) return dir === 'DESC' ? 1 : -1;
    return dir === 'DESC' ? -1 : 1;
};

const sortBy = (key, dir) => (a, b) => {
    if (typeof a[key] === 'string' && typeof b[key] === 'string') {
        return sort(a[key].toLowerCase(), b[key].toLowerCase(), dir);
    } else {
        return sort(a[key], b[key], dir);
    }
};

const map = (array, property) => {
    if (Array.isArray(array) && array?.length > 0) {
        return array?.map((status) => status?.[property]);
    } else {
        if (typeof array === 'object') {
            return Object.values(array).map((status) => status?.[property]);
        } else {
            return array;
        }
    }
};

const debounce = (func, delay) => {
    let timeoutId;

    return function (...args) {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
};

export {
    clamp,
    remove,
    last,
    uniqBy,
    orderBy,
    cloneDeep,
    get,
    groupBy,
    isEmpty,
    isEqual,
    sortBy,
    map,
    debounce,
};
