import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { hotspotService } from '_features/_services';
import {
    LoadingStatus,
    SavingStatus,
} from 'GeminiViewerComponent/_helpers/AsyncStatus';

const ITEM_HOTSPOT_ID = 1;
const NAVIGATION_HOTSPOT_ID = 2;

const initialState = {
    hotspotsStatus: LoadingStatus.Idle,
    defaultHotspotsStatus: LoadingStatus.Idle,
    hotspots: [],
    loadedHotspotsMap: {},
    displayHotspot: {},
    hotspotPageInfo: {},
    defaultNavHotspotIconId: 1,
    defaultItemHotspotIconId: 2,
    hotspotTypes: [],
    enableMultipleHotspot: false,
    enableItemDragging: true,
    enableNavDragging: true,
    hotspotUsage: {},
};

export const fetchDefaultHotspots = createAsyncThunk(
    'hotspots/fetchDefaultHotspots',
    async (params = {}, { getState }) => {
        const response = await hotspotService.getAll(
            params.searchString,
            params.sort,
            getState().accounts.activeUser.s3_prefix
        );
        return response;
    }
);

export const fetchAllHotspotIcons = createAsyncThunk(
    'hotspots/fetchAllHotspotIcons',
    async (params = {}, { getState }) => {
        const response = await hotspotService.getAll(
            params.searchString,
            params.sort,
            params.s3_prefix ?? getState().accounts.activeUser.s3_prefix
        );
        return response;
    }
);

export const fetchHotspotTypes = createAsyncThunk(
    'hotspots/fetchHotspotTypes',
    async (params) => {
        const response = await hotspotService.getHotspotTypes();
        return response.hotspot_types;
    }
);

export const getHotspotById = createAsyncThunk(
    'hotspots/getHotspotById',
    async (hotspotId, { getState }) => {
        const response = await hotspotService.getById(
            hotspotId,
            getState().accounts.activeUser.s3_prefix
        );
        response.id = hotspotId;
        return response;
    }
);

export const editHotspot = createAsyncThunk(
    'hotspots/editHotspot',
    async (newHotspot) => await hotspotService.update(newHotspot)
);

export const addNewHotspot = createAsyncThunk(
    'hotspots/addNewHotspot',
    async (newHotspot) => await hotspotService.create(newHotspot)
);

export const deleteHotspotById = createAsyncThunk(
    'hotspots/deleteHotspotById',
    async (hotspotId) => {
        const response = await hotspotService.delete(hotspotId);
        response.id = hotspotId;
        return response;
    }
);

export const deleteHotspotArray = createAsyncThunk(
    'hotspots/deleteHotspotArray',
    async (hotspotIds, { rejectWithValue }) => {
        try {
            const response = await hotspotService.deleteArray(hotspotIds);
            response.id = hotspotIds;
            return response;
        } catch (err) {
            return rejectWithValue(err.error);
        }
    }
);

export const setHotspotAsDefault = createAsyncThunk(
    'hotspots/setHotspotAsDefault',
    async (hotspotId) => {
        const response = await hotspotService.setHotspotAsDefault(hotspotId);
        return response;
    }
);

export const setHotspotAsHighlight = createAsyncThunk(
    'hotspots/setHotspotAsHighlight',
    async (hotspotId) => {
        const response = await hotspotService.setHotspotAsHighlight(hotspotId);
        return response;
    }
);

export const fetchHotspotUsageById = createAsyncThunk(
    'groups/fetchHotspotUsageById',
    async (hotspotId) => await hotspotService.getHotspotUsageById(hotspotId)
);

const hotspotsSlice = createSlice({
    name: 'hotspots',
    initialState,
    reducers: {
        setHotspotRefreshFlag: (state) => {
            state.hotspotsStatus = LoadingStatus.Idle;
        },
        setEnableMultipleHotspot: (state, action) => {
            state.enableMultipleHotspot = action.payload;
        },
        resetHotspotState: (state) => initialState,
        setItemDragging: (state) => {
            state.enableItemDragging = !state.enableItemDragging;
        },
        setNavDragging: (state) => {
            state.enableNavDragging = !state.enableNavDragging;
        },
    },
    extraReducers: {
        [fetchDefaultHotspots.pending]: (state, action) => {
            state.defaultHotspotsStatus = LoadingStatus.Loading;
        },
        [fetchDefaultHotspots.fulfilled]: (state, action) => {
            state.defaultNavHotspotIconId = action.payload?.hotspots?.find(
                (val) => val.is_default === true && val.hotspot_type_id === 2
            ).hotspot_icon_id;
            state.defaultItemHotspotIconId = action.payload?.hotspots?.find(
                (val) => val.is_default === true && val.hotspot_type_id === 1
            ).hotspot_icon_id;
            state.defaultHotspotsStatus = LoadingStatus.Loaded;
        },
        [fetchDefaultHotspots.rejected]: (state, action) => {
            state.defaultHotspotsStatus = LoadingStatus.Failed;
        },
        [fetchAllHotspotIcons.fulfilled]: (state, action) => {
            state.hotspotsStatus = LoadingStatus.Loaded;
            state.hotspotPageInfo = action.payload.pageInfo;
            state.hotspots = action.payload.hotspots;
        },
        [fetchHotspotTypes.fulfilled]: (state, action) => {
            state.hotspotTypes = action.payload;
        },
        [getHotspotById.fulfilled]: (state, action) => {
            state.displayHotspot = action.payload;
        },
        [addNewHotspot.fulfilled]: (state, action) => {
            state.hotspotsStatus = LoadingStatus.Idle;
        },
        [editHotspot.fulfilled]: (state, action) => {
            state.hotspotsStatus = LoadingStatus.Idle;
        },
        [deleteHotspotById.fulfilled]: (state, action) => {
            state.hotspotsStatus = LoadingStatus.Idle;
        },
        [setHotspotAsDefault.fulfilled]: (state, action) => {
            state.hotspotsStatus = LoadingStatus.Idle;
        },
        [setHotspotAsHighlight.fulfilled]: (state, action) => {
            state.hotspotsStatus = LoadingStatus.Idle;
        },
        [deleteHotspotArray.fulfilled]: (state, action) => {
            state.hotspotsStatus = LoadingStatus.Idle;
        },
        [deleteHotspotArray.rejected]: (state, action) => {
            if (
                Array.isArray(action.payload?.deleted_ids) &&
                action.payload?.deleted_ids?.length > 0
            ) {
                state.hotspotsStatus = LoadingStatus.Idle;
            } else {
                state.status = SavingStatus.Failed;
                state.error = action.error.message;
            }
        },
        [fetchHotspotUsageById.fulfilled]: (state, action) => {
            state.hotspotUsage = action.payload;
        },
    },
});

export const selectAllHotspots = (state) => state.hotspots.hotspots;
export const getHotspotPageInfo = (state) => state.hotspots.hotspotPageInfo;
export const getHotspotStatus = (state) => state.hotspots.hotspotsStatus;
export const getHotspotTypes = (state) => state.hotspots.hotspotTypes;
export const getItemDragging = (state) => state.hotspots.enableItemDragging;
export const getNavDragging = (state) => state.hotspots.enableNavDragging;
export const getItemHotspots = (state) =>
    state.hotspots.hotspots?.filter(
        (item) => item.hotspot_type_id === ITEM_HOTSPOT_ID
    );
export const getNavigationHotspots = (state) =>
    state.hotspots.hotspots?.filter(
        (item) => item.hotspot_type_id === NAVIGATION_HOTSPOT_ID
    );
export const getIsMultipleHotspotEnabled = (state) =>
    state.hotspots.enableMultipleHotspot;
export const getHotspotUsage = (state) => state.hotspots.hotspotUsage;

export const {
    setHotspotRefreshFlag,
    resetHotspotState,
    setEnableMultipleHotspot,
    setItemDragging,
    setNavDragging,
} = hotspotsSlice.actions;

export default hotspotsSlice.reducer;
