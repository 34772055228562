import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    Button,
    Box,
    Autocomplete,
    Checkbox,
    TextField,
    Grid,
    useTheme,
    Tooltip,
    IconButton,
} from '@mui/material';
import moment from 'moment';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import useResizeObserver from 'use-resize-observer';
import { useSnackbar } from 'notistack';
import {
    MdCheckBox,
    MdCheckBoxOutlineBlank,
    MdCheckCircle,
    MdList,
    MdMenu,
} from 'react-icons/md';
import ReactDOMServer from 'react-dom/server';

import { useStyles } from './styles';
import {
    getActiveTree,
    getCurrentNode,
    gotoNode,
    nextNode,
    previousNode,
    resetCurrentTree,
    setActiveTreeId,
    setProcedureAnswer,
    setParentTreeId,
    setIsBranchNode,
    findNextNode,
} from 'GeminiViewerComponent/components/Procedure/_features/procedureSlice';
import {
    makeCommonChecklistStyles,
    customTinyMce,
    overrideResetStyles,
} from '../../styles';
import interceptClicks from 'GeminiViewerComponent/_helpers/interceptClicks';
import {
    findVideoChapter,
    getProcedureContentVersion,
    setLinkDisplayVis,
} from 'GeminiViewerComponent/_features/globals/visibilitySlice';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { saveStatement } from 'GeminiViewerComponent/_features/xAPI/xapiSlice';
import { LrsVerbs } from 'GeminiViewerComponent/_features/xAPI/xApiData';
import { getNodesHistoricalAnswers } from 'GeminiViewerComponent/_helpers/node_helpers';
import {
    getActiveSessionId,
    getChecklistData,
    setChecklistData,
} from '../../_features/checklistDataSlice';
import ProcedureFormNode from '../ProcedureFormNode';
import { getProcedureSession } from 'GeminiViewerComponent/_features/procedureSession/procedureSessionSlice';
import { useStoreSessionData } from 'GeminiViewerComponent/hooks/useStoreSessionData';
import { getConfig } from 'GeminiViewerComponent/_features/config/configSlice';
import { ProcedureNodeTypes } from '../../ProcedureNodeTypes';
import { accountsSlice } from 'app/store';
import {
    validateInspectionPoint,
    validateRequiredField,
} from './_helpers/duplicateFieldValidation';
import { getDependencyContents } from 'GeminiViewerComponent/_features/asset/assetSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { timeToSeconds } from 'GeminiViewerComponent/_helpers/helper';
const { selectActiveUser } = accountsSlice;

const ProcedureQuestions = ({
    displayNode,
    displayNodesFields,
    contentService,
    useZoneNav,
    isSubmitAllow,
    isReadOnly,
    isInDrawer,
    idx = null,
    openNavigationPanel,
    setOpenNavigationPanel,
}) => {
    const theme = useSelector(selectActiveTheme);
    const procedureRef = useRef(null);
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles(theme);
    const commonStyles = makeCommonChecklistStyles(theme);
    const tinyMceCustomClass = customTinyMce(theme);
    const overrideResetClasses = overrideResetStyles();
    const dispatch = useDispatch();
    const activeTree = useSelector(getActiveTree);
    const procedureContentVersion = useSelector(getProcedureContentVersion);
    const activeUser = useSelector(selectActiveUser);
    const currentNode = useSelector(getCurrentNode);
    const userData = useSelector(getChecklistData);
    const getSessionData = useSelector(getProcedureSession);
    const config = useSelector(getConfig);
    const activeSessionId = useSelector(getActiveSessionId);
    const dependencyContents = useSelector(getDependencyContents);
    const [activeChapter, setActiveChapter] = useState([]);
    const [isChapterVideo, setIsChapterVideo] = useState({
        chapterPlayed: false,
        chapterCapture: false,
    });
    const setActiveChapterRef = useRef(activeChapter);
    const isChapterVideoRef = useRef(isChapterVideo);
    const procedureFormId = 'procedure-form-' + currentNode.id;
    const [formFields, setNodeFields] = useState(displayNodesFields);
    const [formValues, setFormValues] = useState({});
    const [fieldValidationData, setFieldValidationData] = useState({});
    const [clickedBtnValue, setClickedBtnValue] = useState(false);
    const [isChapterLoaded, setIsChapterLoaded] = useState(false);
    const [selectedAnswers, setSelectedAnswers] = useState(
        currentNode?.answer
            ? currentNode?.answer_group === 'select'
                ? Array.isArray(currentNode?.answer?.options_required) &&
                  currentNode?.answer?.options_required?.length > 0
                    ? [...currentNode.answer.options_required]
                    : currentNode?.multiple_answers_allow === true
                    ? [currentNode?.answer]
                    : currentNode?.answer
                : currentNode?.answer_group === 'image'
                ? Array.isArray(currentNode?.answer?.options_required) &&
                  currentNode?.answer?.options_required?.length > 0
                    ? [...currentNode.answer.options_required]
                    : [currentNode?.answer]
                : currentNode?.answer
            : (currentNode?.answer_group === 'select' &&
                  currentNode?.multiple_answers_allow === true) ||
              currentNode?.answer_group === 'image'
            ? []
            : null
    );

    let initValue = {};
    let fieldValidations = {};
    const setValidation = (field) => {
        let variable = field?.variable;
        if (
            field?.type === 'text' ||
            field?.type === 'select' ||
            field?.type === 'multiline' ||
            field?.type === 'radiogroup' ||
            field?.type === 'initial'
        ) {
            initValue[variable] = '';
            fieldValidations[variable] = Yup.array().of(Yup.string());
            if (field?.required === true) {
                fieldValidations[variable] = Yup.array().required('Required');
            }
        } else if (field?.type === 'checkbox') {
            initValue[variable] = false;
            fieldValidations[variable] = Yup.array()
                .of(Yup.boolean())
                .transform((currentValue, originalValue) => {
                    if (currentValue === null) {
                        return [false];
                    }
                    return currentValue;
                })
                .nullable();
            if (field?.required === true) {
                fieldValidations[variable] = Yup.array()
                    .of(Yup.boolean())
                    .nullable()
                    .required('Required');
            }
        } else if (field?.type === 'datetime') {
            initValue[variable] = null;
            fieldValidations[variable] = Yup.object().nullable();
            if (field?.autofill === true) {
                initValue[variable] = [
                    moment(Date.now()).format(
                        field?.date_time_type === 'datetime'
                            ? 'MM/DD/YYYY h:mm A'
                            : field?.date_time_type === 'time'
                            ? 'h:mm A'
                            : 'MM/DD/YYYY'
                    ),
                ];
            }
            if (field?.required === true) {
                fieldValidations[variable] = Yup.array()
                    .nullable()
                    // .test(
                    //     'valid_date_test',
                    //     'Please select valid date.',
                    //     function (value) {
                    //         return moment(
                    //             value,
                    //             field?.date_time_type === 'datetime'
                    //                 ? 'MM/DD/YYYY h:mm A'
                    //                 : field?.date_time_type === 'time'
                    //                 ? 'h:mm A'
                    //                 : 'MM/DD/YYYY',
                    //             true
                    //         ).isValid();
                    //     }
                    // )
                    .required('Required');
            }
        } else if (field?.type === 'inspectionpoint') {
            initValue[variable] = [
                {
                    field_id: '',
                    action: '',
                    reason: '',
                },
            ];
            if (field?.required === true) {
                fieldValidations[variable] = Yup.array()
                    .of(
                        Yup.object({
                            field_id: Yup.string().required('Required'),
                            action: Yup.string().required('Required'),
                            reason: Yup.string().required('Required'),
                        })
                    )
                    .test('format-errors', 'Invalid format', function (value) {
                        const errors = {};
                        if (value) {
                            value.forEach((field, index) => {
                                if (!field.action) {
                                    errors[`[${index}].action`] = 'Required';
                                }
                                if (!field.reason) {
                                    errors[`[${index}].reason`] = 'Required';
                                }
                            });
                        }
                        return Object.keys(errors).length === 0 ? true : errors;
                    });
            }
        }
    };

    useEffect(() => {
        document.getElementById(procedureFormId).scrollIntoView();
        initValue = {};
        fieldValidations = {};
        displayNodesFields &&
            displayNodesFields.length > 0 &&
            displayNodesFields?.map((field) => {
                if (field?.type === 'group' || field?.type === 'table') {
                    field?.fields &&
                        field?.fields.length > 0 &&
                        field?.fields?.map((subfield) => {
                            setValidation(subfield);
                            return null;
                        });
                } else {
                    setValidation(field);
                }
                return null;
            });
        setNodeFields(displayNodesFields);
        setFieldValidationData(fieldValidations);
        setFormValues(userData ? { ...initValue, ...userData } : initValue);
    }, [displayNodesFields, currentNode]);

    useEffect(() => {
        if (userData && Object.keys(userData)?.length === 0) {
            initValue = {};
            fieldValidations = {};
            displayNodesFields &&
                displayNodesFields.length > 0 &&
                displayNodesFields?.map((field) => {
                    if (field?.type === 'group' || field?.type === 'table') {
                        field?.fields &&
                            field?.fields.length > 0 &&
                            field?.fields?.map((subfield) => {
                                setValidation(subfield);
                                return null;
                            });
                    } else {
                        setValidation(field);
                    }
                    return null;
                });
            setFieldValidationData(fieldValidations);
            setFormValues(initValue);
        }
    }, [userData]);

    useEffect(() => {
        setSelectedAnswers(
            currentNode?.answer
                ? currentNode?.answer_group === 'select'
                    ? Array.isArray(currentNode?.answer?.options_required) &&
                      currentNode?.answer?.options_required?.length > 0
                        ? [...currentNode.answer.options_required]
                        : currentNode?.multiple_answers_allow === true
                        ? [currentNode?.answer]
                        : currentNode?.answer
                    : currentNode?.answer_group === 'image'
                    ? Array.isArray(currentNode?.answer?.options_required) &&
                      currentNode?.answer?.options_required?.length > 0
                        ? [...currentNode.answer.options_required]
                        : [currentNode?.answer]
                    : currentNode?.answer
                : (currentNode?.answer_group === 'select' &&
                      currentNode?.multiple_answers_allow === true) ||
                  currentNode?.answer_group === 'image'
                ? []
                : null
        );
        setIsChapterLoaded(false);
    }, [currentNode]);

    useEffect(() => {
        setActiveChapterRef.current = activeChapter;
        isChapterVideoRef.current = isChapterVideo;
        for (let index = 0; index < activeChapter.length; index++) {
            const chapterButtons = document.querySelectorAll(
                `div[accessKey="${index}"]`
            );
            chapterButtons.forEach((button) => {
                if (
                    +button.getAttribute('data-item-chapter-id') ===
                    activeChapter[index]?.chapter_id
                ) {
                    button.classList.add('activeChapterButton');
                } else {
                    button.classList.remove('activeChapterButton');
                }
            });
        }
    }, [activeChapter, isChapterVideo]);

    useEffect(() => {
        if (!isChapterLoaded) {
            displayVideoChapters();
        }
    }, [isChapterLoaded]);

    const displayVideoChapters = useCallback(() => {
        if (!isChapterLoaded) {
            setIsChapterLoaded(true);
            const videos = document.querySelectorAll(
                'video[data-target-show_chapters="true"]'
            );
            videos.forEach((video, index) => {
                const contentId = video.getAttribute('data-target-contentid');
                const contentVersionId = video.getAttribute(
                    'data-target-selectedVersion'
                );

                if (config.modeType === 'viewer') {
                    getContentFromDependencies(
                        +contentId,
                        video,
                        index,
                        +contentVersionId
                    );
                } else {
                    fetchContent(+contentId, video, index, +contentVersionId);
                }
            });
        }
    }, [isChapterLoaded]);

    const { gotoZone, gotoZoneAndItem } = useZoneNav();

    const { ref, width = 1 } = useResizeObserver();
    const { width: formWidth } = useResizeObserver({ ref: procedureRef });

    useEffect(() => {
        return interceptClicks(
            document.querySelector('.gemini-node-content'),
            {
                //
                // Leave all these as defauts:
                //
                // modifierKeys: true
                // download: true
                // target: true
                // hash: true
                // mailTo: true

                // Intercept all clicks, even ones that are not same origin
                sameOrigin: false,
            },
            async function (e, el) {
                // e.stopPropagation();
                e.preventDefault();
                let params = new URLSearchParams(el.search);
                console.log(`Clicked on link with params ${params}`);
                let contentId = params.get('content_id');
                let autoPlay = params.get('auto_play');
                let linkTypeId = Number(params.get('link_type_id') ?? 2);
                var stmtResponse = {
                    contentId: contentId,
                    autoPlay: autoPlay,
                    linkTypeId: linkTypeId,
                };
                dispatch(
                    saveStatement({
                        LrsVerb: LrsVerbs.Interacted,
                        response: `${JSON.stringify(stmtResponse)}`,
                        contentId,
                    })
                );
                if (contentId) {
                    let content = await contentService.fetchContentById(
                        contentId,
                        activeUser.s3_prefix
                    );
                    dispatch(
                        setLinkDisplayVis({
                            open: true,
                            linkTypeId: content.default_link_type_id,
                            linkContent:
                                content?.last_content_version
                                    ?.encoding_status &&
                                content?.last_content_version?.encoding_status.toLowerCase() ===
                                    'complete'
                                    ? content?.last_content_version?.encoded_url
                                    : content.last_content_version.url,
                            linkData:
                                typeof content?.last_content_version ===
                                'object'
                                    ? content?.last_content_version
                                    : {},
                            autoPlay: autoPlay,
                            overlayText:
                                content?.last_content_version?.overlay_text,
                        })
                    );
                } else {
                    let nodeId = params.get('node_id');
                    let zoneId = params.get('zone_id');
                    let itemId = params.get('item_id');
                    let linkId = params.get('link_id');
                    if (nodeId) {
                        if (zoneId) {
                            if (itemId) {
                                dispatch(
                                    saveStatement({
                                        LrsVerb: LrsVerbs.Progressed,
                                        response: { zoneId, itemId },
                                        zoneId,
                                        itemId,
                                    })
                                );
                                gotoZoneAndItem(zoneId, itemId, linkId);
                            } else {
                                dispatch(
                                    saveStatement({
                                        LrsVerb: LrsVerbs.Progressed,
                                        response: { nodeId, zoneId },
                                        zoneId,
                                    })
                                );
                                gotoZone(zoneId);
                            }
                        }
                        dispatch(gotoNode(Number(nodeId)));
                    } else if (zoneId) {
                        if (itemId) {
                            dispatch(
                                saveStatement({
                                    LrsVerb: LrsVerbs.Progressed,
                                    response: { zoneId, itemId },
                                    zoneId,
                                    itemId,
                                })
                            );
                            gotoZoneAndItem(zoneId, itemId);
                        } else {
                            dispatch(
                                saveStatement({
                                    LrsVerb: LrsVerbs.Progressed,
                                    response: { zoneId },
                                    zoneId,
                                })
                            );
                            gotoZone(zoneId);
                        }
                    } else {
                        dispatch(
                            saveStatement({
                                LrsVerb: LrsVerbs.Progressed,
                                response: {
                                    linkTypeId,
                                    linkContent: el.href,
                                    autoPlay,
                                },
                            })
                        );
                        if (config.modeType === 'author') {
                            dispatch(
                                setLinkDisplayVis({
                                    open: true,
                                    chapter_id:
                                        +el.getAttribute('data-chapter-id') ||
                                        null,
                                    linkContent: el.href,
                                    linkTypeId: linkTypeId,
                                    linkData: null,
                                    autoPlay: autoPlay,
                                    overlayText: null,
                                })
                            );
                        } else {
                            const chapter_data = findVideoChapter(
                                {
                                    content_id: +el.getAttribute(
                                        'data-target-contentid'
                                    ),
                                    content_version_id: +el.getAttribute(
                                        'data-target-selectedversion'
                                    ),
                                    chapter_id:
                                        +el.getAttribute('data-chapter-id') ||
                                        null,
                                },
                                dependencyContents || []
                            );
                            dispatch(
                                setLinkDisplayVis({
                                    open: true,
                                    chapter_data: chapter_data,
                                    linkContent: el.href,
                                    linkTypeId: linkTypeId,
                                    linkData: null,
                                    autoPlay: autoPlay,
                                    overlayText: null,
                                })
                            );
                        }
                    }
                }
                // Change the page state here
            }
        );
    }, [contentService, gotoZone, gotoZoneAndItem, dispatch]);

    const handleBlur = (values) => dispatch(setChecklistData(values));

    const updateSessionData = useCallback(() => {
        useStoreSessionData(dispatch, activeTree, userData, getSessionData);
    }, [activeTree, userData, getSessionData]);

    const handleSubmit = useCallback(
        (values, resetForm) => {
            if (isSubmitAllow) {
                let inspectioKey = [];
                let inspectioPoint = [];
                Object.entries(values).map((inspectionField) => {
                    if (
                        inspectionField?.[1]?.field_id ||
                        inspectionField?.[1]?.action ||
                        inspectionField?.[1]?.reason
                    ) {
                        inspectioKey.push(inspectionField[0]);
                        inspectioPoint.push(inspectionField[1]);
                    }
                });
                values.inspection_points = inspectioPoint;

                let formValues = { ...values };

                if (inspectioKey.length > 0) {
                    inspectioKey.map((key) => {
                        delete formValues[key];
                    });
                }
                values = { ...formValues };

                if (
                    userData?.imgAtt &&
                    typeof userData?.imgAtt === 'object' &&
                    !Array.isArray(userData?.imgAtt)
                ) {
                    values = { ...values, imgAtt: userData?.imgAtt };
                }
                if (clickedBtnValue.type === 'button') {
                    dispatch(setChecklistData(values));
                    handleButtonAnswer(clickedBtnValue?.button_id);
                    setSelectedAnswers(null);
                    return;
                }
                if (clickedBtnValue.type === 'select') {
                    dispatch(setChecklistData(values));
                    handleSelectAnswer(selectedAnswers, true);
                    setSelectedAnswers(null);
                    return;
                }
                if (clickedBtnValue.type === 'image') {
                    dispatch(setChecklistData(values));
                    handleImageAnswer(selectedAnswers, true);
                    setSelectedAnswers(null);
                    return;
                }
                dispatch(
                    saveStatement({
                        LrsVerb: LrsVerbs.Submitted,
                        extensions: JSON.stringify(values),
                    })
                );
                if (clickedBtnValue?.button_id) {
                    const selectedButton = currentNode.option_buttons.find(
                        (button) =>
                            button.button_id === clickedBtnValue?.button_id
                    );
                    dispatch(
                        setProcedureAnswer({
                            id: currentNode.id,
                            answer: selectedButton,
                        })
                    );
                    if (
                        currentNode?.variable &&
                        clickedBtnValue?.answer_value
                    ) {
                        values = {
                            ...values,
                            [`${currentNode?.variable}`]:
                                clickedBtnValue?.answer_value,
                        };
                    }
                }
                dispatch(setChecklistData(values));
                if (clickedBtnValue?.action?.type === 'node') {
                    if (
                        clickedBtnValue?.action?.id === 'next' ||
                        clickedBtnValue?.action?.id === 'submit'
                    ) {
                        dispatch(nextNode());
                    } else if (clickedBtnValue?.action?.id === 'back') {
                        dispatch(previousNode());
                    } else {
                        dispatch(gotoNode(clickedBtnValue?.action?.id));
                    }
                }
                updateSessionData();
                resetForm();
            } else if (isReadOnly) {
                if (clickedBtnValue.type === 'button') {
                    dispatch(setChecklistData(values));
                    handleButtonAnswer(clickedBtnValue?.button_id);
                    setSelectedAnswers(null);
                    return;
                }
                if (clickedBtnValue.type === 'select') {
                    dispatch(setChecklistData(values));
                    handleSelectAnswer(selectedAnswers, true);
                    setSelectedAnswers(null);
                    return;
                }
                if (clickedBtnValue.type === 'image') {
                    dispatch(setChecklistData(values));
                    handleImageAnswer(selectedAnswers, true);
                    setSelectedAnswers(null);
                    return;
                }
            }
        },
        [
            clickedBtnValue,
            userData,
            currentNode,
            isSubmitAllow,
            isReadOnly,
            selectedAnswers,
        ]
    );

    const handleButtonAnswer = async (buttonId) => {
        const selectedButton = currentNode.option_buttons.find(
            (button) => button.button_id === buttonId
        );

        await dispatch(
            setProcedureAnswer({ id: currentNode.id, answer: selectedButton })
        );
        dispatch(
            saveStatement({
                LrsVerb: LrsVerbs.Answered,
                response:
                    selectedButton.answer_value ?? selectedButton.button_text,
                extensions: getNodesHistoricalAnswers(activeTree),
            })
        );
        if (currentNode?.variable) {
            await dispatch(
                setChecklistData({
                    [currentNode.variable]: selectedButton.answer_value,
                })
            );
        }
        const type = selectedButton.action.type
            ? selectedButton.action.type
            : 'node';

        if (type === 'sub') {
            dispatch(setActiveTreeId(selectedButton.action?.id));
            dispatch(setParentTreeId(activeTree.id));
        }
        if (type === 'node') {
            if (
                selectedButton.action.id === 'next' ||
                selectedButton.action.id === 'submit'
            ) {
                dispatch(nextNode());
            } else if (selectedButton.action.id === 'back') {
                dispatch(previousNode());
            } else {
                dispatch(gotoNode(selectedButton.action.id));
            }
        }
        updateSessionData();
    };

    const handleSelectAnswer = async (selectedOptions, isSubmit = false) => {
        let selectedAnswersGroup = null;
        if (currentNode?.multiple_answers_allow === true && !isSubmit) {
            setSelectedAnswers(selectedOptions);
        } else {
            setSelectedAnswers(selectedOptions);
            const totalOptionsSelected = Array.isArray(selectedOptions)
                ? selectedOptions.length
                : 1;
            if (totalOptionsSelected > 1) {
                const selectedOptionsText = selectedOptions.map(
                    (opt) => opt.option_text
                );
                if (currentNode?.select_target_combinations?.length > 0) {
                    const combinations =
                        currentNode.select_target_combinations.filter(
                            (target) =>
                                target.options_required?.length ===
                                totalOptionsSelected
                        );
                    combinations.map((target) => {
                        const totalMatch = target.options_required.filter(
                            (opt) =>
                                selectedOptionsText.includes(opt.option_text)
                        ).length;
                        if (
                            !selectedAnswersGroup &&
                            totalMatch === totalOptionsSelected
                        ) {
                            selectedAnswersGroup = target;
                        }
                        return null;
                    });
                }
            } else if (totalOptionsSelected === 1) {
                selectedAnswersGroup = currentNode?.select_options.find(
                    (opt) =>
                        opt?.option_text ===
                        (Array.isArray(selectedOptions)
                            ? selectedOptions[0]?.option_text
                            : selectedOptions?.option_text)
                );
            }
            if (selectedAnswersGroup) {
                await dispatch(
                    setProcedureAnswer({
                        id: currentNode.id,
                        answer: selectedAnswersGroup,
                    })
                );
                if (currentNode?.variable) {
                    await dispatch(
                        setChecklistData({
                            [currentNode.variable]:
                                selectedAnswersGroup.answer_value,
                        })
                    );
                }
                const type = selectedAnswersGroup.action.type
                    ? selectedAnswersGroup.action.type
                    : 'node';

                if (type === 'sub') {
                    dispatch(setActiveTreeId(selectedAnswersGroup.action?.id));
                    dispatch(setParentTreeId(activeTree.id));
                }
                if (type === 'node') {
                    if (selectedAnswersGroup.action.id === 'next') {
                        dispatch(nextNode());
                    } else if (selectedAnswersGroup.action.id === 'back') {
                        dispatch(previousNode());
                    } else {
                        dispatch(gotoNode(selectedAnswersGroup.action.id));
                    }
                }
            } else {
                enqueueSnackbar(
                    totalOptionsSelected === 0
                        ? 'Select Answers'
                        : 'Incorrect Answers',
                    {
                        action: (key) => <SnackbarDismiss key={key} />,
                        variant: 'error',
                    }
                );
            }
        }
        updateSessionData();
    };

    const handleImageAnswer = async (selectedOptions, isSubmit = false) => {
        let selectedAnswersGroup = null;
        if (currentNode?.multiple_answers_allow === true && !isSubmit) {
            setSelectedAnswers(selectedOptions);
        } else {
            setSelectedAnswers(selectedOptions);
            const totalOptionsSelected = selectedOptions.length;
            if (totalOptionsSelected > 1) {
                const selectedOptionsText = selectedOptions.map(
                    (opt) => opt.option_text
                );
                if (currentNode?.image_target_combinations?.length > 0) {
                    const combinations =
                        currentNode.image_target_combinations.filter(
                            (target) =>
                                target.options_required?.length ===
                                totalOptionsSelected
                        );
                    combinations.map((target) => {
                        const totalMatch = target.options_required.filter(
                            (opt) =>
                                selectedOptionsText.includes(opt.option_text)
                        ).length;
                        if (
                            !selectedAnswersGroup &&
                            totalMatch === totalOptionsSelected
                        ) {
                            selectedAnswersGroup = target;
                        }
                        return null;
                    });
                }
            } else if (totalOptionsSelected === 1) {
                selectedAnswersGroup = currentNode?.image_options.find(
                    (opt) =>
                        opt?.option_text === selectedOptions[0]?.option_text
                );
            }
            if (selectedAnswersGroup) {
                await dispatch(
                    setProcedureAnswer({
                        id: currentNode.id,
                        answer: selectedAnswersGroup,
                    })
                );
                if (currentNode?.variable) {
                    await dispatch(
                        setChecklistData({
                            [currentNode.variable]:
                                selectedAnswersGroup.answer_value,
                        })
                    );
                }
                const type = selectedAnswersGroup.action.type
                    ? selectedAnswersGroup.action.type
                    : 'node';

                if (type === 'sub') {
                    dispatch(setActiveTreeId(selectedAnswersGroup.action?.id));
                    dispatch(setParentTreeId(activeTree.id));
                }
                if (type === 'node') {
                    if (selectedAnswersGroup.action.id === 'next') {
                        dispatch(nextNode());
                    } else if (selectedAnswersGroup.action.id === 'back') {
                        dispatch(previousNode());
                    } else {
                        dispatch(gotoNode(selectedAnswersGroup.action.id));
                    }
                }
            } else {
                enqueueSnackbar(
                    totalOptionsSelected === 0
                        ? 'Select Image'
                        : 'Incorrect Answers',
                    {
                        action: (key) => <SnackbarDismiss key={key} />,
                        variant: 'error',
                    }
                );
            }
        }
        updateSessionData();
    };
    // const handleNext = () => {
    //     if (
    //         currentNode.next_action?.type === 'return' &&
    //         activeTree.parent_tree_id
    //     ) {
    //         dispatch(resetCurrentTree());
    //         dispatch(setActiveTreeId(activeTree.parent_tree_id));
    //     } else {
    //         dispatch(nextNode());
    //     }
    // };

    const handleReturn = () => {
        if (activeTree.parent_tree_id) {
            dispatch(resetCurrentTree());
            dispatch(setActiveTreeId(activeTree.parent_tree_id));
            dispatch(gotoNode(activeTree.return_node_id));
        }
    };

    const handleBack = () => {
        if (
            currentNode.back_action?.type === 'return' &&
            activeTree.parent_tree_id
        ) {
            dispatch(resetCurrentTree());
            dispatch(setActiveTreeId(activeTree.parent_tree_id));
        } else {
            dispatch(previousNode());
        }
    };

    const showNextButton =
        currentNode.next_button &&
        (currentNode.answer ||
            !currentNode.answer_group ||
            (currentNode.answer_group === 'button' &&
                (!currentNode.option_buttons ||
                    currentNode.option_buttons?.length === 0)) ||
            (currentNode.answer_group === 'select' &&
                (!currentNode.select_options ||
                    currentNode.select_options?.length === 0)) ||
            (currentNode.answer_group === 'image' &&
                (!currentNode.image_options ||
                    currentNode.image_options?.length === 0)));

    const muiTheme = useTheme();
    const smWidth = muiTheme.breakpoints.values.sm;

    const buttonsFirst = width < smWidth;
    let questionOrder = 1;
    let buttonOrder = 2;
    if (buttonsFirst) {
        questionOrder = 2;
        buttonOrder = 1;
    }

    const questionColumns = width < smWidth ? 12 : 8;
    const buttonColumns = width < smWidth ? 12 : 4;

    const showQuestion =
        currentNode.question.length > 0 ||
        currentNode.option_buttons?.length > 0 ||
        currentNode.select_options?.length > 0 ||
        currentNode.image_options?.length > 0;

    const showReturnButton = activeTree.parent_tree_id;

    const nodes = Array.isArray(activeTree.nodes) && activeTree.nodes;
    const completionNode = nodes.find((node) => node?.completion === true);
    const isResultNode = currentNode.type === ProcedureNodeTypes.results.type;
    const isCompletionNode = useMemo(
        () =>
            (config.modeType === 'viewer' &&
                activeTree?.save_sessions &&
                activeSessionId &&
                (currentNode?.type === ProcedureNodeTypes.content.type ||
                    currentNode?.type === ProcedureNodeTypes.results.type) &&
                completionNode?.id === currentNode.id) ||
            (procedureContentVersion &&
                procedureContentVersion?.procedure_end_node === currentNode.id),
        [
            config,
            activeTree,
            activeSessionId,
            currentNode,
            completionNode,
            procedureContentVersion,
        ]
    );

    const validateDuplicateField = (values) => {
        let errors = {};
        displayNodesFields.map((field) => {
            if (field?.type === 'group' || field?.type === 'table') {
                field?.fields &&
                    field?.fields.length > 0 &&
                    field?.fields?.map((subfield) => {
                        Object.keys(values).map((key) => {
                            if (
                                subfield.variable === key &&
                                subfield.required === true
                            ) {
                                if (Array.isArray(values[key])) {
                                    values[key].forEach((e, idx) => {
                                        if (
                                            values[key].filter(Boolean)
                                                .length !== values[key].length
                                        ) {
                                            errors = {
                                                ...errors,
                                                ...validateRequiredField(
                                                    errors,
                                                    key,
                                                    e,
                                                    idx
                                                ),
                                            };
                                        } else {
                                            if (
                                                subfield.type ===
                                                'inspectionpoint'
                                            ) {
                                                errors = {
                                                    ...errors,
                                                    ...validateInspectionPoint(
                                                        errors,
                                                        key,
                                                        e,
                                                        idx
                                                    ),
                                                };
                                            }
                                        }
                                    });
                                }
                            }
                        });
                    });
            } else {
                if (field.required === true) {
                    if (field.type === 'datetime') {
                        if (!values[field.variable]) {
                            errors = {
                                ...errors,
                                [field.variable]: 'Required',
                            };
                        } else {
                            if (typeof values[field.variable] === 'object') {
                                if (!values[field.variable].find(Boolean)) {
                                    errors = {
                                        ...errors,
                                        [field.variable]: 'Required',
                                    };
                                }
                            }
                        }
                    }
                    if (field.type === 'inspectionpoint') {
                        if (!values[field.variable]) {
                            errors = {
                                ...errors,
                                ...validateInspectionPoint(
                                    errors,
                                    field.variable,
                                    values[field.variable],
                                    0
                                ),
                            };
                        } else {
                            if (typeof values[field.variable] === 'object') {
                                values[field.variable].forEach((e, idx) => {
                                    errors = {
                                        ...errors,
                                        ...validateInspectionPoint(
                                            errors,
                                            field.variable,
                                            e,
                                            idx
                                        ),
                                    };
                                });
                            }
                        }
                    }
                }
            }
        });
        return errors;
    };

    const handleTimeUpdate = useCallback(
        (e, video, index) => {
            const activeChapter = setActiveChapterRef.current;
            const isChapterVideo = isChapterVideoRef.current;
            if (activeChapter && activeChapter.length > 0) {
                if (
                    e.target.currentTime >=
                        timeToSeconds(activeChapter?.[index]?.end_time) &&
                    !isChapterVideo?.[index]?.chapterPlayed
                ) {
                    video.pause();
                    setIsChapterVideo((prev) => ({
                        ...prev,
                        chapterPlayed: { ...prev.chapterPlayed, [index]: true },
                    }));
                }
            }
        },
        [setActiveChapterRef, isChapterVideoRef]
    );

    const handleSeekCapture = useCallback(
        (e, index) => {
            const activeChapter = setActiveChapterRef.current;

            if (activeChapter && activeChapter.length > 0) {
                if (
                    e.target.currentTime <
                    timeToSeconds(activeChapter?.[index]?.end_time)
                ) {
                    setIsChapterVideo((prev) => ({
                        ...prev,
                        chapterPlayed: {
                            ...prev.chapterPlayed,
                            [index]: false,
                        },
                    }));
                }
            }
        },
        [setActiveChapterRef, isChapterVideoRef]
    );

    const displayChapterButtons = (videoChapters, video, index) => {
        const collapseChapter = video.getAttribute(
            'data-target-collapse_chapters'
        );

        // Chapter Buttons Wrapper
        const buttonsDiv = document.createElement('div');
        buttonsDiv.style.display =
            collapseChapter === 'false' ? 'flex' : 'none';
        buttonsDiv.style.overflow = 'auto';
        buttonsDiv.style.height = video.getAttribute('height') + 'px';
        buttonsDiv.style.flexDirection = 'column';
        buttonsDiv.style.paddingRight = '10px';

        // Chapter Buttons Label
        const chapterLabel = document.createElement('p');
        chapterLabel.textContent = 'Chapters';
        chapterLabel.style.backgroundColor = '#000000';
        chapterLabel.style.color = '#FFFFFF';
        chapterLabel.style.borderRadius = '4px';
        chapterLabel.style.padding = '5px';
        chapterLabel.style.fontWeight = '600';
        chapterLabel.style.textAlign = 'center';
        chapterLabel.style.margin = '0 0 5px';
        chapterLabel.style.position = 'sticky';
        chapterLabel.style.top = '0px';
        chapterLabel.style.zIndex = '2';

        // Chapter toggle button
        const menuButton = document.createElement('button');
        menuButton.className = 'videoChapterCollapseButton';
        menuButton.style.backgroundColor =
            collapseChapter === 'false' ? '#000' : '#555555';

        const componentHTML = ReactDOMServer.renderToString(
            <MdList
                className="react-icon"
                style={{ backgroundColor: 'transparent', color: '#ffffff' }}
            />
        );
        menuButton.innerHTML = componentHTML;

        const wrapperDiv = document.createElement('div');
        wrapperDiv.style.position = 'relative';
        wrapperDiv.appendChild(menuButton);

        if (videoChapters && videoChapters?.length > 0) {
            const chapters = [...videoChapters].sort(
                (a, b) => a.chapter_id - b.chapter_id
            );
            buttonsDiv.appendChild(chapterLabel);
            chapters.forEach((item) => {
                const button = document.createElement('div');
                button.classList.add('chaptersButton');
                button.accessKey = index;
                button.setAttribute('data-item-chapter-id', item.chapter_id);
                const buttonText = document.createElement('p');
                buttonText.classList.add('chaptersButtonText');
                buttonText.textContent = item.title;
                button.appendChild(buttonText);

                button.onclick = () => {
                    setActiveChapter((prev) => {
                        const newChapter = [...prev];
                        newChapter[index] = item;
                        return newChapter;
                    });
                    video.currentTime = timeToSeconds(item.start_time);
                    if (
                        video?.getAttribute('data-target-auto_play') === 'true'
                    ) {
                        video.play();
                    }
                };
                buttonsDiv.appendChild(button);
            });
            video.addEventListener('play', () => {
                menuButton.style.display = 'none';
            });
            video.addEventListener('pause', () => {
                menuButton.style.display = 'flex';
            });
            video.addEventListener('timeupdate', (e) =>
                handleTimeUpdate(e, video, index)
            );
            video.addEventListener('seeking', (e) =>
                handleSeekCapture(e, index)
            );
            video.parentNode.insertBefore(buttonsDiv, video);
            video.parentNode.insertBefore(wrapperDiv, video);
        }
        menuButton.onclick = () => {
            buttonsDiv.style.display =
                buttonsDiv.style.display === 'none' ? 'flex' : 'none';
            menuButton.style.backgroundColor =
                buttonsDiv.style.display === 'none' ? '#555555' : '#000';
        };
    };

    const playSelectedChapter = (videoChapters, video, index) => {
        const selectedChapterId = video.getAttribute(
            'data-target-selectedChapter'
        );
        const chapters = [...videoChapters];
        const selectedChapter = chapters.find(
            (chapter) => chapter.chapter_id === +selectedChapterId
        );
        // || chapters.sort((a, b) => b.chapter_id - a.chapter_id).pop();
        if (Object.keys(selectedChapter || {}).length > 0) {
            setActiveChapter((prev) => {
                const newChapter = [...prev];
                newChapter[index] = selectedChapter;
                return newChapter;
            });
            if (+selectedChapterId) {
                video.currentTime = timeToSeconds(selectedChapter?.start_time);
            }
        }
    };

    const fetchContent = async (contentId, video, index, contentVersionId) => {
        dispatch(activateLoading());
        const versions = await contentService.getContentVersions(
            contentId,
            activeUser.s3_prefix
        );
        if (versions && versions.length > 0) {
            let contentVersions = null;
            if (contentVersionId) {
                contentVersions = versions.find(
                    (version) => version.content_version_id === contentVersionId
                );
            } else {
                contentVersions = [...versions]?.pop();
            }
            if (
                typeof contentVersions === 'object' &&
                Object.keys(contentVersions).length > 0
            ) {
                displayChapterButtons(contentVersions?.chapters, video, index);
                playSelectedChapter(contentVersions?.chapters, video, index);
            }
        }

        dispatch(deactivateLoading());
    };

    const getContentFromDependencies = async (
        contentId,
        video,
        index,
        contentVersionId
    ) => {
        const data = dependencyContents.find(
            (content) => content.content_id === contentId
        );
        if (data?.content_versions && data?.content_versions?.length > 0) {
            let contentVersions = null;
            if (contentVersionId) {
                contentVersions = data?.content_versions?.find(
                    (version) => version.content_version_id === contentVersionId
                );
            } else {
                contentVersions = [...data.content_versions]?.pop();
            }
            if (
                typeof contentVersions === 'object' &&
                Object.keys(contentVersions).length > 0
            ) {
                displayChapterButtons(contentVersions?.chapters, video, index);
                playSelectedChapter(contentVersions?.chapters, video, index);
            }
        }
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={formValues}
            validationSchema={Yup.object({ ...fieldValidationData })}
            validate={validateDuplicateField}
            onSubmit={(values, { resetForm }) => {
                handleSubmit(values, resetForm);
            }}
        >
            {({ validateForm, submitForm }) => (
                <Form style={{ padding: '10px 5px' }}>
                    <Grid
                        container
                        className={classes.checkListContainer}
                        ref={ref}
                        id={procedureFormId}
                    >
                        <Grid
                            item
                            container
                            style={{ marginBottom: '10px' }}
                            gap={2}
                        >
                            <Grid item xs={12} className={classes.nodeTitle}>
                                {isSubmitAllow &&
                                    (!activeTree?.disable_navigation_pane ||
                                        activeTree.disable_navigation_pane ===
                                            false) && (
                                        <Tooltip title="Navigation Pane">
                                            <IconButton
                                                className={classes.nodeButton}
                                                onClick={() => {
                                                    setOpenNavigationPanel(
                                                        !openNavigationPanel
                                                    );
                                                }}
                                            >
                                                {openNavigationPanel ? (
                                                    <MdMenu className="react-icon" />
                                                ) : (
                                                    <MdMenu className="react-icon" />
                                                )}
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                {displayNode.title}
                                <div style={{ display: 'flex', gap: '10px' }}>
                                    {!isCompletionNode &&
                                        currentNode.back_button && (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleBack}
                                                className={commonStyles.button}
                                            >
                                                Back
                                            </Button>
                                        )}
                                    {!isCompletionNode && showNextButton && (
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={commonStyles.button}
                                            onClick={(e) => {
                                                const nextNode = findNextNode(
                                                    activeTree.nodes,
                                                    activeTree.current_node
                                                );
                                                if (
                                                    nextNode.type ===
                                                    ProcedureNodeTypes.branch
                                                        .type
                                                ) {
                                                    dispatch(
                                                        setIsBranchNode(true)
                                                    );
                                                }
                                                if (!isSubmitAllow) {
                                                    validateForm();
                                                    e.preventDefault();
                                                } else {
                                                    setClickedBtnValue({
                                                        action: {
                                                            type: 'node',
                                                            id: 'next',
                                                        },
                                                    });
                                                }
                                            }}
                                        >
                                            Next
                                        </Button>
                                    )}
                                    {!isCompletionNode &&
                                        !isReadOnly &&
                                        showReturnButton && (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleReturn}
                                                className={commonStyles.button}
                                            >
                                                Return
                                            </Button>
                                        )}
                                </div>
                            </Grid>
                            {!isCompletionNode && showQuestion && (
                                <Grid
                                    item
                                    container
                                    className={
                                        classes.checkListContentContainer
                                    }
                                    spacing={'10px'}
                                >
                                    {/* {currentNode.question && ( */}
                                    <Grid
                                        item
                                        xs={questionColumns}
                                        order={{ xs: questionOrder }}
                                        className={classes.confirmationTitle}
                                    >
                                        {currentNode.question}
                                    </Grid>
                                    {/* )} */}
                                    {currentNode.answer_group === 'hotspot' ? (
                                        'hotspot'
                                    ) : currentNode.answer_group === 'image' ? (
                                        <Grid
                                            item
                                            xs={buttonColumns}
                                            order={{ xs: buttonOrder }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                }}
                                            >
                                                {currentNode?.image_options
                                                    ?.length > 0 &&
                                                    currentNode?.image_options?.map(
                                                        (image_option) => {
                                                            let isSelected =
                                                                null;
                                                            if (
                                                                Array.isArray(
                                                                    selectedAnswers
                                                                )
                                                            ) {
                                                                isSelected =
                                                                    selectedAnswers?.find(
                                                                        (opt) =>
                                                                            opt.option_text ===
                                                                            image_option.option_text
                                                                    );
                                                            }

                                                            const optionWidth =
                                                                '200px';
                                                            const optionHeight =
                                                                '180px';
                                                            return (
                                                                <div
                                                                    key={
                                                                        image_option.option_text
                                                                    }
                                                                    style={{
                                                                        position:
                                                                            'relative',
                                                                        width: optionWidth,
                                                                        height: optionHeight,
                                                                        marginRight:
                                                                            '15px',
                                                                        marginBottom:
                                                                            '15px',
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={
                                                                            image_option?.img_url
                                                                        }
                                                                        alt={
                                                                            image_option?.img_name
                                                                        }
                                                                        width={
                                                                            optionWidth
                                                                        }
                                                                        height={
                                                                            optionHeight
                                                                        }
                                                                        onClick={async () => {
                                                                            let filterAnswers =
                                                                                [];
                                                                            const isAlreadySelected =
                                                                                selectedAnswers?.find(
                                                                                    (
                                                                                        opt
                                                                                    ) =>
                                                                                        opt.option_text ===
                                                                                        image_option.option_text
                                                                                );
                                                                            setClickedBtnValue(
                                                                                {
                                                                                    type: 'image',
                                                                                }
                                                                            );
                                                                            if (
                                                                                currentNode?.multiple_answers_allow ===
                                                                                true
                                                                            ) {
                                                                                if (
                                                                                    isAlreadySelected
                                                                                ) {
                                                                                    filterAnswers =
                                                                                        selectedAnswers?.filter(
                                                                                            (
                                                                                                opt
                                                                                            ) =>
                                                                                                opt.option_text !==
                                                                                                image_option.option_text
                                                                                        );
                                                                                } else {
                                                                                    filterAnswers =
                                                                                        [
                                                                                            ...selectedAnswers,
                                                                                            image_option,
                                                                                        ];
                                                                                }
                                                                                setSelectedAnswers(
                                                                                    filterAnswers
                                                                                );
                                                                            } else {
                                                                                filterAnswers =
                                                                                    [
                                                                                        image_option,
                                                                                    ];
                                                                                setSelectedAnswers(
                                                                                    filterAnswers
                                                                                );
                                                                                await submitForm();
                                                                            }
                                                                        }}
                                                                        style={{
                                                                            border: `3px solid ${
                                                                                isSelected
                                                                                    ? '#3598db'
                                                                                    : '#d0d6d7'
                                                                            }`,
                                                                            borderRadius:
                                                                                '5px',
                                                                        }}
                                                                    />
                                                                    {isSelected && (
                                                                        <MdCheckCircle
                                                                            className="react-icon"
                                                                            style={{
                                                                                position:
                                                                                    'absolute',
                                                                                top: '8px',
                                                                                right: '8px',
                                                                                fill: '#3598db',
                                                                            }}
                                                                        />
                                                                    )}
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                            </div>
                                            {currentNode.multiple_answers_allow && (
                                                <div
                                                    style={{
                                                        textAlign: 'right',
                                                        marginTop: '15px',
                                                    }}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        type="submit"
                                                        onClick={() => {
                                                            setClickedBtnValue({
                                                                type: 'image',
                                                            });
                                                        }}
                                                        className={
                                                            commonStyles.button
                                                        }
                                                    >
                                                        Submit
                                                    </Button>
                                                </div>
                                            )}
                                        </Grid>
                                    ) : currentNode.answer_group === 'select' &&
                                      Array.isArray(
                                          currentNode?.select_options
                                      ) ? (
                                        <Grid
                                            item
                                            xs={buttonColumns}
                                            order={{ xs: buttonOrder }}
                                        >
                                            <div>
                                                <Autocomplete
                                                    key={`select-answer`}
                                                    limitTags={3}
                                                    multiple={
                                                        currentNode.multiple_answers_allow
                                                    }
                                                    variant="outline"
                                                    id={`select-answer`}
                                                    disableCloseOnSelect
                                                    options={
                                                        currentNode.select_options
                                                    }
                                                    isOptionEqualToValue={(
                                                        option,
                                                        value
                                                    ) =>
                                                        option.option_id ===
                                                        value.option_id
                                                    }
                                                    value={
                                                        selectedAnswers
                                                            ? selectedAnswers
                                                            : currentNode?.multiple_answers_allow
                                                            ? []
                                                            : null
                                                    }
                                                    onChange={(
                                                        event,
                                                        newInputValue
                                                    ) => {
                                                        setClickedBtnValue({
                                                            type: 'select',
                                                        });
                                                        setSelectedAnswers(
                                                            newInputValue
                                                        );
                                                        if (
                                                            !currentNode?.multiple_answers_allow
                                                        ) {
                                                            submitForm();
                                                        }
                                                    }}
                                                    style={{ width: '100%' }}
                                                    getOptionLabel={(option) =>
                                                        option.option_text
                                                    }
                                                    renderOption={(
                                                        props,
                                                        option,
                                                        { selected }
                                                    ) => {
                                                        return (
                                                            <li {...props}>
                                                                <Checkbox
                                                                    icon={
                                                                        <MdCheckBoxOutlineBlank className="react-icon" />
                                                                    }
                                                                    checkedIcon={
                                                                        <MdCheckBox className="react-icon" />
                                                                    }
                                                                    style={{
                                                                        marginRight: 8,
                                                                    }}
                                                                    checked={
                                                                        selected
                                                                    }
                                                                />
                                                                {
                                                                    option.option_text
                                                                }
                                                            </li>
                                                        );
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            label="Answer"
                                                            placeholder="Select Answer"
                                                        />
                                                    )}
                                                />
                                            </div>
                                            {currentNode.multiple_answers_allow && (
                                                <div
                                                    style={{
                                                        textAlign: 'right',
                                                        marginTop: '15px',
                                                    }}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        type="submit"
                                                        onClick={() => {
                                                            setClickedBtnValue({
                                                                type: 'select',
                                                            });
                                                        }}
                                                        className={
                                                            commonStyles.button
                                                        }
                                                    >
                                                        Submit
                                                    </Button>
                                                </div>
                                            )}
                                        </Grid>
                                    ) : ['hotspot', 'image', 'select'].includes(
                                          currentNode.answer_group
                                      ) === false &&
                                      Array.isArray(
                                          currentNode.option_buttons
                                      ) ? (
                                        (!isResultNode || idx === 0) && (
                                            <Grid
                                                item
                                                xs={buttonColumns}
                                                order={{ xs: buttonOrder }}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexWrap: 'wrap',
                                                        gap: '10px',
                                                        justifyContent:
                                                            'flex-end',
                                                    }}
                                                >
                                                    {currentNode.option_buttons.map(
                                                        (button) => {
                                                            return (
                                                                <Button
                                                                    key={
                                                                        button.button_id
                                                                    }
                                                                    variant="contained"
                                                                    type="submit"
                                                                    className={clsx(
                                                                        commonStyles.button,
                                                                        {
                                                                            [classes.confirmationButton]:
                                                                                button?.style ===
                                                                                'positive',
                                                                        },
                                                                        {
                                                                            [classes.cancelButton]:
                                                                                button?.style ===
                                                                                'negative',
                                                                        },
                                                                        {
                                                                            [classes.selectedButton]:
                                                                                currentNode.answer ===
                                                                                button.button_text,
                                                                        }
                                                                    )}
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        if (
                                                                            !isSubmitAllow
                                                                        ) {
                                                                            if (
                                                                                isReadOnly
                                                                            ) {
                                                                                setClickedBtnValue(
                                                                                    {
                                                                                        type: 'button',
                                                                                        button_id:
                                                                                            button?.button_id,
                                                                                    }
                                                                                );
                                                                                return;
                                                                            }
                                                                            validateForm();
                                                                            e.preventDefault();
                                                                        } else {
                                                                            setClickedBtnValue(
                                                                                {
                                                                                    type: 'button',
                                                                                    button_id:
                                                                                        button?.button_id,
                                                                                }
                                                                            );
                                                                        }
                                                                    }}
                                                                >
                                                                    {
                                                                        button.button_text
                                                                    }
                                                                </Button>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            </Grid>
                                        )
                                    ) : null}
                                </Grid>
                            )}
                        </Grid>
                        <Grid
                            item
                            container
                            className={classes.checkListContentContainer}
                        >
                            <Grid
                                item
                                container
                                className={classes.checkListContentContainer}
                            >
                                {currentNode.content && (
                                    <div
                                        className={clsx(
                                            classes.nodeContent,
                                            `gemini-node-content ${tinyMceCustomClass.tinymceCustom}`
                                        )}
                                        dangerouslySetInnerHTML={{
                                            __html: currentNode.content,
                                        }}
                                    ></div>
                                )}
                                {currentNode.image && (
                                    <Box
                                        component="img"
                                        alt="forklift"
                                        src={currentNode.image}
                                        className={classes.QuestionImage}
                                    />
                                )}
                                {currentNode.embed && (
                                    <>
                                        <div
                                            className={clsx(
                                                classes.embedContainer,
                                                overrideResetClasses.overrideMarzipanoResetCSS
                                            )}
                                            dangerouslySetInnerHTML={{
                                                __html: currentNode.embed,
                                            }}
                                        ></div>
                                        {/* <iframe
                            src={currentNode.video}
                            className={classes.QuestionImage}
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            title="content"
                        /> */}
                                    </>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item container ref={procedureRef}>
                            <ProcedureFormNode
                                key={`ProcedureQuestions`}
                                isSubmitAllow={isSubmitAllow}
                                isReadOnly={isReadOnly}
                                formFields={formFields}
                                handleBlur={handleBlur}
                                isMobileWidth={formWidth < smWidth}
                                resultNode={true}
                                contentService={contentService}
                            />
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export { ProcedureQuestions };
