/**
 * This slice is to manage the item display table
 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    addActiveZoneItem,
    deleteActiveZoneItem,
} from 'GeminiViewerComponent/_features/asset/assetSlice';

import { editItem } from '_features/common/editItemSlice';
import { itemService } from '_features/_services';
import {
    LoadingStatus,
    SavingStatus,
} from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { addItemToUndoStack } from 'shared/loadZoneSlice';
import { remove } from 'GeminiViewerComponent/_helpers/lodashUtils';

const sliceName = 'itemsTable';

const initialState = {
    status: LoadingStatus.Idle,

    error: null,
    lastFetchedItems: [],
    allItems: [],

    itemPageInfo: {},
    itemStatus: LoadingStatus.Idle,
    selectedItemId: null,

    activeItemLoadingStatus: LoadingStatus.Idle,

    activeItemId: null,
    activeItem: {},

    allAssetItems: [],
    allAssetItemStatus: LoadingStatus.Idle,
};

export const fetchActiveItem = createAsyncThunk(
    `${sliceName}/fetchActiveItem`,
    async ({ zoneId, itemId }, { getState }) => {
        return await itemService.getById(
            zoneId,
            itemId,
            getState().accounts.activeUser.s3_prefix
        );
    }
);

export const fetchItemsPage = createAsyncThunk(
    `${sliceName}/fetchItemsPage`,
    async (params) => {
        return await itemService.getPage(
            params.searchString,
            params.zoneId,
            params.page,
            params.pageSize
        );
    }
);

export const fetchMoreItems = createAsyncThunk(
    `${sliceName}/fetchMoreItems`,
    async (params) =>
        await itemService.getRange(
            params.zoneId,
            params.startIndex,
            params.stopIndex
        )
);

export const addNewItem = createAsyncThunk(
    `${sliceName}/addNewItem`,
    async (newItem, { getState, dispatch }) => {
        const response = await itemService.create(
            newItem,
            getState().accounts.activeUser.s3_prefix
        );
        if (!response.error) {
            dispatch(addActiveZoneItem(response));
            dispatch(addItemToUndoStack(response));
        }
        return response;
    }
);

export const deleteItem = createAsyncThunk(
    `${sliceName}/deleteItem`,
    async ({ zoneId, itemId }, { dispatch }) => {
        const response = await itemService.delete(zoneId, itemId);
        if (response) {
            dispatch(
                deleteActiveZoneItem({
                    zone_id: zoneId,
                    item_id: itemId,
                })
            );
            dispatch(
                deleteAllAssetItem({
                    zone_id: zoneId,
                    item_id: itemId,
                })
            );
            response.id = itemId;
        }
        return response;
    }
);

export const deleteItemArray = createAsyncThunk(
    `${sliceName}/deleteItemArray`,
    async ({ zoneId, itemIds }) => {
        const response = await itemService.deleteArray(zoneId, itemIds);
        if (!response.error) {
            response.zone_id = zoneId;
            response.ids = itemIds;
        }
        return response;
    }
);

export const duplicateItem = createAsyncThunk(
    `${sliceName}/duplicateItem`,
    async ({ zoneId, itemId }, { getState }) =>
        await itemService.duplicate(
            zoneId,
            itemId,
            getState().accounts.activeUser.s3_prefix
        )
);

export const pinItem = createAsyncThunk(
    `${sliceName}/pinItem`,
    async (pinId, { getState, dispatch }) => {
        const item = getState().itemsTable.allItems.find(
            (x) => x.item_id === pinId
        );
        const params = { object_id: pinId, is_pinned: !item.is_pinned };
        const response = await itemService.itemPinning(params);
        if (!response.error) {
            response.pinId = pinId;
            response.isPinned = !item.is_pinned;
        }
        return response;
    }
);

export const addItemToZone = createAsyncThunk(
    `${sliceName}/addItemToZone`,
    async (params, { getState, dispatch }) => {
        const newItem = await itemService.addItemToZone(
            params,
            getState().accounts.activeUser.s3_prefix
        );
        return newItem;
    }
);

export const getAllAssetItems = createAsyncThunk(
    `${sliceName}/getAllAssetItems`,
    async (params) => {
        return await itemService.getAllItems(params.assetId);
    }
);

const resetTableItemState = (state) => {
    state.loadedItemsMap = {};
    state.items = {};
    state.allItems = [];
    state.itemStatus = LoadingStatus.Idle;
};

const itemsTableSlice = createSlice({
    name: 'itemsTable',
    initialState,
    reducers: {
        resetTableItems: (state) => {
            resetTableItemState(state);
        },
        setSelectedTableItem: (state, action) => {
            state.selectedItemId = action.payload;
        },
        updateTableZoneItem: (state, action) => {},
        deleteTableZoneItem: (state, action) => {
            remove(state.items, {
                zone_id: action.payload.zone_id,
                item_id: action.payload.item_id,
            });
        },
        editedAssetZoneItem: (state, action) => {
            resetTableItemState(state);
        },
        deleteZoneItemById: (state, action) => {
            resetTableItemState(state);
        },
        resetAssetItem: (state, action) => {
            state.allAssetItemStatus = LoadingStatus.Idle;
        },
        updateAllAssetItem: (state, action) => {
            let allAssetItems = state.allAssetItems;
            let i = allAssetItems.findIndex(
                (x) => x.item_id === action.payload.item_id
            );
            if (i == -1) {
                state.allAssetItems = [...action.payload];
            } else {
                allAssetItems[i] = action.payload;
                state.allAssetItems = [...allAssetItems];
            }
        },
        deleteAllAssetItem: (state, action) => {
            let allAssetItems = state.allAssetItems;
            let i = allAssetItems.findIndex(
                (x) => x.item_id === action.payload.item_id
            );
            allAssetItems.splice(i, 1);
            state.allAssetItems = [...allAssetItems];
        },
    },
    extraReducers: {
        [fetchItemsPage.pending]: (state, action) => {
            state.itemStatus = LoadingStatus.Loading;
        },
        [fetchItemsPage.fulfilled]: (state, action) => {
            state.itemStatus = LoadingStatus.Loaded;
            state.itemPageInfo = action.payload.pageInfo;
            state.items = action.payload.items;
            state.allItems = action.payload.items;
            const startIndex =
                (action.meta.arg.page - 1) * action.meta.arg.pageSize;
            const stopIndex = startIndex + state.items.length - 1;
            const requestedStopIndex =
                startIndex + action.meta.arg.pageSize - 1;

            state.loadedItemsMap = {};

            for (var i = startIndex; i <= stopIndex; i++) {
                state.loadedItemsMap[i] = LoadingStatus.Loaded;
            }

            // In case we didn't load as many as requested
            for (var j = stopIndex + 1; j <= requestedStopIndex; j++) {
                delete state.loadedItemsMap[j];
            }
        },
        [fetchItemsPage.rejected]: (state, action) => {
            state.itemStatus = LoadingStatus.Failed;
            state.error = action.error.message;
        },
        [fetchMoreItems.pending]: (state, action) => {
            const startIndex = action.meta.arg.startIndex;
            const stopIndex = action.meta.arg.stopIndex;

            for (var i = startIndex; i <= stopIndex; i++) {
                state.loadedItemsMap[i] = LoadingStatus.Loading;
            }
        },
        [fetchMoreItems.fulfilled]: (state, action) => {
            const startIndex = action.meta.arg.startIndex;
            const stopIndex = startIndex + action.payload.items.length - 1;
            const requestedStopIndex = action.meta.arg.stopIndex;

            for (var i = startIndex; i <= stopIndex; i++) {
                state.loadedItemsMap[i] = LoadingStatus.Loaded;
            }

            // In case we didn't load as many as requested
            for (var j = stopIndex + 1; j <= requestedStopIndex; j++) {
                delete state.loadedItemsMap[j];
            }

            state.items = state.items.concat(action.payload.items);
            state.allItems = state.allItems.concat(action.payload.items);
        },
        [fetchMoreItems.rejected]: (state, action) => {
            const startIndex = action.meta.arg.startIndex;
            const stopIndex = action.meta.arg.stopIndex;

            for (var i = startIndex; i <= stopIndex; i++) {
                delete state.loadedItemsMap[i];
            }

            state.status = LoadingStatus.Failed;
            state.error = action.error.message;
        },
        [fetchActiveItem.pending]: (state, action) => {
            state.activeItemLoadingStatus = LoadingStatus.Loading;
        },
        [fetchActiveItem.fulfilled]: (state, action) => {
            state.activeItemLoadingStatus = LoadingStatus.Loaded;
            state.activeItem = action.payload;
            state.activeItemId = action.payload.item_id;
        },
        [fetchActiveItem.rejected]: (state, action) => {
            state.activeItemLoadingStatus = LoadingStatus.Failed;
            state.error = action.error.message;
        },
        [addNewItem.pending]: (state, action) => {
            state.itemStatus = SavingStatus.Saving;
        },
        [addNewItem.fulfilled]: (state, action) => {
            state.itemStatus = LoadingStatus.Loaded;
            state.allItems = [...(state.allItems || []), action.payload];
            state.allAssetItems = [
                ...(state.allAssetItems || []),
                action.payload,
            ];
        },
        [addNewItem.rejected]: (state, action) => {
            state.itemStatus = SavingStatus.Saved;
        },
        [deleteItem.fulfilled]: (state, action) => {
            state.itemStatus = LoadingStatus.Loaded;
            let allItems = state.allItems;
            let i = allItems.findIndex(
                (x) => x.item_id === action.payload.item.item_id
            );
            allItems.splice(i, 1);
            state.allItems = [...allItems];

            let allAssetItems = state.allAssetItems;
            i = allAssetItems.findIndex(
                (x) => x.item_id === action.payload.item.item_id
            );
            allAssetItems.splice(i, 1);
            state.allAssetItems = [...allAssetItems];
        },
        [duplicateItem.fulfilled]: (state, action) => {
            state.allItems = [...(state.allItems || []), action.payload];
        },
        [editItem.fulfilled]: (state, action) => {
            state.itemStatus = LoadingStatus.Loaded;
            let allItems = state.allItems;
            let i = allItems.findIndex(
                (x) => x.item_id === action.payload.item_id
            );
            allItems[i] = action.payload;
            state.allItems = [...allItems];

            let allAssetItems = state.allAssetItems;
            i = allAssetItems.findIndex(
                (x) => x.item_id === action.payload.item_id
            );
            allAssetItems[i] = action.payload;
            state.allAssetItems = [...allAssetItems];
        },
        [editItem.rejected]: (state, action) => {
            state.status = SavingStatus.Failed;
            state.error = action.error.message;
        },
        [addItemToZone.fulfilled]: (state, action) => {
            state.itemStatus = LoadingStatus.Loaded;
            let allItems = state.allItems;
            let i = allItems.findIndex(
                (x) => x.item_id === action.payload.item_id
            );
            allItems[i] = action.payload;
            state.allItems = [...allItems];

            let allAssetItems = state.allAssetItems;
            i = allAssetItems.findIndex(
                (x) => x.item_id === action.payload.item_id
            );
            allAssetItems[i] = action.payload;
            state.allAssetItems = [...allAssetItems];
        },
        [addItemToZone.rejected]: (state, action) => {
            state.status = SavingStatus.Failed;
            state.error = action.error.message;
        },
        [pinItem.fulfilled]: (state, action) => {
            state.itemStatus = LoadingStatus.Loaded;
            let allItems = state.allItems;
            const i = allItems.findIndex(
                (x) => x.item_id === action.payload.pinId
            );
            allItems[i].is_pinned = action.payload.isPinned;
            allItems.sort((a, b) => {
                return b.is_pinned - a.is_pinned;
            });
            state.allItems = [...allItems];
        },
        [pinItem.rejected]: (state, action) => {
            return action.payload;
        },
        [deleteItemArray.fulfilled]: (state, action) => {
            state.itemStatus = LoadingStatus.Loaded;
            let allItems = state.allItems;
            action.payload.ids.forEach((itemId) => {
                const i = allItems.findIndex((x) => x.item_id === itemId);
                allItems.splice(i, 1);
            });
            state.allItems = [...allItems];
        },
        [deleteItemArray.rejected]: (state, action) => {
            state.status = SavingStatus.Failed;
            state.error = action.error.message;
        },
        [getAllAssetItems.pending]: (state, action) => {
            state.allAssetItemStatus = LoadingStatus.Loading;
        },
        [getAllAssetItems.fulfilled]: (state, action) => {
            state.allAssetItems = action.payload;
            state.allAssetItemStatus = LoadingStatus.Loaded;
        },
        [getAllAssetItems.rejected]: (state, action) => {
            state.allAssetItemStatus = LoadingStatus.Failed;
            state.error = action.error.message;
        },
    },
});

export const selectItemPageInfo = (state) => state.itemsTable.itemPageInfo;
export const selectAllTableItems = (state) => {
    return state.itemsTable.allItems;
};
export const selectLoadedItemsMap = (state) => state.itemsTable.loadedItemsMap;
export const selectActiveItem = (state) =>
    state.itemsTable.items.find(
        (x) => x.item_id === state.itemsTable.activeItemId
    );
export const selectTableItemById = (state, itemId) =>
    state.itemsTable.items.find((x) => x.item_id === itemId);
export const selectActiveItemId = (state) => state.itemsTable.activeItemId;
export const selectItemLoadingStatus = (state) => state.itemsTable.itemStatus;

export const selectAllAssetItemStatus = (state) =>
    state.itemsTable.allAssetItemStatus;

export const selectAllAssetItem = (state) => state.itemsTable.allAssetItems;

export const {
    setSelectedTableItem,
    updateTableZoneItem,
    deleteTableZoneItem,
    resetTableItems,
    resetAssetItem,
    updateAllAssetItem,
    deleteAllAssetItem,
} = itemsTableSlice.actions;

export default itemsTableSlice.reducer;
