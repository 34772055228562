import React, { useEffect } from 'react';
import Modal from '@mui/material/Modal';
import clsx from 'clsx';
import { Button } from '@mui/material';
import { FormControl, Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';

import { modalStyles } from 'GeminiViewerComponent/components/styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    addDeployementPackageJob,
    fetchDeployementPackagePage,
    getDeploymentPackageStatus,
    resetDeploymentPackageState,
    selectAllDeploymentPackage,
} from '_features/jobs/jobsSlice';
import { SelectionAutocomplete } from 'components/_Autocomplete/SelectionAutocomplete';
import { snackbarHandler } from '_helpers';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';

const DeploymentPackageModal = ({ isOpen, onClose }) => {
    //#region Constants
    const theme = useSelector(selectActiveTheme);
    const classes = modalStyles(theme);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    //#endregion Constants

    //#region Hooks
    //#endregion Hooks

    //#region State
    //#endregion State

    //#region Selectors
    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Effects
    useEffect(() => {
        return () => {
            dispatch(resetDeploymentPackageState);
        };
    }, []);

    //#endregion Effects

    //#region Methods
    const handleSubmit = async (values) => {
        dispatch(activateLoading());
        try {
            const resultAction = await dispatch(
                addDeployementPackageJob({
                    deployment_package_id: values?.deploymentPackageId ?? null,
                })
            );
            const { message, variant } = snackbarHandler(
                resultAction.meta.requestStatus,
                'Deployment Package'
            );
            enqueueSnackbar(message, {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: variant,
            });
            dispatch(deactivateLoading());
        } catch (error) {
            dispatch(deactivateLoading());
            enqueueSnackbar(
                error.message !== 'undefined' ? error.message : 'Create Failed',
                {
                    action: (key) => <SnackbarDismiss key={key} />,
                    variant: 'error',
                }
            );
        }
        onClose();
    };
    //#endregion Methods

    //#region Render time calcs
    //#endregion Render time calcs

    //#region Render

    return (
        <Modal onClose={onClose} open={isOpen}>
            <Formik
                initialValues={{
                    deploymentPackageId: undefined,
                }}
                onSubmit={handleSubmit}
                validationSchema={Yup.object({
                    deploymentPackageId: Yup.number().required('Required'),
                })}
            >
                {({ isSubmitting, errors }) => (
                    <Form>
                        <div className={classes.modalContainer}>
                            <div className={classes.actionIcon}>
                                <Typography variant="body1" component="h2">
                                    For which deployment package would you like
                                    to create a new job?
                                </Typography>
                            </div>
                            <Box
                                display="flex"
                                flexDirection="column"
                                gap="10px"
                                sx={{ width: '100%', margin: '1rem 0' }}
                            >
                                <FormControl>
                                    <Field name={'deploymentPackageId'}>
                                        {({
                                            field: { value },
                                            form: { setFieldValue },
                                        }) => (
                                            <SelectionAutocomplete
                                                title="Deployment Package"
                                                placeholder="Deployment Package"
                                                keyProperty="deployment_package_id"
                                                nameProperty="name"
                                                entityIds={value}
                                                setEntityIds={setFieldValue}
                                                entitySelector={
                                                    selectAllDeploymentPackage
                                                }
                                                entityStatusSelector={
                                                    getDeploymentPackageStatus
                                                }
                                                fetchEntityPage={
                                                    fetchDeployementPackagePage
                                                }
                                                formField={
                                                    'deploymentPackageId'
                                                }
                                                multiSelection={false}
                                                fieldValidation={
                                                    !!errors?.deploymentPackageId
                                                }
                                            />
                                        )}
                                    </Field>
                                    {errors?.deploymentPackageId && (
                                        <Box
                                            sx={{
                                                color: '#E84C3C',
                                                margin: '4px 14px',
                                                fontSize: '0.75rem',
                                                textAlign: 'left',
                                                fontFamily: 'Open Sans',
                                                fontWeight: 400,
                                                lineHeight: 1.66,
                                            }}
                                        >
                                            {errors.deploymentPackageId}
                                        </Box>
                                    )}
                                </FormControl>
                            </Box>
                            <div>
                                <Button
                                    onClick={onClose}
                                    className={classes.buttonSpacing}
                                    variant="outlined"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className={clsx(
                                        classes.buttonSpacing,
                                        classes.actionButton
                                    )}
                                    type="submit"
                                    variant="contained"
                                    disabled={isSubmitting}
                                >
                                    Confirm
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
    //#endregion Render
};
export { DeploymentPackageModal };
