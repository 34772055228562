import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    TextField,
    IconButton,
    Checkbox,
    Box,
    Button,
    Chip,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Dialog,
} from '@mui/material';
import { MdCheckBox, MdCheckBoxOutlineBlank, MdClose } from 'react-icons/md';
import Autocomplete from '@mui/material/Autocomplete';
import { useSnackbar } from 'notistack';
import Dropzone from 'react-dropzone';

import SearchWithFilters from 'components/SearchWithFilters';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { MultiSelectDoneButton } from 'GeminiViewerComponent/components/MultiSelectDoneButton';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { makeFormStyles } from 'forms/styles';
import { panelStyles } from 'GeminiViewerComponent/components/styles';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import {
    fetchPartGroupPage,
    getPartGroupStatus,
    selectPartGroup,
} from '_features/parts/partGroupSlice';
import {
    fetchPartCategoryPage,
    getPartCategoryStatus,
    selectPartCategories,
} from '_features/parts/partCategorySlice';
import partsFilterStyle from './styles';
import {
    getPartImportSampleCsv,
    resetPartsState,
    uploadPartImportCsv,
} from '_features/parts/partSlice';
import { BulkImportResultDisplay } from 'components/BulkImportResultDisplay';
import { LinkTypes } from '_helpers';

import { debounce } from 'GeminiViewerComponent/_helpers/lodashUtils';
const PartsFilter = ({
    partPayload,
    setPartPayload,
    searchStringState,
    setSearchStringState,
}) => {
    const partFilterStyle = partsFilterStyle();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useSelector(selectActiveTheme);
    const formClasses = makeFormStyles(theme);
    const panelClasses = panelStyles(theme);
    const [openImportDialog, setOpenImportDialog] = useState(false);
    const [importFile, setImportFile] = useState(null);
    const [importFileName, setImportFileName] = useState('');
    const [lastImportResult, setLastImportResult] = useState(null);
    const [partGroupsFilter, setPartGroupsFilter] = useState([]);
    const [partCategoryFilter, setPartCategoryFilter] = useState([]);
    const partGroups = useSelector(selectPartGroup);
    const partGroupStatus = useSelector(getPartGroupStatus);
    const partCategories = useSelector(selectPartCategories);
    const partCategoryStatus = useSelector(getPartCategoryStatus);

    useEffect(() => {
        if (partGroupStatus === LoadingStatus.Idle) {
            dispatch(fetchPartGroupPage({}));
        }
    }, [partGroupStatus]);

    useEffect(() => {
        if (partCategoryStatus === LoadingStatus.Idle) {
            dispatch(fetchPartCategoryPage({}));
        }
    }, [partCategoryStatus]);

    // eslint-disable-next-line
    const debouncedSearch = useCallback(
        debounce((searchString, groupIDs, categoryIDs) => {
            if (Array.isArray(groupIDs) && groupIDs.length > 0) {
                groupIDs = groupIDs.map((grp) => grp.part_group_id);
            } else {
                groupIDs = [];
            }
            if (Array.isArray(categoryIDs) && categoryIDs.length > 0) {
                categoryIDs = categoryIDs.map(
                    (category) => category.part_category_id
                );
            } else {
                categoryIDs = [];
            }
            setPartPayload({
                ...partPayload,
                partGroupIDs: groupIDs,
                partCategoryIDs: categoryIDs,
                searchString: searchString,
            });
        }, 1000),
        [partPayload]
    );

    const handleSearch = (searchString) => {
        setSearchStringState(searchString);
        debouncedSearch(searchString, partGroupsFilter, partCategoryFilter);
    };

    const updateSearchString = (evt) => handleSearch(evt.target.value);

    const clearFilters = () => {
        setPartGroupsFilter([]);
        setPartCategoryFilter([]);
        setPartPayload({
            ...partPayload,
            partGroupIDs: [],
            partCategoryIDs: [],
        });
    };

    const getPartsImportCSVSample = async () => {
        dispatch(activateLoading());
        await dispatch(getPartImportSampleCsv());
        dispatch(deactivateLoading());
    };

    const onDrop = (file) => {
        setImportFile(file?.[0]);
        setImportFileName(file?.[0]?.name);
    };

    const onDropError = (err) => {
        if (err?.[0]?.errors?.[0]?.message) {
            enqueueSnackbar(err[0].errors[0].message, {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: 'error',
            });
        }
    };

    const handleImport = useCallback(async () => {
        dispatch(activateLoading());
        const response = await dispatch(
            uploadPartImportCsv({ file: importFile })
        );
        dispatch(deactivateLoading());
        if (response?.payload) {
            setLastImportResult(response?.payload);
        }
        if (response?.payload?.error?.message) {
            setLastImportResult(null);
            enqueueSnackbar(response?.payload?.error?.message, {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: 'error',
            });
            return;
        }
        if (response.payload?.imported) {
            enqueueSnackbar(
                `${response.payload.imported} Parts Imported Successfully`,
                {
                    action: (key) => <SnackbarDismiss key={key} />,
                    variant: 'success',
                }
            );
            dispatch(resetPartsState());
        }
        if (
            !(
                response?.payload?.errors &&
                typeof response?.payload?.errors === 'object' &&
                !Array.isArray(response?.payload?.errors) &&
                Object.keys(response?.payload?.errors ?? {})?.length > 0
            )
        ) {
            setLastImportResult(null);
            setOpenImportDialog(false);
        }
    }, [importFile]);

    return (
        <Box className={partFilterStyle.actionBar}>
            <Box display="flex" flexWrap="wrap">
                <Box className={partFilterStyle.contentActionBar}>
                    <Box
                        component="fieldset"
                        className={partFilterStyle.filterFieldset}
                    >
                        <Box
                            component="legend"
                            className={partFilterStyle.filterFieldsetLegend}
                        >
                            Filters
                        </Box>
                        {partGroups?.length > 0 && (
                            <Autocomplete
                                sx={{
                                    minWidth: '200px',
                                    maxWidth: 'max-content',
                                }}
                                limitTags={2}
                                multiple
                                variant="outline"
                                id="checkboxes-content-types"
                                options={partGroups}
                                onChange={(event, newInputValue) => {
                                    setPartGroupsFilter(newInputValue);
                                    debouncedSearch(
                                        searchStringState,
                                        newInputValue,
                                        partCategoryFilter
                                    );
                                }}
                                disableCloseOnSelect
                                value={partGroupsFilter}
                                getOptionLabel={(option) => option.display_name}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props} style={{ padding: '0' }}>
                                        <Checkbox
                                            icon={
                                                <MdCheckBoxOutlineBlank className="react-icon" />
                                            }
                                            checkedIcon={
                                                <MdCheckBox className="react-icon" />
                                            }
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.display_name}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Groups"
                                        placeholder="Groups"
                                    />
                                )}
                                PaperComponent={MultiSelectDoneButton}
                            />
                        )}

                        {partCategories?.length > 0 && (
                            <Autocomplete
                                sx={{
                                    minWidth: '200px',
                                    maxWidth: 'max-content',
                                }}
                                limitTags={2}
                                multiple
                                variant="outline"
                                id="checkboxes-content-types"
                                options={partCategories}
                                onChange={(event, newInputValue) => {
                                    setPartCategoryFilter(newInputValue);
                                    debouncedSearch(
                                        searchStringState,
                                        partGroupsFilter,
                                        newInputValue
                                    );
                                }}
                                disableCloseOnSelect
                                value={partCategoryFilter}
                                getOptionLabel={(option) => option.display_name}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props} style={{ padding: '0' }}>
                                        <Checkbox
                                            icon={
                                                <MdCheckBoxOutlineBlank className="react-icon" />
                                            }
                                            checkedIcon={
                                                <MdCheckBox className="react-icon" />
                                            }
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.display_name}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Category"
                                        placeholder="Category"
                                    />
                                )}
                                PaperComponent={MultiSelectDoneButton}
                            />
                        )}

                        <IconButton
                            aria-label="close"
                            onClick={clearFilters}
                            sx={{ padding: '0' }}
                            size="small"
                        >
                            <MdClose className="react-icon" />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
            <Box
                paddingLeft="10px"
                minWidth="200px"
                display="flex"
                flexDirection="column"
                alignItems="end"
                gap="5px"
            >
                <Button
                    onClick={() => {
                        setImportFile(null);
                        setImportFileName('');
                        setLastImportResult(null);
                        setOpenImportDialog(true);
                    }}
                    variant="contained"
                    color="primary"
                    disableElevation
                >
                    Bulk Import
                </Button>
                <SearchWithFilters
                    onChange={(evt) => updateSearchString(evt)}
                    filter={false}
                ></SearchWithFilters>
            </Box>
            {openImportDialog === true && (
                <Dialog
                    open={openImportDialog}
                    onClose={() => {}}
                    maxWidth={'md'}
                    fullWidth
                >
                    <DialogTitle>
                        Parts Bulk Import
                        <IconButton
                            aria-label="close"
                            onClick={() => setOpenImportDialog(false)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 6,
                                color: '#000',
                            }}
                            size="large"
                        >
                            <MdClose className="react-icon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText marginBottom={'5px'}>
                            Upload CSV to Import Parts in Bulk
                        </DialogContentText>
                        <div className={panelClasses.dropzoneContainer}>
                            <Dropzone
                                accept={['text/csv']}
                                maxFiles={1}
                                maxSize={LinkTypes.Document.fileSizeLimit}
                                multiple={false}
                                onDropAccepted={onDrop}
                                onDropRejected={onDropError}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div
                                        {...getRootProps()}
                                        className={`${panelClasses.dropzoneArea} ${panelClasses.dropZoneMinHeight50}`}
                                        style={{
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'contain',
                                            backgroundPosition: 'center center',
                                        }}
                                    >
                                        <input {...getInputProps()} />
                                        <div
                                            className={
                                                panelClasses.dropzoneText
                                            }
                                        >
                                            Drop/Select Csv File
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                            <div className={panelClasses.previewContainer}>
                                {importFile && importFileName && (
                                    <Chip
                                        className={
                                            panelClasses.previewUploadFileName
                                        }
                                        color="secondary"
                                        size="small"
                                        deleteIcon={
                                            <MdClose className="react-icon" />
                                        }
                                        onDelete={() => {
                                            setImportFile(null);
                                            setImportFileName('');
                                            setLastImportResult(null);
                                        }}
                                        label={importFileName}
                                    />
                                )}
                            </div>
                        </div>
                        {lastImportResult && (
                            <BulkImportResultDisplay
                                result={lastImportResult}
                            />
                        )}
                        <Button
                            className={formClasses.submit}
                            onClick={getPartsImportCSVSample}
                            fullWidth
                        >
                            DOWNLOAD SAMPLE CSV
                        </Button>
                        <Button
                            className={formClasses.submit}
                            onClick={handleImport}
                            fullWidth
                            disabled={Boolean(!importFile)}
                        >
                            IMPORT
                        </Button>
                    </DialogContent>
                </Dialog>
            )}
        </Box>
    );
};

export { PartsFilter };
