import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchTags,
    selectTags,
    getTagStatus,
    getTagPageInfo,
    resetTagState,
} from '_features/tags/tagsSlice';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { ItemTagTable } from './ItemTagTable';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';

const ItemTagManagementTab = () => {
    const dispatch = useDispatch();
    const ref = useRef(false);
    const itemTags = useSelector(selectTags);
    const itemTagStatus = useSelector(getTagStatus);
    const itemTagPageInfo = useSelector(getTagPageInfo);
    const [itemTagPayload, setItemTagPayload] = useState({});
    const [sortBy, setSortBy] = useState('');
    const [sortDirection, setSortDirection] = useState('');

    const getTagsData = async (payload) => {
        dispatch(activateLoading());
        await dispatch(fetchTags({ ...payload }));
        dispatch(deactivateLoading());
    };

    useEffect(() => {
        if (ref.current === true) {
            getTagsData({ ...itemTagPayload });
        }
    }, [itemTagPayload]);

    useEffect(() => {
        return async () => {
            await dispatch(resetTagState());
        };
    }, []);

    useEffect(() => {
        if (itemTagStatus === LoadingStatus.Idle) {
            getTagsData({ ...itemTagPayload, page: 1, pageSize: 10 });
        }
        ref.current = true;
    }, [itemTagStatus, dispatch]);

    const setSorting = (_sortBy, _sortDirection) => {
        setSortBy(_sortBy);
        setSortDirection(_sortDirection);
        setItemTagPayload({
            ...itemTagPayload,
            sort: `${_sortDirection === 'ASC' ? '' : '-'}${_sortBy}`,
        });
    };

    return (
        <ItemTagTable
            tags={itemTags}
            pageInfo={itemTagPageInfo}
            itemTagPayload={itemTagPayload}
            setItemTagPayload={setItemTagPayload}
            sortDirection={sortDirection}
            sortBy={sortBy}
            handleSort={(_sortBy, _sortDirection) =>
                setSorting(_sortBy, _sortDirection)
            }
        />
    );
};

export { ItemTagManagementTab };
