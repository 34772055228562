import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { HotspotTable } from './HotspotTable';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    fetchAllHotspotIcons,
    selectAllHotspots,
    getHotspotStatus,
    getHotspotPageInfo,
    resetHotspotState,
} from 'shared/hotspotsSlice';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';

const HotspotManagementTab = () => {
    const dispatch = useDispatch();
    const ref = useRef(false);
    const hotspots = useSelector(selectAllHotspots);
    const hotspotStatus = useSelector(getHotspotStatus);
    const pageInfo = useSelector(getHotspotPageInfo);
    const [hotspotPayload, setHotspotPayload] = useState(null);
    const [sortBy, setSortBy] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [defaultSort, setDefaultSort] = useState(false);
    const hotspotWithDefaultIcons = !defaultSort
        ? [...hotspots]
              ?.sort((a, b) => a?.hotspot_icon_id - b?.hotspot_icon_id)
              .map((item, index) => {
                  if (index <= 1) {
                      return { ...item, defaultIcon: true };
                  }
                  return item;
              })
        : [...hotspots];

    const getHotspotData = async (payload) => {
        dispatch(activateLoading());
        await dispatch(fetchAllHotspotIcons({ ...payload }));
        dispatch(deactivateLoading());
    };

    useEffect(() => {
        if (ref.current === true) {
            getHotspotData({ ...hotspotPayload });
        }
        if (hotspots?.length > 0) {
            setDefaultSort(true);
        }
        return () => {
            dispatch(resetHotspotState());
        };
    }, [hotspotPayload]);

    useEffect(() => {
        if (hotspotStatus === LoadingStatus.Idle) {
            getHotspotData({ ...hotspotPayload });
        }
        ref.current = true;
    }, [hotspotStatus]);

    const setSorting = (_sortBy, _sortDirection) => {
        setSortBy(_sortBy);
        setSortDirection(_sortDirection);
        if (_sortBy === 'is_default') {
            _sortDirection = _sortDirection === 'ASC' ? 'DESC' : 'ASC';
        }
        setHotspotPayload({
            ...hotspotPayload,
            sort: `${_sortDirection === 'ASC' ? '' : '-'}${_sortBy}`,
        });
    };

    return (
        <HotspotTable
            hotspots={hotspotWithDefaultIcons}
            pageInfo={pageInfo}
            hotspotPayload={hotspotPayload}
            setHotspotPayload={setHotspotPayload}
            sortDirection={sortDirection}
            sortBy={sortBy}
            handleSort={(_sortBy, _sortDirection) =>
                setSorting(_sortBy, _sortDirection)
            }
        />
    );
};

export { HotspotManagementTab };
