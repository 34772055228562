import React, { useEffect, useState } from 'react';
import {
    Box,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { MdAdd, MdEdit } from 'react-icons/md';

import { ActionListDialog } from '../../ContentNodeEditor/ActionListDialog';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import {
    fetchInspectionActionList,
    getActionListLoadingStatus,
    setInspectionAction,
} from 'GeminiViewerComponent/_features/inspection/inspectionSlice';

const InspectionActionField = ({
    node,
    setFieldValue,
    values,
    inspectionActionList,
}) => {
    const dispatch = useDispatch();
    const actionListLoadingStatus = useSelector(getActionListLoadingStatus);
    const [openActionListDialog, setOpenActionListDialog] = useState(false);
    const [editingActions, setEditingActions] = useState(false);

    useEffect(() => {
        const fetchInspectionActionData = async () => {
            if (actionListLoadingStatus === LoadingStatus.Idle) {
                await dispatch(fetchInspectionActionList());
            }
        };
        fetchInspectionActionData();
    }, [node, actionListLoadingStatus]);

    useEffect(() => {
        dispatch(
            setInspectionAction(
                inspectionActionList?.find(
                    (actionData) =>
                        actionData.inspection_action_list_id ===
                        values?.action_list_type
                )
            )
        );
    }, [inspectionActionList]);

    return (
        <>
            {openActionListDialog && (
                <ActionListDialog
                    openDialog={openActionListDialog}
                    setOpenDialog={setOpenActionListDialog}
                    setFieldValue={setFieldValue}
                    values={values}
                    inspectionActionsList={
                        editingActions &&
                        inspectionActionList.find(
                            (actionData) =>
                                actionData.inspection_action_list_id ===
                                values?.action_list_type
                        )
                    }
                    editingActions={editingActions}
                />
            )}
            <FormControl fullWidth>
                <Box display="flex" width="100%">
                    <InputLabel id="actions-list-label">
                        Actions List
                    </InputLabel>
                    <Select
                        labelId="actions-list-label"
                        id="action-list-type"
                        label="Actions List"
                        value={values?.action_list_type}
                        onChange={(e) => {
                            setFieldValue('action_list_type', e.target.value);
                        }}
                        style={{
                            width: '80%',
                            border: '0',
                            borderRadius: '4px 0 0 4px',
                        }}
                    >
                        {inspectionActionList?.length > 0 &&
                            inspectionActionList.map((actionData) => (
                                <MenuItem
                                    key={`inspectionActionList-${actionData?.inspection_action_list_id}`}
                                    value={
                                        actionData?.inspection_action_list_id
                                    }
                                >
                                    {!actionData?.display_name ? (
                                        <em>None</em>
                                    ) : (
                                        actionData?.display_name
                                    )}
                                </MenuItem>
                            ))}
                        {!values.required && (
                            <MenuItem value="none">None</MenuItem>
                        )}
                    </Select>
                    <IconButton
                        color="black"
                        aria-label="Edit Actions List"
                        style={{
                            width: '10%',
                            border: '1px solid #c4c4c4',
                            borderRadius: '0',
                        }}
                        onClick={() => {
                            setOpenActionListDialog(true);
                            setEditingActions(true);
                        }}
                    >
                        <MdEdit className="react-icon" />
                    </IconButton>
                    <IconButton
                        color="black"
                        aria-label="Delete Actions List"
                        style={{
                            width: '10%',
                            border: '1px solid #c4c4c4',
                            borderRadius: '0 4px 4px 0',
                        }}
                        onClick={() => {
                            setOpenActionListDialog(true);
                            setEditingActions(false);
                        }}
                    >
                        <MdAdd className="react-icon" />
                    </IconButton>
                </Box>
            </FormControl>
        </>
    );
};

export { InspectionActionField };
