import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    assetTypeContainer: {
        position: 'relative',
    },
    buttonLabel: {
        lineHeight: '40px',
        display: 'flex',
        alignItems: 'center',
        padding: '0 20px',
    },
    assetTypeButtonsWrap: {
        position: 'absolute',
        zIndex: '1300',
        top: '-56px',
        left: '56px',
        boxShadow: 'none !important',
        padding: '8px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    assetTypeIcon: {
        padding: '10px !important',
        borderRadius: '25px !important',
        background: (props) =>
            `${props.colors.button.primaryBackground} !important`,
        color: (props) => `${props.colors.button.primaryForeground} !important`,
    },
    assetTypeIconWrap: {
        alignItems: 'center',
        display: 'flex',
        width: '100%',
        padding: '6px 8px',
        '&:hover svg': {
            fill: '#fff !important',
        },
    },
}));

export default useStyles;
