import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { useSnackbar } from 'notistack';
import { MdClose } from 'react-icons/md';

import { SelectionAutocomplete } from 'components';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { snackbarHandler } from 'GeminiViewerComponent/_helpers/snackbar-handler';
import {
    fetchTags,
    getTagStatus,
    selectTags,
    createTag,
} from '_features/tags/tagsSlice';
import {
    selectAllAudiences,
    getAudienceStatus,
    fetchAudiencesPage,
} from '_features/audiences/audiencesSlice';
import { makeFormStyles } from '../../forms/styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { editItem } from '_features/common/editItemSlice';
import { deleteKey } from 'GeminiViewerComponent/_helpers';

const EditMultipleItemsDialog = ({ open, handleClose, zoneItems }) => {
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const initValues = {
        audience_ids: [],
        tag_ids: [],
    };
    const user = JSON.parse(localStorage.getItem('user'));

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        let resultAction = null;
        for (let i = 0; i < zoneItems.length; i++) {
            const element = zoneItems[i];
            const requestPayload = {
                zone_id: element['zone_id'],
                hotspot_icon_id:
                    typeof element['hotspot_icon_id'] === 'number'
                        ? element['hotspot_icon_id']
                        : null,
                ...values,
                ...element,
            };
            resultAction = await dispatch(editItem(requestPayload));
        }
        const { message, variant } = snackbarHandler(
            resultAction.meta.requestStatus,
            'Edit'
        );
        enqueueSnackbar(message, {
            action: (key) => <SnackbarDismiss key={key} />,
            variant: variant,
        });
        setSubmitting(false);
        resetForm();
        handleClose();
        deleteKey(`tags_${user.user_id}`);
        dispatch(fetchTags());
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle
                sx={{
                    fontSize: '20px',
                    padding: '10px 24px',
                }}
            >
                Edit Multiple Items
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 6,
                        color: '#000',
                    }}
                    size="large"
                >
                    <MdClose className="react-icon" />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ width: '400px', marginTop: '10px' }}>
                <Formik
                    enableReinitialize={true}
                    initialValues={{ ...initValues }}
                    validationSchema={Yup.object({
                        audience_ids: Yup.array(),
                        tag_ids: Yup.array(),
                    })}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        handleSubmit(values, setSubmitting, resetForm);
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form
                            className={classes.form}
                            style={{ padding: '0px' }}
                        >
                            <Field
                                className={classes.input}
                                name="audience_ids"
                            >
                                {({
                                    field: { value },
                                    form: { setFieldValue },
                                }) => (
                                    <SelectionAutocomplete
                                        title="Audiences"
                                        placeholder="none"
                                        keyProperty="audience_id"
                                        nameProperty="display_name"
                                        entityIds={value}
                                        setEntityIds={setFieldValue}
                                        entitySelector={selectAllAudiences}
                                        entityStatusSelector={getAudienceStatus}
                                        fetchEntityPage={fetchAudiencesPage}
                                        formField={'audience_ids'}
                                        fetchParams={{ page: 1, pageSize: 100 }}
                                        fieldValidationLength={150}
                                    />
                                )}
                            </Field>
                            <Field className={classes.input} name="tag_ids">
                                {({
                                    field: { value },
                                    form: { setFieldValue },
                                }) => (
                                    <SelectionAutocomplete
                                        title="Item Tags"
                                        placeholder="Item Tags"
                                        keyProperty="tag_id"
                                        nameProperty="name"
                                        entityIds={value}
                                        setEntityIds={setFieldValue}
                                        entitySelector={selectTags}
                                        entityStatusSelector={getTagStatus}
                                        fetchEntityPage={fetchTags}
                                        formField={'tag_ids'}
                                        enableCreatable
                                        creatableDialogTitle={'tag'}
                                        creatableDialogFieldLabel={'Tag'}
                                        creatableDispatch={createTag}
                                        enableCache
                                        refreshCacheType={`tags_${user.user_id}`}
                                        fieldValidationLength={150}
                                    />
                                )}
                            </Field>
                            <button
                                className={classes.submit}
                                type="submit"
                                disabled={isSubmitting}
                            >
                                Update
                            </button>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export { EditMultipleItemsDialog };
