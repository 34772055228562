import React, { useState, useEffect, useCallback } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Chip, FormControlLabel, Checkbox, InputLabel } from '@mui/material';
import { ConfigValuesField } from 'forms/Components/ConfigValuesField';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { MdClose, MdExpandMore } from 'react-icons/md';
import Dropzone from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import { FormActions } from '_helpers/form-action';
import { Role } from '_helpers';
import { snackbarHandler } from 'GeminiViewerComponent/_helpers/snackbar-handler';
import { makeFormStyles } from '../styles';
import {
    accordionStyles,
    panelStyles,
} from 'GeminiViewerComponent/components/styles';
import { SelectionAutocomplete } from 'components';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { LinkTypes } from '_helpers/enumerations';
import {
    selectAllGroups,
    getGroupStatus,
    fetchGroupsPage,
    resetGroupState,
} from '_features/groups/groupsSlice';
import {
    selectAllAudiences,
    getAudienceStatus,
    fetchAudiencesPage,
} from '_features/audiences/audiencesSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { closePanel, selectFormInfo } from '_features/common/formSlice';
import {
    fetchDefaultHotspots,
    fetchAllHotspotIcons,
    getHotspotStatus,
    getItemHotspots,
    getNavigationHotspots,
} from 'shared/hotspotsSlice';
import { accountsSlice } from 'app/store';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { addNewAsset } from '_features/assets/assetsTableSlice';
import { editAsset, getEditAsset } from '_features/assets/editAssetSlice';
import {
    getAllLinkTypes,
    selectAssetLinkTypes,
    selectAssetLinkTypesStatus,
} from '_features/assets/assetManagerSlice';
import SetDirtyForm from 'forms/SetDirtyForm';
import { LoadingLogo } from 'GeminiViewerComponent/components/LoadingLogo/LoadingLogo';
import {
    fetchAllComapanionApps,
    getAllCompanionApps,
    getCompanionLoadingStatus,
} from 'GeminiViewerComponent/_features/companionApps/companionAppsSlice';
import { useTinyMCEInit } from 'hooks/useTinyMCEInit';
import { TinyMCEEditor } from 'components/TinyMCEEditor';
import { FormTextField } from 'components/TextField';
import {
    createCategory,
    fetchAllCategories,
    getAllCategories,
    getCategoryStatus,
    resetCategoryStatus,
} from '_features/category/categorySlice';

const AssetForm = ({ formAction, assetId }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const init = useTinyMCEInit();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);
    const styles = accordionStyles();
    const panelClasses = panelStyles(theme);
    const [loaded, setLoaded] = useState(
        formAction.id === false // FormActions.Create.id ? true : false
    );
    const formInfo = useSelector(selectFormInfo);
    const activeUser = useSelector(accountsSlice.selectActiveUser);
    const readOnlyMode = activeUser?.role === 'User' ? true : false;
    const defaultItemHotspotIcon = useSelector(
        (state) => state.hotspots.defaultItemHotspotIconId
    );
    const defaultNavHotspotIcon = useSelector(
        (state) => state.hotspots.defaultNavHotspotIconId
    );
    const defaultHotspotStatus = useSelector(
        (state) => state.hotspots.defaultHotspotsStatus
    );
    const INIT_VALUES = {
        display_name: '',
        description: '',
        disclaimer_tooltip: '',
        disclaimer_html: '',
        custom_data: '',
        asset_link_types: [],
        groups: [],
        categories: [],
        audiences: [],
        image: {},
        item_hotspot_icon_id: '',
        nav_hotspot_icon_id: '',
        asset_type_id: formInfo?.assetForm?.assetTypeID ?? 2,
        config_json: [
            {
                key: '',
                value: '',
            },
        ],
        hide_nav_zones: false,
        hide_nav_search: false,
        hide_nav_title: false,
        hide_nav_controls: false,
        pano_asset_type: formInfo?.assetForm?.assetTypeID,
        player_url: '',
        companion_apps: [],
    };
    const [initValues, setInitValues] = useState({ ...INIT_VALUES });

    useEffect(() => {
        // TODO: Test for reloading and prompt user to save changes or cancel
        // the load
        // const reloading = loaded;

        if (formAction.id === FormActions.Create.id) {
            const getAssetsTypes = async () => {
                const allLinkTypes = await dispatch(getAllLinkTypes());
                const link_types = {
                    asset_link_types: allLinkTypes.payload.link_types.map(
                        (a) => a.link_type_id
                    ),
                };

                setInitValues({
                    ...INIT_VALUES,
                    ...link_types,
                    nav_hotspot_icon_id: defaultNavHotspotIcon,
                    item_hotspot_icon_id: defaultItemHotspotIcon,
                });
                setLoaded(true);
            };
            getAssetsTypes();
        }
        if (formAction.id === FormActions.Edit.id) {
            const fetchData = async () => {
                var objectData = await dispatch(getEditAsset(assetId));
                var fetchedObject = objectData.payload;
                let fetched_options_field = fetchedObject?.options_json
                    ? JSON.parse(fetchedObject?.options_json)
                    : {};
                setInitValues({
                    display_name: fetchedObject.display_name,
                    description: fetchedObject.description,
                    disclaimer_tooltip: fetchedObject?.disclaimer_tooltip,
                    disclaimer_html: fetchedObject?.disclaimer_html,
                    custom_data: fetchedObject.custom_data,
                    player_url: fetchedObject.player_url || '',
                    asset_link_types: fetchedObject.asset_link_types?.map(
                        (x) => x.link_type.link_type_id
                    ),
                    companion_apps: fetchedObject.companion_apps?.map(
                        (x) => x.companion_app.companion_app_id
                    ),
                    groups: fetchedObject.groups?.map((x) => x.group.group_id),
                    categories: fetchedObject.categories?.map(
                        (x) => x.category_id
                    ),
                    audiences: fetchedObject.audiences?.map(
                        (x) => x.audience_id
                    ),
                    nav_hotspot_icon_id: fetchedObject.nav_hotspot_icon_id,
                    item_hotspot_icon_id: fetchedObject.item_hotspot_icon_id,
                    image: {},
                    image_url: fetchedObject.image_url,
                    config_json: fetchedObject.config_json ?? {
                        key: '',
                        value: '',
                    },
                    hide_nav_zones: !!fetched_options_field?.hide_nav_zones,
                    hide_nav_search: !!fetched_options_field?.hide_nav_search,
                    hide_nav_title: !!fetched_options_field?.hide_nav_title,
                    hide_nav_controls:
                        !!fetched_options_field?.hide_nav_controls,
                    pano_asset_type:
                        fetchedObject.asset_type_id === 1
                            ? 1
                            : fetched_options_field?.pano_asset_type ===
                              undefined
                            ? 2
                            : fetched_options_field?.pano_asset_type,
                    auto_view_single_link: fetchedObject?.auto_view_single_link,
                });
                setLoaded(true);
            };
            fetchData();
        }
    }, [assetId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (defaultHotspotStatus == LoadingStatus.Idle) {
            dispatch(fetchDefaultHotspots());
        }

        setInitValues({
            ...initValues,
            nav_hotspot_icon_id: defaultNavHotspotIcon,
            item_hotspot_icon_id: defaultItemHotspotIcon,
        });
    }, [defaultItemHotspotIcon, defaultNavHotspotIcon]);
    const handleSubmit = async (values, setSubmitting, resetForm) => {
        dispatch(activateLoading({ showProgress: true }));
        values.options_json = {
            hide_nav_zones: !!values.hide_nav_zones,
            hide_nav_search: !!values.hide_nav_search,
            hide_nav_title: !!values.hide_nav_title,
            hide_nav_controls: !!values.hide_nav_controls,
            pano_asset_type: values.pano_asset_type,
        };
        delete values?.hide_nav_zones;
        delete values?.hide_nav_search;
        delete values?.hide_nav_title;
        delete values?.hide_nav_controls;
        var resultAction = null;
        switch (formAction.id) {
            case FormActions.Edit.id:
                resultAction = await dispatch(
                    editAsset({
                        asset_id: assetId,
                        ...values,
                        dispatch,
                    })
                );
                if (!resultAction.error) {
                    dispatch(resetCategoryStatus());
                    dispatch(closePanel({ formKey: 'assetForm' }));
                    dispatch(resetGroupState());
                }
                break;
            case FormActions.Create.id:
                resultAction = await dispatch(
                    addNewAsset({ ...values, dispatch })
                );
                if (!resultAction.error) {
                    dispatch(resetCategoryStatus());
                    resetForm();
                    if (formInfo?.assetForm?.assetTypeID === 1) {
                        navigate(
                            `/asset/${resultAction?.payload?.asset_id}/videoimporter`
                        );
                    } else if (formInfo?.assetForm?.assetTypeID === 2) {
                        navigate(
                            `/asset/${resultAction?.payload?.asset_id}/panoimporter`
                        );
                    } else {
                        navigate(`/asset/${resultAction?.payload?.asset_id}`);
                    }
                }
                break;
            default:
                break;
        }

        dispatch(deactivateLoading());
        const { message, variant } = snackbarHandler(
            resultAction.meta.requestStatus,
            formAction.label
        );
        enqueueSnackbar(message, {
            action: (key) => <SnackbarDismiss key={key} />,
            variant: variant,
        });
        setSubmitting(false);
    };

    const onDrop = useCallback(({ file, setFieldValue }) => {
        setFieldValue('image', file);

        const reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = (e) => {
            setFieldValue('image_url', e.target.result);
        };
        reader.readAsDataURL(file);
    }, []);

    return !loaded ? (
        <LoadingLogo styleClassName={classes.loadingSvg} />
    ) : (
        <Formik
            validator={() => ({})}
            enableReinitialize={true}
            initialValues={{ ...initValues }}
            validationSchema={Yup.object({
                display_name: Yup.string()
                    .max(150, 'Must be 150 characters or less')
                    .required('Required'),
                description: Yup.string()
                    .max(255, 'Must be 255 characters or less')
                    .required('Required'),
                disclaimer_tooltip: Yup.string().max(
                    255,
                    'Must be 255 characters or less'
                ),
                disclaimer_html: Yup.string(),
                custom_data: Yup.string(),
                config_json: Yup.array(),
                asset_link_types: Yup.array(),
                groups: Yup.array(),
                categories: Yup.array(),
                audiences: Yup.array(),
                nav_hotspot_icon_id: Yup.string(),
                item_hotspot_icon_id: Yup.string(),
                image: Yup.mixed(),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                handleSubmit(values, setSubmitting, resetForm);
            }}
        >
            {({
                values,
                errors,
                touched,
                isSubmitting,
                setFieldValue,
                onBlur,
            }) => (
                <Form className={classes.form}>
                    <div className={classes.formHeader}>Asset Details</div>
                    <FormTextField
                        label="Asset Name"
                        name="display_name"
                        placeholder="Asset Name"
                        disabled={readOnlyMode}
                    />
                    <FormTextField
                        label="Description"
                        name="description"
                        placeholder="Description"
                        disabled={readOnlyMode}
                    />
                    <FormTextField
                        label="Disclaimer Tooltip"
                        name="disclaimer_tooltip"
                        placeholder="Disclaimer Tooltip"
                        disabled={readOnlyMode}
                    />
                    <InputLabel style={{ padding: '6px' }}>
                        Disclaimer Text
                    </InputLabel>
                    <Field name="disclaimer_html" style={{ marginTop: '10px' }}>
                        {({ field, form: { setFieldValue } }) => (
                            <TinyMCEEditor
                                id="disclaimer_html"
                                tinymceScriptSrc={
                                    process.env.PUBLIC_URL +
                                    '/tinymce/tinymce.min.js'
                                }
                                init={{
                                    ...init,
                                }}
                                textareaName="disclaimer_html"
                                onBlur={onBlur}
                                onEditorChange={(content) => {
                                    setFieldValue('disclaimer_html', content);
                                }}
                                value={values.disclaimer_html}
                            />
                        )}
                    </Field>
                    <FormTextField
                        label="Custom Data"
                        name="custom_data"
                        placeholder="Custom Data"
                        disabled={readOnlyMode}
                    />
                    <FormTextField
                        label="Player URL"
                        name="player_url"
                        placeholder="Player URL"
                        disabled={
                            readOnlyMode ||
                            ![Role.IPSAdmin, Role.Admin].includes(
                                activeUser?.role
                            )
                        }
                    />
                    {formAction.id === FormActions.Edit.id && (
                        <Field className={classes.input} name="companion_apps">
                            {({
                                field: { value },
                                form: { setFieldValue },
                            }) => (
                                <SelectionAutocomplete
                                    title="Companion Apps"
                                    placeholder="Companion Apps"
                                    keyProperty="companion_app_id"
                                    nameProperty="display_name"
                                    entityIds={value}
                                    setEntityIds={setFieldValue}
                                    entitySelector={getAllCompanionApps}
                                    entityStatusSelector={
                                        getCompanionLoadingStatus
                                    }
                                    fetchEntityPage={fetchAllComapanionApps}
                                    formField={'companion_apps'}
                                    enableCache
                                    readOnly={readOnlyMode}
                                />
                            )}
                        </Field>
                    )}
                    <Field className={classes.input} name="asset_link_types">
                        {({ field: { value }, form: { setFieldValue } }) => (
                            <SelectionAutocomplete
                                title="Link Types"
                                placeholder="none"
                                keyProperty="link_type_id"
                                nameProperty="display_name"
                                entityIds={value}
                                setEntityIds={setFieldValue}
                                entitySelector={selectAssetLinkTypes}
                                entityStatusSelector={
                                    selectAssetLinkTypesStatus
                                }
                                fetchEntityPage={getAllLinkTypes}
                                formField={'asset_link_types'}
                                enableCache
                                refreshCacheType="allLinkTypes"
                                readOnly={readOnlyMode}
                            />
                        )}
                    </Field>
                    <Field className={classes.input} name="groups">
                        {({ field: { value }, form: { setFieldValue } }) => (
                            <SelectionAutocomplete
                                title="Groups"
                                placeholder="none"
                                keyProperty="group_id"
                                nameProperty="display_name"
                                entityIds={value}
                                setEntityIds={setFieldValue}
                                entitySelector={selectAllGroups}
                                entityStatusSelector={getGroupStatus}
                                fetchEntityPage={fetchGroupsPage}
                                formField={'groups'}
                                fetchParams={{ page: 1, pageSize: 100 }}
                                readOnly={readOnlyMode}
                            />
                        )}
                    </Field>
                    <Field className={classes.input} name="categories">
                        {({ field: { value }, form: { setFieldValue } }) => (
                            <SelectionAutocomplete
                                title="Categories"
                                placeholder="none"
                                keyProperty="category_id"
                                nameProperty="display_name"
                                entityIds={value}
                                setEntityIds={setFieldValue}
                                entitySelector={getAllCategories}
                                entityStatusSelector={getCategoryStatus}
                                fetchEntityPage={fetchAllCategories}
                                formField={'categories'}
                                enableCreatable
                                creatableDialogTitle={'category'}
                                creatableDialogFieldLabel={'Category'}
                                creatableDispatch={createCategory}
                                fieldValidationLength={150}
                                fetchParams={{ page: 1, pageSize: 100 }}
                                readOnly={readOnlyMode}
                            />
                        )}
                    </Field>
                    <Field className={classes.input} name="audiences">
                        {({ field: { value }, form: { setFieldValue } }) => (
                            <SelectionAutocomplete
                                title="Audiences"
                                placeholder="none"
                                keyProperty="audience_id"
                                nameProperty="display_name"
                                entityIds={value}
                                setEntityIds={setFieldValue}
                                entitySelector={selectAllAudiences}
                                entityStatusSelector={getAudienceStatus}
                                fetchEntityPage={fetchAudiencesPage}
                                formField={'audiences'}
                                fetchParams={{ page: 1, pageSize: 100 }}
                                readOnly={readOnlyMode}
                            />
                        )}
                    </Field>
                    <Field className={classes.input} name="nav_hotspot_icon_id">
                        {({ field: { value }, form: { setFieldValue } }) => (
                            <SelectionAutocomplete
                                title="Nav Hotspot"
                                placeholder="default"
                                keyProperty="hotspot_icon_id"
                                nameProperty="display_name"
                                entityIds={value}
                                setEntityIds={setFieldValue}
                                entitySelector={getNavigationHotspots}
                                entityStatusSelector={getHotspotStatus}
                                fetchEntityPage={fetchAllHotspotIcons}
                                formField={'nav_hotspot_icon_id'}
                                multiSelection={false}
                                fetchParams={{ page: 1, pageSize: 100 }}
                                errorMessage={errors['nav_hotspot_icon_id']}
                                touched={touched['nav_hotspot_icon_id']}
                                readOnly={readOnlyMode}
                            />
                        )}
                    </Field>
                    <div className={classes.formErrorDiv}>
                        <ErrorMessage
                            name="nav_hotspot_icon_id"
                            component="div"
                        />
                    </div>

                    <Field
                        className={classes.input}
                        name="item_hotspot_icon_id"
                    >
                        {({ field: { value }, form: { setFieldValue } }) => (
                            <SelectionAutocomplete
                                title="Item Hotspot"
                                placeholder="default"
                                keyProperty="hotspot_icon_id"
                                nameProperty="display_name"
                                entityIds={value}
                                setEntityIds={setFieldValue}
                                entitySelector={getItemHotspots}
                                entityStatusSelector={getHotspotStatus}
                                fetchEntityPage={fetchAllHotspotIcons}
                                formField={'item_hotspot_icon_id'}
                                multiSelection={false}
                                fetchParams={{ page: 1, pageSize: 100 }}
                                errorMessage={errors['item_hotspot_icon_id']}
                                touched={touched['item_hotspot_icon_id']}
                                readOnly={readOnlyMode}
                            />
                        )}
                    </Field>
                    <div className={classes.formErrorDiv}>
                        <ErrorMessage
                            name="item_hotspot_icon_id"
                            component="div"
                        />
                    </div>
                    <FormTextField
                        component={CheckboxWithLabel}
                        Label={{ label: 'Auto view single link' }}
                        type="checkbox"
                        checked={!!values?.auto_view_single_link}
                        placeholder="Auto view single link"
                        disabled={readOnlyMode}
                        indeterminate={
                            values?.auto_view_single_link === undefined
                                ? true
                                : false
                        }
                        onChange={(e) => {
                            setFieldValue(
                                'auto_view_single_link',
                                e.target.checked
                            );
                        }}
                    />
                    <Accordion
                        style={{ padding: '0px' }}
                        className={clsx(classes.input, styles.accordion)}
                    >
                        <AccordionSummary
                            className={styles.customAccordionSummary}
                            expandIcon={<MdExpandMore className="react-icon" />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={styles.heading}>
                                Key Value Pairs
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ConfigValuesField
                                values={values}
                                fieldName="config_json"
                            />
                        </AccordionDetails>
                    </Accordion>
                    <fieldset
                        style={{
                            border: '1px solid #bdc3c7',
                            borderRadius: '5px',
                            paddingLeft: '10px',
                            color: '#000000',
                        }}
                    >
                        <legend
                            style={{
                                marginLeft: '10px',
                                padding: '3px',
                                fontSize: '14px',
                                color: '#6d7178',
                            }}
                        >
                            Navigaton Bar Options
                        </legend>
                        <FormControlLabel
                            label="Hide zones"
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={Boolean(values?.hide_nav_zones)}
                                    onChange={(e) => {
                                        setFieldValue(
                                            'hide_nav_zones',
                                            e.target.checked
                                        );
                                    }}
                                    disabled={readOnlyMode}
                                />
                            }
                        />
                        <FormControlLabel
                            label="Hide search"
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={Boolean(values?.hide_nav_search)}
                                    onChange={(e) => {
                                        setFieldValue(
                                            'hide_nav_search',
                                            e.target.checked
                                        );
                                    }}
                                    disabled={readOnlyMode}
                                />
                            }
                        />
                        <FormControlLabel
                            label="Hide title"
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={Boolean(values?.hide_nav_title)}
                                    onChange={(e) => {
                                        setFieldValue(
                                            'hide_nav_title',
                                            e.target.checked
                                        );
                                    }}
                                    disabled={readOnlyMode}
                                />
                            }
                        />
                        <FormControlLabel
                            label="Hide controls"
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={Boolean(values?.hide_nav_controls)}
                                    onChange={(e) => {
                                        setFieldValue(
                                            'hide_nav_controls',
                                            e.target.checked
                                        );
                                    }}
                                    disabled={readOnlyMode}
                                />
                            }
                        />
                    </fieldset>
                    <Field className={classes.input} name="image" type="file">
                        {({ field: { value }, form: { setFieldValue } }) => (
                            <div className={panelClasses.dropzoneContainer}>
                                <Dropzone
                                    accept={LinkTypes.Image.contentTypes.map(
                                        (at) => at.extension
                                    )}
                                    maxFiles={1}
                                    maxSize={LinkTypes.Image.fileSizeLimit}
                                    multiple={false}
                                    onDropAccepted={(files) => {
                                        if (!readOnlyMode) {
                                            onDrop({
                                                file: files[0],
                                                setFieldValue: setFieldValue,
                                            });
                                        }
                                    }}
                                    noClick={readOnlyMode}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div
                                            {...getRootProps()}
                                            className={
                                                panelClasses.dropzoneArea
                                            }
                                            style={{
                                                backgroundImage: `url(${values.image_url})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'contain',
                                                backgroundPosition:
                                                    'center center',
                                            }}
                                        >
                                            <input {...getInputProps()} />
                                            {!values.image_url && (
                                                <div
                                                    className={
                                                        panelClasses.dropzoneText
                                                    }
                                                >
                                                    Asset Preview Image
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Dropzone>
                                <div className={panelClasses.previewContainer}>
                                    {value && Object.keys(value).length > 0 && (
                                        <Chip
                                            className={
                                                panelClasses.previewUploadFileName
                                            }
                                            color="secondary"
                                            size="small"
                                            deleteIcon={
                                                <MdClose className="react-icon" />
                                            }
                                            onDelete={() => {
                                                if (!readOnlyMode) {
                                                    setFieldValue('image', {});
                                                    setFieldValue(
                                                        'image_url',
                                                        ''
                                                    );
                                                }
                                            }}
                                            label={value?.name}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </Field>
                    {readOnlyMode !== true && (
                        <>
                            <SetDirtyForm />
                            <button
                                className={classes.submit}
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {formAction.buttonLabel}
                            </button>
                        </>
                    )}
                </Form>
            )}
        </Formik>
    );
};

export { AssetForm };
