import { useEffect, useState } from 'react';
import { Paper, IconButton, ClickAwayListener, Grow } from '@mui/material';
import { MdAdd } from 'react-icons/md';

import Loader from 'GeminiViewerComponent/components/Loader';
import AppButton from 'components/AppButton';
import useStyles from './style';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { Action } from '_helpers';
import { useDispatch, useSelector } from 'react-redux';
import { selectAssetTypes } from '_features/assets/assetManagerSlice';
import { openPanel } from '_features/common/formSlice';
import ImportAsset from 'components/ImportAsset';
import getActionIcon from 'GeminiViewerComponent/_helpers/getActionIcon';

const AssetTypeButton = ({ assetType }) => {
    const theme = useSelector(selectActiveTheme);
    const classes = useStyles(theme);
    const [toggle, setToggle] = useState(false);
    const dispatch = useDispatch();

    const entities = useSelector(selectAssetTypes);
    const [assetTypes, setAssetTypes] = useState([]);
    const [openImportDialog, setOpenImportDialog] = useState(false);

    useEffect(() => {
        let data = entities
            .filter((types) => types.asset_type_id !== 3)
            .map((types) => {
                if (types.asset_type_id === 1) {
                    return { ...types, display_name: '360 Orbit' };
                } else if (types.asset_type_id === 2) {
                    return { ...types, display_name: '360 Pano' };
                } else {
                    return types;
                }
            });
        data = [
            ...data,
            { asset_type_id: null, display_name: 'Standard Photos' },
            { asset_type_id: null, display_name: 'Import' },
        ];
        setAssetTypes(data);
    }, [entities]);

    const handleCreateAsset = (assetType) => {
        // if procedure use procedure form
        if (assetType?.asset_type_id === 3)
            dispatch(
                openPanel({
                    formKey: `procedureForm`,
                    formAction: 'Create',
                    assetTypeID: assetType?.asset_type_id,
                    clearEditId: true,
                })
            );
        else if (assetType?.display_name === 'Import')
            setOpenImportDialog(true);
        else
            dispatch(
                openPanel({
                    formKey: `assetForm`,
                    formAction: 'Create',
                    assetTypeID: assetType?.asset_type_id,
                    clearEditId: true,
                })
            );
    };

    const handleToggleChange = (e, assetType) => {
        e.preventDefault();
        setToggle(!toggle);
        handleCreateAsset(assetType);
    };

    return (
        <>
            <ClickAwayListener onClickAway={() => setToggle(false)}>
                <div className={classes.assetTypeContainer}>
                    {assetTypes?.length === 0 ? (
                        <Loader
                            styles={{
                                width: '44px',
                                height: '44px',
                                borderRadius: '25px',
                            }}
                        />
                    ) : (
                        <>
                            <IconButton
                                className={classes.assetTypeIcon}
                                onClick={() => {
                                    if (assetType === 'procedures') {
                                        handleCreateAsset({
                                            asset_type_id: 3,
                                            display_name: 'Procedure',
                                        });
                                    } else {
                                        setToggle(!toggle);
                                    }
                                }}
                                size="large"
                            >
                                <MdAdd className="react-icon" />
                            </IconButton>

                            <Grow
                                in={toggle}
                                style={{ transformOrigin: '0 50% 0' }}
                                {...(toggle ? { timeout: 1000 } : {})}
                            >
                                <Paper
                                    className={classes.assetTypeButtonsWrap}
                                    id="asset-type-menu"
                                >
                                    {assetTypes.map((item) => (
                                        <AppButton
                                            variant="contained"
                                            className="primaryButton"
                                            key={
                                                item?.asset_type_id +
                                                item?.display_name
                                            }
                                            style={{
                                                width: '100%',
                                                padding: '0px',
                                            }}
                                            handleButtonClick={(e) =>
                                                handleToggleChange(e, item)
                                            }
                                        >
                                            <div
                                                className={
                                                    classes.assetTypeIconWrap
                                                }
                                            >
                                                {getActionIcon(
                                                    Action[item?.display_name]
                                                        .iconId,
                                                    {
                                                        marginRight: '10px',
                                                        fill: '#374760',
                                                    }
                                                )}
                                                {item?.display_name}
                                            </div>
                                        </AppButton>
                                    ))}
                                </Paper>
                            </Grow>
                        </>
                    )}
                </div>
            </ClickAwayListener>
            <ImportAsset
                openDialog={openImportDialog}
                setOpenDialog={setOpenImportDialog}
            />
        </>
    );
};

export default AssetTypeButton;
