import { Box, IconButton } from '@mui/material';
import Popover from '@mui/material/Popover';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MdMoreVert } from 'react-icons/md';

import { accountsSlice } from 'app/store';
import { selectPanelInfo } from '_features/common/editPanelSlice';
import { getContentVersionPanelVis } from '_features/globals/visibilitySlice';
import { selectActionModalInfo } from 'GeminiViewerComponent/_features/common/actionModalSlice';
import { ContentPopup } from 'components/_Misc/PopupActions/components/ContentPopup';
import { LinkPopup } from 'components/_Misc/PopupActions/components/LinkPopup';
import { ItemPopup } from 'components/_Misc/PopupActions/components/ItemPopup';
import { ZonePopup } from 'components/_Misc/PopupActions/components/ZonePopup';
import { AssetPopup } from 'components/_Misc/PopupActions/components/AssetPopup';
import { UserManagementPopup } from 'components/_Misc/PopupActions/components/UserManagementPopup';
import { UserProfilePopup } from 'components/_Misc/PopupActions/components/UserProfilePopup';
import { GroupManagementPopup } from 'components/_Misc/PopupActions/components/GroupManagementPopup';
import { TagManagementPopup } from 'components/_Misc/PopupActions/components/TagManagementPopup';
import { AudienceManagementPopup } from 'components/_Misc/PopupActions/components/AudienceManagementPopup';
import { HotspotManagementPopup } from 'components/_Misc/PopupActions/components/HotspotManagementPopup';
import { ToolsCategoryPopup } from 'components/_Misc/PopupActions/components/ToolsCategoryPopup';
import { CategoryManagementPopup } from 'components/_Misc/PopupActions/components/CategoryManagementPopup';
import { LocationManagementPopup } from 'components/_Misc/PopupActions/components/LocationManagementPopup';
import { ToolsManagementPopup } from 'components/_Misc/PopupActions/components/ToolsManagementPopup';
import { PartsManagementPopup } from 'components/_Misc/PopupActions/components/PartsManagementPopup';
import { PartsGroupManagementPopup } from 'components/_Misc/PopupActions/components/PartsGroupManagementPopup';
import { PartsCategoryManagementPopup } from 'components/_Misc/PopupActions/components/PartsCategoryManagementPopup';
import { CompanionAppsPopup } from 'components/_Misc/PopupActions/components/CompanionAppsPopup';
import { SnippetPopup } from 'components/_Misc/PopupActions/components/SnippetPopup';
import { NewsFlashPopup } from 'components/_Misc/PopupActions/components/NewsFlashPopup';
import { ManagementDepoyPackagePopup } from './components/ManagementDepoyPackagePopup copy';

function PopupActions({ object, level, targetPopup, size = 'large' }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const editPanelInfo = useSelector(selectPanelInfo);
    const actionModalInfo = useSelector(selectActionModalInfo);
    const contentVersionVis = useSelector(getContentVersionPanelVis);
    const activeUser = useSelector(accountsSlice.selectActiveUser);
    const readOnlyMode = activeUser?.role === 'User' ? true : false;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        event.preventDefault();
    };

    const stopPropagation = (event) => {
        event.stopPropagation();
    };

    useEffect(() => {
        if (
            (editPanelInfo !== undefined && editPanelInfo.viewPanel) ||
            (actionModalInfo !== undefined && actionModalInfo.isOpen) ||
            contentVersionVis === true
        ) {
            setAnchorEl(null);
        }
    }, [editPanelInfo, actionModalInfo, contentVersionVis]);

    const handleClose = () => setAnchorEl(null);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div onClick={(event) => stopPropagation(event)}>
            <IconButton
                title={targetPopup === 'managementDeploy' && 'Manage'}
                onClick={handleClick}
                size={size}
            >
                <MdMoreVert className="react-icon" />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Box p={2}>
                    {['link', 'techu_link'].includes(targetPopup) === true && (
                        <LinkPopup
                            object={object}
                            level={level}
                            onClose={handleClose}
                            readOnlyMode={readOnlyMode}
                            targetPopup={targetPopup}
                        />
                    )}
                    {targetPopup === 'item' && (
                        <ItemPopup
                            object={object}
                            level={level}
                            onClose={handleClose}
                            readOnlyMode={readOnlyMode}
                        />
                    )}
                    {targetPopup === 'zone' && (
                        <ZonePopup
                            object={object}
                            level={level}
                            onClose={handleClose}
                            readOnlyMode={readOnlyMode}
                        />
                    )}
                    {targetPopup === 'asset' && (
                        <AssetPopup
                            object={object}
                            level={level}
                            onClose={handleClose}
                            readOnlyMode={readOnlyMode}
                            activeUser={activeUser}
                        />
                    )}
                    {targetPopup === 'groupManagement' && (
                        <GroupManagementPopup
                            object={object}
                            level={level}
                            onClose={handleClose}
                        />
                    )}
                    {targetPopup === 'tagManagement' && (
                        <TagManagementPopup
                            object={object}
                            level={level}
                            onClose={handleClose}
                        />
                    )}
                    {targetPopup === 'audienceManagement' && (
                        <AudienceManagementPopup
                            object={object}
                            level={level}
                            onClose={handleClose}
                        />
                    )}
                    {targetPopup === 'hotspotManagement' && (
                        <HotspotManagementPopup
                            object={object}
                            level={level}
                            onClose={handleClose}
                        />
                    )}
                    {targetPopup === 'toolsCategory' && (
                        <ToolsCategoryPopup
                            object={object}
                            level={level}
                            onClose={handleClose}
                        />
                    )}
                    {targetPopup === 'categoryManagement' && (
                        <CategoryManagementPopup
                            object={object}
                            level={level}
                            onClose={handleClose}
                        />
                    )}
                    {targetPopup === 'locationManagement' && (
                        <LocationManagementPopup
                            object={object}
                            level={level}
                            onClose={handleClose}
                        />
                    )}
                    {targetPopup === 'toolManagement' && (
                        <ToolsManagementPopup
                            object={object}
                            level={level}
                            onClose={handleClose}
                        />
                    )}
                    {targetPopup === 'partGroupManagement' && (
                        <PartsGroupManagementPopup
                            object={object}
                            level={level}
                            onClose={handleClose}
                        />
                    )}
                    {targetPopup === 'partCategoryManagement' && (
                        <PartsCategoryManagementPopup
                            object={object}
                            level={level}
                            onClose={handleClose}
                        />
                    )}
                    {targetPopup === 'partManagement' && (
                        <PartsManagementPopup
                            object={object}
                            level={level}
                            onClose={handleClose}
                        />
                    )}
                    {targetPopup === 'userManagement' && (
                        <UserManagementPopup
                            object={object}
                            level={level}
                            onClose={handleClose}
                        />
                    )}
                    {targetPopup === 'userProfile' && (
                        <UserProfilePopup
                            object={object}
                            level={level}
                            onClose={handleClose}
                        />
                    )}
                    {targetPopup === 'content' && (
                        <ContentPopup
                            object={object}
                            level={level}
                            onClose={handleClose}
                            readOnlyMode={readOnlyMode}
                        />
                    )}
                    {targetPopup === 'companionApps' && (
                        <CompanionAppsPopup
                            object={object}
                            level={level}
                            onClose={handleClose}
                            readOnlyMode={readOnlyMode}
                        />
                    )}
                    {targetPopup === 'snippet' && (
                        <SnippetPopup
                            object={object}
                            level={level}
                            onClose={handleClose}
                            readOnlyMode={readOnlyMode}
                        />
                    )}
                    {targetPopup === 'newsFlash' && (
                        <NewsFlashPopup
                            object={object}
                            level={level}
                            onClose={handleClose}
                            readOnlyMode={readOnlyMode}
                        />
                    )}
                    {targetPopup === 'managementDeploy' && (
                        <ManagementDepoyPackagePopup
                            object={object}
                            level={level}
                            onClose={handleClose}
                        />
                    )}
                </Box>
            </Popover>
        </div>
    );
}

export { PopupActions };
