import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { LinkTypeIds, prefixUrl } from 'GeminiViewerComponent/_helpers';
import { config } from 'gemini-config';

const initialState = {
    panelOpen: false,
    allSimilarTypeLinks: [],
    linkDisplay: false,
    linkContent: null,
    linkData: null,
    linkTypeId: null,
    overlayText: null,
    scenesOpen: false,
    marziLoaded: false,
    viewFilters: {
        tags: {
            zone_tags: [],
            item_tags: [],
        },
    },
    isChecklistLoaded: false,
    procedureContentVersion: null,
    procedureLoaded: false,
    autoPlay: false,
    videoPlayback: {
        requireVideoPlayback: false,
        videoPlaybackEndMargin: 0,
    },
    showOnlyByFilter: false,
    showAllTags: false,
    tagsNameDisplay: false,
    tagsContainerDisplay: false,
    isAssetLoaded: false,
    loadedAssetType: null,
    isDisplayAssetButton: false,
    sessionsTabIndex: 0,
    isAssociateAssetLoaded: true,
};

export const findVideoChapter = (node, contents = []) => {
    let chapters = [];
    let chapterData = [];
    if (contents && contents?.length > 0) {
        const contentData = contents.find(
            (content) => content.content_id === node?.content_id
        );
        if (contentData?.content_versions?.length > 0) {
            if (node?.content_version_id) {
                const contentVersions = contentData?.content_versions?.find(
                    (version) =>
                        version.content_version_id === node?.content_version_id
                );
                if (contentVersions?.chapters?.length > 0) {
                    chapters = contentVersions?.chapters;
                }
            } else {
                if (
                    [...contentData.content_versions]?.pop()?.chapters?.length >
                    0
                ) {
                    chapters = [...contentData.content_versions]?.pop()
                        ?.chapters;
                }
            }
        }
    }
    if (chapters && chapters.length > 0) {
        chapterData = chapters.find(
            (chapter) => chapter.chapter_id === node.chapter_id
        );
    }
    return chapterData;
};

export const showNodeContent = createAsyncThunk(
    'viewerVisibility/showNodeContent',
    async (
        { contentService, node },
        { getState, rejectWithValue, dispatch }
    ) => {
        if (node.content_url && node.content_link_type_id) {
            const url = prefixUrl(
                node.content_url,
                getState().accounts.activeUser.s3_prefix
            );
            if (config?.modeType === 'author') {
                let contentData = [];
                if (node?.content_link_type_id === LinkTypeIds.Video) {
                    const content = await contentService.fetchContentById(
                        node.content_id,
                        getState().accounts.activeUser.s3_prefix
                    );
                    contentData = content;
                }
                return {
                    last_content_version: { url: url },
                    default_link_type_id: node.content_link_type_id,
                    auto_play: node.auto_play,
                    show_chapters: node?.show_chapters,
                    collapse_chapters: node?.collapse_chapters,
                    chapters:
                        node?.show_chapters &&
                        contentData?.last_content_version?.chapters,
                    require_video_playback: node?.require_video_playback,
                    video_playback_end_margin: node?.video_playback_end_margin,
                    content_skill_id: node?.content_skill_id,
                    chapter_id: node?.chapter_id || null,
                };
            } else {
                const chapter_data = findVideoChapter(
                    node,
                    getState().asset?.dependencies?.content
                );
                let content = null;
                if (
                    getState().asset?.dependencies?.content &&
                    getState().asset?.dependencies?.content?.length > 0
                ) {
                    content = getState().asset?.dependencies?.content.find(
                        (content) => content.content_id === node.content_id
                    );
                }
                return {
                    last_content_version: { url: url },
                    default_link_type_id: node.content_link_type_id,
                    auto_play: node.auto_play,
                    show_chapters: node?.show_chapters,
                    collapse_chapters: node?.collapse_chapters,
                    chapters:
                        (node?.show_chapters &&
                            content?.last_content_version?.chapters) ||
                        (node?.show_chapters &&
                            content?.content_versions &&
                            content?.content_versions?.length > 0 &&
                            [...content.content_versions]?.pop()?.chapters),
                    require_video_playback: node?.require_video_playback,
                    video_playback_end_margin: node?.video_playback_end_margin,
                    content_skill_id: node?.content_skill_id,
                    chapter_data: chapter_data || null,
                };
            }
        }

        if (contentService || config.modeType === 'author') {
            var content = await contentService.fetchContentById(
                node.content_id,
                getState().accounts.activeUser.s3_prefix
            );
            content.auto_play = node.auto_play;
            return content;
        } else {
            if (
                getState().asset?.dependencies?.content &&
                getState().asset?.dependencies?.content?.length > 0
            ) {
                content = getState().asset?.dependencies?.content.find(
                    (content) => content.content_id === node.content_id
                );
                let updatedContent = { ...content };
                updatedContent.default_link_type_id =
                    updatedContent?.default_link_type_id ||
                    updatedContent?.link_type_id;

                return updatedContent;
            }
        }

        return rejectWithValue('Could not load node content');
    }
);

const viewerVisibilitySlice = createSlice({
    name: 'viewerVisibility',
    initialState,
    reducers: {
        setTagFilters: (state, action) => {
            state.viewFilters.tags = action.payload;
        },
        setScenesOpen: (state, action) => {
            return { ...state, scenesOpen: action.payload };
        },
        setPanelVis: (state, action) => {
            return { ...state, panelOpen: action.payload.open };
        },
        setLinkDisplayVis: (state, action) => {
            return {
                ...state,
                linkDisplay: action.payload.open,
                linkContent: action.payload.linkContent,
                linkData: action.payload.linkData,
                allSimilarTypeLinks: action.payload.allLinks,
                linkTypeId: action.payload.linkTypeId,
                autoPlay: action.payload?.autoPlay,
                chapter_id: action.payload?.chapter_id,
                chapter_data: action.payload?.chapter_data,
                overlayText:
                    action.payload.linkTypeId === LinkTypeIds.Image &&
                    action.payload?.overlayText
                        ? JSON.parse(action.payload?.overlayText)
                        : null,
            };
        },
        displayProcedureContentVersion: (state, action) => {
            state.procedureContentVersion = action.payload;
            state.procedureLoaded = action?.payload ? true : false;
        },
        setMarziLoaded: (state, action) => {
            return { ...state, marziLoaded: true };
        },
        resetVisibilityState: (state) => initialState,
        loadChecklist: (state, action) => {
            state.isChecklistLoaded = action.payload;
        },
        setShowOnlyByFilter: (state, action) => {
            state.showOnlyByFilter = !state.showOnlyByFilter;
        },
        setShowAllTags: (state, action) => {
            state.showAllTags = !state.showAllTags;
        },
        setTagsNameDisplay: (state, action) => {
            state.tagsNameDisplay = !state.tagsNameDisplay;
        },
        setTagsContainerDisplay: (state, action) => {
            state.tagsContainerDisplay = !state.tagsContainerDisplay;
        },
        loadAsset: (state, action) => {
            state.isAssetLoaded = action.payload;
        },
        setAssetType: (state, action) => {
            state.loadedAssetType = action.payload;
        },
        setDisplayAssetButton: (state, action) => {
            state.isDisplayAssetButton = action.payload;
        },
        setSessionsTabIndex: (state, action) => {
            state.sessionsTabIndex = action.payload;
        },
        toggleContentValidationModal: (state) => {
            return {
                ...state,
                contentValidationModal: !state.contentValidationModal,
            };
        },
        setContentValidationModalContent: (state, action) => {
            state.contentValidationModalContent = action.payload;
        },
        setChapterData: (state, action) => {
            state.chapter_data = action.payload;
        },
        loadAssociateAsset: (state, action) => {
            state.isAssociateAssetLoaded = action.payload;
        },
    },
    extraReducers: {
        [showNodeContent.fulfilled]: (state, action) => {
            return {
                ...state,
                linkDisplay: true,
                linkContent:
                    action.payload.last_content_version?.encoding_status &&
                    action.payload.last_content_version?.encoding_status.toLowerCase() ===
                        'complete'
                        ? action.payload.last_content_version?.encoded_url
                        : action.payload.last_content_version.url,
                linkData:
                    action.payload?.default_link_type_id === LinkTypeIds.SME ||
                    action.payload?.default_link_type_id === LinkTypeIds.Video
                        ? action.payload
                        : { skill_id: action.payload.content_skill_id },
                linkTypeId: action.payload.default_link_type_id,
                autoPlay: action.payload.auto_play,
                overlayText: action.payload.last_content_version?.overlay_text,
                chapter_id: action.payload?.chapter_id,
                chapter_data: action.payload?.chapter_data,
                videoPlayback: {
                    requireVideoPlayback:
                        !!action.payload?.require_video_playback,
                    videoPlaybackEndMargin:
                        action.payload?.video_playback_end_margin,
                },
            };
        },
    },
});

export const getPanelVis = (state) => state.viewerVisibility.panelOpen;
export const getLinkAutoPlay = (state) =>
    Boolean(state.viewerVisibility.autoPlay);
export const getLinkOverlayText = (state) => state.viewerVisibility.overlayText;
export const getLinkVideoPlayback = (state) =>
    state.viewerVisibility.videoPlayback;
export const getLinkDisplayVis = (state) => state.viewerVisibility.linkDisplay;
export const getLinkDisplayContent = (state) =>
    state.viewerVisibility.linkContent;
export const getLinkDisplayData = (state) => state.viewerVisibility.linkData;
export const getProcedureContentVersion = (state) => {
    return state.viewerVisibility.procedureContentVersion;
};
export const getProcedureLoaded = (state) =>
    state.viewerVisibility.procedureLoaded;
export const getlLinkTypeId = (state) => state.viewerVisibility.linkTypeId;
export const getScenesOpen = (state) => state.viewerVisibility.scenesOpen;
export const getMarziLoaded = (state) => state.viewerVisibility.marziLoaded;
export const getTagFilters = (state) => state.viewerVisibility.viewFilters.tags;
export const getShowOnlyByFilter = (state) =>
    state.viewerVisibility.showOnlyByFilter;
export const getTagsNameDisplay = (state) =>
    state.viewerVisibility.tagsNameDisplay;
export const getTagsContainerDisplay = (state) =>
    state.viewerVisibility.tagsContainerDisplay;
export const getShowAllTags = (state) => state.viewerVisibility.showAllTags;
export const getIsChecklistLoaded = (state) =>
    state.viewerVisibility.isChecklistLoaded;
export const getIsAssetLoaded = (state) => state.viewerVisibility.isAssetLoaded;
export const getIsAssociateAssetLoaded = (state) =>
    state.viewerVisibility.isAssociateAssetLoaded;
export const getDisplayAssetButton = (state) =>
    state.viewerVisibility.isDisplayAssetButton;
export const getLoadedAssetType = (state) =>
    state.viewerVisibility.loadedAssetType;
export const getSessionsTabIndex = (state) =>
    state.viewerVisibility.sessionsTabIndex;
export const getChapterId = (state) => state.viewerVisibility.chapter_id;
export const getChapterData = (state) => state.viewerVisibility.chapter_data;
export const getAllSimilarLinks = (state) =>
    state.viewerVisibility.allSimilarTypeLinks;

export const {
    resetVisibilityState,
    setPanelVis,
    setLinkDisplayVis,
    setMarziLoaded,
    setScenesOpen,
    setTagFilters,
    loadChecklist,
    displayProcedureContentVersion,
    setShowOnlyByFilter,
    setShowAllTags,
    setTagsNameDisplay,
    setTagsContainerDisplay,
    loadAsset,
    setAssetType,
    setDisplayAssetButton,
    setSessionsTabIndex,
    setChapterData,
    loadAssociateAsset,
} = viewerVisibilitySlice.actions;

export default viewerVisibilitySlice.reducer;
