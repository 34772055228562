import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './styles';
import { AutoSizer } from 'react-virtualized';
import { accountsSlice, accountsThunk } from 'app/store';
import {
    Switch,
    FormControlLabel,
    MenuItem,
    Checkbox,
    TextField,
    IconButton,
    Tooltip,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {
    MdAdd,
    MdCheckBox,
    MdCheckBoxOutlineBlank,
    MdEdit,
} from 'react-icons/md';

import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';
import { Action } from '_helpers';
import {
    activeCell,
    labelHeader,
    labelCell,
    actionCell,
    checkCell,
    checkHeaderCell,
    VirtualTable,
} from 'components';
import { tableStyles } from 'components/_Tables/TableCells/styles';
import { EnhancedTableToolbar } from 'GeminiViewerComponent/components/EnhancedTableToolbar';
import {
    closePanel,
    openPanel,
    setEditObjectId,
} from '_features/common/formSlice';
import SearchWithFilters from 'components/SearchWithFilters';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { EditMultipleUsersDialog } from 'components/EditMultipleUsersDialog';
import { makeButtonStyles } from '../../../../GeminiViewerComponent/components/styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { MultiSelectDoneButton } from 'GeminiViewerComponent/components/MultiSelectDoneButton';
import { debounce } from 'GeminiViewerComponent/_helpers/lodashUtils';

const { selectActiveUser, selectAllRoles, getRolesStatus } = accountsSlice;
const { fetchRoles } = accountsThunk;

function UserTable({
    users,
    userPayload,
    setUserPayload,
    sortDirection,
    sortBy,
    handleSort,
}) {
    const dispatch = useDispatch();
    const theme = useSelector(selectActiveTheme);
    const buttonStyles = makeButtonStyles(theme);
    const classes = useStyles();
    const tableClasses = tableStyles();
    const [selected, setSelected] = useState([]);
    const [selectedAll, setSelectedAll] = useState(false);

    const currentUser = useSelector((state) => selectActiveUser(state));
    const [selectedRole, setSelectedRole] = useState([]);
    const [activeOnly, setActiveOnly] = useState(true);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [filterBadge, setFilterBadge] = useState(0);
    const [editDialog, setEditDialog] = useState(false);
    const roles = useSelector((state) => selectAllRoles(state));
    const roleStatus = useSelector((state) => getRolesStatus(state));
    useEffect(() => {
        if (roleStatus === LoadingStatus.Idle) {
            dispatch(fetchRoles());
        }
    }, [roleStatus]);

    useEffect(() => {
        // Remove current user from table
        var exceptUsers = [...users].filter(
            (x) => x.user_id !== currentUser.user_id
        );
        if (activeOnly) {
            const activeUsers = exceptUsers.filter(
                (usr) => usr?.is_active === true
            );
            setFilteredUsers(activeUsers);
        } else {
            setFilteredUsers(exceptUsers);
        }
    }, [users, activeOnly]);

    const handleActiveOnly = () => setActiveOnly(!activeOnly);

    const loadedRowsMap = useSelector(
        (state) => state.accounts.loadedAccountsMap
    );

    const headerHeight = 40;
    const rowHeight = 60;

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const prevSelectedAll = usePrevious(selectedAll);
    if (prevSelectedAll && selectedAll) {
        if (selected.length !== filteredUsers.length) {
            setSelected(filteredUsers.map((row) => row.user_id));
            setSelectedAll(true);
        }
    }

    const handleClick = (event, user_id) => {
        event.stopPropagation();
        const selectedIndex = selected.indexOf(user_id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, user_id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
        setSelectedAll(selected.length === filteredUsers.length);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelectedIds = filteredUsers.map((user) => user.user_id);
            setSelected(newSelectedIds);
            setSelectedAll(true);
            return;
        }
        setSelected([]);
        setSelectedAll(false);
    };

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const handleRowClick = async ({ rowData }) => {
        await dispatch(closePanel({ formKey: 'accountForm' }));
        if (rowData?.user_id) {
            await dispatch(
                setEditObjectId({
                    formKey: `accountForm`,
                    object_id: rowData?.user_id,
                })
            );
            dispatch(openPanel({ formKey: `accountForm`, formAction: 'Edit' }));
        }
    };

    const columns = [
        {
            classes: tableClasses,
            minWidth: 50,
            maxWidth: 50,
            width: 50,
            label: '',
            dataKey: 'user_id',
            padding: 'checkbox',
            cellLevel: 'user',
            numSelected: selected.length,
            rowCount: filteredUsers.length,
            handleClick: handleClick,
            handleSelectAllClick: handleSelectAllClick,
            isSelected: isSelected,
            component: checkCell,
            headerComponent: checkHeaderCell,
        },
        {
            classes: tableClasses,
            minWidth: 50,
            maxWidth: 50,
            width: 50,
            label: '',
            dataKey: 'user_id',
            padding: 'none',
            actionCellLevel: 'user',
            targetPopup: 'userManagement',
            formKey: 'accountForm',
            dispatch: dispatch,
            setViewPanel: false,
            component: actionCell,
            headerComponent: labelHeader,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 120,
            minWidth: 120,
            label: 'First Name',
            dataKey: 'first_name',
            padding: 'normal',
            component: labelCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 120,
            minWidth: 120,
            label: 'Last Name',
            dataKey: 'last_name',
            padding: 'normal',
            component: labelCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 120,
            minWidth: 120,
            label: 'Email',
            dataKey: 'email',
            padding: 'normal',
            component: labelCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 120,
            minWidth: 120,
            label: 'Role',
            dataKey: 'role',
            padding: 'normal',
            component: labelCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 120,
            minWidth: 120,
            label: 'Active',
            dataKey: 'is_active',
            padding: 'normal',
            component: activeCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
    ];

    // eslint-disable-next-line no-unused-vars
    function loadMoreRows({ startIndex, stopIndex }) {}

    const debouncedSearch = useCallback(
        debounce((searchString) => {
            setUserPayload({ ...userPayload, searchString: searchString });
        }, 1000),
        [userPayload]
    );

    const handleSearch = (searchString) => debouncedSearch(searchString);

    const updateInputValue = (evt) => handleSearch(evt.target.value);

    const addFilters = () => {
        if (Array.isArray(selectedRole) && selectedRole.length > 0) {
            const roles = selectedRole.map((rl) => {
                return rl?.display_name;
            });
            setUserPayload({ ...userPayload, roles: roles });
            setFilterBadge(1);
        } else {
            clearFilters();
            setFilterBadge(0);
            setSelectedRole([]);
        }
    };

    const clearFilters = () => {
        let payload = userPayload;
        delete payload?.roles;
        setUserPayload({ ...payload });
        setFilterBadge(0);
        setSelectedRole([]);
    };

    const handleCreateNew = () => {
        dispatch(
            openPanel({
                formKey: 'accountForm',
                formAction: 'Create',
                clearEditId: true,
            })
        );
    };

    return (
        <div className={classes.root}>
            {selected.length > 0 && (
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    toolTipTitle={'Delete'}
                    totalCount={filteredUsers.length}
                >
                    <IconButton
                        onClick={() => setEditDialog(true)}
                        size="small"
                        toolTipTitle="Edit"
                    >
                        <MdEdit
                            className="react-icon"
                            style={{ width: '20px', height: '20px' }}
                        />
                    </IconButton>
                    <PopupAction
                        action={Action.DeleteArray}
                        object={{
                            accountIds: selected,
                            setAccountIds: setSelected,
                        }}
                        level={'user'}
                        showLabel={false}
                        toolTipTitle="Delete"
                    />
                </EnhancedTableToolbar>
            )}
            <div style={{ display: 'flex', gap: '5px' }}>
                <Tooltip title="Add New Account">
                    <IconButton
                        onClick={handleCreateNew}
                        size="large"
                        className={buttonStyles.contentAddButton}
                    >
                        <MdAdd className="react-icon" />
                    </IconButton>
                </Tooltip>
                <div style={{ flexGrow: 1 }}></div>
                <div>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={activeOnly}
                                onChange={handleActiveOnly}
                            />
                        }
                        label="Active Only"
                    />
                </div>
                <SearchWithFilters
                    filterBadge={filterBadge}
                    addFilters={addFilters}
                    clearFilters={clearFilters}
                    onChange={(evt) => updateInputValue(evt)}
                >
                    <MenuItem disableRipple>
                        {roles.length > 0 && (
                            <Autocomplete
                                variant="outline"
                                id="roles"
                                multiple
                                limitTags={2}
                                options={roles}
                                onChange={(event, newInputValue) => {
                                    setSelectedRole(newInputValue);
                                }}
                                disableCloseOnSelect
                                value={selectedRole}
                                getOptionLabel={(option) =>
                                    option?.display_name
                                }
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={
                                                <MdCheckBoxOutlineBlank className="react-icon" />
                                            }
                                            checkedIcon={
                                                <MdCheckBox className="react-icon" />
                                            }
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option?.display_name}
                                    </li>
                                )}
                                style={{ width: 500 }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Role"
                                        placeholder="Role"
                                    />
                                )}
                                PaperComponent={MultiSelectDoneButton}
                            />
                        )}
                    </MenuItem>
                </SearchWithFilters>
            </div>
            <div style={{ height: 'calc(100vh - 295px)', minWidth: '500px' }}>
                <AutoSizer>
                    {({ width, height }) => (
                        <VirtualTable
                            initialRows={filteredUsers}
                            allRows={filteredUsers}
                            totalRowCount={filteredUsers.length}
                            loadedRowsMap={loadedRowsMap}
                            loadMoreRows={loadMoreRows}
                            columnDefinitions={columns}
                            cellClassName={tableClasses.flexContainer}
                            className={tableClasses.table}
                            rowClassName={tableClasses.flexContainer}
                            headerHeight={headerHeight}
                            width={width}
                            height={height}
                            rowHeight={rowHeight}
                            onRowClick={handleRowClick}
                        />
                    )}
                </AutoSizer>
            </div>
            <EditMultipleUsersDialog
                open={editDialog}
                handleClose={() => setEditDialog(false)}
                users={selected}
            />
        </div>
    );
}

export { UserTable };
