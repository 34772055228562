import React from 'react';
import { Slider } from '@mui/material';
import { Recommendation } from '../Recommendation';
import { recommendationsStyles } from './styles';

const Recommendations = ({ recs }) => {
    const classes = recommendationsStyles();
    const [value, setValue] = React.useState([0, 100]);
    const [showRecs, setShowRecs] = React.useState(recs);

    const userRole = 1;

    const handleChange = (event, newValue) => {
        setValue(newValue);
        var newRecs = [];
        recs.map((r) => {
            if (
                newValue[0] <= r.certainty &&
                r.certainty <= newValue[1] &&
                r.role_ids.includes(userRole)
            ) {
                newRecs.push(r);
            }
            return true
        });
        setShowRecs(newRecs);
    };

    return (
        <div>
            <div className={classes.recHeader}>
                Recommendations
                <div className={classes.slider}>
                    <Slider
                        value={value}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        style={{ color: 'grey' }}
                    />
                </div>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {showRecs.map((r) => {
                    return (
                        r.role_ids.includes(userRole) && (
                            <Recommendation rec={r} />
                        )
                    );
                })}
            </div>
        </div>
    );
};

export { Recommendations };
