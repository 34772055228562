// eslint-disable-next-line
import React, { createElement } from 'react';
import delta from '../../../../delta.png';

import { DtNodeActions } from '../DtNodeActions';
import { dtHeaderStyles } from './styles';

const DtHeader = ({ nodeName }) => {
    const classes = dtHeaderStyles();

    return (
        <div className={classes.dtHeader}>
            <DtNodeActions />
            <div className={classes.dtHeaderTitle}>
                <img
                    src={delta}
                    alt=""
                    style={{ height: '30px', marginRight: '5px' }}
                />
                {/* <SiTreehouse className={classes.dtHeaderIcon} /> */}
                <div>{nodeName}</div>
            </div>
        </div>
    );
};

export { DtHeader };
