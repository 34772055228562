import { makeStyles } from '@mui/styles';

const dtHeaderStyles = makeStyles(() => ({
    dtHeader: {
        display: 'flex',
        height: '50px',
        backgroundColor: '#54595f',
        color: 'white',
        alignItems: 'center',
        fontSize: '20px',
        justifyContent: 'space-between',
    },
    dtHeaderTitle: {
        display: 'flex',
        alignItems: 'center',
        margin: '0px 10px',
    },
    dtHeaderIcon: {
        margin: '0px 10px',
    },
}));

export { dtHeaderStyles };
