import { makeStyles } from '@mui/styles';

const toolsFilterStyle = makeStyles((theme) => ({
    actionBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '10px',
        width: '100%',
    },
    contentActionBar: {
        width: '100%',
        marginTop: '12px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        gap: '20px',
        alignItems: 'center',
    },
    filterFieldset: {
        padding: '7px',
        border: '1px solid #cccccc',
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'left',
        gap: '10px',
        flexWrap: 'nowrap',
    },
    filterFieldsetLegend: {
        marginLeft: '10px',
        padding: '5px',
        fontSize: '13px',
        color: '#6d7178',
    },
}));

export default toolsFilterStyle;
