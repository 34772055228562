import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AutoSizer } from 'react-virtualized';
import { MdAdd } from 'react-icons/md';
import { IconButton, Tooltip } from '@mui/material';

import { useStyles } from './styles';
import {
    labelHeader,
    labelCell,
    actionCell,
    checkCell,
    checkHeaderCell,
    VirtualTable,
} from 'components';
import { tableStyles } from 'components/_Tables/TableCells/styles';
import { EnhancedTableToolbar } from 'GeminiViewerComponent/components/EnhancedTableToolbar';
import SearchWithFilters from 'components/SearchWithFilters';
import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';
import { Action } from '_helpers';
import {
    closePanel,
    openPanel,
    setEditObjectId,
} from '_features/common/formSlice';
import { makeButtonStyles } from '../../../../GeminiViewerComponent/components/styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { debounce } from 'GeminiViewerComponent/_helpers/lodashUtils';

function CompanionAppsTable({
    companionApps,
    pageInfo,
    companionPayload,
    setCompanionPayload,
    sortDirection,
    sortBy,
    handleSort,
}) {
    const dispatch = useDispatch();
    const theme = useSelector(selectActiveTheme);
    const buttonStyles = makeButtonStyles(theme);
    const classes = useStyles();
    const tableClasses = tableStyles();

    const [selected, setSelected] = useState([]);
    const [selectedAll, setSelectedAll] = React.useState(false);

    const loadedRowsMap = useSelector(
        (state) => state.companionApps.loadedRowsMap
    );

    const headerHeight = 40;
    const rowHeight = 60;
    const isSelected = (id) => selected.indexOf(id) !== -1;

    const prevSelectedAll = usePrevious(selectedAll);
    if (prevSelectedAll && selectedAll) {
        if (selected.length !== companionApps.length) {
            setSelected(companionApps.map((row) => row.companion_app_id));
            setSelectedAll(true);
        }
    }

    const handleClick = (event, companion_app_id) => {
        event.stopPropagation();
        const selectedIndex = selected.indexOf(companion_app_id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, companion_app_id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
        setSelectedAll(selected.length === companionApps.length);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelectedIds = companionApps.map(
                (companionApp) => companionApp.companion_app_id
            );
            setSelected(newSelectedIds);
            setSelectedAll(true);
            return;
        }
        setSelected([]);
        setSelectedAll(false);
    };

    const handleRowClick = async ({ rowData }) => {
        await dispatch(closePanel({ formKey: 'companionForm' }));
        if (rowData?.companion_app_id) {
            await dispatch(
                setEditObjectId({
                    formKey: `companionForm`,
                    object_id: rowData?.companion_app_id,
                })
            );
            dispatch(
                openPanel({ formKey: `companionForm`, formAction: 'Edit' })
            );
        }
    };

    const columns = [
        {
            classes: tableClasses,
            minWidth: 50,
            maxWidth: 50,
            width: 50,
            label: 'Select',
            dataKey: 'companion_app_id',
            padding: 'checkbox',
            cellLevel: 'companion_app',
            numSelected: selected.length,
            rowCount: companionApps.length,
            handleClick: handleClick,
            handleSelectAllClick: handleSelectAllClick,
            isSelected: isSelected,
            component: checkCell,
            headerComponent: checkHeaderCell,
        },
        {
            classes: tableClasses,
            minWidth: 50,
            maxWidth: 50,
            width: 50,
            label: '',
            dataKey: 'companion_app_id',
            padding: 'none',
            actionCellLevel: 'companion_app',
            targetPopup: 'companionApps',
            formKey: 'companionForm',
            dispatch: dispatch,
            component: actionCell,
            headerComponent: labelHeader,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 120,
            minWidth: 120,
            label: 'Name',
            dataKey: 'display_name',
            padding: 'normal',
            component: labelCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 200,
            minWidth: 200,
            label: 'URL',
            dataKey: 'app_url',
            padding: 'normal',
            component: labelCell,
            headerComponent: labelHeader,
            sortBy: sortBy,
            sortDirection: sortDirection,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 120,
            minWidth: 120,
            label: 'N* ASSETS',
            dataKey: 'asset_count',
            displayDataKey: '',
            padding: 'none',
            component: labelCell,
            headerComponent: labelHeader,
        },
    ];

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    // eslint-disable-next-line no-unused-vars
    function loadMoreRows({ startIndex, stopIndex }) {}

    const debouncedSearch = useCallback(
        debounce((searchString) => {
            setCompanionPayload({
                ...companionPayload,
                searchString: searchString,
            });
        }, 1000),
        [companionPayload]
    );

    const handleCreateNew = () => {
        dispatch(
            openPanel({
                formKey: `companionForm`,
                formAction: 'Create',
                clearEditId: true,
            })
        );
    };

    return (
        <div className={classes.root}>
            {selected.length > 0 && (
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    toolTipTitle={'Delete'}
                    totalCount={pageInfo.TotalCount}
                >
                    <PopupAction
                        action={Action.DeleteArray}
                        object={{
                            companionAppIds: selected,
                            setCompanionAppIds: setSelected,
                        }}
                        level={'companion_app'}
                        showLabel={false}
                    />
                </EnhancedTableToolbar>
            )}
            <div style={{ display: 'flex', gap: '5px' }}>
                <Tooltip title="Add New Companion App">
                    <IconButton
                        onClick={handleCreateNew}
                        size="large"
                        className={buttonStyles.contentAddButton}
                    >
                        <MdAdd className="react-icon" />
                    </IconButton>
                </Tooltip>
                <div style={{ flexGrow: 1 }}></div>
                <SearchWithFilters
                    filter={false}
                    onChange={(evt) => debouncedSearch(evt.target.value)}
                />
            </div>
            <div style={{ height: 'calc(100vh - 295px)', minWidth: '500px' }}>
                <AutoSizer>
                    {({ width, height }) => (
                        <VirtualTable
                            initialRows={companionApps}
                            allRows={companionApps}
                            totalRowCount={pageInfo.TotalCount}
                            loadedRowsMap={loadedRowsMap}
                            loadMoreRows={loadMoreRows}
                            columnDefinitions={columns}
                            cellClassName={tableClasses.flexContainer}
                            className={tableClasses.table}
                            rowClassName={tableClasses.flexContainer}
                            headerHeight={headerHeight}
                            width={width}
                            height={height}
                            rowHeight={rowHeight}
                            onRowClick={handleRowClick}
                        />
                    )}
                </AutoSizer>
            </div>
        </div>
    );
}

export { CompanionAppsTable };
