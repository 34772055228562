// eslint-disable-next-line
import React, { createElement } from 'react';
import Grid from '@mui/material/Grid';
import { DecisionOptionPicture } from '../DecisionOptionPicture';
import { dtSingleChoicePictureStyles } from './styles.jsx';

const SingleChoicePicture = ({ nodeOptions }) => {
    const classes = dtSingleChoicePictureStyles();

    return (
        <Grid className={classes.dtSingleChoicePicture} container spacing={3}>
            {nodeOptions.map((option) => {
                return (
                    <DecisionOptionPicture
                        option={option}
                        key={option.button_id}
                    />
                );
            })}
        </Grid>
    );
};

export { SingleChoicePicture };
