import { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Checkbox, FormControlLabel, Popper } from '@mui/material';

import { snackbarHandler } from '_helpers';
import { useCategoryPopoverStyles } from './styles';
import { Each } from 'GeminiViewerComponent/_helpers/Each';
import { editAsset } from '_features/assets/editAssetSlice';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { selectActiveAsset } from 'GeminiViewerComponent/_features/asset/assetSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    fetchAllCategories,
    getAllCategories,
    getCategoryStatus,
    resetCategoryStatus,
} from '_features/category/categorySlice';

const CategoryPopoverAction = ({ id, anchorEl, object }) => {
    //#region Constants
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useSelector(selectActiveTheme);
    const classes = useCategoryPopoverStyles(theme);
    //#endregion Constants

    //#region Hooks
    //#endregion Hooks

    //#region State
    const [categoryIds, setCategoryIds] = useState([]);
    //#endregion State

    //#region Selectors
    const assetData = useSelector(selectActiveAsset);
    const allCategories = useSelector(getAllCategories);
    const categoryStatus = useSelector(getCategoryStatus);

    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Effects
    useEffect(() => {
        const fetchCategories = async () => {
            await dispatch(activateLoading());
            await dispatch(fetchAllCategories({ page: 1, pageSize: 100 }));
            await dispatch(deactivateLoading());
        };

        if (categoryStatus !== LoadingStatus.Loaded) {
            fetchCategories();
        }
    }, [anchorEl]);

    useEffect(() => {
        const fetchAsset = async () => {
            if (object?.categories && object?.categories?.length > 0) {
                setCategoryIds(
                    object?.categories.map(
                        (category) => category?.category_id
                    ) || []
                );
            }
        };
        if (assetData && assetData?.asset_id === object?.asset_id) {
            if (assetData?.categories && assetData?.categories?.length > 0) {
                setCategoryIds(
                    assetData.categories.map(
                        (category) => category?.category_id
                    ) || []
                );
            }
            return;
        }
        fetchAsset();
    }, [object?.asset_id]);
    //#endregion Effects

    //#region Methods
    const handleCategorySelect = useCallback(
        (category_id, checked) => {
            if (!checked) {
                const selectedIds = [...categoryIds];
                const index = selectedIds.findIndex((id) => id === category_id);
                if (index !== -1) {
                    selectedIds.splice(
                        selectedIds.findIndex((id) => id === category_id),
                        1
                    );
                    setCategoryIds(selectedIds);
                }
                return;
            }
            if (categoryIds.find((id) => id === category_id)) {
                return;
            }
            setCategoryIds((prev) => [...prev, category_id]);
        },
        [categoryIds]
    );

    const updateAssetCategory = useCallback(async () => {
        await dispatch(activateLoading());
        const resultAction = await dispatch(
            editAsset({
                asset_id: object?.asset_id,
                categories: categoryIds,
                player_url: assetData?.player_url || '',
                nav_hotspot_icon_id: assetData?.nav_hotspot_icon_id,
                item_hotspot_icon_id: assetData?.item_hotspot_icon_id,
            })
        );
        await dispatch(deactivateLoading());
        dispatch(resetCategoryStatus());
        if (!resultAction.error) {
            const { message, variant } = snackbarHandler(
                resultAction.meta.requestStatus,
                'Edit'
            );
            enqueueSnackbar(message, {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: variant,
            });
        }
    }, [categoryIds, assetData]);
    //#endregion Methods

    //#region Render time calcs
    //#endregion Render time calcs

    //#region Render

    return (
        allCategories &&
        allCategories?.length > 0 && (
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement={'right-start'}
                transition={false}
                className={classes.popupWrapper}
            >
                <Box className={classes.categoriesWrapper}>
                    <Each
                        of={[...allCategories]}
                        render={(data) => (
                            <Box className={classes.popupActionContainer}>
                                <FormControlLabel
                                    sx={{ width: '100%', margin: '0' }}
                                    control={
                                        <Checkbox
                                            checked={categoryIds.includes(
                                                data?.category_id
                                            )}
                                            onChange={(e) =>
                                                handleCategorySelect(
                                                    data?.category_id,
                                                    e.target.checked
                                                )
                                            }
                                            name={data?.display_name}
                                        />
                                    }
                                    label={data?.display_name}
                                />
                            </Box>
                        )}
                    />
                </Box>
                <Button
                    className={clsx(
                        classes.submit,
                        classes.multiSelectDoneButton
                    )}
                    onClick={updateAssetCategory}
                >
                    Done
                </Button>
            </Popper>
        )
    );
    //#endregion Render
};
export { CategoryPopoverAction };
