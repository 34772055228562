// eslint-disable-next-line
import React, { createElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IoCaretBackCircleSharp } from 'react-icons/io5';

import {
    setCurrentNode,
    removeReversal,
    getPreviousNode,
} from '../../../../../../_features/decisionTree/decisionTreeSlice';
import { saveStatement } from 'GeminiViewerComponent/_features/xAPI/xapiSlice';
import { LrsVerbs } from 'GeminiViewerComponent/_features/xAPI/xApiData';

import { dtNodeActionStyles } from '../../styles';

const DtBackAction = () => {
    const dispatch = useDispatch();
    const classes = dtNodeActionStyles();
    const previousNode = useSelector((state) => getPreviousNode(state));

    const handleBack = () => {
        dispatch(setCurrentNode({ node_id: previousNode }));
        dispatch(removeReversal());

        dispatch(
            saveStatement({
                LrsVerb: LrsVerbs.Progressed,
                response: { action: 'handleBack' },
            })
        );
    };

    return (
        <div className={classes.dtAction} onClick={handleBack}>
            <IoCaretBackCircleSharp />
            <div>Back</div>
        </div>
    );
};

export { DtBackAction };
