import { config } from 'gemini-config';
import { getAccountService } from 'GeminiViewerComponent/_features/services/account.service';
import { getNetworkService, prefixUrl } from 'GeminiViewerComponent/_helpers';

const baseUrl = `${config.apiUrl}/deploymentpackage`;
const jobBaseUrl = `${config.apiUrl}/deploymentpackagejob`;
const accountService = getAccountService({ config: config });
const networkService = getNetworkService({
    config: config,
    checkAuth: accountService.checkAuth,
});

const setS3PrefixUrl = (deploymentPackage, s3Prefix) => {
    deploymentPackage.file_url = prefixUrl(
        deploymentPackage.file_url,
        s3Prefix
    );
    return deploymentPackage;
};

const mapDataWithS3Prefix = (data, s3Prefix) => {
    return data.map((deploymentPackage) => {
        return setS3PrefixUrl(deploymentPackage, s3Prefix);
    });
};

const getAllByClientId = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    try {
        const response = await networkService.get(
            `${baseUrl}/getallbyclientid/${user.client_id}`
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getAll = async (
    page = 1,
    pageSize = 10,
    searchString = '',
    sort = 'display_name',
    activeOnly = true
) => {
    try {
        const response = await networkService.get(`${baseUrl}`);
        const deploymentPackages = response.data;
        const pageInfo = response.headers['x-pagination'];
        return { pageInfo: pageInfo, deploymentPackages: deploymentPackages };
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getById = async (deployment_package_id) => {
    try {
        const response = await networkService.get(
            `${baseUrl}/${deployment_package_id}`
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const fetchGroupUsageById = async (contentId) => {
    try {
        const response = await networkService.get(
            `${baseUrl}/usage/${contentId}`
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const update = async (id, params) => {
    try {
        const response = await networkService.put(`${baseUrl}/${id}`, params);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const create = async (params) => {
    const { client_id } = JSON.parse(localStorage.getItem('user'));
    params.client_id = client_id;
    try {
        const response = await networkService.post(baseUrl, params);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const _delete = async (id) => {
    try {
        const response = await networkService.delete(`${baseUrl}/${id}`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const deleteArray = async (ids) => {
    const query = ids.join('&ids=');
    try {
        const response = await networkService.delete(
            `${baseUrl}/?ids=${query}`
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getAllDeploymentJob = async (
    searchString = '',
    sort = 'created_date',
    s3Prefix = ''
) => {
    const filters = [searchString ? `file_name@=*${searchString}` : ''];

    const params = {
        filters: filters.join(','),
        sorts: sort,
    };

    const query = new URLSearchParams(params);

    try {
        const response = await networkService.get(`${jobBaseUrl}/?${query}`);
        const deploymentPackages = mapDataWithS3Prefix(response.data, s3Prefix);
        const pageInfo = response.headers['x-pagination'];
        return {
            pageInfo: pageInfo,
            deploymentPackageJobs: deploymentPackages,
        };
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const addDeploymentJob = async (params) => {
    try {
        const response = await networkService.post(jobBaseUrl, params);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

export const deploymentPackageService = {
    getAllByClientId,
    getAll,
    getById,
    create,
    update,
    delete: _delete,
    fetchGroupUsageById,
    deleteArray,
    getAllDeploymentJob,
    addDeploymentJob,
};
