import React from 'react';
import { MdAdd } from 'react-icons/md';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { CheckboxWithLabel } from 'formik-mui';
import useResizeObserver from 'use-resize-observer';
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup,
    Tooltip,
    useTheme,
} from '@mui/material';

import { makeFormStyles } from 'forms/styles';
import { contentService } from '_features/_services';
import { FormTextField } from 'components/TextField';
import { makeContentNodeFormStyles } from '../styles';
import { ExcludeFromNavigationField } from '../../CommonNodeFormFIelds';
import { FormNodeFieldsEditor } from '../../FormNodeEditor/FormNodeFieldsEditor';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import ProcedureFormNode from 'GeminiViewerComponent/components/Procedure/components/ProcedureFormNode';
import ButtonAnswerGroupType from '../ButtonAnswerGroupType';
import SelectAnswerGroupType from '../SelectAnswerGroupType';
import ImageAnswerGroupType from '../ImageAnswerGroupType';
import HotspotAnswerGroupType from '../HotspotAnswerGroupType';

const ContentNodeFormFields = ({
    nodes,
    openEditFieldForm,
    setOpenEditFieldForm,
    selectedField,
    isValidVariableName,
    checkUniqueVariableName,
}) => {
    const theme = useSelector(selectActiveTheme);
    const nodeFormStyles = makeContentNodeFormStyles(theme);
    const { values, setFieldValue } = useFormikContext();

    return (
        <Box display="flex" mt="10px" width="auto" flex="1 1 0px">
            <Box
                component="fieldset"
                border="1px solid #cccccc"
                borderRadius="5px"
                padding="5px"
                width="100%"
                style={{
                    minInlineSize: 'inherit',
                    overflow: 'auto',
                }}
            >
                <Box
                    component="legend"
                    marginLeft="10px"
                    padding="3px"
                    fontSize="14px"
                    color="#6d7178"
                >
                    Form Fields
                </Box>
                <Box marginBottom="5px">
                    <Tooltip title="Add Form field">
                        <span>
                            <IconButton
                                className={nodeFormStyles.addButtonIcon}
                                onClick={() => setOpenEditFieldForm(true)}
                                size="small"
                            >
                                <MdAdd className="react-icon" />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
                <FormNodeFieldsEditor
                    node={values}
                    nodes={values.fields}
                    procedureNodes={nodes}
                    setFieldValue={setFieldValue}
                    openEditFieldForm={openEditFieldForm}
                    setOpenEditFieldForm={setOpenEditFieldForm}
                    isValidVariableName={isValidVariableName}
                    checkUniqueVariableName={checkUniqueVariableName}
                    selectedField={selectedField?.id}
                />
            </Box>
        </Box>
    );
};

const ContentNodeFormPreview = ({
    procedureRef,
    selectedField,
    setSelectedField,
}) => {
    const { values } = useFormikContext();
    const muiTheme = useTheme();
    const smWidth = muiTheme.breakpoints.values.sm;
    const { width: formWidth } = useResizeObserver({ ref: procedureRef });

    return (
        <Box width="auto" flex="1 1 0px" display="flex" mt="10px">
            <Box
                component="fieldset"
                border="1px solid #cccccc"
                borderRadius="5px"
                width="100%"
                ref={procedureRef}
                style={{
                    minInlineSize: 'inherit',
                    overflow: 'auto',
                }}
            >
                <Box
                    component="legend"
                    marginLeft="10px"
                    padding="3px"
                    fontSize="14px"
                    color="#6d7178"
                >
                    Form Preview
                </Box>
                <ProcedureFormNode
                    formFields={values.fields}
                    isSubmitAllow={false}
                    isMobileWidth={formWidth < smWidth}
                    selectedField={selectedField}
                    setSelectedField={setSelectedField}
                    contentService={contentService}
                />
            </Box>
        </Box>
    );
};

const ContentNodeFormFailureDetails = () => {
    return (
        <Box component="fieldset" border="1px solid #cccccc" borderRadius="5px">
            <Box
                component="legend"
                marginLeft="10px"
                padding="3px"
                fontSize="14px"
                color="#6d7178"
            >
                Procedure Failure Target Details
            </Box>
            <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                gap="5px"
                width="100%"
            >
                <FormControl sx={{ padding: '0 8px' }} fullWidth>
                    <FormTextField
                        label="Title"
                        name="target_title"
                        placeholder="Target Title"
                    />
                </FormControl>
                <FormControl sx={{ padding: '0 8px' }} fullWidth>
                    <FormTextField
                        label="Details"
                        name="target_details"
                        placeholder="Target Details"
                    />
                </FormControl>
                <FormControl sx={{ padding: '0 8px' }} fullWidth>
                    <FormTextField
                        label="URL"
                        name="target_url"
                        placeholder="Target URL"
                    />
                </FormControl>
            </Box>
        </Box>
    );
};

const ContentNodeFormSettings = ({ selectCompletionNode, completionNode }) => {
    const theme = useSelector(selectActiveTheme);
    const commonFormStyles = makeFormStyles(theme);
    const { values, setFieldValue } = useFormikContext();

    return (
        <Box component="fieldset" border="1px solid #cccccc" borderRadius="5px">
            <Box
                component="legend"
                marginLeft="10px"
                padding="3px"
                fontSize="14px"
                color="#6d7178"
            >
                Settings
            </Box>
            <FormControl sx={{ padding: '0 8px' }} fullWidth>
                <FormTextField
                    component={CheckboxWithLabel}
                    Label={{
                        label: 'Image attachments',
                    }}
                    label="Image attachments"
                    type="checkbox"
                    checked={Boolean(values.image_attachments_allow)}
                    onChange={(evt) =>
                        setFieldValue(
                            'image_attachments_allow',
                            evt.target.checked
                        )
                    }
                />
            </FormControl>
            <FormControl sx={{ padding: '0 8px' }} fullWidth>
                <FormTextField
                    component={CheckboxWithLabel}
                    Label={{
                        label: 'Completion (submit button)',
                    }}
                    label="Completion (submit button)"
                    type="checkbox"
                    checked={Boolean(values.completion)}
                    onChange={(evt) => {
                        selectCompletionNode(evt.target.checked, setFieldValue);
                    }}
                />
            </FormControl>
            <FormControl sx={{ padding: '0 8px' }} fullWidth>
                <ExcludeFromNavigationField />
            </FormControl>
            {completionNode && (
                <Box className={commonFormStyles.inputErr}>
                    Node {completionNode?.id} - {completionNode?.title} is
                    already completion node.
                </Box>
            )}
        </Box>
    );
};

const ContentNodeFormPreset = () => {
    const { values, setFieldValue } = useFormikContext();

    return (
        <Box component="fieldset" border="1px solid #cccccc" borderRadius="5px">
            <Box
                component="legend"
                marginLeft="10px"
                padding="3px"
                fontSize="14px"
                color="#6d7178"
            >
                Presets
            </Box>
            <Box display="flex" alignItems="center">
                <Checkbox
                    color="primary"
                    checked={values.next_button}
                    onChange={(evt) =>
                        setFieldValue('next_button', evt.target.checked)
                    }
                />
                Next Button &nbsp;&nbsp;&nbsp;
                <Checkbox
                    color="primary"
                    checked={values.back_button}
                    onChange={(evt) =>
                        setFieldValue('back_button', evt.target.checked)
                    }
                />
                Back Button
            </Box>
        </Box>
    );
};

const ContentNodeFormAnswerOptions = () => {
    const { values, setFieldValue } = useFormikContext();

    return (
        <>
            <Box
                component="legend"
                padding="3px"
                fontSize="14px"
                color="#6d7178"
            >
                Answers
            </Box>
            <label>Answer Group Type</label>
            <RadioGroup
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
                name="answer_group"
                value={values?.answer_group}
                onChange={(e) => {
                    setFieldValue('answer_group', e.target.value);
                    setFieldValue(
                        'multiple_answers_allow',
                        e.target.value === 'button'
                            ? false
                            : Boolean(values?.multiple_answers_allow)
                    );
                }}
            >
                <FormControlLabel
                    value="button"
                    control={<Radio color="primary" />}
                    label="Button"
                />
                <FormControlLabel
                    value="image"
                    control={<Radio color="primary" />}
                    label="Image"
                />
                <FormControlLabel
                    value="select"
                    control={<Radio color="primary" />}
                    label="Select"
                />
                <FormControlLabel
                    value="hotspot"
                    control={<Radio color="primary" />}
                    label="Hotspot"
                />
            </RadioGroup>
        </>
    );
};

const ContentNodeFormAnswerOptionFields = ({ node, nodes }) => {
    const { values, setFieldValue } = useFormikContext();
    const theme = useSelector(selectActiveTheme);
    const nodeFormStyles = makeContentNodeFormStyles(theme);

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result, data, fieldName, setFieldValue) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const orderedNodes = reorder(
            data,
            result.source.index,
            result.destination.index
        );

        setFieldValue(fieldName, orderedNodes);
    };

    const handleRemove = (id, values, fieldName, setFieldValue) => {
        let dataValues = [...values];
        dataValues.splice(id, 1);
        setFieldValue(fieldName, dataValues);
    };

    const handleRemoveOptionsSelected = (
        opt_txt,
        values,
        fieldName,
        setFieldValue
    ) => {
        let dataValues = [...values];
        let filteredData = dataValues.map((data) => {
            return {
                ...data,
                options_required: data.options_required.filter(
                    (opt) => opt.option_text !== opt_txt
                ),
            };
        });
        setFieldValue(fieldName, filteredData);
    };

    return (
        <>
            {values?.answer_group !== 'button' && (
                <>
                    <label>Multiple answers allowed?</label>
                    <RadioGroup
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                        name="multiple_answers_allow"
                        value={
                            values?.multiple_answers_allow === true
                                ? 'yes'
                                : 'no'
                        }
                        onChange={(e) => {
                            setFieldValue(
                                'multiple_answers_allow',
                                e.target.value === 'yes' ? true : false
                            );
                        }}
                    >
                        <FormControlLabel
                            value="yes"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                        <FormControlLabel
                            value="no"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                    </RadioGroup>
                </>
            )}
            {values?.answer_group === 'hotspot' && (
                <>
                    <label>Show Hotspots?</label>
                    <RadioGroup
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                        name="show_hotspot"
                        value={values?.show_hotspot === false ? 'no' : 'yes'}
                        onChange={(e) => {
                            setFieldValue(
                                'show_hotspot',
                                e.target.value === 'no' ? false : true
                            );
                        }}
                    >
                        <FormControlLabel
                            value="yes"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                        <FormControlLabel
                            value="no"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                    </RadioGroup>
                </>
            )}
            {values?.answer_group === 'button' && (
                <ButtonAnswerGroupType
                    node={node}
                    nodes={nodes}
                    values={values}
                    setFieldValue={setFieldValue}
                    nodeFormStyles={nodeFormStyles}
                    onDragEnd={onDragEnd}
                    handleRemove={handleRemove}
                />
            )}
            {values?.answer_group === 'select' && (
                <SelectAnswerGroupType
                    node={node}
                    nodes={nodes}
                    values={values}
                    setFieldValue={setFieldValue}
                    nodeFormStyles={nodeFormStyles}
                    onDragEnd={onDragEnd}
                    handleRemove={handleRemove}
                    handleRemoveOptionsSelected={handleRemoveOptionsSelected}
                />
            )}
            {values?.answer_group === 'image' && (
                <ImageAnswerGroupType
                    node={node}
                    nodes={nodes}
                    values={values}
                    setFieldValue={setFieldValue}
                    nodeFormStyles={nodeFormStyles}
                    onDragEnd={onDragEnd}
                    handleRemove={handleRemove}
                    handleRemoveOptionsSelected={handleRemoveOptionsSelected}
                />
            )}
            {values?.answer_group === 'hotspot' && (
                <HotspotAnswerGroupType
                    node={node}
                    nodes={nodes}
                    values={values}
                    setFieldValue={setFieldValue}
                    nodeFormStyles={nodeFormStyles}
                    onDragEnd={onDragEnd}
                    handleRemove={handleRemove}
                    handleRemoveOptionsSelected={handleRemoveOptionsSelected}
                />
            )}
        </>
    );
};

export {
    ContentNodeFormFields,
    ContentNodeFormPreview,
    ContentNodeFormFailureDetails,
    ContentNodeFormSettings,
    ContentNodeFormPreset,
    ContentNodeFormAnswerOptions,
    ContentNodeFormAnswerOptionFields,
};
