// eslint-disable-next-line
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Tooltip, Typography } from '@mui/material';
import { IoMdCloseCircleOutline } from 'react-icons/io';

import { config } from 'gemini-config';
import { accountsSlice } from 'app/store';
const { selectActiveUser } = accountsSlice;
import { sidePanelHeaderStyles } from './styles';
import { clearItemLinks, setItemLinks } from 'shared/loadZoneSlice';
import { hotSpotHasTag } from 'GeminiViewerComponent/_helpers/hotspot_helpers';
import { getAllAssetLinks } from 'GeminiViewerComponent/_features/links/linksSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    getActiveAssetActiveZone,
    getAssetZoneMedia,
    selectActiveAsset,
} from 'GeminiViewerComponent/_features/asset/assetSlice';
import {
    clearContentPanelItem,
    selectContentPanelItem,
} from 'GeminiViewerComponent/_features/contentPanel/contentPanelItemSlice';
import {
    getPanelVis,
    getShowOnlyByFilter,
    getTagFilters,
    setPanelVis,
} from '../../_features/globals/visibilitySlice';
//import logo from '../../assets/logo.svg';

const SidePanelHeader = ({ zoneMediaConfigValue = false }) => {
    const dispatch = useDispatch();

    const theme = useSelector(selectActiveTheme);

    const classes = sidePanelHeaderStyles(theme);

    const panelItem = useSelector(selectContentPanelItem);
    const allAssetLinks = useSelector(getAllAssetLinks);
    const tagFilters = useSelector(getTagFilters);
    const activeZone = useSelector(getActiveAssetActiveZone);
    const activeAsset = useSelector(selectActiveAsset);
    const showOnlyByFilter = useSelector(getShowOnlyByFilter);
    const isOpen = useSelector(getPanelVis);
    const [allZoneMedia, setAllZoneMedia] = useState(false);
    const activeUser = useSelector(selectActiveUser);
    const configZoneMediaValue = useSelector(getAssetZoneMedia);
    const s3Prefix = activeUser?.s3_prefix;

    const handleClose = () => {
        dispatch(setPanelVis({ open: false }));
    };

    useEffect(() => {
        if (config.modeType === 'author') {
            setAllZoneMedia(zoneMediaConfigValue);
        } else {
            setAllZoneMedia(configZoneMediaValue);
        }
    }, [isOpen, activeZone, zoneMediaConfigValue, configZoneMediaValue]);

    useEffect(() => {
        toggleZoneMedia(false);
    }, [tagFilters]);

    const toggleZoneMedia = useCallback(
        async (zoneMedia = true) => {
            let allItemData = [];
            var visibleHotspots = [];
            dispatch(clearItemLinks());
            dispatch(clearContentPanelItem());
            if (allZoneMedia || !zoneMedia) {
                var itemTags = tagFilters?.item_tags.map((tag) => tag.tag_id);
                activeZone?.items?.forEach((hotspot) => {
                    let hasLocation = hotspot.yaw || hotspot.flat_x;
                    if (
                        hasLocation &&
                        hotSpotHasTag(
                            itemTags,
                            hotspot,
                            activeZone?.items,
                            showOnlyByFilter
                        )
                    ) {
                        visibleHotspots.push({
                            ...hotspot,
                            zone_id: activeZone?.zone_id,
                            zone_display_name: activeZone?.display_name,
                        });
                    }
                });
                visibleHotspots?.forEach(async (item) => {
                    if (Array.isArray(item?.links) && item?.links?.length > 0) {
                        item.links.forEach(async (link) => {
                            let linkData = {
                                ...link,
                                zone_id: item?.zone_id,
                                zone_display_name: item?.zone_display_name,
                            };
                            allItemData = [...allItemData, linkData];
                        });
                    }
                });
                await dispatch(
                    setItemLinks({ s3Prefix: s3Prefix, links: allItemData })
                );
            } else {
                if (config.modeType === 'viewer') {
                    activeAsset.zones.map((zone) => {
                        zone?.items?.forEach((item) => {
                            if (
                                !visibleHotspots.find(
                                    (hotspot) =>
                                        hotspot.item_id === item.item_id
                                )
                            ) {
                                visibleHotspots.push({
                                    ...item,
                                    zone_id: zone?.zone_id,
                                    zone_display_name: zone?.display_name,
                                });
                            }
                        });
                    });
                    visibleHotspots?.forEach(async (item) => {
                        if (
                            Array.isArray(item?.links) &&
                            item?.links?.length > 0
                        ) {
                            item.links.forEach(async (link) => {
                                let linkData = {
                                    ...link,
                                    zone_id: item?.zone_id,
                                    zone_display_name: item?.zone_display_name,
                                };
                                allItemData = [...allItemData, linkData];
                            });
                        }
                    });
                    await dispatch(
                        setItemLinks({ s3Prefix: s3Prefix, links: allItemData })
                    );
                } else {
                    await dispatch(setItemLinks({ links: allAssetLinks }));
                }
            }
            if (zoneMedia) {
                setAllZoneMedia(!allZoneMedia);
            } else {
                setAllZoneMedia(false);
            }
        },
        [tagFilters, allZoneMedia, activeZone, showOnlyByFilter, allAssetLinks]
    );

    return (
        <div className={classes.header}>
            <div className={classes.headerBranding}>
                {/* <img className={classes.headerLogo} src={logo} alt="" /> */}
                <IoMdCloseCircleOutline
                    className={classes.closeIcon}
                    onClick={handleClose}
                />
                {!Object.keys(panelItem ?? {}).length > 0 && (
                    <Button
                        onClick={toggleZoneMedia}
                        size="small"
                        className={clsx(classes.displayZoneMediaButton, {
                            [classes.allZoneMediaButton]: allZoneMedia === true,
                        })}
                        variant="contained"
                    >
                        All Zones
                    </Button>
                )}
            </div>
            <div className={classes.hotspotContainer}>
                {panelItem?.display_name && (
                    <Tooltip
                        className={classes.displayName}
                        title={panelItem.display_name}
                    >
                        <Typography
                            overflow="hidden"
                            textOverflow="ellipsis"
                            display="inline-block"
                            whiteSpace="nowrap"
                            width="100%"
                        >
                            {panelItem.display_name}
                        </Typography>
                    </Tooltip>
                )}
                {panelItem?.description && (
                    <Tooltip
                        className={classes.description}
                        title={panelItem.description}
                    >
                        <Typography
                            overflow="hidden"
                            textOverflow="ellipsis"
                            display="inline-block"
                            whiteSpace="nowrap"
                            width="100%"
                        >
                            {panelItem.description}
                        </Typography>
                    </Tooltip>
                )}
                {panelItem?.external_id && (
                    <Tooltip
                        className={classes.externalId}
                        title={panelItem.description}
                    >
                        <Typography
                            overflow="hidden"
                            textOverflow="ellipsis"
                            display="inline-block"
                            whiteSpace="nowrap"
                            width="100%"
                        >
                            External ID: {panelItem.external_id}
                        </Typography>
                    </Tooltip>
                )}
            </div>
        </div>
    );
};

export { SidePanelHeader };
