import { useSelector } from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { useEffect, useState } from 'react';
import { dialogStyles } from 'GeminiViewerComponent/components/Procedure/styles';
import ExistingImageDialog from './components/ExistingImageDialog';
import NewImageDialog from './components/NewImageDialog';
import { SelectedContentCard } from './components/SelectedContentCard';

const ReferenceImagesDialog = ({
    selectedImages,
    openReferenceImageDialog,
    setOpenReferenceImageDialog,
    setFieldValue,
}) => {
    const theme = useSelector(selectActiveTheme);
    const classes = dialogStyles(theme);
    const [newImageDialog, setNewImageDialog] = useState(false);
    const [existingImageDialog, setExistingImageDialog] = useState(false);
    const [referenceImages, setReferenceImages] = useState([]);

    useEffect(() => {
        setReferenceImages([...selectedImages]);
    }, []);

    const handleSubmit = async () => {
        setFieldValue('references', [...referenceImages]);
        setOpenReferenceImageDialog(false);
    };

    return (
        <>
            <Dialog
                fullWidth
                open={openReferenceImageDialog}
                onClose={() => setOpenReferenceImageDialog(false)}
                maxWidth={'md'}
            >
                <DialogTitle
                    display={'flex'}
                    gap="10px"
                    justifyContent={'center'}
                >
                    <Button
                        onClick={() => setNewImageDialog(true)}
                        variant="outlined"
                    >
                        Add New Images
                    </Button>
                    <Button
                        onClick={() => setExistingImageDialog(true)}
                        variant="outlined"
                    >
                        Select Existing Images
                    </Button>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        margin={'15px'}
                        display={'flex'}
                        flexDirection={'row'}
                        gap={'10px'}
                        flexWrap={'wrap'}
                        justifyContent={'center'}
                    >
                        {referenceImages?.length > 0 &&
                            referenceImages?.map((content, idx) => (
                                <SelectedContentCard
                                    key={`attachment-card-${idx}`}
                                    content={content}
                                    index={idx}
                                    selectedImages={referenceImages}
                                    setSelectedImages={setReferenceImages}
                                />
                            ))}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        className={classes.cancel}
                        onClick={() => setOpenReferenceImageDialog(false)}
                    >
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} className={classes.submit}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            {newImageDialog && (
                <NewImageDialog
                    openDialog={newImageDialog}
                    setOpenDialog={setNewImageDialog}
                    referenceImages={referenceImages}
                    setReferenceImages={setReferenceImages}
                />
            )}
            {existingImageDialog && (
                <ExistingImageDialog
                    openDialog={existingImageDialog}
                    setOpenDialog={setExistingImageDialog}
                    referenceImages={referenceImages}
                    setReferenceImages={setReferenceImages}
                />
            )}
        </>
    );
};

export { ReferenceImagesDialog };
