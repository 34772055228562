import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useStyles } from './styles';
import { AutoSizer } from 'react-virtualized';
import { Box } from '@mui/material';
import {
    labelHeader,
    labelCell,
    assetExportDownloadLink,
    checkCell,
    progressStatusCell,
    VirtualTable,
} from 'components';
import { tableStyles } from 'components/_Tables/TableCells/styles';
import SearchWithFilters from 'components/SearchWithFilters';
import { EnhancedTableToolbar } from 'GeminiViewerComponent/components/EnhancedTableToolbar';
import { debounce } from 'GeminiViewerComponent/_helpers/lodashUtils';

function AssetExportTable({
    assetExports,
    pageInfo,
    assetExportPayload,
    setAssetExportPayload,
    sortDirection,
    sortBy,
    handleSort,
}) {
    const classes = useStyles();
    const tableClasses = tableStyles();
    const [selected, setSelected] = useState([]);
    const [selectedAll, setSelectedAll] = React.useState(false);

    const loadedRowsMap = useSelector(
        (state) => state.assetExport.loadedRowsMap
    );

    const headerHeight = 40;
    const rowHeight = 70;
    const isSelected = (id) => {
        return Boolean(
            assetExports?.find((exp) => exp.player_export_job_id === id)
                ?.is_self_contained
        );
    };

    const prevSelectedAll = usePrevious(selectedAll);
    if (prevSelectedAll && selectedAll) {
        if (selected.length !== assetExports.length) {
            setSelected(assetExports.map((row) => row.tag_id));
            setSelectedAll(true);
        }
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const columns = [
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 120,
            minWidth: 120,
            label: 'Asset Name',
            dataKey: 'asset_name',
            padding: 'normal',
            component: labelCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 100,
            minWidth: 100,
            label: 'Viewing',
            dataKey: 'is_scorm',
            padding: 'normal',
            component: labelCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 120,
            minWidth: 120,
            label: 'Self-Contained',
            dataKey: 'is_self_contained',
            padding: 'normal',
            component: checkCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            cellLevel: 'player_export_job',
            handleClick: () => {},
            isSelected: isSelected,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 120,
            minWidth: 120,
            label: 'Export Date',
            dataKey: 'created_date',
            padding: 'normal',
            component: labelCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 120,
            minWidth: 120,
            label: 'Status',
            dataKey: 'status_name',
            padding: 'normal',
            component: progressStatusCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 200,
            minWidth: 200,
            label: 'Completed File',
            dataKey: 'file_name',
            padding: 'normal',
            component: assetExportDownloadLink,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
    ];

    // eslint-disable-next-line no-unused-vars
    function loadMoreRows({ startIndex, stopIndex }) {}

    const debouncedSearch = useCallback(
        debounce((searchString) => {
            setAssetExportPayload({
                ...assetExportPayload,
                searchString: searchString,
            });
        }, 1000),
        [assetExportPayload]
    );

    return (
        <div className={classes.root}>
            {selected.length > 0 && (
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    toolTipTitle={'Delete'}
                    totalCount={pageInfo.TotalCount}
                ></EnhancedTableToolbar>
            )}
            <div style={{ height: 'calc(100vh - 295px)', minWidth: '500px' }}>
                <Box display="flex" alignItems="center" justifyContent="end">
                    <SearchWithFilters
                        filter={false}
                        onChange={(evt) => debouncedSearch(evt.target.value)}
                    />
                </Box>
                <AutoSizer>
                    {({ width, height }) => (
                        <VirtualTable
                            initialRows={assetExports}
                            allRows={assetExports}
                            totalRowCount={pageInfo.TotalCount}
                            loadedRowsMap={loadedRowsMap}
                            loadMoreRows={loadMoreRows}
                            columnDefinitions={columns}
                            cellClassName={tableClasses.flexContainer}
                            className={tableClasses.table}
                            rowClassName={tableClasses.flexContainer}
                            headerHeight={headerHeight}
                            width={width}
                            height={height}
                            rowHeight={rowHeight}
                        />
                    )}
                </AutoSizer>
            </div>
        </div>
    );
}

export { AssetExportTable };
