import React, { useEffect, useState, useRef } from 'react';
import { FiGrid } from 'react-icons/fi';
import { useSelector, useDispatch } from 'react-redux';

import GridView from './components/GridView';
import { ReactComponent as ProcessIcon } from 'assets/process.svg';

import useStyles from './styles';
import { PageHeader } from 'components';
import { accountsSlice } from 'app/store';
import { DataNotFound } from 'components/DataNotFound';
import AssetsActionBar from './components/AssetsActionBar';
const { selectUserSetting, selectActiveUser } = accountsSlice;
import { getAssetTypes } from '_features/assets/assetManagerSlice';
import { CardContainer } from './components/CardView/CardContainer';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    fetchAllCategories,
    getCategoryStatus,
} from '_features/category/categorySlice';
import {
    selectAssetPage,
    fetchAssetsPage,
    selectAssetPageInfo,
    selectAssetsLoadingStatus,
    setAssetsStatus,
    resetAssetsTableState,
} from '_features/assets/assetsTableSlice';

function Assets({ assetType }) {
    //#region Constants
    const assetTypeId = assetType === 'procedures' ? 3 : '1|2';
    const INITIAL_PAYLOAD = {
        page: 0,
        pageSize: 14,
        sort: `-is_pinned,display_name`,
        searchByAssetType: assetTypeId,
    };
    //#endregion Constants

    //#region Hooks
    const dispatch = useDispatch();
    //#endregion Hooks

    //#region State
    const [sortBy, setSortBy] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [isLoadingAssets, setLoadingAssets] = useState(false);
    const [assetPayload, setAssetPayload] = useState(INITIAL_PAYLOAD);
    //#endregion State

    //#region Selectors
    const theme = useSelector(selectActiveTheme);
    const classes = useStyles(theme);

    const assetViewMode = useSelector((state) =>
        selectUserSetting(
            state,
            `${
                assetType === 'assets'
                    ? 'asset_view_mode'
                    : 'procedure_view_mode'
            }`
        )
    );
    const categoryView = useSelector((state) =>
        selectUserSetting(
            state,
            `${
                assetType === 'assets'
                    ? 'asset_category_view'
                    : 'procedure_category_view'
            }`
        )
    );
    const assets = useSelector(selectAssetPage);
    const pageInfo = useSelector(selectAssetPageInfo);
    const assetsStatus = useSelector(selectAssetsLoadingStatus);
    const activeUser = useSelector(selectActiveUser);
    const categoryStatus = useSelector(getCategoryStatus);
    const readOnlyMode = activeUser?.role === 'User' ? true : false;
    //#endregion Selectors

    //#region Refs
    const isComponentLoaded = useRef(true);
    //#endregion Refs

    //#region Effects
    useEffect(() => {
        dispatch(setAssetsStatus(LoadingStatus.Idle));
    }, []);

    useEffect(() => {
        const getAssets = async () => {
            if (assetsStatus === LoadingStatus.Idle) {
                await dispatch(activateLoading());
                setLoadingAssets(true);
                await dispatch(fetchAssetsPage(assetPayload));
                await dispatch(getAssetTypes());
                await dispatch(deactivateLoading());
            }
            if (
                assetsStatus === LoadingStatus.Loaded ||
                assetsStatus === LoadingStatus.Failed
            ) {
                setLoadingAssets(false);
            }
        };
        getAssets();
        const fetchCategories = async () => {
            await dispatch(activateLoading());
            await dispatch(fetchAllCategories({ page: 1, pageSize: 100 }));
            await dispatch(deactivateLoading());
        };
        if (categoryStatus === LoadingStatus.Idle) {
            fetchCategories();
        }
    }, [assetsStatus, categoryStatus]);

    // it's called only when assetPayload changes not on component load.
    useEffect(() => {
        const getAssetByFilter = async () => {
            await dispatch(activateLoading());
            if (isComponentLoaded.current) {
                isComponentLoaded.current = false;
                return;
            }
            await dispatch(fetchAssetsPage({ ...assetPayload }));
            await dispatch(deactivateLoading());
        };
        getAssetByFilter();
    }, [assetPayload]);

    useEffect(() => {
        return () => {
            if (isComponentLoaded.current === false) {
                setInputValue('');
                setSortBy('');
                setSortDirection('');
                setAssetPayload({
                    ...INITIAL_PAYLOAD,
                    searchByAssetType: assetType === 'procedures' ? '1|2' : '3', //Add another IDs if generated other than this
                });
                dispatch(resetAssetsTableState());
            }
        };
    }, [assetType]);
    //#endregion Effects

    //#region Methods
    const setSorting = (_sortBy, _sortDirection) => {
        setSortBy(_sortBy);
        setSortDirection(_sortDirection);
        if (_sortBy !== '') {
            if (_sortBy === 'last_published_date') {
                setAssetPayload({
                    ...assetPayload,
                    sort: `-is_pinned,${
                        _sortDirection === 'ASC' ? '' : '-'
                    }${_sortBy}`,
                });
            } else {
                setAssetPayload({
                    ...assetPayload,
                    sort: `-is_pinned,${
                        _sortDirection === 'DESC' ? '-' : ''
                    }${_sortBy}`,
                });
            }
        }
    };
    //#endregion Methods

    //#region Render time calcs
    //#endregion Render time calcs

    //#region Render
    return (
        <div
            style={{ height: '100vh', width: 'calc(100% - 165px)' }}
            className={classes.content}
        >
            <PageHeader
                header={assetType === 'procedures' ? 'Procedures' : 'Assets'}
                icon={assetType === 'procedures' ? <ProcessIcon /> : <FiGrid />}
            />
            <AssetsActionBar
                assetType={assetType}
                assetPayload={assetPayload}
                setAssetPayload={setAssetPayload}
                setInputValue={setInputValue}
                setSorting={setSorting}
                sortBy={sortBy}
                setSortBy={setSortBy}
                sortDirection={sortDirection}
                setSortDirection={setSortDirection}
            />

            {assets.length === 0 && assetsStatus === LoadingStatus.Loaded ? (
                <DataNotFound title="Asset" inputLength={inputValue.length} />
            ) : assetViewMode === 'list' ? (
                <GridView
                    assets={assets}
                    pageInfo={pageInfo}
                    searchString={inputValue}
                    sortDirection={sortDirection}
                    sortBy={sortBy}
                    loadingStatus={isLoadingAssets ? '' : 'No Assets'}
                    handleSort={(_sortBy, _sortDirection) =>
                        setSorting(_sortBy, _sortDirection)
                    }
                    readOnly={readOnlyMode}
                    assetType={assetType}
                    assetPayload={assetPayload}
                    categoryView={categoryView}
                />
            ) : (
                <CardContainer
                    assets={assets}
                    pageInfo={pageInfo}
                    searchString={inputValue}
                    isLoadingAssets={isLoadingAssets}
                    readOnly={readOnlyMode}
                    assetType={assetType}
                    assetPayload={assetPayload}
                    setAssetPayload={setAssetPayload}
                    categoryView={categoryView}
                />
            )}
        </div>
    );
    //#endregion Render
}

export { Assets };
