import React, { useState } from 'react';
import {
    MdMoreVert,
    MdDelete,
    MdDescription,
    MdExitToApp,
    MdRotateRight,
} from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { panoDraggable } from './PanoDraggable';
import { rotateable } from './Rotateable';
import { getConfig } from 'GeminiViewerComponent/_features/config/configSlice';
import { ItemHotspotPopup } from 'components/_Misc/PopupActions/components/ItemHotspotPopup';
import { clamp } from 'GeminiViewerComponent/_helpers/lodashUtils';

function PanoItemHotspot({
    hotspot,
    icon,
    onUpdatedHotspot,
    onDeletedHotspot,
    onEditClicked,
    onNavClicked,
    relativeMoves,
    readOnly,
    enableDragging,
    setDraggableFlag = () => {},
}) {
    const config = useSelector(getConfig);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event?.target?.parentElement);
        event.preventDefault();
    };

    function handleRotateClick(hotspot) {
        let rotation = hotspot.rotation + 45;
        if (rotation > 360) {
            rotation = 45;
        }

        if (onUpdatedHotspot) {
            const updatedHotspot = {
                item_id: hotspot.item_id,
                rotation: rotation,
            };
            onUpdatedHotspot(updatedHotspot);
        }
    }
    function handleClearClick() {
        let updatedHotspot = { ...hotspot, clear_position_if_missing: true };
        if (hotspot.flat_x) delete updatedHotspot.flat_x;
        if (hotspot.flat_y) delete updatedHotspot.flat_y;
        if (hotspot.label_x) delete updatedHotspot.label_x;
        if (hotspot.label_y) delete updatedHotspot.label_y;
        if (hotspot.yaw) delete updatedHotspot.yaw;
        if (hotspot.pitch) delete updatedHotspot.pitch;
        onUpdatedHotspot(updatedHotspot);
    }
    function handleScaleClick(hotspot, amount) {
        let existingScale = hotspot.scale ? hotspot.scale : 1.0;

        if (existingScale >= 5.0 || existingScale <= 0.1) {
            return;
        }

        let scale = existingScale + amount;
        if (scale > 5.0) {
            scale = 5.0;
        }
        if (scale < 0.3) {
            scale = 0.3;
        }

        if (onUpdatedHotspot) {
            const updatedHotspot = { item_id: hotspot.item_id, scale: scale };
            onUpdatedHotspot(updatedHotspot);
        }
    }

    function handleDeleteClick(hotspot) {
        if (onDeletedHotspot) {
            onDeletedHotspot(hotspot);
        }
    }

    function handleEditClick(hotspot) {
        if (onEditClicked) {
            onEditClicked(hotspot);
        }
        /* const response = await itemService.getById(
            hotspot?.zone_id,
            hotspot?.item_id
        );
        if (editingLink === response.item_id) {
            setEditingLink(undefined);
        } else {
            setEditingLink(response);
        } */
    }

    function handleNavClick(hotspot) {
        if (onNavClicked) {
            onNavClicked(hotspot.item_id);
        }
    }

    function onMovedHotspot(hotspot, location, screenPosition, dragElement) {
        let updatedHotspot = { item_id: hotspot.item_id };
        if (location.yaw) updatedHotspot.yaw = location.yaw;
        if (location.pitch) updatedHotspot.pitch = location.pitch;
        if (location.x) updatedHotspot.flat_x = clamp(location.x, 0, 1);
        if (location.y) updatedHotspot.flat_y = clamp(location.y, 0, 1);
        if (hotspot.label_x) updatedHotspot.label_x = hotspot.label_x;
        if (hotspot.label_y) updatedHotspot.label_y = hotspot.label_y;

        onUpdatedHotspot(updatedHotspot, dragElement);
    }

    function onMovedLabel(hotspot, location, screenPosition, dragElement) {
        let updatedHotspot = { item_id: hotspot.item_id };
        if (hotspot.yaw) updatedHotspot.yaw = hotspot.yaw;
        if (hotspot.pitch) updatedHotspot.pitch = hotspot.pitch;
        if (location.x) updatedHotspot.label_x = parseInt(location.x);
        if (location.y) updatedHotspot.label_y = parseInt(location.y);

        onUpdatedHotspot(updatedHotspot, dragElement);
    }

    function onRotatedHotspot(hotspot, rotation) {
        const updatedHotspot = { item_id: hotspot.item_id, rotation: rotation };
        if (onUpdatedHotspot) {
            onUpdatedHotspot(updatedHotspot);
        }
    }

    const rotation = hotspot.rotation ? hotspot.rotation : 0.0;
    const scale = hotspot.scale ? hotspot.scale : 1.0;
    let dragStyle = {
        pointerEvents: `${
            enableDragging === true ? 'all !important' : 'none !important'
        }`,
        cursor: `${
            enableDragging === true ? 'move !important' : 'auto !important'
        }`,
        width: 'fit-content',
    };

    let style = {
        transform: `rotate(${rotation}deg) scale(${scale},${scale})`,
    };

    const handleResetPosition = () => {
        let updatedHotspot = { item_id: hotspot.item_id };
        updatedHotspot.label_x = '75';
        updatedHotspot.label_y = '20';
        onUpdatedHotspot(updatedHotspot);
    };

    return (
        <div>
            <img
                src={icon?.image_url}
                className="info-hotspot-icon"
                alt="Info Hotspot"
                style={style}
                onPointerDown={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    if (readOnly !== true && enableDragging === true) {
                        setDraggableFlag(false);
                        panoDraggable.handleMouseDown(
                            event,
                            event.currentTarget.parentNode.parentNode,
                            hotspot,
                            onMovedHotspot,
                            relativeMoves
                        );
                    }
                }}
                onPointerUp={() => setDraggableFlag(true)}
            ></img>
            <Box
                className="hotspot-tooltip info-hotspot-tooltip"
                id={`info-${hotspot.item_id}`}
                left={`${hotspot.label_x && hotspot.label_x + 'px !important'}`}
                top={`${hotspot.label_y && hotspot.label_y + 'px !important'}`}
                sx={dragStyle}
                onPointerDown={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    if (readOnly !== true && enableDragging === true) {
                        setDraggableFlag(false);
                        panoDraggable.handleLabelPosition(
                            event,
                            event.currentTarget,
                            hotspot,
                            onMovedLabel,
                            relativeMoves
                        );
                    }
                }}
                onPointerUp={() => {
                    setDraggableFlag(true);
                }}
            >
                {hotspot.display_name}
            </Box>
            <MdExitToApp
                className="react-icon info-hotspot-control info-hotspot-nav-icon"
                onClick={() => handleNavClick(hotspot)}
            />
            <MdDescription
                className="react-icon info-hotspot-control info-hotspot-edit-icon"
                style={{ left: '45px', top: '-11px' }}
                onClick={() => handleEditClick(hotspot)}
            />
            {readOnly !== true && (
                <>
                    <MdMoreVert
                        className="react-icon info-hotspot-control info-hotspot-clear-icon"
                        style={{ left: '58px', top: '24px' }}
                        onClick={handleClick}
                    />
                    <MdDelete
                        className="react-icon info-hotspot-control info-hotspot-delete-icon"
                        style={{ left: '45px', top: '57px' }}
                        onClick={() => handleDeleteClick(hotspot)}
                    />
                    <MdRotateRight
                        className="react-icon info-hotspot-control info-hotspot-rotate-icon"
                        onClick={() => handleRotateClick(hotspot)}
                        onPointerDown={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            rotateable.handleMouseDown(
                                event,
                                hotspot,
                                onRotatedHotspot
                            );
                        }}
                    />
                    <img
                        src={config.paths.LINK_SCALEUP_ICON_URL}
                        alt="Scale Up"
                        style={{ left: '10px', top: '61px' }}
                        className="info-hotspot-control info-hotspot-scaleup-icon"
                        onClick={() => handleScaleClick(hotspot, 0.1)}
                    ></img>
                    <img
                        src={config.paths.LINK_SCALEDOWN_ICON_URL}
                        alt="Scale Down"
                        style={{ left: '-13px', top: '39px' }}
                        className="info-hotspot-control info-hotspot-scaledown-icon"
                        onClick={() => handleScaleClick(hotspot, -0.1)}
                    ></img>
                </>
            )}
            <ItemHotspotPopup
                object={{
                    Unposition: handleClearClick,
                    ResetPosition: handleResetPosition,
                }}
                level={'itemHotspot'}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
            />
        </div>
    );
}

export { PanoItemHotspot };
