import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    overflowX1080: {
        // eslint-disable-next-line
        overflowX: 'auto !important',
    },
    overflowX640: {
        // eslint-disable-next-line
        ['@media (max-width:640px)']: {
            overflowX: 'auto',
        },
    },
    flexGrid: {
        display: 'flex',
        // justifyContent: 'space-between',
    },
    paper: {
        width: '100%',
        boxShadow: 'unset',
        marginTop: '10px',
        '& > div': {
            width: '100% !important',
        },
    },
    assetZones: {
        width: '35%',
        maxWidth: '35%',
        // minWidth: '360px',
        // maxWidth: '460px',
        '& .ReactVirtualized__Table__Grid': {
            backgroundColor: '#fafafa',
        },
    },
    zoneItems: {
        width: '65%',
        maxWidth: '65%',
        marginTop: '80px',
    },
    popupActionContainer: {
        display: 'flex',
        alignItems: 'center',
        color: '#7E8C8D',
        padding: '10px',
        fontSize: '14px',
        '&:hover': {
            cursor: 'pointer',
            transform: 'scale(1.05)',
        },
    },
    popupActionIcon: {
        display: 'flex',
        justifyContent: 'center',
        '& svg': {
            height: '20px',
            width: '20px',
        },
    },
    itemsAutoSizerWrapper: {
        height: 'calc(100vh - 300px) !important',
        minWidth: '520px !important',
    },
}));
