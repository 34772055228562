import React, { useCallback, useEffect, useState } from 'react';
import { Box, FormControl } from '@mui/material';
import { CheckboxWithLabel } from 'formik-mui';

import { FormTextField } from 'components/TextField';
import FieldValidationError from './FieldValidationError';
import { ImageAttachments } from '../../ImageAttachments';

const CheckboxField = ({
    data,
    fieldValue,
    setFieldValue,
    errors,
    setErrors,
    validationError,
    handleBlur,
    inGroup = false,
    isReadOnly = false,
    isSubmitAllow = true,
    handleClick = () => {},
}) => {
    let fieldErrors = errors;
    const [checkedValue, setCheckedValue] = useState(false);
    let variable = data?.variable;
    if (data?.duplicateCount) {
        let lastIndex = data?.variable.lastIndexOf(`-${data?.duplicateCount}`);
        variable = data?.variable.slice(0, lastIndex);
    }

    useEffect(() => {
        setCheckedValue(
            data?.duplicateCount
                ? fieldValue[variable]?.[data?.duplicateCount]
                : fieldValue[variable]?.[0]
        );
    }, [fieldValue]);

    const checkboxData = useCallback(
        <FormControl
            style={{
                padding: '5px',
                width: data?.field_width ? data.field_width + '%' : '100%',
            }}
        >
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <FormTextField
                    component={CheckboxWithLabel}
                    id={data?.variable}
                    Label={{
                        label: `${
                            data?.hide_label !== true ? data?.label : ''
                        }`,
                    }}
                    label={data?.hide_label !== true ? data?.label : ''}
                    type="checkbox"
                    name={
                        data?.duplicateCount
                            ? `${variable}.${data?.duplicateCount}`
                            : `${variable}.[${0}]`
                    }
                    disabled={isReadOnly}
                    helperText={errors?.[variable]}
                    checked={checkedValue || false}
                    style={{
                        [`&, &.Mui-disabled`]: {
                            color: 'black',
                        },
                    }}
                    onBlur={() => isSubmitAllow && handleBlur(fieldValue)}
                    onChange={(evt) => {
                        if (isSubmitAllow === true) {
                            // Store default row data at 0 index
                            if (data?.duplicateCount) {
                                if (
                                    data?.required === true &&
                                    evt.target.checked !== true
                                ) {
                                    fieldErrors[
                                        `${variable}.${[data?.duplicateCount]}`
                                    ] = 'Required';
                                } else {
                                    delete fieldErrors?.[
                                        `${variable}.${[data?.duplicateCount]}`
                                    ];
                                }
                                setFieldValue(
                                    `${variable}.${[data?.duplicateCount]}`,
                                    evt.target.checked
                                );
                            } else {
                                if (
                                    data?.required === true &&
                                    evt.target.checked !== true
                                ) {
                                    fieldErrors[`${variable}.${[0]}`] =
                                        'Required';
                                } else {
                                    delete fieldErrors?.[`${variable}.${[0]}`];
                                }
                                setFieldValue(
                                    `${variable}.${[0]}`,
                                    evt.target.checked
                                );
                            }
                            setErrors({ ...fieldErrors });
                        }
                    }}
                    onClick={(e) => {
                        if (!isSubmitAllow) {
                            e.stopPropagation();
                            handleClick(e, data);
                        }
                    }}
                />
                {data?.image_attachments_allow === true && (
                    <ImageAttachments
                        data={data}
                        var_name={data?.variable}
                        level="field"
                        isReadOnly={!isSubmitAllow}
                        handleClick={handleClick}
                    />
                )}
            </Box>
            {validationError && (
                <FieldValidationError validationError={validationError} />
            )}
        </FormControl>,
        [checkedValue, validationError]
    );

    return inGroup ? (
        checkboxData
    ) : (
        <div style={{ width: '100%' }}>{checkboxData}</div>
    );
};

export default CheckboxField;
