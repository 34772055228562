import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';

import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { makeFormStyles } from 'forms/styles';

const InspectionDataConfirmDialog = ({
    openDialog,
    setOpenDialog,
    onSubmit,
}) => {
    const theme = useSelector(selectActiveTheme);

    const formClasses = makeFormStyles(theme);
    const closeDialog = () => {
        setOpenDialog(false);
    };
    return (
        <Dialog open={openDialog} onClose={closeDialog}>
            <DialogTitle>{'Update Inspection Field'}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography variant="body2">
                        This change will be applied to all inspection fields in
                        all procedures that use this action list.
                    </Typography>
                    <Typography variant="body1">
                        Are you sure you want to continue?
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button className={formClasses.cancel} onClick={closeDialog}>
                    Cancel
                </Button>
                <Button
                    autoFocus
                    className={formClasses.submit}
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export { InspectionDataConfirmDialog };
