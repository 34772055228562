import React, { useCallback, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { makeFormStyles } from 'forms/styles';
import { CheckboxWithLabel, Select } from 'formik-mui';
import {
    Checkbox,
    FormControl,
    Autocomplete,
    MenuItem,
    TextField as MUITextField,
    Chip,
} from '@mui/material';
import { MdCheckBox, MdCheckBoxOutlineBlank, MdClose } from 'react-icons/md';
import { useSelector } from 'react-redux';

import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { MultiSelectDoneButton } from 'GeminiViewerComponent/components/MultiSelectDoneButton';
import { ProcedureSplitViewTypes } from 'GeminiViewerComponent/_helpers';
import { panelStyles } from 'GeminiViewerComponent/components/styles';
import Dropzone from 'react-dropzone';
import { LinkTypes } from '_helpers';
import { ImageResizer } from 'forms/ZoneForm/ImageResizer';
import { blobToBase64, handleFileExtension } from 'hooks/useImageUploadHelper';
import { FormTextField } from 'components/TextField';

const makeProcedurePropsFormStyles = makeStyles(() => ({
    container: {
        padding: '20px',
        width: '100%',
        height: '100%',
    },
    form: {
        display: 'flex',
        width: '100%',
        height: 'auto',
        padding: '10px',
        border: '1px solid',
    },
    formBody: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        width: '100%',
    },
    submitButtonRow: {
        marginTop: 'auto',
        alignSelf: 'end',
        display: 'flex',
        gap: '5px',
    },
}));

const ProcedurePropsForm = ({ procedure, variables, onCancel, onSubmit }) => {
    const theme = useSelector(selectActiveTheme);

    const nodeProcedurePropsStyles = makeProcedurePropsFormStyles();
    const commonFormStyles = makeFormStyles(theme);
    const panelClasses = panelStyles(theme);

    var cleanedProcedure = { ...procedure };
    cleanedProcedure.show_steps = cleanedProcedure.show_steps ?? false;

    const [formValues, setFormValues] = useState(cleanedProcedure);
    const [headerVariables, setHeaderVariables] = useState(
        Array.isArray(cleanedProcedure?.header_variables) &&
            cleanedProcedure?.header_variables?.length > 0
            ? cleanedProcedure?.header_variables?.filter(
                  (variable) => variable?.name && variable
              )
            : []
    );
    useEffect(() => {
        setFormValues(cleanedProcedure);
    }, [procedure]);

    const compressImages = async ({
        file,
        mode = 'drop',
        setFieldValue = null,
        values = null,
    }) => {
        let updatedUrl = '';
        let previewFile = null;
        let imageResizer = new ImageResizer(file);
        let updateValues = values;
        let compressPercent = 0.8;
        previewFile = await imageResizer.run(200, 0, compressPercent);
        updatedUrl = await blobToBase64(previewFile);
        if (mode === 'drop' && setFieldValue) {
            setFieldValue('header_image', previewFile);
            setFieldValue('header_image_url', updatedUrl);
        }
        if (mode === 'submit' && values) {
            updateValues = {
                ...updateValues,
                header_image: previewFile,
                header_image_url: updatedUrl,
            };
        }
        previewFile.name = file.name;
        if (mode === 'drop' && setFieldValue) {
            handleFileExtension(file, setFieldValue);
        }
        if (mode === 'submit' && values) {
            return updateValues;
        }
        return null;
    };

    const onDrop = useCallback(async ({ file, setFieldValue }) => {
        // const reader = new FileReader();

        // reader.onabort = () => console.log('file reading was aborted');
        // reader.onerror = () => console.log('file reading has failed');
        // reader.onload = (e) => {
        //     setFieldValue('header_image', e.target.result);
        // };
        // reader.readAsDataURL(file);

        await compressImages({
            file: file,
            mode: 'drop',
            setFieldValue: setFieldValue,
        });
    }, []);

    const onDropError = useCallback(
        ({ err, setFieldError, setFieldTouched }) => {
            if (err?.[0]?.errors?.[0]?.message) {
                setFieldError('header_image_url', err[0].errors[0].message);
                setFieldTouched('header_image_url', true, false);
            }
        },
        []
    );

    return (
        <div className={nodeProcedurePropsStyles.container}>
            <div className={nodeProcedurePropsStyles.form}>
                <Formik
                    initialValues={formValues}
                    validate={() => {
                        const errors = {};
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                        onSubmit(values);
                    }}
                >
                    {({
                        setFieldValue,
                        values,
                        isSubmitting,
                        setFieldError,
                        setFieldTouched,
                    }) => (
                        <Form className={nodeProcedurePropsStyles.formBody}>
                            <FormTextField
                                label="Title"
                                type="title"
                                name="title"
                                placeholder="Title"
                            />
                            <FormTextField
                                component={CheckboxWithLabel}
                                Label={{ label: 'Display Steps' }}
                                label="Display Steps"
                                type="checkbox"
                                checked={!!values.show_steps}
                                onChange={(e) => {
                                    setFieldValue(
                                        'show_steps',
                                        e.target.checked
                                    );
                                }}
                            />
                            <FormTextField
                                component={CheckboxWithLabel}
                                Label={{ label: 'Disable navigation pane' }}
                                label="Disable navigation pane"
                                type="checkbox"
                                checked={!!values.disable_navigation_pane}
                                onChange={(e) => {
                                    setFieldValue(
                                        'disable_navigation_pane',
                                        e.target.checked
                                    );
                                }}
                            />
                            <FormTextField
                                component={CheckboxWithLabel}
                                Label={{ label: 'Hide close button' }}
                                label="Hide close button"
                                type="checkbox"
                                checked={!!values.hide_close_button}
                                onChange={(e) => {
                                    setFieldValue(
                                        'hide_close_button',
                                        e.target.checked
                                    );
                                }}
                            />
                            <FormTextField
                                component={CheckboxWithLabel}
                                Label={{ label: 'Image attachments' }}
                                label="Image attachments"
                                type="checkbox"
                                checked={!!values.image_attachments_allow}
                                onChange={(e) => {
                                    setFieldValue(
                                        'image_attachments_allow',
                                        e.target.checked
                                    );
                                }}
                            />
                            <FormTextField
                                component={CheckboxWithLabel}
                                Label={{ label: 'Save Sessions' }}
                                label="Save Sessions"
                                type="checkbox"
                                checked={!!values.save_sessions}
                                onChange={(e) => {
                                    setFieldValue(
                                        'save_sessions',
                                        e.target.checked
                                    );
                                }}
                            />
                            <FormControl
                                variant="outlined"
                                style={{ marginTop: '15px' }}
                            >
                                <Field
                                    labelId="select-start-node-id"
                                    component={Select}
                                    margin="dense"
                                    variant="outlined"
                                    name="start_node_id"
                                    label="Start Node"
                                >
                                    {Object.keys(procedure.nodes ?? {})
                                        .sort(
                                            (a, b) =>
                                                parseInt(
                                                    procedure.nodes[a].id
                                                ) -
                                                parseInt(procedure.nodes[b].id)
                                        )
                                        .map((idx) => (
                                            <MenuItem
                                                key={procedure.nodes[idx].id}
                                                value={procedure.nodes[idx].id}
                                            >
                                                {`${procedure.nodes[idx].id} - ${procedure.nodes[idx].title}`}
                                            </MenuItem>
                                        ))}
                                </Field>
                            </FormControl>
                            <ErrorMessage
                                name="start_node_id"
                                component="div"
                            />
                            <FormControl
                                variant="outlined"
                                style={{ marginTop: '15px' }}
                            >
                                <Autocomplete
                                    variant="outline"
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                    }
                                    id="header-vars"
                                    multiple
                                    limitTags={3}
                                    options={variables}
                                    value={headerVariables}
                                    onChange={(event, newInputValue) => {
                                        setHeaderVariables(newInputValue);
                                        setFieldValue(
                                            'header_variables',
                                            newInputValue
                                        );
                                    }}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option?.name}
                                    renderOption={(
                                        props,
                                        option,
                                        { selected }
                                    ) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={
                                                    <MdCheckBoxOutlineBlank className="react-icon" />
                                                }
                                                checkedIcon={
                                                    <MdCheckBox className="react-icon" />
                                                }
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option?.name}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <MUITextField
                                            {...params}
                                            variant="outlined"
                                            label="Variables to display in header"
                                        />
                                    )}
                                    PaperComponent={MultiSelectDoneButton}
                                />
                            </FormControl>

                            <FormControl
                                variant="outlined"
                                style={{ marginTop: '15px' }}
                            >
                                <Autocomplete
                                    variant="outline"
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                    }
                                    id="header-vars"
                                    limitTags={3}
                                    options={variables}
                                    value={values?.lrs_id_variable}
                                    onChange={(event, newInputValue) => {
                                        setFieldValue(
                                            'lrs_id_variable',
                                            newInputValue
                                        );
                                    }}
                                    getOptionLabel={(option) => option?.name}
                                    renderInput={(params) => (
                                        <MUITextField
                                            {...params}
                                            variant="outlined"
                                            label="Variable to use for LRS Id"
                                        />
                                    )}
                                />
                            </FormControl>

                            <FormControl
                                variant="outlined"
                                style={{ marginTop: '15px' }}
                            >
                                <Autocomplete
                                    variant="outline"
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                    }
                                    id="header-vars"
                                    limitTags={3}
                                    options={variables}
                                    value={values?.primary_tag_variable}
                                    onChange={(event, newInputValue) => {
                                        setFieldValue(
                                            'primary_tag_variable',
                                            newInputValue
                                        );
                                    }}
                                    getOptionLabel={(option) => option?.name}
                                    renderInput={(params) => (
                                        <MUITextField
                                            {...params}
                                            variant="outlined"
                                            label="Variable to use as Primary tag"
                                        />
                                    )}
                                />
                            </FormControl>

                            <FormControl
                                variant="outlined"
                                style={{ marginTop: '15px' }}
                            >
                                <Autocomplete
                                    variant="outline"
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                    }
                                    id="header-vars"
                                    limitTags={3}
                                    options={variables}
                                    value={values?.secondary_tag_variable}
                                    onChange={(event, newInputValue) => {
                                        setFieldValue(
                                            'secondary_tag_variable',
                                            newInputValue
                                        );
                                    }}
                                    getOptionLabel={(option) => option?.name}
                                    renderInput={(params) => (
                                        <MUITextField
                                            {...params}
                                            variant="outlined"
                                            label="Variable to use as Secondary tag"
                                        />
                                    )}
                                />
                            </FormControl>

                            <FormControl
                                variant="outlined"
                                style={{ marginTop: '15px' }}
                            >
                                <Field
                                    labelId="split-view-mode"
                                    component={Select}
                                    margin="dense"
                                    variant="outlined"
                                    name="split_view_mode"
                                    label="Split view mode"
                                >
                                    {Object.keys(ProcedureSplitViewTypes).map(
                                        (viewType, idx) => (
                                            <MenuItem
                                                key={`ProcedureSplitViewTypes${idx}`}
                                                value={
                                                    ProcedureSplitViewTypes[
                                                        viewType
                                                    ].id
                                                }
                                            >
                                                {
                                                    ProcedureSplitViewTypes[
                                                        viewType
                                                    ].label
                                                }
                                            </MenuItem>
                                        )
                                    )}
                                </Field>
                            </FormControl>

                            <Field
                                className={commonFormStyles.input}
                                name="header_image_url"
                                type="file"
                            >
                                {({
                                    field: { value },
                                    form: { setFieldValue },
                                }) => (
                                    <div
                                        className={
                                            panelClasses.dropzoneContainer
                                        }
                                    >
                                        <Dropzone
                                            accept={['image/*']}
                                            maxFiles={1}
                                            maxSize={
                                                LinkTypes.Image.fileSizeLimit
                                            }
                                            multiple={false}
                                            onDropAccepted={async (files) => {
                                                await onDrop({
                                                    file: files[0],
                                                    setFieldValue:
                                                        setFieldValue,
                                                    values: values,
                                                });
                                            }}
                                            onDropRejected={(err) =>
                                                onDropError({
                                                    err,
                                                    setFieldError:
                                                        setFieldError,
                                                    setFieldTouched:
                                                        setFieldTouched,
                                                })
                                            }
                                        >
                                            {({
                                                getRootProps,
                                                getInputProps,
                                            }) => (
                                                <div
                                                    {...getRootProps()}
                                                    className={
                                                        panelClasses.dropzoneArea
                                                    }
                                                    style={{
                                                        backgroundImage: `url(${values?.header_image_url})`,
                                                        backgroundRepeat:
                                                            'no-repeat',
                                                        backgroundSize:
                                                            'contain',
                                                        backgroundPosition:
                                                            'center center',
                                                    }}
                                                >
                                                    <input
                                                        {...getInputProps()}
                                                    />
                                                    <div
                                                        className={
                                                            panelClasses.dropzoneText
                                                        }
                                                    >
                                                        {!values?.header_image_url
                                                            ? 'Header Image'
                                                            : 'Drop Replacement Header Image Here'}
                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>
                                        <div
                                            className={
                                                panelClasses?.previewContainer
                                            }
                                        >
                                            {values?.header_image_url && (
                                                <Chip
                                                    className={
                                                        panelClasses.previewUploadFileName
                                                    }
                                                    color="secondary"
                                                    size="small"
                                                    deleteIcon={
                                                        <MdClose className="react-icon" />
                                                    }
                                                    onDelete={() => {
                                                        setFieldValue(
                                                            'header_image_url',
                                                            null
                                                        );
                                                    }}
                                                    label="Remove Image"
                                                />
                                            )}
                                        </div>
                                    </div>
                                )}
                            </Field>
                            <ErrorMessage
                                className={commonFormStyles.inputErr}
                                name="header_image_url"
                                component="div"
                            />
                            <div
                                className={
                                    nodeProcedurePropsStyles.submitButtonRow
                                }
                            >
                                <button
                                    className={commonFormStyles.cancel}
                                    type="button"
                                    disabled={isSubmitting}
                                    onClick={onCancel}
                                >
                                    Cancel
                                </button>
                                <button
                                    className={commonFormStyles.submit}
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Submit
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default ProcedurePropsForm;
