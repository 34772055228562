import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        position: 'relative',
    },
    newObject: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    toolsWrapper: {
        height: 'calc(100vh - 310px)',
        minWidth: '500px',
    },
}));
