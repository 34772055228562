import { LABEL_FIELD_TYPE } from 'components/ProcedureDesigner/components/FormNodeEditor/_helpers/constant';
import { config } from 'gemini-config';
import { getAccountService } from 'GeminiViewerComponent/_features/services/account.service';
import {
    createProgressParams,
    getNetworkService,
    FormDataUtils,
    prefixUrl,
    mapPrefixUrl,
    createGeneralProgressParams,
} from 'GeminiViewerComponent/_helpers';
import {
    setAssetTypes,
    setAllLinkTypes,
    fetchByKey,
} from 'GeminiViewerComponent/_helpers/cacheStore';
import { convertNodeTypes } from 'GeminiViewerComponent/_helpers/node_helpers';

const baseUrl = `${config.apiUrl}/asset`;
const accountService = getAccountService({ config: config });
const networkService = getNetworkService({
    config: config,
    checkAuth: accountService.checkAuth,
});

const mapAsset = (asset, s3Prefix) => {
    asset.image_url = prefixUrl(asset.image_url, s3Prefix);
    asset.orbit_images_url = prefixUrl(asset.orbit_images_url, s3Prefix);
    asset.last_draft_published_url = prefixUrl(
        asset.last_draft_published_url,
        s3Prefix
    );
    asset.last_release_published_url = prefixUrl(
        asset.last_release_published_url,
        s3Prefix
    );
    asset.last_published_url = prefixUrl(asset.last_published_url, s3Prefix);
    if (asset?.procedure && typeof asset?.procedure === 'string') {
        let procedure = JSON.parse(asset?.procedure);
        if (asset.header_image_url) {
            procedure.header_image_url = prefixUrl(
                asset.header_image_url,
                s3Prefix
            );
        }
        procedure = convertNodeTypes(procedure);
        asset.procedure = JSON.stringify(procedure);
    } else if (asset?.procedure && typeof asset?.procedure === 'object') {
        if (asset.header_image_url) {
            asset.procedure.header_image_url = prefixUrl(
                asset.header_image_url,
                s3Prefix
            );
        }
        asset.procedure = convertNodeTypes(asset.procedure);
    }
    if (asset?.procedure_json && typeof asset?.procedure_json === 'string') {
        let procedure_json = JSON.parse(asset?.procedure_json);
        procedure_json = convertNodeTypes(procedure_json);
        asset.procedure_json = JSON.stringify(procedure_json);
    }

    if (asset.compact_zones) {
        asset.compact_zones = asset.compact_zones.map((zone) => {
            zone.equirect_image_preview_url = prefixUrl(
                zone.equirect_image_preview_url,
                s3Prefix
            );
            zone.flat_image_preview_url = prefixUrl(
                zone.flat_image_preview_url,
                s3Prefix
            );
            if (Array.isArray(zone?.zone_tags) && zone.zone_tags.length > 0) {
                zone.zone_tags = mapPrefixUrl(
                    zone.zone_tags,
                    s3Prefix,
                    'image_url'
                );
            }
            if (Array.isArray(zone?.item_tags) && zone.item_tags.length > 0) {
                zone.item_tags = mapPrefixUrl(
                    zone.item_tags,
                    s3Prefix,
                    'image_url'
                );
            }
            return zone;
        });
    }
    return asset;
};

const mapAssets = (assets, s3Prefix) => {
    return assets.map((asset) => {
        return mapAsset(asset, s3Prefix);
    });
};

// TODO: Change ignoreGroups default to false
const getAll = async (
    page = 1,
    pageSize = 10,
    searchString = '',
    searchByAssetType = '',
    lastPublishedDate = '',
    sort = '-is_pinned,display_name',
    s3Prefix = '',
    ignoreGroups = false,
    activeOnly = true
) => {
    const filters = [
        `is_active==${activeOnly.toString()}`,
        searchByAssetType ? `asset_type_id==${searchByAssetType}` : '',
        lastPublishedDate ? `last_published_date>=${lastPublishedDate}` : '',
    ];

    if (searchString) {
        filters.push(`display_name@=*${searchString}`);
    }

    const params = {
        filters: filters.join(','),
        sorts: sort,
        start_index: page,
        stop_index: pageSize,
        ignore_groups: ignoreGroups.toString(),
    };

    const query = new URLSearchParams(params);

    try {
        const response = await networkService.get(`${baseUrl}/?${query}`);
        const assets = mapAssets(response.data, s3Prefix);
        const pageInfo = response.headers['x-pagination'];
        return { pageInfo: pageInfo, assets: assets };
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getAllAsset = async (lastPublishedDate = '') => {
    const sorts = ['display_name'];
    const filters = [
        'is_active==true',
        lastPublishedDate && `${lastPublishedDate}`,
    ];

    const params = {
        sorts: sorts.join(','),
        filters: filters.join(','),
    };

    const query = new URLSearchParams(params);
    try {
        const response = await networkService.get(`${baseUrl}/?${query}`);
        const pageInfo = response.headers['x-pagination'];
        return { pageInfo: pageInfo, assets: response.data };
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getRange = async (
    startIndex = 1,
    stopIndex = 10,
    searchString = '',
    asset_category_id = '',
    s3Prefix = '',
    searchByAssetType = '',
    lastPublishedDate = '',
    sort = '-is_pinned,display_name',
    ignoreGroups = false,
    activeOnly = true
) => {
    const filters = [`is_active==${activeOnly.toString()}`];

    if (searchByAssetType) {
        filters.push(`asset_type_id==${searchByAssetType}`);
    }

    if (asset_category_id || asset_category_id === 0) {
        if (asset_category_id === 0) {
            filters.push(`asset_category_id==`);
        } else {
            filters.push(`asset_category_id==${asset_category_id}`);
        }
    }

    if (lastPublishedDate) {
        filters.push(`last_published_date>=${lastPublishedDate}`);
    }

    if (searchString) {
        filters.push(`display_name@=*${searchString}`);
    }

    const params = {
        filters: filters.join(','),
        sorts: sort,
        start_index: startIndex,
        stop_index: stopIndex,
        ignore_groups: ignoreGroups.toString(),
    };

    const query = new URLSearchParams(params);
    try {
        const response = await networkService.get(`${baseUrl}/?${query}`);
        const assets = mapAssets(response.data, s3Prefix);
        const pageInfo = response.headers['x-pagination'];
        return { pageInfo: pageInfo, assets: assets };
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const updateProcedureNodes = (procedure) => {
    procedure.nodes?.forEach((node) => {
        updateNodeFields(node);
    });
};

const updateNodeFields = (node) => {
    node.fields?.forEach((field) => {
        if (field.type === LABEL_FIELD_TYPE.id && field.content) {
            if (!field.labelcontent) {
                field.labelcontent = field.content;
            }
            delete field.content;
        }
        if (field.fields) {
            updateNodeFields(field);
        }
    });
};

const getById = async (id, s3Prefix = '') => {
    try {
        const response = await networkService.get(`${baseUrl}/${id}`);
        if (response.data.config_json) {
            response.data.config_json = JSON.parse(response.data.config_json);
        } else {
            response.data.config_json = [];
        }
        if (response.data.asset_type_id === 3) {
            response.data.procedure = JSON.parse(response.data.procedure_json);
            updateProcedureNodes(response.data.procedure);
            response.data.procedure.procedure_id = response.data.procedure_id;
        }
        return mapAsset(response.data, s3Prefix);
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const create = async (params, s3Prefix = '') => {
    const user = JSON.parse(localStorage.getItem('user'));
    let formData = new FormData();
    const requestFormArray = [
        ['display_name', params.display_name],
        ['asset_type_id', params.asset_type_id],
        ['description', params.description],
        ['disclaimer_tooltip', params.disclaimer_tooltip],
        ['disclaimer_html', params.disclaimer_html],
        ['custom_data', params.custom_data],
        ['player_url', params.player_url],
        ['initial_zone_id', params.initial_zone_id],
        ['procedure_type_id', params.procedure_type_id],
        [
            'config_json',
            params.config_json ? JSON.stringify(params.config_json) : null,
        ],
        ['client_id', user.client_id],
        ['user_id', user.user_id],
        ['image', params.image],
        ['options_json', JSON.stringify(params.options_json)],
        [
            'auto_view_single_link',
            params.auto_view_single_link === undefined
                ? false
                : `${params.auto_view_single_link}`,
        ],
    ];
    if (params?.isProcedure) {
        FormDataUtils.safeAppend(formData, [
            ...requestFormArray,
            ['procedure_id', params.procedure_id],
        ]);
    } else {
        FormDataUtils.safeAppend(formData, [
            ...requestFormArray,
            ['nav_hotspot_icon_id', params.nav_hotspot_icon_id],
            ['item_hotspot_icon_id', params.item_hotspot_icon_id],
        ]);
    }

    !params?.isProcedure &&
        params.asset_link_types.map((x) => formData.append('link_type_ids', x));
    params.groups.map((x) => formData.append('group_ids', x));
    params.categories?.map((x) => formData.append('category_ids', x));
    params.audiences?.map((x) => formData.append('audience_ids', x));
    params.tag_ids?.map((x) => formData.append('tag_ids', x));

    let progressParams = createProgressParams(params);
    try {
        const response = await networkService.postMultiFormData(
            baseUrl,
            formData,
            progressParams
        );

        return mapAsset(response.data, s3Prefix);
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const update = async (params, s3Prefix = '') => {
    let formData = new FormData();

    FormDataUtils.safeAppend(formData, [
        ['display_name', params.display_name],
        ['description', params.description],
        ['disclaimer_tooltip', params.disclaimer_tooltip ?? ''],
        ['disclaimer_html', params.disclaimer_html ?? ''],
        ['custom_data', params.custom_data],
        ['player_url', params.player_url],
        ['initial_zone_id', params.initial_zone_id],
        [
            'config_json',
            params.config_json ? JSON.stringify(params.config_json) : null,
        ],
        ['image', params.image],
        [
            'nav_hotspot_icon_id',
            typeof params.nav_hotspot_icon_id === 'number'
                ? params.nav_hotspot_icon_id
                : null,
        ],
        [
            'item_hotspot_icon_id',
            typeof params.item_hotspot_icon_id === 'number'
                ? params.item_hotspot_icon_id
                : null,
        ],
        ['orbit_images_zip', params.orbit_images_zip],
        ['procedure_type_id', params.procedure_type_id],
        ['zone_images_zip', params.zone_images_zip],
        ['zone_image_width', params.zone_image_width],
        ['zone_image_height', params.zone_image_height],
        ['trim_positions', JSON.stringify(params.trim_positions)],
        ['zone_positions', JSON.stringify(params.zone_positions)],
        ['options_json', JSON.stringify(params.options_json)],
        [
            'auto_view_single_link',
            params.auto_view_single_link === undefined
                ? false
                : `${params.auto_view_single_link}`,
        ],
    ]);

    FormDataUtils.safeArrayAppend(formData, [
        ['link_type_ids', params.asset_link_types],
        ['group_ids', params.groups],
        ['category_ids', params.categories],
        ['audience_ids', params.audiences],
        ['tag_ids', params.tag_ids],
        ['zone_names', params.zone_names],
        ['zone_frame_indexes', params.zone_frame_indexes],
        ['companion_app_ids', params.companion_apps],
    ]);

    let progressParams = createProgressParams(params);

    try {
        const response = await networkService.putMultiFormData(
            `${baseUrl}/${params.asset_id}`,
            formData,
            progressParams
        );
        const asset = mapAsset(response.data, s3Prefix);
        return asset;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const updateOrbitImages = async (params, s3Prefix = '') => {
    let formData = new FormData();

    FormDataUtils.safeAppend(formData, [
        ['orbit_images_zip', params.orbit_images_zip],
        ['zone_image_width', params.zone_image_width],
        ['zone_image_height', params.zone_image_height],
    ]);

    let progressParams = createProgressParams(params);

    try {
        const response = await networkService.putMultiFormData(
            `${baseUrl}/${params.asset_id}/UpdateOrbitImages`,
            formData,
            progressParams
        );
        const asset = mapAsset(response.data, s3Prefix);
        return asset;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const assetPinning = async (params) => {
    const response = await networkService.post(
        `${baseUrl}/${params.object_id}/${params.is_pinned ? 'pin' : 'unpin'}`
    );
    return response.data;
};

const duplicate = async (id, s3Prefix = '') => {
    const user = JSON.parse(localStorage.getItem('user'));
    var data = {
        client_id: user.client_id,
        user_id: user.user_id,
    };
    try {
        const response = await networkService.post(
            `${baseUrl}/duplicate/${id}`,
            data
        );
        return mapAsset(response.data, s3Prefix);
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const _delete = async (id) => {
    try {
        const response = await networkService.delete(`${baseUrl}/${id}`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const publishById = async (id, isDraft) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const { user_id, client_id } = user;
    try {
        const response = await networkService.post(`${baseUrl}/publish/${id}`, {
            client_id: client_id,
            user_id: user_id,
            draft: isDraft,
        });
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const publishArray = async (ids) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const { user_id, client_id } = user;
    const query = ids.join('&ids=');
    try {
        const response = await networkService.post(
            `${baseUrl}/publish?ids=${query}`,
            { client_id: client_id, user_id: user_id }
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const exportAsset = async (params) => {
    try {
        const response = await networkService.get(
            `${baseUrl}/${params.asset_id}/Export`
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const importAsset = async (params, s3Prefix = '') => {
    try {
        let formData = new FormData();
        FormDataUtils.safeAppend(formData, [
            ['import_file', params.import_file],
        ]);
        let progressParams = createProgressParams(params);
        const response = await networkService.postMultiFormData(
            `${baseUrl}/import_from_gemini`,
            formData,
            progressParams
        );
        return response;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const deleteArray = async (ids) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const { user_id, client_id } = user;
    const query = ids.join('&ids=');
    try {
        const response = await networkService.delete(
            `${baseUrl}?ids=${query}`,
            {
                client_id: client_id,
                user_id: user_id,
            }
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getAllLinkTypes = async () => {
    if (!fetchByKey('allLinkTypes')) {
        const response = await networkService.get(`${baseUrl}/linktypes`);
        setAllLinkTypes(response?.data);
        return response.data;
    }
    return fetchByKey('allLinkTypes');
};

const getAssetTypes = async () => {
    if (!fetchByKey('assetTypes')) {
        const response = await networkService.get(`${baseUrl}/AssetTypes`);
        setAssetTypes(response?.data);
        return response.data;
    }
    return fetchByKey('assetTypes');
};

const getAssetsList = async () => {
    const response = await networkService.get(`${baseUrl}/simplelist`);
    return response.data;
};

const getClientAssetsList = async (clientId) => {
    const response = await networkService.get(
        `${baseUrl}/getallbyclientid/${clientId}?sorts=display_name&filters=is_active==true`
    );
    return response.data;
};

const getAssetJoinData = async (assetId) => {
    const response = await networkService.get(`${baseUrl}/${assetId}/joindata`);
    return response.data;
};

const copyToClient = async (params) => {
    try {
        const assetId = params?.asset_id;
        let progressParams = createGeneralProgressParams(params);
        const response = await networkService.postWithBody(
            `${baseUrl}/copytoclient/${assetId}`,
            params,
            progressParams
        );

        return response;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

export const assetService = {
    create,
    delete: _delete,
    deleteArray: deleteArray,
    getAll,
    getAllAsset,
    getRange,
    getById,
    assetPinning,
    update,
    importAsset,
    exportAsset,
    duplicate,
    publishById,
    publishArray,
    getAllLinkTypes,
    getAssetJoinData,
    getAssetTypes,
    getAssetsList,
    getClientAssetsList,
    updateOrbitImages,
    updateProcedureNodes,
    copyToClient,
};
