import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@mui/material';

import { LinkTypes } from '_helpers';
import { SelectExistingContentFilters } from './SelectExistingContentFilters';
import { SelectExistingContentTable } from './SelectExistingContentTable';
import {
    fetchContentVersions,
    getContentPageInfo,
    selectContentById,
} from '_features/content/contentSlice';

const SelectContentDialog = ({
    openDialog,
    setOpenDialog,
    setFieldValue,
    setSorting,
    contentPayload,
    setContentPayload,
    sortBy,
    sortDirection,
    setSelectedContent,
    setSelectedPoster = () => {},
    inlineVideo = null,
}) => {
    const dispatch = useDispatch();
    const content = useSelector(selectContentById);
    const contentPageInfo = useSelector(getContentPageInfo);
    const [searchStringState, setSearchStringState] = useState('');

    useEffect(() => {
        if (inlineVideo === 'inlineVideo') {
            setContentPayload({
                reset: true,
                startIndex: 0,
                stopIndex: 19,
                contentTypeIds: LinkTypes.Video.contentTypes.map(
                    (link) => link.id
                ),
            });
        } else if (inlineVideo === 'videoPoster') {
            setContentPayload({
                reset: true,
                startIndex: 0,
                stopIndex: 19,
                contentTypeIds: LinkTypes.Image.contentTypes.map(
                    (link) => link.id
                ),
            });
        } else {
            setContentPayload({
                reset: true,
                startIndex: 0,
                stopIndex: 19,
                contentTypeIds: [],
            });
        }
        // return () => {
        //     setSortBy('display_name');
        //     setSortDirection('ASC');
        // };
    }, []);

    return (
        <Dialog
            open={openDialog}
            fullWidth
            maxWidth="sm"
            onClose={() => setOpenDialog(false)}
        >
            <DialogTitle style={{ fontWeight: 700, fontSize: '24px' }}>
                Select Content
            </DialogTitle>
            <DialogContent style={{ padding: '10px 24px 20px' }}>
                <SelectExistingContentFilters
                    contentPayload={contentPayload}
                    setContentPayload={setContentPayload}
                    searchStringState={searchStringState}
                    setSearchStringState={setSearchStringState}
                    inlineVideo={inlineVideo}
                />
                <SelectExistingContentTable
                    content={content}
                    pageInfo={contentPageInfo}
                    contentPayload={contentPayload}
                    sortDirection={sortDirection}
                    sortBy={sortBy}
                    handleSort={(_sortBy, _sortDirection) =>
                        setSorting(_sortBy, _sortDirection)
                    }
                    handleRowClick={async (data) => {
                        const versions = await dispatch(
                            fetchContentVersions(data?.content_id)
                        );
                        setFieldValue('contentVersions', versions?.payload);
                        if (inlineVideo === 'videoPoster') {
                            setFieldValue('poster_image_url', data?.url);
                            setFieldValue(
                                'poster_image_name',
                                data?.display_name
                            );
                            setSelectedPoster(data?.display_name);
                        } else {
                            setSelectedContent(data?.display_name);
                            setFieldValue('content_id', data?.content_id);
                        }
                        if (
                            inlineVideo === 'inlineVideo' &&
                            data?.chapters &&
                            data?.chapters?.length > 0
                        ) {
                            setFieldValue('chapters', data.chapters);
                        }
                        setOpenDialog(false);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export { SelectContentDialog };
