const loadScript = (url, onLoaded) => {
    const script = document.createElement('script');
    document.body.appendChild(script);
    if (onLoaded !== undefined) {
        script.onload = onLoaded;
    }
    script.async = false;
    script.src = url;
};

const loadLink = (url) => {
    const link = document.createElement('link');
    document.head.appendChild(link);
    link.href = url;
    link.rel = 'stylesheet';
};

export { loadScript, loadLink };
