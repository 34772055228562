/**
 * Table based dynamically generated form.
 * params
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Box,
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material';
import { FormTableCell } from './FormTableCell';
import {
    MdAddCircleOutline,
    MdDelete,
    MdPublic,
    MdReplay,
    MdVrpano,
    MdWorkOutline,
} from 'react-icons/md';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { FieldSet } from 'components/_Layout/ClientConfigManagementTab/FieldSet';
import { getDirtyFlag } from '_features/common/formSlice';
import { makeFormTableStyles } from './styles';
import UnsavedPromptDialog from 'components/_Layout/UnsavedPromptDialog';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    cloneDeep,
    groupBy,
    remove,
} from 'GeminiViewerComponent/_helpers/lodashUtils';
import FieldValidationError from 'GeminiViewerComponent/components/Procedure/components/ProcedureFormNode/FormFields/FieldValidationError';
import { Form, Formik, useFormikContext } from 'formik';
import { makeFormStyles } from 'forms/styles';
import { accountsSlice } from 'app/store';
import { Role } from '_helpers';
const { selectActiveUser } = accountsSlice;

const validateTableField = (
    data,
    validationTypes,
    validationRawTypes,
    key,
    value
) => {
    const fieldValue = key === 'value' ? value : data?.value;
    const fieldRequired = key === 'required' ? value : data?.required;

    if (validationTypes && validationTypes?.length > 0) {
        const validationValue = validationTypes.find(
            (validation) =>
                validation?.validation_type_id === data?.validation_type_id
        );
        if (
            validationRawTypes &&
            validationRawTypes?.length > 0 &&
            Object.keys(validationValue || {}).length > 0
        ) {
            const rowType = validationRawTypes.find(
                (rowType) =>
                    rowType?.validation_raw_type_id === validationValue?.type_id
            );
            switch (rowType?.display_name) {
                case 'Integer':
                    if (
                        fieldValue < validationValue?.min_value ||
                        fieldValue > validationValue?.max_value
                    ) {
                        return 'Value is not in range.';
                    }
                    if (fieldValue.toString().match(/[^0-9.]/g, '')) {
                        return 'Invalid Value.';
                    }

                    return;
                case 'String':
                    if (fieldRequired && !fieldValue) {
                        return 'Required.';
                    }
                    return;
                case 'JSON':
                    try {
                        if (fieldRequired && !fieldValue) {
                            return 'Required.';
                        }
                        const fieldData = JSON.parse(fieldValue);
                        if (Object.keys(fieldData || {}).length <= 0) {
                            return 'Invalid value.';
                        }
                    } catch (e) {
                        return 'Invalid type value.';
                    }
                    return;
                case 'Select':
                    if (fieldRequired && !fieldValue) {
                        return 'Required.';
                    }
                    return;
                case 'MultiSelect':
                    if (fieldRequired && !fieldValue) {
                        return 'Required.';
                    }
                    return;
                default:
                    return;
            }
        }
    }
};

const setValidation = (
    formValue,
    tableDefinition,
    validationMessage,
    setValidationMessage,
    key,
    newValue = null
) => {
    const errorMessage = validateTableField(
        formValue,
        tableDefinition?.validationTypes,
        tableDefinition?.validationRawTypes,
        key,
        newValue
    );
    if (errorMessage) {
        setValidationMessage({
            ...validationMessage,
            [formValue.config_field_id]: errorMessage,
        });
    } else {
        const validations = { ...validationMessage };
        delete validations[formValue.config_field_id];
        setValidationMessage({ ...validations });
    }
};

const getRowIcon = (fieldSource) => {
    if (fieldSource === 'Global') {
        return (
            <Tooltip title="Global">
                <Box>
                    <MdPublic
                        className="react-icon"
                        style={{
                            color: '#1154ce',
                            marginTop: '10px',
                            marginRight: '5px',
                        }}
                    />
                </Box>
            </Tooltip>
        );
    } else if (fieldSource === 'Client') {
        return (
            <Tooltip title="Client">
                <Box>
                    <MdWorkOutline
                        className="react-icon"
                        style={{
                            color: '#11cc6d',
                            marginTop: '10px',
                            marginRight: '5px',
                        }}
                    />
                </Box>
            </Tooltip>
        );
    } else {
        return (
            <Tooltip title="Asset">
                <Box>
                    <MdVrpano
                        style={{
                            color: '#d65712',
                            marginTop: '10px',
                            marginRight: '5px',
                        }}
                    />
                </Box>
            </Tooltip>
        );
    }
};

const SingleTable = ({
    values,
    tableDefinition,
    tableKey,
    activeUserRole,
    validationMessage,
    setValidationMessage,
}) => {
    const { setFieldValue, values: formValues } = useFormikContext();
    const theme = useSelector(selectActiveTheme);
    const getDirty = useSelector(getDirtyFlag);
    const styles = makeFormTableStyles(theme);

    const handleAdd = useCallback(
        (category) => {
            const newFormValue = tableDefinition.createMethod(category);

            // Handle updateOnSubmit coluns by adding temp value to
            tableDefinition.columns.map((column) => {
                if (column.updateOnSubmit) {
                    newFormValue[`${column.key}_temp`] =
                        newFormValue[column.key];
                }
            });
            // let updatedFormValues = cloneDeep(tableDefinition.selectFormState);
            let updatedFormValues = Object.values(formValues || {});
            updatedFormValues.push(newFormValue);
            tableDefinition.setStateMethod(updatedFormValues);
        },
        [formValues]
    );

    const handleDelete = useCallback(
        async (formValue) => {
            // let updatedFormValues = cloneDeep(tableDefinition.selectFormState);
            let updatedFormValues = Object.values(formValues || {});
            remove(updatedFormValues, {
                [tableDefinition.key]: formValue[tableDefinition.key],
            });
            tableDefinition.setStateMethod(updatedFormValues);
        },
        [formValues]
    );

    const handleChange = async (key, formValue, newValue, column, fieldId) => {
        if (activeUserRole !== Role.IPSAdmin) {
            setValidation(
                formValue,
                tableDefinition,
                validationMessage,
                setValidationMessage,
                key,
                newValue
            );
        }
        const updatedValues = { ...formValues[fieldId] };

        if (
            updatedValues.field_source == 'Global' &&
            tableDefinition.allowGlobalChange == false
        ) {
            updatedValues.config_value_id = uuidv4();
        }
        if (tableDefinition.seletectedAssetId !== 0) {
            updatedValues['field_source'] = 'Asset';
        }
        updatedValues[key] = newValue;
        updatedValues.dirty = true;

        setFieldValue(`${fieldId}`, updatedValues);
    };

    let numColumns = tableDefinition.columns.length;
    if (tableDefinition.allowDelete !== false || tableDefinition.allowReset) {
        numColumns++;
    }

    return (
        <Table key={tableKey} size="small">
            <TableHead>
                <TableRow>
                    {tableDefinition.columns.map((column) => {
                        return (
                            <TableCell key={column.key}>
                                {column.title}
                            </TableCell>
                        );
                    })}
                    {(tableDefinition.allowDelete !== false ||
                        tableDefinition.allowReset) && (
                        <TableCell>Actions</TableCell>
                    )}
                </TableRow>
            </TableHead>
            <TableBody>
                {values.map((data) => {
                    let rowClassName = tableDefinition.rowClass ?? null;
                    if (typeof tableDefinition.rowClass === 'function') {
                        rowClassName = tableDefinition.rowClass(data);
                    }

                    let allowReset = tableDefinition.allowReset;

                    // See if clear button visibility is based on function call
                    if (typeof tableDefinition.allowReset === 'function') {
                        allowReset = tableDefinition.allowReset(data);
                    }

                    return (
                        <TableRow
                            key={data[tableDefinition.key]}
                            sx={{
                                borderBottom:
                                    '1px solid rgba(224, 224, 224, 1)',
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                                height: `${
                                    validationMessage[data?.config_field_id]
                                        ? '75px'
                                        : 'auto'
                                }`,
                                position: 'relative',
                                verticalAlign: 'top',
                            }}
                            className={rowClassName}
                        >
                            {tableDefinition.columns.map((column) => {
                                return (
                                    <FormTableCell
                                        key={column.key}
                                        fieldId={
                                            tableKey === 'table'
                                                ? data?.validation_type_id
                                                : data?.config_field_id
                                        }
                                        data={data}
                                        column={column}
                                        handleChange={handleChange}
                                        selectedClientId={
                                            tableDefinition.selectedClientId
                                        }
                                        getRowIcon={getRowIcon}
                                    />
                                );
                            })}
                            {(tableDefinition.allowDelete ||
                                tableDefinition.allowReset) && (
                                <>
                                    <TableCell>
                                        <div className={styles.actionCell}>
                                            {tableDefinition.allowDelete && (
                                                <MdDelete
                                                    className="react-icon"
                                                    onClick={() => {
                                                        if (!getDirty) return;
                                                        handleDelete(data);
                                                    }}
                                                />
                                            )}
                                            {allowReset &&
                                                tableDefinition.selectedClientId !==
                                                    0 && (
                                                    <Tooltip title="Reset to parent">
                                                        <Box>
                                                            <MdReplay
                                                                className="react-icon"
                                                                onClick={() => {
                                                                    if (
                                                                        !getDirty
                                                                    )
                                                                        return;
                                                                    tableDefinition.resetMethod(
                                                                        data
                                                                    );
                                                                }}
                                                            />
                                                        </Box>
                                                    </Tooltip>
                                                )}
                                        </div>
                                    </TableCell>
                                </>
                            )}
                            {validationMessage[data?.config_field_id] && (
                                <div
                                    style={{
                                        bottom: '0px',
                                        left: '20px',
                                        position: 'absolute',
                                    }}
                                >
                                    <FieldValidationError
                                        validationError={
                                            validationMessage[
                                                data?.config_field_id
                                            ]
                                        }
                                    />
                                </div>
                            )}
                        </TableRow>
                    );
                })}
                {tableDefinition.createMethod && (
                    <TableRow>
                        <TableCell colSpan={numColumns}>
                            <IconButton
                                disabled={!getDirty}
                                onClick={() =>
                                    handleAdd(tableKey, tableDefinition)
                                }
                                size="small"
                            >
                                <MdAddCircleOutline
                                    className={`react-icon ${styles.addButtonIcon}`}
                                />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
};

/**
  * Form table component
  * @param {Object} tableDefinition Table definition object.
     {func} createMethod(): Method to call when creating a new row. null = Don't allow new rows.
     {func} submitMethod(values): Method to call when submitting all updates. values: Updated form values.
     {func} resetMethod(data): Method to call when reset button is pressed on a row. data: Row data.
     {bool|func(data)} allowDelete: If bool then true adds delete button on each row. If func then return true to add button based on passed row data.
     {bool|func(data)} allowReset: If bool then true adds reset button on each row. If func then return true to add reset button based on passed row data.
     {bool} allowGlobalChange: If true then global values can be changed, otherwise they are set as a new value for client or asset.
     {func} setStateMethod: Called when form state needs updating.
     {func} selectFormState: Func called to get current form values.
     {string} categoryKey: Optinal property key that hold the category for each row. If present then table will be grouped into sections based on the value of the category.
     {string} key: Primary key that represents a unique value for each row.
     {string} class: set a className on the table.
     {string|func(data)}: rowClass: If string then className to set on each row. If func then return classname to set on passed in row data.
     {array}: columns. Definition of all columns in the table. Each column can use the followig settings:
         {object}: config: Configuration object with the following options:
             {string|func(data)} type: If string then 'text'|'checkbox'|'select'|'autocomplete'|'date'. If func then should return valid column config based on passed in row data.
             {array} options: Set of options if type is 'select'. Can be simple array of strings or more complicated array of objects.
             {string} options_key: Optional options key if type is 'select'
             {string} options_value_key: Optional options value key if type is 'select'
             {string} options_display_key: Optional options display key  if type is 'select'
             {string} title: Colmun heading title.
             {string|func} class: If string then className to assign column. If func then return string to use as className based on passed in row data.
             {string} width: Optional width to assign column.
             {string} key: Property key used to display column value.
             {bool} readOnly: Set to true if column should be read only.
             {bool} updateOnSubmit: Set to true if column should only update the original value on form submit. This is useful updating a value immediately would cause UI issues, such as updating a category field causing a row to jump to a new category section.
             {object} validation: Validation settings using same format as Formik validationSchema.
  *
  */
const FormTable = ({ tableDefinition }) => {
    //#region Constants
    //#endregion Constants

    //#region Hooks
    const getDirty = useSelector(getDirtyFlag);
    const theme = useSelector(selectActiveTheme);
    const activeUser = useSelector(selectActiveUser);
    const classes = makeFormStyles(theme);
    const [
        openUnsavedDataConfirmationDialog,
        setOpenUnsavedDataConfirmationDialog,
    ] = useState(false);

    useEffect(() => {
        // When table is first displayed add in temporary values for
        // columns marked as updateOnSubmit.

        // let changed = false;
        let values = cloneDeep(tableDefinition.selectFormState);

        tableDefinition.columns.map((column) => {
            if (column.updateOnSubmit) {
                values.map((value) => {
                    value[`${column.key}_temp`] = value[column.key];
                    // changed = true;
                });
            }
        });
        // if (changed) {
        //     tableDefinition.setStateMethod(changed);
        // }
    }, [tableDefinition]);

    //#endregion Hooks

    //#region State
    const [validationMessage, setValidationMessage] = useState({});

    //#endregion State

    //#region Selectors
    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Effects
    //#endregion Effects

    //#region Methods

    const handleSubmit = (values) => {
        if (Object.keys(validationMessage || {}).length > 0) return;
        // Must pass copy to make sure changes made during update
        // are not reflected here

        // let values = cloneDeep(tableDefinition.selectFormState);
        let formValues = cloneDeep(values || {});

        tableDefinition.columns.map((column) => {
            if (column.updateOnSubmit) {
                formValues.map((value) => {
                    value[column.key] = value[`${column.key}_temp`];
                });
            }
        });
        tableDefinition.submitMethod(formValues);
    };

    //#endregion Methods

    //#region Render time calcs

    // If there is cateogry key then sort by category into multiple tables

    const values = useMemo(() => {
        return cloneDeep(tableDefinition.selectFormState);
    }, [tableDefinition.selectFormState]);

    const valuesByCategory = useMemo(() => {
        if (!tableDefinition.categoryKey) return null;
        return groupBy(values, tableDefinition.categoryKey);
    }, [values, tableDefinition.categoryKey]);
    //#endregion Render time calcs

    //#region Render

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{ ...tableDefinition?.formValues }}
                onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(Object.values(values || {}));
                    setSubmitting(false);
                }}
            >
                {({ resetForm }) => (
                    <Form className={classes.form} style={{ padding: '0px' }}>
                        {valuesByCategory ? (
                            Object.keys(valuesByCategory).map((category) => {
                                const values = valuesByCategory[category];
                                return (
                                    <FieldSet
                                        key={category}
                                        title={category}
                                        style={{
                                            border: '1px solid #cccccc',
                                            padding: '5px',
                                            borderRadius: '5px',
                                        }}
                                    >
                                        <SingleTable
                                            values={values}
                                            tableDefinition={tableDefinition}
                                            tableKey={category}
                                            activeUserRole={activeUser?.role}
                                            validationMessage={
                                                validationMessage
                                            }
                                            setValidationMessage={
                                                setValidationMessage
                                            }
                                        />
                                    </FieldSet>
                                );
                            })
                        ) : (
                            <SingleTable
                                values={values}
                                tableDefinition={tableDefinition}
                                tableKey="table"
                                activeUserRole={activeUser?.role}
                                validationMessage={validationMessage}
                                setValidationMessage={setValidationMessage}
                            />
                        )}
                        {getDirty && (
                            <div
                                style={{
                                    padding: '15px',
                                }}
                            >
                                <Button variant="contained" type="submit">
                                    Submit
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    disabled={!getDirty}
                                    onClick={() => {
                                        setOpenUnsavedDataConfirmationDialog(
                                            true
                                        );
                                        resetForm();
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>
                        )}
                    </Form>
                )}
            </Formik>
            <UnsavedPromptDialog
                openDialog={openUnsavedDataConfirmationDialog}
                setOpenDialog={setOpenUnsavedDataConfirmationDialog}
                confirmSubmit={tableDefinition.handleCancel}
            />
        </>
    );
    //#endregion Render
};
export { FormTable };
