// createElement is mandatory for 3rd party integration, so please don't remove it.
// eslint-disable-next-line
import React, { createElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getVoiceSelection,
    setListening,
    getMessage,
    getSpeak,
} from '../../../../../../_features/voice/voiceSlice';

import {
    setCurrentNode,
    addReversal,
} from '../../../../../../_features/decisionTree/decisionTreeSlice';

const Speak = ({ node }) => {
    const dispatch = useDispatch();
    const voiceOn = useSelector((state) => getSpeak(state));
    const voice = useSelector((state) => getVoiceSelection(state));
    const message = useSelector((state) => getMessage(state));

    useEffect(() => {
        if (message && message !== '' && voiceOn && window.allVoices) {
            window.speechSynthesis.cancel();
            var msg = new SpeechSynthesisUtterance();
            msg.voice = window.allVoices[voice];
            msg.rate = 1;
            msg.pitch = 1;
            msg.text = message;
            msg.onend = function () {
                dispatch(setListening({ listen: true }));
            };
            window.speechSynthesis.speak(msg);
        } else if (node.option_buttons && node.option_buttons.length > 0) {
            dispatch(setListening({ listen: true }));
        }
    }, [dispatch, message]); // eslint-disable-line react-hooks/exhaustive-deps

    return null;
};

const NodeCommands = (options, dispatch, speaker) => {
    var commands = [
        {
            command: 'stop',
            callback: () => {
                window.speechSynthesis.cancel();
            },
        },
    ];
    options.forEach((option) => {
        commands.push({
            command: option.voice_triggers,
            callback: (args) => {
                speaker.stopListening();
                const nodeLink = options.find(
                    (option) => option.voice_triggers.indexOf(args.command) > -1
                ).button_link_node;
                dispatch(setCurrentNode({ node_id: nodeLink }));
                dispatch(addReversal({ node_id: option.node_id }));
                dispatch(setListening({ listen: false }));
            },
        });
    });
    return commands;
};

const RemoveHtmlTags = (str) => {
    if (str === null || str === '') return false;
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, '');
};

export { Speak, RemoveHtmlTags, NodeCommands };
