import { config } from 'gemini-config';
import { getAccountService } from 'GeminiViewerComponent/_features/services/account.service';
import {
    createProgressParams,
    FormDataUtils,
    getNetworkService,
} from 'GeminiViewerComponent/_helpers';

const baseUrl = `${config.apiUrl}/newsflash`;

const accountService = getAccountService({ config: config });
const networkService = getNetworkService({
    config: config,
    checkAuth: accountService.checkAuth,
});

const getAll = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const filters = ['is_active==true', `client_id==${user.client_id}`];

    let params = {
        filters: filters.join(','),
    };

    const query = new URLSearchParams(params).toString();

    const response = await networkService.get(`${baseUrl}?${query}`);

    return response.data;
};

const getAllByRange = async (
    startIndex = 1,
    stopIndex = 10,
    searchString = '',
    sort = 'display_name',
    start_date = null,
    end_date = null,
    display_option_id = [],
    activeOnly = true
) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const filters = [
        `is_active==${activeOnly.toString()}`,
        `client_id==${user.client_id}`,
    ];

    if (searchString) {
        filters.push(`display_name@=*${searchString}`);
    }
    if (start_date) {
        filters.push(`start_date>=${start_date}`);
    }
    if (end_date) {
        filters.push(`end_date<=${end_date}`);
    }

    if (display_option_id && display_option_id?.length > 0) {
        filters.push(
            `news_flash_display_option_id==${display_option_id
                .join('|')
                .toString()}`
        );
    }

    let params = {
        filters: filters.join(','),
        start_index: startIndex,
        stop_index: stopIndex,
    };

    if (sort) {
        params = { ...params, sorts: sort };
    }

    const query = new URLSearchParams(params).toString();

    const response = await networkService.get(`${baseUrl}?${query}`);

    const newsFlashData = response.data;
    const pageInfo = response.headers['x-pagination'];
    return { pageInfo: pageInfo, newsFlashData };
};

const getById = async (newsFlashId) => {
    try {
        const response = await networkService.get(`${baseUrl}/${newsFlashId}`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getDisplayOptions = async () => {
    try {
        const response = await networkService.get(`${baseUrl}/DisplayOptions`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const _create = async (params) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const formData = new FormData();

    FormDataUtils.safeAppend(formData, [
        ['news_flash_display_option_id', params.news_flash_display_option_id],
        ['display_name', params.display_name],
        ['content', params.content],
        ['start_date', params.start_date],
        ['end_date', params.end_date],
        ['client_id', user.client_id],
        ['asset_node_ids', params.asset_node_ids],
    ]);

    FormDataUtils.safeArrayAppend(formData, [['zone_ids', params.zone_ids]]);
    // asset_node_ids accept as object make another function with no index in key

    var progressParams = createProgressParams(params);
    try {
        const response = await networkService.postMultiFormData(
            baseUrl,
            formData,
            progressParams
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

const _update = async (id, params) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const formData = new FormData();

    FormDataUtils.safeAppend(formData, [
        ['news_flash_id', params.news_flash_id],
        ['news_flash_display_option_id', params.news_flash_display_option_id],
        ['display_name', params.display_name],
        ['content', params.content],
        ['start_date', params.start_date],
        ['end_date', params.end_date],
        ['client_id', user.client_id],
        ['asset_node_ids', params.asset_node_ids],
    ]);

    FormDataUtils.safeArrayAppend(formData, [['zone_ids', params.zone_ids]]);
    // asset_node_ids accept as object make another function with no index in key

    var progressParams = createProgressParams(params);
    try {
        const response = await networkService.putMultiFormData(
            `${baseUrl}/${id}`,
            formData,
            progressParams
        );
        return response.data;
    } catch (error) {
        return error;
    }
};

const _delete = async (newsFlashId) => {
    try {
        const response = await networkService.delete(
            `${baseUrl}/${newsFlashId}`
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const deleteArray = async (newsFlashIds) => {
    try {
        let queryParams = [];

        newsFlashIds?.map((id) => {
            queryParams.push(`ids=${id}`);
        });
        const query = queryParams.join('&').toString();

        const response = await networkService.delete(`${baseUrl}?${query}`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

export const newsFlashService = {
    getAll,
    getAllByRange,
    getById,
    getDisplayOptions,
    create: _create,
    update: _update,
    delete: _delete,
    deleteArray,
};
