import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    schooxTab: true,
    contentValidationModal: false,
    contentValidationModalContent: '',
    // generic modal
    genericModal: false,
    genericModalContent: '',
    // admin manager tab state
    adminTabIndex: 0,
    // jobs manager tab state
    jobsTabIndex: 0,
    // tools manager tab state
    toolsTabIndex: 0,
    // parts manager tab state
    partsTabIndex: 0,
    // content version create
    contentVersionCreatePanel: false,
    contentVersionContent: null,
};

const visibilitySlice = createSlice({
    name: 'visibility',
    initialState,
    reducers: {
        toggleSchooxTab: (state) => {
            return { ...state, schooxTab: !state.schooxTab };
        },
        resetSchooxTab: (state) => {
            return { ...state, schooxTab: true };
        },
        toggleContentValidationModal: (state) => {
            return {
                ...state,
                contentValidationModal: !state.contentValidationModal,
            };
        },
        setContentValidationModalContent: (state, action) => {
            state.contentValidationModalContent = action.payload;
        },
        toggleGenericModal: (state) => {
            return {
                ...state,
                genericModal: !state.genericModal,
            };
        },
        setGenericModalContent: (state, action) => {
            state.genericModalContent = action.payload;
        },
        setAdminTabIndex: (state, action) => {
            state.adminTabIndex = action.payload;
        },
        setJobsTabIndex: (state, action) => {
            state.jobsTabIndex = action.payload;
        },
        setToolsTabIndex: (state, action) => {
            state.toolsTabIndex = action.payload;
        },
        setPartsTabIndex: (state, action) => {
            state.partsTabIndex = action.payload;
        },
        showContentVersionCreatePanel: (state, action) => {
            state.contentVersionCreatePanel = true;
            state.contentVersionContent = action.payload.content;
        },
        closeContentVersionCreatePanel: (state) => {
            state.contentVersionCreatePanel = false;
            state.contentVersionContent = null;
        },
        resetVisibilityState: (state) => initialState,
    },
    extraReducers: {},
});

export const getSchooxVis = (state) => state.visibility.schooxTab;
export const getContentValidationModalVis = (state) =>
    state.visibility.contentValidationModal;
export const getContentValidationModalContent = (state) =>
    state.visibility.contentValidationModalContent;

export const getGenericModalVis = (state) => state.visibility.genericModal;
export const getGenericModalContent = (state) =>
    state.visibility.genericModalContent;
export const getAdminTabIndex = (state) => state.visibility.adminTabIndex;
export const getJobsTabIndex = (state) => state.visibility.jobsTabIndex;
export const getToolsTabIndex = (state) => state.visibility.toolsTabIndex;
export const getPartsTabIndex = (state) => state.visibility.partsTabIndex;

// CONTENT VERSION PANEL
export const getContentVersionPanelVis = (state) =>
    state.visibility.contentVersionCreatePanel;
export const getContentVersionContent = (state) =>
    state.visibility.contentVersionContent;

export const {
    closeContentVersionCreatePanel,
    resetVisibilityState,
    resetSchooxTab,
    setAdminTabIndex,
    setJobsTabIndex,
    setToolsTabIndex,
    setPartsTabIndex,
    setContentValidationModalContent,
    setGenericModalContent,
    showContentVersionCreatePanel,
    toggleContentValidationModal,
    toggleGenericModal,
    toggleSchooxTab,
} = visibilitySlice.actions;

export default visibilitySlice.reducer;
