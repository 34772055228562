import { config } from 'gemini-config';
import { v4 as uuidv4 } from 'uuid';

import {
    getNetworkService,
    FormDataUtils,
    prefixUrl,
    createFullProgressParams,
    mapPrefixUrl,
} from 'GeminiViewerComponent/_helpers';
import { getAccountService } from 'GeminiViewerComponent/_features/services/account.service';
import { isEmpty } from 'GeminiViewerComponent/_helpers/lodashUtils';

const accountService = getAccountService({ config: config });
const networkService = getNetworkService({
    config: config,
    checkAuth: accountService.checkAuth,
});
const baseUrl = `${config.apiUrl}/zone`;

const mapZone = (zone, s3Prefix) => {
    zone.equirect_image_url = prefixUrl(zone.equirect_image_url, s3Prefix);
    zone.equirect_image_preview_url = prefixUrl(
        zone.equirect_image_preview_url,
        s3Prefix
    );
    zone.flat_image_url = prefixUrl(zone.flat_image_url, s3Prefix);
    zone.flat_image_preview_url = prefixUrl(
        zone.flat_image_preview_url,
        s3Prefix
    );
    if (Array.isArray(zone?.tags) && zone.tags.length > 0) {
        zone.tags = mapPrefixUrl(zone.tags, s3Prefix, 'image_url');
    }
    if (Array.isArray(zone?.items) && zone.items.length > 0) {
        zone.items = zone.items.map((item) => {
            if (Array.isArray(item?.tags) && item.tags.length > 0) {
                item.tags = mapPrefixUrl(item.tags, s3Prefix, 'image_url');
            }
            return item;
        });
    }
    return zone;
};

const mapZones = (zones, s3Prefix) => {
    return zones.map((zone) => {
        return mapZone(zone, s3Prefix);
    });
};

const getAll = async (
    searchString = '',
    assetId,
    startIndex = 0,
    stopIndex = 0,
    sort = '-is_pinned,display_name',
    includeItems = false,
    s3Prefix = '',
    activeOnly = true
) => {
    const filters = [
        `is_active==${activeOnly.toString()}`,
        `display_name@=*${searchString}`,
    ];

    var params = {
        filters: filters.join(','),
        sorts: sort,
        include_items: includeItems,
    };

    if (stopIndex > 0) {
        params.start_index = startIndex;
        params.stop_index = stopIndex;
    }

    const query = new URLSearchParams(params).toString();
    try {
        const response = await networkService.get(
            `${baseUrl}/getallbyassetid/${assetId}?${query}`
        );
        const zones = mapZones(response.data, s3Prefix);
        const pageInfo = response.headers['x-pagination'];
        return { pageInfo: pageInfo, zones: zones };
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getRange = async (
    assetId,
    startIndex = 1,
    stopIndex = 10,
    s3Prefix = '',
    activeOnly = true,
    includeItems = false
) => {
    const filters = [`is_active==${activeOnly.toString()}`];

    const params = {
        filters: filters.join(','),
        sorts: '-is_pinned,display_name',
        start_index: startIndex,
        stop_index: stopIndex,
        include_items: includeItems,
    };

    const query = new URLSearchParams(params).toString();
    try {
        const response = await networkService.get(
            `${baseUrl}/getallbyassetid/${assetId}?${query}`
        );
        const zones = mapZones(response.data, s3Prefix);
        const pageInfo = response.headers['x-pagination'];
        return { pageInfo: pageInfo, zones: zones };
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const create = async (params, s3Prefix = '') => {
    var formData = new FormData();

    var equirect_image = params.equirect_image;
    if (equirect_image.name === '') {
        equirect_image = null;
    }

    var flat_image = params.flat_image;
    if (flat_image.name === '') {
        flat_image = null;
    }

    var equirect_image_preview = params.equirect_image_preview;
    if (equirect_image_preview?.name === '') {
        equirect_image_preview = null;
    }

    var flat_image_preview = params.flat_image_preview;
    if (flat_image_preview?.name === '') {
        flat_image_preview = null;
    }

    FormDataUtils.safeAppend(formData, [
        ['asset_id', params.asset_id],
        ['display_name', params.display_name],
        ['description', params.description],
        ['pano_id', params.pano_id],
        ['content_tag', params.content_tag],
        ['init_view_yaw', params.init_view_yaw],
        ['init_view_pitch', params.init_view_pitch],
        ['init_view_roll', params.init_view_roll],
        ['init_view_fov', params.init_view_fov],
        ['init_view_flat_x', params.init_view_flat_x],
        ['init_view_flat_y', params.init_view_flat_y],
        ['init_view_zoom', params.init_view_zoom],
        ['equirect_image', equirect_image, equirect_image?.name],
        ['flat_image', flat_image, flat_image?.name],
        [
            'equirect_image_preview',
            equirect_image_preview,
            equirect_image_preview?.name,
        ],
        ['flat_image_preview', flat_image_preview, flat_image_preview?.name],
        ['equirect_image_width', params.equirect_image_width],
        ['equirect_image_height', params.equirect_image_height],
        ['flat_image_width', params.flat_image_width],
        ['flat_image_height', params.flat_image_height],
        ['zone_category_id', params.zone_category_id],
    ]);
    params.audiences?.map((x) => formData.append('audience_ids', x));
    params.tag_ids?.map((x) => formData.append('tag_ids', x));
    params.news_flash_ids?.map((x) => formData.append('news_flash_ids', x));

    var progressParams = createFullProgressParams(params);

    try {
        const response = await networkService.postMultiFormData(
            baseUrl,
            formData,
            progressParams
        );
        return mapZone(response.data, s3Prefix);
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const createMultiple = async (paramsData, s3Prefix = '') => {
    let uuid = uuidv4();
    let formData = new FormData();
    FormDataUtils.safeAppend(formData, [['transaction_id', uuid]]);
    let zoneNo = 0;
    paramsData?.map((x) => {
        let equirect_image = x.equirect_image;
        if (equirect_image.name === '') {
            equirect_image = null;
        }

        let flat_image = x.flat_image;
        if (flat_image.name === '') {
            flat_image = null;
        }

        let equirect_image_preview = x.equirect_image_preview;
        if (equirect_image_preview?.name === '') {
            equirect_image_preview = null;
        }

        let flat_image_preview = x.flat_image_preview;
        if (flat_image_preview?.name === '') {
            flat_image_preview = null;
        }
        formData.append(`zones[${zoneNo}].description`, x.description);
        formData.append(`zones[${zoneNo}].pano_id`, x.pano_id);
        formData.append(`zones[${zoneNo}].asset_id`, x.asset_id);
        formData.append(`zones[${zoneNo}].display_name`, x.display_name);
        formData.append(
            `zones[${zoneNo}].equirect_image`,
            equirect_image,
            equirect_image?.name
        );
        formData.append(
            `zones[${zoneNo}].equirect_image_width`,
            x.equirect_image_width
        );
        formData.append(
            `zones[${zoneNo}].equirect_image_height`,
            x.equirect_image_height
        );
        formData.append(`zones[${zoneNo}].transaction_id`, uuid);
        ++zoneNo;
        return null;
    });
    // const response =
    try {
        await networkService.postMultiFormData(
            baseUrl + '/createmultiple',
            formData,
            false,
            0,
            true
        );
        return true;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getById = async (id, s3Prefix) => {
    try {
        const response = await networkService.get(`${baseUrl}/${id}`);
        return mapZone(response.data, s3Prefix);
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const update = async (params, s3Prefix = '') => {
    var formData = new FormData();

    var equirect_image_preview = params.equirect_image_preview;
    if (
        equirect_image_preview &&
        (equirect_image_preview.name === '' ||
            equirect_image_preview.file === null)
    ) {
        equirect_image_preview = null;
    }

    var equirect_image = params.equirect_image;
    if (
        equirect_image &&
        (equirect_image.name === '' || equirect_image.file === null)
    ) {
        equirect_image = null;
    }

    var flat_image_preview = params.flat_image_preview;
    if (
        flat_image_preview &&
        (flat_image_preview.name === '' || flat_image_preview.file === null)
    ) {
        flat_image_preview = null;
    }

    var flat_image = params.flat_image;
    if (flat_image && (flat_image.name === '' || flat_image.file === null)) {
        flat_image = null;
    }

    FormDataUtils.safeAppend(formData, [
        ['asset_id', params.asset_id],
        ['display_name', params.display_name],
        ['description', params.description],
        ['pano_id', params.pano_id],
        ['content_tag', params.content_tag],
        ['init_view_yaw', params.init_view_yaw],
        ['init_view_pitch', params.init_view_pitch],
        ['init_view_roll', params.init_view_roll],
        ['init_view_fov', params.init_view_fov],
        ['init_view_flat_x', params.init_view_flat_x],
        ['init_view_flat_y', params.init_view_flat_y],
        ['init_view_zoom', params.init_view_zoom],
        ['equirect_image', equirect_image, equirect_image?.name],
        ['flat_image', flat_image, flat_image?.name],
        [
            'equirect_image_preview',
            equirect_image_preview,
            equirect_image_preview?.name,
        ],
        ['flat_image_preview', flat_image_preview, flat_image_preview?.name],
        ['equirect_image_width', params.equirect_image_width],
        ['equirect_image_height', params.equirect_image_height],
        ['flat_image_width', params.flat_image_width],
        ['flat_image_height', params.flat_image_height],
        ['zone_category_id', params.zone_category_id],
    ]);
    params.audiences?.map((x) => formData.append('audience_ids', x));
    params.tag_ids?.map((x) => formData.append('tag_ids', x));
    params.news_flash_ids?.map((x) => formData.append('news_flash_ids', x));

    if (params.equirect_image && isEmpty(params.equirect_image)) {
        formData.append('clear_equirect_image', true);
    }
    if (params.flat_image && isEmpty(params.flat_image)) {
        formData.append('clear_flat_image', true);
    }

    var progressParams = createFullProgressParams(params);

    try {
        const response = await networkService.putMultiFormData(
            `${baseUrl}/${params.zone_id}`,
            formData,
            progressParams
        );

        return mapZone(response.data, s3Prefix);
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const duplicate = async (id, s3Prefix = '') => {
    const user = JSON.parse(localStorage.getItem('user'));
    var data = {
        client_id: user.client_id,
        user_id: user.user_id,
    };
    try {
        const response = await networkService.post(
            `${baseUrl}/duplicate/${id}`,
            data
        );
        return mapZone(response.data, s3Prefix);
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const _delete = async (id) => {
    try {
        const response = await networkService.delete(`${baseUrl}/${id}`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const deleteArray = async (ids) => {
    try {
        const query = ids.join('&ids=');
        const response = await networkService.delete(`${baseUrl}?ids=${query}`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const zonePinning = async (params, s3Prefix = '') => {
    try {
        const response = await networkService.post(
            `${baseUrl}/${params.object_id}/${
                params.is_pinned ? 'pin' : 'unpin'
            }`
        );
        return mapZone(response.data, s3Prefix);
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getZoneCategoryByAssetId = async (id) => {
    try {
        const response = await networkService.get(
            `${config.apiUrl}/zonecategory/asset/${id}`
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const createZoneCategory = async (params) => {
    const requestPayload = {
        display_name: params.display_name,
        asset_id: params.asset_id,
    };
    try {
        const response = await networkService.post(
            `${config.apiUrl}/zonecategory`,
            requestPayload
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const copyZoneToAsset = async (zone_ids = [], asset_ids = []) => {
    let queryParams = [];

    zone_ids?.map((zone_id) => {
        queryParams.push(`zone_ids=${zone_id}`);
    });
    asset_ids?.map((asset_id) => {
        queryParams.push(`asset_ids=${asset_id}`);
    });
    const query = queryParams.join('&').toString();

    try {
        const response = await networkService.post(
            `${baseUrl}/copytoasset?${query}`
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

export const zoneService = {
    create: create,
    delete: _delete,
    deleteArray: deleteArray,
    duplicate: duplicate,
    zonePinning: zonePinning,
    getAll,
    getRange,
    getById,
    update: update,
    createZoneCategory,
    getZoneCategoryByAssetId,
    createMultiple,
    copyZoneToAsset,
};
