import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AssetCard from './components/AssetCard';
import { EnhancedTableToolbar, VirtualGrid } from 'components';
import {
    fetchMoreAssets,
    selectAllAssets,
} from '_features/assets/assetsTableSlice';
import { AutoSizer } from 'react-virtualized';
import { Tooltip } from '@mui/material';
import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';
import { Action } from '_helpers';
import { sortBy } from 'GeminiViewerComponent/_helpers/lodashUtils';

export default function CardView({
    assets,
    pageInfo,
    searchString,
    isLoadingAssets,
    assetType,
    assetPayload,
    readOnly,
    headerDisplay = true,
    accordianGrid = false,
    categoryId = null,
    setFilteredAssets = () => {},
}) {
    const dispatch = useDispatch();
    const rowHeight = 240;

    const [selected, setSelected] = useState([]);
    const [selectedAll, setSelectedAll] = useState(false);
    const [cardWrapperHeight, setCardWrapperHeight] = useState(null);

    /* selection start */
    const isSelected = (id) => selected.indexOf(id) !== -1;

    const prevSelectedAll = usePrevious(selectedAll);
    if (prevSelectedAll && selectedAll) {
        if (selected.length !== assets.length) {
            setSelected(assets.map((row) => row.asset_id));
            setSelectedAll(true);
        }
    }

    const handleClick = (event, asset_id) => {
        event.stopPropagation();
        const selectedIndex = selected.indexOf(asset_id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, asset_id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
        setSelectedAll(selected.length === assets.length);
    };
    const allRows = useSelector(selectAllAssets);
    const loadedRowsMap = useSelector((state) => state.assets.loadedRowsMap);

    const parentRef = useRef(null);

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    return (
        !isLoadingAssets && (
            <>
                {selected.length > 0 && !readOnly && (
                    <EnhancedTableToolbar
                        numSelected={selected.length}
                        totalCount={pageInfo.TotalCount}
                        toolTipTitle={'Publish'}
                    >
                        <Tooltip title="Publish">
                            <PopupAction
                                action={Action.PublishArray}
                                object={selected}
                                level={'asset'}
                                showLabel={false}
                            />
                        </Tooltip>
                    </EnhancedTableToolbar>
                )}
                <div
                    style={{
                        marginTop: `${
                            !headerDisplay
                                ? '0px'
                                : selected.length > 0 && !readOnly
                                ? 0
                                : '60px'
                        }`,
                    }}
                    ref={parentRef}
                >
                    <div
                        style={{
                            height: `${
                                cardWrapperHeight
                                    ? cardWrapperHeight + 'px'
                                    : `calc(100vh - 310px ${
                                          !headerDisplay
                                              ? selected.length > 0
                                                  ? '+ 0px'
                                                  : '+ 60px'
                                              : '+ 0px'
                                      })`
                            }`,
                        }}
                    >
                        <AutoSizer>
                            {({ width, height }) => (
                                <VirtualGrid
                                    initialRows={assets}
                                    allRows={allRows}
                                    totalRowCount={pageInfo.TotalCount}
                                    loadedRowsMap={loadedRowsMap}
                                    loadMoreRows={loadMoreRows}
                                    width={width}
                                    height={height}
                                    rowHeight={rowHeight}
                                    cellRenderer={cellRenderer}
                                    parentRef={parentRef}
                                    style={{
                                        overflowX: 'hidden',
                                    }}
                                    accordianGrid={accordianGrid}
                                    setCardWrapperHeight={setCardWrapperHeight}
                                />
                            )}
                        </AutoSizer>
                    </div>
                </div>
            </>
        )
    );

    async function loadMoreRows({ startIndex, stopIndex }) {
        const updatedStopIndex = stopIndex < 14 ? 14 : stopIndex;
        if (categoryId || categoryId === 0) {
            const loadedAssets = new Map();
            const moreCategoriesAssets = await dispatch(
                fetchMoreAssets({
                    startIndex: startIndex,
                    stopIndex: updatedStopIndex,
                    searchString: searchString,
                    assetCategoryId: categoryId,
                    ...assetPayload,
                })
            );

            if (
                moreCategoriesAssets.payload?.assets &&
                moreCategoriesAssets.payload?.assets?.length > 0
            ) {
                if (startIndex === 0) {
                    setFilteredAssets([...moreCategoriesAssets.payload.assets]);
                } else {
                    // setFilteredAssets((prev) => [
                    //     ...prev,
                    //     ...moreCategoriesAssets.payload.assets,
                    // ]);
                    moreCategoriesAssets.payload.assets.map((e) =>
                        loadedAssets.set(e.asset_id, e)
                    );
                    setFilteredAssets((prev) => {
                        [
                            ...prev,
                            ...moreCategoriesAssets.payload.assets,
                        ].forEach((e) => loadedAssets.set(e.asset_id, e));
                        return [
                            ...Array.from(loadedAssets.values())
                                .sort(sortBy('display_name', 'DESC'))
                                .sort(sortBy('is_pinned', 'ASC')),
                        ];
                    });
                }
            } else {
                setFilteredAssets([]);
            }
        } else {
            dispatch(
                fetchMoreAssets({
                    startIndex: startIndex,
                    stopIndex: stopIndex,
                    searchString: searchString,
                    ...assetPayload,
                })
            );
        }
    }

    function cellRenderer(gridItem, key, style, columnWidth) {
        if (gridItem == null) {
            return null;
        }
        return (
            <div style={style} key={key}>
                <AssetCard
                    asset={gridItem}
                    width={columnWidth - 20}
                    assetType={assetType}
                    handleClick={handleClick}
                    content={gridItem}
                    cellLevel={'asset'}
                    selected={selected}
                    isSelected={isSelected}
                    readOnly={readOnly}
                />
            </div>
        );
    }
}
