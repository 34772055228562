import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    treeData: {},
    currentNode: {},
    rootNode: {},
    treeReverse: [],
    traversal: [],
};

const decisionTreeSlice = createSlice({
    name: 'tree',
    initialState,
    reducers: {
        setTreeData: (state, action) => {
            state.treeData = action.payload;
            state.rootNode = state.treeData.rootNode;
            state.currentNode = state.treeData.nodes[state.treeData.rootNode];
            state.traversal = [state.treeData.rootNode];
        },
        setCurrentNode: (state, action) => {
            return {
                ...state,
                currentNode: state.treeData.nodes[action.payload.node_id],
                traversal: [...state.traversal, action.payload.node_id],
            };
        },
        addReversal: (state, action) => {
            state.treeReverse.push(action.payload.node_id);
        },
        removeReversal: (state, action) => {
            state.treeReverse.pop();
        },
        resetTree: (state, action) => {
            state.currentNode = state.treeData.nodes[state.treeData.rootNode];
        },
    },
    extraReducers: {},
});

export const getCurrentNode = (state) => state.tree.currentNode;
export const getPreviousNode = (state) =>
    state.tree.treeReverse[state.tree.treeReverse.length - 1];

export const {
    setTreeData,
    addReversal,
    removeReversal,
    setCurrentNode,
    resetTree,
} = decisionTreeSlice.actions;

export default decisionTreeSlice.reducer;
