import { makeStyles } from '@mui/styles';

const dtSingleChoicePictureStyles = makeStyles(() => ({
    dtSingleChoicePicture: {
        backgroundColor: '#f7f7f7',
        width: '100%',
        margin: '0PX',
    },
}));

export { dtSingleChoicePictureStyles };
