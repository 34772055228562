import React, { useState, useCallback, useEffect } from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { RadioGroup, FormControlLabel, Radio, Box } from '@mui/material';

import { accountsSlice } from 'app/store';
import { makeFormStyles } from '../styles';
import SetDirtyForm from 'forms/SetDirtyForm';
import { LinkTypes, ContentTypes } from '_helpers';
import { FormTextField } from 'components/TextField';
import { useTinyMCEInit } from 'hooks/useTinyMCEInit';
import { useConfigValues } from 'hooks/useConfigValues';
import { TinyMCEEditor } from 'components/TinyMCEEditor';
import { ImageResizer } from 'forms/ZoneForm/ImageResizer';
import { getProcedureById } from '_features/procedures/proceduresSlice';
import { ContentVersionFormField } from './components/ContentVersionFormField';
import { blobToBase64, handleFileExtension } from 'hooks/useImageUploadHelper';
import { snackbarHandler } from 'GeminiViewerComponent/_helpers/snackbar-handler';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { ProcedureStartEndNodeField } from './components/ProcedureStartEndNodeField';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { SelectionAutocomplete } from 'components/_Autocomplete/SelectionAutocomplete';
import {
    validChapterTime,
    validateContentVersionForm,
} from 'forms/ContentForm/_helpers/contentFormValidations';
import {
    selectConfigValuesAssetId,
    selectConfigValuesByFields,
} from '_features/configValues/configValuesSlice';
import {
    createContentVersion,
    resetContentStatus,
    getContentById,
} from '_features/content/contentSlice';
import {
    setProgress,
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    getProceduresPicklist,
    selectProceduresPicklist,
    selectProceduresPicklistLoadingStatus,
} from '_features/picklists/pickListsSlice';

const ContentVersionForm = ({ contentId }) => {
    const dispatch = useDispatch();
    const tinyMCEInit = useTinyMCEInit();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);
    const init = {
        content_data: null,
        url: '',
        content_id: contentId,
        content_type_id: '',
        toggleFormType: 'url',
        embed_data: '',
        procedure_id: '',
        overlay_text: null,
        img_name: null,
        transcribe_videos: false,
        transcribe_language: '',
        caption_files: [],
        caption_languages: [],
    };
    const [initValues, setInitValues] = useState({ ...init });
    const content = useSelector((state) => getContentById(state, contentId));
    const activeUser = useSelector(accountsSlice.selectActiveUser);
    const selectedAssetId = useSelector(selectConfigValuesAssetId);
    const [imageSettings, setImageSettings] = useState({
        convert_image_to_jpeg: 'false',
        image_compression_percent: '4',
        max_image_width: 'None',
    });
    const [isVideoCompressionEnable, setIsVideoCompressionEnable] =
        useState(false);
    const readOnlyMode = activeUser?.role === 'User' ? true : false;
    const { configValues, configFields, validationTypes } = useConfigValues({
        clientId: activeUser?.client_id,
        assetId: selectedAssetId,
    });
    const [procedureStartEndNode, setProcedureStartEndNode] = useState({
        procedureStartNode: null,
        procedureEndNode: null,
    });

    useEffect(() => {
        const updateImageSettings = async () => {
            const updatedResponse = selectConfigValuesByFields(
                configValues,
                configFields,
                [
                    'image_compression_percent',
                    'max_image_width',
                    'convert_image_to_jpeg',
                    'compress_videos',
                ]
            );
            if (updatedResponse) {
                setIsVideoCompressionEnable(
                    updatedResponse?.['compress_videos'] === 'true'
                        ? true
                        : false
                );
                delete updatedResponse?.['compress_videos'];
                setImageSettings(updatedResponse);
            }
        };
        updateImageSettings();
    }, [configValues]);

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        dispatch(activateLoading({ showProgress: true }));
        var resultAction = null;

        dispatch(
            setProgress({
                progress: 0,
                label: 'Step 1 of 2: Uploading',
                noAnimation: true,
            })
        );
        let contentVersionsParams;
        if (content?.default_link_type_id === LinkTypes.Image.id) {
            if (values.toggleFormType === 'file' && values?.raw_file) {
                values = await compressImages({
                    file: values.raw_file,
                    mode: 'submit',
                    values: values,
                });
            }
            contentVersionsParams = {
                ...contentVersionsParams,
                overlay_text: values.overlay_text,
            };
        }
        contentVersionsParams = {
            transcribe_videos: values.transcribe_videos,
            transcribe_language: values.transcribe_language,
            caption_languages: values.caption_languages,
        };

        if (content?.default_link_type_id === LinkTypes.Embed.id) {
            contentVersionsParams = {
                embed_data: values.embed_data,
                content_type_id: values.content_type_id,
                content_version_type: 3,
            };
        } else if (content?.default_link_type_id === LinkTypes.Procedure.id) {
            contentVersionsParams = {
                procedure_id: values.procedure_id,
                content_type_id: values.content_type_id,
                content_version_type: 4,
                procedure_start_node: values?.procedure_start_node,
                procedure_end_node: values?.procedure_end_node,
            };
        } else {
            if (values.toggleFormType === 'url') {
                contentVersionsParams = {
                    url: values.url,
                    content_type_id: values.content_type_id,
                    content_version_type: 1,
                };
            }
            if (values.toggleFormType === 'file') {
                contentVersionsParams = {
                    content_data: values.content_data,
                    content_type_id: values.content_type_id,
                    content_version_type: 2,
                };
            }
            if (content?.default_link_type_id === LinkTypes.Video.id) {
                contentVersionsParams = {
                    ...contentVersionsParams,
                    chapters: await validChapterTime(
                        values?.chapters,
                        values?.videoDuration
                    ),
                };
            }
        }
        if (
            content?.default_link_type_id === LinkTypes.Video.id &&
            values.toggleFormType === 'file'
        ) {
            if (
                !(
                    `${values?.compress_videos}` ===
                        values?.defaultConfigValues.compress_videos.value &&
                    `${values?.enable_hls}` ===
                        values?.defaultConfigValues.enable_hls.value &&
                    values?.max_video_height ===
                        values?.defaultConfigValues.max_video_height.value
                )
            ) {
                contentVersionsParams = {
                    ...contentVersionsParams,
                    compress_videos: `${values?.compress_videos}`,
                    enable_hls: `${values?.enable_hls}`,
                    max_video_height: values?.max_video_height,
                };
            }
            contentVersionsParams = {
                ...contentVersionsParams,
                transcribe_videos: values?.transcribe_videos,
                transcribe_language: values?.transcribe_language,
            };
            if (
                Array.isArray(values?.caption_files) &&
                Array.isArray(values?.caption_languages) &&
                values?.caption_files?.length > 0 &&
                values?.caption_languages?.length > 0 &&
                values?.caption_languages?.length ===
                    values?.caption_files?.length
            ) {
                contentVersionsParams = {
                    ...contentVersionsParams,
                    caption_files: values?.caption_files,
                    caption_languages: values?.caption_languages,
                };
            }
        }
        resultAction = await dispatch(
            createContentVersion({
                ...contentVersionsParams,
                content_id: contentId,
                dispatch,
            })
        );
        if (!resultAction.error) {
            resetForm();
        }
        dispatch(resetContentStatus());

        dispatch(deactivateLoading());
        const { message, variant } = snackbarHandler(
            resultAction.meta.requestStatus,
            'Create'
        );
        enqueueSnackbar(message, {
            action: (key) => <SnackbarDismiss key={key} />,
            variant: variant,
        });

        setSubmitting(false);
    };

    const handleToggle = (formValues, value) => {
        setInitValues({ ...formValues, toggleFormType: value });
    };

    const getContentTypes = (linkTypeId) => {
        var linkType = Object.keys(LinkTypes).find((lt) => {
            return LinkTypes[lt]?.id === linkTypeId;
        });
        var contentTypes = LinkTypes[linkType]?.contentTypes;
        return contentTypes || [];
    };

    const getContentMaxSize = (linkTypeId) => {
        var linkType = Object.keys(LinkTypes).find((lt) => {
            return LinkTypes[lt]?.id === linkTypeId;
        });
        return LinkTypes[linkType]?.fileSizeLimit || 104715200;
    };

    const compressImages = useCallback(
        async ({
            file,
            mode = 'drop',
            setFieldValue = null,
            values = null,
        }) => {
            let updatedUrl = '';
            let previewFile = null;
            let imageResizer = new ImageResizer(file);
            let updateValues = values;
            if (imageSettings?.['convert_image_to_jpeg'] === 'true') {
                let compressPercent =
                    +imageSettings?.['image_compression_percent'] / 5;
                previewFile = await imageResizer.run(
                    +imageSettings?.['max_image_width'],
                    0,
                    compressPercent
                );
            } else {
                previewFile = await imageResizer.run(
                    +imageSettings?.['max_image_width'],
                    0,
                    null
                );
            }
            updatedUrl = await blobToBase64(previewFile);
            if (mode === 'drop' && setFieldValue) {
                setFieldValue('image_url', updatedUrl);
            }
            if (mode === 'submit' && values) {
                updateValues = { ...updateValues, image_url: updatedUrl };
            }
            if (mode === 'drop' && setFieldValue) {
                setFieldValue('content_data', previewFile);
                setFieldValue('img_name', file.name);
                handleFileExtension(file, setFieldValue);
            }
            if (mode === 'submit' && values) {
                updateValues = {
                    ...updateValues,
                    content_data: previewFile,
                };
                return updateValues;
            }
            return null;
        },
        [imageSettings]
    );

    const generateVideoThumbnail = (file) => {
        return new Promise((resolve) => {
            const canvas = document.createElement('canvas');
            const video = document.createElement('video');

            video.autoplay = true;
            video.muted = true;
            video.src = URL.createObjectURL(file) + '#t=0.5';
            video.onloadeddata = () => {
                let ctx = canvas.getContext('2d');

                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;

                ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
                video.pause();
                return resolve(canvas.toDataURL('image/png'));
            };
        });
    };

    const onDrop = useCallback(
        async ({ file, setFieldValue }) => {
            if (content?.default_link_type_id === LinkTypes.Image.id) {
                setFieldValue('raw_file', file);
                await compressImages({
                    file: file,
                    mode: 'drop',
                    setFieldValue: setFieldValue,
                });
            } else {
                const reader = new FileReader();

                reader.onabort = () => console.log('file reading was aborted');
                reader.onerror = () => console.log('file reading has failed');
                reader.onload = async (e) => {
                    setFieldValue('content_data', file);
                    setFieldValue('img_name', file?.name);
                    handleFileExtension(file, setFieldValue);
                    if (content?.default_link_type_id === LinkTypes.Video.id) {
                        const re = /(?:\.([^.]+))?$/;
                        const extension = re.exec(file?.name)[1];
                        if (
                            ContentTypes.Mov.extension.endsWith(extension) ||
                            ContentTypes.Flv.extension.endsWith(extension) ||
                            ContentTypes.Avi.extension.endsWith(extension)
                        ) {
                            setFieldValue('image_url', 'no_video_preview.svg');
                        } else {
                            setFieldValue(
                                'image_url',
                                await generateVideoThumbnail(file)
                            );
                        }
                    } else {
                        setFieldValue('image_url', e.target.result);
                    }
                };
                reader.readAsDataURL(file);
            }
        },
        [imageSettings, configValues]
    );

    const onDropError = useCallback(
        ({ err, setFieldError, setFieldTouched }) => {
            if (err?.[0]?.errors?.[0]?.message) {
                setFieldError('content_data', err[0].errors[0].message);
                setFieldTouched('content_data', true, false);
            }
        },
        []
    );

    const validateVideoCompression = useCallback(
        (file, link_type_id) => {
            const re = /(?:\.([^.]+))?$/;
            const extension = re.exec(file?.name)[1];
            let ext = extension.replace(/^.{1}/g, extension[0]);
            ext = ext ? ext.toLowerCase() : '';
            if (
                link_type_id === LinkTypes.Video.id &&
                !isVideoCompressionEnable &&
                ext !== 'mp4'
            ) {
                return [
                    {
                        code: 'allow-video-compression',
                        message:
                            'Enable video compression for uploading .webm, .mov, .flv, .avi formats',
                    },
                ];
            }
            return null;
        },
        [isVideoCompressionEnable]
    );

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{ ...initValues }}
            validationSchema={Yup.object({
                ...(content?.default_link_type_id !== LinkTypes.Embed.id &&
                    content?.default_link_type_id !== LinkTypes.Procedure.id &&
                    initValues.toggleFormType === 'url' && {
                        content_type_id: Yup.string().required('Required'),
                        url: Yup.string().required('Required'),
                        embed_data: Yup.string(),
                    }),
                ...(content?.default_link_type_id === LinkTypes.Embed.id && {
                    embed_data: Yup.string().required('Required'),
                    content_type_id: Yup.string().required('Required'),
                }),
                ...(content?.default_link_type_id ===
                    LinkTypes.Procedure.id && {
                    procedure_id: Yup.string().required('Required'),
                    content_type_id: Yup.string().required('Required'),
                }),
                ...(content?.default_link_type_id !== LinkTypes.Embed.id &&
                    content?.default_link_type_id !== LinkTypes.Procedure.id &&
                    initValues.toggleFormType === 'file' && {
                        content_data: Yup.mixed()
                            .nullable(false)
                            .required('Required'),
                        embed_data: Yup.string(),
                    }),
            })}
            validate={(values) =>
                validateContentVersionForm(
                    values,
                    content?.default_link_type_id
                )
            }
            onSubmit={(values, { setSubmitting, resetForm }) => {
                handleSubmit(values, setSubmitting, resetForm);
            }}
        >
            {({
                values,
                isSubmitting,
                setFieldError,
                setFieldTouched,
                setFieldValue,
            }) => (
                <Form className={classes.form}>
                    <>
                        {content?.default_link_type_id !== LinkTypes.Embed.id &&
                            content?.default_link_type_id !==
                                LinkTypes.Procedure.id && (
                                <>
                                    <RadioGroup
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            minWidth: '150px',
                                        }}
                                        name="type"
                                        value={values.toggleFormType}
                                        onChange={(e) =>
                                            handleToggle(values, e.target.value)
                                        }
                                    >
                                        <FormControlLabel
                                            value="url"
                                            control={<Radio color="primary" />}
                                            label="URL"
                                        />
                                        <FormControlLabel
                                            value="file"
                                            control={<Radio color="primary" />}
                                            label="File"
                                        />
                                    </RadioGroup>
                                    {values.toggleFormType === 'url' && (
                                        <>
                                            <FormTextField
                                                label="Content URL"
                                                name="url"
                                                placeholder="Content URL"
                                            />
                                            <Field
                                                className={classes.input}
                                                as="select"
                                                name="content_type_id"
                                            >
                                                <option
                                                    value=""
                                                    defaultValue
                                                    disabled
                                                >
                                                    Select Url Type
                                                </option>
                                                {getContentTypes(
                                                    Number(
                                                        content?.default_link_type_id
                                                    )
                                                ).map((at) => {
                                                    return (
                                                        <option
                                                            key={at.label}
                                                            value={at.id}
                                                            defaultValue={
                                                                at.id === 1
                                                            }
                                                        >
                                                            {at.label}
                                                        </option>
                                                    );
                                                })}
                                            </Field>
                                            <ErrorMessage
                                                className={classes.inputErr}
                                                name="content_type_id"
                                                component="div"
                                            />
                                        </>
                                    )}
                                    {content?.default_link_type_id !== '' && (
                                        <ContentVersionFormField
                                            contentt={content}
                                            contentId={contentId}
                                            readOnlyMode={readOnlyMode}
                                            values={values}
                                            getContentTypes={getContentTypes}
                                            // getDefaultContentTypeId={
                                            //     getDefaultContentTypeId
                                            // }
                                            // handleVersionChange={
                                            //     handleVersionChange
                                            // }
                                            handleToggle={handleToggle}
                                            getContentMaxSize={
                                                getContentMaxSize
                                            }
                                            onDrop={onDrop}
                                            onDropError={onDropError}
                                            validateVideoCompression={
                                                validateVideoCompression
                                            }
                                            // handleViewLink={handleViewLink}
                                            // handleCreateContentClick={
                                            //     handleCreateContentClick
                                            // }
                                            setFieldError={setFieldError}
                                            setFieldTouched={setFieldTouched}
                                            setFieldValue={setFieldValue}
                                            imageSettings={imageSettings}
                                            setImageSettings={setImageSettings}
                                            setIsVideoCompressionEnable={
                                                setIsVideoCompressionEnable
                                            }
                                            configValues={configValues}
                                            configFields={configFields}
                                            validationTypes={validationTypes}
                                        />
                                    )}
                                </>
                            )}
                        {content?.default_link_type_id ===
                            LinkTypes.Embed.id && (
                            <>
                                <Field
                                    margin="dense"
                                    as="textarea"
                                    variant="outlined"
                                    placeholder="Embed Data"
                                    className={`${classes.materialTextarea}`}
                                    name="embed_data"
                                    rows={5}
                                    style={{ marginTop: '10px' }}
                                />
                                <ErrorMessage
                                    className={classes.inputErr}
                                    name="embed_data"
                                    component="div"
                                />
                                <Field
                                    className={classes.input}
                                    as="select"
                                    name="content_type_id"
                                >
                                    <option value="" defaultValue disabled>
                                        Select Url Type
                                    </option>
                                    {getContentTypes(
                                        Number(content?.default_link_type_id)
                                    ).map((at) => {
                                        return (
                                            <option
                                                key={at.label}
                                                value={at.id}
                                                defaultValue={at.id === 1}
                                            >
                                                {at.label}
                                            </option>
                                        );
                                    })}
                                </Field>
                                <ErrorMessage
                                    className={classes.inputErr}
                                    name="content_type_id"
                                    component="div"
                                />
                            </>
                        )}
                        {content?.default_link_type_id ===
                            LinkTypes.Procedure.id && (
                            <>
                                <Field
                                    className={classes.input}
                                    name="procedure_id"
                                >
                                    {({
                                        field: { value },
                                        form: { setFieldValue },
                                    }) => (
                                        <SelectionAutocomplete
                                            title="Procedure"
                                            placeholder="Procedure"
                                            keyProperty="procedure_id"
                                            nameProperty="display_name"
                                            entityIds={value}
                                            setEntityIds={setFieldValue}
                                            entitySelector={
                                                selectProceduresPicklist
                                            }
                                            fetchEntityPage={
                                                getProceduresPicklist
                                            }
                                            formField={'procedure_id'}
                                            multiSelection={false}
                                            entityStatusSelector={
                                                selectProceduresPicklistLoadingStatus
                                            }
                                            onCustomChange={async (
                                                newValue
                                            ) => {
                                                setFieldValue(
                                                    'procedure_start_node',
                                                    null
                                                );
                                                setFieldValue(
                                                    'procedure_end_node',
                                                    null
                                                );
                                                setProcedureStartEndNode({
                                                    procedureStartNode: null,
                                                    procedureEndNode: null,
                                                });
                                                await dispatch(
                                                    getProcedureById(
                                                        newValue?.procedure_id
                                                    )
                                                );
                                            }}
                                        />
                                    )}
                                </Field>
                                <ErrorMessage
                                    className={classes.inputErr}
                                    name="procedure_id"
                                    component="div"
                                />
                                <ProcedureStartEndNodeField
                                    procedureStartEndNode={
                                        procedureStartEndNode
                                    }
                                    setProcedureStartEndNode={
                                        setProcedureStartEndNode
                                    }
                                />
                                <Field
                                    className={classes.input}
                                    as="select"
                                    name="content_type_id"
                                >
                                    <option value="" defaultValue disabled>
                                        Select Url Type
                                    </option>
                                    {getContentTypes(
                                        Number(LinkTypes.Procedure.id)
                                    ).map((at) => {
                                        return (
                                            <option
                                                key={at.label}
                                                value={at.id}
                                                defaultValue={at.id === 1}
                                            >
                                                {at.label}
                                            </option>
                                        );
                                    })}
                                </Field>
                                <ErrorMessage
                                    className={classes.inputErr}
                                    name="content_type_id"
                                    component="div"
                                />
                            </>
                        )}
                        {content?.default_link_type_id ===
                            LinkTypes.Image.id && (
                            <>
                                <Box mt="12px" />
                                <Field name="overlay_text">
                                    {({ field, form: { setFieldValue } }) => (
                                        <TinyMCEEditor
                                            id="overlay_text"
                                            tinymceScriptSrc={
                                                process.env.PUBLIC_URL +
                                                '/tinymce/tinymce.min.js'
                                            }
                                            init={{
                                                ...tinyMCEInit,
                                            }}
                                            textareaName="overlay_text"
                                            onBlur={field.onBlur}
                                            onEditorChange={(content) => {
                                                setFieldValue('overlay_text', {
                                                    html: content,
                                                });
                                            }}
                                            value={
                                                values?.overlay_text?.html ?? ''
                                            }
                                        />
                                    )}
                                </Field>
                            </>
                        )}
                    </>
                    <SetDirtyForm />
                    <button
                        className={classes.submit}
                        type="submit"
                        disabled={isSubmitting}
                    >
                        Submit
                    </button>
                </Form>
            )}
        </Formik>
    );
};

export { ContentVersionForm };
