// eslint-disable-next-line
import React, { createElement } from 'react';
import { DecisionOption } from '../DecisionOption';
import { dtSingleChoiceStyles } from './styles.jsx';

const SingleChoice = ({ nodeOptions }) => {
    const classes = dtSingleChoiceStyles();

    return (
        <div className={classes.dtSingleChoice}>
            {nodeOptions.map((option) => {
                return (
                    <DecisionOption option={option} key={option.button_id} />
                );
            })}
        </div>
    );
};

export { SingleChoice };
