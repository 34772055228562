import React from 'react';
import { Box, Checkbox } from '@mui/material';

const MediaReferenceField = ({ value, setFieldValue }) => {
    return (
        <Box>
            <Checkbox
                checked={Boolean(value)}
                onClick={() => {
                    setFieldValue('media_reference', !value);
                }}
            />
            Allow Media References
        </Box>
    );
};

export default MediaReferenceField;
