import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { companionAppsService } from 'GeminiViewerComponent/_features/services/companionApp.service';

const initialState = {
    loadingStatus: LoadingStatus.Idle,
    AllCompanionApps: [],
    CompanionApp: {},
    loadedRowsMap: {},
    pageInfo: {},
    companionUsage: [],
    error: null,
};

export const fetchAllComapanionApps = createAsyncThunk(
    'companionApps/fetchAllComapanionApps',
    async (params) => {
        const response = await companionAppsService.getAll(
            params.searchString,
            params.sort
        );
        return response;
    }
);

export const fetchCompanionAppById = createAsyncThunk(
    'companionApps/fetchCompanionAppById',
    async (params) => {
        const response = await companionAppsService.getById(params);
        return response;
    }
);

export const createCompanionApp = createAsyncThunk(
    'companionApps/createCompanionApp',
    async (params) => {
        const response = await companionAppsService.create(params);
        return response;
    }
);

export const editCompanionApp = createAsyncThunk(
    'companionApps/editCompanionApp',
    async (params) => {
        const response = await companionAppsService.update(
            params.companion_app_id,
            params
        );
        return response;
    }
);

export const deleteCompanionApp = createAsyncThunk(
    'companionApps/deleteCompanionApp',
    async (params) => {
        const response = await companionAppsService.delete(params);
        return response;
    }
);

export const deleteCompanionApps = createAsyncThunk(
    'companionApps/deleteCompanionApps',
    async (params) => {
        const response = await companionAppsService.deleteArray(params);
        return response;
    }
);

export const fetchCompanionAppUsageById = createAsyncThunk(
    'companionApps/fetchCompanionAppUsageById',
    async (params) => {
        const response = await companionAppsService.fetchUsageById(params);
        return response;
    }
);

const companionAppsSlice = createSlice({
    name: 'companionApps',
    initialState,
    reducers: {
        resetCompanionAppStatus: (state) => initialState,
        setCompanionApps: (state, action) => {
            state.AllCompanionApps = action.payload;
        },
        setCompanionApp: (state, action) => {
            state.CompanionApp = action.payload;
        },
    },
    extraReducers: {
        [fetchAllComapanionApps.fulfilled]: (state, action) => {
            state.loadingStatus = LoadingStatus.Loaded;
            state.AllCompanionApps = action.payload.companionApps;
            state.pageInfo = action.payload.pageInfo;
        },
        [fetchAllComapanionApps.rejected]: (state, action) => {
            state.error = action.error.message;
        },
        [fetchCompanionAppById.fulfilled]: (state, action) => {
            state.loadingStatus = LoadingStatus.Loaded;
            state.CompanionApp = action.payload;
        },
        [fetchCompanionAppById.rejected]: (state, action) => {
            state.error = action.error.message;
        },
        [createCompanionApp.fulfilled]: (state, action) => {
            state.loadingStatus = LoadingStatus.Idle;
        },
        [createCompanionApp.rejected]: (state, action) => {
            state.error = action.error.message;
        },
        [editCompanionApp.fulfilled]: (state, action) => {
            state.loadingStatus = LoadingStatus.Idle;
        },
        [editCompanionApp.rejected]: (state, action) => {
            state.error = action.error.message;
        },
        [deleteCompanionApp.fulfilled]: (state, action) => {
            state.loadingStatus = LoadingStatus.Idle;
        },
        [deleteCompanionApp.rejected]: (state, action) => {
            state.error = action.error.message;
        },
        [deleteCompanionApps.fulfilled]: (state, action) => {
            state.loadingStatus = LoadingStatus.Idle;
        },
        [deleteCompanionApps.rejected]: (state, action) => {
            state.error = action.error.message;
        },
        [fetchCompanionAppUsageById.fulfilled]: (state, action) => {
            state.loadingStatus = LoadingStatus.Loaded;
            state.companionUsage = action.payload;
        },
        [fetchCompanionAppUsageById.rejected]: (state, action) => {
            state.error = action.error.message;
        },
    },
});

export const getCompanionLoadingStatus = (state) =>
    state.companionApps.loadingStatus;
export const getAllCompanionApps = (state) =>
    state.companionApps.AllCompanionApps;
export const getCompanionApp = (state) => state.companionApps.CompanionApp;
export const getCompanionPageInfo = (state) => state.companionApps.pageInfo;
export const getCompanionAppUsage = (state) =>
    state.companionApps.companionUsage;

export const { resetCompanionAppStatus, setCompanionApps, setCompanionApp } =
    companionAppsSlice.actions;

export default companionAppsSlice.reducer;
