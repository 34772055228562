import { MenuItem, Select } from '@mui/material';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setIsDirty } from '_features/common/formSlice';
import {
    selectConfigValuesAssetId,
    selectConfigValuesClientId,
    setConfigValuesAssetId,
} from '_features/configValues/configValuesSlice';
import {
    getClientAssetsPicklist,
    selectClientAssetsPicklist,
} from '_features/picklists/pickListsSlice';

export const AssetSelect = () => {
    const assets = useSelector(selectClientAssetsPicklist);
    const selectedClientId = useSelector(selectConfigValuesClientId);
    const selectedAssetId = useSelector(selectConfigValuesAssetId);
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedClientId !== 0) {
            dispatch(getClientAssetsPicklist(selectedClientId));
        }
    }, [selectedClientId]);

    const assetsWithDefault = [
        { asset_id: 0, display_name: '--ASSET OVERRIDE--' },
        ...assets,
    ];

    return (
        <Select
            size="small"
            labelId="asset-select-label"
            id="asset-select"
            style={{ minWidth: '300px' }}
            value={selectedAssetId}
            onChange={async (e) => {
                await dispatch(
                    setConfigValuesAssetId({
                        assetId: e.target.value,
                        clientId: selectedClientId,
                    })
                );
                await dispatch(setIsDirty(false));
            }}
        >
            {assetsWithDefault.map((asset) => (
                <MenuItem key={asset.asset_id} value={asset.asset_id}>
                    {asset.display_name}
                </MenuItem>
            ))}
        </Select>
    );
};
