import { updateProcedureSession } from 'GeminiViewerComponent/_features/procedureSession/procedureSessionSlice';
import { ProcedureSessionStatus } from 'GeminiViewerComponent/_helpers';

export const useStoreSessionData = async (
    dispatch,
    activeTree,
    userData,
    getSessionData,
    completionNode = false
) => {
    if (activeTree.save_sessions !== true || !getSessionData) return;

    const SessionData = {
        procedure_session_id: getSessionData?.procedure_session_id,
    };

    let inspectioPoint = [];
    Object.entries(userData).map((inspectionField) => {
        if (
            inspectionField?.[1]?.field_id ||
            inspectionField?.[1]?.action ||
            inspectionField?.[1]?.reason
        ) {
            inspectioPoint.push(inspectionField[1]);
        }
    });
    const PrimaryTagData = userData?.[activeTree?.primary_tag_variable?.name];
    const SecondaryTagData =
        userData?.[activeTree?.secondary_tag_variable?.name];
    const Data = userData;

    if (inspectioPoint && inspectioPoint.length > 0) {
        SessionData.inspection_points = inspectioPoint;
    }
    if (PrimaryTagData) SessionData.primary_tag = PrimaryTagData;
    if (SecondaryTagData) SessionData.secondary_tag = SecondaryTagData;
    if (Data) SessionData.data = JSON.stringify(Data);
    if (completionNode === true)
        SessionData.status = ProcedureSessionStatus.Submitted.id;

    await dispatch(updateProcedureSession(SessionData));
};
