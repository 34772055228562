const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

import { newsFlashService } from '../services/newsFlash.service';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';

const initialState = {
    allNewsFlashData: [],
    newsFlashData: null,
    newsFlashStatus: LoadingStatus.Idle,
    newsFlashDisplayOptionStatus: LoadingStatus.Idle,
    errors: null,
    newsFlashPageInfo: {},
    newsFlashLoadedRowsMap: {},
    newsFlashDisplayOptions: [],
};

export const fetchAllNewsFlashPage = createAsyncThunk(
    'newsFlash/fetchAllNewsFlashPage',
    async () => await newsFlashService.getAll()
);

export const fetchAllNewsFlash = createAsyncThunk(
    'newsFlash/fetchAllNewsFlash',
    async (params) =>
        await newsFlashService.getAllByRange(
            params.startIndex ?? 0,
            params.stopIndex ?? 0,
            params.searchString,
            params.sort,
            params.startDate,
            params.endDate,
            params.displayOptionId,
            params.activeOnly
        )
);

export const fetchNewsFlashById = createAsyncThunk(
    'newsFlash/fetchNewsFlashById',
    async (params) => await newsFlashService.getById(params)
);

export const fetchNewsFlashDisplayOptions = createAsyncThunk(
    'newsFlash/fetchNewsFlashDisplayOptions',
    async () => await newsFlashService.getDisplayOptions()
);

export const createNewsFlash = createAsyncThunk(
    'newsFlash/createNewsFlash',
    async (params) => await newsFlashService.create(params)
);

export const updateNewsFlash = createAsyncThunk(
    'newsFlash/updateNewsFlash',
    async (params) =>
        await newsFlashService.update(params?.news_flash_id, params)
);

export const deleteNewsFlash = createAsyncThunk(
    'newsFlash/deleteNewsFlash',
    async (params) => await newsFlashService.delete(params)
);

export const deleteNewsFlashArray = createAsyncThunk(
    'newsFlash/deleteNewsFlashArray',
    async (params) => await newsFlashService.deleteArray(params)
);

const newsFlashSlice = createSlice({
    name: 'newsFlash',
    initialState,
    reducers: {
        resetNewsFlashData: (state) => initialState,
        setNewsFlashStatus: (state, action) => {
            state.newsFlashStatus = action.payload;
        },
        resetNewsFlashStatus: (state) => {
            state.newsFlashStatus = LoadingStatus.Idle;
        },
    },
    extraReducers: {
        [fetchAllNewsFlashPage.pending]: (state) => {
            state.newsFlashStatus = LoadingStatus.Loading;
        },
        [fetchAllNewsFlashPage.fulfilled]: (state, action) => {
            state.newsFlashStatus = LoadingStatus.Loaded;
            state.allNewsFlashData = action.payload;
        },
        [fetchAllNewsFlash.pending]: (state) => {
            state.newsFlashStatus = LoadingStatus.Loading;
        },
        [fetchAllNewsFlash.fulfilled]: (state, action) => {
            state.newsFlashStatus = LoadingStatus.Loaded;
            state.newsFlashPageInfo = action.payload.pageInfo;

            if (action.meta.arg.reset) {
                state.newsFlashLoadedRowsMap = {};
            }

            const startIndex = action.meta.arg.startIndex;
            const stopIndex =
                startIndex + action.payload.newsFlashData.length - 1;

            for (var i = startIndex; i <= stopIndex; i++) {
                state.newsFlashLoadedRowsMap[i] = LoadingStatus.Loaded;
            }

            // In case we didn't load as many as requested
            for (
                let j = stopIndex + 1;
                j <= action.payload?.pageInfo?.TotalCount;
                j++
            ) {
                delete state.newsFlashLoadedRowsMap[j];
            }

            if (action.meta.arg.reset) {
                state.allNewsFlashData = action.payload.newsFlashData;
            } else {
                state.allNewsFlashData = state.allNewsFlashData.concat(
                    action.payload.newsFlashData
                );
            }
        },
        [fetchAllNewsFlash.rejected]: (state, action) => {
            state.newsFlashStatus = LoadingStatus.Failed;
            state.errors = action.error.message;
        },
        [fetchNewsFlashById.fulfilled]: (state, action) => {
            state.newsFlashStatus = LoadingStatus.Loaded;
            state.newsFlashData = action.payload;
        },
        [fetchNewsFlashById.rejected]: (state, action) => {
            state.newsFlashStatus = LoadingStatus.Failed;
            state.errors = action.error.message;
        },
        [fetchNewsFlashDisplayOptions.pending]: (state, action) => {
            state.newsFlashDisplayOptionStatus = LoadingStatus.Loading;
        },
        [fetchNewsFlashDisplayOptions.fulfilled]: (state, action) => {
            state.newsFlashDisplayOptionStatus = LoadingStatus.Loaded;
            state.newsFlashDisplayOptions =
                action.payload?.news_flash_display_options;
        },
        [fetchNewsFlashDisplayOptions.rejected]: (state, action) => {
            state.newsFlashDisplayOptionStatus = LoadingStatus.Failed;
            state.errors = action.error.message;
        },
        [createNewsFlash.fulfilled]: (state, action) => {
            state.newsFlashStatus = LoadingStatus.Idle;
            state.newsFlashData = action.payload;
        },
        [createNewsFlash.rejected]: (state, action) => {
            state.newsFlashStatus = LoadingStatus.Failed;
            state.errors = action.error.message;
        },
        [updateNewsFlash.fulfilled]: (state, action) => {
            state.newsFlashStatus = LoadingStatus.Idle;
            state.newsFlashData = action.payload;
        },
        [updateNewsFlash.rejected]: (state, action) => {
            state.newsFlashStatus = LoadingStatus.Failed;
            state.errors = action.error.message;
        },
        [deleteNewsFlash.fulfilled]: (state) => {
            state.newsFlashStatus = LoadingStatus.Idle;
        },
        [deleteNewsFlash.rejected]: (state, action) => {
            state.newsFlashStatus = LoadingStatus.Failed;
            state.error = action.error.message;
        },
        [deleteNewsFlashArray.fulfilled]: (state) => {
            state.newsFlashStatus = LoadingStatus.Idle;
        },
        [deleteNewsFlashArray.rejected]: (state, action) => {
            state.newsFlashStatus = LoadingStatus.Failed;
            state.error = action.error.message;
        },
    },
});

export const getAllNewsFlash = (state) => state.newsFlash.allNewsFlashData;
export const getNewsFlashData = (state) => state.newsFlash.newsFlashData;
export const getNewsFlashDisplayOptions = (state) =>
    state.newsFlash.newsFlashDisplayOptions;
export const getSessionErrors = (state) => state.newsFlash.errors;
export const getNewsFlashStatus = (state) => state.newsFlash.newsFlashStatus;
export const getNewsFlashDisplayOptionStatus = (state) =>
    state.newsFlash.newsFlashDisplayOptionStatus;
export const getNewsFlashPageInfo = (state) =>
    state.newsFlash.newsFlashPageInfo;
export const getNewsFlashLoadedRowsMap = (state) =>
    state.newsFlash.newsFlashLoadedRowsMap;

export const { resetNewsFlashData, setNewsFlashStatus, resetnewsFlashStatus } =
    newsFlashSlice.actions;

export default newsFlashSlice.reducer;
