/**
 * This slice is to manage a single asset
 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { assetService } from '_features/_services';
import {
    LoadingStatus,
    SavingStatus,
} from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { setActiveAsset } from 'GeminiViewerComponent/_features/asset/assetSlice';
import { resetTableZones } from '_features/zones/zonesTableSlice';
import { setProcedureState } from 'GeminiViewerComponent/components/Procedure/_features/procedureSlice';

const initialState = {
    asset: {},

    assetTypes: [],

    assetLinkTypes: [],
    assetLinkTypesStatus: LoadingStatus.Idle,

    loadStatus: LoadingStatus.Idle,
    saveStatus: SavingStatus.Idle,
};

// Actions related to a single asset
export const getAsset = createAsyncThunk(
    'assetManager/getAsset',
    async (assetId, { getState, dispatch }) => {
        const response = await assetService.getById(
            assetId,
            getState().accounts.activeUser.s3_prefix
        );
        if (!response.error) {
            response.id = assetId;
            dispatch(setActiveAsset(response));
            if (response?.asset_type_id === 3) {
                const procedure = response?.procedure;
                dispatch(setProcedureState(procedure));
            }
            dispatch(resetTableZones());
        }

        return response;
    }
);
export const publishById = createAsyncThunk(
    'assetManager/publishById',
    async ({ asset_id, isDraft }) => {
        const response = await assetService.publishById(asset_id, isDraft);
        response.id = asset_id;
        return response;
    }
);

export const getAssetTypes = createAsyncThunk(
    'assetManager/getAssetTypes',
    async () => await assetService.getAssetTypes()
);

export const getAllLinkTypes = createAsyncThunk(
    'assetManager/getAllLinkTypes',
    async () => await assetService.getAllLinkTypes()
);

const assetManagerSlice = createSlice({
    name: 'assetManager',
    initialState,
    reducers: {
        setDisplayAssetProcedureNodes: (state, action) => {
            state.asset = {
                ...state.asset,
                procedure: { ...state.asset.procedure, nodes: action.payload },
            };
        },
    },
    extraReducers: {
        [publishById.fulfilled]: (state, action) => {
            // state.asset = action.payload;
        },
        [publishById.rejected]: (state, action) => {
            state.status = SavingStatus.Failed;
            state.error = action.error.message;
        },
        [getAsset.pending]: (state, action) => {
            state.loadingStatus = LoadingStatus.Loading;
            // state.asset = {};
        },
        [getAsset.fulfilled]: (state, action) => {
            // state.asset = action.payload;
            state.loadingStatus = LoadingStatus.Loaded;
        },
        [getAsset.rejected]: (state, action) => {
            state.loadingStatus = LoadingStatus.Failed;
        },
        [getAssetTypes.pending]: (state, action) => {
            state.assetTypesStatus = LoadingStatus.Loading;
        },
        [getAssetTypes.fulfilled]: (state, action) => {
            state.assetTypes = action.payload.asset_types;
            state.assetTypesStatus = LoadingStatus.Loading;
        },
        [getAssetTypes.rejected]: (state, action) => {
            state.assetTypesStatus = LoadingStatus.Failed;
        },
        [getAllLinkTypes.pending]: (state, action) => {
            state.assetLinkTypesStatus = LoadingStatus.Loading;
        },
        [getAllLinkTypes.fulfilled]: (state, action) => {
            state.assetLinkTypesStatus = LoadingStatus.Loaded;
            state.assetLinkTypes = action.payload.link_types;
        },
        [getAllLinkTypes.rejected]: (state, action) => {
            state.assetLinkTypesStatus = LoadingStatus.Failed;
        },
    },
});

export const selectAssetLoadingStatus = (state) =>
    state.assetManager.loadingStatus;

export const selectAssetTypes = (state) => state.assetManager.assetTypes;

export const selectAssetLinkTypes = (state) =>
    state.assetManager.assetLinkTypes;

export const selectAssetLinkTypesStatus = (state) =>
    state.assetManager.assetLinkTypesStatus;

export const { setDisplayAssetProcedureNodes } = assetManagerSlice.actions;

export default assetManagerSlice.reducer;
