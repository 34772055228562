import { createSlice } from '@reduxjs/toolkit';

var initialState = {
    voiceSelection: 0,
    speak: true,
    listening: false,
    message: '',
    nodeCommands: [],
};

const voiceSlice = createSlice({
    name: 'voice',
    initialState,
    reducers: {
        setMessage: (state, action) => {
            state.message = action.payload.message;
        },
        setVoiceSelection: (state, action) => {
            state.voiceSelection = action.payload;
        },
        setSpeak: (state, action) => {
            state.speak = !state.speak;
        },
        setListening: (state, action) => {
            state.listening = action.payload.listen;
        },
        setNodeCommands: (state, actions) => {
            state.nodeCommands = actions.payload.nodeCommands;
        },
    },
    extraReducers: {},
});

export const getMessage = (state) => state.voice.message;
export const getListening = (state) => state.voice.listening;
export const getVoiceSelection = (state) => state.voice.voiceSelection;
export const getNodeCommands = (state) => state.voice.nodeCommands;
export const getSpeak = (state) => state.voice.speak;

export const {
    setListening,
    setVoiceSelection,
    setSpeak,
    setMessage,
    setNodeCommands,
} = voiceSlice.actions;

export default voiceSlice.reducer;
