import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    TextField,
    Tooltip,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';

import { ReasonListDialog } from './ReasonListDialog';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { dialogStyles } from 'GeminiViewerComponent/components/Procedure/styles';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import ReasonsDropdown from './components/ReasonsDropdown';
import { makeFormStyles } from 'forms/styles';
import { actionListDialogStyle } from './styles';
import {
    createInspectionAction,
    createInspectionReason,
    fetchInspectionReasonList,
    getInspectionReasonList,
    getReasonListLoadingStatus,
    updateInspectionAction,
    updateInspectionReason,
} from 'GeminiViewerComponent/_features/inspection/inspectionSlice';
import { MdAdd } from 'react-icons/md';
import { InspectionDataConfirmDialog } from './components/InspectionDataConfirmDialog';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';

const ActionListDialog = ({
    openDialog,
    setOpenDialog,
    setFieldValue,
    values,
    inspectionActionsList,
    editingActions,
}) => {
    const dispatch = useDispatch();
    const { setFieldError, errors } = useFormikContext();
    const theme = useSelector(selectActiveTheme);
    const classes = dialogStyles(theme);
    const formClasses = makeFormStyles(theme);
    const actionDialogClasses = actionListDialogStyle(theme);
    const reasonListLoadingStatus = useSelector(getReasonListLoadingStatus);
    const getReasonList = useSelector(getInspectionReasonList);
    const [openReasonListDialog, setOpenReasonListDialog] = useState(false);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [reasonId, setReasonId] = useState(null);
    const [actionsList, setActionsList] = useState([]);
    const [actionName, setActionName] = useState(null);
    const [actionIndex, setActionIndex] = useState(null);
    const [addReason, setAddReason] = useState(false);

    useEffect(() => {
        const fetchInspectionReasonData = async () => {
            if (reasonListLoadingStatus === LoadingStatus.Idle) {
                await dispatch(fetchInspectionReasonList());
            }
        };

        fetchInspectionReasonData();
    }, [reasonListLoadingStatus]);

    useEffect(() => {
        setActionsList(inspectionActionsList?.inspection_actions || []);
        setFieldValue('action_list', inspectionActionsList?.inspection_actions);
        if (
            inspectionActionsList?.inspection_actions &&
            inspectionActionsList?.inspection_actions?.length > 0
        ) {
            let actionList = {};
            inspectionActionsList?.inspection_actions?.map((action, index) => {
                actionList[action.display_name] =
                    action.inspection_reason_list_id;
            });
        }
        if (editingActions) {
            setFieldValue('action_name', inspectionActionsList?.display_name);
            setActionName(inspectionActionsList?.display_name);
        } else {
            setActionName('');
        }
    }, [inspectionActionsList]);

    const handleSubmitReasons = async (reasonData) => {
        let updateReasons = [];
        setOpenReasonListDialog(false);
        if (!reasonId === true) {
            updateReasons = await dispatch(createInspectionReason(reasonData));
        } else {
            updateReasons = await dispatch(updateInspectionReason(reasonData));
        }
        actionsList[actionIndex] = {
            ...actionsList[actionIndex],
            inspection_reason_list_id:
                updateReasons?.payload?.inspection_reason_list_id,
        };
        setReasonId(updateReasons?.payload?.inspection_reason_list_id);
        setActionsList([...actionsList]);
    };

    const validateName = () => {
        if (!actionName) {
            setFieldError('action_name', 'Required');
            return false;
        } else {
            setFieldError('action_name', '');
            return true;
        }
    };

    const validateActions = () => {
        let error;
        if (actionsList.length <= 0) {
            setFieldError('actions', 'Actions is required');
            error = false;
            return false;
        }
        actionsList.forEach((action) => {
            if (!action.display_name) {
                setFieldError('actions', 'Action Name is required');
                error = false;
                return false;
            } else if (!action.inspection_reason_list_id) {
                setFieldError('actions', 'Reason is required');
                error = false;
                return false;
            } else {
                setFieldError('actions', '');
                error = true;
            }
        });
        return error;
    };

    const handleSubmit = async () => {
        let resultAction = [];
        if (validateName() && validateActions()) {
            dispatch(activateLoading());
            if (!editingActions) {
                resultAction = await dispatch(
                    createInspectionAction({
                        display_name: actionName,
                        actions: actionsList,
                    })
                );
            } else {
                resultAction = await dispatch(
                    updateInspectionAction({
                        display_name: actionName,
                        inspection_action_list_id:
                            inspectionActionsList.inspection_action_list_id,
                        inspection_actions: actionsList,
                    })
                );
            }
            dispatch(deactivateLoading());
            setFieldValue('inspectionAction', resultAction.payload);
            setOpenDialog(false);
        }
    };

    return (
        <>
            {openReasonListDialog && (
                <ReasonListDialog
                    openDialog={openReasonListDialog}
                    setOpenDialog={setOpenReasonListDialog}
                    inspectionReasonsList={
                        getReasonList.find(
                            (reasonData) =>
                                reasonData.inspection_reason_list_id ===
                                reasonId
                        ) || []
                    }
                    createReason={!reasonId}
                    handleSubmitReasons={handleSubmitReasons}
                    addReason={addReason}
                />
            )}
            <Dialog
                fullWidth
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                maxWidth={'sm'}
            >
                <DialogTitle>
                    {editingActions
                        ? `Edit Action List (${inspectionActionsList?.display_name})`
                        : 'Create Action List'}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="action-name"
                        value={actionName}
                        onChange={(e) => {
                            setActionName(e.target.value);
                        }}
                        label={'Name'}
                        type="text"
                        variant="outlined"
                    />
                    {errors.action_name && (
                        <Box className={formClasses.inputErr}>
                            {errors.action_name}
                        </Box>
                    )}
                    <FormControl fullWidth>
                        <Box
                            component="fieldset"
                            border="1px solid #cccccc"
                            padding="15px"
                            borderRadius="5px"
                            minWidth="0"
                        >
                            <Box
                                component="legend"
                                padding="3px"
                                fontSize="14px"
                                color="#6d7178"
                                textAlign="left"
                            >
                                Actions
                            </Box>
                            {actionsList &&
                                actionsList.map((actionData, idx) => (
                                    <ReasonsDropdown
                                        key={actionData.inspection_action_id}
                                        actionsData={actionData}
                                        setFieldValue={setFieldValue}
                                        values={values}
                                        setReasonId={setReasonId}
                                        setOpenReasonListDialog={
                                            setOpenReasonListDialog
                                        }
                                        getReasonList={getReasonList}
                                        idx={idx}
                                        actionsList={actionsList}
                                        setActionsList={setActionsList}
                                        setActionId={setActionIndex}
                                        setAddReason={setAddReason}
                                    />
                                ))}

                            {errors.actions && (
                                <Box className={formClasses.inputErr}>
                                    {errors.actions}
                                </Box>
                            )}
                            <Tooltip title="Add Actions">
                                <IconButton
                                    className={
                                        actionDialogClasses.addButtonIcon
                                    }
                                    onClick={() => {
                                        setActionsList([...actionsList, {}]);
                                    }}
                                    size="small"
                                >
                                    <MdAdd className="react-icon" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        className={classes.cancel}
                        onClick={() => setOpenDialog(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            if (validateName() && validateActions()) {
                                setOpenConfirmationDialog(true);
                            }
                        }}
                        className={classes.submit}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            {openConfirmationDialog && (
                <InspectionDataConfirmDialog
                    openDialog={openConfirmationDialog}
                    setOpenDialog={setOpenConfirmationDialog}
                    onSubmit={handleSubmit}
                />
            )}
        </>
    );
};

export { ActionListDialog };
