// eslint-disable-next-line
import React, { createElement, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sceneButtonsStyles } from './styles';
import { SceneButton } from '../SceneButton';
import {
    setScenesOpen,
    getScenesOpen,
} from '../../_features/globals/visibilitySlice';
import { getPreviousZoneId } from '../../_features/zonePreview/zonePreviewSlice';
import clsx from 'clsx';

import { sceneButtonStyles } from '../SceneButton/styles';
import { getHomeZone } from 'GeminiViewerComponent/_features/asset/assetSlice';
import { loadZone } from 'shared/loadZoneSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { accountsSlice } from 'app/store';
import { selectActiveAsset } from '../../_features/asset/assetSlice';
import themes from 'GeminiViewerComponent/theme/defaultThemes';
import { Tooltip } from '@mui/material';

const { selectActiveUser } = accountsSlice;

const ToggleButton = ({
    extraClasses,
    iconPath,
    theme,
    bgColor,
    tooltipTitle,
}) => {
    const dispatch = useDispatch();
    const scenesOpen = useSelector(getScenesOpen);

    const toggleScenes = () => dispatch(setScenesOpen(!scenesOpen));

    const classes = sceneButtonStyles(theme);

    return (
        <Tooltip title={tooltipTitle}>
            <div
                onClick={toggleScenes}
                className={clsx(
                    classes.imageHoverContainer,
                    classes.sceneButton,
                    extraClasses
                )}
            >
                <img
                    src={iconPath}
                    className={clsx(classes.sceneImg, 'normal')}
                    alt="normal-icon"
                ></img>
            </div>
        </Tooltip>
    );
};

const SceneButtons = ({ mode = 'asset', style }) => {
    const dispatch = useDispatch();
    const classes = sceneButtonsStyles();
    const [preventImgCache, setPreventImgCache] = useState(null);
    const previousZoneId = useSelector(getPreviousZoneId);
    const homeZone = useSelector(getHomeZone);
    const activeUser = useSelector(selectActiveUser);
    const asset = useSelector(selectActiveAsset);
    const theme = useSelector(selectActiveTheme);
    const clientID = asset?.client_id || activeUser?.client_id;
    const s3Prefix = activeUser?.s3_prefix || asset.s3_prefix;
    const themeIconPath =
        theme?.id && theme?.id !== themes?.default?.id ? '/' + theme.id : '';
    const toggleIconPath = `${s3Prefix}client_${clientID}/ThemeData${themeIconPath}/nav_hamburger_icon.svg?${preventImgCache}`;
    const homeIconPath = `${s3Prefix}client_${clientID}/ThemeData${themeIconPath}/nav_home_icon.svg?${preventImgCache}`;
    const backIconPath = `${s3Prefix}client_${clientID}/ThemeData${themeIconPath}/nav_back_icon.svg?${preventImgCache}`;

    useEffect(() => {
        if (
            (mode === 'asset' || mode === 'preview') &&
            preventImgCache === null
        ) {
            setPreventImgCache(new Date().getTime());
        }
    }, [mode]);

    function goBack() {
        if (previousZoneId != null) {
            dispatch(loadZone({ zoneId: previousZoneId, backup: true }));
        }
    }

    function goHome() {
        if (homeZone != null) {
            dispatch(loadZone({ zoneId: homeZone.zone_id }));
        }
    }

    return (
        <div className={classes.sceneButtons} style={style}>
            <ToggleButton
                iconPath={toggleIconPath}
                theme={theme}
                tooltipTitle="Zone Navigation"
            />
            <SceneButton
                iconPath={backIconPath}
                onClick={goBack}
                tooltipTitle="Previous Zone"
            />
            <SceneButton
                iconPath={homeIconPath}
                onClick={goHome}
                tooltipTitle="Home Zone"
            />
        </div>
    );
};

export { SceneButtons };
