import React from 'react';
import { TextField } from 'formik-mui';
import { FormControl } from '@mui/material';
import { Field, useFormikContext } from 'formik';

import { ImageAttachments } from '../../ImageAttachments';

const FormTextField = ({
    data,
    handleBlur,
    fieldValue,
    inGroup = false,
    isReadOnly = false,
    isSubmitAllow = true,
    handleClick = () => {},
}) => {
    const { setFieldValue, errors } = useFormikContext();
    const displayLabel = data?.display_label_beside_field === true;
    let variable = data?.variable;
    if (data?.duplicateCount) {
        let lastIndex = data?.variable.lastIndexOf(`-${data?.duplicateCount}`);
        variable = data?.variable.slice(0, lastIndex);
    }

    const textFieldData = (
        <FormControl
            style={{
                padding: '5px',
                width: data?.field_width ? data.field_width + '%' : '100%',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                {data?.hide_label !== true && displayLabel && (
                    <label>{data?.label}&nbsp;</label>
                )}
                <Field
                    id={data?.variable}
                    component={TextField}
                    disabled={!isSubmitAllow}
                    sx={{
                        '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: '#000000',
                        },
                    }}
                    margin="dense"
                    variant="outlined"
                    style={{ width: data?.width ? `${data.width}%` : '100%' }}
                    type={data?.password === true ? 'password' : 'text'}
                    name={
                        data?.duplicateCount
                            ? `${variable}.${data?.duplicateCount}`
                            : `${variable}.${0}`
                    }
                    onBlur={() => isSubmitAllow && handleBlur(fieldValue)}
                    onClick={(e) => {
                        if (!isSubmitAllow) {
                            e.stopPropagation();
                            handleClick(e, data);
                        }
                    }}
                    onChange={(e) => {
                        // Store default row data at 0 index
                        if (isSubmitAllow) {
                            if (data?.duplicateCount) {
                                setFieldValue(
                                    `${variable}.${[data?.duplicateCount]}`,
                                    e.target.value
                                );
                            } else {
                                setFieldValue(
                                    `${variable}.${[0]}`,
                                    e.target.value
                                );
                            }
                        }
                    }}
                    placeholder={
                        data?.hide_label === true || displayLabel || isReadOnly
                            ? ''
                            : data?.label
                    }
                    label={
                        data?.hide_label === true || displayLabel || isReadOnly
                            ? ''
                            : data?.label
                    }
                    error={!!errors?.[data?.variable]}
                    helperText={errors?.[data?.variable]}
                />
                {data?.image_attachments_allow === true && (
                    <ImageAttachments
                        data={data}
                        var_name={data?.variable}
                        level="field"
                        isReadOnly={!isSubmitAllow}
                        handleClick={handleClick}
                    />
                )}
            </div>
        </FormControl>
    );

    return inGroup ? (
        textFieldData
    ) : (
        <div style={{ width: '100%' }}>{textFieldData}</div>
    );
};

export default FormTextField;
