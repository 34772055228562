// createElement is mandatory for 3rd party integration, so please don't remove it.
// eslint-disable-next-line
import React, { useLayoutEffect, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import {
    displayProcedureContentVersion,
    getIsAssetLoaded,
    getPanelVis,
    getProcedureContentVersion,
    setAssetType,
    setLinkDisplayVis,
    setPanelVis,
} from '../../_features/globals/visibilitySlice';
import {
    initAsset,
    selectActiveAsset,
    setAssetDetails,
    setAssetPublishedDate,
    setDependencies,
    setIsDraft,
    setZoneMedia,
} from '../../_features/asset/assetSlice';
import {
    setupXapi,
    setECTLUrl,
} from 'GeminiViewerComponent/_features/xAPI/xapiSlice';
import Bowser from 'bowser';
import Marzipano from 'marzipano';
import { loadScript, loadLink } from '../../_helpers/helper';
import { setViewerLoaded } from 'GeminiViewerComponent/_features/globals/viewerStatusSlice';
import {
    setActiveTheme,
    setLoadingSvg,
    setUserThemes,
} from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    loadAsset,
    loadChecklist,
} from 'GeminiViewerComponent/_features/globals/visibilitySlice';

import {
    setProcedureState,
    setInitialState,
    gotoNode,
} from '../Procedure/_features/procedureSlice';
import {
    LinkTypeIds,
    mapCompanionContents,
    prefixUrl,
} from 'GeminiViewerComponent/_helpers';
import { getConfig } from 'GeminiViewerComponent/_features/config/configSlice';
import { clearActiveZones } from 'GeminiViewerComponent/_features/zonePreview/zonePreviewSlice';
import { selectContentPanelItem } from 'GeminiViewerComponent/_features/contentPanel/contentPanelItemSlice';
import { loadContentPanelItem } from 'shared/loadContentPanelSlice';
import {
    setActiveAssetId,
    setActiveAssetProcedureId,
} from '../Procedure/_features/checklistDataSlice';
import { accountsSlice } from 'app/store';
import {
    setInspectionActionList,
    setInspectionReasonList,
} from 'GeminiViewerComponent/_features/inspection/inspectionSlice';
import { setCompanionApps } from 'GeminiViewerComponent/_features/companionApps/companionAppsSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';

const { setActiveUserS3Prefix } = accountsSlice;

const MarzipanoLoader = ({ onLoaded }) => {
    const dispatch = useDispatch();

    const [loadedConfig, setLoadedConfig] = useState(false);
    const config = useSelector(getConfig);
    const isPanelVis = useSelector(getPanelVis);
    const panelItem = useSelector(selectContentPanelItem);
    const isAssetLoaded = useSelector(getIsAssetLoaded);
    const procedureContentVersion = useSelector(getProcedureContentVersion);
    const asset = useSelector(selectActiveAsset);

    const isPanelVisRef = useRef(isPanelVis);
    const panelItemRef = useRef(panelItem);
    const procedureContentVersionRef = useRef(procedureContentVersion);

    var loadingPrefix = config.modeType === 'author' ? '/' : '';

    useEffect(() => {
        isPanelVisRef.current = isPanelVis;
        panelItemRef.current = panelItem;
        procedureContentVersionRef.current = procedureContentVersion;
    }, [isPanelVis, panelItem, procedureContentVersion]);

    window.bowser = Bowser.getParser(window.navigator.userAgent);
    window.Marzipano = Marzipano;

    useEffect(() => {
        if (
            !loadedConfig &&
            config.paths.PUBLISHED_FILE &&
            isAssetLoaded !== true
        ) {
            axios
                .get(
                    config.paths.PUBLISHED_FILE +
                        '?t=' +
                        new Date().valueOf().toString()
                )
                .then(async function (response) {
                    if (!response.data.client) {
                        // This can happen when trying to load
                        // a local published.json file that
                        // doesn't exist
                        return;
                    }
                    setLoadedConfig(true);

                    // LRS
                    const lrsCreds = {
                        endpoint: response.data.lrs_endpoint,
                        client_key: response.data.lrs_client_key,
                        client_secret: response.data.lrs_client_secret,
                    };
                    const gAsset = {
                        id: response.data.asset_id,
                        type_id: response.data.asset_type_id,
                        name: response.data.display_name,
                    };

                    const gClient = {
                        id: response.data.client.client_id,
                        name: response.data.client.display_name,
                    };

                    await dispatch(
                        setupXapi({
                            LrsCredentials: lrsCreds,
                            GeminiAsset: gAsset,
                            GeminiClient: gClient,
                        })
                    );
                    await dispatch(
                        setECTLUrl({
                            ectlURL: response?.data?.ectl_api_url,
                            ectlToken: response?.data?.ectl_api_token,
                        })
                    );

                    // Asset
                    dispatch(setActiveAssetId(response.data?.asset_id));
                    dispatch(setAssetType(response.data?.asset_type_id));
                    dispatch(
                        setAssetPublishedDate(response.data?.published_date)
                    );
                    dispatch(setIsDraft(response.data?.is_draft));
                    dispatch(setZoneMedia(response.data?.zone_media));
                    dispatch(
                        setAssetDetails({
                            display_name: response.data?.display_name,
                            description: response.data?.description,
                        })
                    );
                    if (response.data?.asset_type_id === 3) {
                        dispatch(clearActiveZones());
                        dispatch(initAsset(response.data));
                        dispatch(
                            setActiveUserS3Prefix(response.data.s3_prefix)
                        );
                        if (response.data?.procedure_json) {
                            if (
                                response.data.s3_prefix &&
                                response.data?.loading_svg_uri
                            ) {
                                dispatch(
                                    setLoadingSvg({
                                        loadingSvgUri: `${response.data.s3_prefix}${response.data.loading_svg_uri}`,
                                        loadingSvgCssAnimate:
                                            response.data
                                                .loading_svg_css_animate ??
                                            true,
                                    })
                                );
                            }

                            if (response.data.client.themes_json) {
                                dispatch(
                                    setUserThemes(
                                        JSON.parse(
                                            response.data.client.themes_json
                                        )
                                    )
                                );
                                dispatch(
                                    setActiveTheme(
                                        response.data.client.active_theme ??
                                            'default'
                                    )
                                );
                            }

                            if (response.data.client.active_theme) {
                                dispatch(
                                    setActiveTheme(
                                        response.data.client.active_theme
                                    )
                                );
                            }

                            let currentDate = new Date();
                            const offset = currentDate.getTimezoneOffset();
                            currentDate = new Date(
                                currentDate.getTime() - offset * 60 * 1000
                            );

                            var checkList = JSON.parse(
                                response.data?.procedure_json
                            );
                            checkList.header_image_url = prefixUrl(
                                response.data?.header_image_url,
                                response.data?.s3_prefix
                            );

                            if (checkList.checklist_user_data) {
                                checkList.checklist_user_data.date = currentDate
                                    .toISOString()
                                    .split('T')[0];
                            }

                            dispatch(
                                setActiveAssetProcedureId(
                                    checkList?.procedure_id
                                )
                            );
                            await dispatch(
                                setProcedureState({
                                    ...checkList,
                                    clearHistory: true,
                                })
                            );
                            dispatch(
                                gotoNode(
                                    Number(checkList?.start_node_id)
                                        ? Number(checkList.start_node_id)
                                        : 1
                                )
                            );
                            if (response.data?.dependencies) {
                                dispatch(
                                    setDependencies(response.data?.dependencies)
                                );
                                if (
                                    response.data?.dependencies
                                        ?.inspection_action_lists &&
                                    response.data?.dependencies
                                        ?.inspection_reason_lists
                                ) {
                                    dispatch(
                                        setInspectionActionList(
                                            response.data.dependencies
                                                .inspection_action_lists
                                        )
                                    );
                                    dispatch(
                                        setInspectionReasonList(
                                            response.data.dependencies
                                                .inspection_reason_lists
                                        )
                                    );
                                }
                            } else {
                                dispatch(setDependencies({}));
                            }
                            dispatch(loadChecklist(true));
                        }
                    } else {
                        dispatch(setInitialState());
                        dispatch(clearActiveZones());
                        dispatch(initAsset(response.data));
                        dispatch(
                            setActiveUserS3Prefix(response.data.s3_prefix)
                        );
                        if (
                            response.data.s3_prefix &&
                            response.data?.loading_svg_uri
                        ) {
                            dispatch(
                                setLoadingSvg({
                                    loadingSvgUri: `${response.data.s3_prefix}${response.data.loading_svg_uri}`,
                                    loadingSvgCssAnimate:
                                        response.data.loading_svg_css_animate ??
                                        true,
                                })
                            );
                        }

                        if (response.data.client.themes_json) {
                            dispatch(
                                setUserThemes(
                                    JSON.parse(response.data.client.themes_json)
                                )
                            );
                            dispatch(
                                setActiveTheme(
                                    response.data.client.active_theme ??
                                        'default'
                                )
                            );
                        }

                        if (response.data.client.active_theme) {
                            dispatch(
                                setActiveTheme(
                                    response.data.client.active_theme
                                )
                            );
                        }
                        if (response.data.asset_companion_apps) {
                            dispatch(
                                setCompanionApps(
                                    mapCompanionContents(
                                        response.data.asset_companion_apps,
                                        response.data.s3_prefix
                                    )
                                )
                            );
                        }
                        var curTime = new Date().valueOf().toString();

                        loadLink(
                            `${loadingPrefix}reset.min.css`,
                            undefined,
                            'reset-css'
                        );
                        loadLink(
                            `${loadingPrefix}common-style.css?t=${curTime}`,
                            () => {
                                loadScript(
                                    `${loadingPrefix}js/screenfull.min.js`,
                                    () => {
                                        if (config.paths.DATA_URL) {
                                            loadScript(
                                                `${config.paths.DATA_URL}?t=${curTime}`,
                                                () => {
                                                    dispatch(setViewerLoaded());
                                                    if (onLoaded) {
                                                        onLoaded();
                                                    }
                                                }
                                            );
                                        } else {
                                            dispatch(setViewerLoaded());
                                            if (onLoaded) {
                                                onLoaded();
                                            }
                                        }
                                    }
                                );
                            },
                            'common-style-css'
                        );
                    }
                    dispatch(loadAsset(true));
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            var curTime = new Date().valueOf().toString();

            loadLink(`${loadingPrefix}reset.min.css`, undefined, 'reset-css');
            loadLink(
                `${loadingPrefix}common-style.css?t=${curTime}`,
                () => {
                    loadScript(`${loadingPrefix}js/screenfull.min.js`, () => {
                        if (config.paths.DATA_URL) {
                            loadScript(
                                `${config.paths.DATA_URL}?t=${curTime}`,
                                () => {
                                    dispatch(setViewerLoaded());
                                    if (onLoaded) {
                                        onLoaded();
                                    }
                                }
                            );
                        } else {
                            dispatch(setViewerLoaded());
                            if (onLoaded) {
                                onLoaded();
                            }
                        }
                    });
                },
                'common-style-css'
            );
        }
    }, [config]); // eslint-disable-line react-hooks/exhaustive-deps

    useLayoutEffect(() => {
        window.showHotspot = (hotspotId) => {
            if (!hotspotId) {
                return;
            }
            var sceneAndHotspot = findSceneWithHotspot(hotspotId);

            if (sceneAndHotspot != null) {
                var scene = sceneAndHotspot.scene;
                window.viewerManager.viewer.switchScene(scene);
                var hotspot = sceneAndHotspot.hotspot;
                if (hotspot.yaw || hotspot.x) {
                    scene.lookTo({
                        yaw: hotspot.yaw,
                        pitch: hotspot.pitch,
                        x: hotspot.x,
                        y: hotspot.y,
                    });
                }
                window.handleHotspot(hotspot.zone_id, hotspot.item_id);
            } else {
                console.log('MarzipanoViewer: Hotspot undefined');
            }
        };

        // Define handleHotspot function
        window.handleHotspot = async (zoneId, hotspotId, fromUser = true) => {
            if (fromUser) {
                if (
                    isPanelVisRef.current &&
                    panelItemRef.current.item_id === hotspotId &&
                    !procedureContentVersionRef.current
                ) {
                    // Close panel if user clicks on same item again
                    dispatch(setPanelVis({ open: false }));
                } else {
                    const item = await dispatch(
                        loadContentPanelItem({
                            zoneId: zoneId,
                            itemId: hotspotId,
                        })
                    ).unwrap();
                    // If there is only  one content item then show
                    // it immediately.
                    // const item = store.getState().asset.panelItem;
                    if (
                        item?.links.length === 1 &&
                        (item?.auto_view_single_link === true ||
                            asset?.auto_view_single_link === true)
                    ) {
                        const link = item.links[0];
                        if (
                            link.link_type.link_type_id ===
                            LinkTypeIds.Procedure
                        ) {
                            await dispatch(activateLoading());
                            await dispatch(setPanelVis({ open: false }));
                            await dispatch(
                                displayProcedureContentVersion(
                                    link.content_version
                                )
                            );
                            await dispatch(deactivateLoading());
                        } else {
                            dispatch(
                                setLinkDisplayVis({
                                    open: true,
                                    linkContent:
                                        link?.content_version
                                            ?.encoding_status &&
                                        link?.content_version?.encoding_status.toLowerCase() ===
                                            'complete'
                                            ? link?.content_version?.encoded_url
                                            : link.content_version.url
                                            ? link.content_version.url
                                            : link.content_version.embed_data,
                                    linkData:
                                        typeof link?.content_version ===
                                        'object'
                                            ? link?.content_version
                                            : {},
                                    linkTypeId: link.link_type.link_type_id,
                                    autoPlay: Boolean(link?.auto_play),
                                    overlayText:
                                        link.content_version?.overlay_text,
                                })
                            );
                        }
                    } else {
                        dispatch(setPanelVis({ open: true }));
                    }
                }
            }
        };
        // eslint-disable-next-line
    }, [asset]);

    function findSceneWithHotspot(hotspotId) {
        var result = null;

        window.viewerManager.scenes.forEach(function (scene) {
            if (result != null) {
                return;
            }
            scene.hotspots?.forEach(function (hotspot) {
                if (hotspot.item_id === hotspotId) {
                    result = { scene: scene.scene, hotspot: hotspot };
                    return;
                }
            });
        });

        return result;
    }

    return null;
};

export { MarzipanoLoader };
