// eslint-disable-next-line
import React, { createElement } from 'react';
import { BsQuestionSquareFill } from 'react-icons/bs';

import { dtQuestionStyles } from './styles';

const DtQuestion = ({ nodeQuestion }) => {
    const classes = dtQuestionStyles();

    return (
        <div className={classes.dtQuestion}>
            <BsQuestionSquareFill className={classes.dtQuestionIcon} />
            <div>{nodeQuestion}</div>
        </div>
    );
};

export { DtQuestion };
