// eslint-disable-next-line
import React, { createElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';

import {
    setCurrentNode,
    addReversal,
    getCurrentNode,
} from '../../../../_features/decisionTree/decisionTreeSlice';
import { saveStatement } from 'GeminiViewerComponent/_features/xAPI/xapiSlice';
import { LrsVerbs } from 'GeminiViewerComponent/_features/xAPI/xApiData';
import { dtOptionPictureStyles } from './styles';

const DecisionOptionPicture = ({ option }) => {
    const dispatch = useDispatch();
    const classes = dtOptionPictureStyles();
    const currentNode = useSelector((state) => getCurrentNode(state));

    const handleOption = () => {
        dispatch(setCurrentNode({ node_id: option.button_link_node }));
        dispatch(addReversal({ node_id: currentNode.node_id }));
        dispatch(
            saveStatement({
                LrsVerb: LrsVerbs.Interacted,
                response: {
                    action: 'handleOption',
                    node_id: option.button_link_node,
                    statementType: 'REPORT_RESPONSE',
                },
            })
        );
    };

    return (
        <Grid onClick={handleOption} item sm={12} md={6} lg={6}>
            <div className={classes.dtOptionPicture}>
                <div style={{ padding: '10px' }}>
                    <img
                        className={classes.dtOptionImg}
                        src={option.img_src}
                        alt={option.img_alt ?? ''}
                    />
                </div>
            </div>
        </Grid>
    );
};

export { DecisionOptionPicture };
