import React from 'react';
import { useSelector } from 'react-redux';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import useStyles, {
    SmoothnessFormLabel,
    SmoothnessRadio,
    OrbitReverseCheckbox,
} from '../styles';
import {
    Button,
    FormControl,
    FormControlLabel,
    RadioGroup,
} from '@mui/material';
import ZonePreviews from './zonePreviews';

const ImportHeader = ({
    canSave,
    inverse,
    hasImages,
    imageResize,
    trimPositions,
    framesPerSecond,
    trimPreviewArray,
    handleSave,
    handleSmoothnessChange,
    handleImageResizeChange,
    handleOrbitReverseChange,
}) => {
    const theme = useSelector(selectActiveTheme);
    const classes = useStyles(theme);

    return (
        <div className={classes.importHeaderContainer}>
            <div className={classes.importHeaderContainer}>
                <div className={classes.smoothnessRadioGroup}>
                    <FormControl style={{ marginLeft: 10 }}>
                        {!hasImages && (
                            <RadioGroup
                                aria-label="smoothness"
                                name="controlled-radio-buttons-group"
                                value={framesPerSecond.toString()}
                                onChange={handleSmoothnessChange}
                                row={true}
                                style={{ alignItems: 'flex-end' }}
                            >
                                <SmoothnessFormLabel>
                                    Smoothness
                                </SmoothnessFormLabel>
                                <FormControlLabel
                                    className={classes.smoothnessRadioLabel}
                                    value="2"
                                    control={<SmoothnessRadio />}
                                    label="Low"
                                />
                                <FormControlLabel
                                    value="3"
                                    control={<SmoothnessRadio />}
                                    label="Medium"
                                />
                                <FormControlLabel
                                    value="4"
                                    control={<SmoothnessRadio />}
                                    label="High"
                                />
                            </RadioGroup>
                        )}

                        {hasImages && (
                            <RadioGroup
                                aria-label="image-resize"
                                name="controlled-radio-buttons-group"
                                value={imageResize}
                                onChange={handleImageResizeChange}
                                row={true}
                                style={{ alignItems: 'flex-end' }}
                            >
                                <SmoothnessFormLabel>
                                    Image Resize
                                </SmoothnessFormLabel>
                                <FormControlLabel
                                    className={classes.smoothnessRadioLabel}
                                    value={0}
                                    control={<SmoothnessRadio />}
                                    label="None"
                                />
                                <FormControlLabel
                                    className={classes.smoothnessRadioLabel}
                                    value={1}
                                    control={<SmoothnessRadio />}
                                    label="Small"
                                />
                                <FormControlLabel
                                    value={2}
                                    control={<SmoothnessRadio />}
                                    label="Medium"
                                />
                                <FormControlLabel
                                    value={3}
                                    control={<SmoothnessRadio />}
                                    label="Large"
                                />
                            </RadioGroup>
                        )}

                        <div>
                            <SmoothnessFormLabel style={{ marginRight: '0px' }}>
                                CCW Rotation
                            </SmoothnessFormLabel>

                            <OrbitReverseCheckbox
                                checked={inverse}
                                label="CCW Rotation"
                                labelPlacement="start"
                                onChange={handleOrbitReverseChange}
                            />
                        </div>
                    </FormControl>

                    {trimPositions && !hasImages && (
                        <ZonePreviews
                            title="Trim Positions"
                            previews={trimPreviewArray}
                            className={classes.trimZonePreviews}
                            isTrimPositions={true}
                        />
                    )}
                </div>
                <div className={classes.fileDescriptionDiv}>
                    <p>
                        Image filenames must contain at least one numeric
                        character and should be in sequence. <br />
                        Some valid sets of examples are:
                    </p>
                    <ul
                        style={{
                            listStyle: 'inside',
                        }}
                    >
                        <li>1.jpg, 2.jpg, 3.jpg, etc.</li>
                        <li>a1.jpg, a2.jpg, a3.jpg, etc.</li>
                        <li>a1.1.jpg, a1.2.jpg, a1.3.jpg, etc.</li>
                    </ul>
                </div>
            </div>

            <Button
                variant="contained"
                color="primary"
                style={{ alignSelf: 'flex-start' }}
                disabled={!canSave}
                onClick={handleSave}
            >
                Save
            </Button>
        </div>
    );
};

export default ImportHeader;
