import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import {
    fetchTags,
    getTagStatus,
    selectTags,
    createTag,
} from '_features/tags/tagsSlice';
import { SelectionAutocomplete } from 'components';
import { Formik, Form, Field } from 'formik';
import { editItem } from '_features/common/editItemSlice';
import { useSnackbar } from 'notistack';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { accountsSlice } from 'app/store';
import { deleteKey } from 'GeminiViewerComponent/_helpers';
const { selectActiveUser } = accountsSlice;

export function ItemTagEdit({ rowData }) {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [itemTags, setItemTags] = useState([]);
    const [initValues, setInitValues] = useState({ ...rowData });
    const [clearConfirm, setClearConfirm] = useState(false);
    const activeUser = useSelector(selectActiveUser);
    const user = JSON.parse(localStorage.getItem('user'));
    let tagIdList = [];
    if (rowData?.tags && rowData?.tags.length > 0) {
        tagIdList = {
            tag_ids: rowData?.tags.map((a) => a.tag_id),
        };
    }
    useEffect(() => {
        const setValues = async () => {
            setInitValues({
                ...initValues,
                ...tagIdList,
            });
            if (tagIdList?.tag_ids) {
                setItemTags([...tagIdList.tag_ids]);
            }
        };
        setValues();
    }, [rowData]);
    function onCustomChange(values) {
        let tag_ids_list = values
            .map((a) => a.tag_id)
            .filter((t) => t != undefined);
        setInitValues({
            ...initValues,
            tag_ids: tag_ids_list,
        });
        setItemTags([...tag_ids_list]);
    }

    function cancelItemTagEdit() {
        setClearConfirm(true);
    }

    const handleItemTagEdit = async () => {
        if (activeUser.role !== 'User') {
            let resultAction = await dispatch(
                editItem({
                    zone_id: rowData?.zone_id,
                    item_id: rowData?.item_id,
                    tag_ids: itemTags,
                })
            );
            if (resultAction.error) {
                enqueueSnackbar('Tags not updated. Please try again.', {
                    action: (key) => <SnackbarDismiss key={'error'} />,
                    variant: 'error',
                });
            } else {
                enqueueSnackbar('Tags updated.', {
                    action: (key) => <SnackbarDismiss key={'success'} />,
                    variant: 'success',
                });
            }
            setClearConfirm(false);
            deleteKey(`tags_${user.user_id}`);
            dispatch(fetchTags());
        }
    };
    return (
        <>
            <Formik enableReinitialize={true} initialValues={{ ...initValues }}>
                {() => (
                    <Form style={{ width: '90%' }}>
                        <Field name="tag_ids">
                            {({
                                field: { value },
                                form: { setFieldValue },
                            }) => (
                                <SelectionAutocomplete
                                    placeholder="Item Tags"
                                    size="small"
                                    keyProperty="tag_id"
                                    nameProperty="name"
                                    entityIds={value}
                                    setEntityIds={(field, value) => {
                                        setFieldValue(field, value);
                                        setItemTags(value);
                                    }}
                                    entitySelector={selectTags}
                                    entityStatusSelector={getTagStatus}
                                    fetchEntityPage={fetchTags}
                                    fetchParams={{ sort: 'name' }}
                                    formField={'tag_ids'}
                                    enableCreatable
                                    creatableDialogTitle={'tag'}
                                    creatableDialogFieldLabel={'Tag'}
                                    creatableDispatch={createTag}
                                    showCloseItemEdit={
                                        activeUser.role !== 'User'
                                    }
                                    handleItemTagEdit={handleItemTagEdit}
                                    enableCache={false}
                                    onCustomChange={onCustomChange}
                                    cancelItemTagEdit={cancelItemTagEdit}
                                    showInitialValuesAlways={
                                        activeUser.role === 'User'
                                    }
                                    fieldValidationLength={150}
                                />
                            )}
                        </Field>
                    </Form>
                )}
            </Formik>
            <Dialog
                open={clearConfirm}
                onClose={(e) => setClearConfirm(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Cancel Changes
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to cancel changes?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={(e) => {
                            setClearConfirm(false);
                        }}
                    >
                        No
                    </Button>
                    <Button
                        onClick={(e) => {
                            setInitValues(
                                JSON.parse(
                                    JSON.stringify({
                                        ...initValues,
                                        ...tagIdList,
                                    })
                                )
                            );
                            setItemTags(tagIdList?.tag_ids);
                            setClearConfirm(false);
                        }}
                        autoFocus
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
