import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { ProcedureNodeTypes } from '../ProcedureNodeTypes';
import { convertNodeTypes } from 'GeminiViewerComponent/_helpers/node_helpers';
import { last } from 'GeminiViewerComponent/_helpers/lodashUtils';

let currentDate = new Date();

const offset = currentDate.getTimezoneOffset();
currentDate = new Date(currentDate.getTime() - offset * 60 * 1000);

const initialState = {
    trees: {},
    active_tree_id: null,
    loadingStatus: LoadingStatus.Idle,
    error: '',
    displaySessionDialog: true,
    isBranchProcedure: false,
};

export const findNextNode = (sortedNodes, node) => {
    const index = sortedNodes.findIndex((item) => item.id === node.id);
    if (index !== -1 && index < sortedNodes.length - 1) {
        return sortedNodes[index + 1];
    } else {
        return null;
    }
};

export const sortNodes = (nodes) => {
    const nodeValues = Object.keys(nodes ?? {}).map((key) => {
        return { id: key, ...nodes[key] };
    });
    const sortedNodes = nodeValues.sort(function (a, b) {
        return a.order_idx - b.order_idx;
    });
    return sortedNodes;
};

export const getAllProcedureVariables = async (nodes) => {
    if (!Array.isArray(nodes) || nodes.length <= 0) {
        return [];
    }
    let findVariables = [];
    await nodes?.map((nd) => {
        if (nd.type === ProcedureNodeTypes.content.type) {
            if (nd?.variable) {
                findVariables.push({ id: `${nd?.id}`, name: nd.variable });
            }
            nd?.fields?.map((field) => {
                if (field.type === 'group' || field.type === 'table') {
                    field?.fields?.map((fld) => {
                        if (fld?.variable) {
                            findVariables.push({
                                id: fld?.id,
                                name: fld.variable,
                            });
                        }
                        return false;
                    });
                } else {
                    if (field?.variable) {
                        findVariables.push({
                            id: field?.id,
                            name: field.variable,
                        });
                    }
                }
                return false;
            });
        }
        return false;
    });
    return findVariables;
};

export const setHeaderVariables = async (procedure) => {
    let updatedProcedure = procedure;
    if (procedure?.header_variables && procedure?.nodes) {
        let findVariables = await getAllProcedureVariables(procedure?.nodes);
        const headerVars = await procedure?.header_variables?.map(
            (headerVar) => {
                return {
                    id: headerVar.id,
                    name: findVariables?.find(
                        (hdVar) => hdVar?.id === headerVar.id
                    )?.name,
                };
            }
        );
        updatedProcedure = { ...procedure, header_variables: headerVars };
    }
    return updatedProcedure;
};

const getFieldAnswers = (options) => {
    if (options) {
        return options
            ?.map((btn) => {
                return { label: btn?.answer_value };
            })
            ?.filter((btn) => btn?.label);
    }
    return [];
};

const getFormFieldAnswers = (field) => {
    let fieldObj = {
        name: field.variable,
        label: field.variable,
    };
    if (field) {
        let opts;
        switch (field.type) {
            case 'multiline':
                return { ...fieldObj, valueEditorType: 'textarea' };
            case 'checkbox':
                return {
                    ...fieldObj,
                    valueEditorType: 'checkbox',
                    defaultValue: opts?.[0]?.label,
                    operators: [{ name: '=', label: '=' }],
                };
            case 'select':
                opts = field.options
                    ?.map((opt) => {
                        return { label: opt?.value };
                    })
                    ?.filter((btn) => btn?.label);
                return {
                    ...fieldObj,
                    valueEditorType: 'select',
                    defaultValue: opts?.[0]?.label,
                    values: opts,
                    operators: [
                        { name: '=', label: '=' },
                        { name: '!=', label: '!=' },
                    ],
                };
            case 'radiogroup':
                opts = field.fields
                    ?.map((opt) => {
                        return { name: opt?.label, label: opt?.label };
                    })
                    ?.filter((btn) => btn?.label);
                return {
                    ...fieldObj,
                    valueEditorType: 'radio',
                    defaultValue: opts?.[0]?.label,
                    values: opts,
                    operators: [
                        { name: '=', label: '=' },
                        { name: '!=', label: '!=' },
                    ],
                };
            case 'datetime':
                opts = field.fields
                    ?.map((opt) => {
                        return { label: opt?.label };
                    })
                    ?.filter((btn) => btn?.label);
                return {
                    ...fieldObj,
                    inputType:
                        field.date_time_type === 'datetime'
                            ? 'datetime-local'
                            : field.date_time_type === 'time'
                            ? 'time'
                            : 'date',
                    values: opts,
                };
            default:
                return { ...fieldObj };
        }
    }
    return fieldObj;
};

export const getQueryBuilderFieldsData = async (nodes) => {
    let findVariables = [];
    let fieldObj = {};
    await nodes?.map((nd) => {
        if (nd.type === ProcedureNodeTypes.content.type) {
            if (nd?.variable) {
                fieldObj = {
                    name: nd.variable,
                    label: nd.variable,
                    valueEditorType: 'select',
                    defaultValue: '',
                    operators: [
                        { name: '=', label: '=' },
                        { name: '!=', label: '!=' },
                    ],
                };
                if (nd?.answer_group === 'image') {
                    let imageOpts = getFieldAnswers(nd?.image_options);
                    if (
                        nd?.multiple_answers_allow === true &&
                        Array.isArray(nd?.image_target_combinations) &&
                        nd?.image_target_combinations?.length > 0
                    ) {
                        imageOpts = [
                            ...imageOpts,
                            ...getFieldAnswers(nd?.image_target_combinations),
                        ];
                    }
                    if (Array.isArray(imageOpts) && imageOpts?.length > 0) {
                        fieldObj = {
                            ...fieldObj,
                            defaultValue: imageOpts?.[0]?.label,
                            values: imageOpts,
                        };
                    }
                } else if (nd?.answer_group === 'select') {
                    let selectOpts = getFieldAnswers(nd?.select_options);
                    if (
                        nd?.multiple_answers_allow === true &&
                        Array.isArray(nd?.select_target_combinations) &&
                        nd?.select_target_combinations?.length > 0
                    ) {
                        selectOpts = [
                            ...selectOpts,
                            ...getFieldAnswers(nd?.select_target_combinations),
                        ];
                    }
                    if (Array.isArray(selectOpts) && selectOpts?.length > 0) {
                        fieldObj = {
                            ...fieldObj,
                            defaultValue: selectOpts?.[0]?.label,
                            values: selectOpts,
                        };
                    }
                } else if (nd?.answer_group === 'hotspot') {
                    console.log('hotspot answer type');
                } else {
                    let buttonOpts = getFieldAnswers(nd?.option_buttons);
                    if (Array.isArray(buttonOpts) && buttonOpts?.length > 0) {
                        fieldObj = {
                            ...fieldObj,
                            defaultValue: buttonOpts?.[0]?.label,
                            values: buttonOpts,
                        };
                    }
                }
                findVariables.push(fieldObj);
            }
            nd?.fields?.map((field) => {
                if (field.type === 'group' || field.type === 'table') {
                    field?.fields?.map((fld) => {
                        if (fld?.variable) {
                            findVariables.push(getFormFieldAnswers(fld));
                        }
                        return false;
                    });
                } else {
                    if (field?.variable) {
                        findVariables.push(getFormFieldAnswers(field));
                    }
                }
                return false;
            });
        }
        return false;
    });
    findVariables = [
        ...findVariables,
        {
            name: 'allAnswersCorrect',
            label: 'All Answers Correct',
            valueEditorType: 'checkbox',
            defaultValue: false,
            operators: [{ name: '=', label: '=' }],
        },
        {
            name: 'correctAnswers',
            label: 'Correct Answers',
            inputType: 'number',
            operators: [
                { name: '=', label: '=' },
                { name: '!=', label: '!=' },
                { name: '<', label: '<' },
                { name: '>', label: '>' },
                { name: '<=', label: '<=' },
                { name: '>=', label: '>=' },
            ],
        },
        {
            name: 'percentOfCorrectAnswers',
            label: '% of Correct Answers',
            inputType: 'number',
            operators: [
                { name: '=', label: '=' },
                { name: '!=', label: '!=' },
                { name: '<', label: '<' },
                { name: '>', label: '>' },
                { name: '<=', label: '<=' },
                { name: '>=', label: '>=' },
            ],
        },
    ];
    return findVariables;
};

const setInitialNode = (tree) => {
    if (tree.parent_tree_id) {
        if (tree.start_node_id) {
            tree.current_node = tree.nodes.find(
                (node) => Number(node.id) === Number(tree.start_node_id)
            );
        } else if (tree.continue_node_id) {
            tree.current_node = tree.nodes.find(
                (node) => Number(node.id) === Number(tree.continue_node_id)
            );
        } else {
            tree.current_node = tree.nodes[0];
        }
    } else {
        if (tree.continue_node_id) {
            tree.current_node = tree.nodes.find(
                (node) => Number(node.id) === Number(tree.continue_node_id)
            );
        } else if (tree.start_node_id) {
            tree.current_node = tree.nodes.find(
                (node) => Number(node.id) === Number(tree.start_node_id)
            );
        } else {
            tree.current_node = tree.nodes[0];
        }
    }
};

export const fetchProcedure = createAsyncThunk(
    'assets/fetchProcedure',
    async ({ service, procedureId }) => {
        return await service.getById(procedureId);
    }
);

const handleNodeNavigation = (state, node) => {
    var activeTree = state.trees[state.active_tree_id];
    switch (node.type) {
        case ProcedureNodeTypes.branch.type:
            if (!node.branch_procedure?.procedure_id) {
                return;
            }
            // If this is a branch then load it now
            if (node.start_node_id) {
                node.branch_procedure = {
                    ...node.branch_procedure,
                    start_node_id: node.start_node_id,
                };
            }
            if (node.return_node_id) {
                node.branch_procedure = {
                    ...node.branch_procedure,
                    return_node_id: node.return_node_id,
                };
            }
            // if (node.end_node_id) {
            //     node.branch_procedure.end_node_id = node.end_node_id;
            // }
            state.trees[node.branch_procedure.procedure_id] =
                node.branch_procedure;
            activeTree = node.branch_procedure;
            activeTree.parent_tree_id = state.active_tree_id;
            state.active_tree_id = node.branch_procedure.procedure_id;
            activeTree.history = [];
            setInitialNode(activeTree);
            break;
        default:
            // If current node is a display type and next node is not, then
            // save current node as the last known display node. This allows
            // us to automatically navigate back to the last displayed node
            // in case the current flow ends on a non display type node.
            if (
                ProcedureNodeTypes[activeTree?.current_node?.type]?.mode ===
                'display'
            ) {
                if (ProcedureNodeTypes[node.type].mode !== 'display') {
                    activeTree.lastDisplayNode = activeTree.current_node;
                } else {
                    activeTree.lastDisplayNode = null;
                }
            }
            if (activeTree.current_node.id !== node.id) {
                if (
                    activeTree.current_node?.type !==
                    ProcedureNodeTypes.contentViewAction.type
                ) {
                    activeTree.history.push(activeTree.current_node);
                }
            }
            activeTree.current_node = node;
            break;
    }
};

const doGotoNode = (state, nodeId) => {
    var activeTree = state.trees[state.active_tree_id];
    let nodes = activeTree.allNodes ?? activeTree.nodes;
    const node =
        nodes.find((node) => node.id === nodeId) ||
        activeTree.nodes.find((node) => node.id === nodeId);
    if (node) {
        state.isBranchProcedure = node.type === ProcedureNodeTypes.branch.type;
        handleNodeNavigation(state, node);
    }
};

const doNextNode = (state) => {
    const activeTree = state.trees[state.active_tree_id];
    const nextNode = findNextNode(activeTree.nodes, activeTree.current_node);
    if (nextNode) {
        handleNodeNavigation(state, nextNode);
    } else {
        const nextNode = findNextNode(
            activeTree?.allNodes || [],
            activeTree.current_node
        );
        if (nextNode) {
            handleNodeNavigation(state, nextNode);
        }
    }
};

const skipSameNode = (state, nodes, last_node_id) => {
    if ([...nodes].pop().id === last_node_id) {
        nodes.pop();
        state.trees[state.active_tree_id].history = nodes;
        skipSameNode(state, nodes, last_node_id);
    }
    return nodes;
};

const skipHiddenNode = (state, nodes, last_node_id) => {
    if (
        !state.trees[state.active_tree_id]?.nodes.find(
            (node) => node.id === last_node_id
        )
    ) {
        const lastNode = nodes.pop();
        state.trees[state.active_tree_id].history = nodes;
        skipHiddenNode(state, nodes, lastNode?.id);
        return lastNode;
    } else return { id: last_node_id };
};

const doPreviousNode = (state) => {
    const activeTree = state.trees[state.active_tree_id];
    if (activeTree.history.length > 0) {
        let lastNode = [...activeTree.history].pop();
        skipSameNode(
            state,
            [...activeTree.history],
            activeTree.current_node.id
        );
        lastNode = activeTree.history.pop();
        lastNode = skipHiddenNode(state, [...activeTree.history], lastNode?.id);
        state.trees[state.active_tree_id].current_node = activeTree.nodes.find(
            (node) => node.id === lastNode.id
        );
    }
};

const procedureSlice = createSlice({
    name: 'procedure',
    initialState,
    reducers: {
        setInitialState: (state, action) => {
            state = initialState;
            return state;
        },
        setActiveTreeData: (state, action) => {
            state.trees[state.active_tree_id] = state.trees?.[
                state.active_tree_id
            ]
                ? { ...state.trees[state.active_tree_id], ...action.payload }
                : state.trees[state.active_tree_id];
        },
        getNewNode: (state, action) => {
            const nodes = state.trees[state.active_tree_id].nodes;
            const lastNode = last(nodes);
            const newNode = {
                id: Number(lastNode.id) + 1,
                type: ProcedureNodeTypes.content.type,
                order_idx: lastNode + 1,
                back_button: false,
                next_button: false,
                submit_button: false,
                option_buttons: [],
                title: '',
                question: '',
                content: '',
                fields: [],
                target_title: '',
                target_details: '',
                target_url: '',
            };
            return newNode;
        },
        setActiveProcedureNodes: (state, action) => {
            if (action.payload?.nodes) {
                state.trees[state.active_tree_id].allNodes =
                    action.payload.allNodes;
                state.trees[state.active_tree_id].nodes = action.payload.nodes;
                if (action.payload?.start_node_id) {
                    let currentNode = action.payload.nodes.find(
                        (node) =>
                            Number(node.id) ===
                            Number(action.payload.start_node_id)
                    );
                    state.trees[state.active_tree_id].current_node =
                        currentNode;
                    state.trees[state.active_tree_id].start_node_id =
                        currentNode.id;
                }
                if (action.payload?.clearHistory) {
                    state.trees[state.active_tree_id].history = [];
                }
                // If current node is a content node but not part of filtered
                // nodes then update current node to first filtered node.
                let nodes = state.trees[state.active_tree_id].nodes;
                let currentNode =
                    state.trees[state.active_tree_id].current_node;
                if (
                    !currentNode ||
                    (nodes.length > 0 &&
                        currentNode.type === 'content' &&
                        nodes.some(
                            (node) => Number(node.id) === Number(currentNode.id)
                        ) === false)
                ) {
                    state.trees[state.active_tree_id].current_node = nodes[0];
                }
            }
        },
        setProcedureState: (state, action) => {
            state.trees = {};
            state.trees[action.payload.procedure_id] = convertNodeTypes({
                ...action.payload,
            });
            var activeTree = state.trees[action.payload.procedure_id];
            if (activeTree.nodes && activeTree.nodes?.length > 0) {
                state.trees[action.payload.procedure_id].nodes = sortNodes(
                    activeTree.nodes
                );
                state.trees[action.payload.procedure_id].primary_tag_variable =
                    action.payload?.primary_tag_variable;
                state.trees[
                    action.payload.procedure_id
                ].secondary_tag_variable =
                    action.payload?.secondary_tag_variable;
                state.trees[action.payload.procedure_id].save_sessions =
                    action.payload?.save_sessions;
                setInitialNode(activeTree);
                if (action.payload?.clearHistory) {
                    activeTree.history = [];
                } else {
                    activeTree.history = action.payload?.history ?? [];
                }
                state.active_tree_id = activeTree.procedure_id;
                return state;
            }
        },
        updateNodes: (state, action) => {
            state.trees[state.active_tree_id].nodes = action.payload;
        },
        setDisplaySessionDialog: (state, action) => {
            state.displaySessionDialog = action.payload;
        },
        updateNode: (state, action) => {
            const activeTree = state.trees[state.active_tree_id];
            const nodeIdx = activeTree.nodes.findIndex(
                (element) => element.id === action.payload.id
            );
            if (nodeIdx !== -1) {
                activeTree.nodes[nodeIdx] = action.payload;
            }
            state.trees[state.active_tree_id] = activeTree;
        },
        setActiveTreeId: (state, action) => {
            state.active_tree_id = action.payload;
        },
        resetCurrentTree: (state, action) => {
            const activeTree = state.trees[state.active_tree_id];
            setInitialNode(activeTree);
            activeTree.history = [];
        },
        setParentTreeId: (state, action) => {
            state.trees[state.active_tree_id].parent_tree_id = action.payload;
        },
        previousNode: (state, action) => {
            doPreviousNode(state);
        },
        gotoNode: (state, action) => {
            doGotoNode(state, action.payload);
        },
        nextNode: (state, action) => {
            doNextNode(state);
        },
        handleNodeAction: (state, action) => {
            const activeTree = state.trees[state.active_tree_id];
            console.log(
                `Handling node action at node ${activeTree.current_node.id}`
            );
            switch (action.payload.id) {
                case 'next':
                    doNextNode(state);
                    break;
                case 'previous':
                    doPreviousNode(state);
                    break;
                default:
                    if (typeof action.payload.id === 'number') {
                        doGotoNode(state, action.payload.id);
                    } else {
                        // Make sure we don't end on a non-display node
                        const activeTree = state.trees[state.active_tree_id];
                        if (
                            ProcedureNodeTypes[activeTree.current_node.type]
                                .mode !== 'display' &&
                            activeTree.lastDisplayNode
                        ) {
                            doGotoNode(state, activeTree.lastDisplayNode.id);
                        }
                    }
                    break;
            }
        },
        setProcedureAnswer: (state, action) => {
            const activeTree = state.trees[state.active_tree_id];
            activeTree.current_node.history_answer =
                action.payload.answer.answer_value ??
                action.payload.answer.button_text;
            const nodeIdx = activeTree.nodes.findIndex(
                (element) => element.id === action.payload.id
            );
            if (nodeIdx !== -1) {
                activeTree.nodes[nodeIdx].answer = action.payload.answer;
            }
        },
        unsetProcedureAnswer: (state, action) => {
            const activeTree = state.trees[state.active_tree_id];
            const nodeIdx = activeTree.nodes.findIndex(
                (element) => element.id === action.payload.id
            );
            if (nodeIdx !== -1) {
                activeTree.nodes[nodeIdx].answer = null;
            }
        },
        setIsBranchNode: (state, action) => {
            state.isBranchProcedure = action.payload;
        },
    },
    extraReducers: {
        [fetchProcedure.pending]: (state, action) => {
            state.loadingStatus = LoadingStatus.Loading;
        },
        [fetchProcedure.fulfilled]: (state, action) => {
            state.loadingStatus = LoadingStatus.Loaded;
            let procedure = JSON.parse(action.payload.procedure_json);
            procedure.procedure_id = action.payload.procedure_id;
            procedure = convertNodeTypes(procedure);
            state.trees = {};
            state.trees[action.payload.procedure_id] = { ...procedure };
            var activeTree = state.trees[action.payload.procedure_id];
            if (activeTree.nodes && activeTree.nodes?.length > 0) {
                if (action.meta?.arg?.start_node_id) {
                    activeTree.start_node_id = action.meta?.arg?.start_node_id;
                }
                state.trees[action.payload.procedure_id].nodes = sortNodes(
                    activeTree.nodes
                );
                activeTree.history = [];
                setInitialNode(activeTree);
            }
            state.active_tree_id = activeTree.procedure_id;
        },
        [fetchProcedure.rejected]: (state, action) => {
            state.loadingStatus = LoadingStatus.Failed;
            state.error = action.error.message;
        },
    },
});

export const getCurrentNode = (state) => {
    return state.procedure.trees[state.procedure.active_tree_id]?.current_node;
};

export const getActiveTreeId = (state) => state.procedure.active_tree_id;

export const getActiveTree = (state) =>
    state.procedure.trees[state.procedure.active_tree_id];

export const getLoadingStatus = (state) => state.procedure.loadingStatus;
export const getDisplaySessionDialog = (state) =>
    state.procedure.displaySessionDialog;

export const getBranchNode = (state) => state.procedure.isBranchProcedure;

export const {
    setInitialState,
    setActiveTreeData,
    updateNodes,
    updateNode,
    setProcedureState,
    handleNodeAction,
    nextNode,
    gotoNode,
    previousNode,
    getCurrentNodeData,
    setProcedureAnswer,
    unsetProcedureAnswer,
    setActiveTreeId,
    setParentTreeId,
    resetCurrentTree,
    getNewNode,
    setDisplaySessionDialog,
    setActiveProcedureNodes,
    setIsBranchNode,
} = procedureSlice.actions;

export default procedureSlice.reducer;
