import logo from 'assets/logo.png';
import { Navigate, NavLink, useLocation, useNavigate } from 'react-router-dom';

import {
    Button,
    TextField,
    Typography,
    Grid,
    FormControl,
    MenuItem,
    Select,
    Alert,
    AlertTitle,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import useStyles from '../../styles';
import clsx from 'clsx';
import { validate } from 'email-validator';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { getAccountService } from '../../../../_features/services/account.service';

import { accountsSlice, accountsThunk } from 'app/store';
import { getConfig } from 'GeminiViewerComponent/_features/config/configSlice';
import {
    getNetworkService,
    UserExceptions,
    UserModes,
} from 'GeminiViewerComponent/_helpers';
import { GeminiSharedLoadingIcon } from 'GeminiViewerComponent/components/Common/GeminiSharedLoadingIcon';
import { selectLoadingSvg } from 'GeminiViewerComponent/_features/globals/themeSlice';

const { loginUser, logoutUser } = accountsThunk;
const {
    selectActiveUser,
    getIsAppLoaded,
    selectLoginError,
    setAppLoading,
    setActiveUser,
} = accountsSlice;

function Login({ demoUsers, loginCompleted }) {
    const classes = useStyles();
    const isLoading = useSelector(getIsAppLoaded);
    const activeUser = useSelector(selectActiveUser);
    const location = useLocation();
    const navigate = useNavigate();

    const config = useSelector(getConfig);
    useEffect(() => {
        if (config.userMode === UserModes.Form) {
            const { from } = location.state || { from: { pathname: '/' } };
            navigate('/form-login', { replace: true, state: from });
        }

        if (config.userFailure) {
            if (config.userException === UserExceptions.Deny) {
                navigate('/denied-access');
            } else if (config.userException === UserExceptions.Redirect) {
                window.location = config.userRedirect;
            }
        }
    }, []);

    return activeUser ? (
        <Navigate to={location.state.from} />
    ) : !isLoading ? (
        <div className={classes.backgroundContainer}>
            <Grid
                container
                className={classes.loginContainer}
                item
                xs={12}
                sm={6}
                lg={4}
                xl={3}
            >
                <Grid item className={classes.loginGridItem}>
                    <img
                        src={logo}
                        className={classes.logo}
                        alt="Gemini logo"
                    ></img>
                </Grid>
                {location?.pathname === '/account/login' && (
                    <LoginForm
                        config={config}
                        demoUsers={demoUsers}
                        loginCompleted={loginCompleted}
                    />
                )}
                {location?.pathname === '/account/login/forgot-password' && (
                    <ForgotPassword config={config} />
                )}
            </Grid>
        </div>
    ) : (
        <GeminiSharedLoadingIcon
            styleClassName={classes.loadingSvg}
            rotateStyleName={classes.rotateSvg}
            svgSelector={selectLoadingSvg}
        />
    );
}

function LoginForm({ config, demoUsers, loginCompleted }) {
    const accountService = getAccountService({ config: config });
    const networkService = getNetworkService({
        config: config,
        checkAuth: accountService.checkAuth,
    });

    var loginEmail = networkService.getLoginEmail();
    networkService.clearLoginEmail();

    // var loginEmail = sessionStorage.getItem('loginEmail') ?? '';
    // sessionStorage.removeItem('loginEmail');

    const classes = useStyles();
    const [isError, setIsError] = useState(false);
    const [enteredEmail, setEnteredEmail] = useState(loginEmail);
    const [isInvalidEmail, setIsInvalidEmail] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [password, setPassword] = useState('');
    const loginError = useSelector(selectLoginError);
    const queryParams = new URLSearchParams(window.location.search);
    const ssoErrorCode = queryParams.get('sso-code');
    const ssoErrorEmail = queryParams.get('sso-email');

    const location = useLocation();
    const navigate = useNavigate();

    const dispatch = useDispatch();

    async function loginDemoUser(user) {
        await dispatch(logoutUser());
        let accountUser = {
            first_name: user.userName,
            user_id: user.user_id,
            certifications: user.certifications,
            tenure: user.tenure,
            role: user.role,
            job: user.job,
            isGuest: user.isGuest,
            dod_id: user.dod_id,
        };
        dispatch(setActiveUser(accountUser));
        const { from } = location.state || { from: { pathname: '/' } };
        navigate({ ...from, ...{ to: '/' } }, { replace: true });
    }

    const handleLogin = useCallback(
        async (user = null) => {
            var email = user?.email;
            var _password = user?.password;

            const resultAction = await dispatch(
                loginUser({
                    email: email || enteredEmail,
                    password: _password || password,
                    dod_id: user?.dod_id,
                })
            );
            if (!resultAction.error) {
                if (loginCompleted) {
                    await loginCompleted();
                }
                unwrapResult(resultAction);
                const { from } = location.state || {
                    from: { pathname: '/' },
                };
                navigate({ ...from, ...{ to: '/' } }, { replace: true });
            } else {
                if (user) {
                    loginDemoUser(user);
                }
            }
        },
        [enteredEmail, password]
    );

    const onSubmit = async () => {
        try {
            dispatch(setAppLoading(true));
            networkService.setLoginEmail(enteredEmail);
            clearError();
            if (validation()) {
                handleLogin();
            }
        } catch (err) {
            console.error(``, err);
        } finally {
            dispatch(setAppLoading(false));
        }
    };

    function validation() {
        if (!validate(enteredEmail)) {
            setEmailError('Please enter a valid email.');
            setIsInvalidEmail(true);
            return false;
        } else return true;
    }

    function clearError() {
        setIsError(false);
        setEmailError('');
        setIsInvalidEmail(false);
    }

    function keyPress(e) {
        if (e.keyCode === 13) {
            onSubmit();
        }
    }

    return (
        <>
            <Grid item className={classes.loginForm}>
                <Typography
                    className={clsx(classes.spacing, classes.formHeader)}
                >
                    Gemini Administration
                    <span className={classes.version}>
                        v{config.appVersion}
                    </span>
                </Typography>
                <form className={clsx(classes.arrangement, classes.spacing)}>
                    <TextField
                        onChange={(e) => setEnteredEmail(e.target.value)}
                        onKeyDown={(e) => keyPress(e)}
                        error={isInvalidEmail}
                        helperText={emailError}
                        size="small"
                        label="Email"
                        name="email"
                        value={enteredEmail}
                        variant="outlined"
                        required
                        className={classes.spacing}
                        autoComplete="email"
                    />
                    <TextField
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={(e) => keyPress(e)}
                        size="small"
                        type="password"
                        name="password"
                        label="Password"
                        variant="outlined"
                        required
                        className={classes.spacing}
                        autoComplete="current-password"
                    />
                    <Button
                        size="medium"
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={onSubmit}
                    >
                        Login
                    </Button>
                </form>
                <Typography onClick={() => setIsError(!isError)}>
                    <NavLink
                        to="/account/login/forgot-password"
                        className={classes.forgotLogin}
                    >
                        Forgot username / password?
                    </NavLink>
                </Typography>
                {loginError && (
                    <Alert severity="error" className={classes.loginError}>
                        <AlertTitle>
                            <strong>Something is wrong!</strong>
                        </AlertTitle>
                        {loginError.code === 'ECONNABORTED' ? (
                            <div>
                                Login timed out. Please check your internet
                                connection.
                            </div>
                        ) : (
                            <div>
                                The username or password could be wrong, please
                                review that or start the Forgot
                                username/password process. If the problem
                                persists, please contact your administrator
                            </div>
                        )}
                    </Alert>
                )}
                {ssoErrorCode && (
                    <Alert severity="error" className={classes.loginError}>
                        <AlertTitle>
                            <strong>An error with SSO!</strong>
                        </AlertTitle>
                        {ssoErrorCode === 'NOUSER' ? (
                            <div>
                                Your SSO login was valid but no user could be
                                found to match inside of Gemini. If this is an
                                error please contact your Gemini Admin to
                                correct your Gemini user email or log in with
                                your Gemini credentials.
                                <span className={classes.ssoErrorEmail}>
                                    {ssoErrorEmail}
                                </span>
                            </div>
                        ) : (
                            <div>
                                An error occurred with your SSO login. Please
                                contact an Administrator or login using your
                                Gemini credentials.
                            </div>
                        )}
                    </Alert>
                )}
            </Grid>
            {demoUsers && demoUsers.length > 0 && (
                <FormControl variant="outlined" size="small">
                    <Select
                        variant="outlined"
                        id="userSelect"
                        value="none"
                        onChange={(event) => {
                            const user = event.target.value;
                            if (user?.email) {
                                handleLogin(user);
                            } else {
                                loginDemoUser(user);
                            }
                        }}
                        className={classes.demoUserSelect}
                    >
                        <MenuItem value="none">Select user</MenuItem>
                        {demoUsers.map((user) => {
                            return (
                                <MenuItem value={user} key={user.userName}>
                                    {user.userName}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            )}
        </>
    );
}

function ForgotPassword({ config }) {
    const classes = useStyles();
    const [isError, setIsError] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [enteredEmail, setEnteredEmail] = useState('');
    const [isInvalidEmail, setIsInvalidEmail] = useState(false);
    const [emailError, setEmailError] = useState('');

    const accountService = getAccountService({ config: config });

    const onSubmit = () => {
        //TODO add loading animation.
        clearError();
        if (validation()) {
            accountService
                .forgotPassword(enteredEmail)
                .then(() => {
                    setIsSuccessful(true);
                })
                .catch(() => {
                    setIsError(true);
                });
        }
    };

    function validation() {
        //var emailValidationMsg = validate({emailAddress: enteredEmail}, constraints);

        if (!validate(enteredEmail)) {
            setEmailError('Please enter a valid email.');
            setIsInvalidEmail(true);
            return false;
        } else return true;
    }

    function clearError() {
        setIsError(false);
        setEmailError('');
        setIsInvalidEmail(false);
    }

    return (
        <Grid item className={classes.resetForm}>
            {!isSuccessful ? (
                <Grid item className={classes.resetForm}>
                    <Typography
                        className={clsx(classes.spacing, classes.formHeader)}
                    >
                        Username/Password recovery
                    </Typography>
                    <Typography
                        className={clsx(classes.spacing, classes.formSubtitle)}
                    >
                        Enter the email you use for this app, and we will send
                        you a link to reset your credentials.
                    </Typography>
                    <form
                        className={clsx(classes.arrangement, classes.spacing)}
                    >
                        <TextField
                            onChange={(e) => setEnteredEmail(e.target.value)}
                            error={isInvalidEmail}
                            helperText={emailError}
                            size="small"
                            id="outlined-basic"
                            label="Email"
                            name="email"
                            variant="outlined"
                            required
                            className={classes.spacing}
                        />
                        <Grid item className={classes.resetButtons}>
                            <NavLink
                                to="/account/login"
                                className={classes.resetButton}
                            >
                                <Button
                                    size="medium"
                                    variant="outlined"
                                    color="secondary"
                                    disableElevation
                                    type=""
                                    className={classes.cancel}
                                >
                                    Cancel
                                </Button>
                            </NavLink>
                            <Button
                                size="medium"
                                variant="contained"
                                color="primary"
                                disableElevation
                                onClick={onSubmit}
                                className={classes.resetButton}
                            >
                                Send
                            </Button>
                        </Grid>
                    </form>
                </Grid>
            ) : (
                <Grid item className={classes.resetForm}>
                    <Typography
                        className={clsx(classes.spacing, classes.formHeader)}
                    >
                        Username/Password recovery
                    </Typography>
                    <Typography
                        className={clsx(classes.spacing, classes.formSubtitle)}
                    >
                        We have sent you a link with your information to login.
                        Once you get the email, please return to login.
                    </Typography>
                    <NavLink
                        to="/account/login"
                        className={clsx(classes.resetButton)}
                    >
                        <Button
                            size="medium"
                            variant="contained"
                            color="primary"
                            disableElevation
                            type=""
                        >
                            Login
                        </Button>
                    </NavLink>
                </Grid>
            )}
            <Alert
                severity="error"
                className={clsx(classes.loginError, {
                    [classes.hide]: !isError,
                })}
            >
                <AlertTitle>
                    <strong>Something is wrong!</strong>
                </AlertTitle>
                The email could have been entered wrong. Please check the email
                entered. If the problem persists, please contact your
                administrator.
            </Alert>
        </Grid>
    );
}

export { Login };
