import React from 'react';
import { Box, Button, FormControl } from '@mui/material';
import { useSelector } from 'react-redux';

import { makeFormStyles } from 'forms/styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';

const ReferenceImagesField = ({ values, setOpenReferenceImageDialog }) => {
    const theme = useSelector(selectActiveTheme);
    const commonFormStyles = makeFormStyles(theme);

    return (
        <FormControl>
            <Box
                component="fieldset"
                border="1px solid #cccccc"
                padding="15px"
                borderRadius="5px"
                minWidth="0"
                textAlign="center"
            >
                <Box
                    component="legend"
                    padding="3px"
                    fontSize="14px"
                    color="#6d7178"
                    textAlign="left"
                >
                    Reference Images
                </Box>
                {values?.references?.length > 0 ? (
                    <Box
                        height="auto"
                        overflow="auto"
                        onClick={() => {
                            setOpenReferenceImageDialog(true);
                        }}
                    >
                        <Box
                            height="auto"
                            width="auto"
                            display="flex"
                            gap="10px"
                            overflowY="auto"
                        >
                            {values?.references?.map((imgRef) => (
                                <Box
                                    key={`reference${imgRef.id}`}
                                    border="1px solid lightgray"
                                    borderRadius="5px"
                                    overflow="auto"
                                    maxWidth="150px"
                                    maxHeight="100px"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    flex="0 0 150px"
                                >
                                    <img
                                        src={imgRef?.url}
                                        alt={imgRef?.name}
                                        style={{
                                            objectFit: 'contain',
                                            width: 'auto',
                                            height: 'auto',
                                            maxWidth: '100%',
                                            maxHeight: '100%',
                                        }}
                                        height={'100px'}
                                    />
                                </Box>
                            ))}
                        </Box>
                    </Box>
                ) : (
                    <Button
                        style={{
                            padding: '9px',
                            fontSize: '16px',
                        }}
                        className={commonFormStyles.submit}
                        aria-label="Add Images"
                        onClick={() => {
                            setOpenReferenceImageDialog(true);
                        }}
                    >
                        Add Images
                    </Button>
                )}
            </Box>
        </FormControl>
    );
};

export default ReferenceImagesField;
