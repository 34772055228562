import React from 'react';
import { useStyles } from 'scenes/styles';
import { FiCheckCircle } from 'react-icons/fi';
import { useDispatch } from 'react-redux';

import { JobsTabPanel, PageHeader } from 'components';
import { resetContentStatus } from '_features/content/contentSlice';
import { setAssetExportStatus } from '_features/assets/assetExportSlice';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';

const Jobs = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const onRefreshIconClick = () => {
        dispatch(resetContentStatus());
        dispatch(setAssetExportStatus(LoadingStatus.Idle));
    };

    return (
        <div className={classes.content}>
            <PageHeader
                header="Job Status"
                icon={<FiCheckCircle />}
                onRefreshIconClick={onRefreshIconClick}
            />
            <JobsTabPanel />
        </div>
    );
};

export { Jobs };
