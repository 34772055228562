// eslint-disable-next-line
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    getSelectedHotspotFromUser,
    getActiveAssetActiveZone,
    selectCompactZones,
} from '../../_features/asset/assetSlice';
import { MarzipanoManager } from './MarzipanoManager';
import {
    getShowOnlyByFilter,
    getTagFilters,
} from '../../_features/globals/visibilitySlice';
import { getConfig } from 'GeminiViewerComponent/_features/config/configSlice';
import { selectContentPanelItem } from 'GeminiViewerComponent/_features/contentPanel/contentPanelItemSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { marzipanoStyles } from './styles';
import { getSelectedSearchItemId } from 'GeminiViewerComponent/_features/asset/assetSlice';
import { useHighlightedItem } from 'GeminiViewerComponent/hooks/useHighlightedItem';

const MarzipanoViewer = ({
    hotspotElements,
    onSelectHotspot,
    onShiftRightClick,
    onRightClick,
    onClick,
    isMultipleHotspotEnabled,
    isLoadedState,
    onClickRefreshItem,
    setActiveItem,
    handleMultipleHotspot,
    previewZone = null,
}) => {
    const dispatch = useDispatch();
    const theme = useSelector(selectActiveTheme);
    const panoStyles = marzipanoStyles(theme);

    const activeItemId = null;

    const config = useSelector(getConfig);
    const allZones = useSelector(selectCompactZones);
    const panelItem = useSelector(selectContentPanelItem);
    const compactZones = useSelector(selectCompactZones);
    const showOnlyByFilter = useSelector(getShowOnlyByFilter);
    const selectedHotspotFromUser = useSelector(getSelectedHotspotFromUser);
    const activeZone = previewZone ?? useSelector(getActiveAssetActiveZone);
    const tagFilters = useSelector(getTagFilters);
    const searchItem = useSelector(getSelectedSearchItemId);
    const highlightedItem = useHighlightedItem(activeZone);

    useEffect(() => {
        if (config.user !== undefined) {
            // dispatch(clearActiveZones());
            window.viewerManager = new MarzipanoManager({
                config: config,
                initialZoneId: activeZone ? activeZone.zone_id : null,
                hotspotItems: activeZone?.items,
                zoneItems: compactZones,
                itemHotspots: activeZone?.items,
                tagFilters: tagFilters,
                showOnlyByFilter: showOnlyByFilter,
                onSceneChanged: (scene) => {
                    let zone = allZones.find((zoneInfo) => {
                        var zoneObj = zoneInfo.editObject
                            ? zoneInfo.editObject
                            : zoneInfo.zone
                            ? zoneInfo.zone
                            : zoneInfo;
                        return zoneObj.zone_id === scene.data.id;
                    });
                    if (zone) {
                        // var zoneObj = zone.editObject
                        //     ? zone.editObject
                        //     : zone.zone
                        //     ? zone.zone
                        //     : zone;
                        // dispatch(setActiveZone(zoneObj));
                        // if (zoneObj) {
                        //   if (onSceneChanged) {
                        //     onSceneChanged(zoneObj.zone_id);
                        //   }
                        // }
                    }
                },
                onShiftRightClick: onShiftRightClick,
                onRightClick: onRightClick,
                onClick: onClick,
                isMultipleHotspotEnabled: isMultipleHotspotEnabled,
                handleMultipleHotspot: handleMultipleHotspot,
            });

            window.viewerManager.init();
        }
    }, [config]); // eslint-disable-line react-hooks/exhaustive-deps

    // NOTE: This method previously used allZones and activeZone as dependencies.
    // This was causing some odd timing issues resulting in dragged hotspots jumpnig
    // back to their previous position even though they were successfully moved to
    // the new location. It was updated to use activeZone only.
    useEffect(() => {
        async function updateScenes() {
            if (activeZone) {
                window.viewerManager.updateSceneData(
                    isMultipleHotspotEnabled,
                    activeZone,
                    handleMultipleHotspot
                );

                await window.viewerManager.addScenesFromZones(
                    [highlightedItem],
                    hotspotElements,
                    tagFilters,
                    showOnlyByFilter
                );

                window.viewerManager.switchSceneById(activeZone.zone_id);
                if (activeItemId) {
                    // Show current node id
                    if (
                        activeZone?.items?.find(
                            (item) => item.item_id === activeItemId
                        )
                    ) {
                        window.showHotspot(activeItemId);
                    } else {
                        if (setActiveItem) {
                            dispatch(setActiveItem({}));
                        }
                    }
                }
                let panoEl = document.getElementById('pano');
                if (panoEl) {
                    if (isMultipleHotspotEnabled) {
                        panoEl.style.cursor = 'crosshair';
                    } else {
                        panoEl.style.cursor = 'default';
                    }
                }
            }
        }
        updateScenes();
        // NOTE: This will be called twice when active zone changes - because after the first
        // render for zone change the hotspotElements are created which causes another render
    }, [
        config,
        searchItem,
        activeZone,
        hotspotElements,
        tagFilters,
        isMultipleHotspotEnabled,
        showOnlyByFilter,
    ]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (activeZone) {
            window.viewerManager.updatesceneView(highlightedItem);
        }
    }, [searchItem]);

    useEffect(() => {
        if (
            window.viewerManager !== undefined &&
            window.showHotspot !== undefined
        ) {
            window.showHotspot(activeItemId);
        }
    }, [activeItemId]);

    useEffect(() => {
        if (
            activeItemId !== undefined &&
            onSelectHotspot !== undefined &&
            onSelectHotspot.canExecute &&
            panelItem !== null &&
            selectedHotspotFromUser === true
        ) {
            onSelectHotspot.execute(panelItem.ZoneId, panelItem.ItemId);
        }

        // eslint-disable-next-line
    }, [panelItem, selectedHotspotFromUser]);

    let hasImage =
        activeZone &&
        (activeZone.cubeFaces !== undefined ||
            activeZone.equirect_image_url !== undefined ||
            activeZone.flat_image_url !== undefined);

    let message = '';
    if (!hasImage && isLoadedState) {
        message = 'This Zone Requires An Image';
    }

    return (
        <>
            <div
                id="pano"
                tabIndex="0"
                className={panoStyles.panoWrapper}
                onKeyDown={(e) => {
                    if (e.code === 'KeyR') {
                        onClickRefreshItem();
                    }
                }}
            ></div>

            {!hasImage && (
                <div
                    style={{
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 1.0)',
                    }}
                >
                    <div
                        style={{
                            fontSize: '24px',
                            color: 'white',
                            position: 'relative',
                            top: '50%',
                            textAlign: 'center',
                        }}
                    >
                        {message}
                    </div>
                </div>
            )}

            {config.data.settings.viewControlButtons && (
                <>
                    <button
                        id="viewUp"
                        className="viewControlButton viewControlButton-1"
                    >
                        <img
                            className="icon"
                            alt="Pan Up"
                            src={config.paths.BASE_STORE_URL + 'img/up.png'}
                        ></img>
                    </button>
                    <button
                        id="viewDown"
                        className="viewControlButton viewControlButton-2"
                    >
                        <img
                            className="icon"
                            alt="Pan Down"
                            src={config.paths.BASE_STORE_URL + 'img/down.png'}
                        ></img>
                    </button>
                    <button
                        id="viewLeft"
                        className="viewControlButton viewControlButton-3"
                    >
                        <img
                            className="icon"
                            alt="Pan Left"
                            src={config.paths.BASE_STORE_URL + 'img/left.png'}
                        ></img>
                    </button>
                    <button
                        id="viewRight"
                        className="viewControlButton viewControlButton-4"
                    >
                        <img
                            className="icon"
                            alt="Pan Right"
                            src={config.paths.BASE_STORE_URL + 'img/right.png'}
                        ></img>
                    </button>
                    <button
                        id="viewIn"
                        className="viewControlButton viewControlButton-5"
                    >
                        <img
                            className="icon"
                            alt="Zoom In"
                            src={config.paths.BASE_STORE_URL + 'img/plus.png'}
                        ></img>
                    </button>
                    <button
                        id="viewOut"
                        className="viewControlButton viewControlButton-6"
                    >
                        <img
                            className="icon"
                            alt="Zoom Out"
                            src={config.paths.BASE_STORE_URL + 'img/minus.png'}
                        ></img>
                    </button>
                </>
            )}
        </>
    );
};

export { MarzipanoViewer };
