import { makeStyles } from '@mui/styles';

const dtOptionStyles = makeStyles(() => ({
    dtOption: {
        minHeight: '45px',
        backgroundColor: 'rgb(231, 231, 231)',
        margin: '10px',
        padding: '.25rem',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        paddingLeft: '1rem',
        border: '1px solid rgb(228, 228, 228)',
        borderRadius: '5px',
        '&:hover': {
            border: '1px solid #7799cc',
            backgroundColor: '#7799cc',
            '& span': {
                color: 'white !important',
            },
        },
    },
    dtOptionText: {
        fontSize: '16px',
        color: '#002A50',
        fontWeight: '500',
    },
}));

export { dtOptionStyles };
