import { PageHeader } from 'components';
import { useStyles } from 'scenes/styles';
import { FiLifeBuoy } from 'react-icons/fi';

function Help() {
    const classes = useStyles();

    return (
        <div className={classes.content}>
            <PageHeader header="Help" icon={<FiLifeBuoy />} />
        </div>
    );
}

export { Help };
