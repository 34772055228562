import { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { apply } from 'json-logic-js';
import { useSnackbar } from 'notistack';
import SplitPane from 'react-split-pane';
import { MdGridView } from 'react-icons/md';
import { formatQuery } from 'react-querybuilder';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Tooltip,
} from '@mui/material';

import { useStyles } from './styles';
import { config } from 'gemini-config';
import { accountsSlice } from 'app/store';
import { SnackbarDismiss } from '../SnackbarDismiss';
import { ProcedureNodeTypes } from './ProcedureNodeTypes';
import { Each } from 'GeminiViewerComponent/_helpers/Each';
// import { ProcedureLrs } from './components/ProcedureLrs';
import { ProcedureFooter } from './components/ProcedureFooter';
import { ProcedureHeader } from './components/ProcedureHeader';
import { ProcedureResult } from './components/ProcedureResult';
// import ProcedureFormNode from './components/ProcedureFormNode';
import { NodeCategoryPanel } from './components/NodeCategoryPanel';
import { ProcedureQuestions } from './components/ProcedureQuestions';
import { LrsVerbs } from 'GeminiViewerComponent/_features/xAPI/xApiData';
import { saveStatement } from 'GeminiViewerComponent/_features/xAPI/xapiSlice';
import { getConfig } from 'GeminiViewerComponent/_features/config/configSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { NewSessionDialog } from './components/SessionSubmit/components/NewSessionDialog';
import { getAccountService } from 'GeminiViewerComponent/_features/services/account.service';
import { setCompanionApps } from 'GeminiViewerComponent/_features/companionApps/companionAppsSlice';
import { useFilterContentProcedureNodes } from 'GeminiViewerComponent/hooks/useFilterContentProcedureNodes';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    getActiveDetails,
    getDependencyAssets,
    initAsset,
    selectActiveAsset,
} from 'GeminiViewerComponent/_features/asset/assetSlice';
import {
    DisplayAnswer,
    mapCompanionContents,
    prefixUrl,
    ProcedureSessionStatus,
} from 'GeminiViewerComponent/_helpers';
import {
    createProcedureSession,
    fetchProcedureSessionById,
    getProcedureSession,
    getSessionSubmit,
    submitSessionData,
} from 'GeminiViewerComponent/_features/procedureSession/procedureSessionSlice';
import {
    displayProcedureContentVersion,
    getDisplayAssetButton,
    getIsAssociateAssetLoaded,
    getIsChecklistLoaded,
    getProcedureContentVersion,
    loadAssociateAsset,
    loadChecklist,
    setDisplayAssetButton,
    showNodeContent,
} from 'GeminiViewerComponent/_features/globals/visibilitySlice';
import {
    getActiveTreeDisplayData,
    getChecklistData,
    getChecklistDataStatus,
    getActiveSessionId,
    setActiveSessionId,
    setActiveTreeDisplayData,
    setChecklistDataStatus,
    setChecklistInitialState,
    setActiveAssetProcedureId,
} from './_features/checklistDataSlice';
import {
    getActiveTree,
    getActiveTreeId,
    getBranchNode,
    getCurrentNode,
    getDisplaySessionDialog,
    gotoNode,
    handleNodeAction,
    previousNode,
    setActiveProcedureNodes,
    setActiveTreeData,
    setDisplaySessionDialog,
    setProcedureState,
} from './_features/procedureSlice';

const accountService = getAccountService({ config: config });

const { selectActiveUser } = accountsSlice;

const DisplayProcedureSteps = ({ activeTree, currentNode }) =>
    activeTree?.show_steps === true && (
        <div
            style={{
                paddingLeft: '10px',
                paddingTop: '10px',
            }}
        >
            {`${
                !isNaN(parseInt(currentNode?.order_idx))
                    ? parseInt(currentNode?.order_idx) + 1
                    : 0
            } of ${activeTree.nodes.length}`}
        </div>
    );

const ProcedureBody = ({
    currentNode,
    isSubmitAllow,
    isInDrawer,
    contentService,
    useZoneNav,
    answerNodes,
    anyAnswers,
    histocialAnswers,
    procedureService,
    showQuestions,
    hasAnswer,
    openNavigationPanel,
    setOpenNavigationPanel,
}) => {
    switch (currentNode?.type) {
        // case ProcedureNodeTypes.form.type:
        //     return (
        //         <ProcedureFormNode
        //             node={currentNode}
        //             formFields={currentNode?.fields}
        //             isSubmitAllow={isSubmitAllow}
        //             isReadOnly={false}
        //             isInDrawer={isInDrawer}
        //         />
        //     );
        case ProcedureNodeTypes.content.type:
            return (
                <ProcedureQuestions
                    contentService={contentService}
                    useZoneNav={useZoneNav}
                    displayNode={currentNode}
                    displayNodesFields={currentNode?.fields}
                    isSubmitAllow={isSubmitAllow}
                    isReadOnly={false}
                    isInDrawer={isInDrawer}
                    openNavigationPanel={openNavigationPanel}
                    setOpenNavigationPanel={setOpenNavigationPanel}
                    // questionAnswers={answerNodes}
                    // historicalAnswers={histocialAnswers}
                />
            );
        case ProcedureNodeTypes.email.type:
            return <div>Sending email...</div>;
        case ProcedureNodeTypes.branch.type:
            return <div>Loading branch...</div>;
        case ProcedureNodeTypes.logic.type:
            return <div>Verifying answers...</div>;
        case ProcedureNodeTypes.contentViewAction.type:
            // NOTE: This should never be seen by the user as
            // the tree should immediately navigate to the next displayable
            // node (or last displayable node if next node is not specified)
            return <div>Content View Action</div>;
        case ProcedureNodeTypes.navigationAction.type:
            // NOTE: This should never be seen by the user as
            // the tree should immediately navigate to the next displayable
            // node (or last displayable node if next node is not specified)
            return <div>Navigation Action</div>;
        // case ProcedureNodeTypes.lrs.type:
        //     return anyAnswers() ? (
        //         <ProcedureLrs
        //             questionAnswers={answerNodes}
        //             historicalAnswers={histocialAnswers}
        //             currentNode={currentNode}
        //             contentService={contentService}
        //             useZoneNav={useZoneNav}
        //         />
        //     ) : (
        //         <div>No nodes had answers</div>
        //     );
        case ProcedureNodeTypes.results.type:
            return (
                <ProcedureResult
                    currentNode={currentNode}
                    procedureService={procedureService}
                    contentService={contentService}
                    questionsData={showQuestions}
                    useZoneNav={useZoneNav}
                    hasAnswer={hasAnswer}
                    openNavigationPanel={openNavigationPanel}
                    setOpenNavigationPanel={setOpenNavigationPanel}
                />
            );
        default:
            return currentNode && currentNode?.type ? (
                <div>{`Error: Unknown node type ${currentNode?.type}`}</div>
            ) : (
                <div>This asset has no content to display.</div>
            );
    }
};
// Handle procedure flow
const Procedure = ({
    procedureService,
    contentService,
    useZoneNav,
    isSubmitAllow = true,
    isInDrawer,
    epssPanelRef,
    splitViewMode,
}) => {
    const theme = useSelector(selectActiveTheme);
    const classes = useStyles(theme);
    const splitPanelRef = useRef(null);

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    // const { gotoZone, gotoZoneAndItem } = useZoneNav();

    const [openSessionConfirmationForm, setOpenSessionConfirmationForm] =
        useState(false);
    const [openNewSessionDialog, setOpenNewSessionDialog] = useState(false);
    const [openNavigationPanel, setOpenNavigationPanel] = useState(true);
    const initRef = useRef(false);

    // remove margin from body for checklist
    const x = document.getElementsByTagName('body')[0];
    x.classList.add('remove-margin');
    const y = document.getElementsByTagName('html')[0];
    y.classList.add('remove-margin');

    const user = accountService.getUser();
    const activeUser = useSelector(selectActiveUser);
    const currentNode = useSelector(getCurrentNode);
    const config = useSelector(getConfig);
    const activeTree = useSelector(getActiveTree);
    const activeTreeId = useSelector(getActiveTreeId);
    const userData = useSelector(getChecklistData);
    const activeTreeStatus = useSelector(getChecklistDataStatus);
    const activeTreeDisplayData = useSelector(getActiveTreeDisplayData);
    const isProcedureLoaded = useSelector(getIsChecklistLoaded);
    const isDisplayAssetButton = useSelector(getDisplayAssetButton);
    const dependencyAssets = useSelector(getDependencyAssets);
    const isDisplaySessionDialog = useSelector(getDisplaySessionDialog);
    const procedureContentVersion = useSelector(getProcedureContentVersion);
    const activeAsset = useSelector(selectActiveAsset);
    const assetDetails = useSelector(getActiveDetails);
    const activeSessionId = useSelector(getActiveSessionId);
    const procedureSessionData = useSelector(getProcedureSession);
    const sessionSubmit = useSelector(getSessionSubmit);
    const isBranchNode = useSelector(getBranchNode);
    const isAssociateAssetLoaded = useSelector(getIsAssociateAssetLoaded);
    const [navigationLinks, setNavigationLinks] = useState(activeTree.nodes);
    const [isBranchProcedure, setIsBranchProcedure] = useState(false);

    useEffect(() => {
        let filterdLinks = [];
        let navLinks = [];
        let originalNodes =
            activeTree?.allNodes && activeTree?.allNodes?.length > 0
                ? activeTree.allNodes
                : activeTree.nodes;
        if (procedureContentVersion) {
            dispatch(activateLoading());
            if (procedureContentVersion?.procedure?.procedure_json) {
                dispatch(
                    setActiveAssetProcedureId(
                        procedureContentVersion.procedure.procedure_id
                    )
                );
                let procedure = JSON.parse(
                    procedureContentVersion.procedure.procedure_json
                );
                originalNodes = procedure.allNodes ?? procedure.nodes;
                procedure.header_image_url = prefixUrl(
                    procedureContentVersion.procedure.header_image_url,
                    activeUser.s3_prefix
                );
                if (!activeTree?.parent_tree_id) {
                    dispatch(
                        setProcedureState({
                            ...procedure,
                            continue_node_id: activeTree?.current_node?.id,
                            clearHistory: false,
                        })
                    );
                }
            }
            filterdLinks = useFilterContentProcedureNodes(
                procedureContentVersion,
                originalNodes
            );
            if (currentNode.type === ProcedureNodeTypes.branch.type) {
                setOpenSessionConfirmationForm(false);
                dispatch(setDisplaySessionDialog(false));
                setIsBranchProcedure(true);
                navLinks = currentNode.branch_procedure.nodes.filter((node) => {
                    if ('exclude_from_navigation' in node) {
                        if (node.exclude_from_navigation === false) {
                            return node;
                        }
                    } else {
                        if (
                            node.type === 'content' ||
                            node.type === 'results'
                        ) {
                            return node;
                        }
                    }
                });
                setNavigationLinks(navLinks);
                dispatch(gotoNode(currentNode.id));
                dispatch(
                    setActiveProcedureNodes({
                        nodes: currentNode.branch_procedure.nodes,
                        allNodes: currentNode.branch_procedure.nodes,
                    })
                );
            } else {
                setIsBranchProcedure(false);
            }
            if (currentNode.type !== ProcedureNodeTypes.branch.type) {
                if (
                    isBranchProcedure ||
                    isBranchNode ||
                    activeTree?.parent_tree_id
                ) {
                    filterdLinks = activeTree.nodes;
                }
                navLinks = filterdLinks.filter((node) => {
                    if ('exclude_from_navigation' in node) {
                        if (node.exclude_from_navigation === false) {
                            return node;
                        }
                    } else {
                        if (
                            node.type === 'content' ||
                            node.type === 'results'
                        ) {
                            return node;
                        }
                    }
                });
                setNavigationLinks(navLinks);
                dispatch(
                    setActiveProcedureNodes({
                        nodes: navLinks,
                        allNodes: originalNodes,
                        clearHistory: false,
                    })
                );
            }
            dispatch(deactivateLoading());
        } else {
            navLinks = activeTree.nodes?.filter((node) => {
                if ('exclude_from_navigation' in node) {
                    if (node.exclude_from_navigation === false) {
                        return node;
                    }
                } else {
                    if (node.type === 'content' || node.type === 'results') {
                        return node;
                    }
                }
            });
            setNavigationLinks(navLinks);
        }
    }, [procedureContentVersion, activeTreeId]);

    useEffect(() => {
        if (initRef.current === true && config.modeType === 'viewer') {
            dispatch(
                setActiveTreeDisplayData({
                    history: activeTree?.history,
                    parent_tree_id: activeTree?.parent_tree_id,
                    lastDisplayNode: activeTree?.lastDisplayNode,
                    current_node: activeTree?.current_node,
                })
            );
        }
    }, [activeTree]);

    useEffect(() => {
        if (activeTreeStatus === false && initRef.current === false) {
            if (
                (userData && Object.keys(userData || {})?.length > 0) ||
                (activeTreeDisplayData &&
                    Object.keys(activeTreeDisplayData || {})?.length > 0)
            ) {
                if (!procedureSessionData) {
                    setOpenSessionConfirmationForm(true);
                }
            } else {
                if (
                    activeTree.save_sessions === true &&
                    sessionSubmit === false &&
                    (activeUser.isGuest === false ||
                        activeUser.is_verified === true)
                ) {
                    startProcedureSession();
                }
                dispatch(setChecklistDataStatus(true));
                initRef.current = true;
            }
        }
        if (
            config.modeType === 'viewer' &&
            Object.keys(userData || {})?.length <= 0 &&
            sessionSubmit === true
        ) {
            setOpenNewSessionDialog(sessionSubmit);
        }
    }, [userData, activeTreeDisplayData]);

    const startProcedureSession = async () => {
        if (config.modeType !== 'viewer') return;
        dispatch(activateLoading());
        const SessionData = {
            display_name: activeTree.title || assetDetails?.assetDisplayName,
            primary_tag: '',
            secondary_tag: '',
            client_id: activeUser.client_id,
            procedure_url: `${activeAsset.player_url}?published=${config.paths.PUBLISHED_FILE}`,
            status: ProcedureSessionStatus.InProgress.id,
            created_by_name: activeUser.client_name || activeUser.first_name,
            // inspection_points: [
            //     {
            //         field_id: 'Field Id 1',
            //         field_name: 'Field Name 1',
            //         action: 'Repair',
            //         reason: 'Damaged',
            //         comment: 'Extensive tearing',
            //         status: 1,
            //         status_comment: 'System',
            //     },
            // ],
        };
        const activeSession = await dispatch(
            createProcedureSession(SessionData)
        );
        await dispatch(
            setActiveSessionId(activeSession?.payload?.procedure_session_id)
        );
        dispatch(submitSessionData(false));
        dispatch(deactivateLoading());
    };

    const fetchProcedureSession = async () => {
        dispatch(activateLoading());
        if (activeSessionId) {
            dispatch(fetchProcedureSessionById(activeSessionId));
        }
        dispatch(deactivateLoading());
    };

    const handleSession = (selected) => {
        if (selected === 'previous') {
            dispatch(setActiveTreeData(activeTreeDisplayData));
            if (
                activeTree.save_sessions === true &&
                (activeUser.isGuest === false ||
                    activeUser.is_verified === true)
            ) {
                fetchProcedureSession();
            }
        } else {
            dispatch(setChecklistInitialState());
            if (
                activeTree.save_sessions === true &&
                (activeUser.isGuest === false ||
                    activeUser.is_verified === true)
            ) {
                startProcedureSession();
            }
        }
        dispatch(setChecklistDataStatus(true));
        initRef.current = true;
        setOpenSessionConfirmationForm(false);
        dispatch(setDisplaySessionDialog(false));
    };

    const handleNavigationAsset = useCallback(async () => {
        let navigateAsset =
            dependencyAssets &&
            Array.isArray(dependencyAssets) &&
            dependencyAssets?.length > 0
                ? Object.values(dependencyAssets)?.find(
                      (asset) => +asset?.asset_id === +currentNode?.asset_id
                  )
                : {};
        dispatch(setDisplaySessionDialog(false));
        dispatch(
            setCompanionApps(
                mapCompanionContents(
                    navigateAsset.asset_companion_apps,
                    activeUser.s3_prefix
                )
            )
        );
        if (
            navigateAsset &&
            typeof navigateAsset === 'object' &&
            !Array.isArray(navigateAsset) &&
            Object.keys(navigateAsset).length > 0
        ) {
            if (!navigateAsset?.s3_prefix) {
                navigateAsset = {
                    ...navigateAsset,
                    s3_prefix: activeUser.s3_prefix ?? '',
                };
            }

            await dispatch(initAsset(navigateAsset));
            // if (currentNode?.zone_id && currentNode?.item_id) {
            //     await gotoZoneAndItem(
            //         currentNode.zone_id,
            //         currentNode?.item_id
            //     );
            // } else if (currentNode?.zone_id) {
            //     await gotoZone(currentNode.zone_id);
            // }
            if (
                isProcedureLoaded === true ||
                procedureContentVersion?.doAction?.zone_id !==
                    currentNode?.zone_id
            ) {
                await dispatch(
                    displayProcedureContentVersion({
                        procedure: {
                            procedure_id: activeTree?.procedure_id,
                            procedure_json: JSON.stringify(activeTree),
                            header_image_url: prefixUrl(
                                activeTree?.header_image_url,
                                activeUser.s3_prefix
                            ),
                        },
                        doAction: currentNode,
                    })
                );
            }
            if (isAssociateAssetLoaded) {
                await dispatch(loadChecklist(false));
            }
        } else {
            if (currentNode.action) {
                await dispatch(handleNodeAction(currentNode.action));
            }
        }
    }, [
        dependencyAssets,
        currentNode,
        activeTree,
        isProcedureLoaded,
        isAssociateAssetLoaded,
    ]);

    let hasAnswer = false;
    activeTree?.nodes?.forEach((data) => {
        if (data.type === ProcedureNodeTypes.content.type) {
            if (data?.option_buttons && data.option_buttons?.length > 0) {
                hasAnswer = true;
            }
            if (data?.select_options && data.select_options?.length > 0) {
                hasAnswer = true;
            }
            if (data?.image_options && data.image_options?.length > 0) {
                hasAnswer = true;
            }
        }
    });

    let showQuestions = [];

    let correctAnswers = activeTree?.nodes?.filter(
        (data) =>
            data.type === ProcedureNodeTypes.content.type &&
            data?.answer?.incorrect !== true
    );

    let failedAnswers = activeTree.nodes.filter(
        (data) =>
            data.type === ProcedureNodeTypes.content.type &&
            data?.answer?.incorrect === true
    );
    let allAnswers = activeTree?.nodes?.filter(
        (data) => data.type === ProcedureNodeTypes.content.type
    );

    if (
        Array.isArray(currentNode?.answer_display_type) &&
        currentNode?.answer_display_type.length > 0
    ) {
        currentNode?.answer_display_type?.forEach((answer_type) => {
            if (answer_type?.value) {
                if (answer_type?.value === DisplayAnswer.correct_only.value) {
                    showQuestions = [...showQuestions, ...correctAnswers];
                }
                if (answer_type?.value === DisplayAnswer.failed_only.value) {
                    showQuestions = [...showQuestions, ...failedAnswers];
                }
                if (answer_type?.value === DisplayAnswer.no_results.value) {
                    showQuestions = [];
                }
                if (answer_type?.value === DisplayAnswer.all_results.value) {
                    showQuestions = [...allAnswers];
                }
            } else {
                if (
                    currentNode?.answer_display_type.includes(
                        DisplayAnswer.correct_only.value
                    )
                ) {
                    showQuestions = [...showQuestions, ...correctAnswers];
                }
                if (
                    currentNode?.answer_display_type.includes(
                        DisplayAnswer.failed_only.value
                    )
                ) {
                    showQuestions = [...showQuestions, ...failedAnswers];
                }
                if (
                    currentNode?.answer_display_type.includes(
                        DisplayAnswer.no_results.value
                    )
                ) {
                    showQuestions = [];
                }
                if (
                    currentNode?.answer_display_type.includes(
                        DisplayAnswer.all_results.value
                    )
                ) {
                    showQuestions = [...allAnswers];
                }
            }
        });
    } else {
        if (
            currentNode?.answer_display_type ===
            DisplayAnswer.correct_only.value
        ) {
            showQuestions = [...showQuestions, ...correctAnswers];
        }
        if (
            currentNode?.answer_display_type === DisplayAnswer.failed_only.value
        ) {
            showQuestions = [...showQuestions, ...failedAnswers];
        }
        if (
            currentNode?.answer_display_type === DisplayAnswer.no_results.value
        ) {
            showQuestions = [];
        }
        if (currentNode?.answer_display_type === 'all_results') {
            showQuestions = [...allAnswers];
        }
    }
    if (!hasAnswer) {
        showQuestions = [...allAnswers];
    }

    let answerNodes = [];
    let histocialAnswers = [];
    const anyAnswers = () => {
        answerNodes = activeTree?.nodes?.filter((data) => data?.answer);
        histocialAnswers = activeTree?.history?.filter(
            (data) => data.history_answer
        );
        return answerNodes.length > 0 || histocialAnswers.length > 0;
    };
    anyAnswers();
    // NOTE: According to React docs, it may be possible to this
    // effect to run twice for the state change. I tried to use
    // a standard technique of setting an 'executedRef' variable
    // to track whether it has alreday been executed but that just
    // caused it to miss state changes because there would be an
    // an actual state change without the component remounting.
    // In practive I haeven't seen a state change duplicated here,
    // but we may meed to look unto another way to test if state change
    // was a duplicate - or move this logic into the procudure slice.
    useEffect(() => {
        async function processNodeAction() {
            switch (currentNode?.type) {
                case ProcedureNodeTypes.contentViewAction.type:
                    dispatch(
                        saveStatement({
                            LrsVerb: LrsVerbs.WasAt,
                            response: {
                                action: 'handleNodeAction',
                                nodeActionType:
                                    ProcedureNodeTypes.contentViewAction.type,
                                nodeId: currentNode.node_id,
                                zoneId: currentNode.zone_id,
                                itemId: currentNode.item_id,
                            },
                            zoneId: currentNode.zone_id,
                            itemId: currentNode.item_id,
                        })
                    );
                    dispatch(
                        showNodeContent({
                            contentService: contentService,
                            node: currentNode,
                        })
                    );
                    await dispatch(handleNodeAction(currentNode.action));
                    break;
                case ProcedureNodeTypes.content.type:
                case ProcedureNodeTypes.navigationAction.type:
                    await dispatch(activateLoading());
                    if (currentNode.zone_id) {
                        if (currentNode.item_id) {
                            dispatch(
                                saveStatement({
                                    LrsVerb: LrsVerbs.WasAt,
                                    response: {
                                        action: 'gotoZoneAndItem',
                                        nodeActionType:
                                            ProcedureNodeTypes.navigationAction
                                                .type,
                                        nodeId: currentNode.node_id,
                                        zoneId: currentNode.zone_id,
                                        itemId: currentNode.item_id,
                                    },
                                    zoneId: currentNode.zone_id,
                                    itemId: currentNode.item_id,
                                })
                            );
                            // gotoZoneAndItem(
                            //     currentNode.zone_id,
                            //     currentNode.item_id
                            // );
                        } else {
                            dispatch(
                                saveStatement({
                                    LrsVerb: LrsVerbs.WasAt,
                                    response: {
                                        action: 'gotoZone',
                                        nodeActionType:
                                            ProcedureNodeTypes.navigationAction
                                                .type,
                                        nodeId: currentNode.node_id,
                                        zoneId: currentNode.zone_id,
                                    },
                                    nodeId: currentNode.node_id,
                                    zoneId: currentNode.zone_id,
                                })
                            );
                            // gotoZone(currentNode.zone_id);
                        }
                    }
                    if (
                        currentNode?.type ===
                        ProcedureNodeTypes.navigationAction.type
                    ) {
                        await dispatch(handleNodeAction(currentNode.action));
                    }
                    if (currentNode?.asset_id && config.modeType === 'viewer') {
                        if (
                            currentNode?.type ===
                            ProcedureNodeTypes.content.type
                        ) {
                            if (!isProcedureLoaded || !isDisplayAssetButton) {
                                await handleNavigationAsset();
                            } else break;
                        } else {
                            await handleNavigationAsset();
                        }
                    }
                    await dispatch(deactivateLoading());
                    break;
                case ProcedureNodeTypes.logic.type: {
                    await dispatch(activateLoading());
                    let attendedQuestions = activeTree?.nodes?.filter(
                        (nd) =>
                            [ProcedureNodeTypes.content.type].includes(
                                nd?.type
                            ) &&
                            nd?.variable &&
                            nd?.answer
                    );
                    let totalAttendedQuestions = Array.isArray(
                        attendedQuestions
                    )
                        ? attendedQuestions?.length
                        : 0;
                    let allAnswersCorrect = false;
                    let correctAnswers = 0;
                    let percentOfCorrectAnswers = 0;
                    if (totalAttendedQuestions !== 0) {
                        correctAnswers = attendedQuestions.filter(
                            (question) => question?.answer?.incorrect !== true
                        )?.length;
                        allAnswersCorrect =
                            totalAttendedQuestions === correctAnswers;
                        percentOfCorrectAnswers = Number(
                            (
                                (100 * correctAnswers) /
                                totalAttendedQuestions
                            ).toFixed(2)
                        );
                    }
                    const rules = formatQuery(
                        currentNode?.logic_rules,
                        'jsonlogic'
                    );
                    const data = {
                        ...userData,
                        allAnswersCorrect,
                        correctAnswers,
                        percentOfCorrectAnswers,
                    };
                    let logicResult = apply(rules, data);
                    await dispatch(deactivateLoading());
                    if (logicResult) {
                        await dispatch(
                            handleNodeAction(currentNode?.pass_target_node_id)
                        );
                    } else {
                        await dispatch(
                            handleNodeAction(currentNode?.fail_target_node_id)
                        );
                    }
                    break;
                }
                case ProcedureNodeTypes.email.type:
                    // try-catch to display dialog in case of api failure
                    try {
                        dispatch(
                            saveStatement({
                                LrsVerb: LrsVerbs.Shared,
                                response: {
                                    nodeActionType:
                                        ProcedureNodeTypes.email.type,
                                    procedure_id: activeTree?.procedure_id,
                                    tree: activeTree,
                                },
                            })
                        );
                        let response;
                        if (currentNode?.sendEmail === true) {
                            response = await procedureService.saveResults({
                                procedure_id: activeTree?.procedure_id,
                                client_id: activeUser?.client_id,
                                user_id: user?.user_id,
                                mail_to: Array.isArray(currentNode.emails)
                                    ? [...currentNode.emails]
                                    : [],
                                results_json: JSON.stringify({
                                    checklist_user_data: userData,
                                    tree: activeTree,
                                }),
                            });
                        }
                        if (currentNode?.show_email_sent_dialog === true) {
                            if (!response?.error) {
                                enqueueSnackbar('Email(s) sent successfully.', {
                                    action: (key) => (
                                        <SnackbarDismiss key={key} />
                                    ),
                                    variant: 'success',
                                });
                            } else {
                                enqueueSnackbar(
                                    'Email(s) could not be sent. Please check the email node settings or have your administrator check the  email server configuration.',
                                    {
                                        action: (key) => (
                                            <SnackbarDismiss key={key} />
                                        ),
                                        variant: 'error',
                                    }
                                );
                            }
                        }
                        if (
                            currentNode?.proceed_next_even_email_fails === true
                        ) {
                            dispatch(handleNodeAction(currentNode?.action));
                        } else {
                            if (!response?.error) {
                                dispatch(handleNodeAction(currentNode?.action));
                            } else {
                                dispatch(previousNode());
                            }
                        }
                    } catch (e) {
                        if (currentNode?.show_email_sent_dialog === true) {
                            enqueueSnackbar(
                                'Email(s) could not be sent. Please check the email node settings or have your administrator check the  email server configuration.',
                                {
                                    action: (key) => (
                                        <SnackbarDismiss key={key} />
                                    ),
                                    variant: 'error',
                                }
                            );
                        }
                        if (
                            currentNode?.proceed_next_even_email_fails === true
                        ) {
                            dispatch(handleNodeAction(currentNode?.action));
                        } else {
                            dispatch(previousNode());
                        }
                    }
                    break;
                default:
                    break;
            }
        }
        processNodeAction();
    }, [currentNode, isAssociateAssetLoaded]);

    useEffect(() => {
        if (openNavigationPanel) {
            splitPanelRef.current.pane1.style.width = '20%';
        } else {
            splitPanelRef.current.pane1.style.width = '0%';
        }
    }, [openNavigationPanel]);

    return (
        <div id="procedureWrapper" className={classes.procedureWrapper}>
            {openNewSessionDialog && (
                <NewSessionDialog
                    openNewSessionDialog={openNewSessionDialog}
                    setOpenNewSessionDialog={setOpenNewSessionDialog}
                    CreateProcedureSession={startProcedureSession}
                />
            )}
            <ProcedureHeader epssPanelRef={epssPanelRef} />
            <div className={classes.content}>
                <SplitPane
                    split="vertical"
                    minSize={150}
                    maxSize={700}
                    defaultSize="20%"
                    ref={splitPanelRef}
                    style={{
                        height: '100%',
                        position: 'relative',
                        overflow: 'auto',
                    }}
                >
                    <div
                        style={{
                            height: '100%',
                            width: '100%',
                            borderTop: '1px solid black',
                            position: 'relative',
                        }}
                    >
                        <Box
                            height="inherit"
                            overflow="auto"
                            position="absolute"
                            width="100%"
                            className={classes.navLinkWrapper}
                        >
                            {activeTree?.header_image_url && (
                                <Box>
                                    <img
                                        width="100%"
                                        src={activeTree?.header_image_url}
                                        alt={'Navigation Panel Header Image'}
                                    />
                                </Box>
                            )}

                            {activeTree?.procedureCategories &&
                            Array.isArray(activeTree?.procedureCategories) &&
                            activeTree?.procedureCategories?.length > 0 ? (
                                <NodeCategoryPanel
                                    navigationLinks={navigationLinks}
                                    procedureCategories={
                                        activeTree.procedureCategories
                                    }
                                    setIsBranchProcedure={setIsBranchProcedure}
                                />
                            ) : (
                                <Each
                                    of={navigationLinks}
                                    render={(node) => (
                                        <IconButton
                                            title={node.title || '-'}
                                            className={clsx([
                                                'navigationLinkButton',
                                                node.id === currentNode.id
                                                    ? 'activeNavigationLink'
                                                    : 'navigationPanel',
                                            ])}
                                            onClick={async () => {
                                                if (
                                                    node.type ===
                                                    ProcedureNodeTypes.branch
                                                        .type
                                                ) {
                                                    setIsBranchProcedure(true);
                                                }
                                                await dispatch(
                                                    handleNodeAction({
                                                        id: node.id,
                                                        type: 'node',
                                                    })
                                                );
                                            }}
                                        >
                                            {node.title || '-'}
                                        </IconButton>
                                    )}
                                />
                            )}
                        </Box>
                    </div>
                    <div className={classes.procedureContainer}>
                        <DisplayProcedureSteps
                            activeTree={activeTree}
                            currentNode={currentNode}
                        />
                        <ProcedureBody
                            currentNode={currentNode}
                            isSubmitAllow={isSubmitAllow}
                            isInDrawer={isInDrawer}
                            contentService={contentService}
                            useZoneNav={useZoneNav}
                            answerNodes={answerNodes}
                            anyAnswers={anyAnswers}
                            histocialAnswers={histocialAnswers}
                            procedureService={procedureService}
                            showQuestions={showQuestions}
                            hasAnswer={hasAnswer}
                            openNavigationPanel={openNavigationPanel}
                            setOpenNavigationPanel={setOpenNavigationPanel}
                        />
                    </div>
                </SplitPane>
            </div>
            <ProcedureFooter />
            {isDisplaySessionDialog && openSessionConfirmationForm && (
                <Dialog open={openSessionConfirmationForm}>
                    <DialogTitle>Procedure</DialogTitle>
                    <DialogContent sx={{ width: '400px' }}>
                        <DialogContentText marginBottom={'5px'}>
                            Do you want to continue with previous session?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleSession('previous')}>
                            Continue the previous session
                        </Button>
                        <Button onClick={() => handleSession('new')}>
                            Start a new session
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {isDisplayAssetButton === true && isProcedureLoaded === true && (
                <Tooltip title="Display Asset">
                    <IconButton
                        onClick={() => {
                            dispatch(loadAssociateAsset(true));
                            dispatch(loadChecklist(false));
                            dispatch(setDisplayAssetButton(false));
                            dispatch(
                                displayProcedureContentVersion({
                                    procedure: {
                                        procedure_id: activeTree?.procedure_id,
                                        procedure_json:
                                            JSON.stringify(activeTree),
                                        header_image_url: prefixUrl(
                                            activeTree?.header_image_url,
                                            activeUser.s3_prefix
                                        ),
                                    },
                                    doAction: currentNode,
                                })
                            );
                        }}
                        style={{
                            position: 'fixed',
                            bottom: '42px',
                            right: '20px',
                            color: '#ffffff',
                            backgroundColor: '#000000',
                        }}
                    >
                        <MdGridView
                            className="react-icon"
                            style={{ fontSize: '2rem' }}
                        />
                    </IconButton>
                </Tooltip>
            )}
        </div>
    );
};

export { Procedure };
