/**
 * This slice is to manage the assets display view
 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { assetService } from '_features/_services';
import { publishById } from './assetManagerSlice';
import {
    LoadingStatus,
    SavingStatus,
} from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { deleteAsset, editAsset } from './editAssetSlice';
import { saveAs } from 'file-saver';

const initialState = {
    assets: [],
    allAssets: [],
    assetPageInfo: {},
    status: LoadingStatus.Idle,
    loadedRowsMap: {},

    error: null,
};

export const fetchAssetsPage = createAsyncThunk(
    'assets/fetchAssetsPage',
    async (params, { getState }) =>
        await assetService.getAll(
            params.page,
            params.pageSize,
            params.searchString,
            params.searchByAssetType,
            params.lastPublishedDate,
            params.sort,
            getState().accounts.activeUser.s3_prefix
        )
);
export const fetchAllAssets = createAsyncThunk(
    'assets/fetchAllAssets',
    async (params) => await assetService.getAllAsset(params.lastPublishedDate)
);

export const fetchMoreAssets = createAsyncThunk(
    'assets/fetchMoreAssets',
    async (params, { getState }) =>
        await assetService.getRange(
            params.startIndex,
            params.stopIndex,
            params.searchString,
            params.assetCategoryId,
            getState().accounts.activeUser.s3_prefix,
            params.searchByAssetType,
            params.lastPublishedDate,
            params.sort
        )
);

export const addNewAsset = createAsyncThunk(
    'assets/addNewAsset',
    async (newAsset, { getState }) =>
        await assetService.create(
            newAsset,
            getState().accounts.activeUser.s3_prefix
        )
);

export const duplicateAsset = createAsyncThunk(
    'assets/duplicateAsset',
    async (existingAssetId, { getState }) =>
        await assetService.duplicate(
            existingAssetId,
            getState().accounts.activeUser.s3_prefix
        )
);

export const publishArray = createAsyncThunk(
    'assets/publishArray',
    async (assetIds) => {
        const response = await assetService.publishArray(assetIds);
        response.ids = assetIds;
        return response;
    }
);

export const deleteArray = createAsyncThunk(
    'assets/deleteArray',
    async (assetIds) => {
        const response = await assetService.deleteArray(assetIds);
        response.ids = assetIds;
        return response;
    }
);

export const pinAsset = createAsyncThunk(
    'assets/pinAsset',
    async (pinId, { getState }) => {
        const asset = getState().assets.allAssets.find(
            (asset) => asset.asset_id === pinId
        );
        const params = { object_id: pinId, is_pinned: !asset?.is_pinned };
        const response = await assetService.assetPinning(params);
        response.pinId = pinId;
        response.isPinned = !asset?.is_pinned;
        return response;
    }
);

export const exportAsset = createAsyncThunk(
    'assets/exportAsset',
    async (params, { getState }) => {
        const response = await assetService.exportAsset(
            params,
            getState().accounts.activeUser.s3_prefix
        );
        return response;
    }
);

export const importAsset = createAsyncThunk(
    'assets/importAsset',
    async (params, { getState }) =>
        await assetService.importAsset(
            params,
            getState().accounts.activeUser.s3_prefix
        )
);

export const copyAssetToClient = createAsyncThunk(
    'assets/AssetCopyToClient',
    async (params) => {
        return await assetService.copyToClient(params);
    }
);

const setAssetsReloadState = (state) => {
    state.status = LoadingStatus.Idle;
    state.allAssets = [];
    state.loadedRowsMap = {};
};

const assetsTableSlice = createSlice({
    name: 'assets',
    initialState,
    reducers: {
        clearMoreAssets: (state) => {
            state.moreAssets = [];
        },
        clearDisplayZone: (state) => {
            state.displayZone = { items: [], allItems: [] };
        },
        setAssetsStatus: (state, action) => {
            state.status = action.payload;
        },
        resetAssetsTableState: (state) => initialState,
    },
    extraReducers: {
        [fetchAssetsPage.pending]: (state) => {
            //state.assetPageInfo = {};
            state.status = LoadingStatus.Loading;
        },
        [fetchAssetsPage.fulfilled]: (state, action) => {
            state.status = LoadingStatus.Loaded;
            state.assetPageInfo = action.payload.pageInfo;
            state.assets = action.payload.assets;
            state.allAssets = action.payload.assets;
            const startIndex = action.meta.arg.page;
            const stopIndex = startIndex + state.assets.length - 1;
            const requestedStopIndex =
                startIndex + action.meta.arg.pageSize - 1;

            state.loadedRowsMap = {};

            for (var i = startIndex; i <= stopIndex; i++) {
                state.loadedRowsMap[i] = LoadingStatus.Loaded;
            }

            // In case we didn't load as many as requested
            for (var j = stopIndex + 1; j <= requestedStopIndex; j++) {
                delete state.loadedRowsMap[j];
            }
        },
        [fetchAssetsPage.rejected]: (state, action) => {
            state.status = LoadingStatus.Failed;
            state.error = action.error.message;
        },
        [fetchAllAssets.fulfilled]: (state, action) => {
            state.status = LoadingStatus.Loaded;
            state.assets = action.payload.assets;
        },
        [fetchAllAssets.rejected]: (state, action) => {
            state.status = LoadingStatus.Failed;
            state.error = action.error.message;
        },
        [fetchMoreAssets.pending]: (state, action) => {
            const startIndex = action.meta.arg.startIndex;
            const stopIndex = action.meta.arg.stopIndex;

            for (var i = startIndex; i <= stopIndex; i++) {
                state.loadedRowsMap[i] = LoadingStatus.Loading;
            }

            //state.assetPageInfo = {};
            //state.status = LoadingStatus.Loading;
        },
        [fetchMoreAssets.fulfilled]: (state, action) => {
            if (
                action.meta.arg.assetCategoryId ||
                action.meta.arg.assetCategoryId === 0
            ) {
                return;
            }
            //state.status = LoadingStatus.Loaded;
            //state.assetPageInfo = action.payload.pageInfo;
            const startIndex = action.meta.arg.startIndex;
            const stopIndex = startIndex + action.payload.assets.length - 1;
            const requestedStopIndex = action.meta.arg.stopIndex;

            for (var i = startIndex; i <= stopIndex; i++) {
                state.loadedRowsMap[i] = LoadingStatus.Loaded;
            }

            // In case we didn't load as many as requested
            for (var j = stopIndex + 1; j <= requestedStopIndex; j++) {
                delete state.loadedRowsMap[j];
            }

            state.allAssets = state.allAssets.concat(action.payload.assets);
        },
        [fetchMoreAssets.rejected]: (state, action) => {
            const startIndex = action.meta.arg.startIndex;
            const stopIndex = action.meta.arg.stopIndex;

            for (var i = startIndex; i <= stopIndex; i++) {
                delete state.loadedRowsMap[i];
            }

            state.status = LoadingStatus.Failed;
            state.error = action.error.message;
        },
        [addNewAsset.rejected]: (state, action) => {},
        [addNewAsset.fulfilled]: (state, action) => {
            setAssetsReloadState(state);
        },
        [duplicateAsset.pending]: (state, action) => {
            state.status = LoadingStatus.Loading;
        },
        [duplicateAsset.fulfilled]: (state, action) => {
            setAssetsReloadState(state);
        },
        [duplicateAsset.rejected]: (state, action) => {
            state.status = SavingStatus.Failed;
            state.error = action.error.message;
        },
        [editAsset.fulfilled]: (state, action) => {
            setAssetsReloadState(state);
        },
        [pinAsset.fulfilled]: (state, action) => {
            setAssetsReloadState(state);
        },
        [pinAsset.rejected]: (state, action) => {
            return action.payload;
        },
        [importAsset.fulfilled]: (state, action) => {
            setAssetsReloadState(state);
        },
        [importAsset.rejected]: (state, action) => {},
        [exportAsset.fulfilled]: (state, action) => {
            saveAs(
                new Blob([JSON.stringify(action.payload)], {
                    type: 'application/json',
                }),
                `asset${action.meta.arg.asset_id}.json`
            );
        },
        [exportAsset.rejected]: (state, action) => {},
        [deleteAsset.fulfilled]: (state, action) => {
            setAssetsReloadState(state);
        },
        [publishById.fulfilled]: (state, action) => {
            setAssetsReloadState(state);
        },
        [publishById.rejected]: (state, action) => {
            state.status = SavingStatus.Failed;
            state.error = action.error.message;
        },
        [publishArray.fulfilled]: (state, action) => {
            setAssetsReloadState(state);
        },
        [deleteArray.fulfilled]: (state, action) => {
            setAssetsReloadState(state);
        },
        [copyAssetToClient.pending]: (state, action) => {
            state.status = LoadingStatus.Loading;
        },
        [copyAssetToClient.fulfilled]: (state, action) => {
            setAssetsReloadState(state);
        },
        [copyAssetToClient.rejected]: (state, action) => {
            state.status = SavingStatus.Failed;
            state.error = action.error.message;
        },
    },
});

export const selectAssetPageInfo = (state) => state.assets.assetPageInfo;

export const selectAssetsLoadingStatus = (state) => state.assets.status;

export const selectAssetPage = (state) => state.assets.assets;

export const selectAllAssets = (state) => state.assets.allAssets;

export const selectAssetById = (state, assetId) =>
    state.assets.assets.find((assets) => assets.id === assetId);

export const { clearMoreAssets, resetAssetsTableState, setAssetsStatus } =
    assetsTableSlice.actions;

export default assetsTableSlice.reducer;
