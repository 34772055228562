// createElement is mandatory for 3rd party integration, so please don't remove it.
// eslint-disable-next-line 
import React, { useEffect, createElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DtNode } from './components/DtNode';
import { Speak } from './components/SpeechSynth/components/SpeechDispatcher';
import {
    getCurrentNode,
    setTreeData,
} from '../../_features/decisionTree/decisionTreeSlice';

const DecisionTree = ({ config }) => {
    const dispatch = useDispatch();
    const currentNode = useSelector((state) => getCurrentNode(state));

    useEffect(() => {
        dispatch(setTreeData(config.treeData));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div
            style={{
                height: '100%',
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                width: '100%',
                overflowY: 'scroll',
            }}
        >
            <DtNode node={currentNode} />
            {/* <SpeechSynth node={currentNode}/> */}
            <Speak node={currentNode} />
        </div>
    );
};

export { DecisionTree };
