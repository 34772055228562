import { IconButton, InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdSearch } from 'react-icons/md';

import {
    getContentStatus,
    selectContentById,
    getContentPageInfo,
    fetchContentByTypeId,
} from '_features/content/contentSlice';
import { ContentTableGrid } from './ContentTableGrid';
import { accordionStyles } from 'GeminiViewerComponent/components/styles';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { selectActiveAsset } from 'GeminiViewerComponent/_features/asset/assetSlice';
import { debounce } from 'GeminiViewerComponent/_helpers/lodashUtils';

function LinkContentTable({
    setExpanded,
    formField,
    linkNameValue,
    linkNameField,
    linkDescField,
    contentVersionsField,
    contentId,
    setFieldValue,
    contentTypes,
    linkTypeId,
    createButton,
    contentDisplayName,
    setContentDisplayName,
}) {
    const dispatch = useDispatch();
    const content = useSelector(selectContentById);
    const contentTypeIds = contentTypes.map((x) => x.id);

    const contentPageInfo = useSelector(getContentPageInfo);
    const contentStatus = useSelector(getContentStatus);
    const displayAsset = useSelector(selectActiveAsset);

    const classes = accordionStyles();

    useEffect(() => {
        if (contentStatus === LoadingStatus.Idle) {
            dispatch(
                fetchContentByTypeId({
                    reset: true,
                    startIndex: 0,
                    stopIndex: 19,
                    contentTypeIds: contentTypeIds,
                    assetId: displayAsset.asset_id,
                })
            );
        }
    }, [contentStatus, dispatch, contentTypeIds]);

    // eslint-disable-next-line
    const debouncedSearch = useCallback(
        debounce((searchString) => {
            dispatch(
                fetchContentByTypeId({
                    reset: true,
                    startIndex: 0,
                    stopIndex: 19,
                    contentTypeIds: contentTypeIds,
                    searchString: searchString,
                    assetId: displayAsset.asset_id,
                })
            );
        }, 1000),
        []
    );

    const handleSearch = (searchString) => debouncedSearch(searchString);

    const updateInputValue = (evt) => handleSearch(evt.target.value);

    return (
        <>
            <TextField
                placeholder="Search…"
                classes={{ root: classes.inputRoot, input: classes.inputInput }}
                type="search"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <IconButton size="large">
                                <MdSearch className="react-icon" />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                onChange={(evt) => updateInputValue(evt)}
            />
            <ContentTableGrid
                setExpanded={setExpanded}
                formField={formField}
                linkNameValue={linkNameValue}
                linkNameField={linkNameField}
                linkDescField={linkDescField}
                contentVersionsField={contentVersionsField}
                content={content}
                selectedContentId={contentId}
                setFieldValue={setFieldValue}
                pageInfo={contentPageInfo}
                linkTypeId={linkTypeId}
                createButton={createButton}
                contentTypeIds={contentTypeIds}
                contentDisplayName={contentDisplayName}
                setContentDisplayName={setContentDisplayName}
            />
        </>
    );
}

export { LinkContentTable };
