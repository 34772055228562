import { config } from 'gemini-config';
import {
    FormDataUtils,
    getNetworkService,
} from 'GeminiViewerComponent/_helpers';
import { getAccountService } from 'GeminiViewerComponent/_features/services/account.service';

const accountService = getAccountService({ config: config });
const networkService = getNetworkService({
    config: config,
    checkAuth: accountService.checkAuth,
});
const baseUrl = `${config.apiUrl}/procedure`;

// TODO: Change ignoreGrpups default to false
const getProcedureTypes = async () => {
    try {
        const response = await networkService.get(`${baseUrl}/ProcedureTypes`);
        const procedure = response.data;
        const pageInfo = response.headers['x-pagination'];
        return { pageInfo: pageInfo, procedure: procedure };
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getById = async (id) => {
    try {
        const response = await networkService.get(`${baseUrl}/${id}`);
        if (response.data.config_json) {
            response.data.config_json = JSON.parse(response.data.config_json);
        } else {
            response.data.config_json = [];
        }
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const create = async (params) => {
    const user = JSON.parse(localStorage.getItem('user'));

    let formData = new FormData();
    FormDataUtils.safeAppend(formData, [
        ['procedure_type', Number(params.procedure_type_id)],
        ['display_name', params.display_name],
        ['description', params.description],
        ['procedure_json', params.procedure_json],
        ['tag_ids', params.tag_ids],
        ['client_id', user.client_id],
    ]);

    if (Array.isArray(params?.tool_ids)) {
        FormDataUtils.safeArrayAppend(formData, [
            ['tool_ids', params?.tool_ids],
        ]);
    }

    try {
        const response = await networkService.postMultiFormData(
            `${baseUrl}`,
            formData
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const update = async (params) => {
    let formData = new FormData();
    FormDataUtils.safeAppend(formData, [
        ['procedure_type', Number(params.procedure_type_id)],
        ['procedure_json', params.procedure_json],
        ['header_image', params.header_image, params.header_image?.name],
    ]);

    if (params?.header_image_url) {
        formData.append(`header_image_url`, params.header_image_url);
    }

    if (Array.isArray(params?.tool_ids)) {
        FormDataUtils.safeArrayAppend(formData, [
            ['tool_ids', params?.tool_ids],
        ]);
    }
    try {
        const response = await networkService.putMultiFormData(
            `${baseUrl}/${params.procedure_id}`,
            formData
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const _delete = async (id) => {
    try {
        const response = await networkService.delete(`${baseUrl}/${id}`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getProceduresList = async (id) => {
    const user = JSON.parse(localStorage.getItem('user'));
    try {
        const response = await networkService.get(
            `${baseUrl}/getallbyclientid/${user.client_id}`
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const saveResults = async (params) => {
    try {
        const response = await networkService.post(
            `${baseUrl}/saveresults`,
            params
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

export const procedureService = {
    create,
    delete: _delete,
    getProcedureTypes,
    getById,
    update,
    getProceduresList,
    saveResults,
};
