import { createSlice } from '@reduxjs/toolkit';

import themesFromStore from 'GeminiViewerComponent/theme/defaultThemes';
import { isEmpty } from 'GeminiViewerComponent/_helpers/lodashUtils';

const initialState = {
    activeTheme: 'default',
    userThemes: {},
    loadingSvgUri: '',
    loadingSvgCssAnimate: false,
};

const updatedTheme = (defaultTheme, selectedTheme) => {
    let update = { ...defaultTheme, ...selectedTheme };
    (Object.keys(update) || []).forEach((keyName) => {
        if (
            typeof update[keyName] === 'object' &&
            update[keyName] !== null &&
            !Array.isArray(update[keyName])
        ) {
            update[keyName] = updatedTheme(
                defaultTheme[keyName],
                update[keyName]
            );
        }
    });
    return update;
};

const themesMissingPropertyAddition = (themes) => {
    Object.keys(themes || {}).forEach((themeName) => {
        themes = {
            ...themes,
            [themeName]: updatedTheme(
                themesFromStore['default'],
                themes[themeName]
            ),
        };
    });
    return themes;
};

const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setActiveTheme: (state, action) => {
            state.activeTheme = action.payload;
        },
        setUserThemes: (state, action) => {
            state.userThemes = themesMissingPropertyAddition(action.payload);
        },
        setLoadingSvg: (state, action) => {
            state.loadingSvgUri = action?.payload?.loadingSvgUri ?? '';
            state.loadingSvgCssAnimate =
                action?.payload?.loadingSvgCssAnimate ?? true;
        },
    },
    extraReducers: {},
});

export const selectActiveThemeName = (state) => state.theme.activeTheme;
export const selectActiveTheme = (state) => {
    const allThemes = {
        ...themesFromStore,
        ...state.theme.userThemes,
    };
    return allThemes[state.theme.activeTheme.toLowerCase()];
};
export const selecttUserThemes = (state) => state.theme.userThemes;
export const selectAllThemes = (state) => ({
    ...themesFromStore,
    ...state.theme.userThemes,
});
export const selectUserThemesLoaded = (state) =>
    !isEmpty(state.theme.userThemes);

export const { setActiveTheme, setUserThemes, setLoadingSvg } =
    themeSlice.actions;

export const selectDefaultTheme = (state) => {
    const defaultTheme = {
        ...themesFromStore,
    };
    return defaultTheme;
};

export const selectLoadingSvg = (state) => ({
    loadingSvgUri: state.theme.loadingSvgUri,
    loadingSvgCssAnimate: state.theme.loadingSvgCssAnimate,
});
export default themeSlice.reducer;
