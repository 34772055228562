import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    MenuItem,
    TextField,
    IconButton,
    Checkbox,
    Box,
    FormControl,
    Select,
    InputLabel,
    Tooltip,
} from '@mui/material';
import {
    MdAdd,
    MdCheckBox,
    MdCheckBoxOutlineBlank,
    MdClose,
    MdLibraryAdd,
} from 'react-icons/md';
import Autocomplete from '@mui/material/Autocomplete';

import contentManagerStyles from '../styles';
import { ViewMode } from 'components';
import {
    resetContentStatus,
    getContentUsageFiltersData,
} from '_features/content/contentSlice';
import { accountsThunk, accountsSlice } from 'app/store';
import { ContentTypes } from '_helpers/enumerations';
import { openPanel } from '_features/common/formSlice';
import SearchWithFilters from 'components/SearchWithFilters';
import MinMaxSelection from 'components/MinMaxSelection';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { MultiSelectDoneButton } from 'GeminiViewerComponent/components/MultiSelectDoneButton';
import ContentBulkImport from '../ContentBulkImport';
import { debounce } from 'GeminiViewerComponent/_helpers/lodashUtils';

const { updateUserSettings } = accountsThunk;
const { selectUserSetting } = accountsSlice;

const ContentFilter = ({
    contentPayload,
    setContentPayload,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    searchStringState,
    setSearchStringState,
}) => {
    const theme = useSelector(selectActiveTheme);
    const contentStyles = contentManagerStyles(theme);
    const dispatch = useDispatch();
    const fileSizeOptions = [1, 10, 50, 100, 200, 500, 1000];
    const widthOptions = [426, 640, 854, 1280, 1920, 2560, 3840, 7680];
    const heightOptions = [240, 360, 480, 720, 1080, 1440, 2160, 4320];
    const [contentTypeFilter, setContentTypeFilter] = useState([]);
    const [assetUsageId, setAssetUsageId] = useState('');
    const [zoneUsageId, setZoneUsageId] = useState('');
    const [itemUsageId, setItemUsageId] = useState('');
    const [minFileSize, setMinFileSize] = useState('');
    const [maxFileSize, setMaxFileSize] = useState('');
    const [minContentWidth, setMinContentWidth] = useState('');
    const [maxContentWidth, setMaxContentWidth] = useState('');
    const [minContentHeight, setMinContentHeight] = useState('');
    const [maxContentHeight, setMaxContentHeight] = useState('');
    const [openBulkImportForm, setOpenBulkImportForm] = useState(false);

    const contentUsageFiltersData = useSelector(getContentUsageFiltersData);
    const activeUser = useSelector(accountsSlice.selectActiveUser);
    const readOnlyMode = activeUser?.role === 'User' ? true : false;
    const contentViewMode = useSelector((state) =>
        selectUserSetting(state, 'content_view_mode')
    );

    const handleViewModeToggle = async (viewMode) => {
        dispatch(activateLoading());
        await dispatch(
            updateUserSettings({
                setting_key: 'content_view_mode',
                setting_value: viewMode,
            })
        );
        dispatch(deactivateLoading());
    };

    useEffect(() => {
        dispatch(resetContentStatus());
    }, [dispatch]);

    // eslint-disable-next-line
    const debouncedSearch = useCallback(
        debounce(
            (
                searchString,
                contentTypeIds,
                assetUsgId,
                zoneUsgId,
                itemUsgId,
                minFlSize,
                maxFlSize,
                minCntWidth,
                maxCntWidth,
                minCntHeight,
                maxCntHeight
            ) => {
                setContentPayload({
                    ...contentPayload,
                    reset: true,
                    startIndex: 0,
                    stopIndex: 19,
                    contentTypeIds: contentTypeIds,
                    searchString: searchString,
                    assetUsageId: assetUsgId,
                    zoneUsageId: zoneUsgId,
                    itemUsageId: itemUsgId,
                    minFileSize:
                        minFlSize && minFlSize !== 'No Min'
                            ? minFlSize * 1024 * 1024
                            : minFlSize,
                    maxFileSize:
                        maxFlSize && maxFlSize !== 'No Max'
                            ? maxFlSize * 1024 * 1024
                            : maxFlSize,
                    minContentWidth: minCntWidth,
                    maxContentWidth: maxCntWidth,
                    minContentHeight: minCntHeight,
                    maxContentHeight: maxCntHeight,
                });
            },
            1000
        ),
        [contentPayload]
    );

    const setSorting = (_sortBy, _sortDirection) => {
        setSortBy(_sortBy);
        setSortDirection(_sortDirection);
        if (_sortBy !== '') {
            setContentPayload({
                ...contentPayload,
                reset: true,
                startIndex: 0,
                stopIndex: 19,
                sort: `${_sortDirection === 'DESC' ? '-' : ''}${
                    _sortBy === 'content_type_id'
                        ? 'content_type_name'
                        : _sortBy
                }`,
            });
        }
    };

    const handleSearch = (searchString) => {
        setSearchStringState(searchString);
        const array = contentTypeFilter.map((item) => item.id);
        debouncedSearch(
            searchString,
            array,
            assetUsageId,
            zoneUsageId,
            itemUsageId,
            minFileSize,
            maxFileSize,
            minContentWidth,
            maxContentWidth,
            minContentHeight,
            maxContentHeight
        );
    };

    const updateSearchString = (evt) => {
        let searchString = evt.target.value;
        handleSearch(searchString);
    };

    const clearFilters = () => {
        setContentTypeFilter([]);
        setAssetUsageId('');
        setZoneUsageId('');
        setItemUsageId('');
        setMinFileSize('');
        setMaxFileSize('');
        setMinContentWidth('');
        setMaxContentWidth('');
        setMinContentHeight('');
        setMaxContentHeight('');
        setContentPayload({
            ...contentPayload,
            reset: true,
            startIndex: 0,
            stopIndex: 19,
            contentTypeIds: [],
            assetUsageId: '',
            zoneUsageId: '',
            itemUsageId: '',
            minFileSize: '',
            maxFileSize: '',
            minContentWidth: '',
            maxContentWidth: '',
            minContentHeight: '',
            maxContentHeight: '',
        });
    };

    const handleCreateNew = () => {
        dispatch(openPanel({ formKey: `contentForm`, formAction: 'Create' }));
    };

    const handleSelectorChange = () => {
        const array = contentTypeFilter.map((item) => item.id);
        debouncedSearch(
            searchStringState,
            array,
            assetUsageId,
            zoneUsageId,
            itemUsageId,
            minFileSize,
            maxFileSize,
            minContentWidth,
            maxContentWidth,
            minContentHeight,
            maxContentHeight
        );
    };

    return (
        <div className={contentStyles.actionBar} style={{ flexWrap: 'wrap' }}>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flex: { xs: '0 0 100%', md: '0 0 75%' },
                }}
            >
                <ViewMode
                    viewMode={contentViewMode}
                    setViewMode={(viewMode) => handleViewModeToggle(viewMode)}
                />
                {!readOnlyMode && (
                    <div className={contentStyles.contentActionBar}>
                        <Tooltip title="Add New Content">
                            <IconButton
                                onClick={handleCreateNew}
                                size="large"
                                className={contentStyles.contentAddButton}
                            >
                                <MdAdd className="react-icon" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Content Bulk Import">
                            <IconButton
                                onClick={() => setOpenBulkImportForm(true)}
                                size="large"
                                className={contentStyles.contentAddButton}
                            >
                                <MdLibraryAdd className="react-icon" />
                            </IconButton>
                        </Tooltip>
                        <ContentBulkImport
                            openForm={openBulkImportForm}
                            setOpenForm={setOpenBulkImportForm}
                        />
                        <fieldset className={contentStyles.filterFieldset}>
                            <legend
                                className={contentStyles.filterFieldsetLegend}
                            >
                                Filters
                            </legend>
                            {Object.keys(ContentTypes)?.length > 0 && (
                                <Autocomplete
                                    sx={{
                                        minWidth: '140px',
                                        maxWidth: 'max-content',
                                    }}
                                    limitTags={2}
                                    multiple
                                    variant="outline"
                                    id="checkboxes-content-types"
                                    options={Object.values(ContentTypes)}
                                    onChange={(event, newInputValue) => {
                                        setContentTypeFilter(newInputValue);
                                        const array = newInputValue.map(
                                            (item) => item.id
                                        );
                                        debouncedSearch(
                                            searchStringState,
                                            array,
                                            assetUsageId,
                                            zoneUsageId,
                                            itemUsageId,
                                            minFileSize,
                                            maxFileSize,
                                            minContentWidth,
                                            maxContentWidth,
                                            minContentHeight,
                                            maxContentHeight
                                        );
                                    }}
                                    PaperComponent={MultiSelectDoneButton}
                                    disableCloseOnSelect
                                    value={contentTypeFilter}
                                    getOptionLabel={(option) => option.label}
                                    renderOption={(
                                        props,
                                        option,
                                        { selected }
                                    ) => (
                                        <li
                                            {...props}
                                            style={{
                                                padding: '0',
                                            }}
                                        >
                                            <Checkbox
                                                icon={
                                                    <MdCheckBoxOutlineBlank className="react-icon" />
                                                }
                                                checkedIcon={
                                                    <MdCheckBox className="react-icon" />
                                                }
                                                style={{
                                                    marginRight: 8,
                                                }}
                                                checked={selected}
                                            />
                                            {option.label}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Content Type"
                                            placeholder="Content Type"
                                        />
                                    )}
                                />
                            )}
                            <Autocomplete
                                sx={{
                                    minWidth: '140px',
                                    maxWidth: 'max-content',
                                }}
                                variant="outline"
                                id="checkboxes-assets"
                                options={contentUsageFiltersData.asset_usage}
                                onChange={(event, newInputValue) => {
                                    let assetUsgId =
                                        newInputValue && newInputValue?.asset_id
                                            ? newInputValue?.asset_id
                                            : '';
                                    setAssetUsageId(assetUsgId);
                                    const array = contentTypeFilter.map(
                                        (item) => item.id
                                    );
                                    debouncedSearch(
                                        searchStringState,
                                        array,
                                        assetUsgId,
                                        zoneUsageId,
                                        itemUsageId,
                                        minFileSize,
                                        maxFileSize,
                                        minContentWidth,
                                        maxContentWidth,
                                        minContentHeight,
                                        maxContentHeight
                                    );
                                }}
                                disableCloseOnSelect={false}
                                value={
                                    contentUsageFiltersData.asset_usage.find(
                                        (asset) =>
                                            asset.asset_id === assetUsageId
                                    ) ?? null
                                }
                                getOptionLabel={(option) => option.asset_name}
                                renderOption={(props, option, { selected }) => (
                                    <li
                                        {...props}
                                        key={`${option.asset_name}-${option.asset_id}`}
                                        style={{
                                            padding: '6px',
                                        }}
                                    >
                                        {option.asset_name}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Asset Usage"
                                        placeholder="Asset Usage"
                                    />
                                )}
                            />
                            <Autocomplete
                                sx={{
                                    minWidth: '140px',
                                    maxWidth: 'max-content',
                                }}
                                variant="outline"
                                id="checkboxes-zones"
                                options={contentUsageFiltersData.zone_usage}
                                onChange={(event, newInputValue) => {
                                    let zoneUsgId =
                                        newInputValue && newInputValue?.zone_id
                                            ? newInputValue?.zone_id
                                            : '';
                                    setZoneUsageId(zoneUsgId);
                                    const array = contentTypeFilter.map(
                                        (item) => item.id
                                    );
                                    debouncedSearch(
                                        searchStringState,
                                        array,
                                        assetUsageId,
                                        zoneUsgId,
                                        itemUsageId,
                                        minFileSize,
                                        maxFileSize,
                                        minContentWidth,
                                        maxContentWidth,
                                        minContentHeight,
                                        maxContentHeight
                                    );
                                }}
                                disableCloseOnSelect={false}
                                value={
                                    contentUsageFiltersData.zone_usage.find(
                                        (zone) => zone.zone_id === zoneUsageId
                                    ) ?? null
                                }
                                getOptionLabel={(option) => option.zone_name}
                                renderOption={(props, option, { selected }) => (
                                    <li
                                        {...props}
                                        key={`${option.zone_name}-${option.zone_id}`}
                                        style={{
                                            padding: '6px',
                                        }}
                                    >
                                        {option.zone_name}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Zone Usage"
                                        placeholder="Zone Usage"
                                    />
                                )}
                            />
                            <Autocomplete
                                sx={{
                                    minWidth: '140px',
                                    maxWidth: 'max-content',
                                }}
                                variant="outline"
                                id="checkboxes-items"
                                options={contentUsageFiltersData.item_usage}
                                onChange={(event, newInputValue) => {
                                    let itemUsgId =
                                        newInputValue && newInputValue?.item_id
                                            ? newInputValue?.item_id
                                            : '';
                                    setItemUsageId(itemUsgId);
                                    const array = contentTypeFilter.map(
                                        (item) => item.id
                                    );
                                    debouncedSearch(
                                        searchStringState,
                                        array,
                                        assetUsageId,
                                        zoneUsageId,
                                        itemUsgId,
                                        minFileSize,
                                        maxFileSize,
                                        minContentWidth,
                                        maxContentWidth,
                                        minContentHeight,
                                        maxContentHeight
                                    );
                                }}
                                disableCloseOnSelect={false}
                                value={
                                    contentUsageFiltersData.item_usage.find(
                                        (item) => item.item_id === itemUsageId
                                    ) ?? null
                                }
                                getOptionLabel={(option) => option.item_name}
                                renderOption={(props, option, { selected }) => (
                                    <li
                                        {...props}
                                        key={`${option.item_name}-${option.item_id}`}
                                        style={{
                                            padding: '6px',
                                        }}
                                    >
                                        {option.item_name}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Item Usage"
                                        placeholder="Item Usage"
                                    />
                                )}
                            />
                            <MinMaxSelection
                                inputLabel="File Size"
                                displayUnit={'mb'}
                                selectorId={'content_file_size'}
                                minOptions={['No Min', ...fileSizeOptions]}
                                maxOptions={['No Max', ...fileSizeOptions]}
                                minValue={minFileSize}
                                maxValue={maxFileSize}
                                setMinValue={setMinFileSize}
                                setMaxValue={setMaxFileSize}
                                onSelectorChange={handleSelectorChange}
                                style={{
                                    width: '180px',
                                }}
                            />
                            <MinMaxSelection
                                inputLabel="Width"
                                selectorId={'content_width'}
                                minOptions={['No Min', ...widthOptions]}
                                maxOptions={['No Max', ...widthOptions]}
                                minValue={minContentWidth}
                                maxValue={maxContentWidth}
                                setMinValue={setMinContentWidth}
                                setMaxValue={setMaxContentWidth}
                                onSelectorChange={handleSelectorChange}
                                style={{
                                    width: '180px',
                                }}
                            />
                            <MinMaxSelection
                                inputLabel="Height"
                                selectorId={'content_height'}
                                minOptions={['No Min', ...heightOptions]}
                                maxOptions={['No Max', ...heightOptions]}
                                minValue={minContentHeight}
                                maxValue={maxContentHeight}
                                setMinValue={setMinContentHeight}
                                setMaxValue={setMaxContentHeight}
                                onSelectorChange={handleSelectorChange}
                                style={{
                                    width: '180px',
                                }}
                            />
                            <IconButton
                                aria-label="close"
                                onClick={clearFilters}
                                sx={{
                                    padding: '0',
                                }}
                                size="small"
                            >
                                <MdClose className="react-icon" />
                            </IconButton>
                        </fieldset>
                    </div>
                )}
            </Box>
            <Box
                sx={{
                    paddingLeft: '10px',
                    flex: { xs: '0 0 50%', md: '0 0 25%' },
                    mt: { xs: '0.5rem', md: '0' },
                    ml: 'auto',
                    maxWidth: '280px',
                }}
            >
                <SearchWithFilters
                    onChange={(evt) => updateSearchString(evt)}
                    filter={contentViewMode === 'card' ? true : false}
                    setSorting={setSorting}
                    sortDirection={sortDirection}
                    sortBy={sortBy}
                    addButtonText={'APPLY'}
                >
                    {contentViewMode === 'card' && (
                        <div className={contentStyles.sortContainer}>
                            <FormControl sx={{ mb: '1rem' }} fullWidth>
                                <InputLabel id="sort-by-id" variant="outlined">
                                    Sort By
                                </InputLabel>
                                <Select
                                    labelId="sort-by-id"
                                    id="select-sort-by-id"
                                    value={sortBy}
                                    label="Sort By"
                                    variant="outlined"
                                    className={contentStyles.selectRoot}
                                    onChange={(event) =>
                                        setSortBy(event?.target.value)
                                    }
                                >
                                    <MenuItem value="display_name">
                                        Content Name
                                    </MenuItem>
                                    <MenuItem value="content_type_name">
                                        Content Type
                                    </MenuItem>
                                    <MenuItem value="file_size">
                                        File Size
                                    </MenuItem>
                                    <MenuItem value="file_width">
                                        File Width
                                    </MenuItem>
                                    <MenuItem value="file_height">
                                        File Height
                                    </MenuItem>
                                    <MenuItem value="encoding_status">
                                        Status
                                    </MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl sx={{ mb: '1rem' }} fullWidth>
                                <InputLabel
                                    id="sot-direction-id"
                                    variant="outlined"
                                >
                                    Order
                                </InputLabel>
                                <Select
                                    labelId="sot-direction-id"
                                    id="select-sort-direction-id"
                                    className={contentStyles.selectRoot}
                                    value={sortDirection}
                                    label="Order"
                                    variant="outlined"
                                    onChange={(event) =>
                                        setSortDirection(event?.target.value)
                                    }
                                >
                                    <MenuItem value="ASC">Ascending</MenuItem>
                                    <MenuItem value="DESC">Descending</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    )}
                </SearchWithFilters>
            </Box>
        </div>
    );
};

export { ContentFilter };
