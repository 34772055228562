import { CaptionLanguages } from 'GeminiViewerComponent/_helpers';

const VideoCaptions = ({ caption_languages, contentURL }) => {
    return (
        Array.isArray(caption_languages) &&
        caption_languages?.map((caption, index) => (
            <track
                key={`caption-${index}`}
                label={
                    Object.values(CaptionLanguages)?.find(
                        (lang) => lang.LanguageCode === caption
                    )?.DisplayName ?? ''
                }
                kind="subtitles"
                srcLang={caption}
                src={contentURL?.concat('_', caption, '.vtt')}
            />
        ))
    );
};

export { VideoCaptions };
