import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { accountsSlice } from 'app/store';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AutoSizer } from 'react-virtualized';
import { IconButton, Tooltip } from '@mui/material';
import { MdFilterNone } from 'react-icons/md';

import { useStyles } from '../styles';
import { ZoneItems } from 'scenes/Asset/components/ZoneItems';
import {
    labelHeader,
    actionCell,
    checkCell,
    checkHeaderCell,
    pinCell,
    createCell,
    zonePreviewLabelCell,
    labelCell,
    labelHeaderWithSearch,
    VirtualTable,
} from 'components';
import { tableStyles } from 'components/_Tables/TableCells/styles';
import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';
import { Action } from '_helpers';
import { EnhancedTableToolbar } from 'GeminiViewerComponent/components/EnhancedTableToolbar';
import { VisualEdit } from './VisualEdit';
import {
    clearSelectedZone,
    fetchMoreZones,
    fetchZonesRange,
    selectAllTableZones,
    selectInitialTableZones,
    selectLoadedZonesMap,
    selectSelectedZone,
    selectTableZoneStatus,
    selectZonePageInfo,
    setSelectedZone,
} from '_features/zones/zonesTableSlice';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { resetTableItems } from '_features/items/itemsTableSlice';
import { DuplicateZonesModal } from 'components/_Modals/DuplicateZonesModal';
import { setZonesOnActiveAsset } from 'GeminiViewerComponent/_features/asset/assetSlice';
import { debounce, map } from 'GeminiViewerComponent/_helpers/lodashUtils';

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

function AssetZones({ asset }) {
    const INIT_ZONES_PAYLOAD = {
        searchString: '',
        assetId: asset?.asset_id,
        startIndex: 0,
        stopIndex: 19,
        includeItems: true,
    };
    const dispatch = useDispatch();
    const isComponentLoaded = useRef(true);
    const initialZones = useSelector(selectInitialTableZones) ?? [];
    const zoneStatus = useSelector(selectTableZoneStatus);
    const pageInfo = useSelector(selectZonePageInfo);
    const allZones = useSelector(selectAllTableZones);
    const loadedZonesMap = useSelector(selectLoadedZonesMap);
    const selectedZone = useSelector(selectSelectedZone);
    const activeUser = useSelector(accountsSlice.selectActiveUser);
    const readOnlyMode = activeUser?.role === 'User' ? true : false;
    // const viewContentPanel = useSelector(selectViewContentPanel);

    const classes = useStyles();
    const tableClasses = tableStyles();
    const [selected, setSelected] = useState([]);
    const [selectedAll, setSelectedAll] = useState(false);
    const [orbitZones, setOrbitZones] = useState([]);
    // const [selectedZone, setSelectedZone] = useState(null);
    const [rowIndex, setRowIndex] = useState(null);
    const [isLoadingAssetsZones, setLoadingAssetsZones] = useState(false);

    const prevSelectedAll = usePrevious(selectedAll);
    const [zonesPayload, setZonesPayload] = useState(INIT_ZONES_PAYLOAD);
    const [sortBy, setSortBy] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [toggleNameSearch, setToggleNameSearch] = useState(false);
    const [openCopyZoneModal, setOpenCopyZoneModal] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(
        debounce((searchString) => {
            setZonesPayload({
                ...zonesPayload,
                searchString: searchString,
            });
        }, 500),
        []
    );

    const onZoneSearch = (e) => {
        debouncedSearch(e.target.value);
    };

    const getZoneData = async (payload, setInitZones = false) => {
        const response = await dispatch(
            fetchZonesRange({
                ...payload,
                assetId: asset.asset_id,
                startIndex: 0,
                stopIndex: 19,
            })
        );
        if (setInitZones) {
            await dispatch(
                setZonesOnActiveAsset({
                    assetId: asset.asset_id,
                    zoneInfo: { zones: response.payload?.zones },
                })
            );
        }
    };

    useEffect(() => {
        if (zoneStatus === LoadingStatus.Idle) {
            setLoadingAssetsZones(true);
            getZoneData({ ...zonesPayload }, true);
        }
        (zoneStatus === LoadingStatus.Loaded ||
            zoneStatus === LoadingStatus.Failed) &&
            setLoadingAssetsZones(false);
    }, [zoneStatus, dispatch, asset.asset_id, zonesPayload]);

    // it's called only when assetPayload changes not on component load.
    useEffect(() => {
        if (isComponentLoaded.current) {
            isComponentLoaded.current = false;
            return;
        }
        getZoneData({ ...zonesPayload });
    }, [dispatch, zonesPayload]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (Array.isArray(initialZones)) {
            const index = initialZones.findIndex(
                (x) => x?.zone_id === selectedZone?.zone_id
            );
            if (index > -1) {
                setRowIndex(index);
            } else {
                dispatch(clearSelectedZone());
                setRowIndex(null);
                // setSelectedZone(null);
            }
        }
    }, [initialZones]); // eslint-disable-line react-hooks/exhaustive-deps

    if (initialZones === undefined) {
        return null;
    }

    const isSelected = (zone_id) => selected.indexOf(zone_id) !== -1;

    if (prevSelectedAll && selectedAll) {
        if (selected.length !== allZones.length) {
            setSelected(allZones.map((row) => row.zone_id));
            setSelectedAll(true);
        }
    }

    const headerHeight = 80;
    const headerHeightWithoutSearch = 50;
    const rowHeight = 60;

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelectedIds = allZones.map((zone) => zone.zone_id);
            setSelected(newSelectedIds);
            setSelectedAll(true);
            const selectedOrbitZones = allZones.filter(
                (zone) =>
                    newSelectedIds.includes(zone.zone_id) &&
                    zone?.orbit_frame >= 0
            );
            setOrbitZones(map(selectedOrbitZones, 'zone_id'));
            return;
        }
        setSelected([]);
        setSelectedAll(false);
    };

    const handleClick = (event, zone_id) => {
        event.stopPropagation();
        const selectedIndex = selected.indexOf(zone_id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, zone_id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        const selectedOrbitZones = allZones.filter(
            (zone) =>
                newSelected.includes(zone.zone_id) && zone?.orbit_frame >= 0
        );
        setOrbitZones(map(selectedOrbitZones, 'zone_id'));
        setSelected(newSelected);
        setSelectedAll(selected.length === allZones.length);
    };

    const handleItemTable = (props) => {
        const { rowData, index } = props;
        if (selectedZone?.zone_id !== rowData.zone_id) {
            dispatch(setSelectedZone(rowData.zone_id));
            setRowIndex(index);
        } else {
            dispatch(setSelectedZone(null));
            setRowIndex(null);
        }
        dispatch(resetTableItems());
    };

    const rowStyleFormat = (row) => {
        if (row.index < 0) {
            return;
        }

        if (row.index === rowIndex) {
            return { border: 'solid 1px black' };
        }

        return null;
    };

    /* start of start sorting */
    const handleSort = (_sortBy, _sortDirection) => {
        setSortBy(_sortBy);
        setSortDirection(_sortDirection);
        setZonesPayload({
            ...zonesPayload,
            sort: `${_sortDirection === 'ASC' ? '' : '-'}${_sortBy}`,
        });
    };
    /* end of asset sorting */

    let columns = [
        {
            classes: tableClasses,
            minWidth: 50,
            maxWidth: 50,
            width: 50,
            label: 'Pin',
            dataKey: 'is_pinned',
            padding: 'checkbox',
            cellLevel: 'zone',
            component: pinCell,
            headerComponent: labelHeader,
            hasHeaderSearchBar: true,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 230,
            minWidth: 230,
            label: 'Name',
            dataKey: 'display_name',
            padding: 'normal',
            component: zonePreviewLabelCell,
            headerComponent: labelHeaderWithSearch,
            allowHeaderSearchBar: true,
            hasHeaderSearchBar: true,
            onSearchChange: onZoneSearch,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            isOpenSearch: toggleNameSearch,
            toggleOpenSearch: () => {
                if (toggleNameSearch !== true || !zonesPayload?.searchString) {
                    setToggleNameSearch(!toggleNameSearch);
                }
            },
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 120,
            minWidth: 120,
            label: 'Zone Category',
            dataKey: 'zone_category_name',
            padding: 'none',
            component: labelCell,
            headerComponent: labelHeader,
            hasHeaderSearchBar: true,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            width: 100,
            minWidth: 100,
            maxWidth: 100,
            label: 'Zone',
            dataKey: 'zone_id',
            padding: 'none',
            actionCellLevel: 'zone',
            targetPopup: 'zone',
            formKey: 'zoneForm',
            dispatch: dispatch,
            component: actionCell,
            childComponents: [VisualEdit],
            headerComponent: createCell,
            hasHeaderSearchBar: true,
            readOnly: readOnlyMode,
        },
    ];

    if (readOnlyMode !== true) {
        columns = [
            {
                classes: tableClasses,
                minWidth: 50,
                maxWidth: 50,
                width: 50,
                label: 'Select',
                dataKey: 'zone_id',
                padding: 'checkbox',
                cellLevel: 'zone',
                numSelected: selected.length,
                rowCount: allZones.length,
                handleClick: handleClick,
                handleSelectAllClick: handleSelectAllClick,
                isSelected: isSelected,
                component: checkCell,
                headerComponent: checkHeaderCell,
                hasHeaderSearchBar: true,
            },
            ...columns,
        ];
    }
    function loadMoreRows({ startIndex, stopIndex }) {
        dispatch(
            fetchMoreZones({
                assetId: asset.asset_id,
                startIndex: startIndex,
                stopIndex: stopIndex,
                includeItems: true,
            })
        );
    }

    return (
        <div
            className={clsx(
                classes.root,
                classes.flexGrid,
                selectedZone != null
                    ? classes.overflowX1080
                    : classes.overflowX640
            )}
        >
            <div
                className={clsx(
                    classes.paper,
                    selectedZone != null && classes.assetZones
                )}
                style={{ height: 'calc(100vh - 220px)', minWidth: '570px' }}
            >
                {selected.length > 0 && (
                    <EnhancedTableToolbar
                        numSelected={selected.length}
                        totalCount={pageInfo.TotalCount}
                        toolTipTitle={'Delete'}
                    >
                        <Tooltip
                            title={'Duplicate All'}
                            onClick={() => setOpenCopyZoneModal(true)}
                        >
                            <IconButton
                                onClick={() => setOpenCopyZoneModal(true)}
                                size="small"
                                toolTipTitle="Duplicate"
                            >
                                <MdFilterNone
                                    className="react-icon"
                                    style={{ width: '18px', height: '18px' }}
                                />
                            </IconButton>
                        </Tooltip>
                        <PopupAction
                            action={Action.DeleteArray}
                            object={{
                                assetId: asset.asset_id,
                                zoneIds: selected,
                                setZoneIds: setSelected,
                            }}
                            level={'zone'}
                            showLabel={false}
                            toolTipTitle="Delete All"
                        />
                    </EnhancedTableToolbar>
                )}
                <AutoSizer>
                    {({ width, height }) => (
                        <VirtualTable
                            initialRows={initialZones}
                            allRows={allZones}
                            totalRowCount={pageInfo.TotalCount}
                            loadMoreRows={loadMoreRows}
                            loadedRowsMap={loadedZonesMap}
                            columnDefinitions={columns}
                            cellClassName={tableClasses.flexContainer}
                            className={tableClasses.table}
                            rowClassName={clsx(
                                tableClasses.searchBarFlexContainer,
                                tableClasses.tableRowHover
                            )}
                            headerHeight={
                                toggleNameSearch
                                    ? headerHeight
                                    : headerHeightWithoutSearch
                            }
                            width={width}
                            height={allZones.length === 0 ? 140 : height}
                            rowHeight={rowHeight}
                            onRowClick={handleItemTable}
                            rowStyle={rowStyleFormat}
                            loadingStatus={
                                isLoadingAssetsZones
                                    ? LoadingStatus.Loading
                                    : 'No zones'
                            }
                            headerStyle={{ backgroundColor: '#eeeeee' }}
                        />
                    )}
                </AutoSizer>
            </div>
            {selectedZone != null && <ZoneItems />}
            {openCopyZoneModal && (
                <DuplicateZonesModal
                    open={openCopyZoneModal}
                    handleClose={() => setOpenCopyZoneModal(false)}
                    selectedItems={selected}
                    setSelectedItems={setSelected}
                    currentAssetId={asset.asset_id}
                    orbitZones={orbitZones}
                />
            )}
        </div>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export { AssetZones };
